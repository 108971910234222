import React from "react";

interface AliPayFormProps {
  params: {
    _input_charset: string;
    currency: string;
    notify_url: string;
    out_trade_no: string;
    product_code: string;
    return_url: string;
    rmb_fee: string;
    service: string;
    partner: string;
    subject: string;
    trade_information: string;
    sign_type: string;
    refer_url: string;
    sign: string;
  };
}

interface AliPayFormState {}

class AliPayForm extends React.Component<AliPayFormProps, AliPayFormState> {
  componentDidMount() {
    document.forms[0].submit();
  }
  render() {
    const {
      _input_charset,
      currency,
      notify_url,
      out_trade_no,
      return_url,
      rmb_fee,
      service,
      partner,
      subject,
      trade_information,
      sign_type,
      refer_url,
      sign,
    } = this.props.params;
    const gateway = process.env.REACT_APP_ALIPAY_GATEWAY;
    return (
      <form
        method="POST"
        action={`${gateway}?_input_charset=${_input_charset}`}
      >
        <input type="hidden" name="sign" value={sign} />
        <input type="hidden" name="service" value={service} />
        <input type="hidden" name="partner" value={partner} />
        <input type="hidden" name="sign_type" value={sign_type} />
        <input type="hidden" name="notify_url" value={notify_url} />
        <input type="hidden" name="return_url" value={return_url} />
        <input type="hidden" name="currency" value={currency} />
        <input type="hidden" name="subject" value={subject} />
        <input type="hidden" name="out_trade_no" value={out_trade_no} />
        <input type="hidden" name="rmb_fee" value={rmb_fee} />
        <input type="hidden" name="refer_url" value={refer_url} />
        <input
          type="hidden"
          name="trade_information"
          value={JSON.stringify(trade_information)}
        />
      </form>
    );
  }
}

export default AliPayForm;
