import { Icon } from "semantic-ui-react";
import styled from "styled-components";
import { ButtonStyle } from "styles";

export const Red = "#db4437";
export const Orange = "#ff9800";
export const Purple = "#673ab7";
export const Indigo = "#3f51b5";
export const Blue = "#4285f4";
export const LightBlue = "#03a9f4";
export const Cyan = "#00bcd4";
export const Teal = "#009688";
export const Persimmon = "#F35B04";
export const Ming = "#16697A";
export const OrangeWeb = "#F49F0A";
export const MiddleBlue = "#82C0CC";
export const LightCoral = "#FE6D73";
export const LightOrgan = "#61210F";
export const RedOrange = "#ff4a1c";
export const Emerald = "#33ca7f";

export const PageContainer = styled.div`
  text-align: left;
  background: #eceef8;
  min-height: 750px;
  padding: 10px 2% 0.1px 2%;
  @media only screen and (min-width: 768px) {
    padding: 20px 6% 0.1px 6%;
  }
  @media only screen and (min-width: 992px) {
    padding: 20px 14% 0.1px 14%;
  }
  @media only screen and (min-width: 1200px) {
    padding: 20px 20% 0.1px 20%;
  }
`;

// section
export const SmallSectionTitleContainer = styled.div<{
  backgroundColor: string;
}>`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  min-width: 0%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: rgba(255, 255, 255, 1);
  word-wrap: break-word;
  width: max-content;
  padding: 8px 16px;
  margin-top: 30px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 20px;
  white-space: break-spaces;
`;

export const GeneralFormSectionContainer = styled.div<{
  topLeftBorderRadius: boolean;
}>`
  border-top-left-radius: ${({ topLeftBorderRadius }) =>
    topLeftBorderRadius ? "8px" : "0"};
  height: max-content;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 12px;
  border: 1px solid #dadce0;
  background-color: white;
  text-align: left;
  display: flex;
  flex-direction: row;
`;
export const GeneralSectionIndicator = styled.div<{
  indicator: boolean;
  indicatorColor: string;
  topLeftBorderRadius: boolean;
}>`
  display: ${({ indicator }) => (indicator ? "block" : "none")};
  border-top-left-radius: ${({ topLeftBorderRadius }) =>
    topLeftBorderRadius ? "8px" : "0"};
  border-bottom-left-radius: 8px;
  width: 6px;
  height: auto;
  background-color: ${({ indicatorColor }) => indicatorColor};
`;
export const GeneralFormSectionContentContainer = styled.div<{
  indicator: boolean;
}>`
  padding: ${({ indicator }) => (indicator ? "24px 24px 24px 18px" : "24px")};
  width: -webkit-fill-available;
`;
export const FormTitle = styled.div`
  color: #202124;
  font-size: 32px;
  font-weight: 400;
  line-height: 135%;
`;
export const FormSectionDescriptionText = styled.div`
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: #202124;
  margin-top: 12px;
`;

// container
export const MultiInputContainer = styled.div`
  display: grid;
  grid-gap: 24px;
`;

// list content
export const InstructionListContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 16px;
`;
export const ListContainer = styled.div`
  display: flex;
`;
export const ListOrdinal = styled.div`
  min-width: 20px;
`;
export const DescriptionText = styled.div`
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: #202124;
`;

// option
export const OptionContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 24px;
  padding: 0.5em 0.5em 0.5em 0;
`;
export const LabelContainer = styled.label`
  display: flex;
  cursor: pointer;
`;
export const OptionRadio = styled.input`
  display: inline-block;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  height: 20px;
  position: relative;
  vertical-align: middle;
  width: 20px;
  cursor: pointer;
`;
export const OptionText = styled.span`
  margin-left: 0.75em;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: #202124;
  min-width: 1px;
`;

// button
export const ButtonContainer = styled.div`
  display: flex;
  background: #eceef8;
  padding: 0 2% 50px 2%;
  @media only screen and (min-width: 768px) {
    padding: 0 6% 80px 6%;
  }
  @media only screen and (min-width: 992px) {
    padding: 0 14% 80px 14%;
  }
  @media only screen and (min-width: 1200px) {
    padding: 0 20% 80px 20%;
  }
`;
export const NextButton = styled(ButtonStyle)`
  height: 40px;
  padding: 0 24px 0 24px;
  margin: 0 14px 0 0;
  font-size: 14px;
  line-height: 20px;
  background-color: white;
  cursor: pointer;
  color: #6898e3;
  :hover {
    background-color: #f3f7fc;
  }
`;

export const SaveButton = styled(ButtonStyle)`
  color: white;
  height: 35px;
  font-size: 12px;
  background: #6898e3;
  margin: 0 0 0 20px;
  padding: 0 25px;
`;

export const FloatButton = styled.div<{ status: boolean; display: boolean }>`
  right: 20px;
  bottom: 20px;
  height: 44px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${({ status }) => (status ? "#6898e3" : "white")};
  color: ${({ status }) => (status ? "white" : "#7FC37F")};
  opacity: ${({ display }) => (display ? "0" : "100")};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 12px 28px rgba(0, 0, 0, 0.2);
  z-index: 998;
`;

export const AutoSaveButton = styled.div<{
  display: boolean;
}>`
  right: 20px;
  bottom: 20px;
  height: 44px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  border-radius: 8px;
  background-color: white;
  color: #7fc37f;
  z-index: 999;
  transition-duration: 0.4s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 12px 28px rgba(0, 0, 0, 0.2);
  transform: ${({ display }) =>
    display ? "translateX(0)" : "translateX(150%)"};
`;

export const SaveIcon = styled(Icon)`
  margin: 0 4px 3px 0 !important;
  font-size: 1.6rem !important;
`;

// activity content
export const ActivityTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;
export const ActivitySubtitle = styled.div`
  margin-top: 15px;
  font-weight: bold;
`;
export const ActivityText = styled.div``;

// strength and weakness skill component
export const SkillContainer = styled.div`
  margin-top: 15px;
  min-height: 50px;
  max-height 400px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
export const Button = styled.div`
  padding: 10px 15px;
  font-weight: bold;
  border-radius: 4px;
  width: max-content;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
  }
`;
export const Skill = styled(Button)`
  background: white;
  border: 1px solid #202124;
  color: #202124;
  margin: 0 10px 10px 0;
`;
export const AddSkillButton = styled(Button)`
  background: #6898e3;
  color: white;
`;

// progress bar unsaved indicator
export const UnsavedIcon = styled(Icon)`
  margin: 1px 0 0 4px !important;
  font-size: 1.6rem !important;
`;
export const ExclamationIcon = styled(Icon)`
  font-size: 0.9rem !important;
  color: red;
`;
