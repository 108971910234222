import moment from "moment-timezone";

const timeZones = {
  "Africa/Abidjan": "GMT+00:00",
  "Africa/Accra": "GMT+00:00",
  "Africa/Addis_Ababa": "GMT+03:00",
  "Africa/Algiers": "GMT+01:00",
  "Africa/Asmera": "GMT+03:00",
  "Africa/Bamako": "GMT+00:00",
  "Africa/Bangui": "GMT+01:00",
  "Africa/Banjul": "GMT+00:00",
  "Africa/Bissau": "GMT+00:00",
  "Africa/Blantyre": "GMT+02:00",
  "Africa/Brazzaville": "GMT+01:00",
  "Africa/Bujumbura": "GMT+02:00",
  "Africa/Cairo": "GMT+02:00",
  "Africa/Casablanca": "GMT+01:00",
  "Africa/Ceuta": "GMT+01:00",
  "Africa/Conakry": "GMT+00:00",
  "Africa/Dakar": "GMT+00:00",
  "Africa/Dar_es_Salaam": "GMT+03:00",
  "Africa/Djibouti": "GMT+03:00",
  "Africa/Douala": "GMT+01:00",
  "Africa/El_Aaiun": "GMT+01:00",
  "Africa/Freetown": "GMT+00:00",
  "Africa/Gaborone": "GMT+02:00",
  "Africa/Harare": "GMT+02:00",
  "Africa/Johannesburg": "GMT+02:00",
  "Africa/Kampala": "GMT+03:00",
  "Africa/Khartoum": "GMT+02:00",
  "Africa/Kigali": "GMT+02:00",
  "Africa/Kinshasa": "GMT+01:00",
  "Africa/Lagos": "GMT+01:00",
  "Africa/Libreville": "GMT+01:00",
  "Africa/Lome": "GMT+00:00",
  "Africa/Luanda": "GMT+01:00",
  "Africa/Lubumbashi": "GMT+02:00",
  "Africa/Lusaka": "GMT+02:00",
  "Africa/Malabo": "GMT+01:00",
  "Africa/Maputo": "GMT+02:00",
  "Africa/Maseru": "GMT+02:00",
  "Africa/Mbabane": "GMT+02:00",
  "Africa/Mogadishu": "GMT+03:00",
  "Africa/Monrovia": "GMT+00:00",
  "Africa/Nairobi": "GMT+03:00",
  "Africa/Ndjamena": "GMT+01:00",
  "Africa/Niamey": "GMT+01:00",
  "Africa/Nouakchott": "GMT+00:00",
  "Africa/Ouagadougou": "GMT+00:00",
  "Africa/Porto-Novo": "GMT+01:00",
  "Africa/Sao_Tome": "GMT+00:00",
  "Africa/Tripoli": "GMT+02:00",
  "Africa/Tunis": "GMT+01:00",
  "Africa/Windhoek": "GMT+02:00",
  "America/Adak": "GMT-10:00",
  "America/Anchorage": "GMT-09:00",
  "America/Anguilla": "GMT-04:00",
  "America/Antigua": "GMT-04:00",
  "America/Araguaina": "GMT-03:00",
  "America/Argentina/Buenos_Aires": "GMT-03:00",
  "America/Argentina/Catamarca": "GMT-03:00",
  "America/Argentina/Cordoba": "GMT-03:00",
  "America/Argentina/Jujuy": "GMT-03:00",
  "America/Argentina/La_Rioja": "GMT-03:00",
  "America/Argentina/Mendoza": "GMT-03:00",
  "America/Argentina/Rio_Gallegos": "GMT-03:00",
  "America/Argentina/San_Juan": "GMT-03:00",
  "America/Argentina/Tucuman": "GMT-03:00",
  "America/Argentina/Ushuaia": "GMT-03:00",
  "America/Aruba": "GMT-04:00",
  "America/Asuncion": "GMT-03:00",
  "America/Atikokan": "GMT-05:00",
  "America/Barbados": "GMT-04:00",
  "America/Belem": "GMT-03:00",
  "America/Belize": "GMT-06:00",
  "America/Boa_Vista": "GMT-04:00",
  "America/Bogota": "GMT-05:00",
  "America/Campo_Grande": "GMT-04:00",
  "America/Cancun": "GMT-05:00",
  "America/Caracas": "GMT-04:00",
  "America/Cayenne": "GMT-03:00",
  "America/Cayman": "GMT-05:00",
  "America/Chicago": "GMT-06:00",
  "America/Chihuahua": "GMT-07:00",
  "America/Costa_Rica": "GMT-06:00",
  "America/Cuiaba": "GMT-04:00",
  "America/Curacao": "GMT-04:00",
  "America/Danmarkshavn": "GMT+00:00",
  "America/Dawson": "GMT-07:00",
  "America/Denver": "GMT-07:00",
  "America/Dominica": "GMT-04:00",
  "America/Edmonton": "GMT-07:00",
  "America/Eirunepe": "GMT-05:00",
  "America/El_Salvador": "GMT-06:00",
  "America/Fortaleza": "GMT-03:00",
  "America/Godthab": "GMT-03:00",
  "America/Grand_Turk": "GMT-05:00",
  "America/Grenada": "GMT-04:00",
  "America/Guadeloupe": "GMT-04:00",
  "America/Guatemala": "GMT-06:00",
  "America/Guayaquil": "GMT-05:00",
  "America/Guyana": "GMT-04:00",
  "America/Halifax": "GMT-04:00",
  "America/Havana": "GMT-05:00",
  "America/Hermosillo": "GMT-07:00",
  "America/Inuvik": "GMT-07:00",
  "America/Iqaluit": "GMT-05:00",
  "America/Jamaica": "GMT-05:00",
  "America/La_Paz": "GMT-04:00",
  "America/Lima": "GMT-05:00",
  "America/Los_Angeles": "GMT-08:00",
  "America/Maceio": "GMT-03:00",
  "America/Managua": "GMT-06:00",
  "America/Manaus": "GMT-04:00",
  "America/Martinique": "GMT-04:00",
  "America/Mazatlan": "GMT-07:00",
  "America/Mexico_City": "GMT-06:00",
  "America/Miquelon": "GMT-03:00",
  "America/Montevideo": "GMT-03:00",
  "America/Montserrat": "GMT-04:00",
  "America/Nassau": "GMT-05:00",
  "America/New_York": "GMT-05:00",
  "America/Nipigon": "GMT-05:00",
  "America/Panama": "GMT-05:00",
  "America/Paramaribo": "GMT-03:00",
  "America/Phoenix": "GMT-07:00",
  "America/Port_of_Spain": "GMT-04:00",
  "America/Port-au-Prince": "GMT-05:00",
  "America/Puerto_Rico": "GMT-04:00",
  "America/Punta_Arenas": "GMT-03:00",
  "America/Rankin_Inlet": "GMT-06:00",
  "America/Recife": "GMT-03:00",
  "America/Regina": "GMT-06:00",
  "America/Rio_Branco": "GMT-05:00",
  "America/Santiago": "GMT-03:00",
  "America/Santo_Domingo": "GMT-04:00",
  "America/Sao_Paulo": "GMT-03:00",
  "America/Scoresbysund": "GMT-01:00",
  "America/St_Kitts": "GMT-04:00",
  "America/St_Lucia": "GMT-04:00",
  "America/St_Vincent": "GMT-04:00",
  "America/Tegucigalpa": "GMT-06:00",
  "America/Thule": "GMT-04:00",
  "America/Thunder_Bay": "GMT-05:00",
  "America/Tijuana": "GMT-08:00",
  "America/Toronto": "GMT-05:00",
  "America/Vancouver": "GMT-08:00",
  "America/Virgin": "GMT-04:00",
  "America/Whitehorse": "GMT-07:00",
  "America/Winnipeg": "GMT-06:00",
  "America/Yellowknife": "GMT-07:00",
  "Antarctica/Casey": "GMT+11:00",
  "Antarctica/Davis": "GMT+07:00",
  "Antarctica/DumontDUrville": "GMT+10:00",
  "Antarctica/Mawson": "GMT+05:00",
  "Antarctica/McMurdo": "GMT+13:00",
  "Antarctica/Palmer": "GMT-03:00",
  "Antarctica/Rothera": "GMT-03:00",
  "Antarctica/Syowa": "GMT+03:00",
  "Antarctica/Vostok": "GMT+06:00",
  "Asia/Aden": "GMT+03:00",
  "Asia/Almaty": "GMT+06:00",
  "Asia/Amman": "GMT+02:00",
  "Asia/Aqtau": "GMT+05:00",
  "Asia/Aqtobe": "GMT+05:00",
  "Asia/Ashgabat": "GMT+05:00",
  "Asia/Baghdad": "GMT+03:00",
  "Asia/Bahrain": "GMT+03:00",
  "Asia/Baku": "GMT+04:00",
  "Asia/Bangkok": "GMT+07:00",
  "Asia/Beirut": "GMT+02:00",
  "Asia/Bishkek": "GMT+06:00",
  "Asia/Brunei": "GMT+08:00",
  "Asia/Calcutta": "GMT+05:30",
  "Asia/Choibalsan": "GMT+08:00",
  "Asia/Chongqing": "GMT+08:00",
  "Asia/Colombo": "GMT+05:30",
  "Asia/Damascus": "GMT+02:00",
  "Asia/Dhaka": "GMT+06:00",
  "Asia/Dili": "GMT+09:00",
  "Asia/Dubai": "GMT+04:00",
  "Asia/Dushanbe": "GMT+05:00",
  "Asia/Gaza": "GMT+02:00",
  "Asia/Harbin": "GMT+08:00",
  "Asia/Hong_Kong": "GMT+08:00",
  "Asia/Hovd": "GMT+07:00",
  "Asia/Irkutsk": "GMT+08:00",
  "Asia/Jakarta": "GMT+07:00",
  "Asia/Jayapura": "GMT+09:00",
  "Asia/Kabul": "GMT+04:30",
  "Asia/Karachi": "GMT+05:00",
  "Asia/Kashgar": "GMT+06:00",
  "Asia/Katmandu": "GMT+05:45",
  "Asia/Kolkata": "GMT+05:30",
  "Asia/Krasnoyarsk": "GMT+07:00",
  "Asia/Kuala_Lumpur": "GMT+08:00",
  "Asia/Kuching": "GMT+08:00",
  "Asia/Kuwait": "GMT+03:00",
  "Asia/Macau": "GMT+08:00",
  "Asia/Magadan": "GMT+11:00",
  "Asia/Makassar": "GMT+08:00",
  "Asia/Manila": "GMT+08:00",
  "Asia/Muscat": "GMT+04:00",
  "Asia/Omsk": "GMT+06:00",
  "Asia/Oral": "GMT+05:00",
  "Asia/Phnom_Penh": "GMT+07:00",
  "Asia/Pontianak": "GMT+07:00",
  "Asia/Pyongyang": "GMT+09:00",
  "Asia/Qatar": "GMT+03:00",
  "Asia/Rangoon": "GMT+06:30",
  "Asia/Riyadh": "GMT+03:00",
  "Asia/Seoul": "GMT+09:00",
  "Asia/Shanghai": "GMT+08:00",
  "Asia/Singapore": "GMT+08:00",
  "Asia/Taipei": "GMT+08:00",
  "Asia/Tashkent": "GMT+05:00",
  "Asia/Tbilisi": "GMT+04:00",
  "Asia/Tehran": "GMT+03:30",
  "Asia/Tel_Aviv": "GMT+02:00",
  "Asia/Thimphu": "GMT+06:00",
  "Asia/Tokyo": "GMT+09:00",
  "Asia/Ulaanbaatar": "GMT+08:00",
  "Asia/Urumqi": "GMT+06:00",
  "Asia/Vientiane": "GMT+07:00",
  "Asia/Vladivostok": "GMT+10:00",
  "Asia/Yakutsk": "GMT+09:00",
  "Asia/Yangon": "GMT+06:30",
  "Asia/Yekaterinburg": "GMT+05:00",
  "Asia/Yerevan": "GMT+04:00",
  "Atlantic/Azores": "GMT-01:00",
  "Atlantic/Bermuda": "GMT-04:00",
  "Atlantic/Canary": "GMT+00:00",
  "Atlantic/Cape_Verde": "GMT-01:00",
  "Atlantic/Faeroe": "GMT+00:00",
  "Atlantic/Jan_Mayen": "GMT+01:00",
  "Atlantic/Reykjavik": "GMT+00:00",
  "Atlantic/South_Georgia": "GMT-02:00",
  "Atlantic/St_Helena": "GMT+00:00",
  "Atlantic/Stanley": "GMT-03:00",
  "Australia/Adelaide": "GMT+10:30",
  "Australia/Brisbane": "GMT+10:00",
  "Australia/Broken_Hill": "GMT+10:30",
  "Australia/Currie": "GMT+11:00",
  "Australia/Darwin": "GMT+09:30",
  "Australia/Hobart": "GMT+11:00",
  "Australia/Lindeman": "GMT+10:00",
  "Australia/Lord_Howe": "GMT+11:00",
  "Australia/Melbourne": "GMT+11:00",
  "Australia/Perth": "GMT+08:00",
  "Australia/Sydney": "GMT+11:00",
  "Brazil/DeNoronha": "GMT-02:00",
  "Europe/Amsterdam": "GMT+01:00",
  "Europe/Andorra": "GMT+01:00",
  "Europe/Athens": "GMT+02:00",
  "Europe/Belgrade": "GMT+01:00",
  "Europe/Berlin": "GMT+01:00",
  "Europe/Bratislava": "GMT+01:00",
  "Europe/Brussels": "GMT+01:00",
  "Europe/Bucharest": "GMT+02:00",
  "Europe/Budapest": "GMT+01:00",
  "Europe/Chisinau": "GMT+02:00",
  "Europe/Copenhagen": "GMT+01:00",
  "Europe/Dublin": "GMT+00:00",
  "Europe/Gibraltar": "GMT+01:00",
  "Europe/Guernsey": "GMT+00:00",
  "Europe/Helsinki": "GMT+02:00",
  "Europe/Isle_of_Man": "GMT+00:00",
  "Europe/Istanbul": "GMT+03:00",
  "Europe/Jersey": "GMT+00:00",
  "Europe/Kaliningrad": "GMT+02:00",
  "Europe/Kiev": "GMT+02:00",
  "Europe/Lisbon": "GMT+00:00",
  "Europe/Ljubljana": "GMT+01:00",
  "Europe/London": "GMT+00:00",
  "Europe/Luxembourg": "GMT+01:00",
  "Europe/Madrid": "GMT+01:00",
  "Europe/Malta": "GMT+01:00",
  "Europe/Mariehamn": "GMT+02:00",
  "Europe/Minsk": "GMT+03:00",
  "Europe/Monaco": "GMT+01:00",
  "Europe/Moscow": "GMT+03:00",
  "Europe/Nicosia": "GMT+02:00",
  "Europe/Oslo": "GMT+01:00",
  "Europe/Paris": "GMT+01:00",
  "Europe/Podgorica": "GMT+01:00",
  "Europe/Prague": "GMT+01:00",
  "Europe/Riga": "GMT+02:00",
  "Europe/Rome": "GMT+01:00",
  "Europe/San_Marino": "GMT+01:00",
  "Europe/Sarajevo": "GMT+01:00",
  "Europe/Skopje": "GMT+01:00",
  "Europe/Sofia": "GMT+02:00",
  "Europe/Stockholm": "GMT+01:00",
  "Europe/Tallinn": "GMT+02:00",
  "Europe/Vaduz": "GMT+01:00",
  "Europe/Vatican": "GMT+01:00",
  "Europe/Vienna": "GMT+01:00",
  "Europe/Vilnius": "GMT+02:00",
  "Europe/Warsaw": "GMT+01:00",
  "Europe/Zagreb": "GMT+01:00",
  "Europe/Zurich": "GMT+01:00",
  "Indian/Antananarivo": "GMT+03:00",
  "Indian/Chagos": "GMT+06:00",
  "Indian/Christmas": "GMT+07:00",
  "Indian/Cocos": "GMT+06:30",
  "Indian/Comoro": "GMT+03:00",
  "Indian/Kerguelen": "GMT+05:00",
  "Indian/Mahe": "GMT+04:00",
  "Indian/Maldives": "GMT+05:00",
  "Indian/Mauritius": "GMT+04:00",
  "Indian/Mayotte": "GMT+03:00",
  "Pacific/Apia": "GMT+13:00",
  "Pacific/Auckland": "GMT+13:00",
  "Pacific/Chatham": "GMT+13:45",
  "Pacific/Chuuk": "GMT+10:00",
  "Pacific/Efate": "GMT+11:00",
  "Pacific/Enderbury": "GMT+13:00",
  "Pacific/Fakaofo": "GMT+13:00",
  "Pacific/Fiji": "GMT+12:00",
  "Pacific/Funafuti": "GMT+12:00",
  "Pacific/Galapagos": "GMT-06:00",
  "Pacific/Gambier": "GMT-09:00",
  "Pacific/Guadalcanal": "GMT+11:00",
  "Pacific/Guam": "GMT+10:00",
  "Pacific/Honolulu": "GMT-10:00",
  "Pacific/Kosrae": "GMT+11:00",
  "Pacific/Kwajalein": "GMT+12:00",
  "Pacific/Majuro": "GMT+12:00",
  "Pacific/Marquesas": "GMT-09:30",
  "Pacific/Nauru": "GMT+12:00",
  "Pacific/Niue": "GMT-11:00",
  "Pacific/Norfolk": "GMT+12:00",
  "Pacific/Noumea": "GMT+11:00",
  "Pacific/Pago_Pago": "GMT-11:00",
  "Pacific/Palau": "GMT+09:00",
  "Pacific/Pitcairn": "GMT-08:00",
  "Pacific/Pohnpei": "GMT+11:00",
  "Pacific/Port_Moresby": "GMT+10:00",
  "Pacific/Rarotonga": "GMT-10:00",
  "Pacific/Saipan": "GMT+10:00",
  "Pacific/Tahiti": "GMT-10:00",
  "Pacific/Tarawa": "GMT+12:00",
  "Pacific/Tongatapu": "GMT+13:00",
  "Pacific/Wake": "GMT+12:00",
  "Pacific/Wallis": "GMT+12:00",
  "US/Eastern": "GMT-05:00",
};

export const getTimeZoneList = (t) => {
  return Object.entries(timeZones).map((timeZone: Array<string>) => {
    return {
      text: t(`utils.timeZone.${timeZone[0]}`) + " (" + timeZone[1] + ")",
      value: timeZone[0],
    };
  });
};

// Refer to https://momentjs.com/timezone/docs/ to change/overwrite new abbrs text
var abbrs = {
  "+01": "UTC+01",
  "-03": "UTC-03",
  "-04": "UTC-04",
  "-05": "UTC-05",
  "-02": "UTC-02",
  "+00": "UTC+00",
  "+11": "UTC+11",
  "+07": "UTC+07",
  "+10": "UTC+10",
  "+05": "UTC+05",
  "+03": "UTC+03",
  "+02": "UTC+02",
  "+06": "UTC+06",
  "+12": "UTC+12",
  "+04": "UTC+04",
  "+08": "UTC+08",
  "+09": "UTC+09",
  "+0530": "UTC+0530",
  "+0430": "UTC+0430",
  "+0545": "UTC+0545",
  "+0630": "UTC+0630",
  "+0330": "UTC+0330",
  "-01": "UTC-01",
  "+0845": "UTC+0845",
  "+1030": "UTC+1030",
  "-06": "UTC-06",
  "-10": "UTC-10",
  "-11": "UTC-11",
  "-12": "UTC-12",
  "-07": "UTC-07",
  "-08": "UTC-08",
  "-09": "UTC-09",
  "+13": "UTC+13",
  "+14": "UTC+14",
  "+1245": "UTC+1245",
  "-0930": "UTC-0930",
  GMT: "UTC",
};

moment.fn.zoneName = function () {
  // overwrite default abbr when using zz: e.g. '+14' to 'UTC+14'
  var abbr = this.zoneAbbr();
  return abbrs[abbr] || abbr;
};

export function getAllTzDropDown() {
  const tzList = moment.tz.names();

  type TimeZone = {
    key: string;
    value: string;
    text: string;
  };

  const timeZoneList: TimeZone[] = [];

  for (const id in tzList) {
    const tzName = tzList[id];
    const abbr = moment().tz(tzName).format("zz");

    timeZoneList.push({
      key: id,
      value: tzName,
      text: `${tzName} (${abbr})`,
    });
  }
  return timeZoneList;
}

export function getUserBrowserTz(): string {
  // guess user's timezone via their browser
  return moment.tz.guess(true); // true: ignore browser's cache
}
