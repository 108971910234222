import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Container, Content, CloseButton } from "./styles";

interface VideoPlayerModalStateProps {
  show: boolean;
  videoUrl: string | null;
  closeModal: () => void;
}

interface VideoPlayerModalState {}

type Props = VideoPlayerModalStateProps & WithTranslation;

class VideoPlayerModal extends Component<Props, VideoPlayerModalState> {
  videoRef = React.createRef() as React.RefObject<HTMLVideoElement>;

  onClose = () => {
    this.videoRef.current?.pause();
    this.props.closeModal();
  };

  render() {
    const { show, videoUrl } = this.props;
    if (!videoUrl) return <></>;

    return (
      <Container id="ModalContainer" open={show}>
        <Content id="ModalContent">
          <CloseButton onClick={this.onClose}>X</CloseButton>
          <video
            ref={this.videoRef}
            src={videoUrl}
            width={800}
            height={500}
            controls
          />
        </Content>
      </Container>
    );
  }
}

export default withTranslation()(VideoPlayerModal);
