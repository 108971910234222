import React from "react";
import { QuestionRadioContainer, QuestionText, Segment } from "../../../styles";
import Radio from "./Radio";

interface QuestionProp {
  question: string;
  index: number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  answers: number[];
  dateForIndex: number;
}

const Question = (props: QuestionProp) => {
  const { question, index, answers } = props;
  return (
    <Segment id="QuestionContainer" key={index}>
      <QuestionText id="Question">{question}</QuestionText>
      <QuestionRadioContainer>
        <Radio
          {...props}
          type="yes"
          index={index}
          isChecked={answers[index] === 0}
        />
        <div style={{ marginLeft: "15px" }} />
        <Radio
          {...props}
          type="no"
          index={index}
          isChecked={answers[index] === 1}
        />
      </QuestionRadioContainer>
    </Segment>
  );
};

export default React.memo(Question);
