import { Component } from "react";
import { withRouter, RouteComponentProps, match } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import Cookies from "js-cookie";
import axios from "axios";
import { videoUploadTabs } from "constants/eval_constants";
import { GET_LANGUAGE, SNOWFLAKE, IS_VALID_URL } from "util/common";
import Uploader from "util/uploader";
import addFile from "../images/AddFile-2x.png";
import api_address from "constants/config";
import fileSuccess from "../images/FileSuccess.png";
import {
  Arrow,
  Circle,
  CircleDiv,
  CheckCircle,
  RecordCard,
  RecordDiv,
  RecordPrompt,
  RecordText,
} from "../../../Core/SoundCollection/record/styles";
import UploadModal from "../UploadModal";
import {
  Background,
  Container,
  OptionText,
  QuestionText,
  Segment,
  Title,
  TitleIndicate,
  UploadVideoTitle,
  UploadVideoSubtitle,
  UploadVideoLinkSubtitle,
  UploadVideoLinkInstructionButton,
  LinkInputBox,
} from "../../../styles";
import {
  UploadInput,
  UploadButtonImg,
  UploadButtonText,
  DriveInput,
  SubmitButton,
  UploadBox,
  UploadBoxContainer,
  ProgressBarLine,
  BarLine,
  ProgressBarContainer,
  SwitchButtonText,
  GoalLine,
} from "../styles";
import {
  CentralisingDiv,
  ShowErrNextButton,
  UploadVideoButton,
  JustifyRightDiv,
  JustifyRightDivForButtons,
  JustifyLeftDivForButtons,
  NextAndPreviousButtonContainer,
} from "styles";
import imgFinishStamp from "../../../Core/assets/imgFinishStamp.png";

// Interfaces remain the same...
export interface videoPromptInterface {
  id: number;
  content: JSX.Element;
  completed: boolean;
}

export interface videoItemProps {
  content: videoPromptInterface;
  video1: string;
  video2: string;
  isAws1: boolean;
  isAws2: boolean;
  video1comment?: string;
  video2comment?: string;
  setVideoStateFunc: (data) => void;
  showNotification: (message: string, status: boolean) => void;
  displayErrorMessage: (message: string) => void;
}

interface UploadVideoSectionProps {
  match?: match<Match>;
}

interface Match {
  transactionId: string;
}

interface VideoItemState {
  [key: string]: boolean | string | number | File | null;
  isToggled: boolean;
  isActive: boolean;
  language: string;
  video1: string;
  video1comment: string;
  encodedVideoName1: string;
  video1IsAws: boolean;
  video2: string;
  video2comment: string;
  encodedVideoName2: string;
  video2IsAws: boolean;
  file1: File | null;
  file2: File | null;
  completed1: number;
  completed2: number;
  showModal: boolean;
  message: string;
  notificationStatus: boolean;
  uploadModalOpen: boolean;
  product: string;
  isUploading: boolean; // New state for tracking upload status
  uploadProgress: number; // New state for tracking upload progress
}

type Props = UploadVideoSectionProps &
  videoItemProps &
  RouteComponentProps &
  WithTranslation;

class VideoItem extends Component<Props, VideoItemState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isToggled: false,
      isActive: false,
      videoTab: 0,
      language: "",
      video1: "",
      video1comment: "",
      encodedVideoName1: "",
      video1IsAws: true,
      video2: "",
      video2comment: "",
      encodedVideoName2: "",
      video2IsAws: true,
      file1: null,
      file2: null,
      completed1: 0,
      completed2: 0,
      showModal: false,
      message: "",
      notificationStatus: false,
      uploadModalOpen: false,
      product: "",
      isUploading: false,
      uploadProgress: 0,
    };
  }
  componentDidMount = async () => {
    const token = Cookies.get("token");
    if (!token) {
      this.props.history.push("/login");
    }
    const language = GET_LANGUAGE();
    this.setState({
      language,
    });

    const { transactionId } = this.props.match.params;
    const res = await axios.get(api_address + "api/results/" + transactionId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { authData, result, status } = res.data;
    this.setState({
      product: res.data.result.product,
    });
    window.scrollTo(0, 0);
  };

  componentDidUpdate = (prevProps: Props, prevState: VideoItemState) => {
    const language = GET_LANGUAGE();
    if (prevState.language !== language) {
      this.setState({ language });
    }
  };

  handleSubmit = async () => {
    const { transactionId } = this.props.match.params;
    const { showNotification } = this.props;
    try {
      const data = {
        transactionid: transactionId,
      };
      const token = Cookies.get("token");
      const res = await axios.post(
        api_address + "api/results/videoSectionSubmit",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { status, message } = res.data;
      if (!status) {
        throw new Error(message);
      }
      this.props.history.push("/core-report/" + transactionId);
    } catch (error: any) {
      showNotification(error.message, false);
    }
  };

  handleSwitchUploadMethod = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  // //Upload Link Handler
  handleChangeVideo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.currentTarget;
    const { video1, video2, isAws1, isAws2, video1comment, video2comment } =
      this.props;
    if (id === "video1" && video2 !== "") {
      this.setState({ video2IsAws: isAws2 });
      if (isAws2) {
        this.setState({ encodedVideoName2: video2 });
      } else {
        this.setState({
          video2: video2,
          video2comment: video2comment as string,
        });
      }
    }
    if (id === "video2" && video1 !== "") {
      this.setState({ video1IsAws: isAws1 });
      if (isAws1) {
        this.setState({ encodedVideoName1: video1 });
      } else {
        this.setState({
          video1: video1,
          video1comment: video1comment as string,
        });
      }
    }
    this.setState({
      [id]: value,
    });
  };

  handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files, id } = e.currentTarget;
    const { t, displayErrorMessage, video1, video2, isAws1, isAws2, content } =
      this.props;
    if (!files || files.length === 0) {
      return;
    }
    const f = files[0];
    if (f && f.type.includes("video")) {
      this.setState({ [id]: f });
      if (content.id === 2 && video1 !== "") {
        this.setState({
          encodedVideoName1: video1,
          video1: video1,
          video1IsAws: isAws1,
        });
      }
      if (content.id === 1 && video2 !== "") {
        this.setState({
          encodedVideoName2: video2,
          video2: video2,
          video2IsAws: isAws2,
        });
      }
    } else {
      displayErrorMessage(t("user.survey.corePro.video.errors.invalidFile"));
    }
  };

  handleSaveLink = async () => {
    const { transactionId } = this.props.match.params;
    const { setVideoStateFunc } = this.props;

    const data = {
      transactionid: transactionId,
      video1: this.state.video1IsAws
        ? this.state.encodedVideoName1
        : this.state.video1,
      video1comment: this.state.video1comment,
      video2: this.state.video2IsAws
        ? this.state.encodedVideoName2
        : this.state.video2,
      video2comment: this.state.video2comment,
      video1aws: this.state.video1IsAws,
      video2aws: this.state.video2IsAws,
    };

    const token = Cookies.get("token");
    const res = await axios.post(api_address + "api/results/video", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { status, message } = res.data;
    if (!status) {
      throw new Error(message);
    }
    setVideoStateFunc(data);
  };

  handleOpenUploadLinkModal = () => {
    const { t, content, showNotification } = this.props;
    const { video1, video1IsAws, video2, video2IsAws, tabSelection } =
      this.state;
    if (content.id === 1) {
      if (!video1IsAws && !IS_VALID_URL(video1)) {
        showNotification(
          t("user.survey.corePro.video.errors.invalidUploadURL"),
          false
        );
        return;
      }
    } else if (content.id === 2) {
      if (!video2IsAws && !IS_VALID_URL(video2)) {
        showNotification(
          t("user.survey.corePro.video.errors.invalidUploadURL"),
          false
        );
        return;
      }
    }
    this.setState({ uploadModalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ uploadModalOpen: false });
  };

  handleVideoUploadModal = async (id: string) => {
    this.setState({
      uploadModalOpen: false,
      isUploading: true,
      uploadProgress: 0,
    });

    const interval = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.uploadProgress < 100) {
          return { uploadProgress: prevState.uploadProgress + 10 };
        } else {
          clearInterval(interval);
          return {};
        }
      });
    }, 1000);

    await new Promise((resolve) => setTimeout(resolve, 10000));

    await this.handleSaveFile(id);
    this.setState({ isUploading: false });
  };

  handleSaveFile = async (id: string) => {
    const { transactionId } = this.props.match.params;
    const { setVideoStateFunc } = this.props;
    const { file1, file2 } = this.state;
    const isVideo1 = id === "file1";
    const file = isVideo1 ? file1 : file2;
    if (!file) {
      return;
    }

    const flakeId = SNOWFLAKE.gen();
    const filenameExtension = "." + file.name.split(".").pop();
    const encodedFileName = flakeId + filenameExtension;
    const completed = isVideo1 ? "completed1" : "completed2";
    const video = isVideo1 ? "video1" : "video2";
    const encodedVideoName = isVideo1
      ? "encodedVideoName1"
      : "encodedVideoName2";

    Uploader(file, transactionId, encodedFileName)
      .on("httpUploadProgress", (evt) => {
        const uploaded = Math.round((evt.loaded / evt.total) * 100);
        uploadClass.setState({
          [completed]: uploaded,
          uploadProgress: uploaded,
        });
      })
      .send(async (err) => {
        if (err) {
          console.error(err);
          return;
        }

        this.setState({
          [video]: file.name,
          [encodedVideoName]: encodedFileName,
        });

        const data = {
          transactionid: transactionId,
          video1: isVideo1
            ? encodedFileName
            : this.state.video1IsAws
            ? this.state.encodedVideoName1
            : this.state.video1,
          video2: isVideo1
            ? this.state.video2IsAws
              ? this.state.encodedVideoName2
              : this.state.video2
            : encodedFileName,
          video1comment: this.state.video1comment,
          video2comment: this.state.video2comment,
          video1aws: isVideo1 ? true : this.state.video1IsAws,
          video2aws: isVideo1 ? this.state.video2IsAws : true,
          completed1: this.state.completed1,
          completed2: this.state.completed2,
        };

        try {
          const token = Cookies.get("token");
          const res = await axios.post(
            api_address + "api/results/video",
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const { status, message } = res.data;
          if (!status) {
            throw new Error(message);
          }

          setVideoStateFunc(data);
        } catch (error) {
          console.error("Error submitting data:", error);
        }
      });
  };

  handleUploadLink = () => {
    this.handleSaveLink();
    this.setState({ uploadModalOpen: false });
  };

  videoUpload = (videoId: number) => {
    const { t } = this.props;
    const {
      file1,
      file2,
      completed1,
      completed2,
      encodedVideoName1,
      encodedVideoName2,
      uploadModalOpen,
      isUploading,
      uploadProgress,
    } = this.state;
    const isVideo1 = videoId === 1;
    const file = isVideo1 ? file1 : file2;
    const disabled = file === null;
    const uploadedVideoName = isVideo1 ? encodedVideoName1 : encodedVideoName2;
    const showSwitchMethod = uploadedVideoName === "";
    const completed = isVideo1 ? completed1 : completed2;
    const percent = `${completed}%`;

    return (
      <Segment>
        {uploadModalOpen && (
          <UploadModal
            modalHeader={t(
              "user.survey.core.questionnaire.sound_collection.uploadModalVideoHeader"
            )}
            modalText={t(
              "user.survey.core.questionnaire.sound_collection.uploadModalVideoText"
            )}
            cancelText={t(
              "user.survey.core.questionnaire.sound_collection.skip.cancelButton"
            )}
            actionText={t(
              "user.survey.core.questionnaire.sound_collection.uploadButton"
            )}
            onCancel={this.handleModalClose}
            onAction={() => {
              this.handleVideoUploadModal("file" + videoId);
            }}
          />
        )}

        <UploadVideoTitle>
          {t("user.survey.corePro.video.videoRecording.option1")}
        </UploadVideoTitle>

        <UploadVideoSubtitle>
          {t("user.survey.corePro.video.videoRecording.optionInstruction1a")}
        </UploadVideoSubtitle>

        <UploadBox disableBox={parseInt(percent) != 0}>
          <UploadInput
            type="file"
            id={"file" + videoId}
            accept="audio/x-m4a,audio/*,video/*"
            onChange={this.handleUploadFile}
          />
          <UploadBoxContainer
            uploading={completed !== 0 && completed !== 100}
            htmlFor={"file" + videoId}
          >
            <UploadButtonImg
              src={
                completed !== 0 && completed !== 100
                  ? ""
                  : completed === 100
                  ? fileSuccess
                  : addFile
              }
              uploading={completed !== 0 && completed !== 100}
            />
            <UploadButtonText uploading={completed !== 0 && completed !== 100}>
              {completed !== 0 && completed !== 100
                ? t(
                    "user.survey.corePro.video.videoRecording.whileUploadingText"
                  )
                : completed === 100
                ? t(
                    "user.survey.corePro.video.videoRecording.finishUploadingText"
                  )
                : file
                ? file.name
                : t("user.survey.corePro.video.videoRecording.uploadText")}
            </UploadButtonText>
          </UploadBoxContainer>
        </UploadBox>

        <ProgressBarContainer display={parseInt(percent) != 0 ? "" : "none"}>
          <ProgressBarLine />
          <BarLine barWidth={percent} />
          <div style={{ textAlign: "center", marginTop: "8px" }}>
            {`${percent} ${t(
              "user.survey.corePro.video.videoRecording.uploaded"
            )}`}
          </div>
        </ProgressBarContainer>

        <CentralisingDiv>
          <UploadVideoButton
            disableButton={
              parseInt(percent) != 0 ||
              (disabled && !(parseInt(percent) != 0)) ||
              isUploading
            }
            status={true}
            onClick={() => this.setState({ uploadModalOpen: true })}
          >
            {isUploading
              ? t("user.survey.corePro.video.videoRecording.uploading")
              : t("user.survey.corePro.video.videoRecording.uploadButton")}
          </UploadVideoButton>
        </CentralisingDiv>

        <JustifyRightDiv style={{ marginTop: "10px" }}>
          {showSwitchMethod && (
            <SwitchButtonText
              display={""}
              onClick={() =>
                this.handleSwitchUploadMethod(
                  "video" + videoId + "IsAws",
                  false
                )
              }
            >
              {t("user.survey.corePro.video.videoRecording.uploadByLink")}
            </SwitchButtonText>
          )}
        </JustifyRightDiv>
      </Segment>
    );
  };

  videoLink = (videoId: number) => {
    const { t, content } = this.props;
    const { video1, video1comment, video2, video2comment, uploadModalOpen } =
      this.state;
    const videoLink = (
      <Segment>
        {uploadModalOpen && (
          <UploadModal
            modalHeader={t(
              "user.survey.core.questionnaire.sound_collection.uploadModalVideoLinkHeader"
            )}
            modalText={t(
              "user.survey.core.questionnaire.sound_collection.uploadModalVideoLinkText1"
            )}
            modalText2={t(
              "user.survey.core.questionnaire.sound_collection.uploadModalVideoLinkText2"
            )}
            modalText3={t(
              "user.survey.corePro.video.videoRecording.optionInstruction2e"
            )}
            cancelText={t(
              "user.survey.core.questionnaire.sound_collection.skip.cancelButton"
            )}
            actionText={t(
              "user.survey.core.questionnaire.sound_collection.uploadButton"
            )}
            onCancel={this.handleModalClose}
            onAction={this.handleUploadLink}
          ></UploadModal>
        )}
        <UploadVideoTitle>
          {t("user.survey.corePro.video.videoRecording.option2")}
        </UploadVideoTitle>
        <UploadVideoLinkSubtitle>
          {t("user.survey.corePro.video.videoRecording.optionInstruction2a")}
        </UploadVideoLinkSubtitle>
        <UploadVideoLinkSubtitle>
          {t("user.survey.corePro.video.videoRecording.optionInstruction2d")}
          <UploadVideoLinkInstructionButton
            href="/upload-instructions"
            target="_blank"
          >
            {t("user.survey.corePro.video.videoRecording.optionInstruction2e")}
          </UploadVideoLinkInstructionButton>
        </UploadVideoLinkSubtitle>

        <CentralisingDiv>
          <LinkInputBox
            disabled={false}
            id={`video${videoId}`}
            placeholder={
              t("user.survey.corePro.video.videoRecording.placeHolder1") +
              videoId +
              t("user.survey.corePro.video.videoRecording.placeHolder2")
            }
            onChange={this.handleChangeVideo}
            value={videoId === 1 ? video1 : video2}
          />
        </CentralisingDiv>

        <CentralisingDiv>
          <LinkInputBox
            disabled={false}
            id={`video${videoId}comment`}
            placeholder={t("user.survey.corePro.video.videoRecording.remarks")}
            onChange={this.handleChangeVideo}
            value={videoId === 1 ? video1comment : video2comment}
          />
        </CentralisingDiv>

        {/* Upload Button */}
        <CentralisingDiv>
          {videoId === 1 ? (
            <UploadVideoButton
              style={{ marginBottom: content.completed ? "20px" : "" }}
              disableButton={
                //Disabled until link is provided and disabled after confirming
                content.completed || video1 === ""
              }
              status={true}
              onClick={this.handleOpenUploadLinkModal}
            >
              {t("user.survey.corePro.video.videoRecording.uploadButton")}
            </UploadVideoButton>
          ) : (
            // Upload button for Video 2 Link Upload, made due to conflicts in logic if same component is used
            <UploadVideoButton
              style={{ marginBottom: content.completed ? "20px" : "" }}
              disableButton={content.completed || video2 === ""}
              status={true}
              onClick={this.handleOpenUploadLinkModal}
            >
              {t("user.survey.corePro.video.videoRecording.uploadButton")}
            </UploadVideoButton>
          )}
        </CentralisingDiv>

        <JustifyRightDiv>
          <SwitchButtonText
            display={content.completed ? "none" : ""}
            onClick={() =>
              this.handleSwitchUploadMethod("video" + videoId + "IsAws", true)
            }
          >
            {t("user.survey.corePro.video.videoRecording.uploadByVideo")}
          </SwitchButtonText>
        </JustifyRightDiv>
      </Segment>
    );
    return videoLink;
  };

  render() {
    const { t, content } = this.props;
    const { isToggled, video1IsAws, video2IsAws, completed1, completed2 } =
      this.state;
    const isAws = content.id === 1 ? video1IsAws : video2IsAws;
    const isCompleted = isAws
      ? content.id === 1
        ? completed1 === 100
        : completed2 === 100
      : content.completed;

    return (
      <RecordDiv>
        <RecordCard
          onClick={() => this.setState({ isToggled: !this.state.isToggled })}
        >
          <CircleDiv>
            {isCompleted ? <CheckCircle src={imgFinishStamp} /> : <Circle />}
          </CircleDiv>
          <RecordPrompt>
            <RecordText>
              {content.id === 1 && t("user.survey.corePro.video.video1.title")}
              {content.id === 2 && t("user.survey.corePro.video.video2.title")}
            </RecordText>
          </RecordPrompt>
          <div style={{ display: "grid", placeItems: "center" }}>
            <Arrow className="angle down" isOpen={isToggled && !isCompleted} />
          </div>
        </RecordCard>
        {isToggled && !isCompleted && (
          <>
            <Segment>
              <Title>
                {content.id === 1 &&
                  t("user.survey.corePro.video.video1.video1Title")}
                {content.id === 2 &&
                  t("user.survey.corePro.video.video2.video2Title")}
              </Title>
            </Segment>
            {content.content}
            {isAws && this.videoUpload(content.id)}
            {!isAws && this.videoLink(content.id)}
          </>
        )}
      </RecordDiv>
    );
  }
}

export default withRouter(withTranslation()(VideoItem));
