import gql from "graphql-tag";
import { ReturnMessage } from "../types/ReturnMessage";

export const DELETE_THERAPIST_BIO_MUTATION = gql`
  mutation DeleteTherapistBio($userUuid: String!, $uniqueBioIds: [String!]!) {
    deleteTherapistBio(userUuid: $userUuid, uniqueBioIds: $uniqueBioIds) {
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface DeleteTherapistBioData {
  deleteTherapistBio: {
    returnMessage: ReturnMessage;
  };
}

export interface DeleteTherapistBioVariables {
  userUuid: string;
  uniqueBioIds: Array<String>;
}
