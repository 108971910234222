import React from "react";
import axios from "axios";
import Cookies from "js-cookie";
import api_address from "../../../../../constants/config";
import { CALCULATE_YEAR_MONTH } from "../../../../../util/common";
import {
  getSolutionIndexByType,
  getScoreWithSection,
} from "../../../../../constants/questionnaireResult";
import {
  ROLE,
  PRODUCTS,
  EVAL_STEPS,
  MODULARIZED_PRODUCTS,
  SELF_PRODUCTS,
  EXPERT_PRODUCTS,
  EXPERT_PRODUCTS_WITHOUT_REPORT,
} from "../../../../../constants/common";
import { QUESTIONNAIRE_STEPS } from "../../../../../constants/questionnaire";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { History, LocationState } from "history";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "types/actions";
import { bindActionCreators } from "redux";
import {
  startLoginSuccess,
  startUpdateUserToken,
} from "../../../../auth/actions";
import { match, RouteComponentProps } from "react-router-dom";
import { ButtonContainer, HomeButtonContainer } from "./styles";
import {
  NextButton,
  HomeButton,
  ContactUsIcon,
  ContactUsContainer,
} from "../../../../../styles";
import {
  LOGIN_VALIDATION,
  REGISTER_VALIDATION,
  DEVELOPER_LOGIN_SUBMIT,
  LOGIN_SUBMIT,
  REGISTER_SUBMIT,
  JS_COOKIE,
} from "../../../../../util/auth";
import LoginModal from "../../../../auth/UserAuthModal/LoginModal";
import RegisterModal from "../../../../auth/UserAuthModal/RegisterModal";
import { Summary } from "./Summary";
import ResultChart from "./ResultChart";
import WeakerSkills from "./WeakerSkills";
import RecommendedActivities from "./RecommendedActivities";
import AnalysisResult from "./AnalysisResult";
import NotificationModal from "../../../../common/NotificationModal";
import { getAge, getAgeRanges } from "../../../../../util/result";
import {
  Container,
  LoadingContainer,
  LoadingText,
  Segment,
  Title,
  ContactUsTitle,
  TitleIndicate,
} from "../../styles";
import { GET_TITLE_BAR } from "util/common";
import { ADMIN_COOKIE_NAME } from "constants/admin";
import ReportNotReadyModal from "components/common/ReportNotReadyModal";
import { getCountryInitial } from "constants/countryCode";
import countryList from "constants/countryList";
import { getUserBrowserTz } from "constants/timeZone";
import lineLogo from "../../../../../assets/Line.png";
import whatsappLogo from "../../../../../assets/WhatsApp.png";
import wechatLogo from "../../../../../assets/WeChat.png";

type registerAndLoginType = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  countryCode: string;
  country: string;
  password: string;
  isAgreed: boolean;
  isPhone: boolean;
  timeZone: string;
};

interface CoreReportProps {
  history: History<LocationState>;
  match?: match<Match>;
  location: History;
}

interface Match {
  transactionId: any;
}

type CoreReportState = {
  [key: string]: any;
  id: string;
  childAge: number;
  childInfo: any;
  questionnaire: any;
  currentStep: string;
  completed: boolean;
  productId: number;
  product: string;
  transactionId: string;
  role: string;
  resultId: number;
  showRegisterModal: boolean;
  showLoginModal: boolean;
  showNotificationModal: boolean;
  errorMessage: string;
  showReportNotReadyModal: boolean;
} & registerAndLoginType;

type Props = CoreReportProps &
  LinkDispatchProps &
  WithTranslation &
  RouteComponentProps;

class CoreReport extends React.Component<Props, CoreReportState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      id: "",
      childAge: 0,
      childInfo: {},
      questionnaire: {},
      currentStep: "",
      completed: false,
      productId: 0,
      product: "",
      transactionId: "",
      role: "",
      resultId: 0,
      showRegisterModal: false,
      showLoginModal: false,
      showNotificationModal: false,
      errorMessage: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      countryCode: "",
      country: "",
      password: "",
      isAgreed: false,
      isPhone: false,
      showReportNotReadyModal: false,
      timeZone: getUserBrowserTz(),
      weakerSkills: {},
    };
  }

  componentDidMount = () => {
    const { t } = this.props;
    document.title = GET_TITLE_BAR(t, "coreReport", false);
    const token = JS_COOKIE.get(ADMIN_COOKIE_NAME) || Cookies.get("token");
    const transactionId = this.props.match.params.transactionId;
    if (!token) {
      this.props.history.push("/login");
      return;
    }
    setTimeout(async () => {
      const res = await axios.get(
        api_address + "api/results/" + transactionId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { id, role } = res.data.authData;
      const { result, weakerSkills } = res.data;
      if (
        role === ROLE.tmpUser &&
        MODULARIZED_PRODUCTS.includes(result.product)
      ) {
        setTimeout(() => {
          this.setState({ showRegisterModal: true });
        }, 1000);
      }

      if (EXPERT_PRODUCTS.includes(result.product)) {
        setTimeout(() => {
          this.setState({ showReportNotReadyModal: true });
        }, 1000);
      }

      this.setState({
        id: id,
        childAge: result.meta.age,
        resultId: result.id,
        questionnaire: result.steps.questionnaire.values,
        childInfo: result.steps.child_info.values,
        transactionId: transactionId,
        productId: result.productid,
        product: result.product,
        currentStep: result.currentstep,
        completed: result.completed,
        weakerSkills: weakerSkills,
        role,
      });
    }, 1000);

    window.scrollTo(0, 0);
  };

  displayErrorMessage = (error: string) => {
    this.setState({
      errorMessage: error,
      showNotificationModal: true,
    });
    setTimeout(() => {
      this.setState({
        errorMessage: "",
        showNotificationModal: false,
      });
    }, 3000);
  };

  onLoginSubmit = async () => {
    try {
      const { transactionId } = this.props.match.params;
      const { phone, email, countryCode, password, isPhone } = this.state;
      let user: { token: string; role: string };
      if (
        email === "developer@ps.com" &&
        process.env.REACT_APP_ENVIRONMENT === "development"
      ) {
        user = await DEVELOPER_LOGIN_SUBMIT({ email });
      } else {
        const userData = {
          countryCode,
          phone,
          email,
          password,
          isPhone,
        };
        LOGIN_VALIDATION(userData);
        user = await LOGIN_SUBMIT(userData);
      }
      const { token, role } = user;
      const res = await axios.post(
        api_address + "api/user/couponLogin",
        { transactionId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { status, message } = res.data;
      if (!status) {
        throw new Error(message);
      }
      this.props.onUpdateUserToken(token);
      this.props.onLoginSuccess(role);
      this.setState({ role, showLoginModal: false });
    } catch (error: any) {
      this.displayErrorMessage(error.message);
    }
  };

  onRegisterSubmit = async () => {
    try {
      const {
        firstName,
        lastName,
        countryCode,
        country,
        email,
        phone,
        password,
        isAgreed,
        timeZone,
      } = this.state;
      const newUser = {
        firstName,
        lastName,
        email,
        phone,
        countryCode,
        country,
        password,
        isAgreed,
        modularized: true,
        originalToken: Cookies.get("token"),
        timeZone,
      };
      REGISTER_VALIDATION(newUser);
      const token = await REGISTER_SUBMIT(newUser);
      this.props.onUpdateUserToken(token);
      this.props.onLoginSuccess(ROLE.user);
      this.setState({
        role: ROLE.user,
        showRegisterModal: false,
      });
    } catch (err: any) {
      console.error(err);
      this.displayErrorMessage(err.message);
    }
  };

  handleRegister = () => {
    this.setState({ showLoginModal: false, showRegisterModal: true });
  };

  handleLogin = () => {
    this.setState({ showLoginModal: true, showRegisterModal: false });
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    this.setState({ [id]: value });
  };

  handleCountryCodeChange = (value: string) => {
    if (value != null)
      this.setState({
        countryCode: value,
      });
    const countryCode = getCountryInitial(value);
    for (let i = 0; i < countryList.length; i++) {
      if (countryList[i].key === countryCode?.toLowerCase()) {
        this.setState({ country: countryList[i].key });
      }
    }
  };

  handleCountryChange = (event, { value }) => {
    if (value != null)
      this.setState({
        country: value,
      });
  };

  handleTimeZoneChange = (event, { value }) => {
    if (value != null)
      this.setState({
        timeZone: value,
      });
  };

  handleTimeZoneClick = (event, { value }) => {
    this.setState({
      timeZone: getUserBrowserTz(),
    });
  };

  setIsPhone = () => {
    this.setState((states) => ({
      isPhone: !states.isPhone,
    }));
  };

  setIsAgreed = () => {
    this.setState((states) => ({
      isAgreed: !states.isAgreed,
    }));
  };

  handleBuyUpOpen = () => {
    this.setState({
      showBuyUpModal: true,
    });
  };

  handleLineClick = () => {
    window.open("https://lin.ee/5TuAhDA");
  };

  handleWhatsappClick = () => {
    window.open("https://wa.me/16503341899");
  };

  handleWechatClick = () => {
    window.open(
      "https://work.weixin.qq.com/u/vc38bf55d0c6882fff?v=4.0.1.81217"
    );
  };

  handleBuyUpClose = () => {
    this.setState({
      showBuyUpModal: false,
    });
  };

  handleGoToDashboard = () => {
    this.props.history.push("/dashboard/");
  };

  getResultData = (type: string) => {
    const { t } = this.props;
    const { questionnaire, childAge } = this.state;
    const data = questionnaire[type];
    const rawResult = data?.meta?.result != null ? data.meta.result : "none";
    const result = t(`utils.fourStatus.${rawResult}`);
    const age = data ? getAge(data, t) : "";
    const score = data ? getScoreWithSection(data) : undefined;
    const solution = data
      ? t(
          `user.survey.core.result.initialAnalysis.devKey.${type}.` +
            getSolutionIndexByType(type, childAge)
        )
      : "";
    return {
      rawResult,
      analysisResult: result,
      development: age,
      answerResult: `${score?.display} / ${score?.total}`,
      developmentPoint: solution,
    };
  };

  getChartTitle = (): string => {
    const { t } = this.props;
    const { childInfo } = this.state;
    const {
      childBirthdayDay,
      childBirthdayMonth,
      childBirthdayYear,
      childName,
    } = childInfo;
    const { years, months } = CALCULATE_YEAR_MONTH(
      childBirthdayYear,
      childBirthdayMonth,
      childBirthdayDay
    );
    const name = `${childName}
    ${years} ${t("user.survey.core.result.year")} ${months} ${t(
      "user.survey.core.result.mo"
    )}`;
    return name;
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.product !== prevState.product) {
      if (EXPERT_PRODUCTS.includes(this.state.product)) {
        this.setState({ showReportNotReadyModal: true });
      }
    }
  }

  render = () => {
    const { t } = this.props;
    const {
      currentStep,
      questionnaire,
      role,
      product,
      showLoginModal,
      showRegisterModal,
      errorMessage,
      showNotificationModal,
      childAge,
      completed,
      showReportNotReadyModal,
      weakerSkills,
    } = this.state;
    //Set to all products can see "result" for now, uncomment and edit if neccessary
    const canSeeReport = true;
    // (MODULARIZED_PRODUCTS.includes(product) && currentStep === EVAL_STEPS.partialReport) ||
    // role === ROLE.admin ||
    // (SELF_PRODUCTS.includes(product) && currentStep === EVAL_STEPS.report) || (EXPERT_PRODUCTS_WITHOUT_REPORT.includes(product) && EVAL_STEPS.report) ||
    // (EXPERT_PRODUCTS.includes(product) && role === ROLE.therapist);
    const lockSummaryAndActivity = MODULARIZED_PRODUCTS.includes(product);
    if (!canSeeReport) {
      return (
        <LoadingContainer>
          <LoadingText>{t("user.survey.core.result.loading")}</LoadingText>
        </LoadingContainer>
      );
    }
    const coupon = process.env.REACT_APP_BJCH_COUPON;
    const showThankyou = product === PRODUCTS.BJCH_Questionnaire;

    const {
      expressionAgeRange,
      comprehensionAgeRange,
      oralAgeRange,
      socialAgeRange,
      preliteracyAgeRange,
    } = getAgeRanges(questionnaire);
    const chartTitle = this.getChartTitle();
    const resultData = QUESTIONNAIRE_STEPS.reduce((obj, item) => {
      return {
        ...obj,
        [item]: this.getResultData(item),
      };
    }, {});

    return (
      <div>
        <NotificationModal
          show={showNotificationModal}
          message={errorMessage}
          status={false}
        />

        <LoginModal isOpen={showLoginModal} {...this.state} {...this} />
        <RegisterModal isOpen={showRegisterModal} {...this.state} {...this} />
        <ReportNotReadyModal display={showReportNotReadyModal} />
        <Container style={{ paddingTop: "10px", background: "white" }}>
          <Segment>
            <TitleIndicate />
            <Title>{t("user.survey.core.result.title")}</Title>
          </Segment>
          <Summary
            product={this.state.product}
            summary={this.state.questionnaire.summary}
            lockSummaryAndActivity={lockSummaryAndActivity}
          />
          <ResultChart
            title={chartTitle}
            resultData={resultData}
            expressionRange={expressionAgeRange}
            comprehensionRange={comprehensionAgeRange}
            oralRange={oralAgeRange}
            socialRange={socialAgeRange}
            preliteracyRange={preliteracyAgeRange}
          />
          <WeakerSkills weakerSkills={weakerSkills} />
          <AnalysisResult
            questionnaire={questionnaire}
            resultData={resultData}
          />
          <RecommendedActivities
            lockSummaryAndActivity={lockSummaryAndActivity}
            age={childAge}
          />
          <ContactUsTitle style={{ marginTop: "30px" }}>
            {t("user.survey.core.result.contactUs")}
          </ContactUsTitle>
          <ContactUsTitle>{`(Result ID: ${this.state.resultId})`}</ContactUsTitle>
          <ContactUsContainer>
            <ContactUsIcon
              src={whatsappLogo}
              onClick={this.handleWhatsappClick}
            />
            <ContactUsIcon src={wechatLogo} onClick={this.handleWechatClick} />
            <ContactUsIcon src={lineLogo} onClick={this.handleLineClick} />
          </ContactUsContainer>
          <HomeButtonContainer>
            <HomeButton status={true} onClick={this.handleGoToDashboard}>
              {t("user.survey.core.result.home")}
            </HomeButton>
          </HomeButtonContainer>
          {showThankyou && (
            <ButtonContainer>
              <NextButton
                status={true}
                onClick={() =>
                  this.props.history.push("/OrganizationEntry/" + coupon)
                }
              >
                返回
              </NextButton>
            </ButtonContainer>
          )}
        </Container>
      </div>
    );
  };
}

interface LinkDispatchProps {
  onLoginSuccess: (data: string) => void;
  onUpdateUserToken: (data: string) => void;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): LinkDispatchProps => ({
  onLoginSuccess: bindActionCreators(startLoginSuccess, dispatch),
  onUpdateUserToken: bindActionCreators(startUpdateUserToken, dispatch),
});

export default connect(null, mapDispatchToProps)(withTranslation()(CoreReport));
