import { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import { videoUploadTabs } from "constants/eval_constants";
import { GoalLine } from "../styles";
import {
  OptionText,
  QuestionText,
  Segment,
  Title,
  TitleIndicate,
} from "components/User/Evaluation/styles";
interface Video1Props {
  language: string;
}

interface Video1State {}

type Props = Video1Props & RouteComponentProps & WithTranslation;

class Video1 extends Component<Props, Video1State> {
  role = () => {
    const { t } = this.props;
    return (
      <Segment>
        <QuestionText>
          {t("user.survey.corePro.video.videoRecording.yourRole")}
        </QuestionText>
        <GoalLine />
        <OptionText>
          {t("user.survey.corePro.video.video1.role1")}
          <b>{t("user.survey.corePro.video.video1.role2")}</b>
        </OptionText>
      </Segment>
    );
  };

  goal = () => {
    const { t } = this.props;
    return (
      <Segment>
        <QuestionText>
          {t("user.survey.corePro.video.videoRecording.goal")}
        </QuestionText>
        <GoalLine />
        <OptionText>{t("user.survey.corePro.video.video1.goal")}</OptionText>
      </Segment>
    );
  };

  sampleScenario = () => {
    const { t } = this.props;
    return (
      <Segment>
        <QuestionText>
          {t("user.survey.corePro.video.video1.sampleScenario")}
        </QuestionText>
        <GoalLine />
        <OptionText>
          {t("user.survey.corePro.video.video1.sampleScenario1a")}
        </OptionText>
        <OptionText>
          {t("user.survey.corePro.video.video1.sampleScenario1b")}
        </OptionText>
        <OptionText>
          {t("user.survey.corePro.video.video1.sampleScenario1c")}
        </OptionText>
        <OptionText>
          {t("user.survey.corePro.video.video1.sampleScenario1d")}
        </OptionText>
      </Segment>
    );
  };

  otherInformation = () => {
    const { t } = this.props;
    return (
      <Segment>
        <QuestionText>
          {t("user.survey.corePro.video.video1.otherInformation")}
        </QuestionText>
        <GoalLine />
        <OptionText>
          {t("user.survey.corePro.video.video1.otherInformation1a")}
          <b>{t("user.survey.corePro.video.video1.otherInformation1b")}</b>
        </OptionText>
        <OptionText>
          {t("user.survey.corePro.video.video1.otherInformation2a")}
          <b>{t("user.survey.corePro.video.video1.otherInformation2b")}</b>
          {t("user.survey.corePro.video.video1.otherInformation2c")}
        </OptionText>
      </Segment>
    );
  };

  render() {
    return (
      <>
        {this.goal()}
        {this.role()}
        {this.sampleScenario()}
        {this.otherInformation()}
      </>
    );
  }
}

export default withRouter(withTranslation()(Video1));
