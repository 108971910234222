import Cookies from "js-cookie";
import { NotifyImg } from "./style";
import Notify from "../../general/assets/Notify.svg";
import {
  ActionTitle,
  ContentText,
  HeaderTitle,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalMask,
  NextButton,
} from "styles";

interface Props {
  show: boolean;
}

export default function ChangeBrowserModal(props: Props) {
  const { show } = props;

  const getLink = () => {
    const token = Cookies.get("token");
    const Link = (process.env.REACT_APP_LOGIN_WITH_TOKEN_URL as string) + token;
    navigator.clipboard.writeText(Link);
  };

  return (
    <ModalMask open={show}>
      <ModalContainer id="ModalContainer">
        <ModalHeader>
          <HeaderTitle>您正在使用微信浏览器</HeaderTitle>
        </ModalHeader>
        <ModalContent id="ModalContent">
          <ContentText>请复制连结并在其他浏览器中贴上,</ContentText>
          <ContentText>以得到更佳的使用者体验</ContentText>
          <NotifyImg src={Notify} />
        </ModalContent>
        <NextButton onClick={getLink} status={true}>
          <ActionTitle>复制</ActionTitle>
        </NextButton>
      </ModalContainer>
    </ModalMask>
  );
}
