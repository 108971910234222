import Logo_Icon from "../../../../general/assets/Logo_Icon.png";
import logoCN from "../../../../general/assets/Logo_CN.png";
import logoEN from "../../../../general/assets/Logo_EN.png";
import logoTW from "../../../../general/assets/Logo_TW.png";
import ActivityImg from "./images/Activity.png";
import BasicInformationImg from "./images/BasicInformation.png";
import ContactImg from "./images/Contact.png";
import GeneralAnalysisImg from "./images/GeneralAnalysis.png";
import SuggestionImg from "./images/Suggestion.png";
import VideoImg from "./images/Video.png";
import facebookEN from "../../../../general/assets/QR/facebookEN.jpeg";
import facebookTW from "../../../../general/assets/QR/facebookTW.jpeg";
import officialTW from "../../../../general/assets/QR/officialTW.jpg";
import officialEN from "../../../../general/assets/QR/officialEN.jpg";
import officialCN from "../../../../general/assets/QR/officialCN.jpg";
import weChat from "../../../../general/assets/QR/WeChatZhuJiao.jpeg";

const getWatermark = (language: string) => {
  switch (language) {
    case "zh-CN":
      return logoCN;
    case "zh-TW":
      return logoTW;
    case "en":
      return logoEN;
    default:
      return "";
  }
};

const getQR = (language: string) => {
  switch (language) {
    case "zh-CN":
      return {
        weChat: weChat,
        official: officialCN,
        facebook: "",
      };
    case "zh-TW":
      return {
        weChat: weChat,
        official: officialTW,
        facebook: facebookTW,
      };
    case "en":
      return {
        weChat: weChat,
        official: officialEN,
        facebook: facebookEN,
      };
    default:
      return {
        weChat: "",
        official: "",
        facebook: "",
      };
  }
};

export {
  Logo_Icon,
  ActivityImg,
  BasicInformationImg,
  ContactImg,
  GeneralAnalysisImg,
  SuggestionImg,
  VideoImg,
  getWatermark,
  getQR,
};
