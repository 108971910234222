// src/util/mailgunEmailSender.tsx
import mailgun, { messages } from "mailgun-js";

const apiKey = process.env.REACT_APP_MAILGUN_APIKEY || "";
const postmasterEmail = process.env.REACT_APP_MAILGUN_POSTMASTER_EMAIL || "";
const domain = process.env.REACT_APP_MAILGUN_DOMAIN || "";

interface EmailVariables {
  [key: string]: string;
}

const getFrom = (locale: string): string => {
  const fromNames: Record<string, string> = {
    en: "PenguinSmart Support",
    cn: "启儿宝助教",
    tw: "啟兒寶助教",
  };
  return `${fromNames[locale]} <${postmasterEmail}>`;
};

const getEmailData = (
  locale: string,
  to: string,
  subject: string,
  template: string,
  variables: EmailVariables = {}
): messages.SendData => {
  return {
    from: getFrom(locale),
    to,
    subject,
    template,
    "t:version": locale || "initial",
    "h:X-Mailgun-Variables": JSON.stringify(variables),
  };
};

export const sendEmail = (
  locale: string,
  to: string,
  subject: string,
  template: string,
  variables: EmailVariables = {}
): Promise<messages.SendResponse> => {
  const mg = mailgun({ apiKey, domain }).messages();
  const data = getEmailData(locale, to, subject, template, variables);
  return mg.send(data);
};
