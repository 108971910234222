import styled from "styled-components";

export const ActivityTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  height: 50px;
  line-height: 20px;
  padding: 15px 0px;
`;

export const ActivityContainer = styled.div`
  margin: 20px 0;
  border-radius: 4px;
  border: solid 1px #80808036;
`;

export const ActivitySubtitle = styled.div`
  height: 30px;
  line-height: 18px;
  padding: 6px 0px;
  font-weight: bold;
  border-radius: 20px;
  font-size: 18px;
`;

export const ActivityDescription = styled.div`
  margin: 0px 0px 15px 0px;
  font-size: 14px;
`;

export const WarningContainer = styled.div``;

export const AnalysisResultGameName = styled.div`
  font-size: 16px;
  display: inline;
`;
