import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import { History, LocationState } from "history";
import axios from "axios";
import Cookies from "js-cookie";
import api_address from "../../../../constants/config";
import { CURRENCY } from "../../../../constants/common";
import Nps from "../../../common/Nps";
import { ServicesContainer } from "./styles";
import Product from "../../Purchase/Product";
import {
  ActionTitle,
  Description,
  EmptyStateButton,
  EmptyStateContainer,
  EmptyStateImg,
  EmtpyStateContent,
  EmtpyStateH1,
  H2Title,
  RouteSectionContainer,
} from "styles";
import TabMenu from "components/common/TabMenu";
import { GET_TITLE_BAR } from "util/common";
import { createStructuredSelector } from "reselect";
import { makeUpdateEvaluationStatus } from "../../../auth/selectors";
import { AppState } from "storeConfig";
import { connect } from "react-redux";
import EmptyStateEvaluation from "../assets/emptyStateEvaluation.svg";
interface BuyUpProps {
  history: History<LocationState>;
}

export type ProductType = {
  id: number;
  name: string;
  price: Map<string, string>;
};

interface BuyUpState {
  showNpsModal: boolean;
  productId: number;
  resultId: number;
  recommendedProducts: Array<ProductType>;
  currency: string;
  selection: number;
  isBuyUp: boolean;
  productName: string;
}

type Props = BuyUpProps & WithTranslation & RouteComponentProps & LinkStateProp;

class BuyUp extends React.Component<Props, BuyUpState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showNpsModal: false,
      productId: 0,
      resultId: 0,
      recommendedProducts: [],
      currency: CURRENCY.usd,
      selection: 0,
      isBuyUp: false,
      productName: "",
    };
  }

  componentDidMount = async () => {
    try {
      const token = Cookies.get("token");
      const res = await axios.get(api_address + "api/user/account", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { country } = res.data;
      if (country == "cn") this.setState({ currency: CURRENCY.cny });
      else if (country == "tw") this.setState({ currency: CURRENCY.twd });
      else this.setState({ currency: CURRENCY.usd });
    } catch (err: any) {
      console.error(err.message);
    }

    try {
      const { t } = this.props;
      document.title = GET_TITLE_BAR(t, "recommendation", true);
      window.scrollTo(0, 0);
      const token = Cookies.get("token");
      const res = await axios.get(api_address + "api/results/getBuyUpProduct", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { status, message, result, products, isBuyUp } = res.data;
      if (!status) throw new Error(message);
      if (result == null) {
        this.setState({
          recommendedProducts: products,
          isBuyUp,
        });
        return;
      }
      const { id, showNPS, productId, currency, completed, product } = result;
      this.setState({
        resultId: id,
        productId,
        recommendedProducts: products,
        currency,
        showNpsModal: showNPS && completed,
        isBuyUp,
        productName: product,
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleCloseNps = async () => {
    const { resultId } = this.state;
    const token = Cookies.get("token");
    const data = { resultId };
    await axios.post(api_address + "api/results/disableNPS", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    this.setState({
      showNpsModal: false,
    });
  };

  getRecommendationTabData = () => {
    const { t } = this.props;
    const { currency, recommendedProducts, isBuyUp, productName } = this.state;
    const { evaluation } = this.props;
    const services = (
      <>
        {evaluation ? (
          <EmptyStateContainer>
            <EmptyStateImg src={EmptyStateEvaluation} />
            <EmtpyStateH1>
              {t("user.purchase.recommendation.services.emptyStateH1")}
            </EmtpyStateH1>
            <EmtpyStateContent>
              {t("user.purchase.recommendation.services.emptyStateContent")}
              {t(`user.purchase.payments.checkoutPayment.${productName}`)}
            </EmtpyStateContent>
            <EmptyStateButton
              onClick={() => this.props.history.push("/evaluation")}
            >
              <ActionTitle>
                {t("user.purchase.recommendation.services.emptyStateButton")}
              </ActionTitle>
            </EmptyStateButton>
          </EmptyStateContainer>
        ) : (
          <ServicesContainer>
            {recommendedProducts.map((product: ProductType) => (
              <Product
                product={product}
                currency={currency}
                isBuyUp={isBuyUp}
              />
            ))}
          </ServicesContainer>
        )}
      </>
    );
    const tabTitles = [t("user.purchase.recommendation.services.title")];
    const tabContent = [services];
    const data = {
      tabTitles,
      tabContent,
    };
    return data;
  };

  handleTabClick = (index: number) => {
    this.setState({
      selection: index,
    });
  };

  render() {
    const { t } = this.props;
    const { selection } = this.state;
    const { tabTitles, tabContent } = this.getRecommendationTabData();
    return (
      <>
        <Nps
          {...this.state}
          history={this.props.history}
          handleCloseModal={this.handleCloseNps}
        />
        <RouteSectionContainer>
          <div style={{ padding: "0 8px 20px 8px" }}>
            <H2Title>{t("user.purchase.recommendation.title")}</H2Title>
            <Description>{t("user.purchase.recommendation.des")}</Description>
          </div>
          <TabMenu
            tabTitles={tabTitles}
            tabContents={tabContent}
            selection={selection}
            handleTabClick={this.handleTabClick}
          />
        </RouteSectionContainer>
      </>
    );
  }
}

interface LinkStateProp {
  evaluation: boolean;
}

const mapStateToProps = createStructuredSelector<AppState, LinkStateProp>({
  evaluation: makeUpdateEvaluationStatus(),
});

export default withRouter(connect(mapStateToProps)(withTranslation()(BuyUp)));
