import styled from "styled-components";

export const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px;
  align-items: center;

  @media only screen and (max-width: 600px) {
    display: block;
    margin: 30px 5px;
  }
`;

export const UserInfoText = styled.p`
  margin: 0px;
  font-size: 16px;
  font-weight: bold;
`;

export const UserInfoEditButton = styled.p`
  color: #365899;
  cursor: pointer;
`;

export const ButtonGroup = styled.div`
  @media only screen and (max-width: 600px) {
    margin: 5px 0px;
  }
`;

export const CancelButton = styled.p`
  padding: 2px 5px;
  margin: 0 3px;
  background-color: red;
  color: white;
  cursor: pointer;
  float: left;
`;

export const SaveButton = styled.p`
  padding: 2px 5px;
  margin: 0 3px;
  background-color: #47e4c2;
  color: white;
  cursor: pointer;
  float: left;
`;
