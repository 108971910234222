import { useTranslation } from "react-i18next";
import {
  A4Container,
  SectionTitle,
  WaterMark,
  PageNumber as PageNumberBelow,
} from "../styles";
import { Content, ContentText, Dot, PageNumber } from "./styles";
import { getWatermark } from "../ImageLoader";

function TableOfContent(props) {
  const { t } = useTranslation();

  return (
    <A4Container>
      <SectionTitle>
        {t("user.survey.corePro.result.tableOfContent.title")}
      </SectionTitle>
      <Content>
        <ContentText>
          {t("user.survey.corePro.result.tableOfContent.basicInformation")}
        </ContentText>
        <Dot></Dot>
        <PageNumber>3</PageNumber>
      </Content>

      <Content>
        <ContentText>
          {t("user.survey.corePro.result.tableOfContent.reviewAndSuggestion")}
        </ContentText>
        <Dot></Dot>
        <PageNumber>4</PageNumber>
      </Content>

      <Content>
        <ContentText>
          {t("user.survey.corePro.result.tableOfContent.generalAnalysis")}
        </ContentText>
        <Dot></Dot>
        <PageNumber>5</PageNumber>
      </Content>

      <Content>
        <ContentText>
          {t("user.survey.corePro.result.tableOfContent.videoAnalysis")}
        </ContentText>
        <Dot></Dot>
        <PageNumber>6</PageNumber>
      </Content>

      <Content>
        <ContentText>
          {t("user.survey.corePro.result.tableOfContent.activity")}
        </ContentText>
        <Dot></Dot>
        <PageNumber>9</PageNumber>
      </Content>

      <Content>
        <ContentText>
          {t("user.survey.corePro.result.tableOfContent.appendix1")}
        </ContentText>
        <Dot></Dot>
        <PageNumber>12</PageNumber>
      </Content>

      <Content>
        <ContentText>
          {t("user.survey.corePro.result.tableOfContent.appendix2")}
        </ContentText>
        <Dot></Dot>
        <PageNumber>15</PageNumber>
      </Content>

      <Content>
        <ContentText>
          {t("user.survey.corePro.result.tableOfContent.trailer")}
        </ContentText>
        <Dot></Dot>
        <PageNumber>17</PageNumber>
      </Content>
      <PageNumberBelow>2</PageNumberBelow>
      <WaterMark src={getWatermark(props.language)} />
    </A4Container>
  );
}
export default TableOfContent;
