import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { History, LocationState } from "history";
import { TextArea, ScoreButton, ScoreButtonContainer } from "./styles";
import api_address from "../../../constants/config";
import axios from "axios";
import Cookies from "js-cookie";
import { Icon } from "semantic-ui-react";
import {
  CloseButtonContainer,
  ModalContainer,
  ModalContainerWithClose,
  ModalContent,
  ModalHeader,
  ModalMask,
  ActionTitle,
  HeaderTitle,
  NextButton,
} from "styles";

interface NpsProps {
  history: History<LocationState>;
  resultId: number;
  productId: number;
  showNpsModal: boolean;
  handleCloseModal: () => void;
}

interface NpsState {
  notifs: object;
  score: number;
  scoreLight: boolean[];
  maxscore: number;
  feedbackComment: string;
  haveScore: boolean;
  showThankyou: boolean;
}

type Props = NpsProps & WithTranslation;

class Nps extends React.Component<Props, NpsState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      notifs: {},
      score: 0,
      scoreLight: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      maxscore: 10,
      feedbackComment: "",
      haveScore: false,
      showThankyou: false,
    };
  }

  handleSubmit = async () => {
    try {
      const token = Cookies.get("token");
      const { resultId, productId } = this.props;
      const { score, maxscore, feedbackComment } = this.state;
      const headers = token
        ? {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : {};
      const feedbackData = {
        score,
        maxscore,
        comment: feedbackComment,
        resultId,
        productId,
      };
      await axios.post(
        api_address + "api/feedback/npsScore",
        feedbackData,
        headers
      );
      this.props.handleCloseModal();
    } catch (err) {
      console.error(err);
    }
  };

  handleHover = (currentSelect: number) => {
    if (this.state.score < currentSelect) {
      this.setState((state) => ({
        scoreLight: state.scoreLight.map((s, index) => index <= currentSelect),
      }));
    }
  };

  handleLeave = () => {
    this.setState((state) => ({
      scoreLight: state.scoreLight.map(
        (s, index) => index <= this.state.score - 1
      ),
    }));
  };

  setScore = (currentSelect: number) => {
    this.setState((state) => ({
      score: currentSelect + 1,
      haveScore: true,
      scoreLight: state.scoreLight.map((s, index) => index <= currentSelect),
    }));
  };

  buttons = () => {
    const { scoreLight } = this.state;
    const button = scoreLight.map((s, index) => {
      return (
        <ScoreButton
          // value={index}
          highlight={s}
          onMouseOver={() => this.handleHover(index)}
          onMouseOut={() => this.handleLeave()}
          onClick={() => {
            this.setScore(index);
          }}
        >
          {index + 1}
        </ScoreButton>
      );
    });
    return button;
  };

  onModalClose = () => {
    this.setState({
      notifs: {},
      score: 0,
      scoreLight: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      feedbackComment: "",
      haveScore: false,
    });
    this.props.handleCloseModal();
  };

  render() {
    const { t, showNpsModal } = this.props;
    const { haveScore } = this.state;
    return (
      <ModalMask id="ModalContainer" open={showNpsModal}>
        <ModalContainerWithClose>
          <CloseButtonContainer>
            <Icon
              className="close"
              size="large"
              link
              style={{ marginBottom: "10px", marginRight: "-4px" }}
              onClick={this.onModalClose}
            />
          </CloseButtonContainer>
          <ModalContainer id="ModalContent">
            <ModalHeader>
              <HeaderTitle>{t("utils.NPS.title")}</HeaderTitle>
            </ModalHeader>
            <ModalContent>
              <div style={{ fontWeight: "bold" }}>
                {t("utils.NPS.question")}
              </div>
              <ScoreButtonContainer>{this.buttons()}</ScoreButtonContainer>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div>{t("utils.NPS.no")}</div>
                <div>{t("utils.NPS.yes")}</div>
              </div>
              <div style={{ fontWeight: "bold" }}>{t("utils.NPS.comment")}</div>
              <TextArea
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  this.setState({ feedbackComment: e.currentTarget.value })
                }
                id="feedbackComment"
              />
            </ModalContent>
            <NextButton onClick={this.handleSubmit} status={haveScore}>
              <ActionTitle>{t("utils.NPS.send")}</ActionTitle>
            </NextButton>
          </ModalContainer>
        </ModalContainerWithClose>
      </ModalMask>
    );
  }
}

export default withTranslation()(Nps);
