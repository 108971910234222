import React, { Component } from "react";
import { withRouter, RouteComponentProps, match } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { withTranslation, WithTranslation } from "react-i18next";
import api_address from "../../../../../constants/config";
import addFile from "./images/AddFile-2x.png";
import fileSuccess from "./images/FileSuccess.png";
import Instruction from "./Instruction";
import SetUp from "./SetUp";
import VideoItem from "./Video/VideoItem";
import Video1 from "./Video1";
import Video2, { Video2Preload } from "./Video2";
import CollapsibleContent from "components/common/CollapsibleContent";
import {
  SNOWFLAKE,
  IS_VALID_URL,
  GET_LANGUAGE,
} from "../../../../../util/common";
import { videoUploadTabs } from "constants/eval_constants";
import Uploader from "../../../../../util/uploader";
import TabMenu from "../../../../common/TabMenu";
import NotificationModal from "../../../../common/NotificationModal";
import EvalProgressBar from "../../ProgressBar/EvalProgressBar";
import {
  UploadInput,
  UploadButtonImg,
  UploadButtonText,
  DriveInput,
  SubmitButton,
  UploadBox,
  UploadBoxContainer,
  ProgressBarLine,
  BarLine,
  ProgressBarContainer,
  SwitchButtonText,
  GoalLine,
} from "./styles";
import {
  Background,
  Container,
  OptionText,
  QuestionText,
  Segment,
  Title,
  TitleIndicate,
  UploadVideoTitle,
  UploadVideoSubtitle,
  UploadVideoLinkSubtitle,
  UploadVideoLinkInstructionButton,
  LinkInputBox,
} from "../../styles";

import {
  CentralisingDiv,
  ShowErrNextButton,
  UploadVideoButton,
  JustifyRightDiv,
  JustifyRightDivForButtons,
  JustifyLeftDivForButtons,
  NextAndPreviousButtonContainer,
} from "styles";
import UploadModal from "./UploadModal";
import { videoPromptInterface } from "./Video/VideoItem";

interface UploadVideoSectionProps {
  match?: match<Match>;
}

interface Match {
  transactionId: string;
}

interface UploadVideoSectionState {
  [key: string]: boolean | string | number | File | null;
  language: string;
  video1: string;
  video1comment: string;
  encodedVideoName1: string;
  video1IsAws: boolean;
  video2: string;
  video2comment: string;
  encodedVideoName2: string;
  video2IsAws: boolean;
  file1: File | null;
  file2: File | null;
  tabSelection: number;
  showModal: boolean;
  message: string;
  notificationStatus: boolean;
  uploadModalOpen: boolean;
  product: string;
  completed1: number;
  completed2: number;
}

type Props = UploadVideoSectionProps & RouteComponentProps & WithTranslation;

class UploadVideoSection extends Component<Props, UploadVideoSectionState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      language: "",
      video1: "",
      video1comment: "",
      encodedVideoName1: "",
      video1IsAws: true,
      video2: "",
      video2comment: "",
      encodedVideoName2: "",
      video2IsAws: true,
      file1: null,
      file2: null,
      tabSelection: 0,
      showModal: false,
      message: "",
      notificationStatus: false,
      uploadModalOpen: false,
      product: "",
      completed1: 0,
      completed2: 0,
    };
  }

  componentDidMount = async () => {
    const token = Cookies.get("token");
    if (!token) {
      this.props.history.push("/login");
    }
    const language = GET_LANGUAGE();
    this.setState({
      language,
    });

    const { transactionId } = this.props.match.params;

    const res = await axios.get(api_address + "api/results/" + transactionId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { authData, result, status } = res.data;
    this.setState({
      product: res.data.result.product,
    });
    window.scrollTo(0, 0);
  };

  componentDidUpdate = (
    prevProps: Props,
    prevState: UploadVideoSectionState
  ) => {
    const language = GET_LANGUAGE();
    if (prevState.language !== language) {
      this.setState({ language });
    }
  };

  showNotification = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  handleSubmit = async () => {
    const { transactionId } = this.props.match.params;
    try {
      const data = {
        transactionid: transactionId,
      };
      const token = Cookies.get("token");
      const res = await axios.post(
        api_address + "api/results/videoSectionSubmit",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { status, message } = res.data;
      if (!status) {
        throw new Error(message);
      }
      if (this.state.product == "CorePro") {
        this.props.history.push("/waiting-section/" + transactionId);
      } else {
        this.props.history.push("/core-report/" + transactionId);
      }
    } catch (error: any) {
      this.showNotification(error.message, false);
    }
  };

  // Enable switching freely between tabs
  handleTabClick = (index: number) => {
    /* Remove comments to enable clicking on tabs */
    this.setState({
      tabSelection: index,
    });
  };

  displayErrorMessage = (message: string) => {
    this.setState({
      message,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  //From anywhere to instruction
  handleGoToInstructions = () => {
    this.setState({
      tabSelection: videoUploadTabs.instruction,
    });
    window.scrollTo(0, 0);
  };

  //From anywhere to setup
  handleGoToSetUp = () => {
    this.setState({
      tabSelection: videoUploadTabs.setup,
    });
    window.scrollTo(0, 0);
  };

  //From anywhere to video
  handleGoToVideo = () => {
    this.setState({
      tabSelection: videoUploadTabs.video,
    });
    window.scrollTo(0, 0);
  };

  submitButton = (videoId: number) => {
    const { t } = this.props;
    const { video1, video2, video1IsAws, video2IsAws, completed1, completed2 } =
      this.state;

    const finishedUpload =
      (video1IsAws ? completed1 === 100 : !!video1) &&
      (video2IsAws ? completed2 === 100 : !!video2);

    if (videoId === videoUploadTabs.instruction) {
      return (
        <JustifyRightDivForButtons>
          <SubmitButton onClick={this.handleGoToSetUp} disabled={false}>
            {t("user.survey.corePro.video.videoRecording.next")}
          </SubmitButton>
        </JustifyRightDivForButtons>
      );
    }

    // Next and previous buttons for Set Up page
    else if (videoId === videoUploadTabs.setup) {
      return (
        <NextAndPreviousButtonContainer>
          <JustifyLeftDivForButtons>
            <SubmitButton
              onClick={this.handleGoToInstructions}
              disabled={false}
            >
              {t("user.survey.corePro.video.videoRecording.previous")}
            </SubmitButton>
          </JustifyLeftDivForButtons>
          <JustifyRightDivForButtons>
            <SubmitButton onClick={this.handleGoToVideo} disabled={false}>
              {t("user.survey.corePro.video.videoRecording.next")}
            </SubmitButton>
          </JustifyRightDivForButtons>
        </NextAndPreviousButtonContainer>
      );

      //Video 1 Buttons
    } else {
      return (
        <NextAndPreviousButtonContainer>
          <JustifyLeftDivForButtons>
            <SubmitButton onClick={this.handleGoToSetUp} disabled={false}>
              {t("user.survey.corePro.video.videoRecording.previous")}
            </SubmitButton>
          </JustifyLeftDivForButtons>
          <JustifyRightDivForButtons>
            {
              /* This condition is commented to enable previous button in Video 2 page*/
              // Output next button depending on uploading link or video
              // If true then it is video, if false then it is link
              // video1IsAws ? (
              //   <SubmitButton onClick={this.handleGoToVideo2} disabled={false}>
              //     {t("user.survey.corePro.video.videoRecording.next")}
              //   </SubmitButton>
              // ) : (
              // )
              // video1IsAws ? (
              //   <SubmitButton onClick={this.handleGoToVideo2} disabled={false}>
              //     {t("user.survey.corePro.video.videoRecording.next")}
              //   </SubmitButton>
              // ) : (
              // <SubmitButton onClick={this.handleGoToVideo2} disabled={false}>

              //   {t("user.survey.corePro.video.videoRecording.next")}
              // </SubmitButton>
              <JustifyRightDivForButtons>
                <SubmitButton
                  onClick={this.handleSubmit}
                  disabled={!finishedUpload}
                >
                  {t("user.survey.corePro.video.videoRecording.submit")}
                </SubmitButton>
              </JustifyRightDivForButtons>
              // )
            }
          </JustifyRightDivForButtons>
        </NextAndPreviousButtonContainer>
      );
    }
  };

  setVideoStateFunc = (data) => {
    const { video1, video2, video1comment, video2comment } = this.state;
    if (!video1) this.setState({ video1: data.video1 });
    if (!video2) this.setState({ video2: data.video2 });
    this.setState({
      video1IsAws: data.video1aws,
      video2IsAws: data.video2aws,
    });
    if (!video1comment) this.setState({ video1comment: data.video1comment });
    if (!video2comment) this.setState({ video2comment: data.video2comment });
    if (data.completed1 && data.completed1 !== 0)
      this.setState({ completed1: data.completed1 });
    if (data.completed2 && data.completed2 !== 0)
      this.setState({ completed2: data.completed2 });
  };

  getVideoTabData = () => {
    const { t } = this.props;
    const {
      language,
      video1,
      video2,
      video1IsAws,
      video2IsAws,
      video1comment,
      video2comment,
    } = this.state;
    const instruction = <Instruction submitButton={this.submitButton} />;
    const setUp = (
      <SetUp submitButton={this.submitButton} language={language} />
    );
    const firstVideo = <Video1 language={""} />;
    const secondVideo = <Video2 language={language} />;
    const videoContents = [
      {
        id: 1,
        content: firstVideo,
        completed: Boolean(video1),
      },
      {
        id: 2,
        content: secondVideo,
        completed: Boolean(video2),
      },
    ];

    const video = (
      <>
        <Segment>
          <TitleIndicate />
          <Title>{t("user.survey.corePro.video.video.title")}</Title>
        </Segment>
        {videoContents.map((content) => (
          <VideoItem
            key={content.id}
            content={content}
            video1={video1}
            video2={video2}
            isAws1={video1IsAws}
            isAws2={video2IsAws}
            video1comment={video1comment}
            video2comment={video2comment}
            setVideoStateFunc={this.setVideoStateFunc}
            showNotification={this.showNotification}
            displayErrorMessage={this.displayErrorMessage}
          />
        ))}
        {this.submitButton(videoUploadTabs.video)}
      </>
    );

    const tabTitles = [
      t("user.survey.corePro.video.instruction.title"),
      t("user.survey.corePro.video.setUp.alttitle"),
      t("user.survey.corePro.video.video.title"),
    ];
    const tabContent = [instruction, setUp, video];
    const data = {
      tabTitles,
      tabContent,
    };
    return data;
  };

  render() {
    const {
      tabSelection,
      showModal,
      message,
      notificationStatus,
      language,
      product,
    } = this.state;
    const { tabTitles, tabContent } = this.getVideoTabData();
    return (
      <Background>
        <Video2Preload language={language}></Video2Preload>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />
        <EvalProgressBar step="video" product={product} />
        <Container>
          <TabMenu
            tabTitles={tabTitles}
            tabContents={tabContent}
            tabSelection={tabSelection}
            handleTabClick={this.handleTabClick}
          />
        </Container>
      </Background>
    );
  }
}

export default withRouter(withTranslation()(UploadVideoSection));
