import gql from "graphql-tag";
import { ReturnMessage } from "../types/ReturnMessage";

export const SEND_RESET_PASSWORD_INSTRUCTIONS_MUTATION = gql`
  mutation SendResetPasswordInstructions($email: String!) {
    sendResetPasswordInstructions(email: $email, isEval: true) {
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface SendResetPasswordInstructionsData {
  sendResetPasswordInstructions: {
    returnMessage: ReturnMessage;
  };
}

export interface SendResetPasswordInstructionsVariables {
  email: string;
}
