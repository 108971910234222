import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  Container,
  ModalContent,
  SubmitButton,
  Content,
  Comment,
} from "./style";

interface TableModalProps {
  data: Array<string>;
  show: boolean;
  handleClose: () => void;
}

interface States {}

type Props = TableModalProps & WithTranslation;

class TableModal extends React.Component<Props, States> {
  render() {
    const { show, data, handleClose } = this.props;
    const code = data.length > 0 ? data.reduce((a, b) => a + "\n" + b) : "";
    return (
      <Container id="ModalContainer" show={show}>
        <ModalContent id="ModalContent">
          <Content>
            <Comment disabled={true} value={code}></Comment>
            <SubmitButton
              onClick={() => {
                navigator.clipboard.writeText(code);
              }}
            >
              Copy All
            </SubmitButton>
            <SubmitButton onClick={handleClose}>Finish</SubmitButton>
          </Content>
        </ModalContent>
      </Container>
    );
  }
}

export default withTranslation()(TableModal);
