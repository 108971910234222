import styled from "styled-components";

// general
export const LanguageContainer = styled.div`
  display: flex;
  align-self: flex-end;
  flex-direction: row-reverse;
  font-size: 8px;
  margin-bottom: 8px;
  @media only screen and (min-width: 992px) {
    font-size: 10px;
    margin-bottom: 6px;
  }
`;
export const PageContainer = styled.div`
  padding: 50px 30px 80px;
  @media only screen and (min-width: 768px) {
    padding: 50px 80px 100px;
  }
`;
export const TitleContainer = styled.div`
  margin-bottom: 32px;
`;

//privacy policy
export const ContentContainer = styled.div`
  margin-bottom: 16px;
  font-family: NotoSansCJKtc;
  font-size: 12px;
  line-height: 1.5;
  color: #000000;
`;

export const IntroTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  color: #6898e3;
  margin-bottom: 16px;
`;

export const SectionTitle = styled.div`
  font-size: 12px;
  line-height: 1.21;
  color: #6898e3;
  margin-bottom: 6px;
`;

//terms conditions
export const TermsTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2;
  color: #000000;
  margin-bottom: 32px;
`;

export const TermsSection = styled.div`
  margin-bottom: 30px;
`;

export const TermsContentContainer = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const TermsIntroTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  color: #6898e3;
`;

export const TermsIndex = styled.div`
  padding-right: 10px;
  min-width: 50px;
`;

export const TermsContent = styled.div`
  font-family: NotoSansCJKtc;
  font-size: 12px;
  line-height: 1.5;
  color: #000000;
`;

export const TermsContentListContainer = styled.div`
  display: flex;
`;

export const TermsContentList = styled.div`
  font-family: NotoSansCJKtc;
  font-size: 12px;
  color: #000000;
  line-height: 22px;
`;

export const TermsContentListDescription = styled.div`
  font-family: NotoSansCJKtc;
  font-size: 12px;
  color: #000000;
  line-height: 22px;
  margin-left: 22px;
`;

export const TermsSectionTitle = styled.div`
  font-size: 14px;
  line-height: 1.21;
  color: #6898e3;
  margin-bottom: 16px;
`;
