import styled from "styled-components";

export const DialogContainer = styled.dialog<{
  display: boolean;
}>`
width:45rem;
border:none;
padding:3.2rem 0;
border-radius:10px;
display: ${(props)=> props.display ? "flex" : "none"};
flex-direction: column;
align-items: center;
line-height:normal;

&::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  
}

@media only screen and (max-width: 650px){
  width:37rem;
  padding:2.5rem 0;
}
`

export const DialogTitle = styled.div`
font-size:2.2rem;
text-align:center;
margin-bottom: 2rem;
font-weight:500;


@media only screen and (max-width: 650px){
  font-size:2rem;
  width:90%;
}
`
export const DialogSubtitle = styled.div`
text-align:center;
padding: 0 7rem;
margin-bottom: 2.5rem;
font-size:1.5rem;

@media only screen and (max-width: 650px){
  font-size:1.5rem;
  padding: 0 3.5rem;
}
`
export const DialogButton = styled.button`
border:none;
border-radius:5px;
height:4.2rem;
background: #6898E3;
color:#ffffff;
margin: 0 2rem;
padding: 0.5rem 2.5rem;
&:hover{
  cursor:pointer;
}
@media only screen and (max-width: 650px){
    padding: 0.5rem 2rem;
    margin: 0 1rem;
  }
`

export const DialogAntiActionButton = styled.button`
border:none;
border-radius:5px;
width: 10rem;
height:4.2rem;
background: #ffffff;
color:#6898E3;
margin: 0 2rem;
&:hover{
  cursor:pointer;
}
@media only screen and (max-width: 650px){
    width: 5rem;
  }
`

export const ButtonContainer = styled.div`
display:flex;
`

