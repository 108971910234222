import { Component } from "react";
import { History, LocationState } from "history";
import { withTranslation, WithTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { Icon } from "semantic-ui-react";
import { RouteSectionContainer } from "../../../styles";
import { OptionSegment, OptionTitle } from "./styles";
import { GET_TITLE_BAR } from "util/common";

interface ProfileProps {
  history: History<LocationState>;
}

interface ProfileState {}

type Props = ProfileProps & WithTranslation;

class Profile extends Component<Props, ProfileState> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    try {
      const { t } = this.props;
      document.title = GET_TITLE_BAR(t, "profile", true);
      const token = Cookies.get("token");
      if (!token) {
        this.props.history.push("/login");
      }
    } catch (err: any) {
      console.error(err.message);
      this.props.history.push("/login");
    }
  };

  render() {
    const { t } = this.props;
    return (
      <RouteSectionContainer>
        <OptionSegment
          onClick={() => this.props.history.push("/account-setting")}
        >
          <OptionTitle>{t("general.navbar.accountSetting")}</OptionTitle>
          <Icon name="chevron right" link style={{ alignSelf: "center" }} />
        </OptionSegment>
        <OptionSegment
          onClick={() => this.props.history.push("/order-history")}
        >
          <OptionTitle>{t("general.navbar.orderHistory")}</OptionTitle>
          <Icon name="chevron right" link style={{ alignSelf: "center" }} />
        </OptionSegment>
        <OptionSegment onClick={() => this.props.history.push("/FAQ")}>
          <OptionTitle>{t("general.navbar.FAQ")}</OptionTitle>
          <Icon name="chevron right" link style={{ alignSelf: "center" }} />
        </OptionSegment>
      </RouteSectionContainer>
    );
  }
}

export default withTranslation()(Profile);
