import styled from "styled-components";

export const Segment = styled.div`
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 12px 0;
  border: 1px solid #dadce0;
  border-radius: 8px;
  background-color: #fff;
`;

export const Image = styled.img`
  width: 200px;
`;

export const Header = styled.div`
  color: #202124;
  font-size: 32px;
  font-weight: 400;
  line-height: 135%;
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;
