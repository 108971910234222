import React from "react";
import RegisterForm from "../../UserAuthForm/RegisterForm";
import mixpanel from "mixpanel-browser";
import { ModalContainer, ModalMask } from "styles";
import NotificationModal from "../../../common/NotificationModal";

type Props = {
  isOpen: boolean;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCountryCodeChange: (data: any) => void;
  handleLogin: () => void;
  setIsAgreed: () => void;
  onRegisterSubmit: () => void;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  countryCode: string;
  password: string;
  isAgreed: boolean;
  notificationStatus: boolean;
  errors: string;
  showModal: boolean;
};

function RegisterModal(props: Props) {
  const onMouseEnter = () => {
    mixpanel.track("Register", {
      action: "mouse enter register form",
    });
  };

  const onMouseLeave = () => {
    mixpanel.track("Register", {
      action: "mouse leave register form",
    });
  };

  return (
    <ModalMask open={props.isOpen}>
      <ModalContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {/* NotificationModal to show errors or notifications */}
        <NotificationModal
          show={props.showModal}
          message={props.errors}
          status={props.notificationStatus}
        />
        {/* RegisterForm component to render the registration form */}
        <RegisterForm {...props} />
      </ModalContainer>
    </ModalMask>
  );
}

export default RegisterModal;
