import React from "react";
import { useTranslation } from "react-i18next";
import lock from "../assets/lock.png";
import {
  LockedModalContainer,
  LockedModalContent,
  LockedImage,
} from "./styles";

export const LockModal = () => {
  const { t } = useTranslation();
  return (
    <LockedModalContainer>
      <LockedImage src={lock} />
      <LockedModalContent>
        {t("user.survey.core.result.buyUpForUnlock")}
      </LockedModalContent>
    </LockedModalContainer>
  );
};
