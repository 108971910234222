import styled from "styled-components";

export const SubContainer = styled.div`
  display: inline-flex;
  margin-top: 20px;
  align-items: center;
  flex-wrap: wrap;
`;

export const UserContainer = styled.div`
  display: flex;
  place-items: center;
  flex-wrap: wrap;
  padding: 10px;
`;

export const User = styled.div`
  min-width: 65px;
  font-size: 20px;
  margin: 5px;
  text-align: center;
  height: 50px;
  display: grid;
  place-items: center;
  cursor: pointer;
  border: solid 1px #f9fff6;
  border-radius: 4px;
  background-color: #a8bf96;
`;
