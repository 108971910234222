import React from "react";
import axios from "axios";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { History, LocationState } from "history";
import { withTranslation, WithTranslation } from "react-i18next";
import api_address from "../../../constants/config";
import NotificationModal from "../../common/NotificationModal";
import {
  Title,
  InputArea,
  SubmitButton,
  Container,
  Description,
} from "./styles";
import { SEND_RESET_PASSWORD_INSTRUCTIONS } from "util/auth";

interface RequestResetProps {
  history: History<LocationState>;
}

interface RequestResetState {
  email: string;
  message: string;
  notificationStatus: boolean;
  showModal: boolean;
}

type Props = RequestResetProps & RouteComponentProps & WithTranslation;

class RequestReset extends React.Component<Props, RequestResetState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      message: "",
      notificationStatus: false,
      showModal: false,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  onSubmit = async () => {
    try {
      const userData = {
        email: this.state.email,
      };
      const sendResetPasswordInstructionsData =
        await SEND_RESET_PASSWORD_INSTRUCTIONS(userData);
      this.showNotification(
        sendResetPasswordInstructionsData.sendResetPasswordInstructions
          .returnMessage.message,
        true
      );
    } catch (error: any) {
      this.showNotification(error.message, false);
    }
  };

  onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.currentTarget.value });
  };

  showNotification = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  render() {
    const { t } = this.props;
    const { showModal, message, notificationStatus } = this.state;
    return (
      <Container>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />
        <Title>{t("auth.forgotPassword.title")}</Title>
        <Description>{t("auth.forgotPassword.description")}</Description>
        <InputArea
          placeholder={t("auth.forgotPassword.email")}
          onChange={this.onEmailChange}
          id="email"
          type="email"
        />
        <SubmitButton onClick={this.onSubmit}>
          {t("auth.forgotPassword.submit")}
        </SubmitButton>
      </Container>
    );
  }
}

export default withRouter(withTranslation()(RequestReset));
