import { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import api_address from "../../../constants/config";
import getSignedUrl from "../../../util/Downloader";
import Cookies from "js-cookie";
import { withTranslation, WithTranslation } from "react-i18next";
import { History, LocationState } from "history";
import { HiOutlineInformationCircle } from "react-icons/hi";
import {
  TopContainer,
  Table,
  ActionButton,
  HeaderTitleContainer,
  BodyContent,
  HeaderRow,
  BodyRow,
  HeaderText,
  ScrollContainer,
  TableContainer,
  ReportNotNeededLabel,
  TooltipVideoContainer,
  TooltipContainer,
  TooltipText,
  RecommendedViewContainer,
} from "./styles";
import VideoPlayerModal from "../../common/VideoPlayerModal";
import { PRODUCTS_WITH_REPORT, VIDEO_STATUS } from "../../../constants/common";
import { ActionTitle, UserComment } from "styles";
import StatusButton from "./component/StatusButton";
import ReactPlayer from "react-player";
import VideoDemo from "../../../components/general/assets/spilt-screen-demo.mp4";

interface TherapistProps {
  history: History<LocationState>;
}

type videoType = {
  id: number;
  childname: string;
  resultid: number;
  status: string;
  therapistId: number;
  transactionid: string;
  video1: string;
  video1comment: string;
  video1aws: boolean;
  video2: string;
  video2comment: string;
  video2aws: boolean;
  createdat: string;
  product_name: string;
};

interface TherapistState {
  videos: Array<videoType>;
  limit: number;
  offset: number;
  currentTab: string;
  totalCount: number;
  videoUrl: string;
  showVideo: boolean;
  showTooltip: boolean;
  showTooltip2: boolean;
}

type Props = TherapistProps & WithTranslation;

class Therapist extends Component<Props, TherapistState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      videos: [],
      limit: 0,
      offset: 0,
      currentTab: VIDEO_STATUS.waiting,
      totalCount: 0,
      videoUrl: "",
      showVideo: false,
      showTooltip: false,
      showTooltip2: false,
    };
  }

  componentDidMount = () => {
    this.getVideoRequest(VIDEO_STATUS.waiting);
  };

  getVideoRequest = async (status: string) => {
    try {
      const token = Cookies.get("token");
      const res = await axios.get(
        api_address + "api/video/getVideoByTherapist?status=" + status,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { videos } = res.data;
      const len = videos.length;
      this.setState({
        videos: videos,
        currentTab: status,
        totalCount: len,
      });
    } catch (err) {
      this.props.history.push("/profile");
    }
  };

  getVideo = (transactionId: string, video: string, isAWS: boolean) => {
    const url = isAWS ? getSignedUrl(transactionId, video) : video;
    this.setState({
      videoUrl: url,
      showVideo: true,
    });
  };

  videoButton = (transactionId: string, video: string, isAWS: boolean) => {
    const { t } = this.props;
    return (
      <ActionButton
        onClick={() => {
          if (isAWS) {
            this.getVideo(transactionId, video, isAWS);
          } else {
            window.open(video, "_blank");
          }
        }}
      >
        <ActionTitle>{t("therapist.casePanel.watchButton")}</ActionTitle>
      </ActionButton>
    );
  };

  resultButton = (resultId: number) => {
    const { t } = this.props;
    return (
      <Link to={"/full-result/" + resultId} target="_blank">
        <ActionButton>
          <ActionTitle>{t("therapist.casePanel.readButton")}</ActionTitle>
        </ActionButton>
      </Link>
    );
  };

  reportButton = (transactionId: string) => {
    const { t } = this.props;
    return (
      <Link to={"/core-report/" + transactionId} target="_blank">
        <ActionButton>
          <ActionTitle>{t("therapist.casePanel.readButton")}</ActionTitle>
        </ActionButton>
      </Link>
    );
  };

  previewButton = (resultId: number) => {
    const { t } = this.props;
    //Disable Read button for in review and completed sections, remove this if neccessary in the future
    return (
      // false && (
      <Link to={"/core-pro-report/" + resultId} target="_blank">
        <ActionButton>
          <ActionTitle>{t("therapist.casePanel.readButton")}</ActionTitle>
        </ActionButton>
      </Link>
      // )
    );
  };

  writeReportButton = (resultId: number) => {
    const { t } = this.props;
    return (
      <Link to={"/report-generation/" + resultId} target="_blank">
        <ActionButton>
          <ActionTitle>{t("therapist.casePanel.writeButton")}</ActionTitle>
        </ActionButton>
      </Link>
    );
  };

  reportNotNeededButton = () => {
    const { t } = this.props;
    return <ReportNotNeededLabel>N/A</ReportNotNeededLabel>;
  };

  tableData = (): JSX.Element[] => {
    const { t } = this.props;
    const { videos, currentTab } = this.state;
    return videos.map((video, index) => {
      const {
        resultid,
        childname,
        transactionid,
        video1,
        video1comment,
        video1aws,
        video2,
        video2comment,
        video2aws,
        status,
        createdat,
        product_name,
      } = video;
      const date = new Date(createdat);
      const formattedDateAndTime = date.toLocaleDateString();

      return (
        <BodyRow key={index}>
          <BodyContent>{resultid}</BodyContent>
          <BodyContent>{childname}</BodyContent>
          <BodyContent>
            {this.videoButton(transactionid, video1, video1aws)}
          </BodyContent>
          <BodyContent>
            <UserComment>{video1comment}</UserComment>
          </BodyContent>
          <BodyContent>
            {this.videoButton(transactionid, video2, video2aws)}
          </BodyContent>
          <BodyContent>
            <UserComment>{video2comment}</UserComment>
          </BodyContent>
          <BodyContent>{this.resultButton(resultid)}</BodyContent>
          <BodyContent>{this.reportButton(transactionid)}</BodyContent>
          <BodyContent>
            <UserComment>{t(`therapist.casePanel.${status}`)}</UserComment>
          </BodyContent>
          {currentTab !== VIDEO_STATUS.waiting && (
            <BodyContent>{this.previewButton(resultid)}</BodyContent>
          )}
          {currentTab !== VIDEO_STATUS.finish &&
          PRODUCTS_WITH_REPORT.includes(product_name) ? (
            <BodyContent>{this.writeReportButton(resultid)}</BodyContent>
          ) : (
            <BodyContent>{this.reportNotNeededButton()}</BodyContent>
          )}
          <BodyContent>{formattedDateAndTime}</BodyContent>
        </BodyRow>
      );
    });
  };

  closeModal = () => {
    if (this.state.showVideo) {
      this.setState({
        showVideo: false,
      });
    }
  };

  headerContent = (data: string) => {
    const { t } = this.props;
    return (
      <HeaderTitleContainer>
        <HeaderText>{t(`therapist.casePanel.${data}`)}</HeaderText>
      </HeaderTitleContainer>
    );
  };

  handleMouseEnterIcon = () => {
    this.setState({ showTooltip: true });
    console.count("enter");
  };

  handleMouseLeaveIcon = () => {
    this.setState({ showTooltip: false });
    console.count("leave");
  };

  render() {
    const { t } = this.props;
    const { currentTab, totalCount, videoUrl, showVideo, showTooltip } =
      this.state;

    return (
      <>
        <VideoPlayerModal
          show={showVideo}
          videoUrl={videoUrl}
          closeModal={this.closeModal}
        />
        <TopContainer>
          <StatusButton
            currentTab={currentTab}
            currentStatus={VIDEO_STATUS.waiting}
            totalCount={totalCount}
            getVideoRequest={this.getVideoRequest}
          />
          <StatusButton
            currentTab={currentTab}
            currentStatus={VIDEO_STATUS.pending}
            totalCount={totalCount}
            getVideoRequest={this.getVideoRequest}
          />
          <StatusButton
            currentTab={currentTab}
            currentStatus={VIDEO_STATUS.finish}
            totalCount={totalCount}
            getVideoRequest={this.getVideoRequest}
          />
        </TopContainer>
        <RecommendedViewContainer>
          <TooltipContainer display={showTooltip}>
            <TooltipText>
              {t("therapist.casePanel.tooltipText1")}
              <br />
              {t("therapist.casePanel.tooltipText2")}
            </TooltipText>
            <TooltipVideoContainer>
              <ReactPlayer
                url={VideoDemo}
                loop
                muted
                playing
                width="100%"
                height="100%"
              />
            </TooltipVideoContainer>
          </TooltipContainer>
          {t("therapist.casePanel.tooltipTitle")}
          <HiOutlineInformationCircle
            style={{ margin: "0 6px" }}
            color="#A3A3A3"
            size={20}
            onMouseEnter={this.handleMouseEnterIcon}
            onMouseLeave={this.handleMouseLeaveIcon}
          />
        </RecommendedViewContainer>
        <TableContainer>
          <ScrollContainer>
            <Table>
              <HeaderRow>
                {this.headerContent("id")}
                {this.headerContent("childname")}
                {this.headerContent("video1")}
                {this.headerContent("video1comment")}
                {this.headerContent("video2")}
                {this.headerContent("video2comment")}
                {this.headerContent("result")}
                {this.headerContent("CDEReport")}
                {this.headerContent("status")}
                {currentTab == VIDEO_STATUS.finish
                  ? this.headerContent("preview")
                  : this.headerContent("report")}
                {this.headerContent("createdat")}
              </HeaderRow>
              {this.tableData()}
            </Table>
          </ScrollContainer>
        </TableContainer>
      </>
    );
  }
}

export default withTranslation()(Therapist);
