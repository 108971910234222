import styled from "styled-components";

export const LockedModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 100;
  height: 97%;
  width: 99%;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LockedModalContent = styled.div`
  width: 100%;
  font-size: 18px;
`;

export const LockedImage = styled.img`
  width: 30px;
  margin: 5px 10px;
`;
