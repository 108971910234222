import { useTranslation } from "react-i18next";
import { ActionTitle, H2Title, Description } from "styles";
import useLockBodyScroll from "util/Hooks/useLockBodyScroll";
import {
  ValidationModalMask,
  ModalContainer,
  ValidationModalContainer,
  ValidationModalButton,
  ValidationModalHeaderContainer,
  ValidationModalContentContainer,
  ValidationModalButtonContainer,
} from "./style";

interface Props {
  show: boolean;
  handleGotoUnfinished: () => void;
  handleCloseModal: () => void;
}
const ValidationModal = ({
  show,
  handleGotoUnfinished,
  handleCloseModal,
}: Props) => {
  const { t } = useTranslation();
  useLockBodyScroll(show);
  return (
    <ModalContainer open={show}>
      <ValidationModalMask onClick={handleCloseModal}></ValidationModalMask>
      <ValidationModalContainer>
        <ValidationModalHeaderContainer>
          <H2Title>{t("therapist.reportGeneration.modal.title")}</H2Title>
        </ValidationModalHeaderContainer>
        <ValidationModalContentContainer>
          <Description>
            {t("therapist.reportGeneration.modal.content")}
          </Description>
        </ValidationModalContentContainer>
        <ValidationModalButtonContainer>
          <ValidationModalButton onClick={() => handleGotoUnfinished()}>
            <ActionTitle>
              {t("therapist.reportGeneration.modal.button")}
            </ActionTitle>
          </ValidationModalButton>
        </ValidationModalButtonContainer>
      </ValidationModalContainer>
    </ModalContainer>
  );
};

export default ValidationModal;
