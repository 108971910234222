import { Component } from "react";
import { match } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import api_address from "../../../../constants/config";
import { History, LocationState } from "history";
import NotificationModal from "../../../common/NotificationModal";
import { CURRENCY } from "../../../../constants/common";
import {
  Container,
  ContentContainer,
  ContentSubtitle,
  Input,
  SaveButton,
  CancelButton,
} from "../../styles";
import { JS_COOKIE } from "util/auth";
import { ADMIN_COOKIE_NAME } from "constants/admin";

interface Match {
  id: string;
}

interface ProductEditProps {
  match: match<Match>;
  history: History<LocationState>;
}

interface ProductEditStates {
  [key: string]: string | number | boolean;
  name: string;
  cny: string;
  usd: string;
  twd: string;
  createdAt: string;
  message: string;
  showModal: boolean;
  notificationStatus: boolean;
}

type Props = ProductEditProps & WithTranslation;
class ProductEdit extends Component<Props, ProductEditStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: "",
      cny: "",
      usd: "",
      twd: "",
      createdAt: "",
      message: "",
      showModal: false,
      notificationStatus: false,
    };
  }

  componentDidMount = async () => {
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { id } = this.props.match.params;
      const res = await axios.get(api_address + "api/admin/product/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { name, price, createdat } = res.data.product;
      const { cny, twd, usd } = price;
      this.setState({
        name,
        cny,
        twd,
        usd,
        createdAt: createdat,
      });
    } catch (err: any) {
      console.error(err.message);
    }
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    if (`${Number(value)}` === value || value === "") {
      this.setState({ [id]: value });
    }
  };

  validation = () => {
    const { cny, twd, usd } = this.state;
    if (parseInt(cny) <= 0 || cny === "") {
      throw new Error("cny price can't be less than 0");
    }
    if (parseInt(twd) <= 0 || twd === "") {
      throw new Error("twd price can't be less than 0");
    }
    if (parseInt(usd) <= 0 || usd === "") {
      throw new Error("usd price can't be less than 0");
    }
  };

  onCancelClick = () => {
    this.props.history.push("/admin/product");
  };

  onSaveClick = async () => {
    try {
      this.validation();
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { id } = this.props.match.params;
      const { twd, cny, usd } = this.state;
      const res = await axios.put(
        api_address + "api/admin/product/" + id,
        {
          twd,
          cny,
          usd,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { message, status } = res.data;
      this.showNotification(message, status);
    } catch (error: any) {
      console.error(error.message);
      this.showNotification(error.message, false);
    }
  };

  showNotification = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  render() {
    const { t } = this.props;
    const { id } = this.props.match.params;
    const { showModal, message, notificationStatus, twd, cny, usd, name } =
      this.state;
    return (
      <Container>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />

        <ContentContainer>
          <ContentSubtitle>{t("admin.product.id")}</ContentSubtitle>
          <Input disabled={true} value={id} style={{ width: "30%" }} />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.product.name")}</ContentSubtitle>
          <Input disabled={true} value={name} style={{ width: "30%" }} />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.product.externalName")}</ContentSubtitle>
          <Input
            disabled={true}
            value={t(`admin.product.${name}`)}
            style={{ width: "30%" }}
          />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.product.twd")}</ContentSubtitle>
          <Input
            onChange={this.handleInputChange}
            id={CURRENCY.twd}
            type="text"
            value={twd}
            style={{ width: "30%" }}
          />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.product.cny")}</ContentSubtitle>
          <Input
            onChange={this.handleInputChange}
            id={CURRENCY.cny}
            type="text"
            value={cny}
            style={{ width: "30%" }}
          />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.product.usd")}</ContentSubtitle>
          <Input
            onChange={this.handleInputChange}
            id={CURRENCY.usd}
            type="text"
            value={usd}
            style={{ width: "30%" }}
          />
        </ContentContainer>

        <ContentContainer>
          <CancelButton onClick={this.onCancelClick}>
            {t("admin.common.cancel")}
          </CancelButton>

          <SaveButton onClick={this.onSaveClick}>
            {t("admin.common.save")}
          </SaveButton>
        </ContentContainer>
      </Container>
    );
  }
}

export default withTranslation()(ProductEdit);
