import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  z-index: 999;
  top: 0%;
  left: 0%;
  width 100%;
  height: 100%;
  display: ${(props: { show: boolean }) => (props.show ? "grid" : "none")};
  place-items: center;
  background: rgba(0, 0, 0, 0.95);
  overscroll-behavior: contain;
  overflow: auto;
  padding-top: 100px;
`;

export const ModalContent = styled.div`
  border-radius: 15px;
  background-color: white;
  padding: 0 30px 35px 30px;
  width: 40%;
  overflow: auto;
  height: 350px;
  text-align: -webkit-center;
`;

export const Header = styled.div`
  font-size: 25px;
  margin-top: 40px;
  line-height: 30px;
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubmitButton = styled.div`
  color: black;
  font-weight: bold;
  font-size: 20px;
  margin: 0 5px;
  cursor: pointer;
  border: black solid 1px;
  width: 120px;
  border-radius: 4px;
  padding: 10px;
`;

export const Content = styled.div`
  color: #ffffff;
  font-size: 18px;
  line-height: 24px;
`;

export const Comment = styled.textarea`
  margin-top: 20px;
  height: 150px;
  resize: none;
`;
