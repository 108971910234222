import React, { Component } from "react";
import "./index.css";

class RadioItem extends Component {
  render() {
    const { value, selected, onClick } = this.props;
    return (
      <div>
        <input
          type="radio"
          id={value}
          name="radios"
          value={value}
          className="item"
          checked={selected}
          onClick={onClick}
        />
        <label for={value}>{value}</label>
      </div>
    );
  }
}

export default RadioItem;
