import styled from "styled-components";

export const DiscountTypeContainer = styled.div`
  display: flex;
  flex: 1 1 300px;
  flex-wrap: wrap;
  margin: 5px;
`;

export const ApplyAllContainer = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const OtherContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
