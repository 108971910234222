import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  IntroTitle,
  SectionTitle,
  TermsSection,
  TermsContentContainer,
  TermsIndex,
  TermsContent,
  ContentContainer,
  PageContainer,
  TitleContainer,
  LanguageContainer,
} from "../TermsConditions/styles";
import { GET_TITLE_BAR } from "util/common";
import LanguageDropdown from "../LanguageDropdown";
import { H1Title } from "styles";

interface PrivacyPolicyProps {}

interface PrivacyPolicyState {}

type Props = PrivacyPolicyProps & WithTranslation;

class PrivacyPolicy extends Component<Props, PrivacyPolicyState> {
  componentDidMount = () => {
    const { t } = this.props;
    document.title = GET_TITLE_BAR(t, "privacyPolicy", true);
  };

  render() {
    window.scrollTo(0, 0);
    const { t } = this.props;
    return (
      <PageContainer>
        <LanguageContainer>
          <LanguageDropdown path="PrivacyPolicy" color={"black"} />
        </LanguageContainer>
        <TitleContainer>
          <H1Title>{t("utils.privacy.title")}</H1Title>
        </TitleContainer>
        <TermsSection>
          <IntroTitle>{t("utils.privacy.intro.title")}</IntroTitle>
          <ContentContainer>{t("utils.privacy.intro.p1")}</ContentContainer>
          <ContentContainer>{t("utils.privacy.intro.p2")}</ContentContainer>
        </TermsSection>
        <TermsSection>
          <IntroTitle>{t("utils.privacy.definition.title")}</IntroTitle>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.definition.1.index")}</TermsIndex>
            <div>
              <SectionTitle>
                {t("utils.privacy.definition.1.title")}
              </SectionTitle>
              <TermsContent>{t("utils.privacy.definition.1.p1")}</TermsContent>
            </div>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.definition.2.index")}</TermsIndex>
            <div>
              <SectionTitle>
                {t("utils.privacy.definition.2.title")}
              </SectionTitle>
              <TermsContent>{t("utils.privacy.definition.2.p1")}</TermsContent>
            </div>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.definition.3.index")}</TermsIndex>
            <div>
              <SectionTitle>
                {t("utils.privacy.definition.3.title")}
              </SectionTitle>
              <TermsContent>{t("utils.privacy.definition.3.p1")}</TermsContent>
            </div>
          </TermsContentContainer>
        </TermsSection>
        <TermsSection>
          <IntroTitle>{t("utils.privacy.content.title")}</IntroTitle>
          <ContentContainer>
            {t("utils.privacy.content.description")}
          </ContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.content.1")}</TermsIndex>
            <TermsContent>{t("utils.privacy.content.t1")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.content.2")}</TermsIndex>
            <TermsContent>{t("utils.privacy.content.t2")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.content.3")}</TermsIndex>
            <TermsContent>{t("utils.privacy.content.t3")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.content.4")}</TermsIndex>
            <TermsContent>{t("utils.privacy.content.t4")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.content.5")}</TermsIndex>
            <TermsContent>{t("utils.privacy.content.t5")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.content.6")}</TermsIndex>
            <TermsContent>{t("utils.privacy.content.t6")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.content.7")}</TermsIndex>
            <TermsContent>{t("utils.privacy.content.t7")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.content.8")}</TermsIndex>
            <TermsContent>{t("utils.privacy.content.t8")}</TermsContent>
          </TermsContentContainer>
        </TermsSection>
        <TermsSection>
          <IntroTitle>{t("utils.privacy.section1.title")}</IntroTitle>
          <ContentContainer>{t("utils.privacy.section1.p1")}</ContentContainer>
          <ContentContainer>{t("utils.privacy.section1.p2")}</ContentContainer>
          <ContentContainer>{t("utils.privacy.section1.p3")}</ContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.section1.1.index")}</TermsIndex>
            <div>
              <SectionTitle>{t("utils.privacy.section1.1.title")}</SectionTitle>
              <TermsContent>{t("utils.privacy.section1.1.p")}</TermsContent>
            </div>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.section1.2.index")}</TermsIndex>
            <div>
              <SectionTitle>{t("utils.privacy.section1.2.title")}</SectionTitle>
              <TermsContent>{t("utils.privacy.section1.2.p")}</TermsContent>
            </div>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.section1.3.index")}</TermsIndex>
            <div>
              <SectionTitle>{t("utils.privacy.section1.3.title")}</SectionTitle>
              <TermsContent>{t("utils.privacy.section1.3.p")}</TermsContent>
            </div>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.section1.4.index")}</TermsIndex>
            <div>
              <SectionTitle>{t("utils.privacy.section1.4.title")}</SectionTitle>
              <TermsContent>{t("utils.privacy.section1.4.p")}</TermsContent>
            </div>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.section1.5.index")}</TermsIndex>
            <div>
              <div>
                <SectionTitle>
                  {t("utils.privacy.section1.5.title")}
                </SectionTitle>
                <TermsContent>{t("utils.privacy.section1.5.p")}</TermsContent>
              </div>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.1")}</TermsIndex>
                <div>
                  <SectionTitle>
                    {t("utils.privacy.section1.5-1.title")}
                  </SectionTitle>
                  <TermsContent>
                    {t("utils.privacy.section1.5-1.description")}
                  </TermsContent>
                  <TermsContentContainer>
                    <TermsIndex>{t("utils.privacy.index.a")}</TermsIndex>
                    <TermsContent>
                      {t("utils.privacy.section1.5-1.c1")}
                    </TermsContent>
                  </TermsContentContainer>
                  <TermsContentContainer>
                    <TermsIndex>{t("utils.privacy.index.b")}</TermsIndex>
                    <TermsContent>
                      {t("utils.privacy.section1.5-1.c2")}
                    </TermsContent>
                  </TermsContentContainer>
                  <TermsContentContainer>
                    <TermsIndex>{t("utils.privacy.index.c")}</TermsIndex>
                    <TermsContent>
                      {t("utils.privacy.section1.5-1.c3")}
                    </TermsContent>
                  </TermsContentContainer>
                  <TermsContentContainer>
                    <TermsIndex>{t("utils.privacy.index.d")}</TermsIndex>
                    <TermsContent>
                      {t("utils.privacy.section1.5-1.c4")}
                    </TermsContent>
                  </TermsContentContainer>
                  <TermsContentContainer>
                    <TermsIndex>{t("utils.privacy.index.e")}</TermsIndex>
                    <TermsContent>
                      {t("utils.privacy.section1.5-1.c5")}
                    </TermsContent>
                  </TermsContentContainer>
                  <TermsContentContainer>
                    <TermsIndex>{t("utils.privacy.index.f")}</TermsIndex>
                    <TermsContent>
                      {t("utils.privacy.section1.5-1.c6")}
                    </TermsContent>
                  </TermsContentContainer>
                  <TermsContentContainer>
                    <TermsIndex>{t("utils.privacy.index.g")}</TermsIndex>
                    <TermsContent>
                      {t("utils.privacy.section1.5-1.c7")}
                    </TermsContent>
                  </TermsContentContainer>
                  <TermsContentContainer>
                    <TermsIndex>{t("utils.privacy.index.h")}</TermsIndex>
                    <TermsContent>
                      {t("utils.privacy.section1.5-1.c8")}
                    </TermsContent>
                  </TermsContentContainer>
                  <TermsContentContainer>
                    <TermsIndex>{t("utils.privacy.index.i")}</TermsIndex>
                    <TermsContent>
                      {t("utils.privacy.section1.5-1.c9")}
                    </TermsContent>
                  </TermsContentContainer>
                  <TermsContentContainer>
                    <TermsIndex>{t("utils.privacy.index.j")}</TermsIndex>
                    <TermsContent>
                      {t("utils.privacy.section1.5-1.c10")}
                    </TermsContent>
                  </TermsContentContainer>
                  <TermsContentContainer>
                    <TermsIndex>{t("utils.privacy.index.k")}</TermsIndex>
                    <div>
                      <SectionTitle>
                        {t("utils.privacy.section1.5-1.c11")}
                      </SectionTitle>
                      <TermsContent>
                        {t("utils.privacy.section1.5-1.p11")}
                      </TermsContent>
                    </div>
                  </TermsContentContainer>
                </div>
              </TermsContentContainer>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.2")}</TermsIndex>
                <div>
                  <SectionTitle>
                    {t("utils.privacy.section1.5-2.title")}
                  </SectionTitle>
                  <TermsContent>
                    {t("utils.privacy.section1.5-2.p1")}
                  </TermsContent>
                </div>
              </TermsContentContainer>
            </div>
          </TermsContentContainer>
        </TermsSection>
        <TermsSection>
          <IntroTitle>{t("utils.privacy.section2.title")}</IntroTitle>
          <ContentContainer>{t("utils.privacy.section2.p1")}</ContentContainer>
        </TermsSection>
        <TermsSection>
          <IntroTitle>{t("utils.privacy.section3.title")}</IntroTitle>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.section3.1.index")}</TermsIndex>
            <div>
              <SectionTitle>{t("utils.privacy.section3.1.title")}</SectionTitle>
              <TermsContent>
                {t("utils.privacy.section3.1.description")}
              </TermsContent>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.1")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section3.1.c1")}</TermsContent>
              </TermsContentContainer>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.2")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section3.1.c2")}</TermsContent>
              </TermsContentContainer>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.3")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section3.1.c3")}</TermsContent>
              </TermsContentContainer>
            </div>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.section3.2.index")}</TermsIndex>
            <div>
              <SectionTitle>{t("utils.privacy.section3.2.title")}</SectionTitle>
              <TermsContent>
                {t("utils.privacy.section3.2.description")}
              </TermsContent>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.1")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section3.2.c1")}</TermsContent>
              </TermsContentContainer>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.2")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section3.2.c2")}</TermsContent>
              </TermsContentContainer>
            </div>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.section3.3.index")}</TermsIndex>
            <div>
              <SectionTitle>{t("utils.privacy.section3.3.title")}</SectionTitle>
              <TermsContent>
                {t("utils.privacy.section3.3.description")}
              </TermsContent>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.1")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section3.3.c1")}</TermsContent>
              </TermsContentContainer>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.2")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section3.3.c2")}</TermsContent>
              </TermsContentContainer>
            </div>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.section3.4.index")}</TermsIndex>
            <div>
              <SectionTitle>{t("utils.privacy.section3.4.title")}</SectionTitle>
              <TermsContent>
                {t("utils.privacy.section3.4.description")}
              </TermsContent>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.1")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section3.4.c1")}</TermsContent>
              </TermsContentContainer>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.2")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section3.4.c2")}</TermsContent>
              </TermsContentContainer>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.3")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section3.4.c3")}</TermsContent>
              </TermsContentContainer>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.4")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section3.4.c4")}</TermsContent>
              </TermsContentContainer>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.5")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section3.4.c5")}</TermsContent>
              </TermsContentContainer>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.6")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section3.4.c6")}</TermsContent>
              </TermsContentContainer>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.7")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section3.4.c7")}</TermsContent>
              </TermsContentContainer>
            </div>
          </TermsContentContainer>
        </TermsSection>
        <TermsSection>
          <IntroTitle>{t("utils.privacy.section4.title")}</IntroTitle>
          <ContentContainer>{t("utils.privacy.section4.p1")}</ContentContainer>
          <ContentContainer>{t("utils.privacy.section4.p2")}</ContentContainer>
          <ContentContainer>{t("utils.privacy.section4.p3")}</ContentContainer>
          <ContentContainer>{t("utils.privacy.section4.p4")}</ContentContainer>
        </TermsSection>
        <TermsSection>
          <IntroTitle>{t("utils.privacy.section5.title")}</IntroTitle>
          <TermsContent>{t("utils.privacy.section5.description")}</TermsContent>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.section5.1.index")}</TermsIndex>
            <div>
              <SectionTitle>{t("utils.privacy.section5.1.title")}</SectionTitle>
              <TermsContent>{t("utils.privacy.section5.1.p1")}</TermsContent>
            </div>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.section5.2.index")}</TermsIndex>
            <div>
              <SectionTitle>{t("utils.privacy.section5.2.title")}</SectionTitle>
              <TermsContent>
                {t("utils.privacy.section5.2.description")}
              </TermsContent>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.1")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section5.2.c1")}</TermsContent>
              </TermsContentContainer>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.2")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section5.2.c2")}</TermsContent>
              </TermsContentContainer>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.3")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section5.2.c3")}</TermsContent>
              </TermsContentContainer>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.4")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section5.2.c4")}</TermsContent>
              </TermsContentContainer>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.5")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section5.2.c5")}</TermsContent>
              </TermsContentContainer>
              <TermsContent style={{ marginTop: "16px" }}>
                {t("utils.privacy.section5.2.p6")}
              </TermsContent>
            </div>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.section5.3.index")}</TermsIndex>
            <div>
              <SectionTitle>{t("utils.privacy.section5.3.title")}</SectionTitle>
              <ContentContainer>
                {t("utils.privacy.section5.3.p1")}
              </ContentContainer>
              <ContentContainer>
                {t("utils.privacy.section5.3.description")}
              </ContentContainer>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.1")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section5.3.c1")}</TermsContent>
              </TermsContentContainer>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.2")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section5.3.c2")}</TermsContent>
              </TermsContentContainer>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.3")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section5.3.c3")}</TermsContent>
              </TermsContentContainer>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.4")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section5.3.c4")}</TermsContent>
              </TermsContentContainer>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.5")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section5.3.c5")}</TermsContent>
              </TermsContentContainer>
              <TermsContentContainer>
                <TermsIndex>{t("utils.privacy.index.6")}</TermsIndex>
                <TermsContent>{t("utils.privacy.section5.3.c6")}</TermsContent>
              </TermsContentContainer>
            </div>
          </TermsContentContainer>
        </TermsSection>
        <TermsSection>
          <IntroTitle>{t("utils.privacy.section6.title")}</IntroTitle>
          <ContentContainer>{t("utils.privacy.section6.p1")}</ContentContainer>
        </TermsSection>
        <TermsSection>
          <IntroTitle>{t("utils.privacy.section7.title")}</IntroTitle>
          <ContentContainer>
            {t("utils.privacy.section7.description")}
          </ContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.section7.1")}</TermsIndex>
            <TermsContent>{t("utils.privacy.section7.p1")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.section7.2")}</TermsIndex>
            <TermsContent>{t("utils.privacy.section7.p2")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.section7.3")}</TermsIndex>
            <TermsContent>{t("utils.privacy.section7.p3")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.privacy.section7.4")}</TermsIndex>
            <TermsContent>{t("utils.privacy.section7.p4")}</TermsContent>
          </TermsContentContainer>
        </TermsSection>
        <TermsSection>
          <IntroTitle>{t("utils.privacy.section8.title")}</IntroTitle>
          <ContentContainer>
            {t("utils.privacy.section8.description")}
          </ContentContainer>
          <ContentContainer>{t("utils.privacy.section8.p1")}</ContentContainer>
          <ContentContainer>{t("utils.privacy.section8.p2")}</ContentContainer>
        </TermsSection>
        <IntroTitle>{t("utils.privacy.lastUpdate")}</IntroTitle>
      </PageContainer>
    );
  }
}
export default withTranslation()(PrivacyPolicy);
