import { useState } from "react";
import {
  LockedContainer,
  UnlockedContainer,
  Header,
  ContentContainer,
  Content,
  Arrow,
  CollapsibleSegment,
} from "./styles";

const RadioCollapsibleContent = (props) => {
  const [isLock, setIsLock] = useState(false);
  const [segment1Open, setSegment1Open] = useState(false);
  const [segment2Open, setSegment2Open] = useState(false);

  const Container = isLock ? LockedContainer : UnlockedContainer;
  const toggling1 = () => {
    setSegment2Open(segment1Open);
    setSegment1Open(!segment1Open);
  };

  const toggling2 = () => {
    setSegment1Open(segment2Open);
    setSegment2Open(!segment2Open);
  };
  return (
    <>
      <CollapsibleSegment>
        <Container>
          <Header isOpen={segment1Open} onClick={toggling1}>
            {props.header1}
            <Arrow className="angle down" isOpen={segment1Open} />
          </Header>
          <ContentContainer isOpen={segment1Open}>
            <Content>{props.content1}</Content>
          </ContentContainer>
        </Container>
      </CollapsibleSegment>
      <CollapsibleSegment>
        <Container>
          <Header isOpen={segment2Open} onClick={toggling2}>
            {props.header2}
            <Arrow className="angle down" isOpen={segment2Open} />
          </Header>
          <ContentContainer isOpen={segment2Open}>
            <Content>{props.content2}</Content>
          </ContentContainer>
        </Container>
      </CollapsibleSegment>
    </>
  );
};
export default RadioCollapsibleContent;
