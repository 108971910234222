import { useMemo, useState, useEffect } from "react";
import {
  Container,
  ModalContent,
  Header,
  Title,
  CloseButton,
  SkillOptionTitle,
  SelectedSkillContainer,
  SelectedSkill,
  SkillOptionContainer,
  SkillOption,
  AddButton,
  TabContainer,
  Tab,
} from "./styles";
import { withTranslation, WithTranslation } from "react-i18next";
import { SKILL_MAP, SKILL_LIST } from "../../../constants/skill";

type SkillType = {
  [key: number]: string;
};

type Props = {
  show: boolean;
  selectedSkills: Array<number>;
  handleAddSkill: (skills: Array<number>) => void;
  handleClose: () => void;
} & WithTranslation;

function SkillModal(props: Props) {
  const [addedSkills, updateAddedSkills] = useState([] as Array<number>);
  const [skillOptions, updateSkillOptions] = useState([] as Array<number>);
  const [tabIndex, setTabIndex] = useState(0 as number);
  const { t, show, selectedSkills } = props;

  const skills = useMemo(() => {
    const skill = t("skill", { returnObjects: true });
    return skill;
  }, [t]) as SkillType;

  useEffect(() => {
    const options = Object.keys(skills).filter(
      (skill: string) =>
        !selectedSkills.includes(+skill) && !addedSkills.includes(+skill)
    );
    const skillOptions = options.map((skill: string) => +skill);
    updateSkillOptions([...skillOptions]);
  }, [selectedSkills, addedSkills, skills]);

  const addNewSkill = (skill: number) => {
    const newAddedSkills = addedSkills;
    newAddedSkills.push(skill);
    updateAddedSkills([...newAddedSkills.reverse()]);
  };

  const removeNewSkill = (skill: number) => {
    const newAddedSkills = addedSkills.filter(
      (addedSkill: number) => addedSkill !== skill
    );
    updateAddedSkills([...newAddedSkills]);
  };

  const handleCloseClick = () => {
    updateAddedSkills([]);
    props.handleClose();
  };

  const handleAddClick = () => {
    updateAddedSkills([]);
    props.handleAddSkill(addedSkills);
  };
  return (
    <Container show={show}>
      <ModalContent>
        <Header>
          <Title>{t("utils.skillModal.title")}</Title>
          <CloseButton onClick={handleCloseClick}>X</CloseButton>
        </Header>
        <SelectedSkillContainer>
          {addedSkills.map((skill: number) => (
            <SelectedSkill onClick={() => removeNewSkill(skill)}>
              {skills[skill] + "    ✔"}
            </SelectedSkill>
          ))}
        </SelectedSkillContainer>
        <SkillOptionTitle>{t("utils.skillModal.subtitle")}</SkillOptionTitle>
        <TabContainer>
          {SKILL_LIST.map((component: string, index: number) => {
            return (
              <Tab
                isSelected={tabIndex === index}
                onClick={() => setTabIndex(index)}
              >
                {component}
              </Tab>
            );
          })}
        </TabContainer>
        <SkillOptionContainer>
          {skillOptions.map((skill: number) => {
            return SKILL_MAP[SKILL_LIST[tabIndex]].includes(skill) ? (
              <SkillOption onClick={() => addNewSkill(skill)}>
                {skills[skill] + "  ➕"}
              </SkillOption>
            ) : (
              <></>
            );
          })}
        </SkillOptionContainer>
        <AddButton onClick={handleAddClick}>
          {t("utils.skillModal.addButton")}
        </AddButton>
      </ModalContent>
    </Container>
  );
}

export default withTranslation()(SkillModal);
