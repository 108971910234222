const countryList = [
  {
    key: "af",
    value: "af",
    text: "Afghanistan",
    countryCode: "+93",
  },
  {
    key: "al",
    value: "al",
    text: "Albania",
    countryCode: "+355",
  },
  {
    key: "dz",
    value: "dz",
    text: "Algeria",
    countryCode: "+213",
  },
  {
    key: "ad",
    value: "ad",
    text: "Andorra",
    countryCode: "+376",
  },
  {
    key: "ao",
    value: "ao",
    text: "Angola",
    countryCode: "+244",
  },
  {
    key: "ag",
    value: "ag",
    text: "Antigua and Barbuda",
    countryCode: "+1",
  },
  {
    key: "ar",
    value: "ar",
    text: "Argentina",
    countryCode: "+54",
  },
  {
    key: "am",
    value: "am",
    text: "Armenia",
    countryCode: "+374",
  },
  {
    key: "au",
    value: "au",
    text: "Australia",
    countryCode: "+61",
  },
  {
    key: "at",
    value: "at",
    text: "Austria",
    countryCode: "+43",
  },
  {
    key: "az",
    value: "az",
    text: "Azerbaijan",
    countryCode: "+994",
  },
  {
    key: "bs",
    value: "bs",
    text: "Bahamas",
    countryCode: "+1",
  },
  {
    key: "bh",
    value: "bh",
    text: "Bahrain",
    countryCode: "+973",
  },
  {
    key: "bd",
    value: "bd",
    text: "Bangladesh",
    countryCode: "+880",
  },
  {
    key: "bb",
    value: "bb",
    text: "Barbados",
    countryCode: "+1",
  },
  {
    key: "by",
    value: "by",
    text: "Belarus",
    countryCode: "+375",
  },
  {
    key: "be",
    value: "be",
    text: "Belgium",
    countryCode: "+32",
  },
  {
    key: "bz",
    value: "bz",
    text: "Belize",
    countryCode: "+501",
  },
  {
    key: "bj",
    value: "bj",
    text: "Benin",
    countryCode: "+229",
  },
  {
    key: "bt",
    value: "bt",
    text: "Bhutan",
    countryCode: "+975",
  },
  {
    key: "bo",
    value: "bo",
    text: "Bolivia",
    countryCode: "+591",
  },
  {
    key: "ba",
    value: "ba",
    text: "Bosnia and Herzegovina",
    countryCode: "+387",
  },
  {
    key: "bw",
    value: "bw",
    text: "Botswana",
    countryCode: "+267",
  },
  {
    key: "br",
    value: "br",
    text: "Brazil",
    countryCode: "+55",
  },
  {
    key: "bn",
    value: "bn",
    text: "Brunei",
    countryCode: "+673",
  },
  {
    key: "bg",
    value: "bg",
    text: "Bulgaria",
    countryCode: "+359",
  },
  {
    key: "bf",
    value: "bf",
    text: "Burkina Faso",
    countryCode: "+226",
  },
  {
    key: "bi",
    value: "bi",
    text: "Burundi",
    countryCode: "+257",
  },
  {
    key: "kh",
    value: "kh",
    text: "Cambodia",
    countryCode: "+855",
  },
  {
    key: "cm",
    value: "cm",
    text: "Cameroon",
    countryCode: "+237",
  },
  {
    key: "ca",
    value: "ca",
    text: "Canada",
    countryCode: "+1",
  },
  {
    key: "cv",
    value: "cv",
    text: "Cape Verde",
    countryCode: "+238",
  },
  {
    key: "cf",
    value: "cf",
    text: "Central African Republic",
    countryCode: "+236",
  },
  {
    key: "td",
    value: "td",
    text: "Chad",
    countryCode: "+235",
  },
  {
    key: "cl",
    value: "cl",
    text: "Chile",
    countryCode: "+56",
  },
  {
    key: "cn",
    value: "cn",
    text: "China",
    countryCode: "+86",
  },
  {
    key: "co",
    value: "co",
    text: "Colombia",
    countryCode: "+57",
  },
  {
    key: "km",
    value: "km",
    text: "Comoros",
    countryCode: "+269",
  },
  {
    key: "cg",
    value: "cg",
    text: "Congo (Brazzaville)",
    countryCode: "+242",
  },
  {
    key: "cd",
    value: "cd",
    text: "Congo (Kinshasa)",
    countryCode: "+243",
  },
  {
    key: "cr",
    value: "cr",
    text: "Costa Rica",
    countryCode: "+506",
  },
  {
    key: "hr",
    value: "hr",
    text: "Croatia",
    countryCode: "+385",
  },
  {
    key: "cu",
    value: "cu",
    text: "Cuba",
    countryCode: "+53",
  },
  {
    key: "cy",
    value: "cy",
    text: "Cyprus",
    countryCode: "+357",
  },
  {
    key: "cz",
    value: "cz",
    text: "Czech Republic",
    countryCode: "+420",
  },
  {
    key: "ci",
    value: "ci",
    text: "Côte d'Ivoire",
    countryCode: "+225",
  },
  {
    key: "dk",
    value: "dk",
    text: "Denmark",
    countryCode: "+45",
  },
  {
    key: "dj",
    value: "dj",
    text: "Djibouti",
    countryCode: "+253",
  },
  {
    key: "dm",
    value: "dm",
    text: "Dominica",
    countryCode: "+1",
  },
  {
    key: "do",
    value: "do",
    text: "Dominican Republic",
    countryCode: "+1",
  },
  {
    key: "ec",
    value: "ec",
    text: "Ecuador",
    countryCode: "+593",
  },
  {
    key: "eg",
    value: "eg",
    text: "Egypt",
    countryCode: "+20",
  },
  {
    key: "sv",
    value: "sv",
    text: "El Salvador",
    countryCode: "+503",
  },
  {
    key: "gq",
    value: "gq",
    text: "Equatorial Guinea",
    countryCode: "+240",
  },
  {
    key: "er",
    value: "er",
    text: "Eritrea",
    countryCode: "+291",
  },
  {
    key: "ee",
    value: "ee",
    text: "Estonia",
    countryCode: "+372",
  },
  {
    key: "et",
    value: "et",
    text: "Ethiopia",
    countryCode: "+251",
  },
  {
    key: "fj",
    value: "fj",
    text: "Fiji",
    countryCode: "+679",
  },
  {
    key: "fi",
    value: "fi",
    text: "Finland",
    countryCode: "+358",
  },
  {
    key: "fr",
    value: "fr",
    text: "France",
    countryCode: "+33",
  },
  {
    key: "ga",
    value: "ga",
    text: "Gabon",
    countryCode: "+241",
  },
  {
    key: "gm",
    value: "gm",
    text: "Gambia",
    countryCode: "+220",
  },
  {
    key: "ge",
    value: "ge",
    text: "Georgia",
    countryCode: "+995",
  },
  {
    key: "de",
    value: "de",
    text: "Germany",
    countryCode: "+49",
  },
  {
    key: "gh",
    value: "gh",
    text: "Ghana",
    countryCode: "+233",
  },
  {
    key: "gr",
    value: "gr",
    text: "Greece",
    countryCode: "+30",
  },
  {
    key: "gd",
    value: "gd",
    text: "Grenada",
    countryCode: "+1",
  },
  {
    key: "gt",
    value: "gt",
    text: "Guatemala",
    countryCode: "+502",
  },
  {
    key: "gn",
    value: "gn",
    text: "Guinea",
    countryCode: "+224",
  },
  {
    key: "gw",
    value: "gw",
    text: "Guinea-Bissau",
    countryCode: "+245",
  },
  {
    key: "gy",
    value: "gy",
    text: "Guyana",
    countryCode: "+592",
  },
  {
    key: "ht",
    value: "ht",
    text: "Haiti",
    countryCode: "+509",
  },
  {
    key: "hn",
    value: "hn",
    text: "Honduras",
    countryCode: "+504",
  },
  {
    key: "hu",
    value: "hu",
    text: "Hungary",
    countryCode: "+36",
  },
  {
    key: "is",
    value: "is",
    text: "Iceland",
    countryCode: "+354",
  },
  {
    key: "in",
    value: "in",
    text: "India",
    countryCode: "+91",
  },
  {
    key: "id",
    value: "id",
    text: "Indonesia",
    countryCode: "+62",
  },
  {
    key: "ir",
    value: "ir",
    text: "Iran",
    countryCode: "+98",
  },
  {
    key: "iq",
    value: "iq",
    text: "Iraq",
    countryCode: "+964",
  },
  {
    key: "ie",
    value: "ie",
    text: "Ireland",
    countryCode: "+353",
  },
  {
    key: "il",
    value: "il",
    text: "Israel",
    countryCode: "+972",
  },
  {
    key: "it",
    value: "it",
    text: "Italy",
    countryCode: "+39",
  },
  {
    key: "jm",
    value: "jm",
    text: "Jamaica",
    countryCode: "+1",
  },
  {
    key: "jp",
    value: "jp",
    text: "Japan",
    countryCode: "+81",
  },
  {
    key: "jo",
    value: "jo",
    text: "Jordan",
    countryCode: "+962",
  },
  {
    key: "kz",
    value: "kz",
    text: "Kazakhstan",
    countryCode: "+7",
  },
  {
    key: "ke",
    value: "ke",
    text: "Kenya",
    countryCode: "+254",
  },
  {
    key: "ki",
    value: "ki",
    text: "Kiribati",
    countryCode: "+686",
  },
  {
    key: "kw",
    value: "kw",
    text: "Kuwait",
    countryCode: "+965",
  },
  {
    key: "kg",
    value: "kg",
    text: "Kyrgyzstan",
    countryCode: "+996",
  },
  {
    key: "la",
    value: "la",
    text: "Laos",
    countryCode: "+856",
  },
  {
    key: "lv",
    value: "lv",
    text: "Latvia",
    countryCode: "+371",
  },
  {
    key: "lb",
    value: "lb",
    text: "Lebanon",
    countryCode: "+961",
  },
  {
    key: "ls",
    value: "ls",
    text: "Lesotho",
    countryCode: "+266",
  },
  {
    key: "lr",
    value: "lr",
    text: "Liberia",
    countryCode: "+231",
  },
  {
    key: "ly",
    value: "ly",
    text: "Libya",
    countryCode: "+218",
  },
  {
    key: "li",
    value: "li",
    text: "Liechtenstein",
    countryCode: "+423",
  },
  {
    key: "lt",
    value: "lt",
    text: "Lithuania",
    countryCode: "+370",
  },
  {
    key: "lu",
    value: "lu",
    text: "Luxembourg",
    countryCode: "+352",
  },
  {
    key: "mg",
    value: "mg",
    text: "Madagascar",
    countryCode: "+261",
  },
  {
    key: "mw",
    value: "mw",
    text: "Malawi",
    countryCode: "+265",
  },
  {
    key: "my",
    value: "my",
    text: "Malaysia",
    countryCode: "+60",
  },
  {
    key: "mv",
    value: "mv",
    text: "Maldives",
    countryCode: "+960",
  },
  {
    key: "ml",
    value: "ml",
    text: "Mali",
    countryCode: "+223",
  },
  {
    key: "mt",
    value: "mt",
    text: "Malta",
    countryCode: "+356",
  },
  {
    key: "mh",
    value: "mh",
    text: "Marshall Islands",
    countryCode: "+692",
  },
  {
    key: "mr",
    value: "mr",
    text: "Mauritania",
    countryCode: "+222",
  },
  {
    key: "mu",
    value: "mu",
    text: "Mauritius",
    countryCode: "+230",
  },
  {
    key: "mx",
    value: "mx",
    text: "Mexico",
    countryCode: "+52",
  },
  {
    key: "fm",
    value: "fm",
    text: "Micronesia",
    countryCode: "+691",
  },
  {
    key: "md",
    value: "md",
    text: "Moldova",
    countryCode: "+373",
  },
  {
    key: "mc",
    value: "mc",
    text: "Monaco",
    countryCode: "+377",
  },
  {
    key: "mn",
    value: "mn",
    text: "Mongolia",
    countryCode: "+976",
  },
  {
    key: "me",
    value: "me",
    text: "Montenegro",
    countryCode: "+382",
  },
  {
    key: "ma",
    value: "ma",
    text: "Morocco",
    countryCode: "+212",
  },
  {
    key: "mz",
    value: "mz",
    text: "Mozambique",
    countryCode: "+258",
  },
  {
    key: "mm",
    value: "mm",
    text: "Myanmar (Burma)",
    countryCode: "+95",
  },
  {
    key: "na",
    value: "na",
    text: "Namibia",
    countryCode: "+264",
  },
  {
    key: "nr",
    value: "nr",
    text: "Nauru",
    countryCode: "+674",
  },
  {
    key: "np",
    value: "np",
    text: "Nepal",
    countryCode: "+977",
  },
  {
    key: "nl",
    value: "nl",
    text: "Netherlands",
    countryCode: "+31",
  },
  {
    key: "nz",
    value: "nz",
    text: "New Zealand",
    countryCode: "+64",
  },
  {
    key: "ni",
    value: "ni",
    text: "Nicaragua",
    countryCode: "+505",
  },
  {
    key: "ne",
    value: "ne",
    text: "Niger",
    countryCode: "+227",
  },
  {
    key: "ng",
    value: "ng",
    text: "Nigeria",
    countryCode: "+234",
  },
  {
    key: "kp",
    value: "kp",
    text: "North Korea",
    countryCode: "+850",
  },
  {
    key: "no",
    value: "no",
    text: "Norway",
    countryCode: "+47",
  },
  {
    key: "om",
    value: "om",
    text: "Oman",
    countryCode: "+968",
  },
  {
    key: "pk",
    value: "pk",
    text: "Pakistan",
    countryCode: "+92",
  },
  {
    key: "pw",
    value: "pw",
    text: "Palau",
    countryCode: "+680",
  },
  {
    key: "pa",
    value: "pa",
    text: "Panama",
    countryCode: "+507",
  },
  {
    key: "pg",
    value: "pg",
    text: "Papua New Guinea",
    countryCode: "+675",
  },
  {
    key: "py",
    value: "py",
    text: "Paraguay",
    countryCode: "+595",
  },
  {
    key: "pe",
    value: "pe",
    text: "Peru",
    countryCode: "+51",
  },
  {
    key: "ph",
    value: "ph",
    text: "Philippines",
    countryCode: "+63",
  },
  {
    key: "pl",
    value: "pl",
    text: "Poland",
    countryCode: "+48",
  },
  {
    key: "pt",
    value: "pt",
    text: "Portugal",
    countryCode: "+351",
  },
  {
    key: "qa",
    value: "qa",
    text: "Qatar",
    countryCode: "+974",
  },
  {
    key: "ro",
    value: "ro",
    text: "Romania",
    countryCode: "+40",
  },
  {
    key: "ru",
    value: "ru",
    text: "Russia",
    countryCode: "+7",
  },
  {
    key: "rw",
    value: "rw",
    text: "Rwanda",
    countryCode: "+250",
  },
  {
    key: "kn",
    value: "kn",
    text: "Saint Kitts and Nevis",
    countryCode: "+1",
  },
  {
    key: "lc",
    value: "lc",
    text: "Saint Lucia",
    countryCode: "+1",
  },
  {
    key: "vc",
    value: "vc",
    text: "Saint Vincent and the Grenadines",
    countryCode: "+1",
  },
  {
    key: "ws",
    value: "ws",
    text: "Samoa",
    countryCode: "+685",
  },
  {
    key: "sm",
    value: "sm",
    text: "San Marino",
    countryCode: "+378",
  },
  {
    key: "st",
    value: "st",
    text: "São Tomé and Príncipe",
    countryCode: "+239",
  },
  {
    key: "sa",
    value: "sa",
    text: "Saudi Arabia",
    countryCode: "+966",
  },
  {
    key: "sn",
    value: "sn",
    text: "Senegal",
    countryCode: "+221",
  },
  {
    key: "rs",
    value: "rs",
    text: "Serbia",
    countryCode: "+381",
  },
  {
    key: "sc",
    value: "sc",
    text: "Seychelles",
    countryCode: "+248",
  },
  {
    key: "sl",
    value: "sl",
    text: "Sierra Leone",
    countryCode: "+232",
  },
  {
    key: "sg",
    value: "sg",
    text: "Singapore",
    countryCode: "+65",
  },
  {
    key: "sk",
    value: "sk",
    text: "Slovakia",
    countryCode: "+421",
  },
  {
    key: "si",
    value: "si",
    text: "Slovenia",
    countryCode: "+386",
  },
  {
    key: "sb",
    value: "sb",
    text: "Solomon Islands",
    countryCode: "+677",
  },
  {
    key: "so",
    value: "so",
    text: "Somalia",
    countryCode: "+252",
  },
  {
    key: "za",
    value: "za",
    text: "South Africa",
    countryCode: "+27",
  },
  {
    key: "kr",
    value: "kr",
    text: "South Korea",
    countryCode: "+82",
  },
  {
    key: "ss",
    value: "ss",
    text: "South Sudan",
    countryCode: "+211",
  },
  {
    key: "es",
    value: "es",
    text: "Spain",
    countryCode: "+34",
  },
  {
    key: "lk",
    value: "lk",
    text: "Sri Lanka",
    countryCode: "+94",
  },
  {
    key: "sd",
    value: "sd",
    text: "Sudan",
    countryCode: "+249",
  },
  {
    key: "sr",
    value: "sr",
    text: "Suriname",
    countryCode: "+597",
  },
  {
    key: "sz",
    value: "sz",
    text: "Swaziland",
    countryCode: "+268",
  },
  {
    key: "se",
    value: "se",
    text: "Sweden",
    countryCode: "+46",
  },
  {
    key: "ch",
    value: "ch",
    text: "Switzerland",
    countryCode: "+41",
  },
  {
    key: "sy",
    value: "sy",
    text: "Syria",
    countryCode: "+963",
  },
  {
    key: "tw",
    value: "tw",
    text: "Taiwan",
    countryCode: "+886",
  },
  {
    key: "tj",
    value: "tj",
    text: "Tajikistan",
    countryCode: "+992",
  },
  {
    key: "tz",
    value: "tz",
    text: "Tanzania",
    countryCode: "+255",
  },
  {
    key: "th",
    value: "th",
    text: "Thailand",
    countryCode: "+66",
  },
  {
    key: "tl",
    value: "tl",
    text: "Timor-Leste",
    countryCode: "+670",
  },
  {
    key: "tg",
    value: "tg",
    text: "Togo",
    countryCode: "+228",
  },
  {
    key: "to",
    value: "to",
    text: "Tonga",
    countryCode: "+676",
  },
  {
    key: "tt",
    value: "tt",
    text: "Trinidad and Tobago",
    countryCode: "+1",
  },
  {
    key: "tn",
    value: "tn",
    text: "Tunisia",
    countryCode: "+216",
  },
  {
    key: "tr",
    value: "tr",
    text: "Turkey",
    countryCode: "+90",
  },
  {
    key: "tm",
    value: "tm",
    text: "Turkmenistan",
    countryCode: "+993",
  },
  {
    key: "tv",
    value: "tv",
    text: "Tuvalu",
    countryCode: "+688",
  },
  {
    key: "ug",
    value: "ug",
    text: "Uganda",
    countryCode: "+256",
  },
  {
    key: "ua",
    value: "ua",
    text: "Ukraine",
    countryCode: "+380",
  },
  {
    key: "ae",
    value: "ae",
    text: "United Arab Emirates",
    countryCode: "+971",
  },
  {
    key: "gb",
    value: "gb",
    text: "United Kingdom",
    countryCode: "+44",
  },
  {
    key: "us",
    value: "us",
    text: "United States",
    countryCode: "+1",
  },
  {
    key: "uy",
    value: "uy",
    text: "Uruguay",
    countryCode: "+598",
  },
  {
    key: "uz",
    value: "uz",
    text: "Uzbekistan",
    countryCode: "+998",
  },
  {
    key: "vu",
    value: "vu",
    text: "Vanuatu",
    countryCode: "+678",
  },
  {
    key: "va",
    value: "va",
    text: "Vatican City",
    countryCode: "+39",
  },
  {
    key: "ve",
    value: "ve",
    text: "Venezuela",
    countryCode: "+58",
  },
  {
    key: "vn",
    value: "vn",
    text: "Vietnam",
    countryCode: "+84",
  },
  {
    key: "ye",
    value: "ye",
    text: "Yemen",
    countryCode: "+967",
  },
  {
    key: "zm",
    value: "zm",
    text: "Zambia",
    countryCode: "+260",
  },
  {
    key: "zw",
    value: "zw",
    text: "Zimbabwe",
    countryCode: "+263",
  },
];

export default countryList;
