import gql from "graphql-tag";

export const ADD_CHILDREN_MUTATION = gql`
  mutation AddChild(
    $childName: String!
    $dob: String!
    $gender: String!
    $therapistUuid: String!
    $uuid: String!
    $productUuid: ID!
  ) {
    addChild(
      childName: $childName
      dob: $dob
      gender: $gender
      therapistUuid: $therapistUuid
      uuid: $uuid
      productUuid: $productUuid
    ) {
      child {
        id
        name
        gender
        client {
          id
        }
      }
      returnMessage {
        status
        message
      }
    }
  }
`;
