import { Segment } from "semantic-ui-react";
import styled from "styled-components";

export const Container = styled.div`
  z-index: 999;
  position: absolute;
  background-color: white;
  text-align: -webkit-center;
  @media only screen and (min-width: 768px) {
    padding: 0 5%;
  }
  @media only screen and (min-width: 992px) {
    padding: 0 5%;
  }
  @media only screen and (min-width: 1200px) {
    padding: 0 5%;
  }
`;
export const QuestionnaireContainer = styled.div`
  @media only screen and (max-width: 575px) {
    padding: 0 2%;
  }
  @media only screen and (min-width: 576px) {
    padding: 0 10%;
  }
  @media only screen and (min-width: 768px) {
    padding: 0 15%;
  }
  @media only screen and (min-width: 992px) {
    padding: 0 20%;
  }
  @media only screen and (min-width: 1200px) {
    padding: 0 20%;
  }
`;
export const SectionContainer = styled.div`
  min-height: 570px;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  text-align: left;
`;

export const Title = styled.div`
  background-color: #6898e3;
  color: #ffffff;
  @media only screen and (max-width: 575px) {
    margin: 10px 2%;
    font-size: 16px;
    font-weight: bold;
    height: 45px;
    padding-top: 12px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    border-radius: 5px;
  }
  @media only screen and (min-width: 576px) {
    margin: 10px 10%;
    font-size: 20px;
    font-weight: bold;
    height: 55px;
    padding-top: 16px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    border-radius: 5px;
  }
  @media only screen and (min-width: 768px) {
    margin: 10px 15%;
    font-size: 22px;
    font-weight: bold;
    height: 60px;
    padding-top: 20px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    border-radius: 5px;
  }
  @media only screen and (min-width: 992px) {
    margin: 10px 20%;
    font-size: 26px;
    font-weight: bold;
    height: 70px;
    padding-top: 23px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    border-radius: 5px;
  }
  @media only screen and (min-width: 1200px) {
    margin: 10px 20%;
    font-size: 26px;
    font-weight: bold;
    height: 70px;
    padding-top: 23px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    border-radius: 5px;
  }
`;

// result summary
export const ResultSummaryContainer = styled.div`
  border-radius: 5px;
  padding: 20px 0;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  text-align: left;
  background: #fff4da;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`;

export const ResultSummaryText = styled.div`
  width: 80%;
  font-size: 16px;
  line-height: 24px;
`;

export const InformationBlueTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #6898e3;
  margin-right: 5px;
  display: inline;
`;

export const InformationContent = styled.div`
  display: inline;
`;

export const AnalysisTitle = styled.div`
  margin: 0px 0px 30px 0px;
  font-size: 16px;
  font-weight: bold;
  color: #6898e3;
`;

export const AnalysisResultAbility = styled.div`
  margin: 0px 32px 0px 0px;
  font-size: 14px;
  display: inline;
`;

export const AnalysisResultAbilityContent = styled.div`
  font-size: 14px;
  font-weight: bold;
  display: inline;
`;

export const AnalysisResultAbilityContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AnalysisContainer = styled.div`
  display: block;
  width: 85%;
`;

export const ButtonContainer = styled.div`
  margin: 15px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const HomeButtonContainer = styled(ButtonContainer)`
  margin: 0;
`;

// Activities Summary
export const SummaryGrid = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(1, 1fr);
  padding: 0;
`;
export const SummarySegment = styled(Segment)`
  cursor: pointer;
`;
export const FlexContainer = styled.div`
  display: flex;
`;
export const SummaryImage = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 4px;
  object-fit: cover;
`;
export const SummaryContainer = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SummaryMainSkill = styled.div<{ backgroundColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: max-content;
  border-radius: 15px;
  padding: 0 8px;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: white;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
export const SummaryName = styled.div`
  font-size: 16px;
  font-weight: bold;
  height: 18px;
`;
export const SummaryCheckInCount = styled.div`
  color: #00000085;
  font-weight: bold;
  font-size: 12px;
  height: 18px;
`;
