import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  ChartContainer,
  ChartResultContainer,
  ChartResultText,
  ChartBackgroundContainer,
  ChartBackgroundRangeContainer,
  ChartBackgroundRangeLine,
  ChartBackgroundRangeText,
  ChartFrontContainer,
  ChartFrontBar,
  ChartFrontBarCover,
  ResultBarHeight,
  ChartResultImg,
  Lock,
} from "./styles";
import comprehensionImg from "../assets/comprehension.png";
import expressionImg from "../assets/expression.png";
import oralImg from "../assets/oral.png";
import socialImg from "../assets/social.png";
import preLiteracyImg from "../assets/preliteracy.png";
import lock from "../assets/lock.png";
import {
  ButtonContainer,
  Segment,
  Title,
} from "components/User/Evaluation/styles";
import { GET_STATUS_BY_COLOR } from "../../../../../../util/result";

interface ResultChartProps {
  expressionRange: Array<number>;
  comprehensionRange: Array<number>;
  oralRange: Array<number>;
  socialRange: Array<number>;
  preliteracyRange: Array<number>;
  resultData: Object;
  title: string;
}

interface States {}

type Props = WithTranslation & ResultChartProps;

class ResultChart extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  resetRange = (range: number): number => {
    range = range < 78 ? range : 78;
    return range;
  };

  render() {
    const { t } = this.props;
    const {
      expressionRange,
      comprehensionRange,
      oralRange,
      socialRange,
      preliteracyRange,
      resultData,
      title,
    } = this.props;

    const expressionRawResult = resultData["expression"].rawResult;
    const comprehensionRawResult = resultData["comprehension"].rawResult;
    const socialRawResult = resultData["social"].rawResult;
    const oralRawResult = resultData["oral"].rawResult;
    const preliteracyRawResult = resultData["preliteracy"].rawResult;
    const expressionResult = resultData["expression"].analysisResult;
    const comprehensionResult = resultData["comprehension"].analysisResult;
    const socialResult = resultData["social"].analysisResult;
    const oralResult = resultData["oral"].analysisResult;
    const preliteracyResult = resultData["preliteracy"].analysisResult;
    const expressionHeight = (
      (this.resetRange(
        expressionRange[0] > 0 && expressionRange[1] > 0
          ? expressionRange[1]
          : 1
      ) *
        ResultBarHeight) /
      78
    ).toFixed();
    const comprehensionHeight = (
      (this.resetRange(
        comprehensionRange[0] > 0 && comprehensionRange[1] > 0
          ? comprehensionRange[1]
          : 1
      ) *
        ResultBarHeight) /
      78
    ).toFixed();
    const socialHeight = (
      (this.resetRange(
        socialRange[0] > 0 && socialRange[1] > 0 ? socialRange[1] : 1
      ) *
        ResultBarHeight) /
      78
    ).toFixed();
    const oralHeight = (
      (this.resetRange(
        oralRange[0] > 0 && oralRange[1] > 0 ? oralRange[1] : 1
      ) *
        ResultBarHeight) /
      78
    ).toFixed();
    const preliteracyHeight = (
      (this.resetRange(
        preliteracyRange[0] > 0 && preliteracyRange[1] > 0
          ? preliteracyRange[1]
          : 1
      ) *
        ResultBarHeight) /
      78
    ).toFixed();
    const backgroundLines = [0, 6, 12, 18, 24, 32, 40, 48, 54, 60, 66, 72]
      .reverse()
      .map((d) => {
        return (
          <ChartBackgroundRangeContainer>
            <ChartBackgroundRangeText>{d}</ChartBackgroundRangeText>
            <ChartBackgroundRangeLine />
          </ChartBackgroundRangeContainer>
        );
      });
    return (
      <>
        <Title
          style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}
        >
          {t("user.survey.core.result.chart")}
        </Title>
        <Segment>
          <Title>{title}</Title>
          <ChartContainer>
            <ChartFrontContainer>
              <ChartResultContainer>
                <ButtonContainer>
                  <ChartResultText
                    color={GET_STATUS_BY_COLOR(expressionRawResult)}
                  >
                    {expressionResult}
                  </ChartResultText>
                </ButtonContainer>
                <ChartFrontBar isOpen={expressionRawResult}>
                  {expressionRawResult ? (
                    <ChartFrontBarCover
                      color={GET_STATUS_BY_COLOR(expressionRawResult)}
                      height={expressionHeight}
                    />
                  ) : (
                    <Lock src={lock} />
                  )}
                </ChartFrontBar>
                <ButtonContainer>
                  <ChartResultImg src={expressionImg}></ChartResultImg>
                </ButtonContainer>
              </ChartResultContainer>
              <ChartResultContainer>
                <ChartResultText
                  color={GET_STATUS_BY_COLOR(comprehensionRawResult)}
                >
                  {comprehensionResult}
                </ChartResultText>
                <ChartFrontBar isOpen={comprehensionRawResult}>
                  {comprehensionRawResult ? (
                    <ChartFrontBarCover
                      color={GET_STATUS_BY_COLOR(comprehensionRawResult)}
                      height={comprehensionHeight}
                    />
                  ) : (
                    <Lock src={lock} />
                  )}
                </ChartFrontBar>
                <ButtonContainer>
                  <ChartResultImg src={comprehensionImg} />
                </ButtonContainer>
              </ChartResultContainer>
              <ChartResultContainer>
                <ChartResultText color={GET_STATUS_BY_COLOR(oralRawResult)}>
                  {oralResult}
                </ChartResultText>
                <ChartFrontBar isOpen={oralRawResult}>
                  {oralRawResult ? (
                    <ChartFrontBarCover
                      color={GET_STATUS_BY_COLOR(oralRawResult)}
                      height={oralHeight}
                    />
                  ) : (
                    <Lock src={lock} />
                  )}
                </ChartFrontBar>
                <ButtonContainer>
                  <ChartResultImg src={oralImg} />
                </ButtonContainer>
              </ChartResultContainer>
              <ChartResultContainer>
                <ChartResultText color={GET_STATUS_BY_COLOR(socialRawResult)}>
                  {socialResult}
                </ChartResultText>
                <ChartFrontBar isOpen={socialRawResult}>
                  {socialRawResult ? (
                    <ChartFrontBarCover
                      color={GET_STATUS_BY_COLOR(socialRawResult)}
                      height={socialHeight}
                    />
                  ) : (
                    <Lock src={lock} />
                  )}
                </ChartFrontBar>
                <ButtonContainer>
                  <ChartResultImg src={socialImg} />
                </ButtonContainer>
              </ChartResultContainer>
              <ChartResultContainer>
                <ChartResultText
                  color={GET_STATUS_BY_COLOR(preliteracyRawResult)}
                >
                  {preliteracyResult}
                </ChartResultText>
                <ChartFrontBar isOpen={preliteracyRawResult}>
                  {preliteracyRawResult ? (
                    <ChartFrontBarCover
                      color={GET_STATUS_BY_COLOR(preliteracyRawResult)}
                      height={preliteracyHeight}
                    />
                  ) : (
                    <Lock src={lock} />
                  )}
                </ChartFrontBar>
                <ButtonContainer>
                  <ChartResultImg src={preLiteracyImg} />
                </ButtonContainer>
              </ChartResultContainer>
            </ChartFrontContainer>
            <ChartBackgroundContainer>
              <ChartBackgroundRangeText>
                {t("user.survey.core.result.month")}
              </ChartBackgroundRangeText>
              {backgroundLines}
            </ChartBackgroundContainer>
          </ChartContainer>
        </Segment>
      </>
    );
  }
}

export default withTranslation()(ResultChart);
