import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import api_address from "../../../constants/config";
import Cookies from "js-cookie";
import { match, RouteComponentProps } from "react-router-dom";
import { GET_LANGUAGE } from "../../../util/common";
import TabMenu from "../../common/TabMenu";
import { Container } from "./styles";
import ChildInfo from "./ChildInfo";
import Questionnaire from "./Questionnaire";
import { JS_COOKIE } from "util/auth";
import { ADMIN_COOKIE_NAME } from "constants/admin";

interface FullResultProps {
  match?: match<Match>;
}

interface Match {
  userUuid: string;
}

interface childInfoType {
  childName: string;
  childBirthdayYear: string;
  childBirthdayMonth: string;
  childBirthdayDay: string;
  worryStatus: string;
  respondent: string;
  respondentOtherText: string;
  sex: string;
  birthStatus: string;
  birthStatusOtherText: string;
  postpartumStatus: object;
  postpartumStatusOther: string;
  babyHealthStatus: object;
  babyHealthStatusOther: string;
  hearingStatus: string;
  eyeStatus: string;
  familyLanguage: object;
  familyLanguageOther: string;
}

const initialChildInfo = {
  childName: "",
  childBirthdayYear: "",
  childBirthdayMonth: "",
  childBirthdayDay: "",
  worryStatus: "",
  respondent: "",
  respondentOtherText: "",
  sex: "",
  birthStatus: "",
  birthStatusOtherText: "",
  postpartumStatus: {},
  postpartumStatusOther: "",
  babyHealthStatus: {},
  babyHealthStatusOther: "",
  hearingStatus: "",
  eyeStatus: "",
  familyLanguage: {},
  familyLanguageOther: "",
};

interface componentType {
  scoreSums: Array<number>;
  ageRange: Array<number>;
  questions: Array<questionType>;
  answers: Array<Array<number>>;
  keyQuestion: Array<Array<boolean>>;
  skills: Array<Array<number>>;
}

const initialComponent = {
  scoreSums: [],
  ageRange: [],
  questions: [],
  answers: [],
  keyQuestion: [],
  skills: [],
};

interface questionType {
  en: Array<string>;
  "zh-TW": Array<string>;
  "zh-CN": Array<string>;
}

interface questionnaireType {
  comprehension: componentType;
  expression: componentType;
  oral: componentType;
  preliteracy: componentType;
  social: componentType;
}

const initialQuestionnaire = {
  comprehension: initialComponent,
  expression: initialComponent,
  oral: initialComponent,
  preliteracy: initialComponent,
  social: initialComponent,
};

interface FullResultState {
  childInfo: childInfoType;
  questionnaire: questionnaireType;
  language: string;
  selection: number;
}

type Props = FullResultProps & WithTranslation & RouteComponentProps;

class FullResult extends Component<Props, FullResultState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      childInfo: initialChildInfo,
      questionnaire: initialQuestionnaire,
      language: "",
      selection: 0,
    };
  }

  componentDidMount = async () => {
    const token = JS_COOKIE.get(ADMIN_COOKIE_NAME) || Cookies.get("token");
    const language = GET_LANGUAGE();
    const { userUuid } = this.props.match.params;
    const res = await axios.get(
      api_address + "api/results/getResultByUserUuid",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          userUuid,
        },
      }
    );
    const { childInfo, questionnaire } = res.data;
    this.setState({
      childInfo,
      questionnaire,
      language,
    });
  };

  componentDidUpdate = (prevProps: Props) => {
    if (this.props.t !== prevProps.t) {
      const language = GET_LANGUAGE();
      this.setState({
        language,
      });
    }
  };

  handleTabClick = (index: number) => {
    this.setState({
      selection: index,
    });
  };

  render() {
    const { t } = this.props;
    const { questionnaire, selection, language, childInfo } = this.state;
    const { expression, comprehension, oral, preliteracy, social } =
      questionnaire;
    const tabTitles = [
      t(`therapist.fullResult.childInfo.title`),
      t(`utils.fiveCategories.expression.shortTerm`),
      t(`utils.fiveCategories.comprehension.shortTerm`),
      t(`utils.fiveCategories.oral.shortTerm`),
      t(`utils.fiveCategories.social.shortTerm`),
      t(`utils.fiveCategories.preliteracy.shortTerm`),
    ];
    const tabContent = [
      <ChildInfo {...childInfo} />,
      <Questionnaire language={language} {...expression} />,
      <Questionnaire language={language} {...comprehension} />,
      <Questionnaire language={language} {...oral} />,
      <Questionnaire language={language} {...social} />,
      <Questionnaire language={language} {...preliteracy} />,
    ];

    return (
      <Container>
        <TabMenu
          tabTitles={tabTitles}
          tabContents={tabContent}
          selection={selection}
          handleTabClick={this.handleTabClick}
        />
      </Container>
    );
  }
}

export default withTranslation()(FullResult);
