import React, { Component, memo } from "react";
import { RedOrange } from "components/Therapist/ReportGeneration/styles";
import { withTranslation, WithTranslation } from "react-i18next";
import { ActionTitle } from "styles";
import {
  ScrollContainer,
  ProgressBarContainer,
  ProgressContainer,
  BarLine,
  SectionTitleContainer,
  CompletionStatusContainer,
  CompletionStatusTextContainer,
  StatusText,
  StepText,
  StatusBadge,
  CompletedIcon,
} from "./styles";
import {
  UnsavedIcon,
  ExclamationIcon,
} from "../../Therapist/ReportGeneration/styles";
import { Icon } from "semantic-ui-react";
interface ProgressBarMenuProps {
  tabTitles: Array<string>;
  tabContents: React.ReactNode[];
  selection: number;
  completionStatus: Array<boolean>;
  handleTabClick: (index: number) => void;
  handleSave: (isTempSave: boolean, selection: number) => void;
  unsavedStatus: boolean;
}

interface ProgressBarMenuState {
  sectionIndex: number;
  barWidth: string;
  isSticky: boolean;
}

type Props = ProgressBarMenuProps & WithTranslation;

class ProgressBarMenu extends Component<Props, ProgressBarMenuState> {
  private progressbarRef;
  constructor(props: Props) {
    super(props);
    this.progressbarRef = React.createRef();
    this.state = {
      sectionIndex: 0,
      barWidth: "",
      isSticky: false,
    };
  }

  componentDidMount = () => {
    this.loadProgressBar();
    const cachedRef = this.progressbarRef.current;
    const options = {
      threshold: 1,
    };
    const observer = new IntersectionObserver(([e]) => {
      if (e.intersectionRatio < 1) {
        this.setState({
          isSticky: true,
        });
      }
      if (e.intersectionRatio === 1) {
        this.setState({
          isSticky: false,
        });
      }
    }, options);
    observer.observe(cachedRef);
  };

  componentDidUpdate = (prevProps: Props) => {
    if (this.props !== prevProps) {
      this.loadProgressBar();
    }
  };

  loadProgressBar = () => {
    const { selection, tabTitles } = this.props;
    const steps = tabTitles;
    const offset = 12;
    const totalSectionCount = steps.length;
    const sectionIndex = selection + 1;
    const { barWidth } = this.calculatePercentage(
      sectionIndex,
      totalSectionCount,
      offset
    );
    this.setState({
      sectionIndex,
      barWidth,
    });
    const progressBar = document.getElementById(
      "ReportGenerationFormProgressBar"
    );
    if (!progressBar) {
      return;
    }
    const progressBarWidth = progressBar.scrollWidth;
    const scrollOffset =
      ((sectionIndex - 2) / totalSectionCount + (offset * 0.7) / 100) *
      progressBarWidth;
    progressBar.scrollTo({
      top: 0,
      left: scrollOffset,
      behavior: "smooth",
    });
  };

  sectionTitleText = (title: string, index: number) => {
    const { sectionIndex } = this.state;
    const { handleTabClick } = this.props;
    const isCurrentStep = sectionIndex === index + 1;
    return (
      <StepText selected={isCurrentStep}>
        <ActionTitle onClick={() => handleTabClick(index)}>
          {index + 1}
          {". "}
          {title}
        </ActionTitle>
      </StepText>
    );
  };

  completionStatusText = (status: boolean, index: number) => {
    const { t, unsavedStatus, handleSave } = this.props;
    const { sectionIndex } = this.state;
    const isCurrentStep = sectionIndex === index + 1;
    const UnsavedIndicator = (
      <>
        {isCurrentStep && unsavedStatus && (
          <UnsavedIcon
            className={"icons"}
            link
            onClick={() => handleSave(true, index)}
          >
            <Icon className={"save"}></Icon>
            <ExclamationIcon
              className={"bottom right corner exclamation circle"}
            />
          </UnsavedIcon>
        )}
      </>
    );
    const StepIndicator = (
      <>
        {status ? (
          <CompletedIcon className={"check circle"} />
        ) : (
          <StatusBadge backgroundColor={RedOrange}>
            <StatusText>{t(`therapist.reportGeneration.${status}`)}</StatusText>
          </StatusBadge>
        )}
      </>
    );
    return (
      <CompletionStatusTextContainer>
        {StepIndicator}
        {UnsavedIndicator}
      </CompletionStatusTextContainer>
    );
  };

  calculatePercentage = (
    stepIndex: number,
    totalStepCount: number,
    offset: number
  ) => {
    const percentage = (stepIndex / totalStepCount) * 100;
    const barWidth =
      (percentage === 100 ? percentage : percentage - offset) + "%";
    return { barWidth, percentage };
  };

  render() {
    const { selection, tabTitles, tabContents, completionStatus } = this.props;
    const { barWidth, isSticky } = this.state;
    return (
      <>
        <ScrollContainer
          id="ReportGenerationFormProgressBar"
          showShadow={isSticky}
        >
          <ProgressBarContainer>
            <ProgressContainer ref={this.progressbarRef}>
              <BarLine barWidth={barWidth} />
            </ProgressContainer>
            <SectionTitleContainer>
              {tabTitles.map((title: string, index: number) =>
                this.sectionTitleText(title, index)
              )}
            </SectionTitleContainer>
            <CompletionStatusContainer>
              {completionStatus.map((status: boolean, index: number) =>
                this.completionStatusText(status, index)
              )}
            </CompletionStatusContainer>
          </ProgressBarContainer>
        </ScrollContainer>
        <div>{tabContents[selection]}</div>
      </>
    );
  }
}
export default withTranslation()(memo(ProgressBarMenu));
