export const summaryBackground = {
  nofit: { backgroundColor: "#c0e7e5" },
  advanced: { backgroundColor: "#c0e7e5" },
  borderline: { backgroundColor: "#fdeabe" },
  normal: { backgroundColor: "#c0e7e5" },
  delayed: { backgroundColor: "#fbc6c1" },
};

export const videoUploadTabs = {
  instruction: 0,
  setup: 1,
  video: 2,
};
