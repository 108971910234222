import styled from "styled-components";

export const Title = styled.div`
  font-size: 30px;
  text-align: center;
`;

export const SubTitle = styled.div`
  font-size: 20px;
`;

export const Skill = styled.div`
  color: #6898e3;
`;

export const Age = styled.div`
  font-size: 18px;
  margin-top: 10px;
`;

export const Score = styled.div`
  font-size: 18px;
  margin-top: 10px;
`;

export const QuestionContainer = styled.div`
  padding: 10px 15px;
  margin-top: 10px;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #6898e3;
  padding-bottom: 10px;
`;

export const Question = styled.div`
  margin-top: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid #6898e3;
`;

export const QuestionAnswer = styled.div`
  padding: 0 10px;
  font-size: 20px;
  line-height: 20px;
  margin-top: 15px;
`;

export const QuestionTitle = styled.div<{ isKeyQuestion: boolean }>`
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  color: ${({ isKeyQuestion }) => (isKeyQuestion ? "red" : "black")};
`;

export const ComponentContainer = styled.div`
  padding: 15px;
`;
