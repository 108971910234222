import { Component, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import M from "materialize-css";
import axios from "axios";
import { withTranslation, WithTranslation } from "react-i18next";
import api_address from "../../../../../constants/config";
import { InstructionDescriptionSkipText } from "./styles";
import { recordingPromptInterface } from "./record/interface";
import RecordItem from "./record/RecordItem";
import EvalProgressBar from "../../ProgressBar/EvalProgressBar";
import { GET_TITLE_BAR } from "util/common";
import { EVAL_STEPS, EXPERT_PRODUCTS } from "../../../../../constants/common";
import {
  NextButton,
  CloseButtonContainer,
  ModalContainer,
  ModalHeader,
  ModalMask,
  ModalContent,
  ShowErrNextButton,
  HeaderTitle,
  ContentText,
  ModalContainerWithClose,
  ModalButtonContainer,
  ActionTitle,
} from "styles";
import { Icon } from "semantic-ui-react";
import {
  Container,
  Background,
  Segment,
  TitleIndicate,
  Title,
  QuestionText,
  SectionIndicate,
  OptionText,
  OrderList,
  List,
  ButtonContainer,
} from "../../styles";
import { connect } from "react-redux";
import { AppActions } from "types/actions";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { updateFinishedEvaluation } from "../../../../auth/actions";
import SkipModal from "./record/SkipModal";

interface SoundCollectionProps {
  currentstep: string;
  transactionId: string;
  product: string;
  handleUpdateCurrentStep: (data: string) => void;
}

interface SoundCollectionState {
  transactionId: string;
  skipModal: boolean;
  recordingPrompts: Array<recordingPromptInterface>;
}

type Props = SoundCollectionProps &
  WithTranslation &
  RouteComponentProps &
  LinkDispatchProps;

class SoundCollection extends Component<Props, SoundCollectionState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      transactionId: "",
      skipModal: false,
      recordingPrompts: [
        {
          key: 4,
          complete: false,
          path: "",
          text: "user.survey.core.questionnaire.sound_collection.recording.questions.9",
        },
        {
          key: 3,
          complete: false,
          path: "",
          text: "user.survey.core.questionnaire.sound_collection.recording.questions.10",
        },
        {
          key: 2,
          complete: false,
          path: "",
          text: "user.survey.core.questionnaire.sound_collection.recording.questions.11",
        },
        {
          key: 1,
          complete: false,
          path: "",
          text: "user.survey.core.questionnaire.sound_collection.recording.questions.12",
        },
      ],
    };
  }

  componentDidMount = () => {
    const { t } = this.props;
    document.title = GET_TITLE_BAR(t, "soundCollection", true);
    var elems = document.querySelectorAll("select");
    M.FormSelect.init(elems, {});
  };

  markComplete = (id: number, path: string) => {
    this.setState({
      recordingPrompts: this.state.recordingPrompts.map((eachPrompt) => {
        if (eachPrompt.key === id) {
          eachPrompt.complete = true;
          eachPrompt.path = path;
        }
        return eachPrompt;
      }),
    });
  };

  //used by submit button disable(on/off) to check if all required inputs have been inputted
  isValid = () => {
    const { recordingPrompts } = this.state;
    return recordingPrompts.filter((obj) => obj.complete === true).length >= 2;
  };

  setSkipModalOpen = () => {
    this.setState({ skipModal: true });
  };

  setSkipModalClose = () => {
    this.setState({ skipModal: false });
  };

  goToNextStep = (nextStep: string) => {
    const { product, transactionId } = this.props;
    if (
      nextStep === EVAL_STEPS.report ||
      nextStep === EVAL_STEPS.partialReport
    ) {
      if (EXPERT_PRODUCTS.includes(product)) {
        this.props.history.push(
          "/waiting-section/:transactionId" + transactionId
        );
      } else {
        this.props.history.push("/core-report/" + transactionId);
      }
    } else if (nextStep === EVAL_STEPS.video) {
      this.props.history.push("/upload-video-section/" + transactionId);
    } else {
      this.props.handleUpdateCurrentStep(nextStep);
    }
  };

  handleClickSkip = async () => {
    try {
      const { transactionId } = this.props;
      const postData = {
        transactionId: transactionId,
        values: {
          skip: true,
        },
      };
      const res = await axios.post(
        api_address + "api/results/sound_collection",
        postData
      );
      const { status, message, next, completed } = res.data;
      if (!status) {
        throw new Error(message);
      }
      if (completed) {
        this.props.onUpdateFinishedEvaluation();
      }
      this.goToNextStep(next);
    } catch (err) {
      console.error(err);
    }
  };

  handleNext = async () => {
    try {
      const { transactionId } = this.props;
      const { recordingPrompts } = this.state;

      const valid = this.isValid();

      if (valid) {
        const postData = {
          transactionId: transactionId,
          values: {
            skip: false,
          },
        };
        recordingPrompts.forEach((p: recordingPromptInterface) => {
          if (p.complete) {
            postData["values"][p.key] = p.path;
          }
        });
        const res = await axios.post(
          api_address + "api/results/sound_collection",
          postData
        );
        const { status, message, next, completed } = res.data;
        if (!status) {
          throw new Error(message);
        }
        if (completed) {
          this.props.onUpdateFinishedEvaluation();
        }

        this.goToNextStep(next);
      }
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const { currentstep, t } = this.props;
    const { skipModal, recordingPrompts, transactionId } = this.state;
    return (
      <Background>
        <EvalProgressBar step={currentstep} product={this.props.product} />
        {skipModal && (
          <SkipModal
            modalHeader={t(
              "user.survey.core.questionnaire.sound_collection.skip.title"
            )}
            modalText={t(
              "user.survey.core.questionnaire.sound_collection.skip.1"
            )}
            skipText={t(
              "user.survey.core.questionnaire.sound_collection.skip.nextButton"
            )}
            cancelText={t(
              "user.survey.core.questionnaire.sound_collection.skip.returnButton"
            )}
            onCancel={this.setSkipModalClose}
            onSkip={this.handleClickSkip}
          ></SkipModal>
        )}

        <Container>
          <Segment>
            <TitleIndicate />
            <Title>
              {t("user.survey.core.questionnaire.sound_collection.title")}
            </Title>
          </Segment>
          <Segment style={{ padding: "72px 24px 24px 24px" }}>
            <SectionIndicate>
              <QuestionText style={{ color: "white" }}>
                {t(
                  "user.survey.core.questionnaire.sound_collection.instruction.title"
                )}
              </QuestionText>
            </SectionIndicate>
            <OrderList>
              <List>
                <OptionText>
                  {t(
                    "user.survey.core.questionnaire.sound_collection.instruction.1"
                  )}
                </OptionText>
              </List>
              <List>
                <OptionText>
                  {t(
                    "user.survey.core.questionnaire.sound_collection.instruction.2"
                  )}
                </OptionText>
              </List>
              <List>
                <OptionText>
                  {t(
                    "user.survey.core.questionnaire.sound_collection.instruction.3-1"
                  )}{" "}
                  <InstructionDescriptionSkipText
                    onClick={this.setSkipModalOpen}
                  >
                    {t(
                      "user.survey.core.questionnaire.sound_collection.instruction.3-2"
                    )}
                  </InstructionDescriptionSkipText>
                </OptionText>
              </List>
              <List>
                <OptionText>
                  {t(
                    "user.survey.core.questionnaire.sound_collection.instruction.4-1"
                  )}
                </OptionText>
              </List>
              <OrderList>
                <List className="subListLowerAlpha">
                  <OptionText>
                    {t(
                      "user.survey.core.questionnaire.sound_collection.instruction.4-2"
                    )}
                  </OptionText>
                </List>
                <List className="subListLowerAlpha">
                  <OptionText>
                    {t(
                      "user.survey.core.questionnaire.sound_collection.instruction.4-3"
                    )}
                  </OptionText>
                </List>
              </OrderList>
            </OrderList>
          </Segment>
          <Segment style={{ padding: "72px 24px 24px 24px" }}>
            <SectionIndicate>
              <QuestionText style={{ color: "white" }}>
                {t(
                  "user.survey.core.questionnaire.sound_collection.recording.title"
                )}
              </QuestionText>
            </SectionIndicate>
            {recordingPrompts.map((prompt) => (
              <RecordItem
                key={prompt.key}
                prompt={prompt}
                markComplete={this.markComplete}
                transactionId={transactionId}
              />
            ))}
          </Segment>
          <ButtonContainer>
            <ShowErrNextButton
              onClick={this.handleNext}
              status={this.isValid()}
            >
              {t(
                "user.survey.core.questionnaire.sound_collection.continueButton"
              )}
            </ShowErrNextButton>
          </ButtonContainer>
        </Container>
      </Background>
    );
  }
}

interface LinkDispatchProps {
  onUpdateFinishedEvaluation: () => void;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): LinkDispatchProps => ({
  onUpdateFinishedEvaluation: bindActionCreators(
    updateFinishedEvaluation,
    dispatch
  ),
});

export default withRouter(
  connect(null, mapDispatchToProps)(withTranslation()(SoundCollection))
);
