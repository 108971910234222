import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GET_LANGUAGE } from "../../../util/common";
import { GET_IMAGES, GET_DRIVE_LINK } from "./utils";

import {
  Container,
  Header,
  Image,
  Instruction,
  InstructionContainer,
} from "./styles";

function UploadInstructions() {
  const { t } = useTranslation();
  const language = GET_LANGUAGE();
  const { signIn, upload1, upload2, upload3, share1, share2 } =
    GET_IMAGES(language);
  const link = GET_DRIVE_LINK(language);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Header>{t("user.survey.corePro.uploadInstructions.title")}</Header>
      <InstructionContainer>
        <Instruction>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {t("user.survey.corePro.uploadInstructions.signUpWebInstruction")}
          </a>
        </Instruction>
        <Instruction>
          {t("user.survey.corePro.uploadInstructions.signUpPhoneInstruction")}
        </Instruction>
        <Image src={signIn} alt="Storyline" />
      </InstructionContainer>
      <InstructionContainer>
        <Instruction>
          {t("user.survey.corePro.uploadInstructions.uploadInstruction")}
        </Instruction>
        <Image src={upload1} />
        <Image src={upload2} />
        <Image src={upload3} />
      </InstructionContainer>
      <InstructionContainer>
        <Instruction>
          {t("user.survey.corePro.uploadInstructions.shareInstruction")}
        </Instruction>
        <Image src={share1} />
        <Image src={share2} />
      </InstructionContainer>
    </Container>
  );
}

export default UploadInstructions;
