import gql from "graphql-tag";
import { User } from "../types/User";
import { ReturnMessage } from "../types/ReturnMessage";

export const REGISTER_MUTATION = gql`
  mutation Regiser(
    $email: String!
    $password: String!
    $role: String!
    $firstName: String!
    $lastName: String!
    $locale: String!
    $phone: String!
    $countryCode: String!
    $country: String!
    $requireOrganizationCode: Boolean
    $organizationCode: String
    $modularized: Boolean
    $originalToken: String
    $isOauth: Boolean
    $timeZone: String!
  ) {
    register(
      email: $email
      password: $password
      locale: $locale
      role: $role
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      countryCode: $countryCode
      country: $country
      requireOrganizationCode: $requireOrganizationCode
      organizationCode: $organizationCode
      modularized: $modularized
      originalToken: $originalToken
      isOauth: $isOauth
      timeZone: $timeZone
    ) {
      user {
        id
        uuid
        firstName
        lastName
        email
        metaType
        phoneNumber
      }
      token
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface RegisterData {
  register: {
    user?: User;
    token: string;
    returnMessage: ReturnMessage;
  };
}

export interface RegisterVariables {
  email: string;
  password: string;
  locale: string;
  role: string;
  firstName: string;
  lastName: string;
  phone: string;
  countryCode: string;
  modularized?: boolean;
  originalToken?: string;
  requireOrganizationCode?: boolean;
  organizationCode?: string;
  isOauth?: boolean;
  timeZone: string;
}
