import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { History, LocationState } from "history";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "types/actions";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { startUpdateUserLanguage } from "../../auth/actions";
import { Header, Checkbox } from "semantic-ui-react";
import {
  GET_USER_INFO,
  UPDATE_USER_SMS_NOTIFICATION,
} from "../../../util/common";
import { EDIT_USER_INFORMATION } from "../../../graphql/mutations/editUserInformation";
import { client } from "graphql/ApolloClient";
import { CheckboxContainer, Text } from "./style";
interface SmsNotificationCheckboxProps {
  path: string;
  history: History<LocationState>;
  color: string;
  handleStatus: (string, boolean) => void;
}

interface SmsNotificationCheckboxState {
  smsNotification: boolean;
  userId: number;
  userUuid: number;
}

type Props = SmsNotificationCheckboxProps &
  WithTranslation &
  LinkDispatchProps &
  RouteComponentProps;

class SmsNotificationCheckbox extends Component<
  Props,
  SmsNotificationCheckboxState
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      smsNotification: false,
      userId: 0,
      userUuid: 0,
    };
  }

  componentDidMount = () => {
    this.getUserInfo();
  };

  componentDidUpdate(
    prevProps: Props,
    prevState: SmsNotificationCheckboxState
  ) {
    if (prevState.smsNotification !== this.state.smsNotification) {
      this.getUserInfo();
    }
  }

  updateUserOnGuide = async (userUuid, smsEnabled) => {
    await client.mutate({
      mutation: EDIT_USER_INFORMATION,
      variables: {
        userUuid: userUuid,
        attribute: "SMS Notification",
        value: smsEnabled.toString(),
      },
    });
  };

  handleChange = () => {
    const smsEnabled = !this.state.smsNotification;
    this.setState({ smsNotification: smsEnabled });
    this.updateUserInfo(smsEnabled);
    this.updateUserOnGuide(this.state.userUuid, smsEnabled);
  };

  updateUserInfo = async (smsNotification) => {
    const response = await UPDATE_USER_SMS_NOTIFICATION(smsNotification);
    if (response.status) {
      console.log("success");
      this.props.handleStatus(
        this.props.t("message.account.saveSuccessful", {
          attribute: "SMS Notifications",
        }),
        response.status
      );
    } else {
      alert("error");
    }
  };

  getUserInfo = async () => {
    const userData = await GET_USER_INFO();
    this.setState({
      smsNotification: userData.smsNotification,
      userId: userData.id,
      userUuid: userData.uuid,
    });
  };

  render = () => {
    const { smsNotification } = this.state;
    const { t } = this.props;
    return (
      <Header>
        <Header.Content>
          <CheckboxContainer>
            <Checkbox onChange={this.handleChange} checked={smsNotification} />
            <Text>{t("account.smsConsentPolicy")}</Text>
          </CheckboxContainer>
        </Header.Content>
      </Header>
    );
  };
}

interface LinkDispatchProps {
  onUpdateLanguage: (data: string) => void;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): LinkDispatchProps => ({
  onUpdateLanguage: bindActionCreators(startUpdateUserLanguage, dispatch),
});

export default withRouter(
  connect(null, mapDispatchToProps)(withTranslation()(SmsNotificationCheckbox))
);
