import LanguageDropdown from "components/general/LanguageDropdown";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import { getCountryCodeList } from "../../../../constants/countryCode";
import DropDown from "../../../common/DropDown";
import api_address from "../../../../constants/config";
import axios from "axios";

import {
  AuthInputTitle,
  AuthActionText,
  InputArea,
  FormContainer,
  AuthButton,
  TermsLink,
  PhoneInput,
  PhoneInputContainer,
  AgreeInput,
  AuthContainer,
  FooterContainer,
  AuthFooterText,
  AuthActionLinkContainer,
  AuthActionLink,
  AgreementContainer,
  AuthTitle,
  ContentContainer,
  OrgName,
  TermsText,
  DivideContainer,
  DivideLine,
  OrText,
} from "../styles";
import { LanguageContainer } from "components/general/NavMenu/styles";
import { ActionTitle } from "styles";
import GoogleLoginComponent from "../GoogleLogin";
import { Dropdown } from "semantic-ui-react";
import countryList from "constants/countryList";
import { getAllTzDropDown } from "constants/timeZone";

const timeZoneList = getAllTzDropDown();

interface RegisterProps {
  handleLogin: () => void;
  handleGoogleAuth: (OAuthResponse: any) => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCountryCodeChange: (data: any) => void;
  handleCountryChange: (data: any) => void;
  handleTimeZoneChange: (data: any) => void;
  handleTimeZoneClick: (data: any) => void;
  setIsAgreed: () => void;
  onRegisterSubmit: () => void;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  countryCode: string;
  country: string;
  password: string;
  isAgreed: boolean;
  showOrganizationCodeInput?: boolean;
  organizationCode?: string;
  timeZone: string;
}

interface RegisterFormState {
  organizationExists?: boolean;
  organizationName?: string;
}
type Props = RegisterProps & WithTranslation;

class RegisterForm extends Component<Props, RegisterFormState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      organizationExists: false,
      organizationName: "",
    } as RegisterFormState;
  }

  onTermsClick = () => {
    mixpanel.track("Register", {
      action: "click terms and condition",
    });
    window.open("/terms-conditions");
  };
  onPrivacyClick = () => {
    mixpanel.track("Register", {
      action: "click privacy policy",
    });
    window.open("/privacy-policy");
  };

  onFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.currentTarget;
    mixpanel.track("Register", {
      action: "focus on " + id,
    });
  };

  onIsAgreeClick = () => {
    const { isAgreed } = this.props;
    mixpanel.track("Register", {
      action: "set isAgree",
      from: isAgreed,
      to: !isAgreed,
    });
    this.props.setIsAgreed();
  };

  onRegisterClick = () => {
    mixpanel.track("Register", {
      action: "click register button",
    });
    this.props.onRegisterSubmit();
  };

  onRegisterEnterClick = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      mixpanel.track("Register", {
        action: "click enter button to register",
      });
      this.props.onRegisterSubmit();
    }
  };

  onLoginClick = () => {
    mixpanel.track("Register", {
      action: "switch to login",
    });

    this.props.handleLogin();
  };

  componentDidMount = () => {
    if (this.props.showOrganizationCodeInput) {
      this.updateOrganizationInfo(this.props.organizationCode);
    }
  };

  componentDidUpdate = (prevProps: Props, prevState: RegisterFormState) => {
    if (prevProps.organizationCode !== this.props.organizationCode) {
      this.updateOrganizationInfo(this.props.organizationCode);
    }
  };

  updateOrganizationInfo = async (organizationCode) => {
    if (!organizationCode) {
      this.setState({ organizationExists: false, organizationName: "" });
      return;
    }

    const params = new URLSearchParams();
    params.append("organizationCode", organizationCode);
    const res = await axios.get(
      api_address + "api/organization/checkCode?" + params.toString()
    );
    if (res.status === 200) {
      const data = res.data.data;
      const { organizationExists, organizationName } = data;
      this.setState({ organizationExists, organizationName });
    } else {
      this.setState({ organizationExists: false, organizationName: "" });
    }
  };

  render() {
    const { t } = this.props;
    let {
      isAgreed,
      phone,
      email,
      firstName,
      lastName,
      countryCode,
      country,
      timeZone,
      organizationCode,
      password,
      showOrganizationCodeInput,
      handleInputChange,
      handleCountryCodeChange,
      handleCountryChange,
      handleTimeZoneChange,
      handleTimeZoneClick,
    } = this.props;

    const { organizationName } = this.state;

    const { pathname } = window.location;
    return (
      <AuthContainer>
        <ContentContainer>
          <AuthTitle>
            {pathname.includes("report")
              ? t("auth.register.reportTitle")
              : t("auth.register.title")}
            <LanguageContainer
              style={{
                float: "right",
                marginTop: "15px",
              }}
            >
              <LanguageDropdown path="register" color={"#909090"} />
            </LanguageContainer>
          </AuthTitle>
          <FormContainer noValidate>
            <AuthInputTitle>{t("auth.register.firstName")}</AuthInputTitle>
            <InputArea
              autoFocus
              onFocus={this.onFocus}
              onChange={handleInputChange}
              id="firstName"
              type="text"
              value={firstName}
              placeholder={t("auth.register.firstName")}
              onKeyDown={this.onRegisterEnterClick}
            />
            <AuthInputTitle>{t("auth.register.lastName")}</AuthInputTitle>
            <InputArea
              autoFocus
              onFocus={this.onFocus}
              onChange={handleInputChange}
              id="lastName"
              type="text"
              value={lastName}
              placeholder={t("auth.register.lastName")}
              onKeyDown={this.onRegisterEnterClick}
            />

            <AuthInputTitle>{t("auth.register.email")}</AuthInputTitle>
            <InputArea
              autoFocus
              onFocus={this.onFocus}
              onChange={handleInputChange}
              id="email"
              type="email"
              value={email}
              placeholder={t("auth.register.emailPlaceholder")}
              onKeyDown={this.onRegisterEnterClick}
            />
            <AuthInputTitle>{t("auth.register.phone")}</AuthInputTitle>
            <PhoneInputContainer>
              <DropDown
                componentName="Register"
                type="Country code"
                defaultSelection={countryCode}
                selectedText={countryCode}
                onOptionChange={handleCountryCodeChange}
                options={getCountryCodeList(t)}
              />
              <PhoneInput
                onFocus={this.onFocus}
                onChange={handleInputChange}
                id="phone"
                type="text"
                value={phone}
                onKeyDown={this.onRegisterEnterClick}
              />
            </PhoneInputContainer>
            {/* <AuthInputTitle>{t("auth.register.country")}</AuthInputTitle>
            <Dropdown
              placeholder={t("auth.login.chooseCountry")}
              fluid
              selection
              search
              onChange={handleCountryChange}
              value={country}
              options={countryList}
              style={{ width: "100%", height: "3rem", marginBottom: "2rem" }}
            /> */}
            <AuthInputTitle>{t("auth.register.password")}</AuthInputTitle>
            <InputArea
              onFocus={this.onFocus}
              onChange={handleInputChange}
              value={password}
              id="password"
              type="password"
              placeholder={t("auth.register.passwordPlaceholder")}
              onKeyDown={this.onRegisterEnterClick}
            />

            {showOrganizationCodeInput &&
              (organizationName ? (
                <>
                  <AuthInputTitle>
                    {t("auth.register.organization")}
                  </AuthInputTitle>
                  <OrgName>{organizationName}</OrgName>
                </>
              ) : (
                <>
                  <AuthInputTitle>
                    {t("auth.register.organizationCode")}
                  </AuthInputTitle>

                  <InputArea
                    onFocus={this.onFocus}
                    onChange={handleInputChange}
                    value={organizationCode}
                    id="organizationCode"
                    type="text"
                    placeholder="ORG-EXAMPLE"
                  />
                </>
              ))}

            <AgreementContainer>
              <AgreeInput
                type="checkbox"
                name="isAgreed"
                onChange={this.onIsAgreeClick}
                checked={isAgreed}
              />
              <TermsText onClick={this.onIsAgreeClick}>
                {t("auth.register.terms1")}
                <TermsLink onClick={this.onTermsClick}>
                  {t("auth.register.terms2")}
                </TermsLink>
                {t("auth.register.terms3")}
                <TermsLink onClick={this.onPrivacyClick}>
                  {t("auth.register.terms4")}
                </TermsLink>
              </TermsText>
            </AgreementContainer>
            <AuthButton onClick={this.onRegisterClick}>
              <ActionTitle>{t("auth.register.signUp")}</ActionTitle>
            </AuthButton>
            <DivideContainer>
              <DivideLine />
              <OrText>{t("auth.register.or")}</OrText>
              <DivideLine />
            </DivideContainer>
            <GoogleLoginComponent {...this} {...this.state} {...this.props} />
            <AuthActionLinkContainer>
              <AuthActionText>
                {t("auth.register.alreadyHaveAccount")}
              </AuthActionText>
              <AuthActionLink onClick={this.onLoginClick}>
                {t("auth.register.signIn")}
              </AuthActionLink>
            </AuthActionLinkContainer>
          </FormContainer>
        </ContentContainer>
        <FooterContainer>
          <AuthFooterText>
            {t("general.copyright", { year: new Date().getFullYear() })}
          </AuthFooterText>
        </FooterContainer>
      </AuthContainer>
    );
  }
}

export default withTranslation()(RegisterForm);
