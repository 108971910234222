import { useEffect, useRef, useState } from "react";
import React from "react";
import {
  DialogButton,
  DialogContainer,
  DialogSubtitle,
  DialogTitle,
} from "./styles";
import { useTranslation } from "react-i18next";

const ReportNotReadyModal = (props) => {
  //Required props: display
  const [display, setDisplay] = useState(props.display);
  const { t } = useTranslation();
  useEffect(() => {
    setDisplay(props.display);
  }, [props.display]);

  useEffect(() => {
    if (display) {
      handleOpenModal();
    } else {
      handleCloseModal();
    }
  }, [display]);

  const dialogRef = useRef<HTMLDialogElement>(null);
  const handleOpenModal = () => {
    if (dialogRef.current) {
      dialogRef.current.showModal();
    }
  };
  const handleCloseModal = () => {
    if (dialogRef.current) {
      dialogRef.current.close();
      setDisplay(false);
    }
  };

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  return (
    <DialogContainer display={display} ref={dialogRef}>
      <DialogTitle>
        {t("user.survey.corePro.waiting.ReportNotReadyModalTitle")}
      </DialogTitle>
      <DialogSubtitle>
        {t("user.survey.corePro.waiting.ReportNotReadyModalSubtitle")}
        <br />
        {t("user.survey.corePro.waiting.ReportNotReadyModalSubtitle3")}
        {t("user.survey.corePro.waiting.ReportNotReadyModalSubtitle2")}
      </DialogSubtitle>
      <DialogButton onClick={handleCloseModal}>
        {t("user.survey.corePro.waiting.ReportNotReadyModalButton1")}
      </DialogButton>
    </DialogContainer>
  );
};

export default ReportNotReadyModal;
