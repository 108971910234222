import React from "react";
import mixpanel from "mixpanel-browser";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  DropDownContainer,
  DropDownHeader,
  HeaderText,
  DropDownListContainer,
  DropDownList,
  ListItem,
  Arrow,
  Placeholder,
} from "./styles";

interface optionType {
  text: string;
  value: string;
}

type DropDownListProps = {
  option: optionType;
  index: number;
  onOptionClicked: (option: optionType) => void;
};

function DropDownListItem(props: DropDownListProps) {
  const { option, index, onOptionClicked } = props;
  const { text, value } = option;
  return (
    <ListItem onClick={() => onOptionClicked(option)} key={index} value={value}>
      {text}
    </ListItem>
  );
}

interface DropDownProps {
  size?: "small" | "medium" | "large" | "medium-adminCreateTherapistPanel";
  componentName: string;
  type: string;
  defaultSelection: string;
  selectedText: string;
  options: Array<optionType>;
  textMargin?: string | undefined;
  onOptionChange: (value: string) => void;
}

type Props = DropDownProps & WithTranslation;

interface States {
  isOpen: boolean;
  selectedOption: string;
}

class DropDown extends React.Component<Props, States> {
  private dropDownRef;
  constructor(props: Props) {
    super(props);
    this.dropDownRef = React.createRef() as React.RefObject<HTMLDivElement>;
    this.state = {
      isOpen: false,
      selectedOption: props.defaultSelection,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps !== this.props) {
      this.setState((state, props) => ({
        selectedOption: props.defaultSelection,
      }));
    }
  };

  toggling = (): void => {
    const { componentName, type } = this.props;
    const { isOpen } = this.state;
    if (!componentName.includes("admin")) {
      mixpanel.track(componentName, {
        action: (isOpen ? "close " : "open ") + type,
      });
    }
    this.setState((states) => ({
      isOpen: !states.isOpen,
    }));
  };

  onOptionClicked = (option: optionType) => {
    const { componentName, type } = this.props;
    const { value, text } = option;
    if (!componentName.includes("admin")) {
      mixpanel.track(componentName, {
        action: "change the selection of " + type,
        from: this.state.selectedOption,
        to: value,
      });
    }
    this.setState((states) => ({
      isOpen: !states.isOpen,
      selectedOption: value,
      selectedText: text,
    }));
    this.props.onOptionChange(value);
  };

  handleClickOutside = (event: MouseEvent) => {
    if (this.dropDownRef && !this.dropDownRef.current?.contains(event.target)) {
      if (this.state.isOpen) {
        this.setState({ isOpen: false });
      }
    }
  };

  render = () => {
    const { isOpen } = this.state;
    const { options, selectedText, type, t, size, textMargin } = this.props;
    return (
      <DropDownContainer id={type} size={size} ref={this.dropDownRef}>
        <DropDownHeader onClick={this.toggling}>
          {selectedText == null ? (
            <Placeholder>{t(`utils.DropDown.${type}`)}</Placeholder>
          ) : (
            <HeaderText
              margin={textMargin ? `margin-right: ${textMargin}` : ""}
            >
              {selectedText}
            </HeaderText>
          )}
          <Arrow className="angle down" isOpen={isOpen} />
        </DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              {options.map((option: optionType, index: number) => (
                <DropDownListItem
                  option={option}
                  index={index}
                  key={index}
                  onOptionClicked={this.onOptionClicked}
                />
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    );
  };
}
export default withTranslation()(DropDown);
