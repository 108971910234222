import React, { Component, useState } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import { withTranslation, WithTranslation } from "react-i18next";
import { SNOWFLAKE } from "../../../../../../util/common";
import Uploader from "../../../../../../util/uploader";
import Timer from "./Timer";
import {
  MicrophoneSVG,
  Dots,
  DotWrapper,
  Microphone,
  RecordIcon,
  RecordIconDiv,
  Vr,
  UploadIcon,
  UploadIconText,
  RecordingText,
  BlockDisplayContainer,
  MicrophoneText,
  SaveAndRecordAgainContainer,
  CentralisingDiv,
  UploadIconTextMobile,
  AudioDiv,
  RecordAgainBtnTextDiv,
  StopBtn,
} from "./styles";

import { recordingProps, progressModalProps } from "./interface";
import btnUpload from "../../assets/btnUpload.png";
import btnRecordAgain from "../../assets/btnRecordAgain.png";
import NotificationModal from "components/common/NotificationModal";
import RecordModal from "./RecordModal";

interface RecordMicrophoneState {
  record: boolean;
  pause: boolean;
  stop: boolean;
}

interface RecordState {
  microphone: RecordMicrophoneState;
  audio: string;
  rec: MicRecorder | null;
  file: File | null;
  showModal: boolean;
  errors: string;
  notificationStatus: boolean;
  recordAgainModalOpen: boolean;
  uploadModalOpen: boolean;
  closeMicrophoneIcon: boolean;
  uploadingStatus: boolean;
}

type Props = progressModalProps & recordingProps & WithTranslation;

class Record extends Component<Props, RecordState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      microphone: { record: false, pause: false, stop: false },
      audio: "",
      rec: null,
      file: null,
      showModal: false,
      notificationStatus: false,
      errors: "",

      //closeMicrophoneIcon supposed to be false by default, used for deactivating the microphone icon and text
      closeMicrophoneIcon: false,

      //Used for deactivating the "record again" and "upload" button when file is uploading
      uploadingStatus: false,

      //Modal States
      recordAgainModalOpen: false,
      uploadModalOpen: false,
    };
  }

  genRecorder = () => {
    const rec = new MicRecorder({
      bitRate: 128,
      encoder: "mp3", // default is mp3, can be wav as well
      sampleRate: 44100, // default is 44100, it can also be set to 16000 and 8000.
    });
    this.setState({ rec: rec });
    return rec;
  };

  startRecording = () => {
    const { t } = this.props;
    const { rec } = this.state;
    if (!rec) {
      this.genRecorder();
      setTimeout(() => {
        this.startRecording();
      }, 100);
      return;
    }
    rec
      .start()
      .then(() => {
        this.setState({
          microphone: { record: true, pause: false, stop: false },
          closeMicrophoneIcon: true,
        });
      })
      .catch((e) => {
        this.displayErrorMessage(
          t("user.survey.core.questionnaire.sound_collection.microphoneError")
        );
      });
  };

  pauseRecording = () => {
    const {
      rec,
      microphone: { record, pause },
    } = this.state;
    if (!rec) {
      return;
    }
    if (record) {
      this.setState({
        microphone: { record: false, pause: true, stop: false },
      });
      rec.stop();
    }
    if (pause) {
      this.setState({
        microphone: { record: true, pause: false, stop: false },
      });
      rec.start();
    }
  };

  uploadAudio = () => {
    const {
      transactionId,
      prompt,
      updateProgressModalProgress,
      updateProgressModalShowChecked,
      setProgressModalClose,
      setProgressModalOpen,
      markComplete,
    } = this.props;
    const { file, uploadingStatus } = this.state;
    // return if user didn't select any file
    if (!file) {
      return;
    }

    //Deactivates the 2 buttons
    this.setState({ uploadingStatus: true });

    const flakeId = SNOWFLAKE.gen();
    const splitFileName = file.name.split(".");
    const filenameExtension = "." + splitFileName[splitFileName.length - 1];
    const encodedFileName = flakeId + filenameExtension;

    setProgressModalOpen();

    // we can random our file name if needed
    Uploader(file, transactionId, encodedFileName)
      .on("httpUploadProgress", function (evt) {
        const uploaded = Math.round((evt.loaded / evt.total) * 100);
        updateProgressModalProgress(uploaded);
      })
      .send(function (err, data) {
        if (err) {
          setProgressModalClose();
          return;
        }
        updateProgressModalShowChecked();
        setProgressModalClose();
        setTimeout(() => {
          markComplete(prompt.key, encodedFileName);
        }, 1500);
      });
  };

  stopRecording = () => {
    const { rec } = this.state;
    if (!rec) {
      return;
    }
    this.setState({ microphone: { record: false, pause: false, stop: true } });
    rec
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, "record.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });
        const url = URL.createObjectURL(file);
        this.setState({ audio: url, file: file });
      })
      .catch((e) => {
        alert("We could not retrieve your message");
      });
  };

  displayErrorMessage = (message: string) => {
    this.setState({
      errors: message,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  // Record Again Modal Functions
  setRecordAgainModalOpen = () => {
    this.setState({ recordAgainModalOpen: true });
  };

  setRecordAgainModalClose = () => {
    this.setState({ recordAgainModalOpen: false });
  };

  recordAgainHandler = () => {
    this.setRecordAgainModalClose();
    this.startRecording();
  };

  // Upload Modal Functions
  setUploadModalOpen = () => {
    this.setState({ uploadModalOpen: true });
  };

  setUploadModalClose = () => {
    this.setState({ uploadModalOpen: false });
  };

  uploadModalHandler = () => {
    this.setUploadModalClose();
    this.uploadAudio();
  };

  render() {
    const { t } = this.props;
    const {
      rec,
      microphone: { record, stop, pause },
      showModal,
      errors,
      notificationStatus,
      closeMicrophoneIcon,
      recordAgainModalOpen,
      uploadModalOpen,
      uploadingStatus,
    } = this.state;
    return (
      <div>
        {/* TODO can be removed after all components are using styled */}
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.min.css"
        />
        {recordAgainModalOpen && (
          <RecordModal
            modalHeader={t(
              "user.survey.core.questionnaire.sound_collection.recordAgainModalHeader"
            )}
            modalText={t(
              "user.survey.core.questionnaire.sound_collection.recordAgainModalText"
            )}
            cancelText={t(
              "user.survey.core.questionnaire.sound_collection.skip.cancelButton"
            )}
            actionText={t(
              "user.survey.core.questionnaire.sound_collection.recordAgainText"
            )}
            onCancel={this.setRecordAgainModalClose}
            onAction={this.recordAgainHandler}
          ></RecordModal>
        )}

        {uploadModalOpen && (
          <RecordModal
            modalHeader={t(
              "user.survey.core.questionnaire.sound_collection.uploadModalHeader"
            )}
            modalText={t(
              "user.survey.core.questionnaire.sound_collection.uploadModalText"
            )}
            cancelText={t(
              "user.survey.core.questionnaire.sound_collection.skip.cancelButton"
            )}
            actionText={t(
              "user.survey.core.questionnaire.sound_collection.uploadButton"
            )}
            onCancel={this.setUploadModalClose}
            onAction={this.uploadModalHandler}
          ></RecordModal>
        )}

        <NotificationModal
          show={showModal}
          message={errors}
          status={notificationStatus}
        />
        <div
          style={{
            textAlign: "center",
            padding: "1% 0px 0px 0px",
            // width: '80%'
          }}
        >
          <audio
            style={{
              width: "100%",
              // This will make the audio player show only when the recording is stopped and there is a mp3 file
              display: `${rec && stop ? "" : "none"}`,
            }}
            controls
            src={this.state.audio}
          />
        </div>

        <RecordIconDiv>
          <CentralisingDiv>
            {record ? (
              <RecordIcon
                color="black"
                backgroundColor="#2AB2AC"
                borderRadius="50%"
                display="none"
                height="60px"
                width="60px"
                margin="0"
                lineHeight="inherit"
                border="1pt solid #2AB2AC"
                pointerHover={pause || record ? "none" : "inherit"}
                activeColor={pause || record ? "none" : "transparent"}
                pointerActive={pause || record ? "none" : "inherit"}
                hoverColor="#2AB2AC"
              >
                <Microphone
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="60pt"
                  height="60pt"
                  viewBox="0 -200 480 900"
                >
                  <path
                    d="M237.541,328.897c25.128,0,46.632-8.946,64.523-26.83c17.888-17.884,26.833-39.399,26.833-64.525V91.365
                        c0-25.126-8.938-46.632-26.833-64.525C284.173,8.951,262.669,0,237.541,0c-25.125,0-46.632,8.951-64.524,26.84
                        c-17.893,17.89-26.838,39.399-26.838,64.525v146.177c0,25.125,8.949,46.641,26.838,64.525
                        C190.906,319.951,212.416,328.897,237.541,328.897z"
                  />
                  <path
                    d="M396.563,188.15c-3.606-3.617-7.898-5.426-12.847-5.426c-4.944,0-9.226,1.809-12.847,5.426
                        c-3.613,3.616-5.421,7.898-5.421,12.845v36.547c0,35.214-12.518,65.333-37.548,90.362c-25.022,25.03-55.145,37.545-90.36,37.545
                        c-35.214,0-65.334-12.515-90.365-37.545c-25.028-25.022-37.541-55.147-37.541-90.362v-36.547c0-4.947-1.809-9.229-5.424-12.845
                        c-3.617-3.617-7.895-5.426-12.847-5.426c-4.952,0-9.235,1.809-12.85,5.426c-3.618,3.616-5.426,7.898-5.426,12.845v36.547
                        c0,42.065,14.04,78.659,42.112,109.776c28.073,31.118,62.762,48.961,104.068,53.526v37.691h-73.089
                        c-4.949,0-9.231,1.811-12.847,5.428c-3.617,3.614-5.426,7.898-5.426,12.847c0,4.941,1.809,9.233,5.426,12.847
                        c3.616,3.614,7.898,5.428,12.847,5.428h182.719c4.948,0,9.236-1.813,12.847-5.428c3.621-3.613,5.431-7.905,5.431-12.847
                        c0-4.948-1.81-9.232-5.431-12.847c-3.61-3.617-7.898-5.428-12.847-5.428h-73.08v-37.691
                        c41.299-4.565,75.985-22.408,104.061-53.526c28.076-31.117,42.12-67.711,42.12-109.776v-36.547
                        C401.998,196.049,400.185,191.77,396.563,188.15z"
                  />
                </Microphone>
                <DotWrapper>
                  <Dots right="0" delay="0s"></Dots>
                  <Dots right="0" delay="-0.4s"></Dots>
                  <Dots right="20px" delay="-0.2s"></Dots>
                </DotWrapper>
              </RecordIcon>
            ) : (
              <RecordIcon
                color="black"
                backgroundColor={
                  pause || record ? "rgb(204, 204, 204)" : "#2AB2AC"
                }
                borderRadius="50%"
                display={closeMicrophoneIcon ? "none" : "flex"}
                textAlign="center"
                height="55px"
                width="55px"
                margin="0"
                lineHeight="inherit"
                border={
                  pause || record
                    ? "1pt solid rgb(204, 204, 204)"
                    : "1pt solid #2AB2AC"
                }
                activeColor={pause || record ? "none" : "rgb(100, 155, 250)"}
                pointerHover={pause || record ? "none" : "inherit"}
                pointerActive={pause || record ? "none" : "inherit"}
                hoverColor="#2AB2AC"
                onClick={this.startRecording}
              >
                <MicrophoneSVG
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="55pt"
                  height="55pt"
                  viewBox="0 -200 480 900"
                >
                  <path
                    d="M237.541,328.897c25.128,0,46.632-8.946,64.523-26.83c17.888-17.884,26.833-39.399,26.833-64.525V91.365
                      c0-25.126-8.938-46.632-26.833-64.525C284.173,8.951,262.669,0,237.541,0c-25.125,0-46.632,8.951-64.524,26.84
                      c-17.893,17.89-26.838,39.399-26.838,64.525v146.177c0,25.125,8.949,46.641,26.838,64.525
                      C190.906,319.951,212.416,328.897,237.541,328.897z"
                  />
                  <path
                    d="M396.563,188.15c-3.606-3.617-7.898-5.426-12.847-5.426c-4.944,0-9.226,1.809-12.847,5.426
                        c-3.613,3.616-5.421,7.898-5.421,12.845v36.547c0,35.214-12.518,65.333-37.548,90.362c-25.022,25.03-55.145,37.545-90.36,37.545
                        c-35.214,0-65.334-12.515-90.365-37.545c-25.028-25.022-37.541-55.147-37.541-90.362v-36.547c0-4.947-1.809-9.229-5.424-12.845
                        c-3.617-3.617-7.895-5.426-12.847-5.426c-4.952,0-9.235,1.809-12.85,5.426c-3.618,3.616-5.426,7.898-5.426,12.845v36.547
                        c0,42.065,14.04,78.659,42.112,109.776c28.073,31.118,62.762,48.961,104.068,53.526v37.691h-73.089
                        c-4.949,0-9.231,1.811-12.847,5.428c-3.617,3.614-5.426,7.898-5.426,12.847c0,4.941,1.809,9.233,5.426,12.847
                        c3.616,3.614,7.898,5.428,12.847,5.428h182.719c4.948,0,9.236-1.813,12.847-5.428c3.621-3.613,5.431-7.905,5.431-12.847
                        c0-4.948-1.81-9.232-5.431-12.847c-3.61-3.617-7.898-5.428-12.847-5.428h-73.08v-37.691
                        c41.299-4.565,75.985-22.408,104.061-53.526c28.076-31.117,42.12-67.711,42.12-109.776v-36.547
                        C401.998,196.049,400.185,191.77,396.563,188.15z"
                  />
                </MicrophoneSVG>
              </RecordIcon>
            )}
          </CentralisingDiv>

          <BlockDisplayContainer display={pause || record ? "block" : "none"}>
            <RecordingText display={pause || record ? "block" : "none"}>
              {t(
                "user.survey.core.questionnaire.sound_collection.recordingText"
              )}
            </RecordingText>
            <Timer timerOn={record}></Timer>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "auto",
                width: "auto",
              }}
            >
              <RecordIcon
                className="fas fa-stop"
                paddingTop="0"
                color="#2AB2AC"
                backgroundColor={
                  pause || record ? "#2AB2AC" : "rgb(204, 204, 204)"
                }
                borderRadius="50%"
                display={pause || record ? "flex" : "none"}
                height="55px"
                width="55px"
                margin="0 0 0 0"
                fontSize="19px"
                textAlign="center"
                lineHeight="inherit"
                border={
                  pause || record
                    ? "2pt solid #2AB2AC"
                    : "2pt solid rgb(204, 204, 204)"
                }
                pointerHover={pause || record ? "inherit" : "none"}
                activeColor={pause || record ? "#2AB2AC" : "none"}
                pointerActive={pause || record ? "inherit" : "none"}
                cursor={pause || record ? "pointer" : "default"}
                hoverColor="#2AB2AC"
                onClick={this.stopRecording}
              >
                <StopBtn />
              </RecordIcon>
            </div>
          </BlockDisplayContainer>

          <SaveAndRecordAgainContainer>
            <CentralisingDiv>
              <RecordAgainBtnTextDiv
                margin={"0 0% 1% 0%"}
                style={{
                  display: "block",
                }}
              >
                <UploadIcon
                  boxShadow={
                    uploadingStatus
                      ? ""
                      : "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;"
                  }
                  src={btnRecordAgain}
                  backgroundColor={
                    uploadingStatus ? "rgb(204, 204, 204)" : "#2AB2AC"
                  }
                  border={
                    uploadingStatus
                      ? "rgb(204, 204, 204) 1px solid"
                      : "#2AB2AC 1px solid"
                  }
                  cursor={uploadingStatus ? "default" : "pointer"}
                  pointerHover={"inherit"}
                  pointerActive={"inherit"}
                  activeColor={"white"}
                  activeBackgroundColor={
                    uploadingStatus ? "rgb(204, 204, 204)" : "#2AB2AC"
                  }
                  width="150px"
                  padding="15px 25px 0 25px"
                  color="#FFFFFF"
                  display={rec && stop ? "" : "none"}
                  onClick={
                    uploadingStatus
                      ? this.setRecordAgainModalClose
                      : this.setRecordAgainModalOpen
                  }
                >
                  <UploadIconText>
                    {t(
                      "user.survey.core.questionnaire.sound_collection.recordAgainText"
                    )}
                  </UploadIconText>
                </UploadIcon>

                <UploadIconTextMobile display={rec && stop ? "" : "none"}>
                  {t(
                    "user.survey.core.questionnaire.sound_collection.recordAgainText"
                  )}
                </UploadIconTextMobile>
              </RecordAgainBtnTextDiv>

              <RecordAgainBtnTextDiv
                margin={"0 5% 1% 5%"}
                style={{
                  display: "block",
                }}
              >
                <UploadIcon
                  onClick={
                    uploadingStatus
                      ? this.setUploadModalClose
                      : this.setUploadModalOpen
                  }
                  src={btnUpload}
                  backgroundColor={
                    uploadingStatus ? "rgb(204, 204, 204)" : "#2AB2AC"
                  }
                  border={
                    uploadingStatus
                      ? "rgb(204, 204, 204) 1px solid"
                      : "#2AB2AC 1px solid"
                  }
                  cursor={uploadingStatus ? "default" : "pointer"}
                  pointerHover={"inherit"}
                  pointerActive={"inherit"}
                  activeColor={"white"}
                  activeBackgroundColor={
                    uploadingStatus ? "rgb(204, 204, 204)" : "#2AB2AC"
                  }
                  width="100px"
                  padding="15px 10px 0 10px"
                  color="#FFFFFF"
                  display={rec && stop ? "" : "none"}
                  boxShadow={
                    uploadingStatus
                      ? ""
                      : "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;"
                  }
                >
                  <UploadIconText>
                    {uploadingStatus
                      ? t(
                          "user.survey.core.questionnaire.sound_collection.uploadingButton"
                        )
                      : t(
                          "user.survey.core.questionnaire.sound_collection.saveButton"
                        )}
                  </UploadIconText>
                </UploadIcon>

                <UploadIconTextMobile
                  display={rec && stop ? "" : "none"}
                  style={{
                    paddingLeft: "15px",
                  }}
                >
                  {uploadingStatus
                    ? t(
                        "user.survey.core.questionnaire.sound_collection.uploadingButton"
                      )
                    : t(
                        "user.survey.core.questionnaire.sound_collection.saveButton"
                      )}
                </UploadIconTextMobile>
              </RecordAgainBtnTextDiv>
            </CentralisingDiv>
          </SaveAndRecordAgainContainer>
        </RecordIconDiv>

        <MicrophoneText display={closeMicrophoneIcon ? "none" : "block"}>
          {t(
            "user.survey.core.questionnaire.sound_collection.recordingClickHereText"
          )}
        </MicrophoneText>
      </div>
    );
  }
}

export default withTranslation()(Record);
