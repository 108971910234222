import {
  PopUpModalContainer,
  PopUpModalText,
  PopUpModalTitle,
  PopUpModalButton,
  ModalMask,
} from "./styles";

const RedirectToNanshanLandingPagePopUpModal = (props) => {
  return (
    <>
      {props.display && (
        <ModalMask style={{ backgroundColor: "#333333" }} open={true}>
          <PopUpModalContainer>
            <PopUpModalTitle>{props.title}</PopUpModalTitle>
            <PopUpModalText>{props.text}</PopUpModalText>
            <PopUpModalButton
              disabled={false}
              onClick={props.onClick}
              marginTop={"30px"}
              marginBottom={"0px"}
            >
              {props.buttonText}
            </PopUpModalButton>
          </PopUpModalContainer>
        </ModalMask>
      )}
    </>
  );
};

export default RedirectToNanshanLandingPagePopUpModal;
