import React from "react";
import axios from "axios";
import { withRouter, match } from "react-router-dom";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import { bindActionCreators } from "redux";
import { AppActions } from "types/actions";
import { ThunkDispatch } from "redux-thunk";
import { History, LocationState } from "history";
import api_address from "../../../constants/config";
import { startLoginSuccess, startUpdateUserToken } from "../../auth/actions";
import { withTranslation, WithTranslation } from "react-i18next";
import { ROLE } from "../../../constants/common";
import { GET_LANGUAGE } from "../../../util/common";
import { JS_COOKIE } from "util/auth";

interface ApplyCouponProps {
  history: History<LocationState>;
  match: match<Match>;
}

interface Match {
  couponcode: string;
}

interface ApplyCouponState {}

type Props = ApplyCouponProps & LinkDispatchProps & WithTranslation;

class ApplyCoupon extends React.Component<Props, ApplyCouponState> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const token = Cookies.get("token") as string;
    const couponcode = this.props.match.params.couponcode;
    const language = GET_LANGUAGE();
    const apiData = {
      code: couponcode,
      language,
    };
    let headers = {};
    if (token) {
      headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
    axios
      .post(api_address + "api/payment/payWithCoupon", apiData, headers)
      .then((res) => {
        if (res.data.status) {
          const { user } = ROLE;
          const newToken = res.data.token;
          JS_COOKIE.set("token", newToken);
          this.props.onUpdateUserToken(token);
          this.props.onLoginSuccess(user);
          this.props.history.push("/evaluation");
        } else {
          this.props.history.push("/login");
        }
      });
  };

  render() {
    return <div />;
  }
}

//satisfies return values of mapDispatchToProps
interface LinkDispatchProps {
  onLoginSuccess: (data: string) => void;
  onUpdateUserToken: (data: string) => void;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): LinkDispatchProps => ({
  onLoginSuccess: bindActionCreators(startLoginSuccess, dispatch),
  onUpdateUserToken: bindActionCreators(startUpdateUserToken, dispatch),
});

export default withRouter(
  connect(null, mapDispatchToProps)(withTranslation()(ApplyCoupon))
);
