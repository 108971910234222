import { Component } from "react";
import axios from "axios";
import { withRouter, RouteComponentProps } from "react-router-dom";
import api_address from "../../../../constants/config";
import Cookies from "js-cookie";
import { withTranslation, WithTranslation } from "react-i18next";
import { History, LocationState } from "history";
import { Icon } from "semantic-ui-react";
import { RouteSectionContainer } from "../../../../styles";
import {
  HeaderContainer,
  HeaderTitle,
  ResultsContainer,
  Result,
  NoResultText,
} from "components/User/ProfileRoute/styles";
import { GET_TITLE_BAR } from "util/common";
import Item from "./Item";
import QsItem from "./QsItem";
import ReportNotReadyModal from "components/common/ReportNotReadyModal";
import ReportReadyModal from "components/common/ReportReadyModal";
import UserAssessment from "./UserAssessment";
interface SurveyHistoryProps {
  history: History<LocationState>;
}

type steps = {
  child_info: {
    values: {
      childName: string;
    };
  };
};

type transaction = {
  id: string;
  transactionid: string;
  completed: boolean;
  product: string;
  createdat: string;
  currentstep: string;
  steps: steps;
};

interface SurveyHistoryState {
  name: string;
  userId: string;
  transactions: Array<transaction>;
  qsTransactions: any;
  userAssessments: any;
  childName: string;
  userUuid: string;
}

type Props = SurveyHistoryProps & RouteComponentProps & WithTranslation;

class SurveyHistory extends Component<Props, SurveyHistoryState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: "",
      userId: "",
      userUuid: "",
      transactions: [],
      qsTransactions: [],
      userAssessments: [],
      childName: "",
    };
  }

  componentDidMount = async () => {
    try {
      const { t } = this.props;
      document.title = GET_TITLE_BAR(t, "evaluationHistory", true);
      const token = Cookies.get("token");
      if (!token) {
        this.props.history.push("/login");
      }
      const res = await axios.get(
        api_address + "api/user/transactionAtReportStep",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { name, id } = res.data.authData;

      const accountDetails = await axios.get(api_address + "api/user/account", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { uuid } = accountDetails.data;

      const { transactions } = res.data;
      const qsRes = await axios.get(
        api_address + "api/quickScreenerResult/getQSResultByUserId",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { qsResult } = qsRes.data;

      const userAssessments = await axios.get(
        api_address + "api/user/assessments",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { userAssessmentList } = userAssessments.data;

      this.setState({
        transactions: transactions,
        qsTransactions: qsResult,
        userAssessments: userAssessmentList,
        name: name,
        userId: id,
        userUuid: uuid,
      });
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const { t } = this.props;
    const { transactions, qsTransactions, userAssessments, userUuid } =
      this.state;
    return (
      <RouteSectionContainer>
        <ReportNotReadyModal display={false} />
        <ReportReadyModal display={false} />
        <Icon
          className="chevron left"
          size="large"
          link
          circular
          onClick={() => this.props.history.push("/dashboard")}
        />
        <HeaderContainer>
          <HeaderTitle>{t("user.survey.history.title")}</HeaderTitle>
        </HeaderContainer>
        {transactions.length > 0 ||
        qsTransactions.length > 0 ||
        userAssessments.length > 0 ? (
          <ResultsContainer>
            <Result>
              {transactions.reverse().map((transaction: transaction) => (
                <Item {...transaction} />
              ))}
              {qsTransactions.map((qsTransaction) => (
                <QsItem {...qsTransaction} />
              ))}
              {userAssessments.map((assesment) => (
                <UserAssessment {...assesment} uuid={userUuid} />
              ))}
            </Result>
          </ResultsContainer>
        ) : (
          <NoResultText>{t("user.survey.history.reportNotReady")}</NoResultText>
        )}
      </RouteSectionContainer>
    );
  }
}

export default withRouter(withTranslation()(SurveyHistory));
