import { ActionTitle, SmallBadgeTitle } from "styles";
import { StatusButtonContainer, StatusNumber } from "../../styles";
import { useTranslation } from "react-i18next";

interface Props {
  currentTab: string;
  currentStatus: string;
  totalCount: number;
  getVideoRequest: (currentStatus: string) => void;
}

const StatusButton = ({
  currentTab,
  currentStatus,
  totalCount,
  getVideoRequest,
}: Props) => {
  const { t } = useTranslation();
  const isSelected = currentTab === currentStatus;
  return (
    <StatusButtonContainer
      selected={isSelected}
      onClick={() => getVideoRequest(currentStatus)}
    >
      <ActionTitle>{t(`therapist.casePanel.${currentStatus}`)}</ActionTitle>
      <StatusNumber selected={isSelected}>
        <SmallBadgeTitle>{isSelected ? totalCount : null}</SmallBadgeTitle>
      </StatusNumber>
    </StatusButtonContainer>
  );
};

export default StatusButton;
