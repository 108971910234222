import { Component } from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import Cookies from "js-cookie";
import axios from "axios";
import { History, LocationState } from "history";
import api_address from "../../../../constants/config";
import Intro from "../Intro";
import KKS from "../Intro/ModularizeIntro";

import {
  makeSelectCurrentStep,
  makeSelectStep,
  makeSelectMeta,
  makeSelectRequest,
  makeSelectLoading,
  makeIsFetchFail,
  makeIsSaveFail,
  makeIsRedirectFail,
  makeSaveResponse,
  makeSelectRedirect,
  makeSaveStepsResponse,
  makeIsNewQuestionnaire,
  makeMostRecentResultId,
  makeSelectQuestionnaireLoading,
  makeSelectResultId,
} from "./utils/selectors";

import {
  startUpdateQuestionnaireStepData,
  startUpdateQuestionnaireStep,
  startUpdateQuestionnaireMeta,
  startReset,
  startSave,
  startDisableLoading,
  startEnableLoading,
  startFetchQuestionnaire,
  startFetchMostRecentUserResult,
  startUpdateData,
  startFetchResultSuccess,
} from "./utils/actions";

import ChildInfo from "./ChildInfo";
import SoundCollection from "./SoundCollection";
import Questions from "./Questionnaire";

import {
  makeIsUserLoggedIn,
  makeSelectLanguage,
} from "../../../auth/selectors";

import { startUpdateUserToken } from "../../../auth/actions";
import { AppState } from "storeConfig";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "types/actions";
import { bindActionCreators } from "redux";
import {
  AutoSaveButton,
  SaveIcon,
} from "components/Therapist/ReportGeneration/styles";
import { t } from "i18next";
import { ActionTitle } from "styles";
import { PRODUCTS } from "constants/common";

interface CoreProps {
  history: History<LocationState>;
}

interface CoreState {
  token: string;
  transactionId: string;
  language: string;
  product: string;
  id: string;
  prevTransactions: any;
  currentTransaction: any;
}

type Props = CoreProps &
  LinkDispatchProps &
  LinkStateProp &
  RouteComponentProps;

class Core extends Component<Props, CoreState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      token: "",
      transactionId: "",
      language: "zh-CN",
      product: "",
      id: "",
      prevTransactions: [],
      currentTransaction: [],
    };
  }

  // TODO - not query again but get the data from props directly
  componentDidMount = async () => {
    try {
      const token = Cookies.get("token") as string;
      this.props.onReset();
      if (!token) {
        this.props.history.push("/login");
      }
      setTimeout(async () => {
        const res = await axios.get(api_address + "api/user/surveys", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const thisid = res.data.authData.id;
        const { transactions, completedResult } = res.data;
        // buy Core Evaluation first
        if (transactions.size === 0) {
          this.props.history.push("purchases/Core");
        }
        const transaction = transactions[0];
        const product = transaction.product;
        const transactionId = transaction.transactionid;
        const lang = res.data.authData.lang;

        // only consider the one that's not quick screener
        // this data will be used for child_info profile
        const prevTransactions = completedResult.filter(
          (item) => item.product !== PRODUCTS.QuickScreener
        );

        this.setState({
          language: lang,
          id: thisid,
          transactionId: transactionId,
          product: product,
          prevTransactions: prevTransactions,
          currentTransaction: transaction,
        });
        this.props.onUpdateUserToken(token);
        this.handleFetchResultSuccess(transaction);
        this.handleDisableLoading();
      }, 500);
      window.scrollTo(0, 0);
    } catch (err) {}
  };

  handleUpdateStepData = (data) => {
    this.props.onUpdateStepData(data);
  };

  handleUpdateCurrentStep = (data) => {
    this.props.onUpdateStep(data);
    window.scrollTo(0, 0);
  };

  handleUpdateMeta = (data) => {
    this.props.onUpdateMeta(data);
    window.scrollTo(0, 0);
  };

  handleReset = () => {
    this.props.onReset();
  };

  handleSave = (data, token) => {
    this.props.onSave(data, token);
  };

  handleDisableLoading = () => {
    this.props.onDisableLoading();
  };

  handleEnableLoading = () => {
    this.props.onEnableLoading();
  };

  handleFetchMostRecentResult = (id) => {
    this.props.onFetchMostRecentUserResult(id);
  };

  handleFetchResultSuccess = (data) => {
    this.props.onFetchResultSuccess(data);
  };

  handleUpdateData = (data) => {
    this.props.onUpdateData(data);
  };

  handleRenderComponent = () => {
    const { currentstep, loading } = this.props;

    if (!loading) {
      switch (currentstep) {
        case "basic_intro":
          return <Intro {...this} {...this.state} {...this.props} />;
        case "kks_intro":
          return <KKS {...this} {...this.state} {...this.props} />;
        // case "choose_child":
        //   return <ChildDropdown {...this} {...this.state} {...this.props} />;
        case "child_info":
          return <ChildInfo {...this} {...this.state} {...this.props} />;
        case "sound_collection":
          return <SoundCollection {...this} {...this.state} {...this.props} />;
        case "questionnaire":
          return <Questions {...this} {...this.state} {...this.props} />;
        default:
          return <ChildInfo {...this} {...this.state} {...this.props} />;
      }
    } else {
      return (
        <Dimmer active>
          <Loader></Loader>
        </Dimmer>
      );
    }
  };

  render(): JSX.Element {
    const { currentstep, loading } = this.props;
    return <>{this.handleRenderComponent()}</>;
  }
}

//satisfies return values of mapStateToProps
interface LinkStateProp {
  currentstep: string;
  steps: object;
  meta: object;
  request: object;
  loading: boolean;
  isFetchFail: boolean;
  isSaveFail: boolean;
  isRedirectFail: boolean;
  saveStepsResponse: object;
  saveResponse: object;
  redirect: object;
  isNewQuestionnaire: boolean;
  mostRecentResultId: string;
  transactionId: string;
  loggedIn: boolean;
  questionnaireLoading: boolean;
  language: string;
}
//satisfies return values of mapDispatchToProps
interface LinkDispatchProps {
  onUpdateStepData: (data: object) => void;
  onUpdateStep: (data: string) => void;
  onUpdateMeta: (data: object) => void;
  onReset: () => void;
  onFetchQuestionnaire: (token: string) => void;
  onSave: (data: object, token: string) => void;
  onDisableLoading: () => void;
  onEnableLoading: () => void;
  onFetchMostRecentUserResult: (id: string) => void;
  onFetchResultSuccess: (data: object) => void;
  onUpdateData: (data: object) => void;
  onUpdateUserToken: (data: string) => void;
}

const mapStateToProps = createStructuredSelector<AppState, LinkStateProp>({
  currentstep: makeSelectCurrentStep(),
  steps: makeSelectStep(),
  meta: makeSelectMeta(),
  request: makeSelectRequest(),
  loading: makeSelectLoading(),
  isFetchFail: makeIsFetchFail(),
  isSaveFail: makeIsSaveFail(),
  isRedirectFail: makeIsRedirectFail(),
  saveStepsResponse: makeSaveStepsResponse(),
  saveResponse: makeSaveResponse(),
  redirect: makeSelectRedirect(),
  isNewQuestionnaire: makeIsNewQuestionnaire(),
  mostRecentResultId: makeMostRecentResultId(),
  transactionId: makeSelectResultId(),
  loggedIn: makeIsUserLoggedIn(),
  questionnaireLoading: makeSelectQuestionnaireLoading(),
  language: makeSelectLanguage(),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: CoreProps
): LinkDispatchProps => ({
  onUpdateStepData: bindActionCreators(
    startUpdateQuestionnaireStepData,
    dispatch
  ),
  onUpdateStep: bindActionCreators(startUpdateQuestionnaireStep, dispatch),
  onUpdateMeta: bindActionCreators(startUpdateQuestionnaireMeta, dispatch),
  onReset: bindActionCreators(startReset, dispatch),
  onFetchQuestionnaire: bindActionCreators(startFetchQuestionnaire, dispatch),
  onSave: bindActionCreators(startSave, dispatch),
  onDisableLoading: bindActionCreators(startDisableLoading, dispatch),
  onEnableLoading: bindActionCreators(startEnableLoading, dispatch),
  onFetchMostRecentUserResult: bindActionCreators(
    startFetchMostRecentUserResult,
    dispatch
  ),
  onFetchResultSuccess: bindActionCreators(startFetchResultSuccess, dispatch),
  onUpdateData: bindActionCreators(startUpdateData, dispatch),
  onUpdateUserToken: bindActionCreators(startUpdateUserToken, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Core));
