import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import Cookies from "js-cookie";
import EvalProgressBar from "../../ProgressBar/EvalProgressBar";
import api_address from "constants/config";
import { PRODUCTS, EVAL_STEPS, EXPERT_PRODUCTS } from "constants/common";
import NotificationModal from "components/common/NotificationModal";
import { ActionTitle, ShowErrNextButton } from "styles";
import { GET_TITLE_BAR, GET_LANGUAGE } from "util/common";
import {
  Background,
  ButtonContainer,
  Container,
  QuestionnaireContainer,
  Segment,
  Title,
  TitleIndicate,
} from "../../styles";
import { makeUpdateEvaluationStatus } from "../../../../auth/selectors";
import { createStructuredSelector } from "reselect";
import { AppState } from "storeConfig";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "types/actions";
import { bindActionCreators } from "redux";
import { updateFinishedEvaluation } from "../../../../auth/actions";
import Question from "./Question";
import {
  AutoSaveButton,
  SaveIcon,
} from "components/Therapist/ReportGeneration/styles";

interface getQuestionWithOffset {
  questionsPerPage: number;
  maxOffset: number;
  questionsWithOffset: Array<Array<string>>;
}

interface QuestionsProps {
  steps: any;
  currentstep: string;
  transactionId: string;
  product: string;
  handleUpdateCurrentStep: (data: string) => void;
}

interface QuestionsState {
  offset: number;
  maxOffset: number;
  questions: Array<string>;
  questionsWithOffset: Array<Array<string>>;
  questionnaireStep: string;
  answers: Array<number>;
  dateForIndex: number;
  questionsPerPage: number;
  showNotificationModal: boolean;
  message: string;
  notificationStatus: boolean;
  buttonState: boolean;
  autoSaveVisible: boolean;
}

type Props = WithTranslation &
  LinkDispatchProps &
  LinkStateProp &
  QuestionsProps &
  RouteComponentProps;

class Questionnaire extends Component<Props, QuestionsState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      offset: 0,
      maxOffset: 0,
      questions: [],
      questionsWithOffset: [],
      questionnaireStep: "",
      answers: [],
      dateForIndex: new Date().getTime(),
      questionsPerPage: 0,
      showNotificationModal: false,
      message: "",
      notificationStatus: false,
      buttonState: false,
      autoSaveVisible: false,
    };
  }

  preventHandleNext = () => {
    if (this.state.buttonState) {
      return;
    } else {
      this.handleNext();
      this.setState({ buttonState: true, autoSaveVisible: true });
    }

    setTimeout(() => {
      this.setState({ buttonState: false });
    }, 10000);

    setTimeout(() => {
      this.setState({
        autoSaveVisible: false,
      });
    }, 3000);
  };

  componentDidMount = () => {
    const { currentstep, steps, t } = this.props;
    const questionnaireStep = steps.getIn(
      [currentstep, "values", "current"],
      "expression"
    );
    const language = GET_LANGUAGE();
    const token = Cookies.get("token");
    const payload = {
      transactionId: this.props.transactionId,
      answer: [],
      language: language,
    };
    axios
      .post(
        api_address + "api/results/questionnaire/" + questionnaireStep,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const { status, message, current_component } = res.data;
        if (!status) {
          throw new Error(message);
        }
        this.goToOtherStep(currentstep);
        if (current_component === questionnaireStep) {
          const { questionnaire, questionnaire_count } = res.data;
          this.updateQuestion(
            questionnaire,
            questionnaireStep,
            questionnaire_count
          );
        } else {
          payload.answer = [];
          axios
            .post(
              api_address + "api/results/questionnaire/" + current_component,
              payload,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              if (res.status === 200) {
                const {
                  questionnaire,
                  current_component,
                  questionnaire_count,
                } = res.data;
                this.updateQuestion(
                  questionnaire,
                  current_component,
                  questionnaire_count
                );
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
        document.title = GET_TITLE_BAR(t, current_component, false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  goToOtherStep = (currentStep: string) => {
    const { transactionId, product } = this.props;
    if (currentStep === EVAL_STEPS.soundCollection) {
      this.props.handleUpdateCurrentStep(EVAL_STEPS.soundCollection);
      return;
    } else if (currentStep === EVAL_STEPS.video) {
      if (EXPERT_PRODUCTS.includes(product)) {
        this.props.history.push("/upload-video-section/" + transactionId);
        return;
      }
    } else if (
      currentStep === EVAL_STEPS.report ||
      currentStep === EVAL_STEPS.partialReport
    ) {
      if (EXPERT_PRODUCTS.includes(product)) {
        this.props.history.push(
          "/waiting-section/:transactionId" + transactionId
        );
      } else if (product === PRODUCTS.BJCH_Questionnaire) {
        this.props.history.push("/OrganizationEnd/BJCH");
      } else {
        this.props.onUpdateFinishedEvaluation();
        this.props.history.push("/core-report/" + transactionId);
      }
      return;
    }
  };

  updateQuestion = (
    questionnaire: Array<string>,
    questionnaireStep: string,
    questionnaireCount: number
  ) => {
    const { questionsWithOffset, maxOffset, questionsPerPage } =
      this.getQuestionWithOffset(questionnaire);
    this.setState({
      offset: 0,
      questionsPerPage,
      questions: questionnaire,
      maxOffset,
      questionsWithOffset,
      questionnaireStep,
      answers: new Array(questionnaireCount).fill(null),
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  /** Handles input change */
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    const { answers } = this.state;
    answers[parseInt(name)] = parseInt(value);
    this.setState({ answers: [...answers] });
  };

  handleChangeProp = this.handleChange.bind(this);

  getQuestionWithOffset = (questions: Array<string>): getQuestionWithOffset => {
    let questionsPerPage = 8;
    if (questions.length === 9 || questions.length === 10) {
      questionsPerPage = 5;
    } else if (
      questions.length === 11 ||
      questions.length === 12 ||
      questions.length === 13
    ) {
      questionsPerPage = 7;
    }
    const maxOffset = Math.floor((questions.length - 1) / questionsPerPage);
    const questionsWithOffset = [] as Array<Array<string>>;
    for (let i = 0; i <= maxOffset; i++) {
      questionsWithOffset.push([]);
    }
    for (let i = 0; i < questions.length; i++) {
      let offset = Math.floor(i / questionsPerPage);
      questionsWithOffset[offset].push(questions[i]);
    }
    const data = {
      questionsPerPage,
      maxOffset,
      questionsWithOffset,
    };
    return data;
  };

  isValid = () => {
    const { answers, offset, questionsPerPage } = this.state;
    const getNullCount = answers
      .slice(questionsPerPage * offset, questionsPerPage * (offset + 1))
      .filter((a) => a == null).length;
    return getNullCount === 0;
  };

  handleNext = async () => {
    try {
      if (!this.isValid()) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        throw new Error("You have unfinished question");
      }
      const { answers, questionnaireStep, offset, maxOffset } = this.state;
      const { transactionId, t } = this.props;
      const token = Cookies.get("token");

      if (offset !== maxOffset) {
        this.setState({
          offset: offset + 1,
        });
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      const language = GET_LANGUAGE();
      const payload = {
        transactionId,
        answer: answers,
        language,
      };

      const res = await axios.post(
        api_address + "api/results/questionnaire/" + questionnaireStep,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { status, message, current_step } = res.data;
      if (!status) {
        throw new Error(message);
      }
      this.goToOtherStep(current_step);
      const { current_component } = res.data;
      if (current_component === questionnaireStep) {
        const { questionnaire, questionnaire_count } = res.data;
        this.updateQuestion(
          questionnaire,
          questionnaireStep,
          questionnaire_count
        );
        return;
      }
      payload.answer = [];
      const nextRes = await axios.post(
        api_address + "api/results/questionnaire/" + current_component,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!nextRes.data.status) {
        throw new Error(nextRes.data.message);
      }
      this.updateQuestion(
        nextRes.data.questionnaire,
        nextRes.data.current_component,
        nextRes.data.questionnaire_count
      );
      document.title = GET_TITLE_BAR(t, current_component, false);
    } catch (err: any) {
      this.displayNotificationMessage(err.message, false);
    }
  };

  displayNotificationMessage = (message: string, status: boolean) => {
    this.setState({
      message,
      showNotificationModal: true,
      notificationStatus: status,
    });
    setTimeout(() => {
      this.setState({ showNotificationModal: false });
    }, 3000);
  };

  render() {
    const { t, product } = this.props;
    const {
      questionnaireStep,
      showNotificationModal,
      message,
      notificationStatus,
      questionsWithOffset,
      offset,
      autoSaveVisible,
      questionsPerPage,
    } = this.state;
    return (
      <Background>
        <NotificationModal
          show={showNotificationModal}
          message={message}
          status={notificationStatus}
        />
        <EvalProgressBar step="questionnaire" product={product} />
        <AutoSaveButton display={autoSaveVisible}>
          <SaveIcon className={"check circle"} />
          <ActionTitle>{t("therapist.reportGeneration.autoSaved")}</ActionTitle>
        </AutoSaveButton>
        {questionnaireStep !== "" && (
          <Container id="Container">
            <Segment>
              <TitleIndicate />
              <Title>
                {t(`utils.fiveCategories.${questionnaireStep}.title`)}
              </Title>
            </Segment>
            {questionsWithOffset[offset].map((question, index) => {
              const finalIndex = offset * questionsPerPage + index;
              return (
                <Question
                  question={question}
                  index={finalIndex}
                  {...this.state}
                  handleChange={this.handleChangeProp}
                />
              );
            })}
            <ButtonContainer>
              <ShowErrNextButton
                status={this.isValid()}
                onClick={this.preventHandleNext}
              >
                {t("user.survey.core.questionnaire.button.next")}
              </ShowErrNextButton>
            </ButtonContainer>
          </Container>
        )}
      </Background>
    );
  }
}

interface LinkStateProp {
  evaluation: boolean;
}
interface LinkDispatchProps {
  onUpdateFinishedEvaluation: () => void;
}

const mapStateToProps = createStructuredSelector<AppState, LinkStateProp>({
  evaluation: makeUpdateEvaluationStatus(),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: QuestionsProps
): LinkDispatchProps => ({
  onUpdateFinishedEvaluation: bindActionCreators(
    updateFinishedEvaluation,
    dispatch
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Questionnaire))
);
