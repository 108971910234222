import { Emerald } from "components/Therapist/ReportGeneration/styles";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";
import { SmallDescription } from "styles";

// progress bar scroll container
export const ScrollContainer = styled.div<{ showShadow: boolean }>`
  padding: 30px 2% 10px 2%;
  background-color: #eceef8;
  overflow: scroll;
  text-align: left;
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: center;
    padding: 30px 0 10px 0;
  }
  @media only screen and (min-width: 992px) {
    padding: 30px 7% 10px 7%;
  }
  @media only screen and (min-width: 1200px) {
    padding: 30px 10% 10px 10%;
  }
  position: -webkit-sticky;
  position: sticky;
  top: -47px;
  z-index: 10;
  box-shadow: ${({ showShadow }) =>
    showShadow ? "0px 4px 12px rgb(60 64 67 / 8%)" : "none"};
`;

// progress bar container
export const ProgressBarContainer = styled.div`
  width: 700px;
  @media only screen and (min-width: 768px) {
    width: 800px;
  }
  @media only screen and (min-width: 992px) {
    width: 100%;
  }
`;

// progress indicator
export const ProgressContainer = styled.div`
  border-radius: 15px;
  height: 20px;
  background: white;
`;
export const BarLine = styled.div`
  border-radius: 15px;
  height: 20px;
  background: #6898e3;
  width: ${(props: { barWidth: string }) => props.barWidth};
  display: flex;
`;

// section text
export const SectionTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;
export const StepText = styled.div<{ selected: boolean }>`
  width: 20%;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
  color: ${(props) => (props.selected ? "#6898E3" : "#8f8f8f")};
  margin-top: 4px;
`;

// completion status
export const CompletionStatusContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;
export const CompletionStatusTextContainer = styled.div`
  width: 20%;
  height: 20px;
  text-align: -webkit-center;
  display: flex;
  justify-content: center;
`;
export const StatusBadge = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: max-content;
  padding: 1px 8px;
  border-radius: 10px;
`;
export const CompletedIcon = styled(Icon)`
  margin: 0 !important;
  font-size: 1.7rem !important;
  color: ${Emerald};
`;
export const StatusText = styled(SmallDescription)`
  color: white;
`;
