import styled from "styled-components";

export const PaymentMethodButton = styled.div<{ isChecked: boolean }>`
  width: 120px;
  height: 50px;
  background-color: ${(props) => (props.isChecked ? "#A8BF96" : "#F9FFF6")};
  border: solid 1px #a8bf96;
  color: ${(props) => (props.isChecked ? "#FFFFFF" : "#000000")};
  font-size: 20px;
  line-height: 40px;
  border-radius: 10px;
  text-align: center;
  :hover {
    cursor: pointer;
  }
`;
