import { fromJS } from "immutable";

import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  UPDATE_USER_TOKEN,
  UPDATE_LANGUAGE,
  ONGOING_EVALUATION,
  FINISHED_EVALUATION,
} from "./constants";
import { UserStatus } from "types/auth";
import { AuthActionTypes } from "types/authActions";

const userStatusInitialState = fromJS({
  loggedIn: false,
  role: "",
  token: "",
  language: "zh-CN",
  evaluation: false,
});

const userStatusReducer = (
  state: any = userStatusInitialState,
  action: AuthActionTypes
): UserStatus => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return state.set("loggedIn", true).set("role", fromJS(action.data));
    case LOGIN_FAIL:
      return state.set("loggedIn", false).set("role", "");
    case UPDATE_USER_TOKEN:
      return state.set("token", fromJS(action.data));
    case UPDATE_LANGUAGE:
      return state.set("language", fromJS(action.data));
    case ONGOING_EVALUATION:
      return state.set("evaluation", true);
    case FINISHED_EVALUATION:
      return state.set("evaluation", false);
    default:
      return state;
  }
};

export { userStatusReducer };
