import React from "react";
import { Input } from "semantic-ui-react";
import { InfoText } from "components/User/ProfileRoute/PurchasesHistory/styles";
import { PhoneInput } from "../../../auth/UserAuthForm/styles";
import DropDown from "components/common/DropDown";
import { getCountryCodeList } from "../../../../constants/countryCode";
import {
  AccountSettingContainer,
  FieldContainer,
  EditContainer,
  NameFieldsContainer,
  NameInputsContainer,
  NameInputWrapper,
  InputLabel,
  StyledInput,
  ButtonContainer,
  StyledButton,
  PhoneInputContainer,
  CountryCodeDropdown,
  PhoneInputWrapper,
  InfoTextStyled,
} from "../../../auth/UserAuthForm/styles";

interface AccountFieldRendererProps {
  field: string;
  label: string;
  value: string;
  editingField: string | null;
  newFirstName: string;
  newLastName: string;
  newValue: string;
  countryCode: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSave: () => void;
  handleCancel: () => void;
  handleEdit: (field: string) => void;
  handleCountryCodeChange: (code: string) => void;
  t: (key: string) => string;
}

const AccountFieldRenderer: React.FC<AccountFieldRendererProps> = ({
  field,
  value,
  editingField,
  newFirstName,
  newLastName,
  newValue,
  countryCode,
  handleChange,
  handleSave,
  handleCancel,
  handleEdit,
  handleCountryCodeChange,
  t,
}) => {
  if (editingField === field) {
    if (field === "name") {
      return (
        <NameFieldsContainer>
          <NameInputsContainer>
            <NameInputWrapper>
              <InputLabel htmlFor="firstName">
                {t("auth.register.firstName")}
              </InputLabel>
              <StyledInput
                name="firstName"
                value={newFirstName}
                onChange={handleChange}
                placeholder="First Name"
              />
            </NameInputWrapper>
            <NameInputWrapper>
              <InputLabel htmlFor="lastName">
                {t("auth.register.lastName")}
              </InputLabel>
              <StyledInput
                name="lastName"
                value={newLastName}
                onChange={handleChange}
                placeholder="Last Name"
              />
            </NameInputWrapper>
          </NameInputsContainer>
          <ButtonContainer>
            <StyledButton onClick={handleSave} primary size="small">
              {t("auth.account.save")}
            </StyledButton>
            <StyledButton onClick={handleCancel} size="small">
              {t("auth.account.cancel")}
            </StyledButton>
          </ButtonContainer>
        </NameFieldsContainer>
      );
    } else if (field === "phone") {
      return (
        <AccountSettingContainer>
          <PhoneInputContainer>
            <DropDown
              componentName="Account"
              type="Country code"
              defaultSelection={countryCode}
              selectedText={countryCode}
              onOptionChange={handleCountryCodeChange}
              options={getCountryCodeList(t)}
            />
            <PhoneInputWrapper>
              <PhoneInput
                onChange={handleChange}
                value={newValue}
                id="phone"
                type="text"
              />
            </PhoneInputWrapper>
          </PhoneInputContainer>
          <ButtonContainer>
            <StyledButton onClick={handleSave} primary size="small">
              {t("auth.account.save")}
            </StyledButton>
            <StyledButton onClick={handleCancel} size="small">
              {t("auth.account.cancel")}
            </StyledButton>
          </ButtonContainer>
        </AccountSettingContainer>
      );
    }
    return (
      <EditContainer>
        <StyledInput value={newValue} onChange={handleChange} />
        <ButtonContainer>
          <StyledButton onClick={handleSave} primary size="small">
            {t("auth.account.save")}
          </StyledButton>
          <StyledButton onClick={handleCancel} size="small">
            {t("auth.account.cancel")}
          </StyledButton>
        </ButtonContainer>
      </EditContainer>
    );
  }

  return (
    <FieldContainer>
      <InfoTextStyled>{value}</InfoTextStyled>
      <StyledButton onClick={() => handleEdit(field)} size="small">
        {t("auth.account.edit")}
      </StyledButton>
    </FieldContainer>
  );
};

export default AccountFieldRenderer;
