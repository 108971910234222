import { Component } from "react";
import { History, LocationState } from "history";
import { withTranslation, WithTranslation } from "react-i18next";
import RedirectToNanshanLandingPagePopUpModal from "../RedirectToNanshanLandingPagePopUp/RedirectToNanshanLandingPagePopUpModal";

interface RedirectToNanshanLandingPagePopUpProps {
  history?: History<LocationState>;
  display: boolean;
  qsResult: any;
}

type Props = RedirectToNanshanLandingPagePopUpProps & WithTranslation;

class RedirectToNanshanLandingPagePopUp extends Component<Props> {
  getLastQsResult = () => {
    const { qsResult } = this.props;
    return Array.isArray(qsResult) && qsResult.length > 0
      ? qsResult.sort((a, b) => b.id - a.id)[0]
      : null;
  };

  handleClickRedirect = (url: string, newTab = false) => {
    if (newTab) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  render() {
    const { display, t } = this.props;
    const lastQsResult = this.getLastQsResult();
    const isNormal = lastQsResult && !lastQsResult.isDelayed;
    const isNoScreenerResult = display;

    const noScreenerConstants = {
      title: "未檢測到綜合發育篩查記錄",
      body: "未檢測到既有綜合發育快篩記錄。若您尚未做過發育篩查，煩請先完成篩查。若您曾經做過發育篩查，請檢查您目前是否是用與發育篩查的註冊帳號同一個帳號登錄。",
      button: "領取綜合篩查",
      onClick: () =>
        this.handleClickRedirect(
          "https://eval.qierbao.com/purchases-payment?product=QuickScreener&currency=twd&coupon=NSIQS-5VF4-1N6T-GLDQ"
        ),
    };

    const normalConstants = {
      title: "未符合服務啟動需求",
      body: "謝謝您申請南山x啟兒寶暖心關懷服務，根據篩查結果，目前您孩子尚不需要進一步的早療支持服務。歡迎您定期回本篩檢工具了解孩子發育情況並點擊下方了解更多南山健康守護圈之婦幼健康福利。",
      button: "健康守護圈",
      onClick: () =>
        this.handleClickRedirect("http://health.nanshanlife.com.tw/", true),
    };

    const { title, body, button, onClick } = isNormal
      ? normalConstants
      : noScreenerConstants;

    return (
      <RedirectToNanshanLandingPagePopUpModal
        display={isNoScreenerResult || isNormal}
        onClick={onClick}
        title={title}
        text={body}
        buttonText={button}
      ></RedirectToNanshanLandingPagePopUpModal>
    );
  }
}

export default withTranslation()(RedirectToNanshanLandingPagePopUp);
