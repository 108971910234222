import gql from "graphql-tag";
import { ReturnMessage } from "../types/ReturnMessage";
import { User } from "../types/User";

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($userId: ID!, $newPassword: String!) {
    resetPassword(userId: $userId, newPassword: $newPassword) {
      user {
        id
      }
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface ResetPasswordData {
  resetPassword: {
    user?: User;
    returnMessage: ReturnMessage;
  };
}

export interface ResetPasswordVariables {
  userId: string;
  newPassword: string;
}
