import { useTranslation } from "react-i18next";
import {
  A4Container,
  PageNumber,
  WaterMark,
  SectionTitle,
  ContentText,
} from "../styles";
import {
  FollowUsContainer,
  QRContainer,
  QRTitle,
  FollowUsPhoto,
} from "./styles";
import { getWatermark, getQR } from "../ImageLoader";
import { WECHAT_ASSISTANT } from "../../../../../../constants/common";
import { SmallDescription } from "styles";
interface Props {
  language: string;
}

export default function FollowUs(props: Props) {
  const { t } = useTranslation();
  const { language } = props;
  const { weChat, official, facebook } = getQR(language);

  const getContactInfo = () => {
    if (language === "zh-CN") {
      return (
        <FollowUsContainer>
          <QRContainer type="WeChat">
            <FollowUsPhoto src={weChat} />
            <QRTitle>
              {t("user.survey.corePro.result.followUs.wechat")}
              <SmallDescription style={{ color: "white" }}>
                {WECHAT_ASSISTANT}
              </SmallDescription>
            </QRTitle>
          </QRContainer>
          <QRContainer type="official">
            <QRTitle>
              {t("user.survey.corePro.result.followUs.official")}
            </QRTitle>
            <FollowUsPhoto src={official} />
          </QRContainer>
        </FollowUsContainer>
      );
    } else if (language === "zh-TW") {
      return (
        <FollowUsContainer>
          <QRContainer type="facebook">
            <FollowUsPhoto src={facebook} />
            <QRTitle>
              {t("user.survey.corePro.result.followUs.facebook")}
            </QRTitle>
          </QRContainer>
          <QRContainer type="official">
            <FollowUsPhoto src={official} />
            <QRTitle>
              {t("user.survey.corePro.result.followUs.official")}
            </QRTitle>
          </QRContainer>
        </FollowUsContainer>
      );
    } else {
      return (
        <FollowUsContainer>
          <QRContainer type="official">
            <FollowUsPhoto src={official} />
            <QRTitle>
              {t("user.survey.corePro.result.followUs.official")}
            </QRTitle>
          </QRContainer>
        </FollowUsContainer>
      );
    }
  };
  return (
    <A4Container>
      <SectionTitle>
        {t("user.survey.corePro.result.followUs.title")}
      </SectionTitle>
      <ContentText>
        {t("user.survey.corePro.result.followUs.trailer")}
      </ContentText>
      {getContactInfo()}
      <PageNumber>17</PageNumber>
      <WaterMark src={getWatermark(language)} />
    </A4Container>
  );
}
