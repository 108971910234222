import styled from "styled-components";
import { Dropdown } from "semantic-ui-react";

export const CountryCodeDropdown = styled(Dropdown)`
  & > .input {
    cursor: pointer;
    height: 15px;
    background-color: #ffffff;
  }
  & > .text {
    cursor: pointer;
    height: 15px;
    background-color: #ffffff;
  }
`;

export const Title = styled.div`
  font-family: Verdana;
  font-size: 17px;
  line-height: 1.24;
  color: #6898e3;
  text-align: left;
  margin-top: 15px;
`;

export const InputArea = styled.input`
  border-radius: 1px !important;
  border: solid 1px #58c9df !important;
  height: 31px !important;
  margin-top: 16px !important;
  margin-bottom: 0px !important;
  &:focus {
    border-bottom: none !important;
  }
`;

export const PhoneLoginContainer = styled.div``;

export const EmailLoginContainer = styled.div``;

export const PhoneInputContainer = styled.div`
  display: "flex";
`;

export const PhoneInput = styled.input`
  margin-left: 10px !important;
  border-radius: 1px !important;
  border: solid 1px #58c9df !important;
  height: 31px !important;
  margin-top: 16px !important;
  margin-bottom: 0px !important;
  &:focus {
    border-bottom: none !important;
  }
`;

export const LoginForm = styled.form`
  text-align: center;
`;

export const LoginButton = styled.button`
  width: 112px;
  height: 33px;
  border-radius: 1px;
  background-color: #53d2dc;
  margin-top: 39px;
  border: solid 1px #53d2dc;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  width: fit-content;
  padding: 8px 38px;
`;

export const LoginContainer = styled.div`
  width: 384px;
  margin-top: 72px;
  @media only screen and (max-width: 600px) {
    width: 200px;
  }
`;

export const Container = styled.div`
  max-width: 4000px;
  text-align: -webkit-center;
  height: 650px;
  font-family: Verdana;
  // @media only screen and (min-width: 993px) {
  //   width: 70%;
  // }
  // @media only screen and (min-width: 601px) {
  //   width: 85%;
  // }
`;

export const DivideLine = styled.div`
  width: 40%;
  height: 1px;
  background-color: #9d9d9d;
`;

export const DivideContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  color: #9d9d9d;
  font-size: 14px;
`;

export const LoginStatusButton = styled.button`
  width: 100%;
  height: 33px;
  margin-top: 16px;
  border-radius: 2px;
  border: solid 0.8px #58c9df;
  background-color: white;
  font-size: 14px;
  color: #53d2dc;
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
`;

export const Logo = styled.img`
  text-align: center;
  margin-bottom: 45px;
  height: 75px;
  width: 342px;
`;
