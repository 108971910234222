export const getYearAndMonth = (
  birthdayYear: string,
  birthdayMonth: string,
  birthdayDay: string
) => {
  const today = new Date();
  let year = today.getFullYear() - parseInt(birthdayYear);
  let month = today.getMonth() - parseInt(birthdayMonth);
  if (0 > month || (0 === month && today.getDate() < parseInt(birthdayDay))) {
    year--;
    month += 12;
  }
  return { year, month };
};

export const getAge = (section, t): string => {
  const start = section?.meta?.age?.start;
  const end = section?.meta?.age?.end;
  if (section == null || start == null || end == null) {
    return "*";
  } else if (start === 0 && end === 0) {
    return t("user.survey.core.result.initialAnalysis.notDeveloped");
  } else {
    return (
      t("user.survey.core.result.initialAnalysis.abilityEqualTo") +
      start.toString() +
      " " +
      " - " +
      (end > 78 ? 78 : end.toString()) +
      " " +
      t("user.survey.core.result.initialAnalysis.child")
    );
  }
};

const getAgeRange = (section) => {
  return section ? [section?.meta?.age?.start, section?.meta?.age?.end] : [];
};

export const getAgeRanges = (questionnaire) => {
  const { comprehension, expression, oral, social, preliteracy } =
    questionnaire;
  return {
    comprehensionAgeRange: getAgeRange(comprehension),
    expressionAgeRange: getAgeRange(expression),
    oralAgeRange: getAgeRange(oral),
    socialAgeRange: getAgeRange(social),
    preliteracyAgeRange: getAgeRange(preliteracy),
  };
};

export const GET_STATUS_BY_COLOR = (status: string) => {
  switch (status) {
    case "delayed":
      return "#EF839F";
    case "borderline":
      return "#F7C042";
    case "normal":
      return "#6898E3";
    case "advanced":
      return "#5AD2DB";
    default:
      return "#e8e9e9";
  }
};
