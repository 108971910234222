export const SKILL_MAP = {
  comprehension: [
    1, 2, 3, 4, 266, 268, 269, 271, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
    17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 265, 267, 270, 28, 29, 30, 31,
    32, 33, 34, 35, 36, 37, 38, 39, 311, 261, 262, 263, 264,
  ],
  literacy: [
    92, 93, 94, 95, 96, 304, 97, 98, 99, 100, 101, 102, 305, 103, 104, 105, 106,
    298, 299, 300, 301, 302, 303,
  ],
  preliteracy: [
    107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121,
    122, 123, 124, 125, 126, 127, 128, 129, 130, 257, 131, 132, 133, 134, 135,
    136, 137, 258, 259, 138,
  ],
  oral: [
    139, 140, 141, 142, 307, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152,
    153, 154, 306, 308, 155, 156, 157, 158, 159, 160, 161, 162, 260, 163, 164,
  ],
  social: [
    165, 166, 312, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178,
    179, 180, 181, 182, 183, 184, 185, 287, 289, 294, 295, 296, 186, 283, 284,
    286, 290, 292, 293, 297, 285, 291, 288, 309,
  ],
  expression: [
    40, 41, 42, 43, 44, 45, 272, 274, 275, 281, 282, 273, 46, 47, 48, 49, 50,
    51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 276, 62, 63, 64, 65, 66, 67, 68,
    69, 70, 71, 279, 280, 72, 73, 74, 310, 75, 76, 77, 78, 79, 80, 81, 82, 83,
    84, 85, 277, 278, 86, 87, 88, 89, 90, 91,
  ],
  parentImplementation: [
    187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201,
    202, 203, 204, 205, 206, 207, 208, 209, 217, 218, 219, 220, 221, 222, 223,
    224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238,
    239, 240, 241, 242, 243, 244, 245, 246, 247, 248, 249, 250, 251, 252, 253,
    254, 255, 256, 210, 211, 212, 213, 214, 215, 216,
  ],
};

export const SKILL_LIST = [
  "comprehension",
  "literacy",
  "preliteracy",
  "oral",
  "social",
  "expression",
];
