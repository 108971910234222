import { useHistory } from "react-router-dom";
import ModBuyUp from "./ModBuyUp";
import Core from "./Core";
import CorePro from "./CorePro";
import CustomizedPrivateCoaching from "./CustomizedPrivateCoaching";
import ParentEducationAcademy from "./ParentEducationAcademy";
import { PRODUCTS } from "../../../../constants/common";
import { ProductType } from "../../Dashboard/BuyUp";

type Props = {
  product: ProductType;
  currency: string;
  isBuyUp: boolean;
};

function Product(props: Props) {
  const { product, currency, isBuyUp } = props;
  const history = useHistory();

  const onContactClick = () => {
    history.push("/contactUs/Sales");
  };

  switch (product.name) {
    case PRODUCTS.Core:
      return <Core product={product} currency={currency} isBuyUp={false} />;
    case PRODUCTS.CoreProBuyUp:
      return <CorePro product={product} currency={currency} isBuyUp={true} />;
    case PRODUCTS.ModBuyUp:
      return <ModBuyUp product={product} currency={currency} isBuyUp={true} />;
    case PRODUCTS.CorePro:
      return (
        <CorePro product={product} currency={currency} isBuyUp={isBuyUp} />
      );
    case PRODUCTS.CPC:
      return <CustomizedPrivateCoaching onContactClick={onContactClick} />;
    case PRODUCTS.PEA:
      return (
        <ParentEducationAcademy
          product={product}
          currency={currency}
          isBuyUp={isBuyUp}
        />
      );
    default:
      return <div />;
  }
}

export default Product;
