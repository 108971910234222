import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  width: 90%;
  margin-left: 5%;
  padding: 20px 0;
  position: relative;
  top: calc(50% - 45%);
`;

export const AdminTableContainer = styled.div`
  transform: scale(0.95);
  padding: 20px 0;
  position: relative;
`;

export const ContainerWithGrid = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-left: 5%;
  padding: 20px 0;
`;

export const GridColumn = styled.div`
  grid-column: span 1;
`;

export const Table = styled.table`
  width: 100%;
  border: 2px solid #000000;
  border-collapse: collapse;
`;

export const Header = styled.tr`
  width: 100%;
  height: 50px;
  text-align: center;
  border-bottom: 2px solid #000000;
`;

export const Row = styled.tr<{ isSelected: boolean }>`
  width: 100%;
  height: 50px;
  text-align: center;
  border-bottom: 2px solid #000000;
  background-color: ${(props) => (props.isSelected ? "#8F8F8F" : "#FFFFFF")};
  :hover {
    cursor: pointer;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1 1 200px;
  margin: 5px;
  min-height: 50px;
  margin: 15px 0;
  padding: 0 10px;
`;

export const ContentSubtitle = styled.label`
  margin: 0 10px;
  line-height: 50px;
  font-size: 18px;
  width: 210px;
`;

export const ChildAgeSubTitle = styled.label`
  margin: 0 10px;
  line-height: 50px;
  font-size: 18px;
`;

export const PercentOffContainer = styled.label`
  margin: 0 10px;
  line-height: 50px;
  font-size: 18px;
  width: fit-content;
`;

export const MaxRedemptionPerCoupon = styled.label`
  margin: 0 10px;
  font-size: 18px;
  width: 210px;
`;

export const ContentNewSubtitle = styled.label`
  margin: 0 10px;
  line-height: 50px;
  font-size: 18px;
  width: 150px;
`;

export const ContentTitle = styled.label`
  margin: 0 10px;
  line-height: 50px;
  font-size: 25px;
  width: 210px;
`;

export const Input = styled.input`
  border: 0.5px solid #6898e3;
  width: 260px;
  padding: 10px;
  border-radius: 15px;
  font-size: 16px;
`;

const SCButton = styled.div`
  width: 120px;
  height: 50px;
  background-color: #f9fff6;
  border: solid 1px #6898e3;
  color: #000000;
  font-size: 30px;
  line-height: 50px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
`;

export const SaveButton = styled(SCButton)`
  margin-right: 20px;
`;

export const CreateButton = styled.button`
  background-color: #6898e3;
  color: white;
  border: none;
  width: 120px;
  border-radius: 10px;
  padding: 8px 16px;
  margin-right: 20px;
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #549dc7;
  }

  &:active {
    background-color: #4684a5;
  }

  &:focus {
    outline: none;
  }
`;

export const CancelButton = styled(SCButton)`
  margin-right: 20px;
`;

const clickAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
`;

export const BackButton = styled.button`
  width: 50px;
  height: 50px;
  background-color: #6898e3;
  color: #ffffff;
  border-radius: 50%;
  margin-right: 20px;
  border: solid 1px #6898e3;
  cursor: pointer;
  transition: background-color 0.3s ease;

  // Apply the animation on click
  &:active {
    animation: ${clickAnimation} 0.2s ease;
  }
`;

export const DeleteButton = styled(SCButton)`
  margin-right: 20px;
  background-color: #fdd;
  border: solid 1px #f44;
`;

export const AdminContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const AdminContentContainer = styled.div`
  width: 100%;
  margin-left: 20px;
  background-color: #ffffff;
`;

export const Button = styled.div`
  width: 100px;
  height: 40px;
  margin: 0 5px;
  background: #6898e3;
  color: white;
  border-radius: 8px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
`;

export const ToolContainer = styled.div`
  margin: 10px 0;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Page = styled.div`
  line-height: 40px;
`;

export const TD = styled.td`
  white-space: pre-line;
`;

export const LinkButton = styled.div<{ isDisabled: boolean }>`
  width: 50px;
  height: 30px;
  margin: 0 auto;
  color: white;
  border-radius: 8px;
  background: ${(props) => (props.isDisabled ? "#c4c4c4" : "#6898E3;")};
  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
  display: grid;
  place-items: center;
  font-size: 16px;
`;

export const LinkButtonInEdit = styled.div`
  width: 260px;
  color: white;
  border-radius: 8px;
  background: #6898e3;
  cursor: pointer;
  display: grid;
  place-items: center;
  font-size: 18px;
`;

export const TextWrapLinkButtonInEdit = styled.div`
  width: 260px;
  color: white;
  border-radius: 8px;
  background: #6898e3;
  cursor: pointer;
  display: grid;
  place-items: center;
  font-size: 18px;
  text-align: center;
  word-break: break-all;
  padding: 10px;
`;

export const PencilButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: ${(props) => (props.isEditing ? "#10B981" : "#6898e3")};

  &:hover {
    opacity: 0.8;
  }
`;

export const UtcNotice = styled.div`
  color: red;
  font-size: 14px;
  position: absolute;
  top: 16px;
  left: 16px;
`;
