import React, { Component } from "react";
import { withRouter, RouteComponentProps, match } from "react-router-dom";
import { History, LocationState } from "history";
import { withTranslation, WithTranslation } from "react-i18next";
import NotificationModal from "../../common/NotificationModal";
import {
  Title,
  InputArea,
  LoginPageButton,
  Container,
  Description,
} from "./styles";
import { RESET_PASSWORD, USER_WITH_RESET_PASSWORD_TOKEN } from "util/auth";

interface ResetPasswordProps {
  history: History<LocationState>;
  match?: match<Match>;
}

//url parameters
interface Match {
  userId: string;
  token: string;
}

interface ResetPasswordState {
  userId: string;
  token: string;
  password: string;
  confirmPassword: string;
  message: string;
  notificationStatus: boolean;
  showModal: boolean;
  loading: boolean;
}

type Props = ResetPasswordProps & RouteComponentProps & WithTranslation;

class ResetPassword extends Component<Props, ResetPasswordState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userId: "",
      token: "",
      password: "",
      confirmPassword: "",
      message: "",
      notificationStatus: false,
      showModal: false,
      loading: true,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.verifyToken();
  };

  verifyToken = async () => {
    const { token } = this.props.match.params;
    const data = {
      token,
    };
    const userWithResetPasswordTokenData = await USER_WITH_RESET_PASSWORD_TOKEN(
      data
    );
    if (userWithResetPasswordTokenData.userWithResetPasswordToken) {
      this.setState({
        userId: String(
          userWithResetPasswordTokenData.userWithResetPasswordToken.id
        ),
      });
    }
    this.setState({ loading: false });
  };

  showNotification = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
      if (status) {
        this.props.history.push("/login");
      }
    }, 3000);
  };

  onSubmit = async () => {
    try {
      const { t } = this.props;
      const { password, confirmPassword, userId } = this.state;
      if (password.length < 6) {
        throw new Error(t("auth.resetPassword.passwordTooShort"));
      }
      if (password !== confirmPassword) {
        throw new Error(t("auth.resetPassword.passwordsDoNotMatch"));
      }
      const userData = {
        userId,
        newPassword: password,
      };
      const resetPasswordData = await RESET_PASSWORD(userData);
      this.showNotification(
        resetPasswordData.resetPassword.returnMessage.message,
        true
      );
    } catch (error: any) {
      this.showNotification(error.message, false);
    }
  };

  onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: e.currentTarget.value });
  };

  onConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ confirmPassword: e.currentTarget.value });
  };

  render() {
    const { t } = this.props;
    const { showModal, message, notificationStatus, loading, userId } =
      this.state;
    const { onSubmit, onPasswordChange, onConfirmPasswordChange } = this;
    return loading ? (
      <></>
    ) : (
      <Container>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />
        <Title>{t("auth.resetPassword.title")}</Title>
        {userId ? (
          <>
            <Description>{t("auth.resetPassword.description")}</Description>
            <InputArea
              onChange={onPasswordChange}
              type="password"
              placeholder={t("auth.resetPassword.password")}
            />
            <InputArea
              onChange={onConfirmPasswordChange}
              type="password"
              placeholder={t("auth.resetPassword.confirmPassword")}
            />
            <LoginPageButton onClick={onSubmit}>
              {t("auth.resetPassword.submit")}
            </LoginPageButton>{" "}
          </>
        ) : (
          <div>Invalid token</div>
        )}
      </Container>
    );
  }
}

export default withRouter(withTranslation()(ResetPassword));
