import styled from "styled-components";

export const ModalContainer = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  text-align: -webkit-center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
`;
export const ValidationModalMask = styled.div`
  background-color: rgba(244, 244, 244, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 50;
`;

export const ValidationModalContainer = styled.div`
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  background-color: white;
  border-radius: 8px;
  height: max-content;
  width: 100%;
  position: relative;
  padding: 0 0 16px;
  @media only screen and (min-width: 768px) {
    width: max-content;
    min-width: 500px;
  }
  @media only screen and (min-width: 992px) {
    width: max-content;
    min-width: 500px;
  }
  z-index: 999;
`;

export const ValidationModalHeaderContainer = styled.div`
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ValidationModalContentContainer = styled.div`
  padding: 16px 16px 0;
  text-align: left;
`;
export const ValidationModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 16px 0;
`;

export const ValidationModalButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 12px;
  border-radius: 6px;
  cursor: pointer;
  background-color: #6898e3;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
  }
`;
