import React, { useEffect, useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";

function GoogleLoginComponent(props) {
  const [loginWidth, setLoginWidth] = useState(
    window.innerWidth < 768
      ? "300px"
      : window.innerWidth < 992
      ? "330px"
      : window.innerWidth < 1200
      ? "360px"
      : "500px"
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setLoginWidth("300px");
      } else if (window.innerWidth < 992) {
        setLoginWidth("330px");
      } else if (window.innerWidth < 1200) {
        setLoginWidth("360px");
      } else {
        setLoginWidth("500px");
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const containerProps = {
    style: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
  return (
    <div>
      <GoogleLogin
        width={loginWidth}
        onSuccess={(credentialResponse) => {
          props.handleGoogleAuth(
            jwt_decode(credentialResponse.credential ?? "")
          );
        }}
        onError={() => {
          console.log("Login Failed");
        }}
        containerProps={containerProps}
      />
    </div>
  );
}

export default GoogleLoginComponent;
