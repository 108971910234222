import styled from "styled-components";

export const ResetPasswordButton = styled.div`
  width: 200px;
  height: 50px;
  border: solid 1px #000000;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  :hover {
    cursor: pointer;
  }
`;
