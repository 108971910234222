import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import api_address from "../../../../constants/config";
import { History, LocationState } from "history";
import NotificationModal from "../../../common/NotificationModal";
import {
  Container,
  ContentContainer,
  ContentSubtitle,
  Input,
  SaveButton,
  CancelButton,
} from "../../styles";
import { JS_COOKIE } from "util/auth";
import { ADMIN_COOKIE_NAME } from "constants/admin";

interface CreateOrganizationProps {
  history: History<LocationState>;
}

interface CreateOrganizationStates {
  [key: string]: string | boolean;
  name: string;
  organizationCode: string;
  defaultProductId: string;
  message: string;
  showModal: boolean;
  notificationStatus: boolean;
}

type Props = CreateOrganizationProps & WithTranslation;
class CreateOrganization extends Component<Props, CreateOrganizationStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: "",
      organizationCode: "",
      defaultProductId: "",
      message: "",
      showModal: false,
      notificationStatus: false,
    };
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    this.setState({ [id]: value });
  };

  handleStatus = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  validation = () => {
    const { name, organizationCode } = this.state;
    if (name === "") {
      throw new Error("organization name is missing");
    }
    if (organizationCode === "") {
      throw new Error("organization code is missing");
    }
    // TODO validate defaultProductId
  };

  onCancelClick = () => {
    this.props.history.push("/admin/organization");
  };

  onSaveClick = async () => {
    try {
      this.validation();
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { name, organizationCode, defaultProductId } = this.state;
      const res = await axios.post(
        api_address + "api/admin/organization/",
        { organizationCode, name, defaultProductId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { status, message } = res.data;
      if (!status) {
        throw new Error(message);
      }
      this.props.history.push("/admin/organization");
    } catch (error: any) {
      console.error(error.message);
      this.displayErrorMessage(error.message);
    }
  };

  displayErrorMessage = (message: string) => {
    this.setState({
      message: message,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  render = () => {
    const { t } = this.props;
    const {
      showModal,
      message,
      notificationStatus,
      name,
      organizationCode,
      defaultProductId,
    } = this.state;

    return (
      <Container>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />

        <ContentContainer>
          <ContentSubtitle>{t("admin.organization.name")}</ContentSubtitle>
          <Input
            onChange={this.handleInputChange}
            id="name"
            type="text"
            value={name}
          />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.organization.code")}</ContentSubtitle>
          <Input
            onChange={this.handleInputChange}
            id="organizationCode"
            type="text"
            value={organizationCode}
          />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>
            {t("admin.organization.defaultProductId")}
          </ContentSubtitle>
          <Input
            onChange={this.handleInputChange}
            id="defaultProductId"
            type="number"
            value={defaultProductId}
          />
        </ContentContainer>

        <ContentContainer>
          <CancelButton onClick={this.onCancelClick}>
            {t("admin.common.cancel")}
          </CancelButton>

          <SaveButton onClick={this.onSaveClick}>
            {t("admin.common.save")}
          </SaveButton>
        </ContentContainer>
      </Container>
    );
  };
}

export default withTranslation()(CreateOrganization);
