import React, { Component } from "react";
import { CheckCircleProgress } from "./styles";
import { Modal } from "react-bootstrap";
import imgFinishStamp from "../../assets/imgFinishStamp.png";
import { CircularProgress } from "@material-ui/core";

interface ProgressModalState {}

interface ProgressProps {
  completed: number;
  showModal: boolean;
  showProgress: boolean;
}

class ProgressModal extends Component<ProgressProps, ProgressModalState> {
  componentDidMount() {}

  render() {
    const { completed, showModal, showProgress } = this.props;
    return (
      <Modal show={showModal} className="modal-customize">
        {/* TODO can be removed after all components are using styled */}
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.min.css"
        />
        <Modal.Body>
          {showProgress ? (
            <CircularProgress
              variant="static"
              size="90pt"
              thickness={5}
              style={{ color: "#2AB2AC" }}
              value={completed}
            />
          ) : (
            <CheckCircleProgress src={imgFinishStamp} />
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

export default ProgressModal;
