import gql from "graphql-tag";
import { ReturnMessage } from "../types/ReturnMessage";

export const EDIT_THERAPIST_MUTATION = gql`
  mutation EditTherapist(
    $userUuid: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $phone: String!
    $countryCode: String!
    $country: String!
    $timeZone: String!
    $originalToken: String
    $consultationUrl: String
    $cnConsultationUrl: String
    $avatarFilename: String!
    $title: String!
    $snowFlakeUuid: [String!]!
    $bio: [JSON!]!
    $disabled: Boolean!
  ) {
    editTherapist(
      userUuid: $userUuid
      email: $email
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      countryCode: $countryCode
      country: $country
      timeZone: $timeZone
      originalToken: $originalToken
      consultationUrl: $consultationUrl
      cnConsultationUrl: $cnConsultationUrl
      avatarFilename: $avatarFilename
      title: $title
      snowFlakeUuid: $snowFlakeUuid
      bio: $bio
      disabled: $disabled
    ) {
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface EditTherapistData {
  editTherapist: {
    returnMessage: ReturnMessage;
  };
}

export interface EditTherapistVariables {
  userUuid: string;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  timeZone: string;
  countryCode: string;
  phone: string;
  originalToken?: string;
  consultationUrl?: string;
  cnConsultationUrl?: string;
  avatarFilename: string;
  title: string;
  bio: Array<Object>;
  snowFlakeUuid: Array<String>;
  disabled: boolean;
}
