import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { History, LocationState } from "history";
import i18n from "i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "types/actions";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { startUpdateUserLanguage } from "../../auth/actions";
import { Dropdown, Header, Icon } from "semantic-ui-react";
import mixpanel from "mixpanel-browser";
import { GET_LANGUAGE } from "../../../util/common";

interface LanguageDropdownProps {
  path: string;
  history: History<LocationState>;
  color: string;
}

interface LanguageDropdownState {
  language: string;
}

type Props = LanguageDropdownProps &
  WithTranslation &
  LinkDispatchProps &
  RouteComponentProps;

class LanguageDropdown extends Component<Props, LanguageDropdownState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      language: GET_LANGUAGE(),
    };
  }

  handleChange = (event) => {
    const lang = event.currentTarget.textContent;
    if (lang === "English") {
      this.setState({ language: lang });
      this.props.onUpdateLanguage("en");
      i18n.changeLanguage("en");
    } else if (lang === "简体中文") {
      this.setState({ language: lang });
      this.props.onUpdateLanguage("zh-CN");
      i18n.changeLanguage("zh-CN");
    } else if (lang === "繁體中文") {
      this.setState({ language: lang });
      this.props.onUpdateLanguage("zh-TW");
      i18n.changeLanguage("zh-TW");
    }
    mixpanel.track("language", {
      location: this.props.path,
      action: "change language",
      from: this.state.language,
      to: lang,
    });
  };

  onClick = () => {
    mixpanel.track("language", {
      location: this.props.path,
      action: "click change language",
    });
  };

  render = () => {
    const { color } = this.props;
    const { language } = this.state;
    const languageKey = [
      { key: "en", text: "English" },
      { key: "zh-CN", text: "简体中文" },
      { key: "zh-TW", text: "繁體中文" },
    ];
    const languageOptions = languageKey.map((language) => {
      const { key, text } = language;
      return { key: key, text: text, value: key };
    });
    return (
      <Header>
        <Icon
          className="world icon"
          style={{ fontSize: "inherit", color: `${color}` }}
        />
        <Header.Content>
          <Dropdown
            onClick={this.onClick}
            style={{ color: `${color}` }}
            inline
            options={languageOptions}
            onChange={this.handleChange}
            defaultValue={language}
          />
        </Header.Content>
      </Header>
    );
  };
}

interface LinkDispatchProps {
  onUpdateLanguage: (data: string) => void;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): LinkDispatchProps => ({
  onUpdateLanguage: bindActionCreators(startUpdateUserLanguage, dispatch),
});

export default withRouter(
  connect(null, mapDispatchToProps)(withTranslation()(LanguageDropdown))
);
