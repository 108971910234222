import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import api_address from "../../../../constants/config";
import { History, LocationState } from "history";
import DropDown from "../../../common/DropDown";
import { getCountryCodeList } from "../../../../constants/countryCode";
import { IS_NUMBER, IS_VALID_EMAIL } from "../../../../util/common";
import NotificationModal from "../../../common/NotificationModal";
import {
  Container,
  ContentContainer,
  ContentSubtitle,
  Input,
  SaveButton,
  CancelButton,
} from "../../styles";
import { JS_COOKIE } from "util/auth";
import { ADMIN_COOKIE_NAME } from "constants/admin";

interface CreateUserProps {
  history: History<LocationState>;
}

interface CreateUserStates {
  [key: string]: string | boolean;
  UUID: string;
  firstName: string;
  lastName: string;
  countryCode: string;
  phone: string;
  email: string;
  password: string;
  message: string;
  showModal: boolean;
  notificationStatus: boolean;
}

type Props = CreateUserProps & WithTranslation;
class CreateUser extends Component<Props, CreateUserStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      UUID: "",
      firstName: "",
      lastName: "",
      countryCode: "",
      phone: "",
      email: "",
      password: "",
      message: "",
      showModal: false,
      notificationStatus: false,
    };
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    this.setState({ [id]: value });
  };

  handleCountryCodeChange = (value: string) => {
    if (value != null)
      this.setState({
        countryCode: value,
      });
  };

  handleStatus = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  validation = () => {
    const { email, phone, countryCode, firstName, lastName, password } =
      this.state;
    if (firstName === "") {
      throw new Error("first name is missing");
    }
    if (lastName === "") {
      throw new Error("last name is missing");
    }
    if (countryCode === "") {
      throw new Error("country code is missing");
    }
    if (phone === "") {
      throw new Error("phone number is missing");
    }
    if (!IS_NUMBER(phone)) {
      this.setState({ phone: "" });
      throw new Error("phone number is invalid");
    }
    if (email === "") {
      throw new Error("email is missing");
    }
    if (!IS_VALID_EMAIL(email)) {
      this.setState({ email: "" });
      throw new Error("email is invalid");
    }
    if (password === "") {
      throw new Error("password can not be empty");
    }
  };

  onCancelClick = () => {
    this.props.history.push("/admin/user");
  };

  onSaveClick = async () => {
    try {
      this.validation();
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { UUID, email, phone, countryCode, firstName, lastName, password } =
        this.state;
      const res = await axios.post(
        api_address + "api/admin/user/",
        { UUID, email, phone, countryCode, firstName, lastName, password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { status, message } = res.data;
      if (!status) {
        throw new Error(message);
      }
      this.props.history.push("/admin/user");
    } catch (error: any) {
      console.error(error.message);
      this.displayErrorMessage(error.message);
    }
  };

  displayErrorMessage = (message: string) => {
    this.setState({
      message: message,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  render = () => {
    const { t } = this.props;
    const {
      showModal,
      message,
      notificationStatus,
      UUID,
      firstName,
      lastName,
      countryCode,
      phone,
      email,
      password,
    } = this.state;

    return (
      <Container>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />

        <ContentContainer>
          <ContentSubtitle>{t("admin.user.UUID")}</ContentSubtitle>
          <Input
            onChange={this.handleInputChange}
            id="UUID"
            type="text"
            value={UUID}
          />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.user.firstName")}</ContentSubtitle>
          <Input
            onChange={this.handleInputChange}
            id="firstName"
            type="text"
            value={firstName}
          />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.user.lastName")}</ContentSubtitle>
          <Input
            onChange={this.handleInputChange}
            id="lastName"
            type="text"
            value={lastName}
          />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.user.countryCode")}</ContentSubtitle>
          <DropDown
            componentName="admin - user"
            type="Country code"
            defaultSelection={countryCode}
            selectedText={countryCode}
            options={getCountryCodeList(t)}
            onOptionChange={this.handleCountryCodeChange}
          />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.user.phone")}</ContentSubtitle>
          <Input
            onChange={this.handleInputChange}
            id="phone"
            type="tel"
            value={phone}
          />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.user.email")}</ContentSubtitle>
          <Input
            onChange={this.handleInputChange}
            id="email"
            type="email"
            value={email}
          />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.user.password")}</ContentSubtitle>
          <Input
            onChange={this.handleInputChange}
            id="password"
            type="password"
            value={password}
          />
        </ContentContainer>

        <ContentContainer>
          <CancelButton onClick={this.onCancelClick}>
            {t("admin.common.cancel")}
          </CancelButton>

          <SaveButton onClick={this.onSaveClick}>
            {t("admin.common.save")}
          </SaveButton>
        </ContentContainer>
      </Container>
    );
  };
}

export default withTranslation()(CreateUser);
