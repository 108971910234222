import styled from "styled-components";

export const NavbarLinkContainer = styled.div`
  width: 200px;
  height: 90%;
  background-color: white;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  padding: 0px 16px;
  border: 2px solid #6898e3;
  border-left: none;
  margin-left: -10px;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  position: absolute;
  top: 50%; /* Positions the top center of the container at 50% height of AdminPageContainer */
  left: 0; /* Aligns the container to the left of AdminPageContainer */
  transform: translateY(-50%); /* Centers the container vertically */
`;

export const AdminTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 90vh;
`;

export const AdminTabColumn = styled.div<{ selected: boolean }>`
  padding: 12px 16px;
  margin-bottom: 8px;
  cursor: pointer;
  color: ${(props) => (props.selected ? "white" : "#6898E3A6")};
  background-color: ${(props) =>
    props.selected ? "#6898E3A6" : "transparent"};
  border-radius: 8px;
  font-weight: ${(props) => (props.selected ? "bold" : "500")};
  font-size: 16px;
  font-family: "Nunito", sans-serif;
  &:hover {
    background-color: ${(props) => (props.selected ? "#6898E3A6" : "#F3F4F6")};
  }
`;

export const BottomContainer = styled.div`
  margin-top: auto;
`;

export const LanguageButton = styled.div`
  display: flex;
  align-items: left;
  justify-content: space-between;
  padding: 12px 16px;
  color: #6b7280;
  cursor: pointer;
`;

export const LogoutButton = styled.button`
  width: 130px;
  height: 35px;
  background-color: #f3f4f6;
  border: 1px solid #6898e3;
  border-radius: 8px;
  color: #6898e3;
  cursor: pointer;
  margin: 20px auto 30px auto;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: #e5e7eb;
  }
`;

export const ScrollContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 16px;
`;

export const AdminTabTitle = styled.div`
  margin: 24px 32px 30px 32px;
  font-weight: bold;
`;
