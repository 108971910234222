import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  MODULARIZED_PRODUCTS,
  SELF_PRODUCTS,
  EXPERT_PRODUCTS,
  PRODUCTS,
  EXPERT_PRODUCTS_WITHOUT_REPORT,
} from "../../../../../constants/common";
import { ResultListSegment } from "./styles";
import {
  FlexBetweenContainer,
  GreyInfoText,
  InfoContainer,
  InfoRow,
  LabelText,
  ProductName,
  RowContainer,
  ValueText,
} from "../../../ProfileRoute/PurchasesHistory/styles";
import { DatesRangeInput } from "semantic-ui-calendar-react";

type steps = {
  child_info: {
    values: {
      childName: string;
    };
  };
};

type Props = {
  id: string;
  transactionId: string;
  sex: string;
  dob: string;
  questionnaireId: number;
  finalScore: number;
  isDelayed: boolean;
  createdAt: string;
  agentId: string;
};

export default function QsItem(props: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    id,
    transactionId,
    sex,
    dob,
    questionnaireId,
    finalScore,
    isDelayed,
    createdAt,
    agentId,
  } = props;

  // Create a new Date object using the UTC string
  const date = new Date(dob);

  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed in JavaScript
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  const formattedDobForQSResult = `${month}-${day}-${year}`;
  console.log(formattedDobForQSResult, "formattedDob");

  const handleViewResult = () => {
    window.open(
      "/quick-screener/results/" +
        questionnaireId +
        "/" +
        finalScore +
        "/" +
        sex +
        "/" +
        formattedDobForQSResult +
        "/" +
        transactionId +
        "/" +
        agentId
    );
  };

  let productName = t(`quickScreener.index.topbar`);
  let delayedText = isDelayed
    ? t(`quickScreener.results.result1`)
    : t(`quickScreener.results.result2`);

  const createdAtForDisplay = createdAt.substring(0, 10);
  const formattedDobForDisplay = `${year}-${month}-${day}`;
  return (
    <ResultListSegment onClick={() => handleViewResult()}>
      <FlexBetweenContainer>
        <ProductName>{productName + " (ID: " + id + ")"}</ProductName>
        <GreyInfoText>{createdAtForDisplay}</GreyInfoText>
      </FlexBetweenContainer>
      <FlexBetweenContainer>
        <InfoContainer>
          <RowContainer>
            <InfoRow>
              <LabelText>{t(`quickScreener.index.gender`)}</LabelText>
              <ValueText>{sex}</ValueText>
            </InfoRow>
            <InfoRow>
              <LabelText>{t(`quickScreener.index.dob`)}</LabelText>
              <ValueText>{formattedDobForDisplay}</ValueText>
            </InfoRow>
            <InfoRow>
              <LabelText>{t(`quickScreener.index.transactionId`)}</LabelText>
              <ValueText>{transactionId}</ValueText>
            </InfoRow>
            <InfoRow>
              <LabelText>{t(`admin.result.title`)}</LabelText>
              <ValueText>{delayedText}</ValueText>
            </InfoRow>
            {agentId && (
              <InfoRow>
                <LabelText>
                  {t(`quickScreener.index.nanshanMemberProjectCode`)}
                </LabelText>
                <ValueText>{agentId}</ValueText>
              </InfoRow>
            )}
          </RowContainer>
        </InfoContainer>
      </FlexBetweenContainer>
    </ResultListSegment>
  );
}
// Styled components for row layout
