import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import api_address from "../../../constants/config";
import { useTranslation } from "react-i18next";
import { CURRENCY, PRODUCTS } from "../../../constants/common";
import {
  Container,
  ModalContent,
  Header,
  CloseButton,
  Content,
  TextArea,
  CopyButton,
} from "./style";
import { ADMIN_COOKIE_NAME } from "constants/admin";
import { JS_COOKIE } from "util/auth";
import { Radio, Button as AntButton } from "antd";
import { BackButton } from "components/Admin/styles";
import { X } from "lucide-react";

interface optionType {
  text: string;
  value: string;
}

interface CouponType {
  couponCode: string;
  currency: string;
  applyTo: Array<string>;
  applyAll: boolean;
}

interface Props {
  showReferralLinkModal: boolean;
  handleCloseReferralLinkModal: () => void;
  applyTo: string[];
  applyAll: boolean;
  couponCode: string;
  currency: string;
}

export default function ReferralLinkModal(props: Props) {
  const [productList, setProductList] = useState([] as Array<string>);
  const [product, setProduct] = useState("" as string);
  const [referralLink, setReferralLink] = useState("" as string);

  const { t } = useTranslation();

  const productMap = useMemo(() => {
    return t("admin.product", { returnObjects: true });
  }, [t]);

  useEffect(() => {
    const getData = async () => {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const productData = await axios.get(
        api_address + "api/admin/coupon/allActiveProduct",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const productList = productData.data.products.map(
        (product) => product.name
      );

      const filteredProductList = productList.filter((product) =>
        props.applyTo ? props.applyTo.includes(product) : false
      );
      console.log("productList", productList, filteredProductList);

      setProductList(props.applyAll ? productList : filteredProductList);
    };
    getData();
  }, [productMap, props.applyTo, props.applyAll]);

  useEffect(() => {
    let referralLink = "";
    let currency = props.currency ? props.currency : "cny";
    if (product !== "") {
      referralLink +=
        window.location.origin +
        "/purchases-payment?product=" +
        product +
        "&currency=" +
        currency +
        "&coupon=" +
        props.couponCode;
      if (product === PRODUCTS.CPCBuyUp) {
        referralLink += "&isBuyUp=true";
      }
    }
    setReferralLink(referralLink);
  }, [product]);

  const handleProductChange = (event: any) => {
    setProduct(event.target.value);
  };

  const handleClose = () => {
    setProduct("");
    props.handleCloseReferralLinkModal();
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink);
  };

  return (
    <Container id="ModalContainer" show={props.showReferralLinkModal}>
      <ModalContent id="ModalContent">
        <div style={{ display: "flex" }}>
          <BackButton onClick={handleClose}>
            <X />
          </BackButton>
        </div>
        <Content>
          <Header>{t("admin.referralLink.selectProduct")}</Header>
          <Radio.Group
            onChange={handleProductChange}
            value={product}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {productList.map((prod) => (
              <Radio.Button
                key={prod}
                value={prod}
                style={{ marginBottom: "5px" }}
              >
                {prod}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Content>

        <Content>
          <TextArea disabled={true} value={referralLink} />
          <CopyButton onClick={handleCopyLink}>
            {t("admin.referralLink.copy")}
          </CopyButton>
        </Content>
      </ModalContent>
    </Container>
  );
}
