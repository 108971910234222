import styled from "styled-components";
import { ButtonStyle, ContentTitle, Description } from "styles";

export const FlexBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

export const InfoAndActionContainer = styled.div`
  display: block;
  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
`;

export const ContentTitleContainer = styled.div`
  min-width: max-content;
`;

export const ContentContainer = styled.div`
  text-align: left;
  min-width: max-content;
  padding-left: 20px;
`;

export const InfoContainer = styled.div`
  display: flex;
  padding-top: 20px;
`;

export const ProductName = styled(ContentTitle)``;

export const GreyInfoText = styled(Description)``;

export const InfoText = styled(Description)``;

export const PurchaseButton = styled(ButtonStyle)`
  height: 44px;
  background: #6898e3;
  color: white;
  width: 100%;
  @media only screen and (min-width: 768px) {
    width: inherit;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
  color: #606060;
`;

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // margin-bottom: 8px;
`;

export const LabelText = styled.span`
  text-align: left;
  min-width: 160px; // Adjust based on your longest label to ensure alignment
`;

export const ValueText = styled.span`
  text-align: left;
  flex-grow: 1; // Ensures it takes up the remaining space
`;

export const TitleText = styled(Description)`
  font-size: 16px;
  font-weight: bold;
  color: black;
  margin: 10px 0px;
`;
