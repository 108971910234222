import styled from "styled-components";

export const FollowUsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
`;

export const QRContainer = styled.div<{
  type: "facebook" | "WeChat" | "official";
}>`
  --main-color: ${(props) => {
    switch (props.type) {
      case "facebook":
        return "#007bf9";
      case "WeChat":
        return "#30ab47";
      case "official":
        return "#00bdb0";
      default:
        return "#00bdb0";
    }
  }};
  --border: 8px;
  --bottom: 60px;
  position: relative;
  margin: 0 5px;
  border-radius: 4px;
  box-shadow: 0 var(--bottom) 0 var(--border) var(--main-color),
    0 0 0 var(--border) var(--main-color), var(--border) 0 0 0 var(--main-color),
    calc(-1 * var(--border)) 0 0 0 var(--main-color);
`;

export const QRTitle = styled.div`
  position: absolute;
  color: white;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  top: calc(100% + 15px);
  font-size: 22px;
`;

export const FollowUsPhoto = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 4px;
`;
