import { useTranslation } from "react-i18next";
import {
  List,
  OptionText,
  OrderList,
  QuestionText,
  Segment,
  Title,
  TitleIndicate,
  UnOrderList,
} from "../../styles";

function CoreIntro() {
  const { t } = useTranslation();
  return (
    <>
      <Segment>
        <TitleIndicate />
        <Title>{t("user.survey.core.questionnaire.intro.core.welcome")}</Title>
      </Segment>
      <Segment>
        <QuestionText>
          {t("user.survey.core.questionnaire.intro.core.read")}
        </QuestionText>
        <OrderList>
          <List>
            <OptionText>
              {t("user.survey.core.questionnaire.intro.core.time")}
            </OptionText>
          </List>
          <List>
            <OptionText>
              {t("user.survey.core.questionnaire.intro.core.1.text")}
            </OptionText>
          </List>
          <UnOrderList>
            <List>
              <OptionText>
                {t("user.survey.core.questionnaire.intro.core.1.part1")}
              </OptionText>
            </List>
            <List>
              <OptionText>
                {t("user.survey.core.questionnaire.intro.core.1.part2")}
              </OptionText>
            </List>
          </UnOrderList>
          <List>
            <OptionText>
              {t("user.survey.core.questionnaire.intro.core.2.system")}
              <b>{t("user.survey.core.questionnaire.intro.core.2.save")}</b>
              {t("user.survey.core.questionnaire.intro.core.2.continue")}
            </OptionText>
          </List>
          <List>
            <OptionText>
              {t("user.survey.core.questionnaire.intro.core.3.questionnaire")}
              <b>{t("user.survey.core.questionnaire.intro.core.3.required")}</b>
              {t("user.survey.core.questionnaire.intro.core.3.repetitive")}
            </OptionText>
          </List>
        </OrderList>
      </Segment>
    </>
  );
}

export default CoreIntro;
