import styled from "styled-components";

export const ExpertTeamContainer = styled.div`
  margin: 0 8.75%;
  padding-top: 50px;
`;

export const TherapistContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
`;

export const TherapistPhoto = styled.img`
  height: 170px;
  width: 170px;
  border-radius: 8px;
`;

export const InfoContainer = styled.div`
  margin: 9px 0px 0px 52px;
`;

export const TherapistNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

export const DescriptionContainer = styled.div`
  margin: 0px 0px 0px 4px;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
`;

export const HistoryContainer = styled.div`
  margin: 15px 0px 0px 0px;
`;

export const History = styled.li`
  list-style-position: outside;
  align-items: flex-start;
`;

export const BioContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: 4px;
`;

export const Paragraph = styled.p`
  text-align: justify;
`;

export const ExpertTeamTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  color: #000000;
  margin-bottom: 70px;
  text-align: center;
`;

export const TherapistName = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  line-height: normal;
`;

export const TherapistTitle = styled.div`
  font-size: 14px;
  line-height: normal;
`;
