import { fromJS } from "immutable";

import {
  UPDATE_QUESTIONNAIRE_STEP,
  UPDATE_QUESTIONNAIRE_STEP_DATA,
  UPDATE_QUESTIONNAIRE_META,
  FETCH_QUESTIONNAIRE_SUCCESS,
  FETCH_QUESTIONNAIRE_FAIL,
  FETCH_RESULT_SUCCESS,
  FETCH_RESULT_FAIL,
  RESET,
  ENABLE_LOADING,
  DISABLE_LOADING,
  SAVE_SUCCESS,
  SAVE_FAIL,
  REDIRECT,
  REDIRECT_FAIL,
  FETCH_MOST_RECENT_USER_RESULT_SUCCESS,
  ENABLE_QUESTIONNAIRE_LOADING,
  DISABLE_QUESTIONNAIRE_LOADING,
} from "./constants";
import { QuestionnaireActionTypes } from "types/questionnaireActions";

const questionnaireInitialState = fromJS({
  meta: {},
  currentstep: "one",
  steps: {
    sound_collection: {
      values: {},
    },
    child_info: {
      values: {},
    },
    questionnaire: {
      values: {},
    },
    four: {
      values: {},
    },
    five: {
      values: {},
    },
  },
  request: {},
  loading: true,
  isFetchFail: false,
  isSaveFail: false,
  isRedirectFail: false,
  saveStepsResponse: {},
  saveResponse: {},
  redirect: {},
  isNewQuestionnaire: true,
  mostRecentResultid: "",
  transactionId: "",
  questionnaireLoading: true,
  completed: false,
  video: 0,
  sentEmail: false,
  report: "",
});

function questionnaireReducer(
  state: any = questionnaireInitialState,
  action: QuestionnaireActionTypes | any
) {
  switch (action.type) {
    case FETCH_RESULT_SUCCESS:
      return state.merge(fromJS(action.data));
    case UPDATE_QUESTIONNAIRE_STEP:
      return state.set("currentstep", action.data);
    case UPDATE_QUESTIONNAIRE_STEP_DATA:
      return state.set("steps", fromJS(action.data));
    case UPDATE_QUESTIONNAIRE_META:
      return state.set("meta", fromJS(action.data));
    case RESET:
      return questionnaireInitialState;
    case FETCH_QUESTIONNAIRE_SUCCESS:
      return state.set("request", fromJS(action.data.questionnaire));
    case FETCH_QUESTIONNAIRE_FAIL:
    case FETCH_RESULT_FAIL:
      return state.set("isFetchFail", true);
    case ENABLE_LOADING:
      return state.set("loading", true);
    case DISABLE_LOADING:
      return state.set("loading", false);
    case SAVE_SUCCESS:
      return state.merge({
        saveResponse: action.data,
        isSaveFail: false,
      });
    case SAVE_FAIL:
      return state.set("isSaveFail", true);
    case REDIRECT:
      return state.set(
        "redirect",
        fromJS({
          pathname: action.path,
        })
      );
    case REDIRECT_FAIL:
      return state.set("isRedirectFail", true);
    case FETCH_MOST_RECENT_USER_RESULT_SUCCESS:
      if (action.data.currentstep !== "one") {
        const {
          meta,
          steps,
          currentstep,
          transactionid,
          completed,
          videoid,
          report,
        } = action.data;

        return state
          .set("meta", fromJS(meta))
          .set("steps", fromJS(steps))
          .set("currentstep", currentstep)
          .set("mostRecentResultId", transactionid)
          .set("isNewQuestionnaire", false)
          .set("completed", completed)
          .set("video", videoid)
          .set("report", report);
      }
      return state;
    case ENABLE_QUESTIONNAIRE_LOADING:
      return state.set("questionnaireLoading", true);
    case DISABLE_QUESTIONNAIRE_LOADING:
      return state.set("questionnaireLoading", false);
    default:
      return state;
  }
}

export default questionnaireReducer;
