import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ProTable } from "@ant-design/pro-components";
import { Button, Input, ConfigProvider } from "antd";
import api_address from "../../../constants/config";
import { ADMIN_COOKIE_NAME, LIMIT } from "../../../constants/admin";
import { GET_DATE_TIME_STRING_FROM_TIMESTAMP } from "../../../util/common";
import { JS_COOKIE } from "util/auth";
import { AdminTableContainer } from "../styles";
import enUS from "antd/es/locale/en_US";

interface OrganizationType {
  id: string;
  name: string;
  organizationCode: string;
  defaultProductId: string;
  createdAt: string;
}

interface SorterState {
  field: string;
  order: "ascend" | "descend" | undefined;
}

const Organization: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [organizationData, setOrganizationData] = useState<OrganizationType[]>(
    []
  );
  const [filteredData, setFilteredData] = useState<OrganizationType[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [sorter, setSorter] = useState<SorterState | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(25);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const getOrganizationData = async (
    page: number = 1,
    pageSize: number = LIMIT
  ) => {
    setLoading(true);
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const offset = (page - 1) * pageSize;
      const { data } = await axios.get(api_address + "api/admin/organization", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          offset: offset,
          limit: pageSize,
        },
      });
      const sortedData = sortData(data.organization, sorter);
      setOrganizationData(sortedData);
      setFilteredData(sortedData);
      setTotal(data.rowCount);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrganizationData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  useEffect(() => {
    applyFiltersAndSort();
  }, [sorter, organizationData, searchText]);

  const applyFiltersAndSort = () => {
    let result = [...organizationData];

    if (searchText) {
      result = result.filter((org) =>
        Object.values(org).some((value) =>
          String(value).toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }

    result = sortData(result, sorter);

    setFilteredData(result);
    setTotal(result.length);
  };

  const sortData = (
    data: OrganizationType[],
    currentSorter: SorterState | null
  ) => {
    if (!currentSorter) return data;

    return [...data].sort((a, b) => {
      if (currentSorter.order === "ascend") {
        return a[currentSorter.field] > b[currentSorter.field] ? 1 : -1;
      } else if (currentSorter.order === "descend") {
        return a[currentSorter.field] < b[currentSorter.field] ? 1 : -1;
      }
      return 0;
    });
  };

  const handleTableChange = (
    pagination: { current: number; pageSize: number },
    filters: any,
    sorter: any
  ) => {
    const newSorter: SorterState = {
      field: sorter.field,
      order: sorter.order,
    };
    setSorter(newSorter);
    setPageSize(pagination.pageSize);
    setCurrentPage(pagination.current);
    getOrganizationData(pagination.current, pagination.pageSize);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  const columns = [
    {
      title: t("admin.organization.id"),
      dataIndex: "id",
      sorter: true,
    },
    {
      title: t("admin.organization.name"),
      dataIndex: "name",
      sorter: true,
    },
    {
      title: t("admin.organization.code"),
      dataIndex: "organizationCode",
      sorter: true,
    },
    {
      title: t("admin.organization.defaultProductId"),
      dataIndex: "defaultProductId",
      sorter: true,
    },
    {
      title: t("admin.user.createdAt"),
      dataIndex: "createdAt",
      render: GET_DATE_TIME_STRING_FROM_TIMESTAMP,
      sorter: true,
    },
  ];

  return (
    <ConfigProvider locale={enUS}>
      <AdminTableContainer>
        <Input.Search
          placeholder={"Organization"}
          onChange={handleSearch}
          style={{ marginBottom: 16 }}
        />
        <ProTable<OrganizationType>
          columns={columns}
          dataSource={filteredData}
          loading={loading}
          rowKey="id"
          pagination={{
            showQuickJumper: true,
            total: total,
            pageSize: pageSize,
            current: currentPage,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "25", "50", "100"],
          }}
          search={false}
          dateFormatter="string"
          headerTitle={"Organization"}
          rowSelection={{
            onChange: (selectedRowKeys) => {
              setSelectedRowKeys(selectedRowKeys);
            },
          }}
          toolBarRender={() => [
            <Button
              key="create"
              onClick={() => history.push("/admin/organization/create")}
            >
              {t("admin.user.create")}
            </Button>,
            selectedRowKeys.length === 1 && (
              <Button
                key="edit"
                onClick={() =>
                  history.push(`/admin/organization/edit/${selectedRowKeys[0]}`)
                }
              >
                {t("admin.user.edit")}
              </Button>
            ),
          ]}
          onChange={handleTableChange}
        />
      </AdminTableContainer>
    </ConfigProvider>
  );
};

export default Organization;
