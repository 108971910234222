import { createSelector } from "reselect";
import { AppState } from "storeConfig";

const selectQuestionnaire = (state: AppState) => state.get("questionnaire");

const makeSelectCurrentStep = () =>
  createSelector(selectQuestionnaire, (questionnaireState) =>
    questionnaireState.get("currentstep")
  );

const makeSelectCreatedAt = () =>
  createSelector(selectQuestionnaire, (questionnaireState) =>
    questionnaireState.get("createdAt")
  );

const makeSelectStep = () =>
  createSelector(selectQuestionnaire, (questionnaireState) =>
    questionnaireState.get("steps")
  );
const makeSelectMeta = () =>
  createSelector(selectQuestionnaire, (questionnaireState) =>
    questionnaireState.get("meta")
  );

const makeSelectRequest = () =>
  createSelector(selectQuestionnaire, (questionnaireState) =>
    questionnaireState.get("request")
  );

const makeSelectLoading = () =>
  createSelector(selectQuestionnaire, (questionnaireState) =>
    questionnaireState.get("loading")
  );

const makeIsFetchFail = () =>
  createSelector(selectQuestionnaire, (questionnaireState) =>
    questionnaireState.get("isFetchFail")
  );

const makeIsSaveFail = () =>
  createSelector(selectQuestionnaire, (questionnaireState) =>
    questionnaireState.get("isSaveFail")
  );

const makeIsRedirectFail = () =>
  createSelector(selectQuestionnaire, (questionnaireState) =>
    questionnaireState.get("isRedirectFail")
  );

const makeSaveStepsResponse = () =>
  createSelector(selectQuestionnaire, (questionnaireState) =>
    questionnaireState.get("saveStepsResponse")
  );

const makeSaveResponse = () =>
  createSelector(selectQuestionnaire, (questionnaireState) =>
    questionnaireState.get("saveResponse")
  );

const makeSelectRedirect = () =>
  createSelector(selectQuestionnaire, (questionnaireState) =>
    questionnaireState.get("redirect")
  );

const makeIsNewQuestionnaire = () =>
  createSelector(selectQuestionnaire, (questionnaireState) =>
    questionnaireState.get("isNewQuestionnaire")
  );

const makeMostRecentResultId = () =>
  createSelector(selectQuestionnaire, (questionnaireState) =>
    questionnaireState.get("mostRecentResultId")
  );

const makeSelectResultId = () =>
  createSelector(selectQuestionnaire, (questionnaireState) =>
    questionnaireState.get("transactionid")
  );

const makeSelectQuestionnaireLoading = () =>
  createSelector(selectQuestionnaire, (questionnaireState) =>
    questionnaireState.get("questionnaireLoading")
  );

const makeSelectSentEmail = () =>
  createSelector(selectQuestionnaire, (questionnaireState) =>
    questionnaireState.get("sentemail")
  );

const makeSelectCompleted = () =>
  createSelector(selectQuestionnaire, (questionnaireState) =>
    questionnaireState.get("completed")
  );

const makeSelectVideo = () =>
  createSelector(selectQuestionnaire, (questionnaireState) =>
    questionnaireState.get("video")
  );

const makeSelectReport = () =>
  createSelector(selectQuestionnaire, (questionnaireState) =>
    questionnaireState.get("report")
  );

export {
  makeSelectCurrentStep,
  makeSelectCreatedAt,
  makeSelectStep,
  makeSelectMeta,
  makeSelectRequest,
  makeSelectLoading,
  makeIsFetchFail,
  makeSaveStepsResponse,
  makeSaveResponse,
  makeIsSaveFail,
  makeIsRedirectFail,
  makeSelectRedirect,
  makeIsNewQuestionnaire,
  makeMostRecentResultId,
  makeSelectQuestionnaireLoading,
  makeSelectSentEmail,
  makeSelectCompleted,
  makeSelectVideo,
  makeSelectReport,
  makeSelectResultId,
};
