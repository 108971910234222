import { Form } from "antd";
import {
  OauthRegisterContainer,
  OauthRegisterText,
  OauthRegisterTitle,
  OauthRegisterButton,
  ModalMask,
} from "./styles";
import { Input } from "antd";
import { getCountryCodeList, getCountryInitial } from "constants/countryCode";
import { useState, useEffect } from "react";
import DropDown from "components/common/DropDown";

import { connect } from "react-redux";
import { History, LocationState } from "history";
import { bindActionCreators, Dispatch } from "redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { REGISTER_VALIDATION, REGISTER_SUBMIT } from "util/auth";
import { ROLE } from "../../../../constants/common";
import {
  startLoginSuccess,
  startUpdateUserToken,
} from "components/auth/actions";
import { TermsLink, TermsText } from "components/auth/UserAuthForm/styles";
import { Dropdown } from "semantic-ui-react";
import countryList from "constants/countryList";
import { getUserBrowserTz } from "constants/timeZone";
import NotificationModal from "../../../common/NotificationModal";
interface OauthRegisterModalProps extends WithTranslation {
  history: History<LocationState>;
  display: boolean;
  title: string;
  text: string;
  buttonText: string;
  notNowText: string;
  location: any;
  onUpdateUserToken: (data: string) => void;
  onLoginSuccess: (data: string) => void;
  onClick: () => void;
  onNotNowClick: () => void;
}

const OauthRegisterModal = (props: OauthRegisterModalProps) => {
  const { t } = props;
  const [countryCode, setCountryCode] = useState("+1");
  const [country, setCountry] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);
  const [errors, setErrors] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState(false);

  useEffect(() => {}, [showModal]);

  const displayErrorMessage = (message: string) => {
    setErrors(message);
    setShowModal(true);

    setTimeout(() => {
      setShowModal(false);
      setErrors("");
    }, 3000);
  };

  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (value === "1234567890") {
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }
  };

  const onIsAgreeClick = () => {
    setIsAgreed(!isAgreed);
  };

  const onTermsClick = () => {
    window.open("/terms-conditions");
  };
  const onPrivacyClick = () => {
    window.open("/privacy-policy");
  };

  const countryCodes = getCountryCodeList(t);

  useEffect(() => {
    const countryInitial = getCountryInitial(countryCode);
    for (let i = 0; i < countryList.length; i++) {
      if (countryList[i].key === countryInitial?.toLowerCase()) {
        setCountry(countryList[i].key);
      }
    }
  }, [countryCode]);

  // const handleCountryChange = (event, { value }) => {
  //   setCountry(value);
  // };
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const newUser = props.location.state.newUser;
      newUser.phone = values.phoneNumber;
      newUser.countryCode = countryCode;
      newUser.country = country;
      newUser.timeZone = getUserBrowserTz();
      REGISTER_VALIDATION(newUser);
      const token = await REGISTER_SUBMIT(newUser);
      props.onUpdateUserToken(token);
      props.onLoginSuccess(ROLE.user);
      props.history.push(props.location.state.redirectLink);
      props.onClick();
    } catch (error) {
      console.log(error);
      displayErrorMessage(error.message);
    }
  };
  return (
    <>
      {props.display && (
        <ModalMask open={true}>
          <OauthRegisterContainer>
            <OauthRegisterTitle>{props.title}</OauthRegisterTitle>
            <OauthRegisterText>{props.text}</OauthRegisterText>
            <p>
              <b>{t("auth.register.firstName")}: </b>
              {props.location.state.newUser.firstName}
            </p>
            {props.location.state.newUser.lastName !== "" ? (
              <p>
                <b>{t("auth.register.lastName")}: </b>
                {props.location.state.newUser.lastName}
              </p>
            ) : null}
            <p>
              <b>{t("auth.register.email")}: </b>
              {props.location.state.newUser.email}
            </p>
            <Form form={form} onFinish={onFinish}>
              <Input.Group compact style={{ width: "320px" }}>
                <DropDown
                  componentName="Register"
                  type="Country code"
                  defaultSelection={countryCode}
                  selectedText={countryCode}
                  onOptionChange={setCountryCode}
                  options={countryCodes}
                  textMargin={"10px"}
                />
                <NotificationModal
                  show={showModal}
                  message={errors}
                  status={notificationStatus}
                />
                <Form.Item
                  name="phoneNumber"
                  noStyle
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value !== "1234567890") {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "Phone number cannot be 1234567890"
                        );
                      },
                    },
                  ]}
                  validateTrigger="onBlur"
                  hasFeedback
                >
                  <Input
                    placeholder={t("auth.register.phone")}
                    style={{ width: "50%", height: "50px" }}
                    onChange={handlePhoneNumberChange}
                  />
                </Form.Item>
                {phoneNumberError && (
                  <div style={{ color: "red", marginBottom: "5px" }}>
                    Phone number cannot be 1234567890
                  </div>
                )}
              </Input.Group>
              {/* <Dropdown
                placeholder={t("auth.login.chooseCountry")}
                fluid
                selection
                search
                onChange={handleCountryChange}
                options={countryList}
                style={{ width: "80%", left: "10%", marginBottom: "20px" }}
              /> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  margin: "0 40px 0 40px",
                  alignItems: "center",
                }}
              >
                <input
                  style={{ marginRight: "12px", cursor: "pointer" }}
                  type="checkbox"
                  name="isAgreed"
                  onChange={onIsAgreeClick}
                  checked={isAgreed}
                />
                <TermsText onClick={onIsAgreeClick}>
                  {t("auth.register.terms1")}
                  <TermsLink onClick={onTermsClick}>
                    {t("auth.register.terms2")}
                  </TermsLink>
                  {t("auth.register.terms3")}
                  <TermsLink onClick={onPrivacyClick}>
                    {t("auth.register.terms4")}
                  </TermsLink>
                </TermsText>
              </div>
              <OauthRegisterButton
                disabled={!isAgreed}
                htmlType="submit"
                marginTop={"20px"}
                marginBottom={"0px"}
              >
                {props.buttonText}
              </OauthRegisterButton>
              <p
                onClick={props.onNotNowClick}
                style={{ marginTop: "8px", color: "gray", cursor: "pointer" }}
              >
                {props.notNowText}
              </p>
            </Form>
          </OauthRegisterContainer>
        </ModalMask>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onUpdateUserToken: bindActionCreators(startUpdateUserToken, dispatch),
  onLoginSuccess: bindActionCreators(startLoginSuccess, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(OauthRegisterModal));
