import { Component, memo } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  MODULARIZED_EVAL_PROGRESSBAR_OFFSET,
  BJCH_PROGRESSBAR_OFFSET,
  SELF_EVAL_PROGRESSBAR_OFFSET,
  EXPERT_EVAL_PROGRESSBAR_OFFSET,
  MODULARIZED_EVAL_STEPS,
  BJCH_STEPS,
  SELF_EVAL_STEPS,
  EXPERT_EVAL_STEPS,
  EXPERT_EVAL_NO_REPORT_STEPS,
  EXPERT_EVAL_NO_REPORT_PROGRESSBAR_OFFSET,
  CHILD_INFO_ONLY_PROGRESSBAR_OFFSET,
  CHILD_INFO_ONLY_STEPS,
} from "../../../../constants/questionnaire";
import {
  EXPERT_PRODUCTS,
  MODULARIZED_PRODUCTS,
  SELF_PRODUCTS,
  PRODUCTS,
  EXPERT_PRODUCTS_WITHOUT_REPORT,
  PRODUCTS_WITH_CHILD_INFO_ONLY,
} from "../../../../constants/common";
import {
  Container,
  ProgressContainer,
  StepContainer,
  StepText,
  BarLine,
  ProgressBarContainer,
  StepCircle,
  // CircleContainer,
  TimeText,
  TimeContainer,
  TimeTextContainer,
} from "./styles";

interface ProgressBarProps {
  step: string;
  product: string;
}

interface ProgressBarState {
  steps: Array<string>;
  stepIndex: number;
  barWidth: string;
}

type Props = ProgressBarProps & WithTranslation;

class EvalProgressBar extends Component<Props, ProgressBarState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      steps: [],
      stepIndex: 0,
      barWidth: "",
    };
  }

  componentDidMount = () => {
    this.loadProgressBar();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (this.props !== prevProps) {
      this.loadProgressBar();
    }
  };

  loadProgressBar = () => {
    const { step } = this.props;
    const { steps, offset } = this.getStepInfo();
    const totalStepCount = steps.length;
    const stepIndex = steps.indexOf(step) + 1;
    const { barWidth } = this.calculatePercentage(
      stepIndex,
      totalStepCount,
      offset
    );
    this.setState({
      steps,
      stepIndex,
      barWidth,
    });

    const progressBar = document.getElementById("EvalProgressBar");
    if (!progressBar) {
      return;
    }
    const progressBarWidth = progressBar.scrollWidth;
    const scrollOffset =
      ((stepIndex[this.props.step || ""] - 2) / totalStepCount +
        (offset * 0.7) / 100) *
      progressBarWidth;
    progressBar.scrollTo({
      top: 0,
      left: scrollOffset,
      behavior: "smooth",
    });
  };

  isCompleted = (targetStep: string) => {
    const { stepIndex, steps } = this.state;
    const completed = stepIndex > steps.indexOf(targetStep);
    return completed;
  };

  stepCircle = (targetStep: string) => {
    const completed = this.isCompleted(targetStep);
    return <StepCircle completed={completed} />;
  };

  stepText = (targetStep: string, index: number) => {
    const { t } = this.props;
    const completed = this.isCompleted(targetStep);
    return (
      <StepText key={index} completed={completed}>
        {index + 1}
        {". "}
        {t(`user.survey.core.questionnaire.progressBar.${targetStep}`)}
      </StepText>
    );
  };

  stepTime = (targetStep: string, index: number) => {
    const { t, product } = this.props;
    return (
      <TimeTextContainer key={index}>
        <TimeText>
          {MODULARIZED_PRODUCTS.includes(product) &&
          targetStep === "questionnaire"
            ? t(
                "user.survey.core.questionnaire.progressBarTime.mod_questionnaire"
              )
            : t(`user.survey.core.questionnaire.progressBarTime.${targetStep}`)}
        </TimeText>
      </TimeTextContainer>
    );
  };

  calculatePercentage = (
    stepIndex: number,
    totalStepCount: number,
    offset: number
  ) => {
    const percentage = (stepIndex / totalStepCount) * 100;
    const barWidth =
      (percentage === 100 ? percentage : percentage - offset) + "%";
    return { barWidth, percentage };
  };

  getStepInfo = () => {
    const { product } = this.props;
    if (MODULARIZED_PRODUCTS.includes(product)) {
      return {
        steps: MODULARIZED_EVAL_STEPS,
        offset: MODULARIZED_EVAL_PROGRESSBAR_OFFSET,
      };
    } else if (product === PRODUCTS.BJCH_Questionnaire) {
      return {
        steps: BJCH_STEPS,
        offset: BJCH_PROGRESSBAR_OFFSET,
      };
    } else if (PRODUCTS_WITH_CHILD_INFO_ONLY.includes(product)) {
      return {
        steps: CHILD_INFO_ONLY_STEPS,
        offset: CHILD_INFO_ONLY_PROGRESSBAR_OFFSET,
      };
    } else if (SELF_PRODUCTS.includes(product)) {
      return {
        steps: SELF_EVAL_STEPS,
        offset: SELF_EVAL_PROGRESSBAR_OFFSET,
      };
    } else if (EXPERT_PRODUCTS.includes(product)) {
      return {
        steps: EXPERT_EVAL_STEPS,
        offset: EXPERT_EVAL_PROGRESSBAR_OFFSET,
      };
    } else if (EXPERT_PRODUCTS_WITHOUT_REPORT.includes(product)) {
      return {
        steps: EXPERT_EVAL_NO_REPORT_STEPS,
        offset: EXPERT_EVAL_NO_REPORT_PROGRESSBAR_OFFSET,
      };
    } else {
      return {
        steps: [],
        offset: 0,
      };
    }
  };

  render = () => {
    const { steps, barWidth } = this.state;
    return (
      <Container id="EvalProgressBar">
        <ProgressBarContainer>
          <ProgressContainer>
            <BarLine barWidth={barWidth} />
            {/* <CircleContainer>
              {steps.map((step: string) => this.stepCircle(step))}
            </CircleContainer> */}
          </ProgressContainer>
          <StepContainer>
            {steps.map((step: string, index: number) =>
              this.stepText(step, index)
            )}
          </StepContainer>
          <TimeContainer>
            {steps.map((step: string, index: number) =>
              this.stepTime(step, index)
            )}
          </TimeContainer>
        </ProgressBarContainer>
      </Container>
    );
  };
}

export default withTranslation()(memo(EvalProgressBar));
