const activityMap = {
  expression: {
    0: 9,
    1: 8,
    2: 7,
    3: 6,
    4: 5,
    5: 4,
    6: 3,
    7: 2,
    8: 1,
  },
  comprehension: {
    0: 9,
    1: 8,
    2: 7,
    3: 6,
    4: 5,
    5: 4,
    6: 3,
    7: 2,
    8: 1,
  },
  social: {
    0: 7,
    1: 6,
    2: 5,
    3: 4,
    4: 3,
    5: 2,
    6: 1,
  },
  oral: {},
  preliteracy: {},
};

export const getSolutionIndexByType = (type: string, age: number) => {
  if (type === "comprehension" || type === "expression") {
    if (age <= 6) return activityMap[type][0];
    if (age <= 12) return activityMap[type][1];
    if (age <= 18) return activityMap[type][2];
    if (age <= 24) return activityMap[type][3];
    if (age <= 30) return activityMap[type][4];
    if (age <= 38) return activityMap[type][5];
    if (age <= 53) return activityMap[type][6];
    if (age <= 65) return activityMap[type][7];
    return 8;
  }
  if (type === "social") {
    if (age <= 6) return activityMap[type][0];
    if (age <= 12) return activityMap[type][1];
    if (age <= 25) return activityMap[type][2];
    if (age <= 38) return activityMap[type][3];
    if (age <= 53) return activityMap[type][4];
    if (age <= 65) return activityMap[type][5];
    return 6;
  }
  if (type === "oral") {
    if (age <= 6) return 0;
    if (age <= 12) return 1;
    if (age <= 25) return 2;
    if (age <= 38) return 3;
    return 4;
  }
  if (type === "preliteracy") {
    if (age <= 25) return 0;
    if (age <= 38) return 1;
    if (age <= 53) return 2;
    if (age <= 65) return 3;
    return 4;
  }
  return 0;
};

export const getScoreWithSection = (
  section
): { total: number; display: number } => {
  const scoreValuesOneDim = section.scores.flat();
  const total = scoreValuesOneDim.length;
  const display = scoreValuesOneDim.filter((score: number) => score > 0).length;

  return {
    total,
    display,
  };
};
