import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import api_address from "../../../../constants/config";
import NotificationModal from "../../../common/NotificationModal";
import TableModal from "../../../common/TableModal";
import { match } from "react-router-dom";
import { History, LocationState } from "history";
import DropDown from "../../../common/DropDown";
import { COUPON_DISCOUNT_TYPE } from "../../../../constants/common";
import {
  CURRENCY_LIST,
  CURRENCY_OPTIONS,
  CURRENCY_OPTIONS_COUPON,
} from "../../../../constants/payment";
import { ChevronLeft } from "lucide-react";
import { Radio, Button } from "antd";
import {
  Container,
  ContentContainer,
  ContentSubtitle,
  MaxRedemptionPerCoupon,
  Input,
  PercentOffContainer,
  SaveButton,
  CancelButton,
  CreateButton,
  BackButton,
  UtcNotice,
} from "../../styles";
import {
  DiscountContainer,
  DiscountTitle,
  DiscountLabel,
  DiscountInfoContainer,
  DiscountInfoInput,
  DiscountInfoSubtitle,
  DiscountInfoPostfix,
  ApplyToContainer,
  ApplyContainer,
  ApplyTitle,
  ApplyToOption,
  DayAndHour,
  DayAndHourText,
  DescriptionTextArea,
} from "../styles";
import {
  DiscountTypeContainer,
  ApplyAllContainer,
  OtherContainer,
} from "./styles";
import moment from "moment";
import { DAYS_AND_HOURS_BETWEEN_TWO_DATE } from "../../../../../src/util/common";
import { JS_COOKIE } from "util/auth";
import { ADMIN_COOKIE_NAME } from "constants/admin";

interface Match {
  productId: string;
}

interface CreateCouponProps {
  history: History<LocationState>;
  match: match<Match>;
}

interface productType {
  id: number;
  name: string;
}

interface CreateCouponStates {
  [key: string]:
    | string
    | number
    | Array<number>
    | Array<string>
    | boolean
    | Array<productType>;
  products: Array<productType>;
  number: string;
  prefix: string;
  amountOff: string;
  applyAll: string;
  applyTo: Array<number>;
  currency: string;
  discountType: string;
  maxDiscount: string;
  maxRedemption: string;
  offRate: string;
  partnerId: number;
  redeemBy: string;
  redeemByDays: number;
  redeemByHours: number;
  redeemStart: string;
  message: string;
  showModal: boolean;
  showTableModal: boolean;
  tableData: Array<string>;
  notificationStatus: boolean;
  comment: string;
}

type Props = CreateCouponProps & WithTranslation;
class CreateCoupon extends Component<Props, CreateCouponStates> {
  constructor(props: Props) {
    super(props);
    const { productId } = props.match.params;
    this.state = {
      products: [],
      number: "1",
      prefix: "",
      amountOff: "",
      applyAll: "false",
      applyTo: productId === "0" ? [] : [parseInt(productId)],
      currency: "cny",
      discountType: COUPON_DISCOUNT_TYPE.percent,
      maxDiscount: "",
      maxRedemption: "",
      offRate: "",
      partnerId: 0,
      redeemBy: "",
      redeemByDays: 0,
      redeemByHours: 0,
      redeemStart: "",
      message: "",
      showModal: false,
      showTableModal: false,
      tableData: [],
      notificationStatus: false,
      comment: "",
    };
  }

  componentDidMount = () => {
    const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
    axios
      .get(api_address + "api/admin/coupon/allActiveProduct", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const { products } = res.data;
        const productList = products.map((product) => {
          return {
            name: product.name,
            id: product.id,
          };
        });
        this.setState({
          products: productList,
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  handleClearClick = () => {
    this.setState({
      prefix: "",
      number: "1",
      amountOff: "",
      applyAll: "false",
      applyTo: [],
      currency: CURRENCY_LIST[0],
      discountType: COUPON_DISCOUNT_TYPE.percent,
      maxDiscount: "",
      maxRedemption: "",
      offRate: "",
      redeemBy: "",
      redeemByDays: 0,
      redeemByHours: 0,
      comment: "",
    });
  };

  handleStringInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = event.currentTarget;
    this.setState({ [id]: value });
  };

  handleNumberInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    if (`${Number(value)}` === value) {
      this.setState({ [id]: +value });
    } else if (value === "") {
      this.setState({ [id]: value });
    }
  };

  handleCurrencyChange = (value: string) => {
    if (value != null)
      this.setState({
        currency: value,
      });
  };

  handlePercentClick = () => {
    this.setState({
      discountType: COUPON_DISCOUNT_TYPE.percent,
      amountOff: "",
    });
  };

  handleAmountClick = () => {
    this.setState({
      discountType: COUPON_DISCOUNT_TYPE.amount,
      offRate: "",
      maxDiscount: "",
    });
  };

  handleApplyAllChange = (value: string) => {
    if (value != null) {
      let { applyTo } = this.state;
      if (value === "true") {
        applyTo = [];
      }
      this.setState({
        applyAll: value,
        applyTo: applyTo,
      });
    }
  };

  handleApplyToOptionClick = (id) => {
    let { applyTo } = this.state;
    const isSelected = applyTo.includes(id);

    if (isSelected) {
      applyTo = applyTo.filter((item) => item !== id);
    } else {
      applyTo.push(id);
    }

    this.setState({
      applyTo,
      applyAll:
        applyTo.length === this.state.products.length ? "true" : "false",
    });
  };

  handleRedeemByChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const { daysFromNow, hoursFromNow } = DAYS_AND_HOURS_BETWEEN_TWO_DATE(
      moment().format("YYYY-MM-DD HH:mm:ss"),
      value
    );
    this.setState({
      redeemBy: value,
      redeemByDays: daysFromNow,
      redeemByHours: hoursFromNow,
    });
  };

  onCancelClick = () => {
    this.props.history.push("/admin/coupon");
  };

  onFinishClick = () => {
    this.setState({
      showTableModal: false,
    });
    this.props.history.push("/admin/coupon");
  };

  onSaveClick = () => {
    try {
      this.validation();
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const {
        prefix,
        number,
        currency,
        discountType,
        offRate,
        maxDiscount,
        amountOff,
        applyAll,
        applyTo,
        redeemBy,
        maxRedemption,
        comment,
      } = this.state;
      axios
        .post(
          api_address + "api/admin/coupon/",
          {
            prefix,
            number,
            currency,
            discountType,
            offRate,
            maxDiscount,
            amountOff,
            applyAll,
            applyTo,
            maxRedemption,
            redeemBy: redeemBy !== "" ? redeemBy : undefined,
            comment,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const { status, message } = res.data;
          if (status) {
            this.setState({
              tableData: res.data.couponData.couponCode,
              showTableModal: true,
            });
          } else {
            this.displayErrorMessage(message);
          }
        })
        .catch((err) => {
          console.error(err.message);
          this.displayErrorMessage(err.message);
        });
    } catch (error: any) {
      console.error(error.message);
      this.displayErrorMessage(error.message);
    }
  };
  validation = () => {
    const {
      discountType,
      offRate,
      amountOff,
      applyAll,
      applyTo,
      maxRedemption,
    } = this.state;
    const { percent, amount } = COUPON_DISCOUNT_TYPE;
    if (discountType === percent) {
      if (parseInt(offRate) > 100 || parseInt(offRate) < 0) {
        throw new Error("offRate must between 0 and 100");
      }
    } else if (discountType === amount) {
      if (amountOff === "") {
        throw new Error("amountOff can't be empty");
      }
    }
    if (applyAll === "false") {
      if (applyTo.length === 0) {
        throw new Error("applyTo can't be empty");
      }
    }
    if (maxRedemption === "") {
      throw new Error("maxRedemption can't be empty");
    }
  };

  displayErrorMessage = (message: string) => {
    this.setState({
      message: message,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  handleApplyAllToggle = (isChecked) => {
    if (isChecked) {
      this.setState({
        applyAll: "true",
        applyTo: this.state.products.map((p) => p.id),
      });
    } else {
      this.setState({ applyAll: "false", applyTo: [] });
    }
  };

  render = () => {
    const { t } = this.props;
    const {
      showModal,
      message,
      notificationStatus,
      currency,
      discountType,
      offRate,
      maxDiscount,
      amountOff,
      applyAll,
      applyTo,
      products,
      redeemBy,
      redeemByDays,
      redeemByHours,
      maxRedemption,
      prefix,
      number,
      tableData,
      showTableModal,
      comment,
    } = this.state;
    const ApplyAllOptions = [
      { text: "true", value: "true" },
      { text: "false", value: "false" },
    ];
    return (
      <Container>
        <UtcNotice>{t("admin.result.utcNote")}</UtcNotice>
        <TableModal
          data={tableData}
          show={showTableModal}
          handleClose={this.onFinishClick}
        />
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />
        <BackButton onClick={this.onCancelClick} style={{ marginTop: "20px" }}>
          <ChevronLeft />
        </BackButton>
        <ContentSubtitle style={{ fontSize: "32px" }}>
          Create Coupon
        </ContentSubtitle>
        <Button
          onClick={this.handleClearClick}
          type="primary"
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: 1,
            backgroundColor: "red",
          }}
        >
          Clear
        </Button>
        <DiscountContainer
          style={{
            display: "flex",
            flexDirection: "row",
            border: "none",
          }}
        >
          <ContentContainer style={{ flex: 1 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ContentSubtitle style={{ textAlign: "center" }}>
                {t("admin.payment.currency")}
              </ContentSubtitle>
              <Radio.Group
                onChange={(e) => this.setState({ currency: e.target.value })}
                value={currency}
                style={{ display: "flex", flexDirection: "column" }}
              >
                {CURRENCY_OPTIONS_COUPON.map((option) => (
                  <Radio
                    value={option.value}
                    key={option.value}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span style={{ marginLeft: "25px", fontSize: "20px" }}>
                      {option.label}
                    </span>{" "}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          </ContentContainer>

          <DiscountTypeContainer
            style={{
              flex: 3,
              display: "flex",
              flexDirection: "column",
              marginRight: "20px",
              alignItems: "center",
            }}
          >
            <ContentSubtitle style={{ textAlign: "center" }}>
              {t("admin.coupon.discountType")}
            </ContentSubtitle>
            <Radio.Group
              onChange={(e) => this.setState({ discountType: e.target.value })}
              value={discountType}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "750px",
              }}
            >
              <Radio
                value={COUPON_DISCOUNT_TYPE.percent}
                style={{ width: "100%" }}
              >
                <DiscountInfoContainer
                  style={{
                    backgroundColor:
                      discountType === COUPON_DISCOUNT_TYPE.percent
                        ? "#E6F7FF"
                        : "#fff",
                    padding: "10px",
                    borderRadius: "8px",
                    border:
                      discountType === COUPON_DISCOUNT_TYPE.percent
                        ? "1px solid #1890ff"
                        : "1px solid #d9d9d9",
                    marginBottom: "10px",
                    width: "597px",
                  }}
                >
                  <ContentContainer
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <PercentOffContainer
                      style={{ marginRight: "20px", textAlign: "justify" }}
                    >
                      {t("admin.coupon.percent")}
                    </PercentOffContainer>
                    <DiscountInfoInput
                      onChange={this.handleNumberInputChange}
                      id="offRate"
                      type="text"
                      disabled={discountType !== COUPON_DISCOUNT_TYPE.percent}
                      value={offRate}
                      style={{ marginRight: "10px" }}
                    />
                    <DiscountInfoPostfix>% off</DiscountInfoPostfix>

                    <DiscountInfoSubtitle
                      style={{
                        marginLeft: "20px",
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    >
                      {t("admin.coupon.maxDiscount")}
                    </DiscountInfoSubtitle>
                    <DiscountInfoInput
                      onChange={this.handleNumberInputChange}
                      id="maxDiscount"
                      type="text"
                      disabled={discountType !== COUPON_DISCOUNT_TYPE.percent}
                      value={maxDiscount}
                      style={{ marginLeft: "10px" }}
                    />
                    <DiscountInfoPostfix>{currency}</DiscountInfoPostfix>
                  </ContentContainer>
                </DiscountInfoContainer>
              </Radio>

              <Radio
                value={COUPON_DISCOUNT_TYPE.amount}
                style={{ width: "100%" }}
              >
                <DiscountInfoContainer
                  style={{
                    backgroundColor:
                      discountType === COUPON_DISCOUNT_TYPE.amount
                        ? "#E6F7FF"
                        : "#fff",
                    padding: "10px",
                    borderRadius: "8px",
                    border:
                      discountType === COUPON_DISCOUNT_TYPE.amount
                        ? "1px solid #1890ff"
                        : "1px solid #d9d9d9",
                    marginBottom: "10px",
                    width: "597px",
                  }}
                >
                  <ContentContainer
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <PercentOffContainer
                      style={{ marginRight: "20px", textAlign: "justify" }}
                    >
                      {t("admin.coupon.amount")}
                    </PercentOffContainer>
                    <DiscountInfoInput
                      onChange={this.handleNumberInputChange}
                      id="amountOff"
                      type="text"
                      disabled={discountType !== COUPON_DISCOUNT_TYPE.amount}
                      value={amountOff}
                      style={{ marginRight: "10px" }}
                    />
                    <DiscountInfoPostfix>{currency}</DiscountInfoPostfix>
                  </ContentContainer>
                </DiscountInfoContainer>
              </Radio>
            </Radio.Group>
          </DiscountTypeContainer>

          <ContentContainer style={{ flex: 1 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ContentSubtitle style={{ textAlign: "center" }}>
                {t("admin.payment.description")}
              </ContentSubtitle>
              <DescriptionTextArea
                id="comment"
                value={comment}
                onChange={this.handleStringInputChange}
              />
            </div>
          </ContentContainer>
        </DiscountContainer>

        <OtherContainer
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <ContentContainer>
              <ContentSubtitle>{t("admin.coupon.redeemBy")}</ContentSubtitle>
              <Input
                id="redeemBy"
                type="datetime-local"
                onChange={this.handleRedeemByChange}
                value={redeemBy}
              />
              <DayAndHour>{redeemByDays}</DayAndHour>
              <DayAndHourText>{t("admin.coupon.days")}</DayAndHourText>
              <DayAndHour>{redeemByHours}</DayAndHour>
              <DayAndHourText>{t("admin.coupon.hours")}</DayAndHourText>
            </ContentContainer>
            <ContentContainer>
              <ContentSubtitle>
                {t("admin.coupon.couponQuantity")}
              </ContentSubtitle>
              <Input
                id="number"
                type="text"
                onChange={this.handleNumberInputChange}
                value={number}
              />
            </ContentContainer>
          </div>
          <div>
            <ContentContainer>
              <ContentSubtitle>{t("admin.coupon.prefix")}</ContentSubtitle>
              <Input
                id="prefix"
                type="text"
                onChange={this.handleStringInputChange}
                value={prefix}
              />
            </ContentContainer>

            <ContentContainer>
              <MaxRedemptionPerCoupon style={{ lineHeight: "none" }}>
                {t("admin.coupon.maxRedemption")}
              </MaxRedemptionPerCoupon>
              <Input
                id="maxRedemption"
                type="text"
                onChange={this.handleNumberInputChange}
                value={maxRedemption}
              />
            </ContentContainer>
          </div>
        </OtherContainer>

        <ApplyTitle>{t("admin.coupon.applyTo")}</ApplyTitle>
        <ApplyContainer>
          <ApplyToContainer
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              padding: "20px",
              border: "1px solid white",
              borderRadius: "8px",
            }}
          >
            <ApplyAllContainer style={{ marginBottom: "20px" }}>
              <ApplyToOption style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  checked={
                    applyAll === "true" || applyTo.length === products.length
                  }
                  onChange={(e) => this.handleApplyAllToggle(e.target.checked)}
                  style={{ marginRight: "8px" }}
                />
                <label>{t("admin.coupon.applyAll")}</label>
              </ApplyToOption>
            </ApplyAllContainer>
          </ApplyToContainer>

          <ApplyToContainer
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              padding: "20px",
              border: "1px solid white",
              borderRadius: "8px",
            }}
          >
            {products.map((product) => {
              const { id, name } = product;
              return (
                <ApplyToOption
                  key={id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    wordWrap: "break-word", // Allows the text to wrap within the container
                    wordBreak: "break-all", // Ensures words are broken if they are too long
                    whiteSpace: "normal", // Allows the text to wrap normally
                    width: "100%",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={applyTo.includes(id)}
                    onChange={() => this.handleApplyToOptionClick(id)}
                    style={{ marginRight: "8px" }}
                  />
                  <label>{name}</label>
                </ApplyToOption>
              );
            })}
          </ApplyToContainer>
        </ApplyContainer>

        <ContentContainer>
          <CreateButton onClick={this.onSaveClick}>
            {t("admin.common.create")}
          </CreateButton>
        </ContentContainer>
      </Container>
    );
  };
}

export default withTranslation()(CreateCoupon);
