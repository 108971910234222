import styled from "styled-components";

export const Image = styled.img`
  width: 200px;
  margin: 0 auto;
`;

export const Header = styled.div`
  color: #202124;
  font-size: 32px;
  font-weight: 400;
  line-height: 135%;
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;
