import React from "react";
import ReactDOM from "react-dom";
import { store } from "./storeConfig";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import "./i18n";
import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN as string, {
  debug: true,
});

ReactDOM.render(
  React.createElement(
    Provider,
    { store: store },
    React.createElement(App, null)
  ),
  document.getElementById("root")
);
serviceWorker.unregister();
