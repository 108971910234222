import Dashboard from "components/User/Dashboard";
import BuyUp from "components/User/Dashboard/BuyUp";
import UploadVideoSection from "components/User/Evaluation/CorePro/UploadVideoSection";
import UploadInstructions from "components/general/UploadInstructions";
import WaitingSection from "components/User/Evaluation/CorePro/WaitingSection";
import OrderHistory from "components/User/ProfileRoute/PurchasesHistory";
import ExpertTeam from "components/general/ExpertTeam";
import ContactUs from "components/general/ContactUs";
import FAQ from "components/general/FAQ/index";
import History from "components/User/Dashboard/SurveyHistory";
import Evaluation from "components/User/Evaluation";
import Profile from "components/User/ProfileRoute";
import Account from "components/auth/Account";
import FullResult from "components/Therapist/fullResult";
import CoreProReport from "components/User/Evaluation/CorePro/CoreProReport";
import Questionnaire from "components/User/Evaluation/Core";
import CoreReport from "components/User/Evaluation/Core/CoreReport";
import NavMenu from "components/general/NavMenu";

import { Redirect, Route, Switch, useHistory } from "react-router";
import ChangeCountryPopUp from "components/User/ProfileRoute/ChangeCountryPopUp";
import Landing from "components/QuickScreener/index";
import QSQuestionnaire from "components/QuickScreener/questionnaire";
import Results from "components/QuickScreener/results";
import PublicRoute from "./PublicRoute";

const UserRoute = () => {
  const history = useHistory();
  return (
    <>
      <NavMenu />
      <ChangeCountryPopUp history={history} />
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/purchases-buy-up" component={BuyUp} />
        <Route
          path="/upload-video-section/:transactionId"
          component={UploadVideoSection}
        />
        <Route
          path="/waiting-section/:transactionId"
          component={WaitingSection}
        />
        <Route path="/order-history" component={OrderHistory} />
        <Route path="/evaluation-history" component={History} />
        <Route path="/expert-team" component={ExpertTeam} />
        <Route path="/upload-instructions" component={UploadInstructions} />
        <Route path="/ContactUs/:type" component={ContactUs} />
        <Route path="/FAQ" component={FAQ} />
        <Route exact path="/evaluation" component={Evaluation} />
        <Route exact path="/profile" component={Profile} />
        <Route path="/account-setting" component={Account} />
        <Route path="/full-result/:resultId" component={FullResult} />
        <Route path="/core-report/:transactionId" component={CoreReport} />
        <Route path="/core-pro-report/:resultId" component={CoreProReport} />
        <Route path="/questionnaire/" component={Questionnaire} />
        <Route exact path="/quick-screener" component={Landing} />
        <Route
          exact
          path="/quick-screener/questionnaire/:questionnaireId/:sexSel/:dateSel/:transactionId/:agentId?"
          component={QSQuestionnaire}
        />
        <Route
          exact
          path="/quick-screener/results/:questionnaireId/:finalScore/:sexSel/:dateSel/:transactionId/:agentId?"
          component={Results}
        />
        <PublicRoute />
      </Switch>
    </>
  );
};

export default UserRoute;
