import styled from "styled-components";
import WhiteOrder from "./assets/WhiteOrder.svg";
import WhiteSurvey from "./assets/WhiteSurvey.svg";
import WhiteProfile from "./assets/WhiteProfile.svg";
import WhiteHome from "./assets/WhiteHome.svg";
import WhiteContact from "./assets/WhiteContact.svg";
import WhiteRecommendation from "./assets/WhiteRecommendation.svg";
import FillOrder from "./assets/FillOrder.svg";
import FillSurvey from "./assets/FillSurvey.svg";
import FillProfile from "./assets/FillProfile.svg";
import FillHome from "./assets/FillHome.svg";
import FillRecommendation from "./assets/FillRecommendation.svg";
import GreyOrder from "./assets/GreyOrder.svg";
import GreySurvey from "./assets/GreySurvey.svg";
import GreyProfile from "./assets/GreyProfile.svg";
import GreyHome from "./assets/GreyHome.svg";
import GreyContact from "./assets/GreyContact.svg";
import GreyRecommendation from "./assets/GreyRecommendation.svg";

// NavBar
export const LeftNavBar = styled.div`
  position: fixed;
  // background: linear-gradient(0deg, #58D1D0 0%, #63E2B7 100%);
  // background-color: rgb(71, 228, 194);
  z-index: 998;
  text-align: left;
  text-indent: 15px;
  @media only screen and (max-width: 767px) {
    display: none;
  }
  @media only screen and (min-width: 768px) {
    left: 0;
    height: 100%;
    width: 220px;
  }
  @media only screen and (min-width: 992px) {
    left: 0;
    height: 100%;
    width: 220px;
  }
  @media only screen and (min-width: 1200px) {
    left: 0;
    height: 100%;
    width: 220px;
  }
`;

export const TopNavBar = styled.div<{ status: boolean }>`
  // position: fixed;
  z-index: 998;
  background-color: #6898e3;
  @media only screen and (max-width: 575px) {
    top: 0;
    height: 48px;
    width: 100%;
  }
  @media only screen and (min-width: 576px) {
    top: 0;
    height: 48px;
    width: 100%;
  }
  @media only screen and (min-width: 768px) {
    top: 0;
    left: 0;
    height: ${({ status }) => (status ? "0px" : "48px")};
    width: 100%;
  }
  @media only screen and (min-width: 992px) {
    top: 0;
    left: 0;
    height: ${({ status }) => (status ? "0px" : "48px")};
    width: 100%;
  }
  @media only screen and (min-width: 1200px) {
    top: 0;
    left: 0;
    height: ${({ status }) => (status ? "0px" : "48px")};
    width: 100%;
  }
`;

export const BottomNavBar = styled.div`
  position: fixed;
  background-color: #6898e3;
  z-index: 998;
  @media only screen and (max-width: 575px) {
    bottom: 0;
    height: 50px;
    width: 100%;
  }
  @media only screen and (min-width: 576px) {
    bottom: 0;
    height: 50px;
    width: 100%;
  }
  @media only screen and (min-width: 768px) {
    display: none;
  }
  @media only screen and (min-width: 992px) {
    display: none;
  }
  @media only screen and (min-width: 1200px) {
    display: none;
  }
`;

// Container
export const TopBarMenuContainer = styled.div`
  display: flex;
  height: 100%;
  @media only screen and (max-width: 575px) {
    justify-content: space-between;
    align-items: center;
    padding: 5px 12px;
  }
  @media only screen and (min-width: 576px) {
    justify-content: space-between;
    align-items: center;
    padding: 5px 12px;
  }
  @media only screen and (min-width: 768px) {
    justify-content: space-between;
    align-items: center;
    padding: 5px 12px;
  }
  @media only screen and (min-width: 992px) {
    justify-content: space-between;
    align-items: center;
    padding: 5px 12px;
  }
  @media only screen and (min-width: 1200px) {
    justify-content: space-between;
    align-items: center;
    padding: 5px 12px;
  }
`;
export const BarMenuContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-start;
  @media only screen and (max-width: 575px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;
  }
  @media only screen and (min-width: 576px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;
  }
  @media only screen and (min-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
  }
  @media only screen and (min-width: 992px) {
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
  }
  @media only screen and (min-width: 1200px) {
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
  }
`;
export const NavbarLinkContainer = styled.div`
  width: 100%;
  text-align: center;
  @media only screen and (max-width: 575px) {
  }
  @media only screen and (min-width: 576px) {
  }
  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: inherit;
    padding-top: 30px;
  }
  @media only screen and (min-width: 992px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: inherit;
    padding-top: 30px;
  }
  @media only screen and (min-width: 1200px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: inherit;
    padding-top: 30px;
  }
`;
export const NavbarItemContainer = styled.div`
  @media only screen and (max-width: 575px) {
    justify-content: space-evenly;
    display: flex;
    padding: 10px 0;
  }
  @media only screen and (min-width: 576px) {
    justify-content: space-evenly;
    display: flex;
    padding: 10px 0;
  }
  @media only screen and (min-width: 768px) {
    display: block;
  }
  @media only screen and (min-width: 992px) {
    display: block;
  }
  @media only screen and (min-width: 1200px) {
    display: block;
  }
`;
export const LanguageContainer = styled.div`
  display: flex;
  align-self: flex-end;
  @media only screen and (max-width: 575px) {
    font-size: 8px;
    margin-bottom: 8px;
  }
  @media only screen and (min-width: 576px) {
    font-size: 8px;
    margin-bottom: 8px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 8px;
    margin-bottom: 8px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 10px;
    margin-bottom: 6px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 10px;
    margin-bottom: 6px;
  }
`;

export const SmsNotificationContainer = styled.div`
  display: flex;
  align-self: flex-end;
  @media only screen and (max-width: 575px) {
    font-size: 8px;
    margin-bottom: 8px;
  }
  @media only screen and (min-width: 576px) {
    font-size: 8px;
    margin-bottom: 8px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 8px;
    margin-bottom: 8px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 10px;
    margin-bottom: 6px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 10px;
    margin-bottom: 6px;
  }
`;

export const CountryContainer = styled.div`
  display: flex;
  align-self: flex-end;
  @media only screen and (max-width: 575px) {
    font-size: 8px;
    margin-bottom: 8px;
  }
  @media only screen and (min-width: 576px) {
    font-size: 8px;
    margin-bottom: 8px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 8px;
    margin-bottom: 8px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 10px;
    margin-bottom: 6px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 10px;
    margin-bottom: 6px;
  }
`;
export const LeftBarLanguageContainer = styled(LanguageContainer)`
  margin-left: 15px;
  margin-top: -30px;
`;
export const NoDisplayOnMobile = styled.div`
  @media only screen and (max-width: 575px) {
    display: none;
  }
  @media only screen and (min-width: 576px) {
    display: none;
  }
  @media only screen and (min-width: 768px) {
    display: block;
  }
  @media only screen and (min-width: 992px) {
    display: block;
  }
  @media only screen and (min-width: 1200px) {
    display: block;
  }
`;
// Company Logo
export const TopNavbarCompanyLogo = styled.img<{ status: boolean }>`
  margin-left: 5vw;
  @media only screen and (max-width: 575px) {
    width: 110px;
    height: 38px;
    padding-top: 2px;
  }
  @media only screen and (min-width: 576px) {
    width: 110px;
    height: 38px;
    padding-top: 2px;
  }
  @media only screen and (min-width: 768px) {
    display: ${({ status }) => (status ? "none" : "block")};
  }

  &:hover {
    cursor: pointer;
  }
`;

export const EvalAdminCompanyLogo = styled.img`
  @media only screen and (max-width: 575px) {
    width: 80px;
    height: auto;
    padding-top: 2px;
  }
  @media only screen and (min-width: 576px) {
    width: 80px;
    height: auto;
    padding-top: 2px;
  }
  @media only screen and (min-width: 768px) {
    width: 120px;
    height: auto;
    margin-top: 5px;
    margin-left: 10px;
  }
  @media only screen and (min-width: 992px) {
    width: 120px;
    height: auto;
    margin-top: 5px;
    margin-left: 10px;
  }
  @media only screen and (min-width: 1200px) {
    width: 120px;
    height: auto;
    margin-top: 5px;
    margin-left: 10px;
  }
`;

export const WordCompanyLogo = styled.img`
  @media only screen and (max-width: 575px) {
    width: 110px;
    height: auto;
    padding-top: 2px;
  }
  @media only screen and (min-width: 576px) {
    width: 110px;
    height: auto;
    padding-top: 2px;
  }
  @media only screen and (min-width: 768px) {
    width: 155px;
    height: auto;
    margin-top: 5px;
    margin-left: 15px;
  }
  @media only screen and (min-width: 992px) {
    width: 155px;
    height: auto;
    margin-top: 5px;
    margin-left: 15px;
  }
  @media only screen and (min-width: 1200px) {
    width: 155px;
    height: auto;
    margin-top: 5px;
    margin-left: 15px;
  }
`;

// NavbarLink with five extend component
export const NavbarLink = styled.div<{ status: boolean }>`
  border-radius: 6px;
  cursor: pointer;
  pointer-events: ${({ status }) => (status ? "none" : "cursor")};
  @media only screen and (max-width: 575px) {
    width: 60px;
    background-color: #6898e3;
    color: ${({ status }) => (status ? "white" : "#E8E9E9")};
  }
  @media only screen and (min-width: 576px) {
    width: 60px;
    background-color: #6898e3;
    color: ${({ status }) => (status ? "white" : "#E8E9E9")};
  }
  @media only screen and (min-width: 768px) {
    width: 100%;
    padding: 10px 0 10px 20px;
    display: flex;
    align-items: center;
    color: ${({ status }) => (status ? "white" : "#E8E9E9")};
    background: ${({ status }) => (status ? "#A4C1EE" : "#6898E3")};
    :hover {
      color: white;
    }
  }
  @media only screen and (min-width: 992px) {
    width: 100%;
    padding: 10px 0 10px 20px;
    display: flex;
    align-items: center;
    color: ${({ status }) => (status ? "white" : "#E8E9E9")};
    background: ${({ status }) => (status ? "#A4C1EE" : "#6898E3")};
    :hover {
      color: white;
    }
  }
  @media only screen and (min-width: 1200px) {
    width: 100%;
    padding: 10px 0 10px 20px;
    display: flex;
    align-items: center;
    color: ${({ status }) => (status ? "white" : "#E8E9E9")};
    background: ${({ status }) => (status ? "#A4C1EE" : "#6898E3")};
    :hover {
      color: white;
    }
  }
`;
export const SurveyLink = styled(NavbarLink)``;
export const OrderLink = styled(NavbarLink)``;
export const ProfileLink = styled(NavbarLink)``;
export const HomeLink = styled(NavbarLink)``;
export const ContactLink = styled(NavbarLink)`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
export const RecommendationLink = styled(NavbarLink)``;

// NavbarIcon with five extend component
export const NavbarIcon = styled.div<{ status: boolean }>`
  cursor: pointer;
  @media only screen and (max-width: 575px) {
    height: 20px;
    width: 20px;
    display: inline-block;
  }
  @media only screen and (min-width: 576px) {
    height: 20px;
    width: 20px;
    display: inline-block;
  }
  @media only screen and (min-width: 768px) {
    height: 20px;
    width: 20px;
  }
  @media only screen and (min-width: 992px) {
    height: 20px;
    width: 20px;
  }
  @media only screen and (min-width: 1200px) {
    height: 20px;
    width: 20px;
  }
`;
export const SurveyIcon = styled(NavbarIcon)`
  content: url(${({ status }) => (status ? FillSurvey : GreySurvey)});
  @media (hover: hover) and (pointer: fine) {
    ${SurveyLink}: hover & {
      content: url(${WhiteSurvey});
    }
  }
`;
export const OrderIcon = styled(NavbarIcon)`
  content: url(${({ status }) => (status ? FillOrder : GreyOrder)});
  @media (hover: hover) and (pointer: fine) {
    ${OrderLink}: hover & {
      content: url(${WhiteOrder});
    }
  }
`;
export const ProfileIcon = styled(NavbarIcon)`
  content: url(${({ status }) => (status ? FillProfile : GreyProfile)});
  @media (hover: hover) and (pointer: fine) {
    ${ProfileLink}: hover & {
      content: url(${WhiteProfile});
    }
  }
`;
export const HomeIcon = styled(NavbarIcon)`
  content: url(${({ status }) => (status ? FillHome : GreyHome)});
  @media (hover: hover) and (pointer: fine) {
    ${HomeLink}: hover & {
      content: url(${WhiteHome});
    }
  }
`;
export const ContactIcon = styled(NavbarIcon)`
  content: url(${({ status }) => (status ? WhiteContact : GreyContact)});
  @media (hover: hover) and (pointer: fine) {
    ${ContactLink}: hover & {
      content: url(${WhiteContact});
    }
  }
`;
export const RecommendationIcon = styled(NavbarIcon)`
  content: url(${({ status }) =>
    status ? FillRecommendation : GreyRecommendation});
  @media (hover: hover) and (pointer: fine) {
    ${RecommendationLink}: hover & {
      content: url(${WhiteRecommendation});
    }
  }
`;

// Navbar Text
export const NavbarText = styled.div`
  color: inherit;
  cursor: inherit;
  @media only screen and (max-width: 575px) {
    font-size: 8px;
    margin-top: -5px;
  }
  @media only screen and (min-width: 576px) {
    font-size: 8px;
    margin-top: -5px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 14px;
    font-weight: 600;
    margin-left: 20px;
    margin-top: 0;
  }
  @media only screen and (min-width: 992px) {
    font-size: 14px;
    font-weight: 600;
    margin-left: 20px;
    margin-top: 0;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 14px;
    font-weight: 600;
    margin-left: 20px;
    margin-top: 0;
  }
`;

export const RecommendedActiveButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 28px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: white;
  background-color: #6898e3;
  border: 1px solid #6898e3;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  }
`;

export const ActiveButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 28px;
  margin-right: 5px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: #6898e3;
  background-color: white;
  border: 1px solid #6898e3;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  }
`;

export const ContactButton = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: 30px;
  padding: 0 10px 0 10px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer;
  background: white;
  color: #6898e3;
  border: solid 1px #6898e3;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  }
  @media only screen and (min-width: 576px) {
    display: none;
  }
  @media only screen and (min-width: 768px) {
    display: flex;
  }
  @media only screen and (min-width: 992px) {
    display: flex;
  }
  @media only screen and (min-width: 1200px) {
    display: flex;
  }
`;

export const ContactImg = styled.img`
  cursor: pointer;
  margin-right: 5px;
  height: 15px;
  width: 15px;
`;

export const TopProfileIcon = styled.img`
  cursor: pointer;
  margin: 0 10px 0 10px;
  height: 20px;
  width: 20px;
`;

export const TopContactIcon = styled.img`
  cursor: pointer;
  margin: 0 10px 0 10px;
  height: 20px;
  width: 20px;
  display: block;
  @media only screen and (min-width: 576px) {
    display: block;
  }
  @media only screen and (min-width: 768px) {
    display: none;
  }
  @media only screen and (min-width: 992px) {
    display: none;
  }
  @media only screen and (min-width: 1200px) {
    display: none;
  }
`;

export const TopHomeIcon = styled.img`
  cursor: pointer;
  margin: 0 10px 0 10px;
  height: 20px;
  width: 20px;
`;
