import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import { videoUploadTabs } from "constants/eval_constants";
import { GoalLine, StoryLineImageContainer, StoryLineImage } from "../styles";
import { GET_STORY_LINE } from "./storyLine";
import CollapsibleContent from "../../../../../common/CollapsibleContent";
import RadioCollapsibleContent from "../../../../../common/RadioCollapsibleContent";
import {
  List,
  OptionText,
  OrderList,
  QuestionText,
  Segment,
  Title,
  TitleIndicate,
  UnOrderList,
  VideoListItem,
} from "components/User/Evaluation/styles";

interface Video2Props {
  language: string;
}

interface Video2State {}

type Props = Video2Props & RouteComponentProps & WithTranslation;

export function Video2Preload({ language }) {
  const {
    storyLine1png,
    storyLine2png,
    storyLine3png,
    storyLine4png,
    storyLine1webp,
    storyLine2webp,
    storyLine3webp,
    storyLine4webp,
  } = GET_STORY_LINE(language);
  return (
    <div style={{ display: "none" }}>
      <picture>
        <source srcSet={storyLine1webp} type="image/webp" />
        <StoryLineImage src={storyLine1png} alt="Storyline1" />
      </picture>
      <picture>
        <source srcSet={storyLine2webp} type="image/webp" />
        <StoryLineImage src={storyLine2png} alt="Storyline2" />
      </picture>
      <picture>
        <source srcSet={storyLine3webp} type="image/webp" />
        <StoryLineImage src={storyLine3png} alt="Storyline3" />
      </picture>
      <picture>
        <source srcSet={storyLine4webp} type="image/webp" />
        <StoryLineImage src={storyLine4png} alt="Storyline4" />
      </picture>
    </div>
  );
}

class Video2 extends Component<Props, Video2State> {
  goal = () => {
    const { t } = this.props;
    return (
      <Segment>
        <QuestionText>
          {t("user.survey.corePro.video.videoRecording.goal")}
        </QuestionText>
        <GoalLine />
        <OptionText>
          {t("user.survey.corePro.video.video2.goal1")}
          <b>{t("user.survey.corePro.video.video2.goal2")}</b>
        </OptionText>
      </Segment>
    );
  };

  role = () => {
    const { t } = this.props;
    return (
      <Segment>
        <QuestionText>
          {t("user.survey.corePro.video.videoRecording.yourRole")}
        </QuestionText>
        <GoalLine />
        <OptionText>{t("user.survey.corePro.video.video2.role")}</OptionText>
      </Segment>
    );
  };

  instructions = () => {
    const { t } = this.props;
    return (
      <Segment>
        <QuestionText>
          {t("user.survey.corePro.video.videoRecording.instructions")}
        </QuestionText>
        <GoalLine />
        <OrderList>
          <VideoListItem>
            <OptionText>
              {t("user.survey.corePro.video.video2.instructions1")}
            </OptionText>
          </VideoListItem>
          <VideoListItem>
            <OptionText>
              {t("user.survey.corePro.video.video2.instructions2")}
            </OptionText>
          </VideoListItem>
          <VideoListItem>
            <OptionText>
              {t("user.survey.corePro.video.video2.instructions3")}
            </OptionText>
          </VideoListItem>
        </OrderList>
      </Segment>
    );
  };

  storyLinePicture = () => {
    const { language } = this.props;
    const {
      storyLine1png,
      storyLine2png,
      storyLine3png,
      storyLine4png,
      storyLine1webp,
      storyLine2webp,
      storyLine3webp,
      storyLine4webp,
    } = GET_STORY_LINE(language);
    return (
      <StoryLineImageContainer>
        <picture>
          <source srcSet={storyLine1webp} type="image/webp" />
          <StoryLineImage src={storyLine1png} alt="Storyline1" />
        </picture>
        <picture>
          <source srcSet={storyLine2webp} type="image/webp" />
          <StoryLineImage src={storyLine2png} alt="Storyline2" />
        </picture>
        <picture>
          <source srcSet={storyLine3webp} type="image/webp" />
          <StoryLineImage src={storyLine3png} alt="Storyline3" />
        </picture>
        <picture>
          <source srcSet={storyLine4webp} type="image/webp" />
          <StoryLineImage src={storyLine4png} alt="Storyline4" />
        </picture>
      </StoryLineImageContainer>
    );
  };

  sampleScenario = () => {
    const { t } = this.props;
    return (
      <CollapsibleContent
        header={
          <QuestionText>
            {t("user.survey.corePro.video.video2.sampleScenario")}
          </QuestionText>
        }
        content={
          <>
            <GoalLine />
            {this.storyLinePicture()}
          </>
        }
        defaultOpen={true}
      />
    );
  };

  //Not used
  simpleActivities = () => {
    const { t } = this.props;
    return (
      <CollapsibleContent
        header={
          <QuestionText>
            {t("user.survey.corePro.video.video2.simpleActivities")}
          </QuestionText>
        }
        content={
          <>
            <GoalLine />

            <OptionText style={{ marginTop: "16px" }}>
              <b>{t("user.survey.corePro.video.video2.simpleActivities1")}</b>
            </OptionText>
            <UnOrderList>
              <List>
                <OptionText>
                  {t("user.survey.corePro.video.video2.simpleActivities2")}
                </OptionText>
              </List>
              <List>
                <OptionText>
                  {t("user.survey.corePro.video.video2.simpleActivities3")}
                </OptionText>
              </List>
            </UnOrderList>
            <OptionText>
              <b>{t("user.survey.corePro.video.video2.simpleActivities4")}</b>
            </OptionText>
            <OrderList>
              <List>
                <OptionText>
                  {t("user.survey.corePro.video.video2.simpleActivities5")}
                </OptionText>
              </List>
              <List>
                <OptionText>
                  {t("user.survey.corePro.video.video2.simpleActivities6")}
                </OptionText>
              </List>
              <List>
                <OptionText>
                  {t("user.survey.corePro.video.video2.simpleActivities7")}
                </OptionText>
              </List>
            </OrderList>
          </>
        }
      />
    );
  };

  //Not used
  advancedActivities = () => {
    const { t } = this.props;
    return (
      <CollapsibleContent
        header={
          <QuestionText>
            {t("user.survey.corePro.video.video2.advanced")}
          </QuestionText>
        }
        content={
          <>
            <GoalLine />
            <OptionText style={{ marginTop: "16px" }}>
              <b>{t("user.survey.corePro.video.video2.simpleActivities1")}</b>
            </OptionText>
            <UnOrderList>
              <List>
                <OptionText>
                  {t("user.survey.corePro.video.video2.simpleActivities2")}
                </OptionText>
              </List>
              <List>
                <OptionText>
                  {t("user.survey.corePro.video.video2.simpleActivities3")}
                </OptionText>
              </List>
            </UnOrderList>
            <OptionText>
              <b>{t("user.survey.corePro.video.video2.advanced4")}</b>
            </OptionText>
            <OrderList>
              <List>
                <OptionText>
                  {t("user.survey.corePro.video.video2.advanced5")}
                  {t("user.survey.corePro.video.video2.advanced6")}
                </OptionText>
              </List>
            </OrderList>
            <OptionText>
              <b>{t("user.survey.corePro.video.video2.advanced7")}</b>
            </OptionText>
            <OrderList>
              <List>
                {" "}
                <OptionText>
                  {t("user.survey.corePro.video.video2.advanced8")}
                  {t("user.survey.corePro.video.video2.advanced9")}
                </OptionText>
              </List>
              <List>
                {" "}
                <OptionText>
                  {t("user.survey.corePro.video.video2.advanced10")}
                </OptionText>
              </List>
              <List>
                {" "}
                <OptionText>
                  {t("user.survey.corePro.video.video2.advanced11")}
                </OptionText>
              </List>
            </OrderList>
          </>
        }
      />
    );
  };

  //Only one can open at a time
  simpleAndAdvancedActivities = () => {
    const { t } = this.props;
    return (
      <RadioCollapsibleContent
        header1={
          <QuestionText>
            {t("user.survey.corePro.video.video2.simpleActivities")}
          </QuestionText>
        }
        content1={
          <>
            <GoalLine />

            <OptionText style={{ marginTop: "16px" }}>
              <b>{t("user.survey.corePro.video.video2.simpleActivities1")}</b>
            </OptionText>
            <UnOrderList>
              <List>
                <OptionText>
                  {t("user.survey.corePro.video.video2.simpleActivities2")}
                </OptionText>
              </List>
              <List>
                <OptionText>
                  {t("user.survey.corePro.video.video2.simpleActivities3")}
                </OptionText>
              </List>
            </UnOrderList>
            <OptionText>
              <b>{t("user.survey.corePro.video.video2.simpleActivities4")}</b>
            </OptionText>
            <OrderList>
              <List>
                <OptionText>
                  {t("user.survey.corePro.video.video2.simpleActivities5")}
                </OptionText>
              </List>
              <List>
                <OptionText>
                  {t("user.survey.corePro.video.video2.simpleActivities6")}
                </OptionText>
              </List>
              <List>
                <OptionText>
                  {t("user.survey.corePro.video.video2.simpleActivities7")}
                </OptionText>
              </List>
            </OrderList>
          </>
        }
        //Advanced Activity
        header2={
          <QuestionText>
            {t("user.survey.corePro.video.video2.advanced")}
          </QuestionText>
        }
        content2={
          <>
            <GoalLine />
            <OptionText style={{ marginTop: "16px" }}>
              <b>{t("user.survey.corePro.video.video2.simpleActivities1")}</b>
            </OptionText>
            <UnOrderList>
              <List>
                <OptionText>
                  {t("user.survey.corePro.video.video2.simpleActivities2")}
                </OptionText>
              </List>
              <List>
                <OptionText>
                  {t("user.survey.corePro.video.video2.simpleActivities3")}
                </OptionText>
              </List>
            </UnOrderList>
            <OptionText>
              <b>{t("user.survey.corePro.video.video2.advanced4")}</b>
            </OptionText>
            <OrderList>
              <List>
                <OptionText>
                  {t("user.survey.corePro.video.video2.advanced5")}
                  {t("user.survey.corePro.video.video2.advanced6")}
                </OptionText>
              </List>
            </OrderList>
            <OptionText>
              <b>{t("user.survey.corePro.video.video2.advanced7")}</b>
            </OptionText>
            <OrderList>
              <List>
                {" "}
                <OptionText>
                  {t("user.survey.corePro.video.video2.advanced8")}
                  {t("user.survey.corePro.video.video2.advanced9")}
                </OptionText>
              </List>
              <List>
                {" "}
                <OptionText>
                  {t("user.survey.corePro.video.video2.advanced10")}
                </OptionText>
              </List>
              <List>
                {" "}
                <OptionText>
                  {t("user.survey.corePro.video.video2.advanced11")}
                </OptionText>
              </List>
            </OrderList>
          </>
        }
      ></RadioCollapsibleContent>
    );
  };

  notCooperative = () => {
    const { t } = this.props;
    return (
      <CollapsibleContent
        header={
          <QuestionText>
            {t("user.survey.corePro.video.video2.notCooperative")}
          </QuestionText>
        }
        content={
          <>
            <GoalLine />
            <UnOrderList>
              <List>
                <OptionText>
                  {t("user.survey.corePro.video.video2.notCooperative1")}
                </OptionText>
              </List>
              <List>
                <OptionText>
                  {t("user.survey.corePro.video.video2.notCooperative2")}
                </OptionText>
              </List>
              <List>
                <OptionText>
                  {t("user.survey.corePro.video.video2.notCooperative3")}
                </OptionText>
              </List>
              <List>
                <OptionText>
                  {t("user.survey.corePro.video.video2.notCooperative4")}
                </OptionText>
              </List>
            </UnOrderList>
          </>
        }
      />
    );
  };

  render() {
    return (
      <>
        {this.goal()}
        {this.role()}
        {this.instructions()}
        {this.simpleAndAdvancedActivities()}
        {this.notCooperative()}
        {this.sampleScenario()}
      </>
    );
  }
}

export default withRouter(withTranslation()(Video2));
