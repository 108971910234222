import styled from "styled-components";

export const CoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const LogoContainer = styled.div`
  display: grid;
  place-items: center;
`;

export const Logo = styled.img`
  width: 120px;
  margin: 0 auto;
`;

export const TitleContainer = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  min-width: max-content;
`;
export const InformationContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const CompanyName = styled.div`
  font-size: 36px;
  font-weight: 400;
  margin: 15px 0 0 0;
  line-height: normal;
`;
export const CompanySubtitle = styled.div`
  font-size: 16px;
  line-height: normal;
  text-align: center;
  margin-top: 10px;
`;
