import signInTw from "./images/signInTw.png";
import signInEn from "./images/signInEn.jpg";
import signInCn from "./images/signInCn.jpg";
import uploadTw1 from "./images/uploadTw1.png";
import uploadEn1 from "./images/uploadEn1.jpg";
import uploadCn1 from "./images/uploadCn1.jpg";
import uploadTw2 from "./images/uploadTw2.png";
import uploadEn2 from "./images/uploadEn2.jpg";
import uploadCn2 from "./images/uploadCn2.jpg";
import uploadTw3 from "./images/uploadTw3.png";
import uploadEn3 from "./images/uploadEn3.jpg";
import uploadCn3 from "./images/uploadCn3.jpg";
import shareTw1 from "./images/shareTw1.png";
import shareEn1 from "./images/shareEn1.jpg";
import shareCn1 from "./images/shareCn1.jpg";
import shareTw2 from "./images/shareTw2.png";
import shareEn2 from "./images/shareEn2.jpg";
import shareCn2 from "./images/shareCn2.jpg";

export const GET_IMAGES = (language: string) => {
  switch (language) {
    case "en":
      return {
        signIn: signInEn,
        upload1: uploadEn1,
        upload2: uploadEn2,
        upload3: uploadEn3,
        share1: shareEn1,
        share2: shareEn2,
      };
    case "zh-CN":
      return {
        signIn: signInCn,
        upload1: uploadCn1,
        upload2: uploadCn2,
        upload3: uploadCn3,
        share1: shareCn1,
        share2: shareCn2,
      };
    case "zh-TW":
      return {
        signIn: signInTw,
        upload1: uploadTw1,
        upload2: uploadTw2,
        upload3: uploadTw3,
        share1: shareTw1,
        share2: shareTw2,
      };
    default:
      return {
        signIn: signInEn,
        upload1: uploadEn1,
        upload2: uploadEn2,
        upload3: uploadEn3,
        share1: shareEn1,
        share2: shareEn2,
      };
  }
};

export const GET_DRIVE_LINK = (language: string) => {
  switch (language) {
    case "en":
      return "https://drive.google.com/drive/my-drive";
    case "zh-CN":
      return "https://cowtransfer.com/";
    case "zh-TW":
      return "https://drive.google.com/drive/my-drive";
    default:
      return "https://drive.google.com/drive/my-drive";
  }
};
