import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { SNOWFLAKE } from "../../../../../../util/common";
import Uploader from "../../../../../../util/uploader";
import {
  UploadInput,
  UploadButton,
  SaveButtonContainer,
  SaveButton,
  UploadBox,
  UploadButtonText,
} from "./styles";

import { recordingProps, progressModalProps } from "./interface";
import icAddFile from "../../assets/icAddFile.png";
import icFileSucess from "../../assets/icFileSucess.png";
import btnUpload from "../../assets/btnUpload.png";
import NotificationModal from "components/common/NotificationModal";
import RecordModal from "./RecordModal";

interface UploadState {
  file: File | null;
  showModal: boolean;
  showProgress: boolean;
  completed: number;
  errors: string;
  notificationStatus: boolean;
  uploadModalOpen: boolean;
  uploadButtonClicked: boolean;
}

type Props = progressModalProps & recordingProps & WithTranslation;

class Upload extends Component<Props, UploadState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      file: null,
      showModal: false,
      errors: "",
      showProgress: false,
      completed: 0,
      notificationStatus: false,
      uploadModalOpen: false,
      uploadButtonClicked: false,
    };
  }

  componentDidMount() {}

  displayErrorMessage = (message: string) => {
    this.setState({
      errors: message,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;
    const { t } = this.props;
    if (!files || files.length === 0) return;
    const f = files[0];
    if (f && f.type.includes("audio")) {
      this.setState({ file: f });
    } else {
      this.displayErrorMessage(
        t("user.survey.core.questionnaire.sound_collection.invalidFileError")
      );
    }
  };

  handleSaveFile = () => {
    const {
      transactionId,
      prompt,
      updateProgressModalProgress,
      updateProgressModalShowChecked,
      setProgressModalClose,
      setProgressModalOpen,
      markComplete,
    } = this.props;
    const { file } = this.state;

    // return if user didn't select any file
    if (!file) {
      return;
    }

    const flakeId = SNOWFLAKE.gen();
    const splitFileName = file.name.split(".");
    const filenameExtension = "." + splitFileName[splitFileName.length - 1];
    const encodedFileName = flakeId + filenameExtension;

    setProgressModalOpen();

    // we can random our file name if needed
    Uploader(file, transactionId, encodedFileName)
      .on("httpUploadProgress", (evt) => {
        const uploaded = Math.round((evt.loaded / evt.total) * 100);
        updateProgressModalProgress(uploaded);
      })
      .send((err, data) => {
        if (err) {
          setProgressModalClose();
          return;
        }
        updateProgressModalShowChecked();
        setProgressModalClose();
        setTimeout(() => {
          markComplete(prompt.key, encodedFileName);
        }, 1500);
      });
  };

  //Modal functions
  setUploadModalOpen = () => {
    this.setState({ uploadModalOpen: true });
  };

  setUploadModalClose = () => {
    this.setState({ uploadModalOpen: false });
  };

  uploadModalHandler = () => {
    this.setUploadModalClose();
    this.setState({ uploadButtonClicked: true });
    this.handleSaveFile();
  };

  render() {
    const { t, prompt } = this.props;
    const {
      file,
      showModal,
      errors,
      notificationStatus,
      uploadModalOpen,
      uploadButtonClicked,
    } = this.state;
    return (
      <div>
        <NotificationModal
          show={showModal}
          message={errors}
          status={notificationStatus}
        />
        {uploadModalOpen && (
          <RecordModal
            modalHeader={t(
              "user.survey.core.questionnaire.sound_collection.uploadFileModalHeader"
            )}
            modalText={t(
              "user.survey.core.questionnaire.sound_collection.uploadFileModalText"
            )}
            cancelText={t(
              "user.survey.core.questionnaire.sound_collection.skip.cancelButton"
            )}
            actionText={t(
              "user.survey.core.questionnaire.sound_collection.uploadButton"
            )}
            onCancel={this.setUploadModalClose}
            onAction={this.uploadModalHandler}
          ></RecordModal>
        )}

        <UploadBox>
          <UploadInput
            type="file"
            accept="*"
            id={`Upload_${prompt.key}`}
            onChange={this.handleUploadFile}
          />
          <UploadButton
            src={file ? icFileSucess : icAddFile}
            htmlFor={`Upload_${prompt.key}`}
          >
            <UploadButtonText>
              {file
                ? file.name
                : t(
                    "user.survey.core.questionnaire.sound_collection.selectFile"
                  )}
            </UploadButtonText>
          </UploadButton>
        </UploadBox>
        <SaveButtonContainer>
          <SaveButton
            backgroundColor={
              !uploadButtonClicked && file ? "#2AB2AC" : "rgb(204, 204, 204)"
            }
            border={
              !uploadButtonClicked && file
                ? "1pt solid #2AB2AC "
                : "1pt solid rgb(204, 204, 204)"
            }
            activeColor={file ? "rgb(100, 155, 250)" : "none"}
            pointerActive={file && !uploadButtonClicked ? "inherit" : "none"}
            cursor={!uploadButtonClicked && file ? "pointer" : "default"}
            onClick={
              // If the upload button on the modal has been clicked, then the upload button will be disabled and no modal will pop up
              !uploadButtonClicked
                ? this.setUploadModalOpen
                : this.setUploadModalClose
            }
            boxShadow={
              !uploadButtonClicked && file
                ? "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;"
                : ""
            }
          >
            {uploadButtonClicked
              ? t(
                  "user.survey.core.questionnaire.sound_collection.uploadingButton"
                )
              : t(
                  "user.survey.core.questionnaire.sound_collection.uploadButton"
                )}
          </SaveButton>
        </SaveButtonContainer>
      </div>
    );
  }
}

export default withTranslation()(Upload);
