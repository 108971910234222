import EN from "../../../locales/en/translation.json";
import ZH_CN from "../../../locales/zh-CN/translation.json";
import ZH_TW from "../../../locales/zh-TW/translation.json";

export const languages = [
  {
    key: "en",
    value: EN,
    file: "en_file",
    path: "src/locales/en/translation.json",
    keyPath: "en_path",
  },
  {
    key: "zh-CN",
    value: ZH_CN,
    file: "zh_cn_file",
    path: "src/locales/zh-CN/translation.json",
    keyPath: "zh_cn_path",
  },
  {
    key: "zh-TW",
    value: ZH_TW,
    file: "zh_tw_file",
    path: "src/locales/zh-TW/translation.json",
    keyPath: "zh_tw_path",
  },
];

export const filterJson = (arrPath) => {
  let resultJson = {};

  for (let i = 0; i < languages.length; i++) {
    const resultGet = getObj(arrPath, languages[i].value);
    resultJson[languages[i].key] = resultGet;
  }

  return resultJson;
};

export const getObj = (arrPath, data, index = 0) => {
  if (index >= arrPath.length) return data;
  return getObj(arrPath, data[arrPath[index]], index + 1);
};

// Handle merge the updated json into the original json
export const mergeJson = (newJson, arrPath) => {
  let finalJson = {};

  if (arrPath.includes("questionnaire")) {
    for (let i = 0; i < languages.length; i++) {
      const data = JSON.parse(JSON.stringify(languages[i].value));
      data["user"]["survey"]["core"]["questionnaire"]["intro"] =
        newJson[languages[i].key]["intro"];
      data["user"]["survey"]["core"]["questionnaire"]["child_info"] =
        newJson[languages[i].key]["child_info"];
      finalJson[languages[i].file] = data;
    }
  } else {
    for (let i = 0; i < languages.length; i++) {
      const data = JSON.parse(JSON.stringify(languages[i].value));
      const router = [...arrPath];
      const result = updateJson(
        data,
        router.reverse(),
        newJson[languages[i].key]
      );
      finalJson[languages[i].file] = result;
    }
  }
  return finalJson;
};

const updateJson = (dataOriginal, path, dataNew) => {
  if (path.length == 1) {
    dataOriginal[path[0]] = dataNew;
    return dataOriginal;
  }
  let key = path.pop();
  dataOriginal[key] = updateJson(dataOriginal[key], path, dataNew);

  return dataOriginal;
};

// Check Diff Json
export const isEqualsJson = (arg1, arg2, isUpload) => {
  if (
    Object.prototype.toString.call(arg1) ===
    Object.prototype.toString.call(arg2)
  ) {
    if (
      Object.prototype.toString.call(arg1) === "[object Object]" ||
      Object.prototype.toString.call(arg1) === "[object Array]"
    ) {
      if (Object.keys(arg1).length !== Object.keys(arg2).length) {
        return false;
      }
      return Object.keys(arg1).every(function (key) {
        return isEqualsJson(arg1[key], arg2[key], isUpload);
      });
    }
    return isUpload ? typeof arg1 === typeof arg2 : arg1 === arg2;
  }
  return false;
};

// Read file upload
export const parseJsonFileUpload = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    //@ts-ignore
    fileReader.onload = (event) => resolve(JSON.parse(event.target.result));
    fileReader.onerror = (error) => reject(error);
    fileReader.readAsText(file);
  });
};

export const checkEmptyInJson = (json) => {
  const keys = Object.keys(json);
  for (const key of keys) {
    if (json[key] === "") return true;
    if (typeof json[key] == "object" && checkEmptyInJson(json[key]))
      return true;
  }
  return false;
};
