import styled from "styled-components";

// form title component
export const FormTitleSegment = styled.div`
  padding: 24px;
  margin: 12px 0;
  border: 1px solid #dadce0;
  border-radius: 8px;
  background-color: #fff;
  position: relative;
`;
export const FormTitleSegmentIndicate = styled.div`
  background: #6898e3;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 10px;
  width: calc(100% + 2px);
  top: -1px;
  left: -1px;
  position: absolute;
`;

// Question title
export const QuestionContainer = styled.div`
  margin-bottom: 16px;
`;
export const QuestionText = styled.div`
  font-size: 16px;
  letter-spacing: 0.1px;
  line-height: 24px;
  color: #202124;
  font-weight: 400;
  width: 100%;
  word-break: break-word;
  white-space: break-spaces;
`;

// textarea
export const TextareaContainer = styled.div`
  min-height: 48px;
  position: relative;
  vertical-align: top;
  width: 100%;
  padding: 14px 0 8px 0;
`;
export const TextareaFlexContainer = styled.div`
  display: flex;
  position: relative;
`;
export const Textarea = styled.textarea`
  :: placeholder {
    color: ${(props: { isEmpty: boolean }) =>
      props.isEmpty ? "red" : "#70757a"};
  }
  height: auto;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 24px;
  color: #202124;
  background-color: transparent;
  border: none;
  outline: none;
  box-flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  resize: none;
`;
export const TextareaBorder = styled.div<{ isEmpty: boolean }>`
  background-color: ${({ isEmpty }) =>
    isEmpty ? "#d93025" : "rgba(0, 0, 0, 0.12)"};
  bottom: -2px;
  height: 1px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
`;
