import { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import englishTable from "./images/en_Wall.png";
import twTable from "./images/zh-TW_Wall.png";
import cnTable from "./images/zh-CN_Wall.png";
import { SetUpImage } from "../styles";
import {
  VideoListItem,
  OptionText,
  OrderList,
  Segment,
  SetUpSegment,
  Title,
  TitleIndicate,
} from "components/User/Evaluation/styles";
import { videoUploadTabs } from "constants/eval_constants";

interface SetUpProps {
  submitButton: (videoId: number) => JSX.Element;
  language: string;
}

interface SetUpState {}

type Props = SetUpProps & RouteComponentProps & WithTranslation;

class SetUp extends Component<Props, SetUpState> {
  getImage = () => {
    const { language } = this.props;
    switch (language) {
      case "en":
        return englishTable;
      case "zh-TW":
        return twTable;
      case "zh-CN":
        return cnTable;
      default:
        return "";
    }
  };

  render() {
    const { t } = this.props;
    const { submitButton } = this.props;
    return (
      <>
        <Segment>
          <TitleIndicate />
          <Title>{t("user.survey.corePro.video.setUp.alttitle")}</Title>
        </Segment>
        <SetUpSegment>
          <OrderList style={{ marginLeft: "20px", marginRight: "30px" }}>
            <VideoListItem>
              <OptionText>
                {t("user.survey.corePro.video.setUp.guidelines1")}
                <b>{t("user.survey.corePro.video.setUp.guideline1Bold")}</b>
              </OptionText>
            </VideoListItem>
            <VideoListItem>
              <OptionText>
                {t("user.survey.corePro.video.setUp.guidelines2")}
                <b>{t("user.survey.corePro.video.setUp.guidelines2Bold")}</b>
                {t("user.survey.corePro.video.setUp.guidelines2-1")}
              </OptionText>
            </VideoListItem>

            <VideoListItem>
              <OptionText>
                {t("user.survey.corePro.video.setUp.guidelines4")}
                <b>{t("user.survey.corePro.video.setUp.guidelines4Bold")}</b>
                {t("user.survey.corePro.video.setUp.guidelines4-1")}
              </OptionText>
              <OrderList>
                <VideoListItem className="subListLowerAlpha">
                  <OptionText>
                    {t("user.survey.corePro.video.setUp.guidelines4-2")}
                  </OptionText>
                </VideoListItem>
              </OrderList>
            </VideoListItem>
            <VideoListItem>
              <OptionText>
                {t("user.survey.corePro.video.setUp.guidelines5")}
                <b>{t("user.survey.corePro.video.setUp.guidelines5Bold")}</b>
              </OptionText>
            </VideoListItem>
            <VideoListItem>
              <OptionText>
                {t("user.survey.corePro.video.setUp.guidelines6")}
                <b>{t("user.survey.corePro.video.setUp.guidelines6Bold")}</b>
                {t("user.survey.corePro.video.setUp.guidelines6-1")}
              </OptionText>
            </VideoListItem>
            <VideoListItem>
              <OptionText>
                {t("user.survey.corePro.video.setUp.guidelines3")}
                <b>{t("user.survey.corePro.video.setUp.guidelines3Bold")}</b>
                {t("user.survey.corePro.video.setUp.guidelines3-1")}
              </OptionText>
            </VideoListItem>
          </OrderList>

          <SetUpImage
            className="center"
            src={this.getImage()}
            alt="Recording setup"
          />
        </SetUpSegment>
        {submitButton(videoUploadTabs.setup)}
      </>
    );
  }
}

export default withRouter(withTranslation()(SetUp));
