import { useTranslation } from "react-i18next";
import { GET_LANGUAGE } from "../../../../util/common";
import {
  AvailableTimesInput,
  AvailableTimesText,
  AvailableTimesContainer,
  AvailableTimesOptionContainer,
  ContactUsContainer,
  ContentContainer,
  Container,
} from "../styles";
import DropDown from "../../../common/DropDown";
import { getCountryCodeList } from "../../../../constants/countryCode";
import { getTimeZoneList } from "../../../../constants/timeZone";
import { ActionTitle, Description } from "styles";
import {
  AuthButton,
  AuthInputTitle,
  AuthTitle,
  InputArea,
  PhoneInput,
  PhoneInputContainer,
} from "components/auth/UserAuthForm/styles";

type Props = {
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCountryCodeChange: (value: string) => void;
  handleAvailableTimesChange: (id: number) => void;
  handleTimezoneChange: (value: string) => void;
  handleSubmit: () => void;
  onFocus: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  countryCode: string;
  phone: string;
  name: string;
  weChatId: string;
  timeZone: string;
  availableTimes: Array<number>;
};

export const Form = (props: Props) => {
  const { t } = useTranslation();
  const language = GET_LANGUAGE();
  return (
    <Container>
      <ContactUsContainer>
        <ContentContainer>
          <AuthTitle>{t(`general.contactUs.${props.type}.title`)}</AuthTitle>
          <AuthInputTitle>{t("general.contactUs.name")}</AuthInputTitle>
          <InputArea
            autoFocus
            onFocus={props.onFocus}
            onChange={props.handleInputChange}
            id="name"
            name="name"
            value={props.name}
            placeholder={t("general.contactUs.namePlaceholder")}
          />
          {language === "zh-CN" && (
            <>
              <AuthInputTitle>{t("general.contactUs.weChatId")}</AuthInputTitle>
              <InputArea
                autoFocus
                onFocus={props.onFocus}
                onChange={props.handleInputChange}
                id="weChatId"
                name="weChatId"
                value={props.weChatId}
                placeholder={t("general.contactUs.weChatIdPlaceholder")}
              />
            </>
          )}
          <AuthInputTitle>{t("auth.register.phone")}</AuthInputTitle>
          <PhoneInputContainer>
            <DropDown
              componentName="Contact us"
              type="countryCode"
              defaultSelection={props.countryCode}
              selectedText={props.countryCode}
              onOptionChange={props.handleCountryCodeChange}
              options={getCountryCodeList(t)}
            />
            <PhoneInput
              onFocus={props.onFocus}
              onChange={props.handleInputChange}
              id="phone"
              name="phone"
              type="tel"
              value={props.phone}
            />
          </PhoneInputContainer>
          <AuthInputTitle>{t("general.contactUs.timeZone")}</AuthInputTitle>
          <DropDown
            size="large"
            componentName="Contact us"
            type="timeZone"
            defaultSelection={props.timeZone}
            selectedText={props.timeZone}
            onOptionChange={props.handleTimezoneChange}
            options={getTimeZoneList(t)}
          />
          <AuthInputTitle>{t("general.contactUs.time.title")}</AuthInputTitle>
          <AvailableTimesContainer id="availableTimes">
            {[0, 1, 2, 3].map((i: number) => (
              <AvailableTimesOptionContainer
                onClick={() => props.handleAvailableTimesChange(i)}
              >
                <AvailableTimesInput
                  type="checkbox"
                  name="availableTimes"
                  checked={props.availableTimes.includes(i)}
                />
                <AvailableTimesText>
                  <Description>{t("general.contactUs.time." + i)}</Description>
                </AvailableTimesText>
              </AvailableTimesOptionContainer>
            ))}
          </AvailableTimesContainer>
          <AuthButton onClick={props.handleSubmit}>
            <ActionTitle>{t("general.contactUs.submit")}</ActionTitle>
          </AuthButton>
        </ContentContainer>
      </ContactUsContainer>
    </Container>
  );
};
