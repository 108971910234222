import {
  TherapistContainer,
  InfoContainer,
  DescriptionContainer,
  TherapistName,
  TherapistPhoto,
  TherapistTitle,
  TherapistNameContainer,
  HistoryContainer,
  History,
  BioContainer,
  Paragraph,
} from "../styles";
import { Therapist } from "components/User/Evaluation/CorePro/CoreProReport"; //Therapist Interface
import { THERAPIST_PROFILE_PICTURE_FOLDER_ON_S3_ROUTE } from "constants/common";
import { FORMAT_NAME_BY_COUNTRY } from "util/common";

type Props = {
  therapist: Therapist;
  therapistBio: [];
  UUID: string;
  language: string;
};

export default function TherapistInformation(props: Props) {
  const { UUID, therapist, therapistBio, language } = props;
  const therapistName = FORMAT_NAME_BY_COUNTRY(
    therapist.firstName,
    therapist.lastName,
    therapist.country
  );
  return (
    <TherapistContainer>
      <TherapistPhoto
        src={
          THERAPIST_PROFILE_PICTURE_FOLDER_ON_S3_ROUTE +
          therapist.avatar_filename
        }
        alt={UUID}
      />
      <InfoContainer>
        <TherapistNameContainer>
          <TherapistName>{therapistName}</TherapistName>
          <TherapistTitle>{therapist.title}</TherapistTitle>
        </TherapistNameContainer>
        <DescriptionContainer>
          <HistoryContainer>
            {therapistBio &&
              therapistBio.length > 0 &&
              therapistBio
                .filter(
                  (bio: { bio: string; language: string }) =>
                    bio.language === language.toUpperCase()
                )
                .slice(0, 3)
                .map((bio: { bio: string; language: string }) => (
                  <BioContainer>
                    <History></History>
                    <Paragraph>{bio.bio}</Paragraph>
                  </BioContainer>
                ))}
          </HistoryContainer>
        </DescriptionContainer>
      </InfoContainer>
    </TherapistContainer>
  );
}
