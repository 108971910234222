import styled from "styled-components";

export const ComponentContainer = styled.div`
  padding: 15px;
`;

export const ContentContainer = styled.div`
  margin-top: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid #6898e3;
`;

export const InformationContent = styled.div`
  font-size: 20px;
  line-height: 20px;
  margin-top: 15px;
`;

export const QuestionTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
`;
