import { Component, useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import {
  NextButton,
  CloseButtonContainer,
  ModalContainer,
  ModalHeader,
  ModalMask,
  ModalContent,
  ShowErrNextButton,
  HeaderTitle,
  ContentText,
  ModalContainerWithClose,
  ModalButtonContainer,
  ActionTitle,
} from "styles";
import { Icon } from "semantic-ui-react";

const SkipModal = (props) => {
  //Disable scrolling functions when modal opens
  const disableScroll = () => {
    document.addEventListener("wheel", preventDefault, { passive: false });
  };

  const enableScroll = () => {
    document.removeEventListener("wheel", preventDefault, false);
  };

  const preventDefault = (e: any) => {
    e = e || window.event;
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.returnValue = false;
  };

  //Disables scrolling when the modal is first loaded
  useEffect(() => {
    disableScroll();
  }, []);

  return (
    <ModalMask open={true}>
      <ModalContainerWithClose>
        <CloseButtonContainer>
          <Icon
            className="close"
            size="large"
            link
            style={{ marginBottom: "10px", marginRight: "-4px" }}
            onClick={function (event) {
              props.onCancel();
              enableScroll();
            }}
          />
        </CloseButtonContainer>
        <ModalContainer>
          <ModalHeader>
            <HeaderTitle>{props.modalHeader}</HeaderTitle>
          </ModalHeader>
          <ModalContent>
            <ContentText>{props.modalText}</ContentText>
          </ModalContent>
          <ModalButtonContainer>
            <ShowErrNextButton
              onClick={function (event) {
                enableScroll();
                props.onSkip();
              }}
              status={false}
              style={{
                marginRight: "20px",
                borderRadius: "50px",
              }}
            >
              <ActionTitle>{props.skipText}</ActionTitle>
            </ShowErrNextButton>
            <NextButton
              onClick={function (event) {
                enableScroll();
                props.onCancel();
              }}
              status={true}
              style={{ borderRadius: "50px" }}
            >
              <ActionTitle>{props.cancelText}</ActionTitle>
            </NextButton>
          </ModalButtonContainer>
        </ModalContainer>
      </ModalContainerWithClose>
    </ModalMask>
  );
};

export default withTranslation()(SkipModal);
