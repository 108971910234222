import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  Button as ButtonAntd,
  Form,
  Input,
  Space,
  Select,
  Row,
  Col,
} from "antd";
import { HiOutlineMinusCircle } from "react-icons/hi";
import {
  ModalHeader,
  ModalDescription,
  ModalContent,
  ModalActions,
  Button,
  Modal,
} from "semantic-ui-react";

interface therapistBioTextboxProps {
  currParentComponent: string;
  bio: Array<{ unique_bio_id: string; bio: string; language: string }>;
  handleBio: (bioList) => void;
  handleDeleteBio: (unique_bio_id: string) => void;
}

interface States {
  showOnDeleteModal: boolean;
  onDeleteUniqueBioId: string;
  removeFunc: any;
}

type Props = therapistBioTextboxProps & WithTranslation;

class TherapistBioTextbox extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showOnDeleteModal: false,
      onDeleteUniqueBioId: "",
      removeFunc: null,
    };
  }

  triggerOpenDeleteModal = (unique_bio_id: string, remove: Function) => {
    this.setState({
      showOnDeleteModal: true,
      onDeleteUniqueBioId: unique_bio_id,
      removeFunc: remove,
    });
  };
  triggerCloseDeleteModal = () => {
    this.setState({ showOnDeleteModal: false });
  };

  onFinishForm = (changedValues, allValues) => {
    const { handleBio } = this.props;
    handleBio(allValues.bioList);
  };
  render() {
    const { t, bio, handleDeleteBio, currParentComponent } = this.props;
    const { showOnDeleteModal, onDeleteUniqueBioId, removeFunc } = this.state;

    return (
      <div>
        <Modal
          onClose={this.triggerCloseDeleteModal}
          open={showOnDeleteModal}
          size={"small"}
        >
          <ModalHeader style={{ fontSize: "28px" }}>Are you sure?</ModalHeader>
          <ModalContent>
            <p style={{ fontSize: "20px" }}>
              Are you sure you want to delete this description? This process
              cannot be undone.
            </p>
          </ModalContent>
          <ModalActions>
            <Button
              color="twitter"
              style={{ fontSize: "16px" }}
              onClick={() => {
                this.triggerCloseDeleteModal();
                handleDeleteBio(onDeleteUniqueBioId);
                removeFunc(onDeleteUniqueBioId);
              }}
            >
              Yes
            </Button>
            <Button
              style={{ fontSize: "16px" }}
              onClick={() => {
                this.triggerCloseDeleteModal();
              }}
            >
              No
            </Button>
          </ModalActions>
        </Modal>
        <Form
          name="dynamic_form_nest_item"
          onValuesChange={this.onFinishForm}
          initialValues={{ bioList: bio }}
          style={{ maxWidth: "100%", minWidth: "500px" }}
        >
          <Form.List name="bioList">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Row
                    key={key}
                    gutter={16}
                    style={{ marginBottom: 8 }}
                    align="top"
                  >
                    <Col span={18}>
                      <Form.Item
                        {...restField}
                        name={[name, "bio"]}
                        rules={[{ required: true, message: "Missing bio" }]}
                      >
                        <Input.TextArea
                          autoSize={true}
                          placeholder={t("admin.therapist.bio")}
                          style={{ display: "flex" }} // IMPORTANT!!
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        {...restField}
                        name={[name, "language"]}
                        rules={[
                          { required: true, message: "Missing language" },
                        ]}
                      >
                        <Select
                          placeholder={t("admin.therapist.language")}
                          options={[
                            { value: "EN", label: "English" },
                            { value: "ZH-CN", label: "Simplified Chinese" },
                            { value: "ZH-TW", label: "Traditional Chinese" },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={1}
                      style={{
                        height: "32px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <HiOutlineMinusCircle
                        onClick={() => {
                          if (
                            bio[index] &&
                            bio[index].unique_bio_id &&
                            currParentComponent === "edit"
                          ) {
                            this.triggerOpenDeleteModal(
                              bio[index].unique_bio_id,
                              () => remove(name)
                            );
                          } else {
                            remove(name);
                          }
                        }}
                        style={{ cursor: "pointer" }} // Add cursor pointer style for better UX
                      />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <ButtonAntd type="dashed" onClick={() => add()} block>
                    Add field
                  </ButtonAntd>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </div>
    );
  }
}

export default withTranslation()(TherapistBioTextbox);
