import { useTranslation } from "react-i18next";
import axios from "axios";
import api_address from "constants/config";

export const GET_ALL_THERAPIST_PUBLIC = async (): Promise<any> => {
  const res = await axios.get(api_address + "api/therapist");
  const { status, therapist, message } = res.data;
  return { status, therapist };
};

export const GET_THERAPIST_BY_UUID = async (
  UUID: string,
  token: string
): Promise<any> => {
  const res = await axios.get(
    api_address + "api/admin/therapist/uuid/" + UUID,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const { status, therapist } = res.data;
  return { status, therapist };
};

export const GET_THERAPIST_BIO_BY_THERAPIST_ID_PUBLIC = async (
  therapist_id: number | undefined
): Promise<any> => {
  const res = await axios.get(
    api_address + "api/therapist/bio/" + therapist_id
  );
  const { status, therapistBio } = res.data;
  return { status, therapistBio };
};

export const GET_THERAPIST_ID_LIST = () => {
  const { t } = useTranslation();
  const therapistList = t("therapist.name", { returnObjects: true });
  return Object.keys(therapistList);
};

export const isImageFileSizeBelow5MB = (fileSize: any) => {
  const isLt5M = fileSize / 1024 / 1024 < 5;
  if (!isLt5M) throw new Error("Image must smaller than 5MB!");
  return isLt5M;
};

export const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    try {
      if (!file) throw new Error("File is unavailable!");
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    } catch (err: any) {
      console.error(err.message);
    }
  });
