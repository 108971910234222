import gql from "graphql-tag";
import { User } from "../types/User";
import { ReturnMessage } from "../types/ReturnMessage";

export const TOKEN_LOGIN_MUTATION = gql`
  mutation EvalTokenLogin($token: String!) {
    evalTokenLogin(token: $token) {
      user {
        id
        name
        email
        metaType
        phoneNumber
      }
      token
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface Data {
  evalTokenLogin: {
    user?: User;
    token: string;
    returnMessage: ReturnMessage;
  };
}

export interface Variables {
  token: string;
}
