import { Component } from "react";
import { History, LocationState } from "history";
import { withTranslation, WithTranslation } from "react-i18next";
import Cookies from "js-cookie";
import ChangeCountryModal from "../ChangeCountryPopUp/ChangeCountryModal";
import {
  GET_USER_INFO,
  GET_TITLE_BAR,
  GET_TRANSACTION_HISTORY,
} from "util/common";

interface ChangeCountryProps {
  history: History<LocationState>;
}

interface ChangeCountryState {
  displayPopUp: boolean;
  transactionHistory: any;
}

type Props = ChangeCountryProps & WithTranslation;

class ChangeCountry extends Component<Props, ChangeCountryState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      displayPopUp: false,
      transactionHistory: [],
    };
  }

  componentDidMount = async () => {
    try {
      const { t } = this.props;
      document.title = GET_TITLE_BAR(t, "profile", true);
      const token = Cookies.get("token");
      if (!token) {
        this.props.history.push("/login");
      }
      const userData = await GET_USER_INFO();
      if (userData) {
        const transactionHistory = await GET_TRANSACTION_HISTORY();
        this.setState({ transactionHistory: transactionHistory.transactions });
      }
      this.setState({
        displayPopUp: userData.country == "",
      });
    } catch (err: any) {
      console.error(err.message);
      this.props.history.push("/login");
    }
  };

  handleClick = () => {
    this.setState({ displayPopUp: false });
  };

  render() {
    const { t } = this.props;
    return (
      <ChangeCountryModal
        display={this.state.displayPopUp}
        onClick={this.handleClick}
        title={t("auth.login.chooseCountry")}
        text={t("auth.login.chooseCountryDescription")}
        buttonText={t("auth.login.submit")}
        {...this.props}
        {...this.state}
      ></ChangeCountryModal>
    );
  }
}

export default withTranslation()(ChangeCountry);
