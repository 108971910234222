import styled from "styled-components";
import purchaseYesImg from "./assets/checkoutChecked.svg";
import purchaseNoImg from "./assets/unchecked.svg";
import Core from "../../general/assets/core.svg";
import CorePro from "../../general/assets/CorePro.png";
import PEA from "../../general/assets/PEA.jpeg";
import coreImg from "./assets/core.png";
import coreProImg from "./assets/corePro.png";
import { PRODUCTS } from "../../../constants/common";
import QRCode from "../../general/assets/QR/WeChatZhuJiao.jpeg";

// Purchase
export const PurchaseContainer = styled.div`
  text-align: -webkit-center;
  @media only screen and (max-width: 575px) {
    padding: 30px 5% 0 5%;
  }
  @media only screen and (min-width: 576px) {
    padding: 30px 5% 0 5%;
  }
  @media only screen and (min-width: 768px) {
    padding: 30px 5% 0 5%;
  }
  @media only screen and (min-width: 992px) {
    padding: 30px 5% 0 5%;
  }
  @media only screen and (min-width: 1200px) {
    padding: 30px 5% 0 5%;
  }
`;

export const PurchaseContainerTitle = styled.div`
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: bold;
  color: #000000;
`;

// Currency
export const PurchaseCurrencyContainer = styled.div`
  margin-top: 20px;
`;
export const PurchaseCurrencyInput = styled.div`
  margin-top: 8px;
  display: flex;
`;
export const PurchaseCurrencyInputButton = styled.input``;
export const PurchaseCurrencyInputText = styled.span`
  font-size: 16px;
  font-weight: normal;
  color: #000000;
`;

// Product
export const PurchaseProductContainer = styled.div<{ isSelect: boolean }>`
  margin-top: 40px;
  height: max-content;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: ${({ isSelect }) =>
    isSelect ? "solid 1px #6898e3" : "solid 1px #fff"};
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 16px;
  @media only screen and (max-width: 600px) {
    display: block;
  }
`;

export const PurchaseProductImg = styled.img`
  width: 264px;
  height: 268px;
  content: url(${(props: { name: string }) => {
    if (props.name === PRODUCTS.Core) return Core;
    if (props.name === PRODUCTS.CorePro) return CorePro;
    if (props.name === PRODUCTS.PEA) return PEA;
    return;
  }});
  object-fit: cover;
  border-radius: 20px;
  @media only screen and (max-width: 600px) {
    width: 164px;
    height: 168px;
  }
`;

export const PurchaseProductIntroductionContainer = styled.div`
  width: 40%;
  text-align: left;
  @media only screen and (max-width: 720px) {
    width: 100%;
    margin-top: 15px;
  }
`;
export const PurchaseProductIntroductionTitleContainer = styled.div`
  width: 200px;
  height: 35px;
`;
export const PurchaseProductIntroductionTitleText = styled.div`
  font-size: 24px;
  color: #6898e3;
`;
export const PurchaseProductIntroductionTitleImg = styled.img<{ name: string }>`
  height: 36px;
  width: 152px;
  margin-top: 10px;
  content: url(${(props) => {
    if (props.name === PRODUCTS.Core) return coreImg;
    if (props.name === PRODUCTS.CorePro) return coreProImg;
    return coreProImg;
  }});
`;
export const PurchaseProductIntroductionSubTitle = styled.div`
  text-align: left;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  width: 90%;
`;
export const PurchaseProductIntroductionDescription = styled.div`
  text-align: left;
  margin-top: 15px;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #000000;
  width: 90%;
  white-space: pre-wrap;
`;
export const PurchaseProductIntroductionPrice = styled.div`
  margin-top: 50px;
  font-size: 24px;
  color: #000000;
  text-align: center;
`;
export const PurchaseProductSelected = styled.div`
  margin: 20px 0;
  width: 48px;
`;

export const PurchaseProductSelectedImg = styled.img<{ selected: boolean }>`
  width: 48px;
  height: 48px;
  content: url(${(props) => {
    if (props.selected) return purchaseYesImg;
    return purchaseNoImg;
  }});
`;

// Checkout
export const PurchaseCheckoutContainer = styled.div`
  margin-top: 40px;
  height: 33px;
  padding-bottom: 80px;
`;

export const ContactUsContainer = styled.div`
  text-align: left;
  margin-top: 10px;
  padding: 0 20px;
  min-height: 100px;
  width: 100%;
  border: solid 1px #c4c4c4;
  background-color: #f0f1f4;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #6898e3;
  font-size: 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

export const QRCodeImg = styled.img`
  content: url(${QRCode});
  height: 95px;
  border-radius: 8px;
`;
