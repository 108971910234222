import React, { Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import api_address from "../../../../../constants/config";
import { withTranslation, WithTranslation } from "react-i18next";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { withRouter, match, RouteComponentProps } from "react-router-dom";
import { GET_TITLE_BAR, GET_LANGUAGE } from "util/common";
import {
  Container,
  OtherActionContainer,
  Button,
  PrintA4Container,
} from "./styles";
import { ROLE } from "../../../../../constants/common";
import Cover from "./Cover";
import TableOfContent from "./TableOfContent";
import BasicInformation from "./BasicInformation";
import ReviewAndSuggestion from "./ReviewAndSuggestion";
import GeneralAnalysis from "./GeneralAnalysis";
import VideoAnalysis from "./VideoAnalysis";
import Activity from "./Activity";
import Appendix1 from "./Appendix1";
import Appendix2 from "./Appendix2";
import FollowUs from "./FollowUs";
import {
  basicInformationType,
  initialBasicInformation,
  reviewAndSuggestionType,
  initialReviewAndSuggestion,
  generalAnalysisType,
  initialGeneralAnalysis,
  videoAnalysisType,
  initialVideoAnalysis,
  activitiesType,
  initialActivities,
} from "../data";
import NotificationModal from "components/common/NotificationModal";
import { GET_THERAPIST_BY_UUID } from "util/therapist";
import { GET_COOKIE_NAME, JS_COOKIE } from "util/auth";
import { connect } from "react-redux";
import { makeSelectRole } from "components/auth/selectors";
import { createStructuredSelector } from "reselect";
import { AppState } from "storeConfig";

export interface WindowWithDataLoaded extends Window {
  dataLoaded?: boolean;
}
interface CoreProReportProps {
  match?: match<Match>;
}

interface Match {
  resultId: any;
}

export interface Therapist {
  id: number;
  firstName: string;
  lastName: string;
  country: string;
  title: string;
  avatar_filename: string;
}

interface CoreProReportState {
  message: string;
  notificationStatus: boolean;
  showModal: boolean;
  therapistUUID: string;
  therapist: Therapist;
  therapistBio: [];
  createdAt: string;
  therapistBioLanguage: string;
  language: string;
  basicInformation: basicInformationType;
  reviewAndSuggestion: reviewAndSuggestionType;
  generalAnalysis: generalAnalysisType;
  videoAnalysis: videoAnalysisType;
  activities: activitiesType;
  role: string;
  isDownload: boolean;
  dataLoaded: boolean;
}

type Props = CoreProReportProps &
  WithTranslation &
  RouteComponentProps &
  LinkStateProp;

class CoreProReport extends Component<Props, CoreProReportState> {
  private componentRef: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      message: "",
      notificationStatus: false,
      showModal: false,
      therapistUUID: "",
      therapist: {
        id: 0,
        firstName: "",
        lastName: "",
        country: "",
        title: "",
        avatar_filename: "",
      },
      therapistBio: [],
      createdAt: "",
      therapistBioLanguage: "",
      language: "",
      basicInformation: initialBasicInformation,
      reviewAndSuggestion: initialReviewAndSuggestion,
      generalAnalysis: initialGeneralAnalysis,
      videoAnalysis: initialVideoAnalysis,
      activities: initialActivities,
      role: "",
      isDownload: false,
      dataLoaded: false,
    };
    this.componentRef = React.createRef();
  }

  componentDidMount = async () => {
    try {
      const { t, role } = this.props;
      document.title = GET_TITLE_BAR(t, "coreProReport", true);
      const token = JS_COOKIE.get(GET_COOKIE_NAME(role));
      const { resultId } = this.props.match.params;

      if (!token) {
        await this.showNotification("Cannot get Core Pro report", false);
        this.props.history.push("/login");
        return;
      }

      const isDownload = this.getIsDownloadFromURL();

      const languageURL = this.getReportLanguageFromURL();
      if (languageURL) {
        this.props.i18n.changeLanguage(languageURL);
      }

      const res = await axios.get(
        api_address + "api/therapistForm/expertReport/" + resultId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { status, result, authData } = res.data;
      if (!status) {
        this.props.history.push("/profile");
      }
      const res_getUserBasicInfo = await axios.get(
        api_address + "api/user/basicInfo/" + result.userId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { user } = res_getUserBasicInfo.data;
      let bioLanguage = "EN";
      if (user.country === "cn" || user.country === "hk") {
        bioLanguage = "ZH-CN";
      } else if (user.country === "tw") {
        bioLanguage = "ZH-TW";
      }
      const language = GET_LANGUAGE();
      this.setState({
        language,
        isDownload,
        languageURL,
        therapistBioLanguage: bioLanguage,
        // therapistUUID,
        dataLoaded: true,
        ...result,
        role,
      });
      const { therapistUUID } = this.state;
      const therapist = await GET_THERAPIST_BY_UUID(therapistUUID, token);
      const {
        status: get_therapist_status,
        therapist: { therapistData, therapistBio },
      } = therapist;
      if (get_therapist_status && therapistData) {
        this.setState({ therapist: therapistData, therapistBio: therapistBio });
      }
      (window as WindowWithDataLoaded).dataLoaded = true;
      window.scrollTo(0, 0);
    } catch (err: any) {
      console.error(err.message);
    }
  };

  getReportLanguageFromURL = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    return searchParams.get("reportLanguage") || "en";
  };

  getIsDownloadFromURL = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    return searchParams.get("isDownload") === "True";
  };

  componentDidUpdate = (prevProps: Props, prevState: CoreProReportState) => {
    const language = GET_LANGUAGE();
    if (prevState.language !== language) {
      this.setState({ language });
    }
  };

  showNotification = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showModal: true,
    });
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        this.setState({ showModal: false });
        resolve();
      }, 1000);
    });
  };

  goEdit = () => {
    const { resultId } = this.props.match.params;
    const { role } = this.props;

    if (role === ROLE.admin) {
      this.props.history.push("/admin/report-generation/" + resultId);
    } else {
      this.props.history.push("/report-generation/" + resultId);
    }
  };

  goFinish = () => {
    const { resultId } = this.props.match.params;
    const { role } = this.props;
    if (role === ROLE.admin) {
      this.props.history.push("/admin/result");
    } else {
      this.props.history.push("/therapist");
    }
  };

  render = () => {
    const { t } = this.props;
    const { resultId } = this.props.match.params;
    const { role, showModal, message, notificationStatus, isDownload } =
      this.state;
    const { childName } = this.state.basicInformation;
    return (
      <Container>
        {/* Notification Modal */}
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />

        {/* Conditionally Render OtherActionContainer */}
        {isDownload && (
          <OtherActionContainer>
            {role === ROLE.user && (
              <ReactToPrint content={() => this.componentRef}>
                <PrintContextConsumer>
                  {({ handlePrint }) => (
                    <Button onClick={handlePrint}>
                      {t("user.survey.corePro.result.print")}
                    </Button>
                  )}
                </PrintContextConsumer>
              </ReactToPrint>
            )}
            {(role === ROLE.therapist || role === ROLE.admin) && (
              <>
                <Button onClick={this.goEdit}>
                  {t("user.survey.corePro.result.edit")}
                </Button>
                <Button onClick={this.goFinish}>
                  {t("user.survey.corePro.result.finish")}
                </Button>
              </>
            )}
          </OtherActionContainer>
        )}

        {/* Report Content */}
        <div ref={(el) => (this.componentRef = el)}>
          <PrintA4Container>
            <Cover {...this.state} childName={childName} />
          </PrintA4Container>

          <PrintA4Container>
            <TableOfContent {...this.state} />
          </PrintA4Container>

          <PrintA4Container>
            <BasicInformation {...this.state} />
          </PrintA4Container>

          <PrintA4Container>
            <ReviewAndSuggestion {...this.state} />
          </PrintA4Container>

          <PrintA4Container>
            <GeneralAnalysis {...this.state} />
          </PrintA4Container>

          <PrintA4Container>
            <VideoAnalysis {...this.state} />
          </PrintA4Container>

          <PrintA4Container>
            <Activity {...this.state} />
          </PrintA4Container>

          <PrintA4Container>
            <Appendix1 {...this.state} />
          </PrintA4Container>

          <PrintA4Container>
            <Appendix2 {...this.state} />
          </PrintA4Container>

          <PrintA4Container>
            <FollowUs {...this.state} />
          </PrintA4Container>
        </div>
      </Container>
    );
  };
}

interface LinkStateProp {
  role: string;
}

const mapStateToProps = createStructuredSelector<AppState, LinkStateProp>({
  role: makeSelectRole(),
});
export default withRouter(
  connect(mapStateToProps)(withTranslation()(CoreProReport))
);
