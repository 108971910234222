import { withTranslation, WithTranslation } from "react-i18next";
import {
  ComponentContainer,
  ContentContainer,
  QuestionTitle,
  InformationContent,
} from "./styles";

interface childInfoType {
  childName: string;
  childBirthdayYear: string;
  childBirthdayMonth: string;
  childBirthdayDay: string;
  worryStatus: string;
  respondent: string;
  respondentOtherText: string;
  sex: string;
  birthStatus: string;
  birthStatusOtherText: string;
  postpartumStatus: object;
  postpartumStatusOther: string;
  babyHealthStatus: object;
  babyHealthStatusOther: string;
  hearingStatus: string;
  eyeStatus: string;
  familyLanguage: object;
  familyLanguageOther: string;
}

type Props = childInfoType & WithTranslation;

function ChildInfo(props: Props) {
  const {
    t,
    birthStatus,
    birthStatusOtherText,
    childName,
    childBirthdayYear,
    childBirthdayMonth,
    childBirthdayDay,
    sex,
    postpartumStatus,
    postpartumStatusOther,
    babyHealthStatus,
    babyHealthStatusOther,
    respondent,
    respondentOtherText,
    familyLanguage,
    familyLanguageOther,
    worryStatus,
    hearingStatus,
    eyeStatus,
  } = props;

  const showOtherText = (
    options: object,
    translateKey: string,
    lastKey: string,
    otherString: string,
    otherFactor?: string[]
  ) => {
    let str = "";
    Object.keys(options).forEach((k) => {
      if (options[k]) {
        if (k === lastKey) str += otherString;
        else str += t(`therapist.fullResult.childInfo.${translateKey}.${k}`);
        str += ", ";
      }
    });
    if (
      otherFactor &&
      !options[lastKey] &&
      otherFactor.filter((f) => options[f]).length >= 1
    )
      str += otherString + ", ";
    return str.slice(0, -2);
  };
  const postpartumStatusText = showOtherText(
    postpartumStatus,
    "postPartumStatus",
    "5",
    postpartumStatusOther
  );

  const familyLanguageText = showOtherText(
    familyLanguage,
    "familyLanguage",
    "3",
    familyLanguageOther
  );

  const babyHealthStatusText = showOtherText(
    babyHealthStatus,
    "babyHealthStatus",
    "1",
    babyHealthStatusOther,
    ["2", "3", "4"]
  );

  return (
    <ComponentContainer>
      <ContentContainer>
        <QuestionTitle>
          {t(`therapist.fullResult.childInfo.birthStatus.title`)}
        </QuestionTitle>
        <InformationContent>
          {birthStatus === "3"
            ? birthStatusOtherText
            : t(`therapist.fullResult.childInfo.birthStatus.${birthStatus}`)}
        </InformationContent>
      </ContentContainer>

      <ContentContainer>
        <QuestionTitle>
          {t(`therapist.fullResult.childInfo.childName.title`)}
        </QuestionTitle>
        <InformationContent>{childName}</InformationContent>
      </ContentContainer>

      <ContentContainer>
        <QuestionTitle>
          {t(`therapist.fullResult.childInfo.birthday.title`)}
        </QuestionTitle>
        <InformationContent>{`${childBirthdayYear}${t(
          `therapist.fullResult.childInfo.birthday.year`
        )}${childBirthdayMonth}${t(
          `therapist.fullResult.childInfo.birthday.month`
        )}${childBirthdayDay}${t(
          `therapist.fullResult.childInfo.birthday.day`
        )}`}</InformationContent>
      </ContentContainer>

      <ContentContainer>
        <QuestionTitle>
          {t(`therapist.fullResult.childInfo.sex.title`)}
        </QuestionTitle>
        <InformationContent>
          {t(`therapist.fullResult.childInfo.sex.${sex}`)}
        </InformationContent>
      </ContentContainer>

      <ContentContainer>
        <QuestionTitle>
          {t(`therapist.fullResult.childInfo.postPartumStatus.title`)}
        </QuestionTitle>
        <InformationContent>{postpartumStatusText}</InformationContent>
      </ContentContainer>

      <ContentContainer>
        <QuestionTitle>
          {t(`therapist.fullResult.childInfo.babyHealthStatus.title`)}
        </QuestionTitle>
        <InformationContent>{babyHealthStatusText}</InformationContent>
      </ContentContainer>

      <ContentContainer>
        <QuestionTitle>
          {t(`therapist.fullResult.childInfo.respondent.title`)}
        </QuestionTitle>
        <InformationContent>
          {respondent === "3"
            ? respondentOtherText
            : t(`therapist.fullResult.childInfo.respondent.${respondent}`)}
        </InformationContent>
      </ContentContainer>

      <ContentContainer>
        <QuestionTitle>
          {t(`therapist.fullResult.childInfo.familyLanguage.title`)}
        </QuestionTitle>
        <InformationContent>{familyLanguageText}</InformationContent>
      </ContentContainer>

      <ContentContainer>
        <QuestionTitle>
          {t(`therapist.fullResult.childInfo.worryLevel.title`)}
        </QuestionTitle>
        <InformationContent>
          {t(`therapist.fullResult.childInfo.worryLevel.${worryStatus}`)}
        </InformationContent>
      </ContentContainer>

      <ContentContainer>
        <QuestionTitle>
          {t(`therapist.fullResult.childInfo.hearing.title`)}
        </QuestionTitle>
        <InformationContent>
          {t(`therapist.fullResult.childInfo.hearing.${hearingStatus}`)}
        </InformationContent>
      </ContentContainer>

      <ContentContainer>
        <QuestionTitle>
          {t(`therapist.fullResult.childInfo.eyesight.title`)}
        </QuestionTitle>
        <InformationContent>
          {t(`therapist.fullResult.childInfo.eyesight.${eyeStatus}`)}
        </InformationContent>
      </ContentContainer>
    </ComponentContainer>
  );
}

export default withTranslation()(ChildInfo);
