import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import {
  QuestionText,
  Segment,
  Title,
  TitleIndicate,
  Input,
  ListContainer,
  ListItem,
} from "../../styles";
import { ShowErrNextButton } from "styles";
import { ButtonContainer, Container } from "../../../User/Evaluation/styles";
import { GET_TITLE_BAR } from "util/common";

type Props = {
  transactionId: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleNext: () => void;
  displayNotificationMessage: (message: string, status: boolean) => void;
};

export default function BJCHIntro(props: Props) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("zh-CN");
    document.title = GET_TITLE_BAR(t, "BJCH", false);
    const favicon = document.getElementById("favicon") as HTMLLinkElement;
    favicon.href = "/ncch.ico";
  }, []);

  const validation = () => {
    const { transactionId } = props;
    if (transactionId === "") {
      throw new Error("请输入您的病历号");
    }
  };

  const onClickNext = () => {
    try {
      validation();
      props.handleNext();
    } catch (err: any) {
      props.displayNotificationMessage(err.message, false);
    }
  };

  const onClickEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onClickNext();
    }
  };

  return (
    <Container>
      <Segment>
        <TitleIndicate />
        <Title>0-6岁儿童综合语言发育量表科研项目</Title>
      </Segment>
      <Segment>
        <QuestionText>
          为能更好的帮助各地家庭与医护了解孩子语言发育情况并能做到更好的诊治与干预，我们目前正展开本语言量表的临床应用信效度科研。诚挚感谢您的参与。
        </QuestionText>
        <QuestionText>流程：</QuestionText>
        <ListContainer>
          <ListItem>签参与计划的知情同意书</ListItem>
          <ListItem>填写电子问卷</ListItem>
        </ListContainer>
        <QuestionText>
          成功送出后，您可再填入病历号查看结果与定制推荐锻炼活动与建议。
        </QuestionText>
        <QuestionText>
          (测评结果正进行科研中，不代表临床判断，仅供参考)
        </QuestionText>
      </Segment>
      <Segment>
        <QuestionText>请输入您的病历号:</QuestionText>
        <Input
          autoFocus
          name="transactionId"
          type="text"
          value={props.transactionId}
          onChange={props.handleInputChange}
          style={{ margin: "20px 0;", borderRadius: "4px;" }}
          onKeyDown={onClickEnter}
        />
      </Segment>
      <ButtonContainer>
        <ShowErrNextButton onClick={onClickNext} status={true}>
          {t("user.survey.core.questionnaire.intro.corePro.4.continue")}
        </ShowErrNextButton>
      </ButtonContainer>
    </Container>
  );
}
