import styled from "styled-components";

export const StateImage = styled.img`
  width: 60%;
  @media only screen and (min-width: 576px) {
    width: 60%;
  }
  @media only screen and (min-width: 768px) {
    width: 30%;
  }
  @media only screen and (min-width: 992px) {
    width: 30%;
  }
  @media only screen and (min-width: 1200px) {
    width: 20%;
  }
`;

export const InformationContentContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
`;

export const InformationContentImage = styled.img`
  height: 100px;
  width: 100px;
  border-radius: 100%;
  object-fit: cover;
`;
export const ZoomImage = styled.img``;

export const QRCode = styled.img`
  width: 100px;
  height: 100px;
  background-color: #3984fd;
  border-radius: 4px;
  box-shadow: 5px 5px 5px 0px #c4c4c4;
`;
