import { useTranslation } from "react-i18next";
import { OptionText, Segment, Title } from "../../../styles";
import { StatusText } from "./styles";
import { GET_STATUS_BY_COLOR } from "../../../../../../util/result";
import { PRODUCTS } from "../../../../../../constants/common";
import { LockModal } from "../Utils";

type Props = {
  product: string;
  summary: string;
  lockSummaryAndActivity: boolean;
};

const LockedSummary = () => {
  const { t } = useTranslation();
  return (
    <>
      <Title
        style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}
      >
        {t("user.survey.core.result.summary")}
      </Title>
      <div style={{ position: "relative" }}>
        <LockModal />
        <Segment style={{ height: "72px" }} />
      </div>
    </>
  );
};

const UnLockedSummary = (props: Props) => {
  const { t } = useTranslation();
  const { product, summary } = props;
  const statusColor = GET_STATUS_BY_COLOR(summary);
  return (
    <>
      <Title
        style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}
      >
        {t("user.survey.core.result.summary")}
      </Title>
      <Segment>
        <OptionText>
          {t("user.survey.core.result.outcome1")}
          <StatusText status={statusColor}>
            {t(`utils.fourStatus.${summary}`)}
          </StatusText>
          {product !== PRODUCTS.BJCH_Questionnaire &&
            t("user.survey.core.result.outcome2")}
        </OptionText>
      </Segment>
    </>
  );
};

export const Summary = (props: Props) => {
  return props.lockSummaryAndActivity ? (
    <LockedSummary />
  ) : (
    <UnLockedSummary {...props} />
  );
};
