import styled from "styled-components";
import purchaseYesImg from "../assets/buyUpChecked.svg";
import purchaseNoImg from "../assets/unchecked.svg";
import selfEvalImg from "../assets/core.png";
import coreProImg from "../assets/corePro.png";
import selfEval from "../../../general/assets/core.svg";
import CorePro from "../../../general/assets/CorePro.png";
import CPC from "../../../general/assets/CPC.png";
import QRCode from "../../../general/assets/QR/WeChatZhuJiao.jpeg";
import { PRODUCTS } from "../../../../constants/common";

// Purchase
export const PurchaseContainer = styled.div`
  text-align: -webkit-center;
  @media only screen and (max-width: 575px) {
    padding: 30px 5% 0 5%;
  }
  @media only screen and (min-width: 576px) {
    padding: 30px 5% 0 5%;
  }
  @media only screen and (min-width: 768px) {
    padding: 30px 5% 0 5%;
  }
  @media only screen and (min-width: 992px) {
    padding: 30px 5% 0 5%;
  }
  @media only screen and (min-width: 1200px) {
    padding: 30px 5% 0 5%;
  }
`;

export const PurchaseContainerTitle = styled.div`
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: bold;
  color: #000000;
`;

// Currency
export const PurchaseCurrencyContainer = styled.div`
  margin-top: 20px;
`;
export const PurchaseCurrencyInput = styled.div`
  margin-top: 8px;
  display: flex;
`;
export const PurchaseCurrencyInputButton = styled.input``;
export const PurchaseCurrencyInputText = styled.span`
  font-size: 16px;
  font-weight: normal;
  color: #000000;
`;

// Product
export const PurchaseProductContainer = styled.div`
  width: fit-content;
  margin: 20px 20px 10px 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: #00000085;
  border-radius: 20px;
  display: block;
  // flex-wrap: wrap;
  // justify-content: space-around;
  // align-items: center;
  padding: 20px;
`;

export const PurchaseProductImg = styled.img<{ name: string }>`
  width: 264px;
  height: 268px;
  margin: auto 0;
  object-fit: cover;
  content: url(${(props) => {
    if (props.name === PRODUCTS.ModBuyUp) return selfEval;
    if (props.name === PRODUCTS.CorePro) return CorePro;
    if (props.name === PRODUCTS.CPC) return CPC;
    return;
  }});
  @media only screen and (max-width: 600px) {
    width: 164px;
    height: 168px;
  }
`;

export const PurchaseProductIntroductionContainer = styled.div`
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const PurchaseProductIntroductionTitleContainer = styled.div`
  width: 200px;
  height: 35px;
`;
export const PurchaseProductIntroductionTitleText = styled.h1`
  color: #6898e3;
`;
export const PurchaseProductIntroductionTitleImg = styled.img<{ name: string }>`
  height: 36px;
  width: 152px;
  margin-top: 10px;
  content: url(${(props) => {
    if (props.name === PRODUCTS.ModBuyUp) return selfEvalImg;
    if (props.name === PRODUCTS.CorePro) return coreProImg;
    return coreProImg;
  }});
`;
export const PurchaseProductIntroductionSubTitle = styled.div`
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  width: 60%;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const PurchaseProductIntroductionPrice = styled.div`
  width: 100%;
  line-height: 60px;
  font-size: 24px;
  color: #000000;
  text-align: center;
`;
export const PurchaseProductSelected = styled.div`
  margin-top: 110px;
  width: 48px;
`;

export const PurchaseProductSelectedImg = styled.img<{ selected: boolean }>`
  width: 48px;
  height: 48px;
  content: url(${(props) => {
    if (props.selected) return purchaseYesImg;
    return purchaseNoImg;
  }});
`;

// Checkout

export const PurchaseCheckoutButton = styled.div`
  margin: 40px auto 0 auto;
  width: 90%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #6898e3;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
`;

export const ContactUsContainer = styled.div`
  text-align: left;
  margin-top: 10px;
  padding: 0 20px;
  min-height: 100px;
  width: 100%;
  border: solid 1px #c4c4c4;
  background-color: #f0f1f4;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #6898e3;
  font-size: 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

export const QRCodeImg = styled.img`
  content: url(${QRCode});
  height: 95px;
`;
