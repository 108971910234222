import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  A4Container,
  A4ContentContainer,
  AppendixContainer,
  ContentText,
  SectionTitle,
  SmallSectionTitle,
  TipsContainer,
  PageNumber,
  WaterMark,
} from "../styles";
import { getWatermark } from "../ImageLoader";

interface AppendixProps {
  language: string;
}

interface AppendixState {}

type Props = AppendixProps & WithTranslation;

class Appendix1 extends Component<Props, AppendixState> {
  get0To18Month = () => {
    const { t } = this.props;
    return (
      <>
        <SmallSectionTitle>
          {t("utils.milestones.0to18Month.title")}
        </SmallSectionTitle>
        <AppendixContainer>
          <SmallSectionTitle>
            {t("utils.milestones.0to18Month.before3Month.title")}
          </SmallSectionTitle>
          <ContentText>
            {t("utils.milestones.0to18Month.before3Month.content")}
          </ContentText>
          <TipsContainer>
            <ContentText>
              {t("utils.milestones.0to18Month.3monthTips")}
            </ContentText>
          </TipsContainer>
          <SmallSectionTitle>
            {t("utils.milestones.0to18Month.4to6Month.title")}
          </SmallSectionTitle>
          <ContentText>
            {t("utils.milestones.0to18Month.4to6Month.content")}
          </ContentText>
          <SmallSectionTitle>
            {t("utils.milestones.0to18Month.7to9Month.title")}
          </SmallSectionTitle>
          <ContentText>
            {t("utils.milestones.0to18Month.7to9Month.content")}
          </ContentText>
          <TipsContainer>
            <ContentText>
              {t("utils.milestones.0to18Month.7monthTips")}
            </ContentText>
          </TipsContainer>
          <SmallSectionTitle>
            {t("utils.milestones.0to18Month.10to12Month.title")}
          </SmallSectionTitle>
          <ContentText>
            {t("utils.milestones.0to18Month.10to12Month.content")}
          </ContentText>
          <TipsContainer>
            <ContentText>
              {t("utils.milestones.0to18Month.1yearTips")}
            </ContentText>
          </TipsContainer>
          <SmallSectionTitle>
            {t("utils.milestones.0to18Month.13to15Month.title")}
          </SmallSectionTitle>
          <ContentText>
            {t("utils.milestones.0to18Month.13to15Month.content")}
          </ContentText>
        </AppendixContainer>
      </>
    );
  };

  get18MonthTo4Year = () => {
    const { t } = this.props;
    return (
      <>
        <SmallSectionTitle>
          {t("utils.milestones.18MonthTo4Year.title")}
        </SmallSectionTitle>
        <AppendixContainer>
          <SmallSectionTitle>
            {t("utils.milestones.18MonthTo4Year.18monthTo2Year.title")}
          </SmallSectionTitle>
          <ContentText>
            {t("utils.milestones.18MonthTo4Year.18monthTo2Year.content")}
          </ContentText>
          <SmallSectionTitle>
            {t("utils.milestones.18MonthTo4Year.2to3year.title")}
          </SmallSectionTitle>
          <ContentText>
            {t("utils.milestones.18MonthTo4Year.2to3year.content")}
          </ContentText>
          <TipsContainer>
            <ContentText>
              {t("utils.milestones.18MonthTo4Year.2to3YearTips")}
            </ContentText>
          </TipsContainer>
          <SmallSectionTitle>
            {t("utils.milestones.18MonthTo4Year.3to4year.title")}
          </SmallSectionTitle>
          <ContentText>
            {t("utils.milestones.18MonthTo4Year.3to4year.content")}
          </ContentText>
          <TipsContainer>
            <ContentText>
              {t("utils.milestones.18MonthTo4Year.3to4YearTips")}
            </ContentText>
          </TipsContainer>
        </AppendixContainer>
      </>
    );
  };

  getOver4Year = () => {
    const { t } = this.props;
    return (
      <>
        <SmallSectionTitle>
          {t("utils.milestones.over4Year.title")}
        </SmallSectionTitle>
        <AppendixContainer>
          <SmallSectionTitle>
            {t("utils.milestones.over4Year.4year.title")}
          </SmallSectionTitle>
          <ContentText>
            {t("utils.milestones.over4Year.4year.content")}
          </ContentText>
          <SmallSectionTitle>
            {t("utils.milestones.over4Year.5year.title")}
          </SmallSectionTitle>
          <ContentText>
            {t("utils.milestones.over4Year.5year.content")}
          </ContentText>
          <SmallSectionTitle>
            {t("utils.milestones.over4Year.6year.title")}
          </SmallSectionTitle>
          <ContentText>
            {t("utils.milestones.over4Year.6year.content")}
          </ContentText>
          <TipsContainer>
            <ContentText>{t("utils.milestones.over4Year.Tips")}</ContentText>
          </TipsContainer>
        </AppendixContainer>
      </>
    );
  };

  render() {
    const { t } = this.props;
    const zeroMonthToOneAndHalfYear = this.get0To18Month();
    const oneAndHalfToFourYear = this.get18MonthTo4Year();
    const overFourYear = this.getOver4Year();
    return (
      <>
        <A4Container>
          <SectionTitle>
            {t("user.survey.corePro.result.appendix1.title")}
          </SectionTitle>
          {zeroMonthToOneAndHalfYear}
          <PageNumber>12</PageNumber>
          <WaterMark src={getWatermark(this.props.language)} />
        </A4Container>
        <A4ContentContainer>
          {oneAndHalfToFourYear}
          <PageNumber>13</PageNumber>
          <WaterMark src={getWatermark(this.props.language)} />
        </A4ContentContainer>
        <A4ContentContainer>
          {overFourYear}
          <PageNumber>14</PageNumber>
          <WaterMark src={getWatermark(this.props.language)} />
        </A4ContentContainer>
      </>
    );
  }
}
export default withTranslation()(Appendix1);
