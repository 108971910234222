import styled from "styled-components";

export const StatusText = styled.span<{ status: string }>`
  font-weight: bold;
  color: ${({ status }) => status};
`;

export const ResultSummaryImg = styled.img`
  width: 24px;
  height: 24px;
`;
