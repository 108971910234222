import { QuestionContainer, QuestionText } from "../style";

const QuestionTitle = (props) => {
  return (
    <QuestionContainer>
      <QuestionText>{props.children}</QuestionText>
    </QuestionContainer>
  );
};

export default QuestionTitle;
