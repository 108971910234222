import { Icon } from "semantic-ui-react";
import styled from "styled-components";
import { Description, GeneralSegment, SmallContentTitle } from "styles";

export const CollapsibleSegment = styled(GeneralSegment)<{}>`
  cursor: pointer;
  margin-bottom: 15px;
  background: white;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
  }
`;

export const LockedContainer = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: #80808036;
`;

export const UnlockedContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const Header = styled(SmallContentTitle)<{ isOpen: boolean }>`
  max-height: fit-content;
  display: flex;
  justify-content: space-between;
  color: ${({ isOpen }) => (isOpen ? "#6898e3" : "black")};
`;

export const ContentContainer = styled.div<{ isOpen: boolean }>`
  padding: 20px 20px 0px 0px;
  cursor: default;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

export const Content = styled(Description)`
  padding: 0;
  margin: 0;
  height: max-content;
  box-sizing: border-box;
  text-align: left;
  white-space: pre-line;
`;

export const Arrow = styled(Icon)`
  align-self: center;
  ${(props: { isOpen: boolean }) =>
    props.isOpen
      ? "transform: rotate(180deg); transition: transform .2s ease-in-out"
      : "transform: rotate(360deg); transition: transform .2s ease-in-out"};
`;
