import { EVAL_STEPS } from "./common";

export const MODULARIZED_EVAL_PROGRESSBAR_OFFSET = 12;
export const BJCH_PROGRESSBAR_OFFSET = 12;
export const SELF_EVAL_PROGRESSBAR_OFFSET = 9;
export const EXPERT_EVAL_PROGRESSBAR_OFFSET = 7;
export const CHILD_INFO_ONLY_PROGRESSBAR_OFFSET = 3;
export const EXPERT_EVAL_NO_REPORT_PROGRESSBAR_OFFSET = 8;

const {
  kksIntro,
  basicIntro,
  childInfo,
  soundCollection,
  expression,
  comprehension,
  oral,
  social,
  preliteracy,
  questionnaire,
  report,
  video,
  expertAnalysis,
  consultation,
  localEvaluation,
} = EVAL_STEPS;

export const MODULARIZED_EVAL_STEPS = [
  kksIntro,
  childInfo,
  questionnaire,
  report,
];

export const BJCH_STEPS = [
  basicIntro,
  childInfo,
  questionnaire,
  localEvaluation,
];

export const SELF_EVAL_STEPS = [
  basicIntro,
  childInfo,
  questionnaire,
  soundCollection,
  report,
];
export const EXPERT_EVAL_STEPS = [
  basicIntro,
  childInfo,
  questionnaire,
  soundCollection,
  video,
  expertAnalysis,
  consultation,
];

export const EXPERT_EVAL_NO_REPORT_STEPS = [
  basicIntro,
  childInfo,
  questionnaire,
  soundCollection,
  video,
  report,
];

export const CHILD_INFO_ONLY_STEPS = [basicIntro, childInfo, report];

export const QUESTIONNAIRE_STEPS = [
  expression,
  comprehension,
  oral,
  social,
  preliteracy,
];

export const questionnaireIndexToStep = {
  1: expression,
  2: comprehension,
  3: oral,
  4: social,
  5: preliteracy,
};

export const questionnaireStepToIndex = {
  expression: 1,
  comprehension: 2,
  oral: 3,
  social: 4,
  preliteracy: 5,
};

export const expertStepIndex = {
  successful: 1,
  waiting: 2,
  reviewing: 3,
  finish: 4,
};
