import styled from "styled-components";
import Evaluation from "./assets/evaluation.svg";
import Recommendation from "./assets/recommendation.svg";
import Result from "./assets/result.svg";
import Guide from "./assets/guide.svg";
import { SectionTitle, Description, GeneralSegment } from "../../../styles";

// main container
export const SegmentContainer = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  margin: 30px 0 20px 0;
`;

// segment container
export const SectionContainer = styled(GeneralSegment)`
  cursor: pointer;
  height: 100%;
  width: 100%;
  min-width: 350px;
  min-height: 250px;
  background-size: 40%;
  background-position: 8% 85%;
  background-repeat: no-repeat;
  margin-bottom: 0px;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
    color: #6898e3;
  }
`;
export const RecommendationSegment = styled(SectionContainer)`
  background-image: url(${Recommendation});
`;
export const EvaluationSegment = styled(SectionContainer)`
  background-image: url(${Evaluation});
`;
export const ResultSegment = styled(SectionContainer)`
  background-image: url(${Result});
`;
export const GuideSegment = styled(SectionContainer)`
  background-image: url(${Guide});
  :hover {
    color: #5abaae;
  }
`;

// segment title
export const SegmentTitle = styled(SectionTitle)`
  margin-bottom: 5px;
`;

// segment description
export const SectionDes = styled(Description)``;

// right bottom button in every segment
export const GoToButton = styled.span`
  position: relative;
  display: flex;
  cursor: pointer;
  :: before {
    position: absolute;
    right: 20px;
    bottom: 20px;
    content: attr(data-duration);
    color: #6898e3;
  }
`;
export const GoToGuideButton = styled(GoToButton)`
  :: before {
    color: #5abaae;
  }
`;
