import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import { bindActionCreators } from "redux";
import { AppActions } from "types/actions";
import { ThunkDispatch } from "redux-thunk";
import { History, LocationState } from "history";
import { withTranslation, WithTranslation } from "react-i18next";
import { DropdownProps } from "semantic-ui-react";
import api_address from "../../../constants/config";
import PenguinSmart from "../assets/PenguinSmart.png";
import {
  JS_COOKIE,
  LOGIN_VALIDATION,
  LOGIN_SUBMIT,
  ADMIN_LOGIN_SUBMIT,
} from "../../../util/auth";
import {
  Title,
  InputArea,
  LoginForm,
  LoginButton,
  LoginContainer,
  Logo,
  Container,
  DivideLine,
  DivideContainer,
  LoginStatusButton,
  LinkContainer,
  PhoneInput,
  PhoneInputContainer,
  PhoneLoginContainer,
  EmailLoginContainer,
  CountryCodeDropdown,
} from "./styles";
import {
  startLoginSuccess,
  startLoginFail,
  startUpdateUserToken,
} from "../actions";
import { CountryCodeOptions } from "../../../util/CountryCodeOptions";
import { ROLE } from "../../../constants/common";
import NotificationModal from "../../common/NotificationModal";
import { ADMIN_COOKIE_NAME, ADMIN_COOKIE_ROLE } from "constants/admin";
import i18n from "i18n";

interface LoginProps {
  history: History<LocationState>;
}

interface LoginState {
  [key: string]: boolean | string;
  email: string;
  phone: string;
  password: string;
  errors: string;
  countryCode: string;
  isPhone: boolean;
  showModal: boolean;
  notificationStatus: boolean;
}

type Props = LoginProps & LinkDispatchProps & WithTranslation;

class AdminLogin extends React.Component<Props, LoginState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      phone: "",
      password: "",
      errors: "",
      countryCode: "",
      isPhone: false,
      showModal: false,
      notificationStatus: false,
    };
  }

  componentDidMount = async () => {
    const adminToken = Cookies.get(ADMIN_COOKIE_NAME);
    const adminRole = Cookies.get(ADMIN_COOKIE_ROLE);
    if (adminToken && adminRole) {
      this.props.onUpdateUserToken(adminToken);
      this.props.onLoginSuccess(adminRole);
      if (adminRole === ROLE.admin) {
        this.props.history.push("/admin/user");
      } else if (adminRole === ROLE.translator) {
        this.props.history.push("/admin/i18n");
      }
    }
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    this.setState({ [id]: value });
  };

  handleCountryCodeChange = (data: any) => {
    if (data.value != null)
      this.setState({
        countryCode: data.value,
      });
  };

  setIsPhone = () => {
    this.setState((states) => ({
      isPhone: !states.isPhone,
    }));
  };

  displayErrorMessage = (message: string) => {
    this.setState({
      errors: message,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  onUserLogin = async () => {
    try {
      const { phone, email, countryCode, password, isPhone } = this.state;
      const userName = isPhone ? phone : email;
      const userData = {
        userName,
        phone,
        email,
        password,
        countryCode,
        isPhone,
      };
      LOGIN_VALIDATION(userData);
      ADMIN_LOGIN_SUBMIT(userData);
      axios
        .post(api_address + "api/adminUser/login", userData)
        .then((res) => {
          const { status, token, role } = res.data;
          if (!status) {
            throw new Error(i18n.t("message.account.loginError"));
          }
          JS_COOKIE.set(ADMIN_COOKIE_NAME, token);
          JS_COOKIE.set(ADMIN_COOKIE_ROLE, role);
          this.props.onUpdateUserToken(token);
          this.props.onLoginSuccess(role);
          switch (role) {
            case ROLE.translator:
              this.props.history.push("/admin/i18n");
              break;
            default:
              this.props.history.push("/admin/user");
          }
        })
        .catch((error) => {
          this.displayErrorMessage(error.message);
        });
    } catch (error: any) {
      this.displayErrorMessage(error.message);
    }
  };

  phoneLoginForm = (): JSX.Element => {
    const { t } = this.props;
    const { phone, countryCode } = this.state;
    return (
      <PhoneLoginContainer>
        <Title>{t("auth.login.phone")}</Title>
        <PhoneInputContainer>
          <CountryCodeDropdown
            style={{ width: "40%", height: "31px", marginTop: "16px" }}
            fluid
            search
            selection
            text={countryCode}
            options={CountryCodeOptions()}
            onChange={(data: DropdownProps) => {
              this.handleCountryCodeChange(data);
            }}
          />
          <PhoneInput
            onChange={this.handleInputChange}
            id="phone"
            type="text"
            value={phone}
          />
        </PhoneInputContainer>
      </PhoneLoginContainer>
    );
  };

  emailLoginForm = (): JSX.Element => {
    const { t } = this.props;
    const { handleInputChange } = this;
    const { email } = this.state;
    return (
      <EmailLoginContainer>
        <Title>{t("auth.login.email")}</Title>
        <InputArea
          onChange={handleInputChange}
          id="email"
          type="text"
          value={email}
        />
      </EmailLoginContainer>
    );
  };

  divideLine = (): JSX.Element => {
    return (
      <DivideContainer>
        <DivideLine />
        <div>or</div>
        <DivideLine />
      </DivideContainer>
    );
  };

  loginStatusButton = (): JSX.Element => {
    const { t } = this.props;
    const { isPhone } = this.state;
    const status = isPhone ? t("auth.login.byEmail") : t("auth.login.byPhone");
    return (
      <LoginStatusButton onClick={this.setIsPhone}>{status}</LoginStatusButton>
    );
  };

  render = () => {
    window.scrollTo(0, 0);
    const { t } = this.props;
    const { isPhone, password, showModal, errors, notificationStatus } =
      this.state;
    return (
      <Container>
        <NotificationModal
          show={showModal}
          message={errors}
          status={notificationStatus}
        />
        <LoginContainer>
          <Logo src={PenguinSmart} alt="" />
          <LoginForm
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              this.onUserLogin();
            }}
          >
            {isPhone ? this.phoneLoginForm() : this.emailLoginForm()}
            <Title>{t("auth.login.password")}</Title>
            <InputArea
              onChange={this.handleInputChange}
              id="password"
              type="password"
              value={password}
            />
          </LoginForm>
          <LinkContainer>
            <Link to="/forgot">{t("auth.login.forgotPassword")}</Link>
          </LinkContainer>
          <LoginButton onClick={this.onUserLogin}>
            {t("auth.login.login")}
          </LoginButton>
          {this.divideLine()}
          {this.loginStatusButton()}
        </LoginContainer>
      </Container>
    );
  };
}

// Satisfies return values of mapDispatchToProps
interface LinkDispatchProps {
  onLoginSuccess: (data: string) => void;
  onLoginFail: () => void;
  onUpdateUserToken: (data: string) => void;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: LoginProps
): LinkDispatchProps => ({
  onLoginSuccess: bindActionCreators(startLoginSuccess, dispatch),
  onLoginFail: bindActionCreators(startLoginFail, dispatch),
  onUpdateUserToken: bindActionCreators(startUpdateUserToken, dispatch),
});

export default withRouter(
  connect(null, mapDispatchToProps)(withTranslation()(AdminLogin))
);
