import React from "react";

interface WeChatPayFormProps {
  data: string;
}

interface WeChatPayFormState {}

class WeChatPayForm extends React.Component<
  WeChatPayFormProps,
  WeChatPayFormState
> {
  componentDidMount() {
    document.forms[0].submit();
  }
  render() {
    const pattern = /<form.+form>/;
    const { data } = this.props;
    const extract = pattern.exec(data);
    const form = extract ? extract[0] : "";
    return <div dangerouslySetInnerHTML={{ __html: form }} />;
  }
}

export default WeChatPayForm;
