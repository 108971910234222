import styled from "styled-components";

export const Container = styled.div`
  overflow: scroll;
  text-align: left;
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  @media only screen and (max-width: 575px) {
    padding: 30px 2% 20px 2%;
  }
  @media only screen and (min-width: 576px) {
    padding: 30px 2% 20px 2%;
  }
  @media only screen and (min-width: 768px) {
    padding: 30px 0;
  }
  @media only screen and (min-width: 992px) {
    padding: 30px 7% 30px 7%;
  }
  @media only screen and (min-width: 1200px) {
    padding: 30px 10% 30px 10%;
  }
`;

export const ProgressBarContainer = styled.div`
  @media only screen and (max-width: 575px) {
    width: 700px;
  }
  @media only screen and (min-width: 576px) {
    width: 800px;
  }
  @media only screen and (min-width: 768px) {
    width: 800px;
  }
  @media only screen and (min-width: 992px) {
    width: 100%;
  }
`;

export const ProgressContainer = styled.div`
  border-radius: 15px;
  height: 20px;
  background: white;
`;

export const StepContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;
export const TimeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const StepText = styled.div<{ completed: boolean }>`
  width: 20%;
  line-height: 25px;
  text-align: center;
  cursor: default;
  color: ${(props) => (props.completed ? "#6898E3" : "#8f8f8f")};
  margin-top: 4px;
  font-size: 11px;
  @media only screen and (min-width: 768px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 14px;
  }
`;
export const TimeText = styled.div`
  background: white;
  width: max-content;
  padding: 0px 8px;
  border-radius: 10px;
  color: #8f8f8f;
  cursor: default;
  @media only screen and (max-width: 575px) {
    font-size: 9px;
  }
  @media only screen and (min-width: 576px) {
    font-size: 9px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 10px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 10px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 12px;
  }
`;
export const TimeTextContainer = styled.div`
  width: 20%;
  text-align: -webkit-center;
`;

export const BarLine = styled.div`
  border-radius: 15px;
  height: 20px;
  background: #6898e3;
  width: ${(props: { barWidth: string }) => props.barWidth};
  display: flex;
`;

export const CircleContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const StepCircle = styled.div`
  display: inline-flex;
  width: 16px;
  height: 16px;
  background-color: ${(props: { completed: boolean }) =>
    props.completed ? "#6898E3" : "#9d9d9d"};
  border-radius: 15px;
  // margin-top: -9px;
`;

export const QuestionnaireStepContainer = styled.div<{
  index: number;
  isFirst: boolean;
}>`
  z-index: ${(props) => props.index};
  margin-left: ${(props) => (props.isFirst ? "0px" : "-400px")};
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const StepImage = styled.img``;

export const QuestionnaireProgressBarContainer = styled.div`
  text-align: center;
  width: 90%;
  padding-left: 10%;
  display: flex;
  justify-content: space-around;
  overflow: scroll;
`;

export const QuestionnaireStepText = styled.div`
  margin-top: -42px;
  z-index: 10;
  font-weight: bold;
  color: ${(props: { completed: boolean }) =>
    props.completed ? "#6898E3" : "#ffffff"};
`;
