export const ACTIVITY_INDEX = {
  count: 3,
  typeCount: 4,
};

export const INDEX_TO_TYPE = {
  1: "comprehension",
  2: "expression",
  3: "social",
  4: "oral",
};
