import { CURRENCY } from "../constants/common";

export const CURRENCY_LIST = [CURRENCY.cny, CURRENCY.usd, CURRENCY.twd];

export const CURRENCY_OPTIONS = CURRENCY_LIST.map((currency: string) => {
  return {
    text: currency,
    value: currency,
  };
});

export const CURRENCY_OPTIONS_COUPON = [
  { value: CURRENCY.cny, label: CURRENCY.cny },
  { value: CURRENCY.usd, label: CURRENCY.usd },
  { value: CURRENCY.twd, label: CURRENCY.twd },
];

export const PAYMENT_STATUS = ["pending", "canceled", "success"];

export const PAYMENT_METHOD = ["stripe", "wechatpay", "alipay", "coupon"];
