import React, { useEffect } from "react";
import {
  TextareaContainer,
  TextareaFlexContainer,
  Textarea,
  TextareaBorder,
} from "../style";

interface Props {
  _ref: any;
  id: string;
  placeholder: string;
  name: string;
  value: string | number | readonly string[] | undefined;
  handleInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  isEmpty: boolean;
}
const TextArea = ({
  _ref,
  id,
  placeholder,
  name,
  value,
  handleInputChange,
  isEmpty,
}: Props) => {
  useEffect(() => {
    if (_ref && _ref.current) {
      _ref.current.style.height = "0px";
      const scrollHeight = _ref.current.scrollHeight;
      _ref.current.style.height = scrollHeight + "px";
    }
  }, [_ref, value]);

  return (
    <TextareaContainer>
      <TextareaFlexContainer>
        <Textarea
          ref={_ref}
          id={id}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={handleInputChange}
          isEmpty={isEmpty}
        />
        <TextareaBorder isEmpty={value === ""}></TextareaBorder>
      </TextareaFlexContainer>
    </TextareaContainer>
  );
};

export default TextArea;
