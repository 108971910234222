import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { QUESTIONNAIRE_STEPS } from "../../../../../../constants/questionnaire";
import { EVAL_STEPS } from "../../../../../../constants/common";
import comprehensionImg from "../assets/comprehension.png";
import expressionImg from "../assets/expression.png";
import oralImg from "../assets/oral.png";
import socialImg from "../assets/social.png";
import preLiteracyImg from "../assets/preliteracy.png";
import DefinitionModal from "../../../../../common/DefinitionModal";
import { GET_STATUS_BY_COLOR } from "../../../../../../util/result";
import { AnalysisResultContainer, CategoryContent } from "./styles";
import {
  FlexContainer,
  SummaryCheckInCount,
  SummaryContainer,
  SummaryImage,
  SummaryMainSkill,
  SummaryName,
} from "../styles";
import {
  List,
  LoadingContainer,
  LoadingContainerReport,
  LoadingText,
  LoadingTextReport,
  OptionText,
  Title,
  UnOrderList,
} from "components/User/Evaluation/styles";
import CollapsibleContent from "components/common/CollapsibleContent";
import { LockModal } from "../Utils";
import { t } from "i18next";

type Props = {
  resultData: Object;
  questionnaire: any;
};

const CategoryIcon = (props: { category: string }): JSX.Element => {
  const { category } = props;
  switch (category) {
    case EVAL_STEPS.expression:
      return <SummaryImage src={expressionImg} />;
    case EVAL_STEPS.comprehension:
      return <SummaryImage src={comprehensionImg} />;
    case EVAL_STEPS.oral:
      return <SummaryImage src={oralImg} />;
    case EVAL_STEPS.social:
      return <SummaryImage src={socialImg} />;
    case EVAL_STEPS.preliteracy:
      return <SummaryImage src={preLiteracyImg} />;
    default:
      return <SummaryImage src={expressionImg} />;
  }
};

const CategoryHeaderContent = (props: {
  resultData: Object;
  category: string;
  questionnaire: any;
}) => {
  const { category, questionnaire } = props;
  const hasData = questionnaire[category];
  return hasData ? <CategoryHeader {...props} /> : <PartialCategoryHeader />;
};

const CategoryBodyContent = (props: {
  resultData: Object;
  category: string;
  questionnaire: any;
}) => {
  const { category, questionnaire } = props;
  const hasData = questionnaire[category];
  return hasData ? <CategoryBody {...props} /> : <PartialCategoryHeader />;
};

const CategoryHeader = (props: {
  resultData: Object;
  category: string;
}): JSX.Element => {
  const { resultData, category } = props;
  const { t } = useTranslation();
  const { rawResult, answerResult, analysisResult } = resultData[category];
  const statusColor = GET_STATUS_BY_COLOR(rawResult);
  return (
    <>
      <SummaryMainSkill backgroundColor={statusColor}>
        {analysisResult}
      </SummaryMainSkill>
      <SummaryName>{t(`utils.fiveCategories.${category}.title`)}</SummaryName>
      <SummaryCheckInCount>
        {t("user.survey.core.result.initialAnalysis.score")}
        {": "}
        {answerResult}
      </SummaryCheckInCount>
    </>
  );
};

const CategoryBody = (props: {
  category: string;
  resultData: Object;
}): JSX.Element => {
  const { resultData, category } = props;
  const { development, developmentPoint } = resultData[category];
  const { t } = useTranslation();
  return (
    <div>
      <CategoryContent>
        {t("user.survey.core.result.initialAnalysis.surveyResult")}
      </CategoryContent>
      <OptionText>{development}</OptionText>
      <CategoryContent>
        {t("user.survey.core.result.initialAnalysis.developPoint")}
      </CategoryContent>
      <OptionText>{developmentPoint}</OptionText>
      <CategoryContent>
        {t("user.survey.core.result.definition")}
      </CategoryContent>
      <OptionText>{t(`utils.fiveCategories.${category}.content`)}</OptionText>
      {category === "expression" && (
        <UnOrderList>
          <List>{t(`utils.fiveCategories.${category}.content-1`)}</List>
          <List>{t(`utils.fiveCategories.${category}.content-2`)}</List>
          <List>{t(`utils.fiveCategories.${category}.content-3`)}</List>
        </UnOrderList>
      )}
    </div>
  );
};

const PartialCategoryHeader = (): JSX.Element => {
  return (
    <LoadingContainerReport>
      <LoadingTextReport>
        {t("user.survey.core.result.loading")}
      </LoadingTextReport>
    </LoadingContainerReport>
  );
};

const CategorySegment = (props: {
  resultData: Object;
  category: string;
  questionnaire: any;
}): JSX.Element => {
  return (
    <CollapsibleContent
      header={
        <>
          <FlexContainer>
            <CategoryIcon {...props} />
            <SummaryContainer>
              <CategoryHeaderContent {...props} />
            </SummaryContainer>
          </FlexContainer>
        </>
      }
      content={<CategoryBodyContent {...props} />}
    />
  );
};

const AnalysisResult = (props: Props) => {
  const { t } = useTranslation();
  const { questionnaire } = props;
  const [definitionType, setDefinitionType] = useState("");
  const [showDefinitionModal, setShowDefinitionModal] = useState(false);

  useEffect(() => {
    let firstComponent = "";
    QUESTIONNAIRE_STEPS.some((step: string) => {
      firstComponent = step;
      return questionnaire[step] != null;
    });
    setDefinitionType(firstComponent);
  }, [questionnaire]);

  return (
    <>
      <DefinitionModal
        show={showDefinitionModal}
        type={definitionType}
        handleDefinitionClose={() => setShowDefinitionModal(false)}
      />
      <Title
        style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}
      >
        {t("user.survey.core.result.analysis")}
      </Title>
      <CategorySegment category="expression" {...props} />
      <CategorySegment category="comprehension" {...props} />
      <CategorySegment category="oral" {...props} />
      <CategorySegment category="social" {...props} />
      <CategorySegment category="preliteracy" {...props} />
    </>
  );
};

export default AnalysisResult;
