import { useTranslation } from "react-i18next";
import { ContactUsTitle, Container, FAQContainer } from "../styles";
export const CompleteMessage = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <FAQContainer>
        <ContactUsTitle>{t("general.contactUs.success1")}</ContactUsTitle>
        <ContactUsTitle>{t("general.contactUs.success2")}</ContactUsTitle>
      </FAQContainer>
    </Container>
  );
};
