import styled from "styled-components";

export const ServicesContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    width: 1px;
    background: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    width: 1px;
    position: relative;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0);
  }
`;
