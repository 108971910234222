import { Title } from "components/User/Evaluation/styles";
import { withTranslation, WithTranslation } from "react-i18next";
import { NextButton } from "styles";
import finish from "../../../assets/finish-2x.png";
import { StateImage } from "../../styles";
type Props = {
  handleGoToReport: () => void;
} & WithTranslation;

function ExpertReport(props: Props) {
  const { t } = props;
  return (
    <div>
      <StateImage src={finish} alt="" />
      <Title>{t("user.survey.corePro.waiting.report")}</Title>
      <NextButton status={true} onClick={props.handleGoToReport}>
        {t("user.survey.corePro.waiting.download")}
      </NextButton>
    </div>
  );
}

export default withTranslation()(ExpertReport);
