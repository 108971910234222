import styled from "styled-components";
import ThankyouImg from "./assets/thankyou.png";
import ThankyouMobileImg from "./assets/thankyoumobile.png";

export const ContentThankyouImg = styled.div`
  background-image: url(${ThankyouImg});
  margin: 0 5%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 15px;
  position: relative;
  @media screen and (min-width: 801px) and (max-width: 1024px) {
    background-image: url(${ThankyouMobileImg});
  }

  @media screen and (min-width: 601px) and (max-width: 800px) {
    background-image: url(${ThankyouMobileImg});
  }

  @media screen and (max-width: 600px) {
    background-image: url(${ThankyouMobileImg});
  }
`;

export const ScoreButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 0 0;
`;

export const ScoreButton = styled.div<{ highlight: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  border: ${({ highlight }) =>
    highlight ? "solid 2px #6898e3" : "solid 1px #e0e0e0"};
  font-weight: ${({ highlight }) => (highlight ? "bold" : "none")};
  color: ${({ highlight }) => (highlight ? "#6898e3" : "black")};
  :hover {
    color: #6898e3;
    font-weight: bold;
    border: solid 2px #6898e3;
  }
  @media only screen and (max-width: 575px) {
    height: 27px;
    width: 27px;
  }
  @media only screen and (min-width: 576px) {
    height: 27px;
    width: 27px;
  }
  @media only screen and (min-width: 768px) {
    height: 30px;
    width: 30px;
  }
  @media only screen and (min-width: 992px) {
    height: 30px;
    width: 30px;
  }
  @media only screen and (min-width: 1200px) {
    height: 35px;
    width: 35px;
  }
`;

export const TextArea = styled.textarea`
  height: 100px;
  width: 100%;
  margin-top: 5px;
  padding: 10px;
  resize: none;
  overflow: auto;
  border-radius: 8px;
  border-color: #e0e0e0;
  background-color: white;
  :focus {
    border-radius: 8px;
    outline: none;
    border: solid 1px #6898e3;
  }
`;
