import { Component } from "react";
import { withRouter, RouteComponentProps, match } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import { History, LocationState } from "history";
import { Background } from "../../User/Evaluation/styles";
import BJCHEnd from "./BJCH";
import { ORGANIZATION } from "../../../constants/common";

interface EndState {}

interface Match {
  organization: string;
}

interface EndProps {
  history: History<LocationState>;
  match: match<Match>;
}

type Props = EndProps & WithTranslation & RouteComponentProps;

class End extends Component<Props, EndState> {
  getEndComponentByOrganization = () => {
    const { organization } = this.props.match.params;
    switch (organization) {
      case ORGANIZATION.BJCH:
        return <BJCHEnd />;
      default:
        return <div />;
    }
  };

  render() {
    return <Background>{this.getEndComponentByOrganization()}</Background>;
  }
}

export default withRouter(withTranslation()(End));
