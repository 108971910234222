import {
  TopNavBar,
  TopBarMenuContainer,
  TopNavbarCompanyLogo,
  // LanguageContainer,
  ContactButton,
  ContactImg,
  TopProfileIcon,
  TopContactIcon,
  TopHomeIcon,
} from "../styles";
import { DESKTOP_LOGO, GET_LANGUAGE } from "../../../../util/common";
import BlueContact from "../assets/BlueContact.svg";
import WhiteContact from "../assets/WhiteContact.svg";
import WhiteProfile from "../assets/WhiteProfile.svg";
import WhiteHome from "../assets/WhiteHome.svg";
// import LanguageDropdown from "components/general/LanguageDropdown";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { ActionTitle } from "styles";
import { ROLE } from "constants/common";

type Props = {
  role: string;
};

export default function TopBar({ role }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const language = GET_LANGUAGE();
  const Logo = DESKTOP_LOGO(language);

  const onContactClick = () => {
    history.push("/contactUs/Issues");
  };

  const onProfileClick = () => {
    mixpanel.track("NavBar", {
      option: "profile",
    });
    history.push("/profile");
  };

  const handleClickHome = () => {
    if (role === ROLE.therapist) {
      return history.push("/therapist");
    }
    return history.push("/dashboard");
  };

  return (
    <TopNavBar status={false}>
      <TopBarMenuContainer>
        <TopNavbarCompanyLogo
          status={false}
          src={Logo}
          onClick={handleClickHome}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* {!isLoggedIn && (
            <LanguageContainer>
              <LanguageDropdown path="NavBar" color={"white"} />
            </LanguageContainer>
          )} */}
          <ContactButton onClick={onContactClick}>
            <ContactImg src={BlueContact} />
            <ActionTitle>{t("general.navbar.contact")}</ActionTitle>
          </ContactButton>
          <TopHomeIcon src={WhiteHome} onClick={handleClickHome} />
          <TopContactIcon src={WhiteContact} onClick={onContactClick} />
          <TopProfileIcon src={WhiteProfile} onClick={onProfileClick} />
        </div>
      </TopBarMenuContainer>
    </TopNavBar>
  );
}
