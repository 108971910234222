import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { History, LocationState } from "history";
import { Icon } from "semantic-ui-react";
import { MilestonesContainer, QuestionContainer } from "./styles";
import CollapsibleContent from "../../common/CollapsibleContent";
import { RouteSectionContainer, SectionTitle } from "styles";
import {
  HeaderContainer,
  HeaderTitle,
} from "components/User/ProfileRoute/styles";
import { List, UnOrderList } from "components/User/Evaluation/styles";
import { GET_TITLE_BAR } from "util/common";

interface FAQProps {
  history: History<LocationState>;
}

interface FAQState {}

type Props = FAQProps & WithTranslation;

class FAQ extends Component<Props, FAQState> {
  componentDidMount = () => {
    const { t } = this.props;
    document.title = GET_TITLE_BAR(t, "FAQ", true);
  };
  render() {
    const { t } = this.props;
    return (
      <RouteSectionContainer>
        <Icon
          className="chevron left"
          size="large"
          link
          circular
          onClick={() => this.props.history.push("/profile")}
        />
        <HeaderContainer>
          <HeaderTitle>{t("utils.FAQ.title")}</HeaderTitle>
        </HeaderContainer>
        <div style={{ padding: "0 8px" }}>
          <QuestionContainer>
            <SectionTitle>{t("utils.fiveCategories.title")}</SectionTitle>
            <CollapsibleContent
              header={<>{t("utils.fiveCategories.expression.title")}</>}
              content={
                <>
                  <>{t("utils.fiveCategories.expression.content")}</>
                  <UnOrderList>
                    <List>
                      {t(`utils.fiveCategories.expression.content-1`)}
                    </List>
                    <List>
                      {t(`utils.fiveCategories.expression.content-2`)}
                    </List>
                    <List>
                      {t(`utils.fiveCategories.expression.content-3`)}
                    </List>
                  </UnOrderList>
                </>
              }
            />
            <CollapsibleContent
              header={<>{t("utils.fiveCategories.comprehension.title")}</>}
              content={<>{t("utils.fiveCategories.comprehension.content")}</>}
            />
            <CollapsibleContent
              header={<>{t("utils.fiveCategories.social.title")}</>}
              content={<>{t("utils.fiveCategories.social.content")}</>}
            />
            <CollapsibleContent
              header={<>{t("utils.fiveCategories.preliteracy.title")}</>}
              content={<>{t("utils.fiveCategories.preliteracy.content")}</>}
            />
            <CollapsibleContent
              header={<>{t("utils.fiveCategories.oral.title")}</>}
              content={<>{t("utils.fiveCategories.oral.content")}</>}
            />
          </QuestionContainer>
          <QuestionContainer>
            <SectionTitle>{t("utils.milestones.title")}</SectionTitle>
            <CollapsibleContent
              header={<>{t("utils.milestones.0to18Month.title")}</>}
              content={
                <>
                  <MilestonesContainer>
                    {t("utils.milestones.0to18Month.before3Month.title")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.0to18Month.before3Month.content")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.0to18Month.3monthTips")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.0to18Month.4to6Month.title")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.0to18Month.4to6Month.content")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.0to18Month.7to9Month.title")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.0to18Month.7to9Month.content")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.0to18Month.7monthTips")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.0to18Month.10to12Month.title")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.0to18Month.10to12Month.content")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.0to18Month.1yearTips")}
                  </MilestonesContainer>
                </>
              }
            />
            <CollapsibleContent
              header={<>{t("utils.milestones.18MonthTo4Year.title")}</>}
              content={
                <>
                  <MilestonesContainer>
                    {t("utils.milestones.18MonthTo4Year.18monthTo2Year.title")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t(
                      "utils.milestones.18MonthTo4Year.18monthTo2Year.content"
                    )}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.18MonthTo4Year.2to3year.title")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.18MonthTo4Year.2to3year.content")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.18MonthTo4Year.2to3YearTips")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.18MonthTo4Year.3to4year.title")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.18MonthTo4Year.3to4year.content")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.18MonthTo4Year.3to4YearTips")}
                  </MilestonesContainer>
                </>
              }
            />
            <CollapsibleContent
              header={<>{t("utils.milestones.over4Year.title")}</>}
              content={
                <>
                  <MilestonesContainer>
                    {t("utils.milestones.over4Year.4year.title")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.over4Year.4year.content")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.over4Year.5year.title")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.over4Year.5year.content")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.over4Year.6year.title")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.over4Year.6year.content")}
                  </MilestonesContainer>
                  <MilestonesContainer>
                    {t("utils.milestones.over4Year.Tips")}
                  </MilestonesContainer>
                </>
              }
            />
          </QuestionContainer>
          <QuestionContainer>
            <SectionTitle>{t("utils.FAQ.core.title")}</SectionTitle>
            {/* <CollapsibleContent
              header={<>{t("utils.FAQ.core.question1")}</>}
              content={<>{t("utils.FAQ.core.question1Content")}</>}
            /> */}
            <CollapsibleContent
              header={<>{t("utils.FAQ.core.question2")}</>}
              content={<>{t("utils.FAQ.core.question2Content")}</>}
            />
            <CollapsibleContent
              header={<>{t("utils.FAQ.core.question3")}</>}
              content={<>{t("utils.FAQ.core.question3Content")}</>}
            />
          </QuestionContainer>
          <QuestionContainer>
            <SectionTitle>{t("utils.FAQ.corePro.title")}</SectionTitle>
            {/* <CollapsibleContent
              header={<>{t("utils.FAQ.corePro.question1")}</>}
              content={<>{t("utils.FAQ.corePro.question1Content")}</>}
            /> */}
            <CollapsibleContent
              header={<>{t("utils.FAQ.corePro.question2")}</>}
              content={<>{t("utils.FAQ.corePro.question2Content")}</>}
            />
            <CollapsibleContent
              header={<>{t("utils.FAQ.corePro.question3")}</>}
              content={<>{t("utils.FAQ.corePro.question3Content")}</>}
            />

            <CollapsibleContent
              header={<>{t("utils.FAQ.corePro.question4")}</>}
              content={<>{t("utils.FAQ.corePro.question4Content")}</>}
            />
            <CollapsibleContent
              header={<>{t("utils.FAQ.corePro.question5")}</>}
              content={<>{t("utils.FAQ.corePro.question5Content")}</>}
            />
            <CollapsibleContent
              header={<>{t("utils.FAQ.corePro.question6")}</>}
              content={<>{t("utils.FAQ.corePro.question6Content")}</>}
            />
            {/* <CollapsibleContent
              header={<>{t("utils.FAQ.corePro.question7")}</>}
              content={<>{t("utils.FAQ.corePro.question7Content")}</>}
            /> */}
          </QuestionContainer>
          <QuestionContainer>
            <SectionTitle>{t("utils.FAQ.others.title")}</SectionTitle>
            <CollapsibleContent
              header={<>{t("utils.FAQ.others.question1")}</>}
              content={<>{t("utils.FAQ.others.question1Content")}</>}
            />
          </QuestionContainer>
        </div>
      </RouteSectionContainer>
    );
  }
}
export default withTranslation()(FAQ);
