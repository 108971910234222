import { createSelector } from "reselect";
import { AppState } from "storeConfig";

const selectUserStatusList = (state: AppState) => state.get("auth");

const makeIsUserLoggedIn = () =>
  createSelector(selectUserStatusList, (selectUserStatusList) =>
    selectUserStatusList.get("loggedIn")
  );

const makeSelectRole = () =>
  createSelector(selectUserStatusList, (selectUserStatusList) =>
    selectUserStatusList.get("role")
  );

const makeSelectToken = () =>
  createSelector(selectUserStatusList, (selectUserStatusList) =>
    selectUserStatusList.get("token")
  );

const makeSelectLanguage = () =>
  createSelector(selectUserStatusList, (selectUserStatusList) =>
    selectUserStatusList.get("language")
  );

const makeUpdateEvaluationStatus = () =>
  createSelector(selectUserStatusList, (selectUserStatusList) =>
    selectUserStatusList.get("evaluation")
  );

export {
  makeIsUserLoggedIn,
  makeSelectRole,
  makeSelectToken,
  makeSelectLanguage,
  makeUpdateEvaluationStatus,
};
