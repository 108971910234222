import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  z-index: 999;
  top: 0%;
  left:0%;
  width 100%;
  height: 100%;
  display: ${(props: { show: boolean }) => (props.show ? "grid" : "none")};
  place-items: center;
  background: rgba(0, 0, 0, 0.95);
`;

export const ModalContent = styled.div`
  border-radius: 15px;
  background-color: white;
  padding: 30px;
  width: 50%;
  height: 70%;
  overflow: auto;
`;

export const Header = styled.div`
  width: 100%;
  font-size: 25px;
  margin-bottom: 10px;
`;

export const CloseButton = styled.div`
  color: black;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  border: black solid 1px;
  height: 40px;
  width: 180px;
  border-radius: 4px;
  text-align: center;
  display: grid;
  place-items: center;
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  margin-top: 20px;
  font-size: 18px;
  line-height: 24px;
  min-height: 50px;
`;

export const CopyButton = styled.div`
  right: 10px;
  bottom: 15px;
  position: absolute;
  background: pink;
  border: solid 1px black;
  width: 50px;
  height: 30px;
  display: grid;
  place-items: center;
  border-radius: 4px;
  cursor: pointer;
`;

export const TextArea = styled.textarea`
  resize: none;
  width: 100%;
  height: 100px;
`;
