import React from "react";
import { useTranslation } from "react-i18next";
import { YesNoRadioLabel, RadioInput, OptionText } from "../../../../styles";

interface RadioProp {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  index: number;
  isChecked: boolean;
  dateForIndex: number;
}

const Radio = ({
  handleChange,
  type,
  index,
  isChecked,
  dateForIndex,
}: RadioProp) => {
  const { t } = useTranslation();
  const isYes = type === "yes";
  const value = isYes ? 0 : 1;
  return (
    <YesNoRadioLabel checked={isChecked}>
      <RadioInput
        id={(dateForIndex + index).toString()}
        name={index.toString()}
        type="radio"
        value={value}
        checked={isChecked}
        onChange={handleChange}
      />
      <OptionText>
        {t(`user.survey.core.questionnaire.button.${type}`)}
      </OptionText>
    </YesNoRadioLabel>
  );
};

export default React.memo(Radio);
