import styled from "styled-components";
import alipayColor from "./assets/alipayColor.svg";
import alipayGrey from "./assets/alipayGrey.svg";
import wechatpayColor from "./assets/wechatpayColor.svg";
import wechatpayGrey from "./assets/wechatpayGrey.svg";
import creditCardColor from "./assets/creditCardColor.svg";
import creditCardGrey from "./assets/creditCardGrey.svg";

// container
export const Container = styled.div`
  background-color: white;
  text-align: -webkit-center;
  min-height: 800px;
  @media only screen and (max-width: 575px) {
    padding: 0;
  }
  @media only screen and (min-width: 576px) {
    padding: 0;
  }
  @media only screen and (min-width: 768px) {
    padding: 30px 15%;
  }
  @media only screen and (min-width: 992px) {
    padding: 30px 5%;
  }
  @media only screen and (min-width: 1200px) {
    padding: 30px 5%;
  }
`;
export const PaymentContainer = styled.div`
  @media only screen and (max-width: 575px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  @media only screen and (min-width: 576px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  @media only screen and (min-width: 992px) {
    display: flex;
    flex-direction: inherit;
    justify-content: space-between;
    align-items: inherit;
  }
  @media only screen and (min-width: 1200px) {
    display: flex;
    flex-direction: inherit;
    justify-content: space-between;
    align-items: inherit;
  }
`;
export const SummaryContainer = styled.div`
  background-color: white;
  @media only screen and (max-width: 575px) {
    width: 100%;
    margin-bottom: 20px;
    padding: 5%;
    display: none;
  }
  @media only screen and (min-width: 576px) {
    width: 100%;
    margin-bottom: 20px;
    padding: 5%;
    display: none;
  }
  @media only screen and (min-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
    padding: 5%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    display: block;
  }
  @media only screen and (min-width: 992px) {
    width: 40%;
    margin-bottom: 0px;
    padding: 2%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    display: block;
  }
  @media only screen and (min-width: 1200px) {
    width: 40%;
    margin-bottom: 0px;
    padding: 2%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    display: block;
  }
`;
export const MobileSummaryContainer = styled.div`
  background-color: white;
  @media only screen and (max-width: 575px) {
    width: 100%;
    margin: 50px 0;
  }
  @media only screen and (min-width: 576px) {
    width: 100%;
    margin: 50px 0;
  }
  @media only screen and (min-width: 768px) {
    display: none;
  }
  @media only screen and (min-width: 992px) {
    display: none;
  }
  @media only screen and (min-width: 1200px) {
    display: none;
  }
`;
export const CheckoutContainer = styled.div`
  background-color: white;
  height: max-content;
  @media only screen and (max-width: 575px) {
    width: 100%;
    padding: 0 5%;
  }
  @media only screen and (min-width: 576px) {
    width: 100%;
    padding: 0 5%;
  }
  @media only screen and (min-width: 768px) {
    width: 100%;
    padding: 5%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    border-radius: 8px;
  }
  @media only screen and (min-width: 992px) {
    width: 55%;
    padding: 2%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    border-radius: 8px;
  }
  @media only screen and (min-width: 1200px) {
    width: 55%;
    padding: 2%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    border-radius: 8px;
  }
`;
export const ApplyCouponContainer = styled.div`
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
  margin-bottom: 35px;
`;
export const ApplyButtonContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  margin-right: 6px;
  @media only screen and (max-width: 575px) {
    width: 26%;
  }
  @media only screen and (min-width: 576px) {
    width: 24%;
  }
  @media only screen and (min-width: 768px) {
    width: 16%;
  }
  @media only screen and (min-width: 992px) {
    width: 10%;
  }
  @media only screen and (min-width: 1200px) {
    width: 12%;
  }
`;
export const PaymentMethodContainer = styled.div`
  height: max-content;
  display: flex;
  justify-content: space-around;
`;

export const CreditCardContainer = styled.div`
  border-radius: 4px;
  content: url(${(props: { status: string }) =>
    props.status === "stripe" ? creditCardColor : creditCardGrey});
  width: 31%;
  padding: 2px;
  :hover {
    cursor: pointer;
    content: url(${creditCardColor});
  }
  @media only screen and (max-width: 575px) {
    height: 45px;
  }
  @media only screen and (min-width: 576px) {
    height: 50px;
  }
  @media only screen and (min-width: 768px) {
    height: 55px;
  }
  @media only screen and (min-width: 992px) {
    height: 60px;
  }
  @media only screen and (min-width: 1200px) {
    height: 60px;
  }
`;
export const WechatPayContainer = styled.div`
  border-radius: 4px;
  content: url(${(props: { status: string }) =>
    props.status === "wechatpay" ? wechatpayColor : wechatpayGrey});
  height: 60px;
  width: 31%;
  padding: 1px;
  :hover {
    cursor: pointer;
    content: url(${wechatpayColor});
  }
  @media only screen and (max-width: 575px) {
    height: 45px;
  }
  @media only screen and (min-width: 576px) {
    height: 50px;
  }
  @media only screen and (min-width: 768px) {
    height: 55px;
  }
  @media only screen and (min-width: 992px) {
    height: 60px;
  }
  @media only screen and (min-width: 1200px) {
    height: 60px;
  }
`;
export const AliPayContainer = styled.div`
  border-radius: 4px;
  content: url(${(props: { status: string }) =>
    props.status === "alipay" ? alipayColor : alipayGrey});
  height: 60px;
  width: 31%;
  padding: 6px;
  :hover {
    cursor: pointer;
    content: url(${alipayColor});
  }
  @media only screen and (max-width: 575px) {
    height: 45px;
  }
  @media only screen and (min-width: 576px) {
    height: 50px;
  }
  @media only screen and (min-width: 768px) {
    height: 55px;
  }
  @media only screen and (min-width: 992px) {
    height: 60px;
  }
  @media only screen and (min-width: 1200px) {
    height: 60px;
  }
`;
export const StripePaymentContainer = styled.div`
  height: max-content;
`;

export const ExtraInfoContainer = styled.div`
  height: max-content;
  text-align: left;
  padding: 20px 5px;
`;

// text
export const SectionTitle = styled.div`
  text-align: left;
  font-weight: bold;
  color: #242424;
  margin: 20px 0;
  @media only screen and (max-width: 575px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 576px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 18px;
  }
`;
export const Title = styled.div`
  font-weight: bold;
  color: #242424;
  text-align: left;
  @media only screen and (max-width: 575px) {
    font-size: 24px;
    padding: 20px 0 20px 5%;
  }
  @media only screen and (min-width: 576px) {
    font-size: 24px;
    margin: 20px 0 20px 5%;
  }
  @media only screen and (min-width: 768px) {
    font-size: 28px;
    margin: 20px 0 30px 0;
  }
  @media only screen and (min-width: 992px) {
    font-size: 30px;
    margin: 20px 0 30px 0;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 32px;
    margin: 20px 0 30px 0;
  }
`;

// link
export const TermsLink = styled.div`
  display: block;
  color: #6898e385;
  letter-spacing: 0.2px;
  margin-top: 5px;
  cursor: pointer;
  :hover {
    color: #6898e3;
  }
  @media only screen and (max-width: 575px) {
    font-size: 10px;
  }
  @media only screen and (min-width: 576px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 14px;
  }
`;

// input
export const InputArea = styled.input`
  letter-spacing: 0.3px;
  width: 100%;
  height: 52px;
  padding: 0 10px;
  border-radius: 4px;
  border: solid 1px rgb(218, 220, 224);
  line-height: 20px;
  :focus {
    border-bottom: none;
    outline: none;
    border: solid 1px #242424;
  }
  :hover {
    border: solid 1px #242424;
  }
  :: placeholder {
    @media only screen and (max-width: 575px) {
      font-size: 10px;
    }
    @media only screen and (min-width: 576px) {
      font-size: 12px;
    }
    @media only screen and (min-width: 768px) {
      font-size: 12px;
    }
    @media only screen and (min-width: 992px) {
      font-size: 14px;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 14px;
    }
  }
`;

// button

// order summary table
export const Table = styled.table`
  width: 100%;
`;
export const TableRow = styled.tr``;
export const ProductRow = styled.tr`
  height: 40px;
  vertical-align: baseline;
`;
export const TotalPriceRow = styled.tr`
  // height: 30px;
  // vertical-align: bottom;
`;
export const ProductData = styled.td``;
export const TableData = styled.td`
  width: 70%;
  color: #9e9ea7;
`;
export const TablePriceData = styled.td`
  width: 30%;
  text-align: right;
`;
export const TotalPrice = styled.td`
  font-weight: bold;
  text-align: right;
  // font-size: 20px;
`;
