import { useTranslation } from "react-i18next";
import {
  PurchaseProductContainer,
  PurchaseProductIntroductionContainer,
  PurchaseProductIntroductionTitleText,
  PurchaseProductIntroductionTitleImg,
  PurchaseCheckoutButton,
} from "../styles";
import { ContactImg } from "components/general/NavMenu/styles";
import WhiteContact from "../../../../general/NavMenu/assets/WhiteContact.svg";
import { PRODUCTS } from "../../../../../constants/common";
import { List, UnOrderList } from "components/User/Evaluation/styles";

type Props = {
  onContactClick: () => void;
};

const CustomizedPrivateCoaching = (props: Props) => {
  const { t } = useTranslation();

  return (
    <PurchaseProductContainer>
      <PurchaseProductIntroductionContainer>
        <div>
          <PurchaseProductIntroductionTitleText>
            {t(`user.purchase.order.product.${PRODUCTS.CPC}.title`)}
          </PurchaseProductIntroductionTitleText>
          <PurchaseProductIntroductionTitleImg name={PRODUCTS.CPC} />
          <UnOrderList>
            <List>
              {t(`user.purchase.order.product.CustomizedPrivateCoaching.des1`)}
            </List>
            <List>
              {t(`user.purchase.order.product.CustomizedPrivateCoaching.des2`)}
            </List>
            <List>
              {t(`user.purchase.order.product.CustomizedPrivateCoaching.des3`)}
            </List>
            <List>
              {t(`user.purchase.order.product.CustomizedPrivateCoaching.des4`)}
            </List>
            <List>
              {t(`user.purchase.order.product.CustomizedPrivateCoaching.des5`)}
            </List>
            <List>
              {t(`user.purchase.order.product.CustomizedPrivateCoaching.des6`)}
            </List>
            <List>
              {t(`user.purchase.order.product.CustomizedPrivateCoaching.des7`)}
            </List>
          </UnOrderList>
        </div>
        <div>
          <PurchaseCheckoutButton onClick={props.onContactClick}>
            <ContactImg src={WhiteContact} />
            {t("general.navbar.contact")}
          </PurchaseCheckoutButton>
        </div>
      </PurchaseProductIntroductionContainer>
    </PurchaseProductContainer>
  );
};

export default CustomizedPrivateCoaching;
