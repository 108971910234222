import axios from "axios";
import { call, put, takeLatest, select } from "redux-saga/effects";
import api_address from "../../../../../constants/config";

import {
  FETCH_QUESTIONNAIRE,
  SAVE,
  FETCH_MOST_RECENT_USER_RESULT,
  UPDATE_DATA,
  FETCH_RESULT,
} from "./constants";

import {
  fetchQuestionnaireSuccess,
  fetchQuestionnaireFail,
  enableLoading,
  disableLoading,
  saveSuccess,
  saveFail,
  redirectFail,
  fetchMostRecentUserResultSuccess,
  fetchMostRecentUserResultFail,
  enableQuestionnaireLoading,
  disableQuestionnaireLoading,
  fetchResultFail,
  fetchResultSuccess,
} from "./actions";

import { makeSelectToken } from "../../../../auth/selectors";

const axiosRequest = (token?) => {
  return axios.create({
    baseURL: api_address + "api",
    timeout: 60000,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      //"Content-Language": currentLanguage
    },
  });
};

function* fetchQuestionnaire(token) {
  try {
    yield put(enableQuestionnaireLoading());
    const request = yield call(axiosRequest);
    const language = token.token;
    var result;
    if (language === "en") {
      result = yield call(request.get, "questionnaire/lang/en");
    } else if (language === "zh-CN") {
      result = yield call(request.get, "questionnaire/lang/ch");
    } else if (language === "zh-TW") {
      result = yield call(request.get, "questionnaire/lang/tr");
    }
    yield put(fetchQuestionnaireSuccess(result.data));
  } catch (error: any) {
    yield put(fetchQuestionnaireFail(error));
  } finally {
    yield put(disableQuestionnaireLoading());
  }
}

function* saveAnswers(action) {
  const { data } = action;
  try {
    yield put(enableLoading());
    const token = yield select(makeSelectToken());

    const request = yield call(axiosRequest, token);
    const result: any = yield call(request.post, "results/", data);
    yield put(saveSuccess(result.data));
  } catch (error: any) {
    yield put(saveFail(error));
  } finally {
    yield put(disableLoading());
  }
}

function* fetchResult(action) {
  const { id } = action;

  try {
    yield put(enableLoading());

    const request = yield call(axiosRequest, id);
    const result = yield call(request.get, "results/fetch/user", id);

    yield put(fetchResultSuccess(result.data));
  } catch (error: any) {
    yield put(fetchResultFail(error));
  } finally {
    yield put(disableLoading());
  }
}

function* fetchMostRecentUserResult(action) {
  const { id } = action;
  try {
    yield put(enableLoading());
    //const token = yield select(makeSelectToken());
    const request = yield call(axiosRequest, id);
    const userResultsList = yield call(request.get, "results/fetch/user", id);
    yield put(fetchMostRecentUserResultSuccess(userResultsList.data));
  } catch (error: any) {
    yield put(fetchMostRecentUserResultFail(error));
  } finally {
    yield put(disableLoading());
  }
}

function* updateData(action) {
  const token = yield select(makeSelectToken());
  let result;
  try {
    const { transactionId, meta, steps, currentstep, completed, userid } =
      action.data;

    yield put(enableLoading());

    const updateDataRequest = yield call(axiosRequest, token);
    result = yield call(updateDataRequest.put, "results/" + transactionId, {
      transactionId,
      meta,
      steps,
      currentstep,
      createdAt: new Date(),
      completed,
      userid,
    });
  } catch (error) {
    console.error(error);
  } finally {
    yield put(disableLoading());
  }

  if (
    result.data.currentstep === "five" &&
    Object.entries(result.data.steps.four.values).length !== 0
  ) {
    try {
      yield put(enableLoading());
    } catch (error: any) {
      yield put(redirectFail(error));
      yield put(disableLoading());
    } finally {
      yield put(disableLoading());
    }
  }
}

export function* fetchQuestionnaireSaga() {
  yield takeLatest(FETCH_QUESTIONNAIRE, fetchQuestionnaire);
}

export function* mostRecentResultSaga() {
  yield takeLatest(FETCH_MOST_RECENT_USER_RESULT, fetchMostRecentUserResult);
}

export function* resultSaga() {
  yield takeLatest(FETCH_RESULT, fetchResult);
}

export function* saveSaga() {
  yield takeLatest(SAVE, saveAnswers);
}

export function* updateSectionSaga() {
  yield takeLatest(UPDATE_DATA, updateData);
}
