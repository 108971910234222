import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import Cookies from "js-cookie";
import axios from "axios";
import KKSIntroQuestionnaireImg from "../assets/intro-questionnaire.png";
import KKSIntroResultImg from "../assets/intro-result.png";
import KKSIntroAnalysisImg from "../assets/intro-analysis.png";
import KKSIntroActivityImg from "../assets/intro-activity.png";
import api_address from "../../../../../constants/config";
import {
  IntroImageContainer,
  IntroImage,
  Background,
  ButtonContainer,
  Container,
  List,
  OptionText,
  QuestionText,
  Segment,
  Title,
  TitleIndicate,
  UnOrderList,
} from "../../styles";
import { ShowErrNextButton } from "styles";
import EvalProgressBar from "../../ProgressBar/EvalProgressBar";
import { EVAL_STEPS } from "constants/common";

interface KKSState {}

interface KKSProps {
  transactionId: string;
  product: string;
  handleUpdateCurrentStep: (data: string) => void;
}

type Props = KKSProps & WithTranslation & RouteComponentProps;

class KKS extends React.Component<Props, KKSState> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  handleNext = async () => {
    try {
      const token = Cookies.get("token");
      let headers = {};
      if (token) {
        headers = {
          Authorization: `Bearer ${token}`,
        };
      }
      const transactionId = this.props.transactionId;
      const apiData = {
        transactionId: transactionId,
      };
      const res = await axios.post(
        api_address + "api/results/intro",
        apiData,
        headers
      );
      const { next, status } = res.data;
      if (status) {
        this.props.handleUpdateCurrentStep(next);
        return;
      }
    } catch (err: any) {
      console.error(err.message);
    }
  };

  render() {
    const { t, product } = this.props;
    return (
      <Background>
        <EvalProgressBar step={EVAL_STEPS.basicIntro} product={product} />
        <Container>
          <Segment>
            <TitleIndicate />
            <Title>
              {t("user.survey.core.questionnaire.intro.modular.title")}
            </Title>
          </Segment>
          <Segment>
            <QuestionText>
              {t("user.survey.core.questionnaire.intro.modular.welcome")}
            </QuestionText>
            <QuestionText>
              {t("user.survey.core.questionnaire.intro.modular.wording1")}
              {t("user.survey.core.questionnaire.intro.modular.eval")}
              {t("user.survey.core.questionnaire.intro.modular.wording2")}
            </QuestionText>
            <UnOrderList>
              <List>
                <OptionText>
                  {t(
                    "user.survey.core.questionnaire.intro.modular.description"
                  )}
                </OptionText>
              </List>
              <List>
                <OptionText>
                  {t(
                    "user.survey.core.questionnaire.intro.modular.currentTest"
                  )}
                  <span style={{ color: "#6898E3" }}>
                    {t(
                      "user.survey.core.questionnaire.intro.modular.testSection"
                    )}
                  </span>
                </OptionText>
              </List>
              <List>
                <OptionText>
                  {t("user.survey.core.questionnaire.intro.modular.time")}
                </OptionText>
              </List>
            </UnOrderList>
            <IntroImageContainer>
              <IntroImage src={KKSIntroQuestionnaireImg} hide={false} />
              <IntroImage src={KKSIntroResultImg} hide={false} />
              <IntroImage src={KKSIntroAnalysisImg} hide={true} />
              <IntroImage src={KKSIntroActivityImg} hide={true} />
            </IntroImageContainer>
          </Segment>
          <ButtonContainer>
            <ShowErrNextButton onClick={this.handleNext} status={true}>
              {t("user.survey.core.questionnaire.intro.modular.button")}
            </ShowErrNextButton>
          </ButtonContainer>
        </Container>
      </Background>
    );
  }
}

export default withRouter(withTranslation()(KKS));
