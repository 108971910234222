import {
  Persimmon,
  Ming,
  OrangeWeb,
  MiddleBlue,
  LightCoral,
  LightOrgan,
} from "./styles";

export const Color = (index: number) => {
  switch (index) {
    case 0:
      return Persimmon;
    case 1:
      return Ming;
    case 2:
      return OrangeWeb;
    case 3:
      return MiddleBlue;
    case 4:
      return LightCoral;
    case 5:
      return LightOrgan;
    default:
      return Ming;
  }
};
