import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
  InfoContainer,
  DescriptionContainer,
  TherapistName,
  TherapistTitle,
  TherapistNameContainer,
  HistoryContainer,
  History,
  BioContainer,
  Paragraph,
} from "./styles";
import { RouteSectionContainer } from "styles";
import { Segment, Title } from "components/User/Evaluation/styles";
import {
  GET_ALL_THERAPIST_PUBLIC,
  GET_THERAPIST_BIO_BY_THERAPIST_ID_PUBLIC,
} from "util/therapist";
import { THERAPIST_PROFILE_PICTURE_FOLDER_ON_S3_ROUTE } from "constants/common";
import { useState, useEffect } from "react";
interface TherapistType {
  UUID: string;
  id: number;
  firstName: string;
  lastName: string;
  avatar_filename: string;
  title: string;
  disabled: boolean;
}
export default function ExpertTeam() {
  const { t } = useTranslation();
  const [therapists, setTherapists] = useState([]);
  const [therapistBio, setTherapistBio] = useState<
    Array<Array<{ therapist_id: number; bio: string; language: string }>>
  >([]);

  const getAllTherapist = async () => {
    const res = await GET_ALL_THERAPIST_PUBLIC();
    const { status, therapist } = res;
    if (!status) return;

    if (therapist) {
      setTherapists(therapist);
    }

    var tempTherapistBio: Array<
      Array<{ therapist_id: number; bio: string; language: string }>
    > = [];
    for (let i = 0; i < therapist.length; i++) {
      const res_bio = await GET_THERAPIST_BIO_BY_THERAPIST_ID_PUBLIC(
        therapist[i].id
      );
      const { status, therapistBio } = res_bio;
      if (status && therapistBio) tempTherapistBio.push(therapistBio);
    }
    if (tempTherapistBio) setTherapistBio(tempTherapistBio);
  };
  useEffect(() => {
    getAllTherapist();
  }, []);
  window.scrollTo(0, 0);
  return (
    <RouteSectionContainer>
      <Title>{t("utils.ExpertTeam.title")}</Title>
      {therapists && therapists.length > 0 ? (
        therapists.map((therapist: TherapistType, index) => (
          <Segment>
            <img
              style={{ height: "180px", width: "180px" }}
              src={
                THERAPIST_PROFILE_PICTURE_FOLDER_ON_S3_ROUTE +
                therapist.avatar_filename
              }
              alt="Avatar"
            />
            <InfoContainer>
              <TherapistNameContainer>
                <TherapistName>
                  {therapist.firstName} {therapist.lastName}
                </TherapistName>
                <TherapistTitle>{therapist.title}</TherapistTitle>
              </TherapistNameContainer>
              <DescriptionContainer>
                <HistoryContainer>
                  {therapistBio &&
                    therapistBio[index] &&
                    Array.isArray(therapistBio[index]) &&
                    therapistBio[index].length > 0 &&
                    therapistBio[index][0].therapist_id === therapist.id &&
                    therapistBio[index].map((bio) => (
                      <BioContainer>
                        <History></History>
                        <Paragraph>{bio.bio}</Paragraph>
                      </BioContainer>
                    ))}
                </HistoryContainer>
              </DescriptionContainer>
            </InfoContainer>
          </Segment>
        ))
      ) : (
        <div>No therapist</div>
      )}
    </RouteSectionContainer>
  );
}
