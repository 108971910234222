export const GET_AGE_RANGE = "questionnaire/GET_AGE_RANGE";
export const GET_QUESTIONNAIRE = "questionnaire/GET_QUESTIONNAIRE";
export const GET_QUESTION_BLOCK = "questionnaire/GET_QUESTION_BLOCK";

export const UPDATE_QUESTIONNAIRE_STEP =
  "questionnaire/UPDATE_QUESTIONNAIRE_STEP";
export const UPDATE_QUESTIONNAIRE_STEP_DATA =
  "questionnaire/UPDATE_QUESTIONNAIRE_STEP_DATA";
export const UPDATE_QUESTIONNAIRE_META =
  "questionnaire/UPDATE_QUESTIONNAIRE_META";

export const RESET = "questionnaire/RESET";

export const FETCH_QUESTIONNAIRE = "questionnaire/FETCH_QUESTIONNAIRE";
export const FETCH_QUESTIONNAIRE_SUCCESS =
  "questionnaire/FETCH_QUESTIONNAIRE_SUCCESS";
export const FETCH_QUESTIONNAIRE_FAIL =
  "questionnaire/FETCH_QUESTIONNAIRE_FAIL";

export const ENABLE_LOADING = "questionnaire/ENABLE_LOADING";
export const DISABLE_LOADING = "questionnaire/DISABLE_LOADING";

export const ENABLE_QUESTIONNAIRE_LOADING =
  "questionnaire/ENABLE_QUESTIONNAIRE_LOADING";
export const DISABLE_QUESTIONNAIRE_LOADING =
  "questionnaire/DISABLE_QUESTIONNAIRE_LOADING";

export const SAVE = "questionnaire/SAVE";
export const SAVE_SUCCESS = "questionnaire/SAVE_SUCCESS";
export const SAVE_FAIL = "questionnaire/SAVE_FAIL";

export const FETCH_RESULT = "questionnaire/FETCH_RESULT";
export const FETCH_RESULT_SUCCESS = "questionnaire/FETCH_RESULT_SUCCESS";
export const FETCH_RESULT_FAIL = "questionnaire/FETCH_RESULT_FAIL";

export const REDIRECT = "questionnaire/REDIRECT";
export const REDIRECT_FAIL = "questionnaire/REDIRECT_FAIL";

export const FETCH_MOST_RECENT_USER_RESULT =
  "questionnaire/FETCH_MOST_RECENT_USER_RESULT";
export const FETCH_MOST_RECENT_USER_RESULT_SUCCESS =
  "questionnaire/FETCH_MOST_RECENT_USER_RESULT_SUCCESS";
export const FETCH_MOST_RECENT_USER_RESULT_FAIL =
  "questionnaire/FETCH_MOST_RECENT_USER_RESULT_FAIL";

export const UPDATE_DATA = "quetionnaire/UPDATE_SECTION_DATA";
