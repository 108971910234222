import { useTranslation } from "react-i18next";
import {
  A4Container,
  PageSubtitle,
  PageTitle,
  SmallSectionTitle,
} from "../styles";
import {
  LogoContainer,
  Logo,
  InformationContainer,
  ContentContainer,
  CoverContainer,
  TitleContainer,
  CompanyName,
  CompanySubtitle,
} from "./styles";
import { FORMAT_NAME_BY_COUNTRY } from "util/common";
import { Logo_Icon } from "../ImageLoader";
import { Therapist } from "../../CoreProReport/index";

type Props = {
  therapistUUID: string;
  therapist: Therapist;
  createdAt: string;
  childName: string;
};

export default function Cover(props: Props) {
  const { t, i18n } = useTranslation();
  const { therapistUUID, therapist, createdAt, childName } = props;
  const date = new Date(createdAt);
  const evaluationDate =
    date.getFullYear() + "." + (date.getMonth() + 1) + "." + date.getDate();

  const therapistName = FORMAT_NAME_BY_COUNTRY(
    therapist.firstName,
    therapist.lastName,
    therapist.country
  );

  return (
    <A4Container>
      <CoverContainer>
        <LogoContainer>
          <Logo src={Logo_Icon} />
          <CompanyName>{t("general.companyName")}</CompanyName>
          <CompanySubtitle>{t("general.keyword")}</CompanySubtitle>
        </LogoContainer>
        <TitleContainer>
          <PageTitle>{t("user.survey.corePro.result.cover.title")}</PageTitle>
          <PageSubtitle>
            {t("user.survey.corePro.result.cover.des")}
          </PageSubtitle>
        </TitleContainer>
        <InformationContainer>
          <ContentContainer>
            <SmallSectionTitle>
              {t("user.survey.corePro.result.cover.childName")}
              {": "}
              {childName}
            </SmallSectionTitle>
            <SmallSectionTitle>
              {`${t(
                "user.survey.corePro.result.cover.therapist"
              )}: ${therapistName}`}
            </SmallSectionTitle>
            <SmallSectionTitle>
              {t("user.survey.corePro.result.cover.evaluationDate")}
              {": "}
              {evaluationDate}
            </SmallSectionTitle>
          </ContentContainer>
        </InformationContainer>
      </CoverContainer>
    </A4Container>
  );
}
