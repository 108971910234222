import {
  List,
  OptionText,
  OrderList,
  Segment,
  Title,
  TitleIndicate,
} from "../../styles";

export default function BJCHIntro() {
  return (
    <>
      <Segment>
        <TitleIndicate />
        <Title>开始前请留意：</Title>
      </Segment>
      <Segment>
        <OrderList>
          <List>
            <OptionText>测评填写大约需要10分钟。</OptionText>
          </List>
          <List>
            <OptionText>
              问卷针对孩童沟通发展五方面评估 -
              请根据生活中的观察，以最佳判断来做出选择。
            </OptionText>
          </List>
          <List>
            <OptionText>
              本问卷为动态跳转式设计，题目难易交替、或是出现看似类似题目描述为设计的一部分，敬请耐心答题。
            </OptionText>
          </List>
          <List>
            <OptionText>
              本系统将自动储存各阶段作答，重新输入病历号后可继续完成测评。
            </OptionText>
          </List>
        </OrderList>
      </Segment>
    </>
  );
}
