import styled from "styled-components";

export const UploadBox = styled.div<{
  disableBox: boolean;
}>`
  margin: 0 auto;
  display: grid;
  place-items: center;
  height: 100px;
  border: 1px dashed rgb(204, 204, 204);
  border-radius: 4px;
  text-align: center;
  width: 80%;
  pointer-events: ${(props) => (props.disableBox ? "none" : "inherit")};

  @media only screen and (max-width: 600px) {
    display: block;
    height: auto;
  }
`;

export const UploadInput = styled.input`
  display: none;
`;

export const UploadButtonImg = styled.img<{
  uploading: boolean;
}>`
  border: 0px solid red;
  width: ${(props) => (props.uploading ? "0%" : "20%")};
  margin: 20px auto;
  clear: both;
  @media only screen and (max-width: 600px) {
  }
`;

export const UploadButtonText = styled.div<{
  uploading: boolean;
}>`
  border: 0px solid red;
  display: flex;
  text-align: center;
  width: fit-content;

  @media only screen and (max-width: 600px) {
    margin: ${(props) => (props.uploading ? "20px" : "0px")};
    margin-bottom: 20px;
    text-align: center;
  }
`;

export const UploadBoxContainer = styled.label<{
  uploading: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: start;
  line-height: 28px;
  font-size: 16px;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    flex-direction: ${(props) => (props.uploading ? "row" : "column")};
  }
`;

export const DriveInput = styled.input`
  margin: 10px 0;
  width: 100%;
  border-radius: 4px;
  padding: 10px;
  line-height: 18px;
  outline: 0;
`;

export const SaveButton = styled.div<{ disabled: boolean }>`
  margin: 10px auto;
  display: grid;
  place-items: center;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 20px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  height: 50px;
  width: 150px;
  outline: 0;
  background-color: ${(props) => (props.disabled ? "#C4C4C4" : "#6898E3")};
  border: ${(props) =>
    props.disabled ? "1px solid #C4C4C4" : "1px solid #6898E3"};
  &:active {
    transform: translate(0px, 0px);
    transition: 0.2s;
    background-color: ${(props) =>
      props.disabled ? "none" : "rgb(100, 155, 250)"};
    pointer-events: ${(props) => (props.disabled ? "none" : "inherit")};
  }
`;

export const CheckBoxContainer = styled.div`
  width: 100%;
  padding: 0 5px;
  margin-top: 20px;
`;

export const SubmitButton = styled.div<{ disabled: boolean }>`
  margin-top: 20px;
  border-radius: 4px;
  background-color: ${(props) => (props.disabled ? "#C4C4C4" : "#6898E3")};
  color: #ffffff;
  width: 150px;
  height: 50px;
  display: grid;
  place-items: center;
  font-size: 22px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "")};

  @media only screen and (max-width: 450px) {
    width: 100px;
    font-size: 16px;
    height: 40px;
  }
`;

export const ProgressBarContainer = styled.div<{
  display: string;
}>`
  width: 80%;
  margin: 10px auto;
  height: 20px;
  display: ${(props) => props.display};
`;

export const ProgressBarLine = styled.div`
  border-radius: 15px;
  height: 8px;
  border: solid 8px #d9d9d9;
`;

export const BarLine = styled.div<{ barWidth: string }>`
  border-radius: 15px;
  height: 8px;
  border: solid 8px #6898e3;
  width: ${(props) => props.barWidth};
  margin-top: -16px;
`;

export const SwitchButtonText = styled.label<{
  display: string;
}>`
  margin-top: 20px;
  color: #6898e3;
  cursor: pointer;
  display: ${(props) => props.display};
`;

export const Link = styled.link`
  // :hover {
  //   cursor: pointer;
  //   font-weight: bold;
  // }
`;

export const InstructionHighlightText = styled.span`
  color: #6898e3;
  font-weight: bold;
`;

export const SetUpImage = styled.img`
  width: 100%;
  margin-bottom: 20px;

  @media only screen and (min-width: 576px) {
    width: 80%;
  }
  @media only screen and (min-width: 768px) {
    width: 60%;
  }
  @media only screen and (min-width: 992px) {
    width: 50%;
  }
  @media only screen and (min-width: 1200px) {
    width: 50%;
  }
`;

export const GoalLine = styled.div`
  width: 100%;
  margin: 5px 0;
  height: 0px;
  border: solid 1px #e4e4e4;
`;

export const StoryLineImageContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  text-align: center;
`;

export const StoryLineImage = styled.img`
  margin: 5px 0;
  width: 40%;
  background-image: ${(props) => props.src};
  border: 1px solid #333333;
  @media only screen and (max-width: 750px) {
    width: 100%;
  }
`;
