let api_address = "";

if (process.env.REACT_APP_ENVIRONMENT === "staging") {
  api_address = "https://staging-api.evaluation.qierbao.com/";
} else if (process.env.REACT_APP_ENVIRONMENT === "production") {
  api_address = "https://production-api.evaluation.qierbao.com/";
} else {
  api_address = "http://localhost:8000/";
}

export default api_address;
