import axios from "axios";
import DropDown from "components/common/DropDown";
import NotificationModal from "components/common/NotificationModal";
import { FlexContainer } from "components/User/Evaluation/Core/CoreReport/styles";
import { ADMIN_COOKIE_NAME } from "constants/admin";
import api_address from "constants/config";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import ReactJson, {
  InteractionProps,
  ReactJsonViewProps,
  ThemeKeys,
} from "react-json-view";
import { JS_COOKIE } from "util/auth";
import { ContentContainer, ContentSubtitle } from "../styles";
import ReactJsonViewCompare from "react-json-view-compare";
import {
  Button,
  ButtonGroupContainer,
  Container,
  Header,
  Loading,
  Mask,
  StyledResponseViewer,
  UploadButton,
  UploadInput,
} from "./style";
import { SELECT_OPTION } from "./constants";
import {
  filterJson,
  mergeJson,
  isEqualsJson,
  languages,
  parseJsonFileUpload,
  checkEmptyInJson,
} from "./utils";

interface I18nEditorStates {
  selectionData: any[];
  selectedOptions: {
    text: string;
    value: string;
    options: any[];
  }[];
  message: string;
  showModal: boolean;
  notificationStatus: boolean;
  jsonSrc: Record<string, any>;
  responseJsonSrc: Record<string, any>;
  loading: boolean;
  searchTerm: string;
  isUploaded: boolean;
}

type Props = {};

const JSONViewerConfig: Partial<ReactJsonViewProps> = {
  name: null,
  theme: "apathy:inverted" as ThemeKeys,
  displayDataTypes: false,
  style: {
    width: "40%",
    overflow: "auto",
    height: "70vh",
  },
};

let errorValidate: any = [];

class I18nEditor extends Component<Props, I18nEditorStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectionData: [],
      selectedOptions: [],
      message: "",
      showModal: false,
      notificationStatus: false,
      jsonSrc: {},
      responseJsonSrc: {},
      loading: false,
      searchTerm: "",
      isUploaded: false,
    };
  }

  componentDidMount() {
    this.fetchI18nSelectOptions();
  }

  showNotification = (message: any, status: boolean) => {
    this.setState({
      message: typeof message === "string" ? message : "Internal sever error",
      notificationStatus: status,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  fetchI18nSelectOptions = async (filter?: string) => {
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const response = await axios.get(
        `${api_address}api/admin/i18n/questionnaire`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const {
        status,
        questionnaire = {},
        message: errorMessage = "",
      } = response?.data;

      if (!status) {
        this.showNotification(errorMessage, false);
        return;
      }

      SELECT_OPTION[0].child.splice(2, 0, ...questionnaire);

      this.setState({ selectionData: SELECT_OPTION });
    } catch (error: any) {
      this.showNotification(error.message, false);
    }
  };

  handleSelectedOptionChange = (idx: number) => (value: string) => {
    if (!value) return;

    const { selectedOptions } = this.state;

    // change key at idx
    let newSelectedOptions = [...selectedOptions];

    if (idx >= selectedOptions.length) {
      newSelectedOptions.push({ text: "", value: "", options: [] });
    } else {
      newSelectedOptions = selectedOptions.slice(0, idx + 1);
    }

    newSelectedOptions[idx].value = value;

    // get the next options
    const { selectionData } = this.state;
    let options: any = null;
    for (const selectedKey of newSelectedOptions) {
      const parent = options || selectionData;
      const currentOptions = parent.find((e) => e.path === selectedKey.value);
      newSelectedOptions[idx].text = currentOptions.title;
      if (currentOptions) {
        options = currentOptions.child;
      }
    }

    newSelectedOptions[idx].options = options;
    this.setState({ selectedOptions: newSelectedOptions });
  };

  onDownload = async () => {
    // Source: https://stackoverflow.com/questions/55613438/reactwrite-to-json-file-or-export-download-no-server
    const { jsonSrc } = this.state;
    const fileName = "i18n";
    const json = JSON.stringify(jsonSrc, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  onUploadData = async (e: React.ChangeEvent<HTMLInputElement>) => {
    errorValidate = [];
    const { selectedOptions } = this.state;

    const lastEl = selectedOptions[selectedOptions.length - 1] || "";
    if (!lastEl) {
      this.showNotification(
        "You must choose the path before uploading the file",
        false
      );
      return;
    }
    const { files } = e.currentTarget;
    if (!files || files.length === 0) {
      return;
    }
    this.setState({
      jsonSrc: {},
      responseJsonSrc: {},
      isUploaded: true,
    });

    try {
      const file = files[0];
      const getJsonOrigin = filterJson(lastEl.value);
      const readFileUpload = await parseJsonFileUpload(file);
      const isMatch = isEqualsJson(readFileUpload, getJsonOrigin, true);

      if (!isMatch) {
        this.showNotification(
          "Uploaded file is not in the correct format",
          false
        );
        return;
      }
      const checkEmpty = checkEmptyInJson(readFileUpload);

      if (checkEmpty) {
        this.showNotification("Uploaded file has null value", false);
        return;
      }

      this.showNotification("Validation success", true);
      this.setState({
        //@ts-ignore
        responseJsonSrc: readFileUpload,
        //@ts-ignore
        jsonSrc: getJsonOrigin,
      });
    } catch (error: any) {
      this.showNotification(error.message, false);
    }
  };

  onValidate = async (json: Record<string, any>) => {
    try {
      // create a json file and upload it
      const formData = new FormData();
      const jsonStr = JSON.stringify(json, null, 2);
      const blob = new Blob([jsonStr], { type: "application/json" });
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      formData.append("file", blob, "sample.json");

      const response = await axios.post(
        `${api_address}api/admin/i18n/validate`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const { status, message: errorMessage = "" } = response?.data;

      if (!status) {
        this.showNotification(errorMessage, false);
        return false;
      }
      this.showNotification("Validation success", true);
      this.setState({
        responseJsonSrc: json,
      });
      return true;
    } catch (error: any) {
      this.showNotification(error.message, false);
      return false;
    }
  };

  onCreatePullRequest = async () => {
    if (errorValidate.length > 0) {
      this.renderMessageError();
    } else {
      const { responseJsonSrc, jsonSrc } = this.state;
      if (
        !responseJsonSrc ||
        !jsonSrc ||
        !Object.keys(jsonSrc).length ||
        !Object.keys(responseJsonSrc).length
      ) {
        return;
      }

      const { selectedOptions } = this.state;

      const lastEl = selectedOptions[selectedOptions.length - 1] || "";

      this.setState({
        loading: true,
      });

      try {
        // User selected questionnaire
        if (typeof lastEl.value === "string") {
          const jsonStr = JSON.stringify(responseJsonSrc, null, 2);
          const blob = new Blob([jsonStr], { type: "application/json" });
          const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
          const formData = new FormData();
          formData.append("questionnaire_file", blob, "sample.json");
          formData.append("questionnaire_path", lastEl?.value || "");
          const response = await axios.post(
            `${api_address}api/admin/i18n/createPullRequestToBitBucket`,
            formData,
            {
              params: {
                workspace: process.env.REACT_APP_BITBUCKET_API_EVAL_WORKSPACE,
              },
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );
          const { status, message } = response?.data;
          if (!status) {
            this.showNotification(message, false);
            this.setState({
              loading: false,
            });
            return;
          }
          this.showNotification(message, true);

          // User selected Basic Information or CorePro
        } else {
          const result = mergeJson(responseJsonSrc, lastEl?.value);
          const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
          const formData = new FormData();
          let countFileChange = 0;

          for (let i = 0; i < languages.length; i++) {
            if (
              !isEqualsJson(
                languages[i].value,
                result[languages[i].file],
                false
              )
            ) {
              countFileChange++;
              formData.append(
                languages[i].file,
                new Blob([JSON.stringify(result[languages[i].file], null, 2)], {
                  type: "application/json",
                }),
                `${languages[i].value}.json`
              );
              formData.append(languages[i].keyPath, languages[i].path);
            }
          }

          if (countFileChange > 0) {
            const response = await axios.post(
              `${api_address}api/admin/i18n/createPullRequestToBitBucket`,
              formData,
              {
                params: {
                  workspace: process.env.REACT_APP_BITBUCKET_EVAL_WORKSPACE,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            );
            const { status, message } = response?.data;
            if (!status) {
              this.showNotification(message, false);
              this.setState({
                loading: false,
              });
              return;
            }
            this.showNotification(message, true);
          }
        }
      } catch (error: any) {
        this.showNotification(error.message, false);
      }
      this.setState({
        loading: false,
      });
    }
  };

  onSearch = async () => {
    errorValidate = [];
    const { selectedOptions } = this.state;

    if (!selectedOptions || !selectedOptions.length) return;

    const lastEl = selectedOptions[selectedOptions.length - 1];
    if (!lastEl.value) return;

    try {
      if (typeof lastEl.value === "string") {
        const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
        const response = await axios.get(`${api_address}api/admin/i18n`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            filter: lastEl.value,
          },
        });

        const { status, i18nData, message: errorMessage = "" } = response?.data;

        if (!status) {
          this.showNotification(errorMessage, false);
          return;
        }

        this.setState({
          jsonSrc: i18nData,
          responseJsonSrc: i18nData,
          isUploaded: false,
        });
      } else {
        const i18nData = filterJson(lastEl.value);
        this.setState({
          jsonSrc: i18nData,
          responseJsonSrc: i18nData,
          isUploaded: false,
        });
      }
    } catch (error: any) {
      this.showNotification(error.message, false);
    }
  };

  handleRouterJson = (arrRoute, key) => {
    const arrToString = arrRoute.toString();
    return arrToString.replaceAll(",", "/") + "/" + key;
  };

  renderMessageError = () => {
    let arrMessageError: any = [];

    if (errorValidate.length > 0) {
      for (let i = 0; i < errorValidate.length; i++) {
        if (errorValidate[i].emty) {
          arrMessageError.push(
            `Value at position ${errorValidate[i].router} cannot be empty`
          );
        } else {
          arrMessageError.push(
            `Data type at position ${errorValidate[i].router} is not the same as the original data type`
          );
        }
      }

      this.setState({
        message: arrMessageError,
        notificationStatus: false,
        showModal: true,
      });
      setTimeout(() => {
        this.setState({ showModal: false });
      }, 3000);
    }
  };

  onChangeJSON = (e: InteractionProps) => {
    const { updated_src, new_value, existing_value, namespace, name } = e;

    if (errorValidate.length === 0) {
      if (typeof new_value !== typeof existing_value || new_value === "") {
        errorValidate.push({
          router: this.handleRouterJson(namespace, name),
          oldValue: existing_value,
          emty: new_value === "",
        });
      }
    } else {
      const arrErrorFilter = errorValidate.filter(
        (item: any) => item.router === this.handleRouterJson(namespace, name)
      );

      if (arrErrorFilter[0]) {
        const itemFirst = arrErrorFilter[0];

        if (typeof new_value === typeof itemFirst.oldValue || itemFirst.emty) {
          errorValidate = errorValidate.filter(
            (item) => item.router !== this.handleRouterJson(namespace, name)
          );

          if (
            new_value === "" ||
            (itemFirst.emty && typeof new_value !== typeof itemFirst.oldValue)
          ) {
            errorValidate.push({
              router: this.handleRouterJson(namespace, name),
              oldValue: itemFirst.oldValue,
              emty: new_value === "",
            });
          }
        }
      } else {
        if (typeof new_value !== typeof existing_value || new_value === "") {
          errorValidate.push({
            router: this.handleRouterJson(namespace, name),
            oldValue: existing_value,
            emty: new_value === "",
          });
        }
      }
    }
    // show message error
    this.renderMessageError();

    this.setState({
      responseJsonSrc: updated_src,
    });
  };

  clearEditor = () => {
    errorValidate = [];
    this.setState({
      jsonSrc: {},
      responseJsonSrc: {},
      selectedOptions: [],
    });
  };

  onChangeSearchTerm = (e) => {
    this.setState({
      searchTerm: e.target.value,
    });
  };

  onInputUploadClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = "";
  };

  render() {
    const {
      showModal,
      message,
      notificationStatus,
      selectedOptions,
      selectionData,
      jsonSrc,
      loading,
      isUploaded,
      responseJsonSrc,
    } = this.state;

    const firstKeySelections = selectionData.map(({ title, path }: any) => ({
      text: title,
      value: path,
    }));

    return (
      <Container>
        {loading && (
          <Mask>
            <Loading />
          </Mask>
        )}

        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />

        <Header>
          <ContentContainer>
            <ContentSubtitle>Select</ContentSubtitle>
            <DropDown
              componentName="admin - i18n - first key"
              type="i18n key"
              defaultSelection={""}
              selectedText={
                selectedOptions.length > 0 ? selectedOptions[0].text : ""
              }
              onOptionChange={this.handleSelectedOptionChange(0)}
              options={firstKeySelections}
            />
          </ContentContainer>

          {selectedOptions.length > 0 &&
            selectedOptions.map(({ text, value, options }, idx: number) => {
              if (options?.length > 0) {
                const availableOptions = options?.map(
                  ({ title, path }: any) => ({
                    text: title,
                    value: path,
                  })
                );
                return (
                  <ContentContainer>
                    <ContentSubtitle>Select</ContentSubtitle>
                    <DropDown
                      componentName={`admin - i18n - ${idx + 1} key`}
                      type="i18n key"
                      defaultSelection={""}
                      selectedText={
                        idx < selectedOptions.length - 1
                          ? selectedOptions[idx + 1].text
                          : ""
                      }
                      onOptionChange={this.handleSelectedOptionChange(idx + 1)}
                      options={availableOptions}
                    />
                  </ContentContainer>
                );
              }

              return null;
            })}

          <Button onClick={this.onSearch}>Search</Button>
        </Header>

        <FlexContainer>
          <ReactJson
            src={isUploaded ? responseJsonSrc : jsonSrc}
            {...JSONViewerConfig}
            onEdit={this.onChangeJSON}
            // onAdd={this.onChangeJSON}
            // onDelete={this.onChangeJSON}
          />
          <ButtonGroupContainer>
            <Button onClick={this.onDownload}>Download</Button>
            {/* <Button onClick={() => this.onValidate(jsonSrc)}>Validate</Button> */}
            <div>
              <UploadButton as="label" htmlFor={`upload_i18n_file`}>
                Upload Data
              </UploadButton>
              <UploadInput
                type="file"
                id={`upload_i18n_file`}
                accept="application/json"
                onChange={this.onUploadData}
                onClick={this.onInputUploadClick}
              />
            </div>
            <Button onClick={this.onCreatePullRequest}>
              Create Pull Request
            </Button>
            <Button onClick={this.clearEditor}>Clear editor</Button>
          </ButtonGroupContainer>

          <StyledResponseViewer>
            <ReactJsonViewCompare oldData={jsonSrc} newData={responseJsonSrc} />
          </StyledResponseViewer>
        </FlexContainer>
      </Container>
    );
  }
}

export default withTranslation()(I18nEditor);
