export const SELECT_OPTION = [
  {
    child: [
      // evaluation intro
      {
        child: [
          {
            child: [],
            name: "core",
            path: ["user", "survey", "core", "questionnaire", "intro", "core"],
            title: "CORE",
            type: "file",
          },
          {
            child: [],
            name: "corePro",
            path: [
              "user",
              "survey",
              "core",
              "questionnaire",
              "intro",
              "corePro",
            ],
            title: "CORE-PRO",
            type: "file",
          },
          {
            child: [],
            name: "modular",
            path: [
              "user",
              "survey",
              "core",
              "questionnaire",
              "intro",
              "modular",
            ],
            title: "Modular",
            type: "file",
          },
        ],
        name: "intro",
        path: ["user", "survey", "core", "questionnaire", "intro"],
        title: "Intro",
        type: "file",
      },
      // basic information form
      {
        child: [],
        name: "basicInformation",
        path: ["user", "survey", "core", "questionnaire", "child_info"],
        title: "Basic information",
        type: "file",
      },
      // sound collection
      {
        child: [],
        name: "sound collection",
        path: ["user", "survey", "core", "questionnaire", "sound_collection"],
        title: "Sound collection",
        type: "file",
      },
      // video upload
      {
        child: [],
        name: "videoUpload",
        path: ["user", "survey", "corePro", "video"],
        title: "Video upload",
        type: "file",
      },
      // waiting, reviewing, report
      {
        child: [],
        name: "lastSection",
        path: ["user", "survey", "corePro", "waiting"],
        title: "Last section",
        type: "file",
      },
    ],
    name: "evaluation",
    path: "src/locales/user/survey",
    title: "Evaluation",
    type: "file",
  },
  {
    child: [
      {
        child: [],
        name: "reviewAndSuggestion",
        path: ["therapist", "reportGeneration", "reviewAndSuggestion"],
        title: "ReviewAndSuggestion",
        type: "file",
      },
      {
        child: [],
        name: "generalAnalysis",
        path: ["therapist", "reportGeneration", "generalAnalysis"],
        title: "GeneralAnalysis",
        type: "file",
      },
      {
        child: [],
        name: "videoAnalysis",
        path: ["therapist", "reportGeneration", "videoAnalysis"],
        title: "VideoAnalysis",
        type: "file",
      },
      {
        child: [],
        name: "activity",
        path: ["therapist", "reportGeneration", "activity"],
        title: "Activity",
        type: "file",
      },
    ],
    name: "therapist",
    path: "src/locales/therapist/reportGeneration",
    title: "Report generation",
    type: "folder",
  },
  {
    child: [
      {
        child: [],
        name: "cover",
        path: ["user", "survey", "corePro", "result", "cover"],
        title: "Cover",
        type: "file",
      },
      {
        child: [],
        name: "tableOfContent",
        path: ["user", "survey", "corePro", "result", "tableOfContent"],
        title: "Table of content",
        type: "file",
      },
      {
        child: [],
        name: "basicInformation",
        path: ["user", "survey", "corePro", "result", "basicInformation"],
        title: "Basic information",
        type: "file",
      },
      {
        child: [],
        name: "reviewAndSuggestion",
        path: ["user", "survey", "corePro", "result", "reviewAndSuggestion"],
        title: "ReviewAndSuggestion",
        type: "file",
      },
      {
        child: [],
        name: "generalAnalysis",
        path: ["user", "survey", "corePro", "result", "generalAnalysis"],
        title: "GeneralAnalysis",
        type: "file",
      },
      {
        child: [],
        name: "videoAnalysis",
        path: ["user", "survey", "corePro", "result", "videoAnalysis"],
        title: "VideoAnalysis",
        type: "file",
      },
      {
        child: [],
        name: "activity",
        path: ["user", "survey", "corePro", "result", "activity"],
        title: "Activity",
        type: "file",
      },
      {
        child: [],
        name: "appendix1",
        path: ["user", "survey", "corePro", "result", "appendix1"],
        title: "Appendix1",
        type: "file",
      },
      {
        child: [],
        name: "appendix2",
        path: ["user", "survey", "corePro", "result", "appendix2"],
        title: "Appendix2",
        type: "file",
      },
      {
        child: [],
        name: "followUs",
        path: ["user", "survey", "corePro", "result", "followUs"],
        title: "Follow us",
        type: "file",
      },
    ],
    name: "parent",
    path: "src/locales/user/survey/corePro/result",
    title: "CORE-Pro report",
    type: "folder",
  },
  {
    child: [
      {
        child: [],
        name: "comprehension",
        path: ["recommendedActivities", "comprehension"],
        title: "Comprehension",
        type: "file",
      },
      {
        child: [],
        name: "expression",
        path: ["recommendedActivities", "expression"],
        title: "Expression",
        type: "file",
      },
      {
        child: [],
        name: "social",
        path: ["recommendedActivities", "social"],
        title: "Social",
        type: "file",
      },
      {
        child: [],
        name: "oral",
        path: ["recommendedActivities", "oral"],
        title: "Oral",
        type: "file",
      },
    ],
    name: "activities",
    path: "src/locales/recommendedActivities",
    title: "Activities",
    type: "folder",
  },
];
