import { FormTitleSegment, FormTitleSegmentIndicate } from "../style";

const FormSectionTitle = (props) => {
  return (
    <FormTitleSegment>
      <FormTitleSegmentIndicate />
      {props.children}
    </FormTitleSegment>
  );
};

export default FormSectionTitle;
