import styled from "styled-components";

export const Background = styled.div`
  background: #eceef8;
`;
// container

export const Container = styled.div`
  text-align: left;
  background: #eceef8;
  min-height: 750px;
  @media only screen and (max-width: 575px) {
    padding: 0 2% 50px 2%;
  }
  @media only screen and (min-width: 576px) {
    padding: 0 2% 50px 2%;
  }
  @media only screen and (min-width: 768px) {
    padding: 0 6% 80px 6%;
  }
  @media only screen and (min-width: 992px) {
    padding: 0 14% 80px 14%;
  }
  @media only screen and (min-width: 1200px) {
    padding: 0 20% 80px 20%;
  }
`;
export const QuestionnaireContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const SetUpSegment = styled.div`
  padding: 24px;
  margin: 12px 0;
  border: 1px solid #dadce0;
  border-radius: 8px;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
`;

export const Segment = styled.div`
  margin: 12px 0;
  border: 1px solid #dadce0;
  border-radius: 8px;
  background-color: #fff;
  position: relative;
  padding: 28px;
  @media only screen and (min-width: 850px) {
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

// indicate
export const TitleIndicate = styled.div`
  background: #6898e3;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 10px;
  width: calc(100% + 2px);
  top: -1px;
  left: -1px;
  position: absolute;
`;
export const SectionIndicate = styled.div`
  background: #6898e3;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 48px;
  width: calc(100% + 2px);
  top: -1px;
  left: -1px;
  position: absolute;
  padding: 12px 24px;
`;
export const LeftIndicate = styled.div`
  background: #6898e3;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: calc(100% + 2px);
  width: 6px;
  bottom: -1px;
  left: -1px;
  padding-right: 10px;
  position: absolute;
`;

// text
export const Title = styled.div`
  color: #202124;
  font-size: 32px;
  font-weight: 400;
  line-height: 135%;
  display: flex;
  text-align: center;
  justify-content: center;

  @media only screen and (max-width: 400px) {
    font-size: 26px;
  }
`;

export const ContactUsTitle = styled(Title)`
  font-size: 15px;
  font-weight: normal;
`;

export const UploadVideoTitle = styled(Title)`
  font-size: 28px;
  margin-bottom: 35px;
  margin-top: 10px;
  @media only screen and (max-width: 400px) {
    font-size: 26px;
  }
`;

export const QuestionText = styled.div`
  font-size: 18px;
  letter-spacing: 0.1px;
  line-height: 24px;
  color: #202124;
  font-weight: 400;
  width: 85%;
  word-break: break-word;

  @media only screen and (min-width: 850px) {
    width: 100%;
    font-size: 20px;
    line-height: normal;
  }
`;
export const OptionText = styled.div`
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 20px;
  // color: #202124;
  min-width: 1px;
`;

export const UploadVideoSubtitle = styled(OptionText)`
  text-align: center;
  margin: 0px 20px 20px 20px;
`;

export const UploadVideoLinkSubtitle = styled(UploadVideoSubtitle)`
  text-align: left;
  margin: 0px 30px 20px 60px;

  @media only screen and (max-width: 600px) {
    text-align: left;
    margin: 0px 20px 20px 20px;
  }
`;

export const UploadVideoLinkInstructionButton = styled.a`
  color: #6898e3;
  font-weight: bold;
  text-decoration: underline;
`;

export const LinkInputBox = styled.input`=
  font-family: Noto Sans CJK TC Regular, sans-serif;
  line-height: 18px;
  width: 80%;
  font-size: 16px;
  padding-left: 1%;
  height: 40px;
  margin-bottom: 2%;

  border: 1pt #BBBBBB solid;
  border-style: none none solid none;
  
  :focus{
    border: 1pt black solid;
    border-style: none none solid none;
    outline: none;
  }
  :active{
    border-color: #333333;
  }

  @media screen and (max-width: 600px){
    font-size: 14px;
    width: 80%;
  }
  
`;

export const CentralisingDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
`;

export const ItaticText = styled.div`
  margin: 60px 20px 30px 20px;
  font-style: italic;
  font-size: 16px;

  @media only screen and (max-width: 1000px) {
    font-size: 14px;
  }
`;

// list
export const OrderList = styled.ol`
  padding-inline-start: 20px;
`;
export const UnOrderList = styled.ul`
  padding-inline-start: 20px;
`;

export const VideoListItem = styled.li`
  margin-bottom: 10px;
  &.subListLowerAlpha {
    list-style-type: lower-alpha;
    margin-top: 10px;
  }
  @media only screen and (max-width: 600px) {
    margin-bottom: 26px;
  }
`;

export const VideoListItem2 = styled(VideoListItem)`
  color: red;
`;

export const List = styled.li`
  margin-bottom: 8px;

  &.subListLowerAlpha {
    list-style-type: lower-alpha;
  }
`;

// modularize intro
export const IntroImageContainer = styled.div`
  margin-top: 12px;
  padding: 16px 20px;
  display: flex;
  justify-content: space-evenly;
`;

export const IntroImage = styled.img<{ hide: boolean }>`
  width: 140px;
  height: 280px;
  @media only screen and (max-width: 800px) {
    ${(props) => (props.hide ? "display: none;" : "")}
  }
  @media only screen and (max-width: 400px) {
    width: 115px;
    height: 230px;
  }
`;

// child info page
export const ChildRadioContainer = styled.div`
  display: grid;
  text-align: left;
  height: max-content;
  @media only screen and (max-width: 575px) {
    margin-top: 10px;
  }
  @media only screen and (min-width: 576px) {
    margin-top: 15px;
  }
  @media only screen and (min-width: 768px) {
    margin-top: 15px;
  }
  @media only screen and (min-width: 992px) {
    margin-top: 20px;
  }
  @media only screen and (min-width: 1200px) {
    margin-top: 20px;
  }
`;
export const TextInput = styled.input`
  :: placeholder {
    color: ${(props: { isEmpty: boolean }) =>
      props.isEmpty ? "red!important" : "#9e9e9e!important"};
  }
  &:focus {
    outline: none;
    border-color: #6898e3;
  }
  @media only screen and (max-width: 575px) {
    font-size: 12px !important;
  }
  @media only screen and (min-width: 576px) {
    font-size: 12px !important;
  }
  @media only screen and (min-width: 768px) {
    font-size: 12px !important;
  }
  @media only screen and (min-width: 992px) {
    font-size: 16px !important;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 16px !important;
  }
`;
export const TextArea = styled.textarea`
  height: 60px;
  border-radius: 4px;
  padding: 5px;
  margin-top: 10px;
  border-color: #767676;
  :: placeholder {
    color: ${(props: { isEmpty: boolean }) =>
      props.isEmpty ? "red!important" : "#9e9e9e!important"};
  }
  @media only screen and (max-width: 575px) {
    font-size: 12px !important;
  }
  @media only screen and (min-width: 576px) {
    font-size: 12px !important;
  }
  @media only screen and (min-width: 768px) {
    font-size: 12px !important;
  }
  @media only screen and (min-width: 992px) {
    font-size: 16px !important;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 16px !important;
  }
`;
export const ChildBirthDayContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const RadioLabel = styled.label`
  width: 100%;
  height: max-content;
  margin: 0px 0px 8px 0;
  padding: 15px;
  border-radius: 6px;
  line-height: 20px;
  color: ${(props: { checked: boolean }) =>
    props.checked ? "white" : "#202124"};
  background: ${(props: { checked: boolean }) =>
    props.checked ? "#6898e3" : "white"};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  &:active {
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  :hover {
    cursor: pointer;
    background-color: ${(props: { checked: boolean }) =>
      props.checked ? "#6898e3" : "#6898E309"};
    color: ${(props: { checked: boolean }) =>
      props.checked ? "white" : "#6898E3"};
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  }
`;

// child info and question page
export const RadioInput = styled.input`
  display: none;
`;

// question page
export const QuestionRadioContainer = styled.div`
  display: flex;
  height: max-content;
  justify-content: center;
  margin-top: 3rem;
  width: 100%;
  @media only screen and (min-width: 576px) {
    margin-top: 15px;
  }
  @media only screen and (min-width: 850px) {
    margin-top: 20px;
    justify-content: flex-start;
  }
  @media only screen and (min-width: 992px) {
    margin-top: 25px;
  }
  @media only screen and (min-width: 1200px) {
    margin-top: 25px;
  }
`;

export const YesNoRadioLabel = styled.label`
  height: max-content;
  padding: 1.5rem 4rem;
  border-radius: 10px;
  text-align: center;
  line-height: 20px;
  transition-duration: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  color: ${(props: { checked: boolean }) =>
    props.checked ? "white" : "#333333"};
  background: ${(props: { checked: boolean }) =>
    props.checked ? "#6898e3" : "white"};
  &:active {
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  :hover {
    cursor: pointer;
    background-color: ${(props: { checked: boolean }) =>
      props.checked ? "#6898e3" : "#6898E309"};
    color: ${(props: { checked: boolean }) =>
      props.checked ? "white" : "#6898E3"};
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  }

  @media only screen and (min-width: 850px) {
    padding: 1.5rem 6rem;
    width: 100%;
  }
`;

export const EndEvalPicAndTitleContainer = styled.div`
  grid-row-start: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 20px;
  @media only screen and (max-width: 650px) {
    margin-top: 10px;
  }
`;

export const EndEvalTitle = styled.div`
  color: #202124;
  font-size: 3rem;
  font-weight: 400;
  line-height: 135%;
  display: flex;
  text-align: center;
  justify-content: center;
  width: 80%;
  @media only screen and (max-width: 650px) {
    font-size: 20px;
    font-weight: bold;
  }
`;
export const EndEvalLogo = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 100%;
  object-fit: cover;
  grid-column-start: 1;
  margin-bottom: 20px;

  @media only screen and (max-width: 650px) {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
  }
`;
export const EndEvalSegment = styled.div`
  padding: 24px;
  margin: 12px 0;
  border: 1px solid #dadce0;
  border-radius: 8px;
  background-color: #fff;
  display: grid;
  grid-template-rows: 0.3fr 0.3fr 0.8fr 1rem 5rem;
  @media only screen and (max-width: 650px) {
    margin: 0 10px 0 10px;
    padding: 10px;
    padding-top: 30px;
    grid-template-rows: 0.2fr 0.3fr 1fr 0.1fr 1rem 20rem;
  }
`;

export const EndEvalSegmentText = styled(QuestionText)`
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 60%;
  text-align: center;
  @media only screen and (max-width: 650px) {
    width: 90%;
    text-align: center;
  }
`;

export const EndEvalSegmentTextContainer = styled.div`
  grid-row-start: 2;
  padding: 0 20px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 650px) {
  }
`;

export const EndEvalBigContactsContainer = styled.div`
  grid-row-start: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem 0;

  @media only screen and (min-width: 650px) {
    padding-left: 10rem;
  }
`;
export const EndEvalContactItemContainer = styled.div`
  display: flex;
  width: 30rem;
  margin-bottom: 16px;

  @media only screen and (max-width: 650px) {
    font-size: 12px;
    margin-left: 6px;
    width: 20rem;
  }
`;
export const EndEvalContactIcon = styled.div``;
export const EndEvalContactContent = styled(QuestionText)`
  font-size: 16px;
  margin-left: 16px;

  @media only screen and (max-width: 650px) {
    font-size: 14px;
    margin-left: 10px;
  }
`;
export const EndEvalContactLogo = styled.div`
  grid-row-start: 4;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const EndEvalLink = styled.a`
  text-decoration: none;
  color: #333333;
  transition-duration: 0.3s;
  cursor: pointer;
  &:hover {
    color: #6898e3;
  }
  @media only screen and (max-width: 650px) {
    text-decoration: underline;
  }
`;

export const EndEvalSocialMediaLink = styled.a`
  text-decoration: none;
  color: #333333;
  transition-duration: 0.3s;
  &:hover {
    color: #333333;
  }
`;

export const EndEvalLinkContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const EndEvalButton = styled.button<{
  background: string;
}>`
  border: none;
  border-radius: 5px;
  width: fit-content;
  padding: 13px 20px;
  margin: 0px 20px;
  color: white;
  transition-duration: 0.3s;
  color: #ffffff;
  background: ${(props) => props.background};
  border: 1px solid ${(props) => props.background};
  &:hover {
    cursor: pointer;
    box-shadow: 1px 1px 4px 0px rgba(133, 131, 133, 1);
    background: ${(props) => props.background};
    color: white;
  }

  @media only screen and (max-width: 650px) {
    margin: 10px 0px;
    background: ${(props) => props.background};
    color: white;
    width: 250px;
    padding: 13px 5px;
  }
`;
export const EndEvalButtonContainer = styled.div`
  grid-row-start: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;

  @media only screen and (max-width: 650px) {
    flex-direction: column;
    margin-top: 20px;
  }
`;

export const LoadingContainer = styled.div`
  width: 100wh;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const LoadingText = styled.div`
  font-size: 2vw;

  @media only screen and (max-width: 650px) {
    font-size: 2.5rem;
  }
`;
export const LoadingContainerReport = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 100;
  height: 97%;
  width: 99%;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const LoadingTextReport = styled.div`
  font-size: 2vw;

  @media only screen and (max-width: 650px) {
    font-size: 2.5rem;
  }
`;

export const NoEvalContainer = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: normal;
`;
export const NoEvalTitle = styled.div`
  font-size: 1.6vw;
  line-height: normal;
  @media only screen and (max-width: 750px) {
    font-size: 2.3rem;
  }
`;
export const NoEvalButton = styled.button`
  border: none;
  border-radius: 5px;
  width: 15rem;
  height: 4.2rem;
  background: #6898e3;
  color: #ffffff;
  cursor: pointer;
  margin-top: 50px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
  }
  @media only screen and (max-width: 750px) {
    margin-top: 30px;
    width: 12rem;
  }
`;
