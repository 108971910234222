import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  z-index: 999;
  top: 0%;
  left: 0%;
  width 100%;
  height: 100%;
  display: ${(props: { show: boolean }) => (props.show ? "grid" : "none")};
  place-items: center;
  background: rgba(0, 0, 0, 0.5);
  overscroll-behavior: contain;
`;

export const ModalContent = styled.div`
  position: relative;
  border-radius: 15px;
  background-color: white;
  width: 70%;
  height: 95vh;
  overflow: auto;
`;

export const Header = styled.div`
  padding: 0 10px 0 3%;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #6898e3;
`;

export const CloseButton = styled.div`
  display: grid;
  place-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #6e548f;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
  :hover {
    background: #6e548f;
    color: white;
  }
`;

export const SelectedSkillContainer = styled.div`
  margin: 15px auto;
  width: 95%;
  max-height: 40%;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
`;

export const SelectedSkill = styled.div`
  padding: 10px 5px;
  background: #6898e3;
  color: white;
  font-weight: bold;
  border-radius: 4px;
  margin: 5px;
  cursor: pointer;
  white-space: pre;
`;

export const SkillOptionTitle = styled.div`
  width: 100%;
  padding-left: 3%;
  font-size: 20px;
  color: #6898e3;
`;

export const SkillOptionContainer = styled.div`
  margin: 0 auto 5px auto;
  width: 95%;
  max-height: 40%;
  overflow: auto;
  border: solid 1px #6e548f;
  background: #6e548f;
  display: flex;
  flex-wrap: wrap;
`;

export const SkillOption = styled.div`
  padding: 10px 5px;
  background: white;
  color: #6898e3;
  border: 1px solid #6898e3;
  font-weight: bold;
  border-radius: 4px;
  margin: 5px;
  white-space: pre;
  cursor: pointer;
  :hover {
    background: #6898e3;
    color: white;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
      0 1px 3px 0 rgb(0 0 0 / 12%);
  }
`;

export const AddButton = styled.div`
  position: absolute;
  top: 15px;
  left: 40%;
  display: grid;
  place-items: center;
  height: 40px;
  width: 20%;
  background: white;
  border-radius: 4px;
  color: #6898e3;
  font-size: 16px;
  border: 1px solid #6898e3;
  cursor: pointer;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  &: focus {
    background: white;
    color: #6898e3;
  }
  :hover {
    background: #6898e3;
    color: white;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
      0 1px 3px 0 rgb(0 0 0 / 12%);
  }
`;

export const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 95%;
  margin: 20px auto 0 auto;
`;

export const Tab = styled.div<{ isSelected: boolean }>`
  flex: 1 1 50px;
  background: ${(props) => (props.isSelected ? "#6e548f" : "white")};
display: grid;
place - items: center;
padding: 10px 5px;
border: 1px solid #6e548f;
font - weight: bold;
font - size: 16px;
cursor: pointer;
color: ${(props) => (props.isSelected ? "white" : "#6e548f")};;
  :hover {
  background: #6e548f;
  color: white;
  box - shadow: 0 2px 1px - 1px rgb(0 0 0 / 20 %), 0 1px 1px 0 rgb(0 0 0 / 14 %),
    0 1px 3px 0 rgb(0 0 0 / 12 %);
}
`;
