import gql from "graphql-tag";
import { User } from "../types/User";
import { ReturnMessage } from "../types/ReturnMessage";

export const LOGIN_MUTATION = gql`
  mutation Login(
    $email: String!
    $password: String!
    $eval: Boolean!
    $locale: String!
  ) {
    login(email: $email, password: $password, eval: $eval, locale: $locale) {
      user {
        id
        uuid
        name
        email
        metaType
        phoneNumber
        isOauth
      }
      token
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface LoginData {
  login: {
    user?: User;
    token: string;
    returnMessage: ReturnMessage;
  };
}

export interface LoginVariables {
  email: string;
  password: string;
  eval: boolean;
  locale: string;
}
