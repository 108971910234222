import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Select } from "antd";
import { UserInfo } from "./styles";
import { getAllTzDropDown } from "constants/timeZone";
interface TimeZoneDropdownProps {
  userId: string;
  timeZone: string;
  userUuid: string;
  showNotification: (message: string, status: boolean) => void;
  handleTimeZoneChange: (value: string) => void;
}

interface TimeZoneDropdownStates {
  [key: string]: string | boolean;
  currTimeZone: string;
}

type Props = TimeZoneDropdownProps & WithTranslation;
class TimeZoneDropdown extends React.Component<Props, TimeZoneDropdownStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currTimeZone: "",
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.timeZone !== prevProps.timeZone) {
      this.setState({ currTimeZone: this.props.timeZone });
    }
  };

  componentDidMount = () => {
    this.setState({ currTimeZone: this.props.timeZone });
  };

  timeZoneList = getAllTzDropDown();

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    this.setState({ [id]: value });
  };

  handleChange = (input) => {
    this.setState({ currTimeZone: input });
    this.props.handleTimeZoneChange(input);
  };

  filterOption = (input, option) => {
    const tzText = option.children.toLowerCase();
    return tzText.indexOf(input.toLowerCase()) >= 0;
  };

  render() {
    const { t } = this.props;
    const { currTimeZone } = this.state;
    const { Option } = Select;
    return (
      <UserInfo>
        <Select
          style={{ width: "260px" }}
          showSearch
          placeholder={t("auth.login.chooseTimeZone")}
          value={currTimeZone === "" ? null : currTimeZone}
          onChange={this.handleChange}
        >
          {this.timeZoneList.map((tz) => (
            <Option key={tz.key} value={tz.value}>
              {tz.text}
            </Option>
          ))}
        </Select>
      </UserInfo>
    );
  }
}

export default withTranslation()(TimeZoneDropdown);
