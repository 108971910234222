import { useTranslation } from "react-i18next";
import {
  PurchaseProductContainer,
  PurchaseProductIntroductionContainer,
  PurchaseProductIntroductionTitleText,
  PurchaseProductIntroductionTitleImg,
  PurchaseCheckoutButton,
} from "../styles";
import { PRODUCTS, CURRENCY_LABEL } from "../../../../../constants/common";
import { ProductType } from "../../../Dashboard/BuyUp";
import { useHistory } from "react-router-dom";
import { List, UnOrderList } from "components/User/Evaluation/styles";

type Props = {
  currency: string;
  product: ProductType;
  isBuyUp: boolean;
};

const Core = (props: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const currencyLabel = CURRENCY_LABEL[props.currency];
  const price = props.product["price"][props.currency];

  const handleNext = () => {
    const queryString =
      "?product=" +
      PRODUCTS.Core +
      "&currency=" +
      props.currency +
      "&isBuyUp=" +
      (props.isBuyUp ? "true" : "false");
    history.push("/purchases-payment" + queryString);
  };

  return (
    <PurchaseProductContainer>
      <PurchaseProductIntroductionContainer>
        <div>
          <PurchaseProductIntroductionTitleText>
            {t(`user.purchase.order.product.${PRODUCTS.ModBuyUp}.title`)}
          </PurchaseProductIntroductionTitleText>
          <PurchaseProductIntroductionTitleImg name={PRODUCTS.ModBuyUp} />
          <UnOrderList>
            <List>{t(`user.purchase.order.product.ModBuyUp.des1`)}</List>
            <UnOrderList>
              <List>{t(`user.purchase.order.product.ModBuyUp.des1-1`)}</List>
              <List>{t(`user.purchase.order.product.ModBuyUp.des1-2`)}</List>
              <List>{t(`user.purchase.order.product.ModBuyUp.des1-3`)}</List>
              <List>{t(`user.purchase.order.product.ModBuyUp.des1-4`)}</List>
              <List>{t(`user.purchase.order.product.ModBuyUp.des1-5`)}</List>
            </UnOrderList>
          </UnOrderList>
        </div>
        <div>
          <PurchaseCheckoutButton onClick={handleNext}>
            {t("user.purchase.order.checkout")} {currencyLabel + price}
          </PurchaseCheckoutButton>
        </div>
      </PurchaseProductIntroductionContainer>
    </PurchaseProductContainer>
  );
};

export default Core;
