import React, { useState } from "react";
import { Modal, Form, Input, Button, message } from "antd";
import { useTranslation } from "react-i18next";
import { RESET_PASSWORD_BY_UUID } from "util/auth";

const ResetPasswordModal = ({ visible, onClose, uuid }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      if (values.newPassword !== values.confirmNewPassword) {
        message.error(t("auth.resetPassword.passwordsDoNotMatch"));
        return;
      }
      if (values.newPassword.length < 6) {
        message.error(t("auth.resetPassword.passwordTooShort"));
        return;
      }

      const userData = {
        userUuid: uuid,
        newPassword: values.newPassword,
      };

      const resetPasswordData = await RESET_PASSWORD_BY_UUID(userData);
      message.success(
        resetPasswordData.resetPasswordByUuid.returnMessage.message
      );
      onClose();
    } catch (error: any) {
      console.log("Validate Failed:", error);
      message.error(error.message);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal
      title={t("auth.resetPassword.modalTitle")}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          {t("auth.resetPassword.cancelButton")}
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          {t("auth.resetPassword.submitButton")}
        </Button>,
      ]}
    >
      <Form form={form}>
        <Form.Item
          label={t("auth.resetPassword.newPasswordLabel")}
          name="newPassword"
          rules={[
            {
              required: true,
              message: t("auth.resetPassword.newPasswordMessage"),
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={t("auth.resetPassword.confirmNewPasswordLabel")}
          name="confirmNewPassword"
          rules={[
            {
              required: true,
              message: t("auth.resetPassword.confirmNewPasswordMessage"),
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ResetPasswordModal;
