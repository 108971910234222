import React from "react";
import { match, RouteComponentProps } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import { History, LocationState } from "history";
import axios from "axios";
import mixpanel from "mixpanel-browser";
import api_address from "../../../constants/config";
import { PRODUCTS, CURRENCY } from "../../../constants/common";
import {
  // PurchaseContainer,
  PurchaseContainerTitle,
  PurchaseCheckoutContainer,
  PurchaseCurrencyContainer,
  PurchaseCurrencyInput,
  PurchaseCurrencyInputButton,
  PurchaseCurrencyInputText,
  PurchaseProductContainer,
  // PurchaseProductImg,
  PurchaseProductIntroductionContainer,
  PurchaseProductIntroductionTitleText,
  PurchaseProductIntroductionTitleImg,
  // PurchaseProductIntroductionSubTitle,
  // PurchaseProductIntroductionDescription,
  PurchaseProductIntroductionPrice,
  PurchaseProductSelected,
  PurchaseProductSelectedImg,
  // ContactUsContainer,
  // QRCodeImg,
} from "./styles";
import { CheckoutButton, RouteSectionContainer } from "styles";
import { ContactButton, ContactImg } from "components/general/NavMenu/styles";
import BlueContact from "../../general/NavMenu/assets/BlueContact.svg";
import { List, UnOrderList } from "../Evaluation/styles";

interface OrderProps {
  history: History<LocationState>;
  match?: match<Match>;
}

//url parameters
interface Match {
  product: string;
}

interface ProductInfo {
  id: number;
  name: string;
  price: Map<string, string>;
}

interface OrderState {
  product: string;
  price: string;
  currency: string;
  productInfo: Map<string, ProductInfo> | null;
}

type Props = OrderProps & WithTranslation & RouteComponentProps;

class Order extends React.Component<Props, OrderState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      product: "",
      price: "",
      currency: CURRENCY.cny,
      productInfo: null,
    };
  }

  componentDidMount = async () => {
    const selectedProduct = this.props.match.params.product;
    this.setState({ product: selectedProduct });
    const res = await axios.get(api_address + "api/product", {});
    const data = res.data.data as Map<string, ProductInfo>;
    const defaultCurrency = CURRENCY.cny;
    this.setState({
      productInfo: data,
      price: data[selectedProduct].price[defaultCurrency],
      product: selectedProduct,
      currency: defaultCurrency,
    });
    window.scrollTo(0, 0);
  };

  onCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currency = event.currentTarget.id;
    const { productInfo, product } = this.state;
    const price = productInfo ? productInfo[product]["price"][currency] : "0";
    this.setState({
      currency: currency,
      price: price,
    });
    mixpanel.track("Product", {
      action: "change currency",
      from: this.state.currency,
      to: currency,
    });
  };

  handleClick = (product: string) => {
    const { productInfo, currency } = this.state;
    if (!productInfo) return;
    const price = productInfo[product].price[currency];
    this.setState({
      product,
      price,
    });
    mixpanel.track("Product", {
      action: "change product",
      from: this.state.product,
      to: product,
    });
  };

  handleNext = () => {
    const { product, currency } = this.state;
    mixpanel.track("Product", {
      action: "click checkout button",
    });
    const queryString = "?product=" + product + "&currency=" + currency;
    this.props.history.push("/purchases-payment" + queryString);
  };

  onContactClick = () => {
    this.props.history.push("/contactUs/Issues");
  };

  render() {
    const { t } = this.props;
    const { product, productInfo, currency, price } = this.state;
    const { onCurrencyChange } = this;

    const corePrice = productInfo
      ? productInfo[PRODUCTS.Core].price[currency]
      : 0;
    const coreName = productInfo ? productInfo[PRODUCTS.Core].name : "";

    const coreProPrice = productInfo
      ? productInfo[PRODUCTS.CorePro].price[currency]
      : 0;
    const coreProName = productInfo ? productInfo[PRODUCTS.CorePro].name : "";

    const PEAName = productInfo ? productInfo[PRODUCTS.PEA].name : "";

    const currencyPresent = currency === CURRENCY.cny ? "¥" : "$";

    return (
      <RouteSectionContainer>
        <PurchaseContainerTitle>
          {t("user.purchase.order.title")}
        </PurchaseContainerTitle>
        <PurchaseCurrencyContainer>
          <form action="#">
            <PurchaseCurrencyInput>
              <p>
                <label htmlFor={CURRENCY.cny}>
                  <PurchaseCurrencyInputButton
                    type="radio"
                    id={CURRENCY.cny}
                    name="currency"
                    onChange={onCurrencyChange}
                    defaultChecked
                  />
                  <PurchaseCurrencyInputText>
                    {t("user.purchase.order.currency.cny")}
                  </PurchaseCurrencyInputText>
                </label>
              </p>
            </PurchaseCurrencyInput>
            <PurchaseCurrencyInput>
              <label htmlFor={CURRENCY.usd}>
                <PurchaseCurrencyInputButton
                  type="radio"
                  id={CURRENCY.usd}
                  name="currency"
                  value={CURRENCY.usd}
                  onChange={onCurrencyChange}
                />
                <PurchaseCurrencyInputText>
                  {t("user.purchase.order.currency.usd")}
                </PurchaseCurrencyInputText>
              </label>
            </PurchaseCurrencyInput>
            <PurchaseCurrencyInput>
              <label htmlFor={CURRENCY.twd}>
                <PurchaseCurrencyInputButton
                  type="radio"
                  id={CURRENCY.twd}
                  name="currency"
                  onChange={onCurrencyChange}
                />
                <PurchaseCurrencyInputText>
                  {t("user.purchase.order.currency.twd")}
                </PurchaseCurrencyInputText>
              </label>
            </PurchaseCurrencyInput>
          </form>
        </PurchaseCurrencyContainer>
        <PurchaseProductContainer
          isSelect={coreName === product}
          onClick={() => {
            this.handleClick(coreName);
          }}
        >
          <PurchaseProductIntroductionContainer>
            <PurchaseProductIntroductionTitleText>
              {t("user.purchase.order.product.Core.title")}
            </PurchaseProductIntroductionTitleText>
            <PurchaseProductIntroductionTitleImg name={coreName} />
            <UnOrderList>
              <List>{t(`user.purchase.order.product.ModBuyUp.des1`)}</List>
              <UnOrderList>
                <List>{t(`user.purchase.order.product.Core.des1-1`)}</List>
                <List>{t(`user.purchase.order.product.Core.des1-2`)}</List>
                <List>{t(`user.purchase.order.product.Core.des1-3`)}</List>
                <List>{t(`user.purchase.order.product.Core.des1-4`)}</List>
                <List>{t(`user.purchase.order.product.Core.des1-5`)}</List>
              </UnOrderList>
            </UnOrderList>
            <PurchaseProductIntroductionPrice>
              {currencyPresent + corePrice}
            </PurchaseProductIntroductionPrice>
          </PurchaseProductIntroductionContainer>
          <PurchaseProductSelected>
            <PurchaseProductSelectedImg selected={coreName === product} />
          </PurchaseProductSelected>
        </PurchaseProductContainer>
        <PurchaseProductContainer
          isSelect={coreProName === product}
          onClick={() => this.handleClick(coreProName)}
        >
          <PurchaseProductIntroductionContainer>
            <PurchaseProductIntroductionTitleText>
              {t("user.purchase.order.product.CorePro.title")}
            </PurchaseProductIntroductionTitleText>
            <PurchaseProductIntroductionTitleImg name={coreProName} />
            <UnOrderList>
              <List>{t(`user.purchase.order.product.CorePro.des1`)}</List>
              <List>{t(`user.purchase.order.product.CorePro.des2`)}</List>
              <List>{t(`user.purchase.order.product.CorePro.des3`)}</List>
            </UnOrderList>
            <PurchaseProductIntroductionPrice>
              {currencyPresent + coreProPrice}
            </PurchaseProductIntroductionPrice>
          </PurchaseProductIntroductionContainer>
          <PurchaseProductSelected>
            <PurchaseProductSelectedImg selected={coreProName === product} />
          </PurchaseProductSelected>
        </PurchaseProductContainer>
        <PurchaseProductContainer isSelect={product === PEAName}>
          <PurchaseProductIntroductionContainer>
            <PurchaseProductIntroductionTitleText>
              {t("user.purchase.order.product.ParentEducationAcademy.title")}
            </PurchaseProductIntroductionTitleText>
            <PurchaseProductIntroductionTitleImg name={coreProName} />
            <UnOrderList>
              <List>
                {t(`user.purchase.order.product.ParentEducationAcademy.des1`)}
              </List>
              <List>
                {t(`user.purchase.order.product.ParentEducationAcademy.des2`)}
              </List>
              <List>
                {t(`user.purchase.order.product.ParentEducationAcademy.des3`)}
              </List>
              <List>
                {t(`user.purchase.order.product.ParentEducationAcademy.des4`)}
              </List>
              <List>
                {t(`user.purchase.order.product.ParentEducationAcademy.des5`)}
              </List>
              <List>
                {t(`user.purchase.order.product.ParentEducationAcademy.des6`)}
              </List>
            </UnOrderList>
          </PurchaseProductIntroductionContainer>
          <ContactButton onClick={this.onContactClick}>
            <ContactImg src={BlueContact} />
            {t("general.navbar.contact")}
          </ContactButton>
        </PurchaseProductContainer>
        <PurchaseCheckoutContainer>
          <CheckoutButton onClick={this.handleNext}>
            {t("user.purchase.order.checkout")}
            {"   " + currencyPresent + price}
          </CheckoutButton>
        </PurchaseCheckoutContainer>
      </RouteSectionContainer>
    );
  }
}

export default withTranslation()(Order);
