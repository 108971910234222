import { useTranslation } from "react-i18next";
import { GET_LANGUAGE } from "../../../../util/common";
import WeChatQR from "../../assets/QR/WeChatZhuJiao.jpeg";
import MessengerIcon from "../../assets/Icon/messenger.svg";
import EmailIcon from "../../assets/Icon/email.svg";
import {
  MESSENGER_LINK_TW,
  MESSENGER_LINK_US,
  EMAIL_LINK,
} from "../../../../constants/common";
import {
  ContactUsTitle,
  ContactUsText,
  ContactUsTextFAQ,
  Icon,
  QRCode,
  Container,
  SocialMediaContainer,
  FAQContainer,
} from "../styles";

const SocialLinks = (props: { language: string }) => {
  switch (props.language) {
    case "en":
      return (
        <SocialMediaContainer>
          <Icon
            src={MessengerIcon}
            alt="messenger"
            onClick={() => window.open(MESSENGER_LINK_US)}
          />
          <a href={EMAIL_LINK}>
            <Icon src={EmailIcon} alt="email" />
          </a>
        </SocialMediaContainer>
      );
    case "zh-CN":
      return <QRCode src={WeChatQR} alt="weChat" />;
    case "zh-TW":
      return (
        <SocialMediaContainer>
          <Icon
            src={MessengerIcon}
            alt="messenger"
            onClick={() => window.open(MESSENGER_LINK_TW)}
          />
          <a href={EMAIL_LINK}>
            <Icon src={EmailIcon} alt="email" />
          </a>
        </SocialMediaContainer>
      );
    default:
      return (
        <SocialMediaContainer>
          <Icon
            src={MessengerIcon}
            alt="messenger"
            onClick={() => window.open(MESSENGER_LINK_US)}
          />
          <a href={EMAIL_LINK}>
            <Icon src={EmailIcon} alt="email" />
          </a>
        </SocialMediaContainer>
      );
  }
};

export const FAQ = () => {
  const { t } = useTranslation();
  const language = GET_LANGUAGE();

  const onFAQClick = () => {
    window.open("/FAQ", "_blank");
  };

  return (
    <Container>
      <FAQContainer>
        <ContactUsTitle>{t("general.contactUs.FAQ.title")}</ContactUsTitle>
        <ContactUsText>
          {t("general.contactUs.FAQ.text1")}
          <ContactUsTextFAQ onClick={onFAQClick}>
            {t("general.contactUs.FAQ.FAQ")}
          </ContactUsTextFAQ>
          {t("general.contactUs.FAQ.text2")}
        </ContactUsText>
        <SocialLinks language={language} />
      </FAQContainer>
    </Container>
  );
};
