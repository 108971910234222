import {
  REQUEST_QUESTIONNAIRES,
  RECEIVE_QUESTIONNAIRES,
  RECEIVE_QUESTIONNAIRE_RESULT,
  ANSWER_QUESTION,
} from "../constants/actionType";

interface Option {
  id: number;
  key: string;
  value: string;
  score: number;
}

interface Question {
  id: number;
  title: string;
  single: boolean;
  image: string | null;
  options: Option[];
}

interface Milestone {
  name: string;
  text: string;
}

interface Questionnaire {
  id: number;
  title: string;
  description: string;
  duration: number;
  imageUrl: string;
  milestone: Milestone[];
  questions: Question[];
}

interface StateType {
  list: Questionnaire[];
  loading: boolean;
  finalScore: number;
}

const INITIAL_STATE: StateType = {
  list: [],
  loading: false,
  finalScore: 0,
};

export default function questionnaires(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_QUESTIONNAIRES:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_QUESTIONNAIRES:
      return {
        list: action.payload,
        loading: false,
      };
    case RECEIVE_QUESTIONNAIRE_RESULT:
      return {
        ...state,
        loading: false,
        finalScore: action.payload.finalScore,
      };
    case ANSWER_QUESTION: {
      const { questionnaireId, questionId, selectedIds } = action.payload;
      return {
        ...state,
        list: state.list.map((questionnaire) => {
          if (questionnaire.id === questionnaireId) {
            return {
              ...questionnaire,
              questions: questionnaire.questions.map((question) => {
                if (question.id === questionId) {
                  return {
                    ...question,
                    selectedIds,
                  };
                }
                return question;
              }),
            };
          }
          return questionnaire;
        }),
      };
    }
    default:
      return state;
  }
}
