import Therapist from "components/Therapist/CasePanel";
import ReportGeneration from "components/Therapist/ReportGeneration";
import Profile from "components/User/ProfileRoute";
import Account from "components/auth/Account";
import FullResult from "components/Therapist/fullResult";
import CoreProReport from "components/User/Evaluation/CorePro/CoreProReport";
import Questionnaire from "components/User/Evaluation/Core";
import CoreReport from "components/User/Evaluation/Core/CoreReport";
import NavMenu from "components/general/NavMenu";

import { Redirect, Route, Switch } from "react-router";
import ResultAccessFromGuide from "components/Therapist/ResultAccessFromGuide";
import PublicRoute from "./PublicRoute";

const TherapistRoute = () => {
  return (
    <>
      <NavMenu />
      <Switch>
        <Route exact path="/">
          <Redirect to="/therapist" />
        </Route>
        <Route
          path="/getResultByUserUuid/:userUuid"
          component={ResultAccessFromGuide}
        />
        <Route path="/therapist" component={Therapist} />
        <Route
          path="/report-generation/:resultId"
          component={ReportGeneration}
        />
        <Route path="/profile" component={Profile} />
        <Route path="/account-setting" component={Account} />
        <Route path="/full-result/:resultId" component={FullResult} />
        <Route path="/core-report/:transactionId" component={CoreReport} />
        <Route path="/core-pro-report/:resultId" component={CoreProReport} />
        <Route path="/questionnaire/" component={Questionnaire} />
        <PublicRoute />
      </Switch>
    </>
  );
};

export default TherapistRoute;
