import { Component } from "react";
import {
  useTranslation,
  withTranslation,
  WithTranslation,
} from "react-i18next";
import { History, LocationState } from "history";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "types/actions";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { startUpdateUserLanguage } from "../../auth/actions";
import { Dropdown, DropdownProps, Header, Icon } from "semantic-ui-react";
import { GET_USER_INFO, UPDATE_USER_TIME_ZONE } from "../../../util/common";
import { EDIT_CLIENT_INFORMATION } from "graphql/mutations/editClientInformation";
import { EDIT_USER_INFORMATION } from "graphql/mutations/editUserInformation";
import { client } from "graphql/ApolloClient";
import { getAllTzDropDown } from "constants/timeZone";

interface TimeZoneDropdownProps {
  path: string;
  history: History<LocationState>;
  color: string;
  handleStatus: (string, boolean) => void;
}

interface TimeZoneDropdownState {
  timeZone: string;
  userId: number;
  userUuid: number;
  maxWidth: string;
}

type Props = TimeZoneDropdownProps &
  WithTranslation &
  LinkDispatchProps &
  RouteComponentProps;

class TimeZoneDropdown extends Component<Props, TimeZoneDropdownState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      timeZone: "",
      userId: 0,
      userUuid: 0,
      maxWidth: "120px",
    };
    this.getUserInfo();
  }

  updateMaxWidth = () => {
    if (window.innerWidth >= 768) {
      this.setState({ maxWidth: "250px" });
    } else {
      this.setState({ maxWidth: "120px" });
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateMaxWidth);
    this.updateMaxWidth();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateMaxWidth);
  }

  componentDidUpdate(prevProps: Props, prevState: TimeZoneDropdownState) {
    if (prevState.timeZone !== this.state.timeZone) {
      this.getUserInfo();
    }
  }

  updateUserOnGuide = async (userUuid, timeZone) => {
    await client.mutate({
      mutation: EDIT_CLIENT_INFORMATION,
      variables: {
        userUuid: userUuid,
        attribute: "Time Zone",
        value: timeZone,
      },
    });
  };

  handleChange = (
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    const timeZone = data.value?.toString() ?? "";
    this.setState({ timeZone: timeZone });
    this.updateUserInfo(timeZone);
    this.updateUserOnGuide(this.state.userUuid, timeZone);
  };

  updateUserInfo = async (timeZone) => {
    const response = await UPDATE_USER_TIME_ZONE(timeZone);
    if (response.status) {
      console.log("success");
      this.props.handleStatus(
        this.props.t("message.account.saveSuccessful", {
          attribute: "Time Zone",
        }),
        response.status
      );
    } else {
      alert("error");
    }
  };

  getUserInfo = async () => {
    const userData = await GET_USER_INFO();
    this.setState({
      timeZone: userData.timeZone,
      userId: userData.id,
      userUuid: userData.uuid,
    });
  };

  render = () => {
    const { t } = this.props;
    const { timeZone, maxWidth } = this.state;

    return (
      <Header>
        <Header.Content>
          <Dropdown
            placeholder={t("auth.login.chooseTimeZone")}
            fluid
            selection
            search
            value={timeZone ? timeZone : ""}
            onChange={this.handleChange}
            options={getAllTzDropDown()}
            style={{ maxWidth: maxWidth }}
          />
        </Header.Content>
      </Header>
    );
  };
}

interface LinkDispatchProps {
  onUpdateLanguage: (data: string) => void;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): LinkDispatchProps => ({
  onUpdateLanguage: bindActionCreators(startUpdateUserLanguage, dispatch),
});

export default withRouter(
  connect(null, mapDispatchToProps)(withTranslation()(TimeZoneDropdown))
);
