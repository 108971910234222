import gql from "graphql-tag";
import { User } from "../types/User";

export const VIEWER_QUERY = gql`
  query Viewer($eval: Boolean) {
    viewer(eval: $eval) {
      id
      uuid
      token
      firstName
      lastName
      name
      email
      locale
      phoneNumber
      metaId
      metaType
    }
  }
`;

export interface Data {
  viewer: User;
}
