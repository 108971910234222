import gql from "graphql-tag";

export const EDIT_CLIENT_INFORMATION = gql`
  mutation EditClientInformationByUuid(
    $userUuid: ID!
    $attribute: String!
    $value: String!
  ) {
    editClientInformationByUuid(
      userUuid: $userUuid
      attribute: $attribute
      value: $value
    ) {
      client {
        id
        timeZone
      }
      returnMessage {
        status
        message
      }
    }
  }
`;
