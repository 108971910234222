import styled from "styled-components";

export const DayAndHour = styled.div`
  border-radius: 4px;
  margin: 0 10px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
`;

export const DayAndHourText = styled.div`
  margin: 0 5px;
  line-height: 50px;
  font-size: 20px;
  white-space: pre;
`;

export const DiscountContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border: solid 1px #6898e3;
  border-radius: 10px;
  margin: 10px 0;
`;

export const DiscountTitle = styled.div`
  width: 100%;
  text-align: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #6898e3;
  color: #ffffff;
  font-size: 20px;
  height: 36px;
  line-height: 36px;
`;

export const ApplyContainer = styled.div`
  margin-top: 10px;
  border: solid 1px #6898e3;
  border-radius: 10px;
`;

export const ApplyTitle = styled.div`
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
  color: black;
  font-size: 20px;
  height: 36px;
  line-height: 36px;
`;

export const DiscountLabel = styled.div`
  width: 100%;
  margin: 0 20px;
  border-radius: 15px;
  height: 44px;
  line-height: 44px;
  font-size: 20px;
  text-align: center;
  background-color: ${(props: { checked: boolean }) =>
    props.checked ? "#6898E3" : "#E0E0E0"};
  color: #ffffff;
  :hover {
    cursor: pointer;
  }
`;

export const DiscountInfoContainer = styled.div`
  width: 100%;
  margin: 0px 20px;
  border-radius: 15px;
  border: solid 1px
    ${(props: { checked: boolean }) => (props.checked ? "#6898E3" : "#E5E5E5")};
  background-color: ${(props: { checked: boolean }) =>
    props.checked ? "white" : "#FFFFFF"};
  height: fit-content;
`;

export const DiscountInfoSubtitle = styled.label`
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  margin: 0 15px;
`;

export const DiscountInfoPostfix = styled.label`
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  margin: 0 10px;
`;

export const DiscountInfoInput = styled.input`
  width: 50px;
  border-radius: 8px; /* Adjust the border-radius as needed */
  border: 1px solid #6898e3; /* Add the border with the specified color */
  padding: 5px; /* Add some padding for a better look */
  outline: none; /* Remove the default outline */

  &:focus {
    border-color: #467bc1; /* Optional: Change the border color when focused */
  }
`;

export const ApplyToContainer = styled.div`
  border: solid 1px #6898e3;
  border-radius: 4px;
  margin: 5px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const ApplyToOption = styled.div`
  flex: 1 1 150px;
  font-size: 20px;
  margin: 5px 10px; /* Increase horizontal margin */
  padding: 0 20px; /* Increase horizontal padding */
  text-align: center;
  height: 50px;
  display: grid;
  place-items: center;
  cursor: pointer;
  border-radius: 4px;
`;

export const RedeemByButton = styled.div`
  margin: 0 auto;
  width: 250px;
  height: 50px;
  background-color: white;
  border: solid 1px #6898e3;
  color: #000000;
  font-size: 20px;
  display: grid;
  place-items: center;
  border-radius: 10px;
  :hover {
    cursor: pointer;
  }
`;

export const DescriptionTextArea = styled.textarea`
  width: 100%;
  height: 150px;
  borderradius: 8px;
  border: 1px solid #6898e3;
  padding: 10px;
  resize: none;
  outline: none;
`;
