const countryCodes = {
  US: "+1",
  CA: "+1",
  DO: "+1",
  RU: "+7",
  EG: "+20",
  ZA: "+27",
  GR: "+30",
  NL: "+31",
  BE: "+32",
  FR: "+33",
  ES: "+34",
  HU: "+36",
  IT: "+39",
  CH: "+41",
  AT: "+43",
  GB: "+44",
  DK: "+45",
  SE: "+46",
  NO: "+47",
  DE: "+49",
  MX: "+52",
  AR: "+54",
  BR: "+55",
  CL: "+56",
  CO: "+57",
  MY: "+60",
  AU: "+61",
  ID: "+62",
  PH: "+63",
  NZ: "+64",
  SG: "+65",
  TH: "+66",
  KR: "+82",
  JP: "+81",
  VN: "+84",
  CN: "+86",
  TR: "+90",
  IN: "+91",
  PK: "+92",
  MM: "+95",
  NG: "+234",
  PT: "+351",
  IE: "+353",
  IS: "+354",
  FI: "+358",
  MC: "+377",
  PA: "+507",
  HK: "+852",
  MO: "+853",
  KH: "+855",
  TW: "+886",
  SA: "+966",
  AE: "+971",
  IL: "+972",
  QA: "+974",
  MN: "+976",
  NP: "+977",
};

export const getCountryInitial = (countryCode) => {
  for (const [key, value] of Object.entries(countryCodes)) {
    if (value === countryCode) {
      return key;
    }
  }
  return null; // Country code not found
};

export const getCountryCodeList = (t) => {
  return Object.entries(countryCodes).map((countryCode: Array<string>) => {
    return {
      text:
        t(`utils.countryCode.${countryCode[0]}`) + "\t(" + countryCode[1] + ")",
      value: countryCode[1],
    };
  });
};
