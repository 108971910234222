import { Component } from "react";
import { match } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import api_address from "../../../../constants/config";
import { History, LocationState } from "history";
import NotificationModal from "../../../common/NotificationModal";
import { ChevronLeft, CheckIcon, PencilIcon } from "lucide-react";
import {
  Container,
  ContentContainer,
  ContentSubtitle,
  Input,
  SaveButton,
  CancelButton,
  BackButton,
  CreateButton,
  UtcNotice,
} from "../../styles";
import {
  DiscountContainer,
  DiscountTitle,
  DayAndHour,
  DayAndHourText,
  RedeemByButton,
  DescriptionTextArea,
} from "../styles";
import { SubContainer, UserContainer } from "./style";
import moment from "moment";
import { DAYS_AND_HOURS_BETWEEN_TWO_DATE } from "../../../../../src/util/common";
import { JS_COOKIE } from "util/auth";
import { ADMIN_COOKIE_NAME } from "constants/admin";
import ReferralLinkModal from "components/common/ReferralLinkModal";
import { Table, Button } from "antd";
import { PencilButton } from "../../styles";

interface productType {
  id: string;
  name: string;
}

interface Match {
  id: string;
}

interface CouponEditProps {
  match: match<Match>;
  history: History<LocationState>;
}

interface CouponEditStates {
  [key: string]:
    | string
    | number
    | Array<productType>
    | Array<number>
    | boolean
    | undefined;
  amountOff: number;
  applyAll: boolean;
  applyTo: Array<productType>;
  couponCode: string;
  currency: string;
  discountType: string;
  maxDiscount: number;
  maxRedemption: number;
  currentRedemption: number;
  offRate: string;
  partnerId: number;
  hasRedeemBy: boolean;
  redeemBy: string | undefined;
  redeemByDays: number;
  redeemByHours: number;
  redeemStart: string;
  message: string;
  showModal: boolean;
  notificationStatus: boolean;
  showUsers: boolean;
  users: Array<any>; // Updated to include user objects
  showReferralLinkModal: boolean; // Added to manage modal visibility
  comment: string;
  isDescriptionEditable: boolean; // Editable state for description
  tempDescription: string; // Temporary description value
  isRedeemByEditable: boolean; // Editable state for redeemBy
  tempRedeemBy: string; // Temporary redeemBy value
}

type Props = CouponEditProps & WithTranslation;

class CouponEdit extends Component<Props, CouponEditStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      amountOff: 0,
      applyAll: false,
      applyTo: [],
      couponCode: "",
      currency: "",
      discountType: "",
      maxDiscount: 0,
      maxRedemption: 0,
      currentRedemption: 0,
      offRate: "",
      partnerId: 0,
      hasRedeemBy: false,
      redeemBy: "",
      redeemByDays: 0,
      redeemByHours: 0,
      redeemStart: "",
      message: "",
      showModal: false,
      notificationStatus: false,
      showUsers: false,
      users: [],
      showReferralLinkModal: false,
      comment: "",
      isDescriptionEditable: false,
      tempDescription: "",
      isRedeemByEditable: false,
      tempRedeemBy: "",
    };
  }

  componentDidMount = async () => {
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { id } = this.props.match.params;
      const res = await axios.get(api_address + "api/admin/coupon/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { redeemBy, redeemStart } = res.data.coupon;

      const { daysFromNow, hoursFromNow } = DAYS_AND_HOURS_BETWEEN_TWO_DATE(
        new Date().toUTCString(),
        redeemBy
      );
      this.setState({
        ...res.data.coupon,
        hasRedeemBy: redeemBy ? true : false,
        redeemBy: redeemBy && moment(redeemBy).format("YYYY-MM-DDThh:mm"),
        redeemByDays: redeemBy ? daysFromNow : 0,
        redeemByHours: redeemBy ? hoursFromNow : 0,
        redeemStart:
          redeemStart && moment(redeemStart).format("YYYY-MM-DDThh:mm"),
      });
    } catch (err: any) {
      this.showNotification(err.message, false);
    }
  };

  handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = event.currentTarget;
    if (id === "comment") {
      this.setState({ [id]: value });
    } else if (`${Number(value)}` === value) {
      this.setState({ [id]: +value });
    }
  };

  handleRedeemByChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    this.setState({ tempRedeemBy: value });
  };

  showNotification = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  enableRedeemByClick = () => {
    this.setState({
      hasRedeemBy: true,
    });
  };

  disableRedeemByClick = () => {
    this.setState({
      hasRedeemBy: false,
      redeemBy: undefined,
    });
  };

  onCancelClick = () => {
    this.props.history.push("/admin/coupon");
  };

  onSaveClick = async () => {
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { id } = this.props.match.params;
      const {
        redeemBy,
        maxRedemption,
        applyAll,
        applyTo,
        hasRedeemBy,
        comment,
      } = this.state;
      const res = await axios.put(
        api_address + "api/admin/coupon/" + id,
        {
          redeemBy: hasRedeemBy ? redeemBy : undefined,
          maxRedemption,
          applyAll,
          applyTo,
          comment,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { message, status } = res.data;
      this.showNotification(message, status);
    } catch (error: any) {
      this.showNotification(error.message, false);
    }
  };

  // Editing functionality
  handleFieldEdit = (field: string) => {
    this.setState({
      [`is${field}Editable`]: true,
      [`temp${field}`]: this.state[field.toLowerCase()],
    });
  };

  handleFieldSave = async (field: string, apiField: string) => {
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { id } = this.props.match.params;
      const tempValue = this.state[`temp${field}`];
      const res = await axios.put(
        `${api_address}api/admin/coupon/${id}`,
        { [apiField]: tempValue },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.data.status) {
        this.setState({
          [apiField]: tempValue,
          [`is${field}Editable`]: false,
        });
        this.showNotification(`${field} updated successfully`, true);
      } else {
        throw new Error(res.data.message);
      }
    } catch (error: any) {
      this.showNotification(error.message, false);
    }
  };

  handleFieldCancel = (field: string) => {
    this.setState({
      [`is${field}Editable`]: false,
      [`temp${field}`]: "",
    });
  };

  showAllUsers = async () => {
    if (this.state.showUsers) {
      // If the table is already visible, hide it
      this.setState({ showUsers: false });
    } else {
      try {
        const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
        const { id } = this.props.match.params;
        const res = await axios.get(
          api_address + "api/admin/coupon/getUserByCoupon/" + id,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.setState({ users: res.data.users, showUsers: true });
      } catch (error: any) {
        this.showNotification(error.message, false);
      }
    }
  };

  handleOpenReferralLinkModal = () => {
    this.setState({ showReferralLinkModal: true });
  };

  handleCloseReferralLinkModal = () => {
    this.setState({ showReferralLinkModal: false });
  };

  render = () => {
    const { t } = this.props;
    const { id } = this.props.match.params;
    const {
      showUsers,
      users,
      hasRedeemBy,
      showModal,
      message,
      notificationStatus,
      amountOff,
      applyAll,
      applyTo,
      couponCode,
      currency,
      discountType,
      maxDiscount,
      maxRedemption,
      currentRedemption,
      offRate,
      redeemBy,
      redeemStart,
      redeemByDays,
      redeemByHours,
      showReferralLinkModal,
      comment,
      isDescriptionEditable,
      tempDescription,
      isRedeemByEditable,
      tempRedeemBy,
    } = this.state;

    const applyAllText = applyAll ? "Yes" : "No";
    const applyToText = applyAll
      ? "All"
      : applyTo
          .map((product: productType) => t(`admin.product.${product.name}`))
          .join(", ");

    const columns = [
      {
        title: t("admin.coupon.userId"),
        dataIndex: "userId",
        key: "userId",
        render: (userId) => (
          <Button
            type="link"
            onClick={() =>
              this.props.history.push("/admin/user/edit/" + userId)
            }
          >
            {userId}
          </Button>
        ),
      },
      {
        title: t("admin.coupon.firstName"),
        dataIndex: "firstName",
        key: "firstName",
      },
      {
        title: t("admin.coupon.lastName"),
        dataIndex: "lastName",
        key: "lastName",
      },
      {
        title: t("admin.coupon.updatedAt"),
        dataIndex: "updatedAt",
        key: "updatedAt",
        render: (updatedAt) => moment(updatedAt).format("YYYY-MM-DD HH:mm:ss"),
      },
    ];

    return (
      <Container>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />
        <UtcNotice>{t("admin.result.utcNote")}</UtcNotice>
        <BackButton onClick={this.onCancelClick} style={{ marginTop: "20px" }}>
          <ChevronLeft />
        </BackButton>
        <ContentSubtitle>Coupon Details</ContentSubtitle>
        <Button
          style={{
            color: "white",
            backgroundColor: "#6898E3",
            position: "absolute",
            top: "10px",
            right: "150px",
            zIndex: 1,
          }}
          key="link"
          onClick={this.handleOpenReferralLinkModal}
        >
          {t("admin.coupon.link")}
        </Button>
        <Button
          style={{
            color: "white",
            backgroundColor: "#CB0000",
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: 1,
          }}
          onClick={this.disableRedeemByClick}
        >
          {t("admin.coupon.disableRedeemBy")}
        </Button>
        <DiscountContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.coupon.id")}</ContentSubtitle>
            <Input disabled={true} value={id} />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.coupon.couponCode")}</ContentSubtitle>
            <Input disabled={true} value={couponCode} />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.coupon.currency")}</ContentSubtitle>
            <Input disabled={true} value={currency} />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.coupon.products")}</ContentSubtitle>
            <Input disabled={true} value={applyToText} />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.coupon.description")}</ContentSubtitle>
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Input
                disabled={!isDescriptionEditable}
                onChange={(e) =>
                  this.setState({ tempDescription: e.target.value })
                }
                value={isDescriptionEditable ? tempDescription : comment}
                style={{ flex: 1, marginRight: "40px" }}
              />
              <PencilButton
                onClick={
                  isDescriptionEditable
                    ? () => this.handleFieldSave("Description", "comment")
                    : () => this.handleFieldEdit("Description")
                }
                isEditing={isDescriptionEditable}
                style={{
                  right: "5px",
                }}
              >
                {isDescriptionEditable ? (
                  <CheckIcon
                    className="h-5 w-5 text-white"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                ) : (
                  <PencilIcon
                    className="h-5 w-5 text-white"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                )}
              </PencilButton>
            </div>
          </ContentContainer>

          <ContentContainer>
            <ContentSubtitle>{t("admin.coupon.redemption")}</ContentSubtitle>
            <Input
              disabled={true}
              value={`${currentRedemption} / ${maxRedemption}`}
            />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.coupon.discountType")}</ContentSubtitle>
            <Input disabled={true} value={discountType} />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>
              {discountType === "percent"
                ? t("admin.coupon.percentOff")
                : t("admin.coupon.amountOff")}
            </ContentSubtitle>
            <Input
              disabled={true}
              value={
                discountType === "percent"
                  ? `${Number(offRate) * 100}%`
                  : `${amountOff} ${currency}`
              }
            />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.coupon.maxDiscount")}</ContentSubtitle>
            <Input disabled={true} value={`${maxDiscount} ${currency}`} />
          </ContentContainer>
        </DiscountContainer>
        {hasRedeemBy ? (
          <SubContainer>
            <ContentContainer>
              <ContentSubtitle>{t("admin.coupon.redeemBy")}</ContentSubtitle>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Input
                  id="redeemBy"
                  type="datetime-local"
                  disabled={!isRedeemByEditable}
                  onChange={this.handleRedeemByChange}
                  value={isRedeemByEditable ? tempRedeemBy : redeemBy}
                  style={{ flex: 1, marginRight: "40px" }}
                />
                <PencilButton
                  onClick={
                    isRedeemByEditable
                      ? () => this.handleFieldSave("RedeemBy", "redeemBy")
                      : () => this.handleFieldEdit("RedeemBy")
                  }
                  isEditing={isRedeemByEditable}
                  style={{
                    position: "absolute",
                    right: "5px",
                  }}
                >
                  {isRedeemByEditable ? (
                    <CheckIcon
                      className="h-5 w-5 text-white"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  ) : (
                    <PencilIcon
                      className="h-5 w-5 text-white"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  )}
                </PencilButton>
              </div>
            </ContentContainer>

            <ContentContainer>
              <DayAndHour>{redeemByDays}</DayAndHour>
              <DayAndHourText>{t("admin.coupon.days")}</DayAndHourText>
              <DayAndHour>{redeemByHours}</DayAndHour>
              <DayAndHourText>{t("admin.coupon.hours")}</DayAndHourText>
            </ContentContainer>
          </SubContainer>
        ) : (
          <RedeemByButton onClick={this.enableRedeemByClick}>
            {t("admin.coupon.activateCoupon")}
          </RedeemByButton>
        )}
        <DiscountContainer>
          <DiscountTitle>
            {t("admin.coupon.userWhoUsedThisCoupon")}
          </DiscountTitle>
          {showUsers ? (
            <UserContainer style={{ display: "flex", flexDirection: "column" }}>
              <Button
                onClick={this.showAllUsers}
                style={{ marginBottom: "15px" }}
              >
                {t("admin.coupon.closeTable")}
              </Button>
              {users && users.length > 0 ? (
                <Table
                  columns={columns}
                  dataSource={users}
                  rowKey="userId"
                  pagination={{ pageSize: 5 }}
                />
              ) : (
                <p>{t("admin.coupon.noUsersFound")}</p>
              )}
            </UserContainer>
          ) : (
            <ContentContainer>
              <RedeemByButton onClick={this.showAllUsers}>
                {t("admin.coupon.showAllUsers")}
              </RedeemByButton>
            </ContentContainer>
          )}
        </DiscountContainer>
        <ReferralLinkModal
          showReferralLinkModal={showReferralLinkModal}
          handleCloseReferralLinkModal={this.handleCloseReferralLinkModal}
          applyTo={applyTo.map((product) => product.name)}
          applyAll={applyAll}
          couponCode={couponCode}
          currency={currency}
        />
      </Container>
    );
  };
}

export default withTranslation()(CouponEdit);
