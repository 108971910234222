import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { History, LocationState } from "history";
import { startReset } from "../../../User/Evaluation/Core/utils/actions";
import { AppActions } from "types/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { startLoginFail } from "../../../auth/actions";
import {
  NavbarLinkContainer,
  AdminTabContainer,
  AdminTabTitle,
  ScrollContainer,
  AdminTabColumn,
  LogoutButton,
} from "./styles";
import { ADMIN_COOKIE_NAME, ADMIN_COOKIE_ROLE } from "constants/admin";
import { JS_COOKIE } from "util/auth";
import { createStructuredSelector } from "reselect";
import { AppState } from "storeConfig";
import { makeSelectRole } from "components/auth/selectors";
import { ROLE } from "constants/common";
import LanguageDropdown from "../../../../components/general/LanguageDropdown";
import AdminLanguageDropdown from "../../../../components/general/LanguageDropdown/AdminLanguageDropDown";
import { LanguageContainer } from "../../../../components/general/NavMenu/styles";
import { GET_LANGUAGE, EVAL_ADMIN_LOGO } from "../../../../util/common";
import { EvalAdminCompanyLogo } from "../styles";
interface AdminBarProps {
  history: History<LocationState>;
}

interface AdminBarState {}

type Props = AdminBarProps &
  WithTranslation &
  LinkDispatchProps &
  LinkStateProps;

class AdminBar extends Component<Props, AdminBarState> {
  onLogoutClick = () => {
    this.props.onLoginFail();
    this.props.onReset();
    JS_COOKIE.remove(ADMIN_COOKIE_NAME);
    JS_COOKIE.remove(ADMIN_COOKIE_ROLE);
    this.props.history.push("/adminLogin");
  };

  handleNextComponent = (componentName: string) => {
    this.props.history.push("/admin/" + componentName);
  };

  render = () => {
    const role = JS_COOKIE.get(ADMIN_COOKIE_ROLE);
    const { t } = this.props;
    const { pathname } = window.location;
    const language = GET_LANGUAGE();
    const DesktopLogo = EVAL_ADMIN_LOGO(language);

    return (
      <NavbarLinkContainer>
        <AdminTabContainer>
          <EvalAdminCompanyLogo
            src={DesktopLogo}
            onClick={() => {
              this.props.history.push("/adminLogin");
            }}
          />

          {role === ROLE.admin && (
            <React.Fragment>
              <ScrollContainer>
                <AdminTabColumn
                  selected={pathname.includes("user")}
                  onClick={() => {
                    this.handleNextComponent("user");
                  }}
                >
                  {t("general.navbar.user")}
                </AdminTabColumn>
                <AdminTabColumn
                  selected={pathname.includes("therapist")}
                  onClick={() => {
                    this.handleNextComponent("therapist");
                  }}
                >
                  {t("general.navbar.therapist")}
                </AdminTabColumn>
                <AdminTabColumn
                  selected={pathname.includes("payment")}
                  onClick={() => {
                    this.handleNextComponent("payment");
                  }}
                >
                  {t("general.navbar.payment")}
                </AdminTabColumn>
                <AdminTabColumn
                  selected={pathname.includes("result")}
                  onClick={() => {
                    this.handleNextComponent("result");
                  }}
                >
                  {t("general.navbar.result")}
                </AdminTabColumn>
                <AdminTabColumn
                  selected={pathname.includes("product")}
                  onClick={() => {
                    this.handleNextComponent("product");
                  }}
                >
                  {t("general.navbar.product")}
                </AdminTabColumn>
                <AdminTabColumn
                  selected={pathname.includes("coupon")}
                  onClick={() => {
                    this.handleNextComponent("coupon");
                  }}
                >
                  {t("general.navbar.coupon")}
                </AdminTabColumn>
                <AdminTabColumn
                  selected={pathname.includes("feedback")}
                  onClick={() => {
                    this.handleNextComponent("feedback");
                  }}
                >
                  {t("general.navbar.feedback")}
                </AdminTabColumn>
                <AdminTabColumn
                  selected={pathname.includes("organization")}
                  onClick={() => {
                    this.handleNextComponent("organization");
                  }}
                >
                  {t("general.navbar.organization")}
                </AdminTabColumn>
                {role === ROLE.translator && (
                  <AdminTabColumn
                    selected={pathname.includes("i18n")}
                    onClick={() => {
                      this.handleNextComponent("i18n");
                    }}
                  >
                    I18n Editor
                  </AdminTabColumn>
                )}
              </ScrollContainer>
            </React.Fragment>
          )}
          <AdminLanguageDropdown path="admin" />
          <LogoutButton onClick={this.onLogoutClick}>
            {t("general.navbar.logout")}
          </LogoutButton>
        </AdminTabContainer>
      </NavbarLinkContainer>
    );
  };
}

interface LinkDispatchProps {
  onLoginFail: () => void;
  onReset: () => void;
}

interface LinkStateProps {
  role: string;
}
const mapStateToProps = createStructuredSelector<AppState, LinkStateProps>({
  role: makeSelectRole(),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): LinkDispatchProps => ({
  onLoginFail: bindActionCreators(startLoginFail, dispatch),
  onReset: bindActionCreators(startReset, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdminBar))
);
