// src/components/newUploadReportEmailSender.tsx
import { sendEmail } from "util/mailgunEmailSender";

const getSubject = (locale) => {
  if (locale == "en") {
    return "[PenguinSmart] A New Evaluation Report Uploaded";
  } else if (locale == "cn") {
    return "[启儿宝] 有新评估报告上传到您的帐号";
  } else if (locale == "tw") {
    return "[啟兒寶] 有新評估報告剛剛上傳到您的帳號";
  } else {
    return "[PenguinSmart] A New Evaluation Report Uploaded";
  }
};

export const handleSendNewReportEmail = (locale: string, to: string) => {
  const subject = getSubject(locale);
  const template = "new report email";

  sendEmail(locale, to, subject, template)
    .then((response) => {
      console.log("Email sent successfully:", response);
    })
    .catch((error) => {
      console.error("Failed to send email:", error);
    });
};
