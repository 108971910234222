import { Component } from "react";
import { RouteComponentProps, match } from "react-router-dom";
import { History, LocationState } from "history";
import { WithTranslation } from "react-i18next";
import User from "components/Admin/User";
import Feedback from "components/Admin/Feedback";
import Product from "components/Admin/Product";
import Therapist from "components/Admin/Therapist";
import Payment from "components/Admin/Payment";
import Coupon from "components/Admin/Coupon";
import Result from "components/Admin/Result";
import Organization from "components/Admin/Organization";

import { AdminContainer, AdminContentContainer } from "./styles";

interface AdminProps {
  history: History<LocationState>;
  match?: match<Match>;
}
interface AdminStates {}

interface Match {
  component: string;
}

type Props = AdminProps & RouteComponentProps & WithTranslation;
class Admin extends Component<Props, AdminStates> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  currentComponent = (component: string) => {
    const { history } = this.props;
    switch (component) {
      case "user":
        return <User history={history} />;
      case "feedback":
        return <Feedback history={history} />;
      case "product":
        return <Product history={history} />;
      case "therapist":
        return <Therapist history={history} />;
      case "payment":
        return <Payment history={history} />;
      case "coupon":
        return <Coupon history={history} />;
      case "result":
        return <Result history={history} />;
      case "organization":
        return <Organization history={history} />;
      default:
        return <div />;
    }
  };

  render() {
    const { component } = this.props.match.params;
    const content = this.currentComponent(component);
    return (
      <AdminContainer>
        <AdminContentContainer>{content}</AdminContentContainer>
      </AdminContainer>
    );
  }
}

export default Admin;
