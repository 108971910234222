import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  margin: 0 5rem;
`;

export const Header = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const UploadInput = styled.input`
  display: none;
`;

export const Button = styled.button`
  height: 40px;
  margin: 0 5px;
  background: #a8bf96;
  color: white;
  border-radius: 4px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const UploadButton = styled(Button)`
  display: block;
`;

export const ButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  margin: 0 3rem;
`;

export const Mask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const rotate = keyframes`
 to{transform: rotate(1turn)}
`;

export const Loading = styled.div`
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid lightblue;
  border-right-color: orange;
  animation: ${rotate} 1s infinite linear;
`;

export const SearchableJSONViewerContainer = styled.div`
  width: 40%;
`;

export const StyledResponseViewer = styled.div`
  width: 40%;
  overflow: auto;
  height: 70vh;

  & > pre {
    height: 100%;
    overflow: auto;
    margin: 0;
    background-color: rgb(239, 255, 255);
  }
`;
