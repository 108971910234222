import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { History, LocationState } from "history";
import i18n from "i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "types/actions";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { startUpdateUserLanguage } from "../../auth/actions";
import { Dropdown, Header, Icon } from "semantic-ui-react";
import mixpanel from "mixpanel-browser";
import { GET_LANGUAGE } from "../../../util/common";
import { FaGlobe } from "react-icons/fa";

interface AdminLanguageDropDownProps {
  path: string;
  history: History<LocationState>;
}

interface AdminLanguageDropDownState {
  language: string;
}

type Props = AdminLanguageDropDownProps &
  WithTranslation &
  LinkDispatchProps &
  RouteComponentProps;

class AdminLanguageDropDown extends Component<
  Props,
  AdminLanguageDropDownState
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      language: GET_LANGUAGE(),
    };
  }

  handleChange = (event) => {
    const lang = event.currentTarget.textContent;
    if (lang === "English") {
      this.setState({ language: lang });
      this.props.onUpdateLanguage("en");
      i18n.changeLanguage("en");
    } else if (lang === "简体中文") {
      this.setState({ language: lang });
      this.props.onUpdateLanguage("zh-CN");
      i18n.changeLanguage("zh-CN");
    } else if (lang === "繁體中文") {
      this.setState({ language: lang });
      this.props.onUpdateLanguage("zh-TW");
      i18n.changeLanguage("zh-TW");
    }
    mixpanel.track("language", {
      location: this.props.path,
      action: "change language",
      from: this.state.language,
      to: lang,
    });
  };

  onClick = () => {
    mixpanel.track("language", {
      location: this.props.path,
      action: "click change language",
    });
  };

  render = () => {
    const { t } = this.props;
    const { language } = this.state;
    const languageKey = [
      { key: "en", text: "English" },
      { key: "zh-CN", text: "简体中文" },
      { key: "zh-TW", text: "繁體中文" },
    ];
    const languageOptions = languageKey.map((language) => {
      const { key, text } = language;
      return { key: key, text: text, value: key };
    });
    return (
      <Dropdown
        onClick={this.onClick}
        inline
        options={languageOptions}
        onChange={this.handleChange}
        defaultValue={language}
        text={t("general.language")}
        style={{
          color: "#6898E3A6",
          fontSize: "10px",
          fontWeight: "unset",
          margin: "20px auto auto auto",
        }}
      />
    );
  };
}

interface LinkDispatchProps {
  onUpdateLanguage: (data: string) => void;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): LinkDispatchProps => ({
  onUpdateLanguage: bindActionCreators(startUpdateUserLanguage, dispatch),
});

export default withRouter(
  connect(null, mapDispatchToProps)(withTranslation()(AdminLanguageDropDown))
);
