import styled from "styled-components";

export const Container = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 20px 5px 50px 5px;
`;

export const Header = styled.div`
  font-size: 30px;
  line-height: 60px;
`;

export const Image = styled.img`
  width: calc(33% - 10px);
  height: auto;
  border: 1px solid lightgrey;
  margin: 0 5px;
  @media only screen and (max-width: 500px) {
    width: 100%;
    margin: 5px 0;
  }
`;

export const Instruction = styled.div`
  margin: 10px 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
`;

export const InstructionContainer = styled.div`
  margin: 10px 0;
  padding: 10px 15px;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 40%);
`;
