import styled from "styled-components";
import { H1Title, H2Title } from "styles";

//Container
export const Container = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  background-color: #6898e3;
`;

export const LoginContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const LoginBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 55%;
  height: 100vh;
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const RegisterScroll = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const LoginIntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const TitleContainer = styled.div`
  align-self: center;
  width: 80%;
`;

export const CompanyNameContainer = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;
  margin-bottom: 10px;
`;

// img
export const LoginDesImg = styled.img`
  height: 200px;
  @media only screen and (min-width: 768px) {
    height: 35%;
  }
  @media only screen and (min-width: 992px) {
    height: 50%;
  }
  @media only screen and (min-width: 1200px) {
    height: 55%;
  }
`;

export const Logo = styled.img`
  height: 38px;
  @media only screen and (min-width: 1200px) {
    height: 40px;
  }
`;

// text
export const CompanyName = styled(H1Title)`
  color: white;
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

export const Des = styled(H2Title)`
  color: white;
  display: flex;
  align-items: center;
`;
