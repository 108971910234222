import styled from "styled-components";
import { ContainerWidth, H2Title, SmallContentTitle } from "../../../styles";

// container
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90vh;
  text-align: center;
`;
export const FAQContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10%;
  margin-bottom: 96px;
  @media only screen and (min-width: 768px) {
    padding: 0 25%;
  }
`;
export const ContactUsContainer = styled.div`
  width: fit-content;
  padding: 30px 40px 20px 40px;
  border-radius: 8px;
  @media only screen and (min-width: 576px) {
    box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
  }
`;
export const ContentContainer = styled(ContainerWidth)``;
export const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: center;
`;

// text
export const ContactUsTitle = styled(H2Title)`
  color: #6898e3;
  font-size: 3.5rem;
  line-height: normal;
`;
export const ContactUsText = styled(SmallContentTitle)`
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 1.7rem;
  line-height: normal;
`;
export const ContactUsTextFAQ = styled.span`
  color: #6898e3;
  display: inline;
  cursor: pointer;
`;

// social media icon
export const Icon = styled.img`
  width: 50px;
  cursor: pointer;
  margin-right: 10px;
`;
export const QRCode = styled.img`
  width: 100px;
  cursor: pointer;
`;

// Form
export const AvailableTimesContainer = styled.div``;
export const AvailableTimesInput = styled.input`
  margin-right: 10px;
  cursor: pointer;
  height: 14px;
  width: 14px;
`;
export const AvailableTimesText = styled.label`
  cursor: pointer;
`;
export const AvailableTimesOptionContainer = styled.div`
  width: max-content;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0 0 8px 2px;
`;
