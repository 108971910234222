import React, { Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { History, LocationState } from "history";
import { withTranslation, WithTranslation } from "react-i18next";
import { Icon, Input, Button } from "semantic-ui-react";
import { ThunkDispatch } from "redux-thunk";
import { bindActionCreators } from "redux";
import mixpanel from "mixpanel-browser";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import api_address from "../../../constants/config";
import NotificationModal from "../../common/NotificationModal";
import { IS_NUMBER, IS_VALID_EMAIL } from "../../../util/common";
import {
  ActionTitle,
  GeneralSegment,
  RouteSectionContainer,
  CenterSegment,
} from "../../../styles";
import {
  HeaderContainerSpaceBetween,
  HeaderTitle,
  LogoutButton,
} from "components/User/ProfileRoute/styles";
import LanguageDropdown from "../../general/LanguageDropdown";
import {
  SmsNotificationContainer,
  CountryContainer,
  LanguageContainer,
} from "components/general/NavMenu/styles";
import {
  ContentTitleContainer,
  FlexBetweenContainer,
  InfoContainer,
  InfoText,
  TitleText,
} from "components/User/ProfileRoute/PurchasesHistory/styles";
import { AppActions } from "types/actions";
import { startLoginFail } from "../../auth/actions";
import { startReset } from "../../User/Evaluation/Core/utils/actions";
import { AppState } from "storeConfig";
import { makeIsUserLoggedIn } from "../../auth/selectors";
import { client } from "../../../graphql/ApolloClient";
import { LOGOUT_MUTATION } from "../../../graphql/mutations/logout";
import { GET_TITLE_BAR } from "util/common";
import { JS_COOKIE } from "util/auth";
import SmsNotificationCheckbox from "components/general/SmsNotificationCheckbox";
import CountryDropdown from "components/general/CountryDropdown";
import TimeZoneDropdown from "components/general/TimeZoneDropdown";
import { EDIT_USER_INFORMATION } from "../../../graphql/mutations/editUserInformation";
import { GET_USER_INFO } from "../../../util/common";
import AccountFieldRenderer from "./components/AccountFieldRenderer";
interface AccountProps {
  history: History<LocationState>;
}
interface AccountState {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  countryCode: string;
  editingField: string | null;
  newFirstName: string;
  newLastName: string;
  newValue: string;
  message: string;
  showModal: boolean;
  notificationStatus: boolean;
  organizationName: string | null;
  userUuid: number;
}

type Props = AccountProps & WithTranslation & LinkDispatchProps & LinkStateProp;
class Account extends Component<Props, AccountState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      countryCode: "",
      editingField: null,
      newFirstName: "",
      newLastName: "",
      newValue: "",
      message: "",
      showModal: false,
      notificationStatus: false,
      organizationName: null,
      userUuid: 0,
    };
  }
  componentDidMount = async () => {
    try {
      const { t } = this.props;
      document.title = GET_TITLE_BAR(t, "accountSetting", true);
      const token = Cookies.get("token");
      if (!token) {
        this.props.history.push("/login");
      }
      const res = await axios.get(api_address + "api/user/account", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const {
        firstName,
        lastName,
        email,
        id,
        phone,
        countryCode,
        organizationName,
      } = res.data;
      this.setState({
        id: id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        countryCode: countryCode,
        organizationName,
      });
    } catch (err: any) {
      console.error(err.message);
      this.props.history.push("/login");
    }
  };

  handleEdit = (field: string) => {
    if (field === "name") {
      this.setState({
        editingField: field,
        newFirstName: this.state.firstName,
        newLastName: this.state.lastName,
      });
    } else {
      this.setState({
        editingField: field,
        newValue: this.state[field as keyof AccountState] as string,
      });
    }
  };

  updateNameOnGuide = async (userUuid, attribute, value) => {
    await client.mutate({
      mutation: EDIT_USER_INFORMATION,
      variables: {
        userUuid: userUuid,
        attribute: attribute,
        value: value.toString(),
      },
    });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "firstName") {
      this.setState({ newFirstName: value });
    } else if (name === "lastName") {
      this.setState({ newLastName: value });
    } else {
      this.setState({ newValue: value });
    }
  };

  handleCountryCodeChange = (data: any) => {
    this.setState({ countryCode: data });
  };

  handleSave = async () => {
    const { editingField, newFirstName, newLastName, newValue, countryCode } =
      this.state;
    const { t } = this.props;
    const token = Cookies.get("token");
    const userData = await GET_USER_INFO();
    this.setState({
      userUuid: userData.uuid,
    });

    try {
      if (editingField === "email" && !newValue) {
        throw new Error(t("message.account.emailMissing"));
      } else if (editingField === "email" && !IS_VALID_EMAIL(newValue)) {
        throw new Error(t("message.account.invalidEmail"));
      } else if (editingField === "phone" && !newValue) {
        throw new Error(t("message.account.phoneMissing"));
      } else if (editingField === "phone" && !IS_NUMBER(newValue)) {
        throw new Error(t("message.account.invalidPhone"));
      } else if (editingField === "name") {
        if (!newFirstName || !newLastName) {
          throw new Error(t("message.account.nameMissing"));
        }
        const firstNameRes = await axios.put(
          `${api_address}api/user/account/firstName`,
          { data: newFirstName },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        await this.updateNameOnGuide(userData.uuid, "First Name", newFirstName);
        const lastNameRes = await axios.put(
          `${api_address}api/user/account/lastName`,
          { data: newLastName },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        await this.updateNameOnGuide(userData.uuid, "Last Name", newLastName);
        const { status: firstNameStatus } = firstNameRes.data;
        if (!firstNameStatus) {
          throw new Error("First Name Update Failed");
        }
        const { status: lastNameStatus } = lastNameRes.data;
        if (!lastNameStatus) {
          throw new Error("Last Name Update Failed");
        }
        this.setState(
          {
            firstName: newFirstName,
            lastName: newLastName,
            editingField: null,
          },
          () => this.handleStatus(t("message.account.successName"), true)
        );
        return;
      }

      if (editingField === "phone") {
        const phoneRes = await axios.put(
          `${api_address}api/user/account/phone`,
          { data: newValue },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { status: phoneStatus } = phoneRes.data;
        if (!phoneStatus) {
          throw new Error(t("message.account.updateFailedPhoneNumber"));
        }

        let countryCodeVariable = "countrycode";
        if (userData.role === "Therapist") {
          countryCodeVariable = "countryCode";
        }
        let countryCodeRes = await axios.put(
          `${api_address}api/user/account/${countryCodeVariable}`,
          { data: countryCode },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        await this.updateNameOnGuide(
          userData.uuid,
          "Phone Number",
          countryCode + newValue
        );

        const { status: countryCodeStatus } = countryCodeRes.data;
        if (!countryCodeStatus) {
          throw new Error("Failed to update country code.");
        }
      } else {
        const res = await axios.put(
          `${api_address}api/user/account/${editingField}`,
          { data: newValue },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { status } = res.data;
        if (!status) {
          throw new Error("Failed to update status");
        }
        if (editingField === "email") {
          await this.updateNameOnGuide(userData.uuid, "Email", newValue);
        }
      }

      this.setState(
        {
          [editingField]: newValue,
          editingField: null,
        } as Pick<AccountState, keyof AccountState>,
        () =>
          this.handleStatus(
            t(
              `message.account.success${
                editingField.charAt(0).toUpperCase() + editingField.slice(1)
              }`
            ),
            true
          )
      );
    } catch (error: any) {
      this.handleStatus(error.message, false);
    }
  };

  handleCancel = () => {
    this.setState({
      editingField: null,
      newValue: "",
    });
  };

  handleStatus = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  onLogoutClick = async () => {
    try {
      const res = await client.mutate({
        mutation: LOGOUT_MUTATION,
        variables: {},
      });
      const logoutReturn = res.data.logout;
      const { status, message } = logoutReturn.returnMessage;
      if (!status) {
        throw new Error(message);
      }
      mixpanel.track("logout");
    } catch (error) {}
    this.props.onLoginFail();
    this.props.onReset();
    JS_COOKIE.remove("token");
    window.location.href = "/login";
  };

  renderField = (field: string, label: string) => {
    const { editingField, newFirstName, newLastName, newValue, countryCode } =
      this.state;

    const value =
      field === "phone"
        ? `${countryCode} ${this.state.phone}`
        : field === "name"
        ? `${this.state.firstName} ${this.state.lastName}`
        : (this.state[field as keyof AccountState] as string);

    return (
      <AccountFieldRenderer
        field={field}
        label={label}
        value={value}
        editingField={editingField}
        newFirstName={newFirstName}
        newLastName={newLastName}
        newValue={newValue}
        countryCode={countryCode}
        handleChange={this.handleChange}
        handleSave={this.handleSave}
        handleCancel={this.handleCancel}
        handleEdit={this.handleEdit}
        handleCountryCodeChange={this.handleCountryCodeChange}
        t={this.props.t}
      />
    );
  };

  render() {
    const { showModal, message, notificationStatus, organizationName } =
      this.state;
    const hasOrganization = Boolean(organizationName);
    const { t, loggedIn } = this.props;

    return (
      <RouteSectionContainer>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />

        <HeaderContainerSpaceBetween style={{ margin: "10px 0 0 0" }}>
          <Icon
            className="chevron left"
            size="large"
            link
            circular
            onClick={() => this.props.history.push("/profile")}
          />
          <HeaderTitle>{t("auth.account.accountSettings")}</HeaderTitle>
          {loggedIn && (
            <LogoutButton
              onClick={this.onLogoutClick}
              style={{ padding: "15px 10px" }}
            >
              <ActionTitle>{t("general.navbar.logout")}</ActionTitle>
            </LogoutButton>
          )}
        </HeaderContainerSpaceBetween>
        <CenterSegment>
          <GeneralSegment>
            <FlexBetweenContainer>
              <InfoContainer style={{ paddingTop: "0px" }}>
                <ContentTitleContainer>
                  <TitleText>{t("auth.account.name")}</TitleText>
                  {this.renderField("name", t("auth.account.name"))}
                  <TitleText>{t("auth.account.email")}</TitleText>

                  {this.renderField("email", t("auth.account.email"))}
                  <TitleText>{t("auth.account.phone")}</TitleText>
                  {this.renderField("phone", t("auth.account.phone"))}
                  {hasOrganization && (
                    <TitleText>{t("auth.account.organization")}</TitleText>
                  )}
                  {hasOrganization && <InfoText>{organizationName}</InfoText>}
                  <TitleText>{t("auth.account.currentLocale")}</TitleText>
                  <LanguageContainer>
                    <LanguageDropdown path="account" color={"black"} />
                  </LanguageContainer>
                  <TitleText>{t("auth.account.smsNotification")}</TitleText>
                  <SmsNotificationContainer>
                    <SmsNotificationCheckbox
                      path="account"
                      color={"black"}
                      handleStatus={this.handleStatus}
                    />
                  </SmsNotificationContainer>
                  <TitleText>{t("auth.account.country")}</TitleText>
                  <CountryContainer>
                    <CountryDropdown
                      path="account"
                      color={"black"}
                      handleStatus={this.handleStatus}
                    />
                  </CountryContainer>
                  <TitleText>{t("auth.account.timeZone")}</TitleText>
                  <CountryContainer>
                    <TimeZoneDropdown
                      path="account"
                      color={"black"}
                      handleStatus={this.handleStatus}
                    />
                  </CountryContainer>
                </ContentTitleContainer>
              </InfoContainer>
            </FlexBetweenContainer>
          </GeneralSegment>
        </CenterSegment>
      </RouteSectionContainer>
    );
  }
}

interface LinkStateProp {
  loggedIn: boolean;
}

interface LinkDispatchProps {
  onLoginFail: () => void;
  onReset: () => void;
}

const mapStateToProps = createStructuredSelector<AppState, LinkStateProp>({
  loggedIn: makeIsUserLoggedIn(),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): LinkDispatchProps => ({
  onLoginFail: bindActionCreators(startLoginFail, dispatch),
  onReset: bindActionCreators(startReset, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Account))
);
