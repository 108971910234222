import { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import OauthRegisterModal from "./OauthRegister";

interface OauthRegisterState {
  displayPopUp: boolean;
}

type Props = RouteComponentProps & WithTranslation;

class OauthRegister extends Component<Props, OauthRegisterState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      displayPopUp: false,
    };
  }
  componentDidMount = async () => {
    this.setState({ displayPopUp: true });
  };

  handleClick = () => {
    this.setState({ displayPopUp: false });
  };

  handleNotNow = () => {
    this.setState({ displayPopUp: false });
    this.props.history.push("/login");
  };

  render() {
    const { t } = this.props;
    return (
      <OauthRegisterModal
        display={this.state.displayPopUp}
        onClick={this.handleClick}
        onNotNowClick={this.handleNotNow}
        title={t("auth.register.signUpOauth")}
        text={t("auth.register.oauthRegister")}
        buttonText={t("auth.register.registerButton")}
        notNowText={t("auth.register.cancelButton")}
        {...this.props}
        {...this.state}
      ></OauthRegisterModal>
    );
  }
}

export default withRouter(withTranslation()(OauthRegister));
