import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  ACTIVITY_INDEX,
  INDEX_TO_TYPE,
} from "../../../../constants/activities";
import {
  LabelContainer,
  OptionText,
  OptionRadio,
  ActivityTitle,
  ActivitySubtitle,
  ActivityText,
  OptionContainer,
  SmallSectionTitleContainer,
  PageContainer,
  FormTitle,
} from "../styles";
import { activitiesType } from "../../../User/Evaluation/CorePro/data";
import { objectToArray } from "util/common";
import FormSectionTitle from "../component/FormSectionTitle";
import { QuestionContainer, QuestionText } from "../component/style";
import QuestionTitle from "../component/QuestionTitle";
import FormSection from "../component/FormSection";
interface ActivityProps {
  activities: activitiesType;
  setState: (states: object) => void;
}

interface ActivityStates {}

type States = ActivityStates;

type Props = ActivityProps & WithTranslation & RouteComponentProps;

class Activity extends Component<Props, States> {
  componentDidMount = () => {
    window.scrollTo({ top: 0 });
  };

  handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    const { setState } = this.props;
    if (name.includes("activityType")) {
      setState({
        [`activity${name[name.length - 1]}`]: 0, //reset selected activity when activityType changes
        [name]: parseInt(value),
      });
    } else {
      setState({
        [name]: parseInt(value),
      });
    }
  };

  getActivityType = (index: number): JSX.Element => {
    const { t, activities } = this.props;
    const value = activities[`activityType${index}`];
    const optionCount = ACTIVITY_INDEX.typeCount;
    const options = Array.from(Array(optionCount).keys()).map((i: number) => {
      return t(`therapist.reportGeneration.activity.${index}.${i}`);
    });

    return (
      <>
        <SmallSectionTitleContainer backgroundColor={"#6898e3"}>
          {t(`therapist.reportGeneration.activity.${index}.title`)}
        </SmallSectionTitleContainer>
        <FormSection
          topLeftBorderRadius={false}
          indicator={false}
          index={index}
        >
          <QuestionContainer key={"activity type " + index}>
            <QuestionText>
              {t(`therapist.reportGeneration.activity.${index}.typeTitle`)}
            </QuestionText>
            {options.map((option: string, i: number) => {
              return (
                <OptionContainer>
                  <LabelContainer key={"activity type " + i + "." + index}>
                    <OptionRadio
                      type="radio"
                      value={i + 1}
                      name={`activityType${index}`}
                      onChange={this.handleRadioChange}
                      checked={value === i + 1}
                    />
                    <OptionText>{option}</OptionText>
                  </LabelContainer>
                </OptionContainer>
              );
            })}
          </QuestionContainer>
        </FormSection>
      </>
    );
  };

  getActivityQuestion = (index: number): JSX.Element => {
    const { t, activities } = this.props;
    const type = activities[`activityType${index}`];
    const typeName = INDEX_TO_TYPE[type];
    const value = activities[`activity${index}`];
    const activityTranslation = t(`recommendedActivities.${typeName}`, {
      returnObjects: true,
    });
    const options = Object.keys(activityTranslation).map(
      (id: string) => activityTranslation[id]["name"]
    );

    return (
      <FormSection
        topLeftBorderRadius={true}
        indicator={false}
        index={index}
        key={"activity type " + index}
      >
        <QuestionTitle>
          {t(`therapist.reportGeneration.activity.${index}.activityTitle`)}
        </QuestionTitle>
        {options.map((option: string, i: number) => {
          return (
            <OptionContainer>
              <LabelContainer key={"activity " + i + "." + index}>
                <OptionRadio
                  type="radio"
                  value={i + 1}
                  name={`activity${index}`}
                  onChange={this.handleRadioChange}
                  checked={value === i + 1}
                />
                <OptionText>{option}</OptionText>
              </LabelContainer>
            </OptionContainer>
          );
        })}
      </FormSection>
    );
  };

  getActivity = (index: number): JSX.Element => {
    const { t, activities } = this.props;
    const activityType = activities[`activityType${index}`];
    const activity = activities[`activity${index}`];
    const activityTypeName = INDEX_TO_TYPE[activityType];
    const activityTranslation = t(
      `recommendedActivities.${activityTypeName}.${activity}`,
      { returnObjects: true }
    );
    const forChildren = objectToArray(
      activityTranslation["skills"]["children"]
    );
    const forParent = objectToArray(activityTranslation["skills"]["parent"]);
    const steps = objectToArray(activityTranslation["steps"]);
    const parentReactions = objectToArray(
      activityTranslation["parentReaction"]
    );
    const notes = objectToArray(activityTranslation["notes"]);

    return (
      <FormSection topLeftBorderRadius={true} indicator={false} index={index}>
        <ActivityTitle>{activityTranslation["name"]}</ActivityTitle>
        <ActivityTitle>{activityTranslation["age"]}</ActivityTitle>
        <ActivitySubtitle>
          {t(`therapist.reportGeneration.activity.skills`)}
        </ActivitySubtitle>
        <ActivityText>
          {activityTranslation["skills"]["description"]}
        </ActivityText>

        {forChildren.length > 0 && (
          <>
            <ActivitySubtitle>
              {t(`therapist.reportGeneration.activity.forChild`)}
            </ActivitySubtitle>
            {forChildren.map((line: string, i: number) => {
              return (
                <ActivityText key={`for children ${index} ${i}`}>
                  {line}
                </ActivityText>
              );
            })}
          </>
        )}
        {forParent.length > 0 && (
          <>
            <ActivitySubtitle>
              {t(`therapist.reportGeneration.activity.forParent`)}
            </ActivitySubtitle>
            {forParent.map((line: string, i: number) => {
              return (
                <ActivityText key={`for parent ${index} ${i}`}>
                  {line}
                </ActivityText>
              );
            })}
          </>
        )}

        <ActivitySubtitle>
          {t(`therapist.reportGeneration.activity.scenarioOrSetup`)}
        </ActivitySubtitle>
        <ActivityText>{activityTranslation["scenarioOrSetup"]}</ActivityText>
        <ActivitySubtitle>
          {t(`therapist.reportGeneration.activity.frequency`)}
        </ActivitySubtitle>
        <ActivityText>{activityTranslation["frequency"]}</ActivityText>
        {steps.length > 0 && (
          <div>
            <ActivitySubtitle>
              {t(`therapist.reportGeneration.activity.steps`)}
            </ActivitySubtitle>
            {steps.map((step: string, i: number) => {
              return (
                <ActivityText key={`step ${index} ${i}`}>{step}</ActivityText>
              );
            })}
          </div>
        )}
        {parentReactions.length > 0 && (
          <div>
            <ActivitySubtitle>
              {t(`therapist.reportGeneration.activity.parentReaction`)}
            </ActivitySubtitle>
            {parentReactions.map((reaction: string, i: number) => {
              return (
                <ActivityText key={`reaction ${index} ${i}`}>
                  {reaction}
                </ActivityText>
              );
            })}
          </div>
        )}
        {notes.length > 0 && (
          <div>
            <ActivitySubtitle>
              {t(`therapist.reportGeneration.activity.notes`)}
            </ActivitySubtitle>
            {notes.map((note: string, i: number) => {
              return (
                <ActivityText key={`note ${index} ${i}`}>{note}</ActivityText>
              );
            })}
          </div>
        )}
      </FormSection>
    );
  };

  getQuestions = (): JSX.Element => {
    const { activities } = this.props;
    const activityType = this.getActivityType;
    const activityQuestion = this.getActivityQuestion;
    const activity = this.getActivity;
    const questionIndex = Array.from(Array(ACTIVITY_INDEX.count).keys());

    return (
      <>
        {questionIndex.map((value: number, index: number) => {
          const typeIndex = activities[`activityType${index}`];
          const activityIndex = activities[`activity${index}`];
          return (
            <div key={`activity ${value}`}>
              {activityType(value)}
              {typeIndex > 0 && activityQuestion(value)}
              {typeIndex > 0 && activityIndex > 0 && activity(value)}
            </div>
          );
        })}
      </>
    );
  };

  render = () => {
    const { t } = this.props;
    const questions = this.getQuestions();
    return (
      <PageContainer>
        <FormSectionTitle>
          <FormTitle>
            {t("therapist.reportGeneration.activity.title")}
          </FormTitle>
        </FormSectionTitle>
        {questions}
      </PageContainer>
    );
  };
}

export const isActivityNextDisabled = (activities: activitiesType): boolean => {
  const {
    activityType2,
    activity2,
    activityType1,
    activity1,
    activityType0,
    activity0,
  } = activities;
  const activity0Invalid = activityType0 === 0 || activity0 === 0;
  const activity1Invalid = activityType1 === 0 || activity1 === 0;
  const activity2Invalid = activityType2 === 0 || activity2 === 0;
  return activity2Invalid || activity1Invalid || activity0Invalid;
};

export default withRouter(withTranslation()(Activity));
