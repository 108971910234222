import gql from "graphql-tag";
import { ReturnMessage } from "../types/ReturnMessage";

export const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout {
      success
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface Data {
  logout: {
    success: boolean;
    returnMessage: ReturnMessage;
  };
}

export interface Variables {}
