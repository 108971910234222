import React, { Component } from "react";
import axios from "axios";
import { withRouter, RouteComponentProps } from "react-router-dom";
import api_address from "../../../../constants/config";
import {
  PRODUCTS,
  MODULARIZED_PRODUCTS,
  SELF_PRODUCTS,
  EXPERT_PRODUCTS,
  CURRENCY,
} from "../../../../constants/common";
import Cookies from "js-cookie";
import { withTranslation, WithTranslation } from "react-i18next";
import { History, LocationState } from "history";
import { Icon } from "semantic-ui-react";
import {
  FlexBetweenContainer,
  ProductName,
  GreyInfoText,
  PurchaseButton,
  InfoText,
  ContentTitleContainer,
  ContentContainer,
  InfoContainer,
  InfoAndActionContainer,
} from "./styles";
import { GeneralSegment, RouteSectionContainer, ActionTitle } from "styles";
import {
  HeaderContainer,
  HeaderTitle,
} from "components/User/ProfileRoute/styles";
import { GET_TITLE_BAR } from "util/common";

interface OrderProps {
  history: History<LocationState>;
}

interface OrderState {
  orders: any;
}

type Props = OrderProps & RouteComponentProps & WithTranslation;

class PurchasesHistory extends Component<Props, OrderState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      orders: [],
    };
  }

  componentDidMount = async () => {
    try {
      const { t } = this.props;
      document.title = GET_TITLE_BAR(t, "orderHistory", true);
      const token = Cookies.get("token");
      if (!token) {
        this.props.history.push("/login");
      }
      const res = await axios.get(api_address + "api/user/purchases", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { status, message } = res.data;
      if (!status) {
        throw new Error(message);
      }
      const orders = res.data.orders;
      this.setState({
        orders: orders,
      });
    } catch (err: any) {
      console.error(err.message);
      this.props.history.push("/login");
    }
  };

  purchaseAgain = (product: string) => {
    let buyUpProduct = "";
    if (
      SELF_PRODUCTS.includes(product) ||
      MODULARIZED_PRODUCTS.includes(product)
    ) {
      buyUpProduct = PRODUCTS.Core;
    } else if (EXPERT_PRODUCTS.includes(product)) {
      buyUpProduct = PRODUCTS.CorePro;
    }
    this.props.history.push("purchases/" + buyUpProduct);
  };

  renderTableData = () => {
    const { t } = this.props;
    return this.state.orders.reverse().map((order) => {
      const date = order["createdat"].substr(0, 10);
      const name = order["name"];
      const method = order["paymentmethod"];
      let total = "";
      switch (order["currency"].toLowerCase()) {
        case CURRENCY.cny:
          total = "¥";
          break;
        case CURRENCY.usd:
          total = "$";
          break;
        case CURRENCY.twd:
          total = "NT$";
          break;
      }
      total += order["price"];
      const status = order["status"];
      const transactionId = order["transactionid"];
      return (
        <GeneralSegment>
          <FlexBetweenContainer>
            <ProductName>
              {t(`user.purchase.payments.checkoutPayment.${name}`)}
            </ProductName>
            <GreyInfoText>{date}</GreyInfoText>
          </FlexBetweenContainer>
          <InfoAndActionContainer>
            <InfoContainer>
              <ContentTitleContainer>
                <GreyInfoText>
                  {t("user.purchase.PurchasesHistory.transactionId")}
                </GreyInfoText>
                <GreyInfoText>
                  {t("user.purchase.PurchasesHistory.orderPayment")}
                </GreyInfoText>
                <GreyInfoText>
                  {t("user.purchase.PurchasesHistory.orderPrice")}
                </GreyInfoText>
                <GreyInfoText>
                  {t("user.purchase.PurchasesHistory.orderStatus")}
                </GreyInfoText>
              </ContentTitleContainer>
              <ContentContainer>
                <InfoText>{transactionId}</InfoText>
                <InfoText>
                  {t(`admin.payment.paymentMethod.${method}`)}
                </InfoText>
                <InfoText>{total}</InfoText>
                <InfoText>
                  {t(`user.purchase.PurchasesHistory.${status}`)}
                </InfoText>
              </ContentContainer>
            </InfoContainer>
            {/* <PurchaseButton onClick={() => this.purchaseAgain(name)}>
              <ActionTitle>
                {t("user.purchase.PurchasesHistory.orderAgain")}
              </ActionTitle>
            </PurchaseButton> */}
          </InfoAndActionContainer>
        </GeneralSegment>
      );
    });
  };

  render() {
    const { t } = this.props;
    const tableData = this.renderTableData();

    return (
      <RouteSectionContainer>
        <Icon
          className="chevron left"
          size="large"
          link
          circular
          onClick={() => this.props.history.push("/profile")}
        />
        <HeaderContainer>
          <HeaderTitle>{t("user.purchase.PurchasesHistory.title")}</HeaderTitle>
        </HeaderContainer>
        {tableData}
      </RouteSectionContainer>
    );
  }
}

export default withRouter(withTranslation()(PurchasesHistory));
