import React from "react";
import { useHistory } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import LoginForm from "../../UserAuthForm/LoginForm";
import { ModalContainer, ModalMask } from "styles";

type Props = {
  isOpen: boolean;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCountryCodeChange: (data: string) => void;
  setIsPhone: () => void;
  onLoginSubmit: () => void;
  handleRegister: () => void;
  email: string;
  phone: string;
  countryCode: string;
  password: string;
  isPhone: boolean;
};

function LoginModal(props: Props) {
  const history = useHistory();

  const onMouseEnter = () => {
    mixpanel.track("Login", {
      action: "mouse enter login form",
    });
  };

  const onMouseLeave = () => {
    mixpanel.track("Login", {
      action: "mouse leave login form",
    });
  };

  return (
    <ModalMask open={props.isOpen}>
      <ModalContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <LoginForm {...props} history={history} />
      </ModalContainer>
    </ModalMask>
  );
}

export default LoginModal;
