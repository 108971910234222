import styled from "styled-components";

export const Input = styled.input`
  letter-spacing: 0.3px;
  width: 100%;
  height: 42px;
  margin: 20px 0;
  padding: 0 10px;
  border-radius: 8px;
  border: solid 1px #bdbdbd;
  line-height: 20px;
  :focus {
    border-bottom: none;
    outline: none;
    border: solid 1px #6898e3;
  }
  :hover {
    border: solid 1px #6898e3;
  }
`;

export const Segment = styled.div`
  padding: 24px;
  margin: 12px 0;
  border: 1px solid #dadce0;
  border-radius: 8px;
  background-color: #fff;
  position: relative;
`;

export const OrderList = styled.ol`
  padding-inline-start: 20px;
`;

export const UnOrderList = styled.ul`
  padding-inline-start: 20px;
`;

export const List = styled.li`
  margin-bottom: 8px;
`;

export const QuestionText = styled.div`
  font-size: 16px;
  letter-spacing: 0.1px;
  line-height: 24px;
  color: #202124;
  font-weight: 400;
  width: 100%;
  word-break: break-word;
`;

export const OptionText = styled.div`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 20px;
  // color: #202124;
  min-width: 1px;
`;

export const Title = styled.div`
  color: #202124;
  font-size: 32px;
  font-weight: 400;
  line-height: 135%;
`;

export const TitleIndicate = styled.div`
  background: #6898e3;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 10px;
  width: calc(100% + 2px);
  top: -1px;
  left: -1px;
  position: absolute;
`;

export const ListContainer = styled.ol``;

export const ListItem = styled.li`
  padding-inline-start: 20px;
  margin: 8px 0;
  font-size: 16px;
`;
export const Image = styled.img`
  width: 200px;
`;
