import { Component } from "react";
import { match } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import api_address from "../../../../constants/config";
import { History, LocationState } from "history";
import NotificationModal from "../../../common/NotificationModal";
import {
  Container,
  ContentContainer,
  ContentSubtitle,
  Input,
  SaveButton,
  CancelButton,
  LinkButtonInEdit,
} from "../../styles";
import { PaymentMethodButton } from "./style";
import { PAYMENT_STATUS, PAYMENT_METHOD } from "../../../../constants/payment";
import { ADMIN_COOKIE_NAME } from "constants/admin";
import { JS_COOKIE } from "util/auth";

interface Match {
  id: string;
}

interface PaymentEditProps {
  match: match<Match>;
  history: History<LocationState>;
}

interface PaymentEditStates {
  [key: string]: string | number | boolean;
  transactionId: string;
  userId: string;
  product: string;
  productName: string;
  stripeId: string;
  currency: string;
  paymentMethod: string;
  couponId: string;
  couponCode: string;
  price: string;
  status: string;
  createdAt: string;
  message: string;
  showModal: boolean;
  notificationStatus: boolean;
}

type Props = PaymentEditProps & WithTranslation;
class PaymentEdit extends Component<Props, PaymentEditStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      transactionId: "",
      userId: "",
      product: "",
      productName: "",
      stripeId: "",
      currency: "",
      paymentMethod: "",
      couponId: "",
      couponCode: "",
      price: "",
      status: "",
      createdAt: "",
      message: "",
      showModal: false,
      notificationStatus: false,
    };
  }

  componentDidMount = () => {
    const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
    const { id } = this.props.match.params;
    axios
      .get(api_address + "api/admin/payment/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const {
          transactionId,
          userId,
          product,
          productName,
          stripeId,
          currency,
          paymentMethod,
          couponId,
          couponCode,
          price,
          status,
          createdAt,
        } = res.data.payment;
        this.setState({
          transactionId,
          userId,
          product,
          productName,
          stripeId,
          currency,
          paymentMethod,
          couponId,
          couponCode,
          price,
          status,
          createdAt,
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  handlePaymentStatusChange = (value: string) => {
    this.setState({
      status: value,
    });
  };

  handlePaymentMethodChange = (value: string) => {
    this.setState({
      paymentMethod: value,
    });
  };

  showNotification = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  onCancelClick = () => {
    this.props.history.push("/admin/payment");
  };

  onSaveClick = () => {
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { id } = this.props.match.params;
      const { status, paymentMethod } = this.state;
      axios
        .put(
          api_address + "api/admin/payment/" + id,
          {
            status,
            paymentMethod,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const { message, status } = res.data;
          this.showNotification(message, status);
        })
        .catch((err) => {
          console.error(err.message);
          this.showNotification(err.message, false);
        });
    } catch (error: any) {
      console.error(error.message);
      this.showNotification(error.message, false);
    }
  };

  render = () => {
    const { t } = this.props;
    const { id } = this.props.match.params;
    const {
      showModal,
      message,
      notificationStatus,
      transactionId,
      userId,
      product,
      stripeId,
      currency,
      paymentMethod,
      couponId,
      couponCode,
      price,
      status,
      createdAt,
      productName,
    } = this.state;

    const isPending = status === "pending";
    return (
      <Container>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />

        <ContentContainer>
          <ContentSubtitle>{t("admin.payment.id")}</ContentSubtitle>
          <Input disabled={true} value={id} />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.payment.transactionId")}</ContentSubtitle>
          <Input disabled={true} value={transactionId} />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.user.id")}</ContentSubtitle>
          <LinkButtonInEdit
            onClick={() => {
              this.props.history.push("/admin/user/edit/" + userId);
            }}
          >
            {userId}
          </LinkButtonInEdit>
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.payment.product")}</ContentSubtitle>
          <LinkButtonInEdit
            onClick={() => {
              this.props.history.push("/admin/product/edit/" + product);
            }}
          >
            {productName}
          </LinkButtonInEdit>
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.payment.stripeId")}</ContentSubtitle>
          <Input disabled={true} value={stripeId} />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.payment.currency")}</ContentSubtitle>
          <Input disabled={true} value={currency} />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>
            {t("admin.payment.paymentMethod.title")}
          </ContentSubtitle>
          {isPending ? (
            <ContentContainer>
              {PAYMENT_METHOD.map((method: string) => {
                return (
                  <PaymentMethodButton
                    isChecked={paymentMethod === method}
                    onClick={() => this.handlePaymentMethodChange(method)}
                  >
                    {method}
                  </PaymentMethodButton>
                );
              })}
            </ContentContainer>
          ) : (
            <Input disabled={true} value={paymentMethod} />
          )}
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.payment.coupon")}</ContentSubtitle>
          <LinkButtonInEdit
            onClick={() => {
              this.props.history.push("/admin/coupon/edit/" + couponId);
            }}
          >
            {couponCode}
          </LinkButtonInEdit>
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.payment.price")}</ContentSubtitle>
          <Input disabled={true} value={price} />
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.payment.status.title")}</ContentSubtitle>

          {isPending ? (
            PAYMENT_STATUS.map((s: string) => {
              return (
                <PaymentMethodButton
                  isChecked={status === s}
                  onClick={() => this.handlePaymentStatusChange(s)}
                >
                  {s}
                </PaymentMethodButton>
              );
            })
          ) : (
            <Input disabled={true} value={status} />
          )}
        </ContentContainer>

        <ContentContainer>
          <ContentSubtitle>{t("admin.payment.createdAt")}</ContentSubtitle>
          <Input disabled={true} value={createdAt} />
        </ContentContainer>

        <ContentContainer>
          <CancelButton onClick={this.onCancelClick}>
            {t("admin.common.cancel")}
          </CancelButton>

          <SaveButton onClick={this.onSaveClick}>
            {t("admin.common.save")}
          </SaveButton>
        </ContentContainer>
      </Container>
    );
  };
}

export default withTranslation()(PaymentEdit);
