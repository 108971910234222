import styled from "styled-components";

export const Container = styled.div`
  width: 60%;
  margin: 0 auto;
  padding: 100px 0;
  text-align: -webkit-center;
  display: flex;
  flex-direction: column;
  align-items: left;
  // height: 650px;
  font-family: Verdana;
  // @media only screen and (min-width: 993px) {
  //   width: 70%;
  // }
  // @media only screen and (min-width: 601px) {
  //   width: 85%;
  // }
`;

export const InputArea = styled.input`
  border-radius: 4px;
  border: solid 1px #58c9df;
  height: 31px;
  width: 80%;
  padding: 0 10px;
  &:focus {
    border-bottom: none;
  }
`;

export const SubmitButton = styled.button`
  width: 112px;
  height: 33px;
  border-radius: 4px;
  background-color: #53d2dc;
  margin-top: 39px;
  border: solid 1px #53d2dc;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  padding: 8px;
`;

export const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2;
  color: #000000;
`;

export const Description = styled.div`
  margin: 14px 0px 34px 0px;
  font-size: 12px;
  color: #8f8f8f;
`;
