import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AppActions } from "types/actions";
import { ThunkDispatch } from "redux-thunk";
import { History, LocationState } from "history";
import { withTranslation, WithTranslation } from "react-i18next";
import { client } from "graphql/ApolloClient";
import {
  TOKEN_LOGIN_MUTATION,
  Variables as TokenLoginVariables,
} from "graphql/mutations/tokenLogin";
import {
  LOGIN_VALIDATION,
  DEVELOPER_LOGIN_SUBMIT,
  LOGIN_SUBMIT,
  JS_COOKIE,
  REGISTER_VALIDATION,
  REGISTER_SUBMIT,
} from "util/auth";
import { GET_TITLE_BAR } from "util/common";
import {
  CompanyName,
  CompanyNameContainer,
  Container,
  Des,
  LoginContainer,
  LoginBackground,
  LoginDesImg,
  LoginIntroContainer,
  Logo,
  TitleContainer,
} from "../styles";
import { startLoginSuccess, startUpdateUserToken } from "../../actions";
import { ROLE, GRAPHQL_RETURN_STATUS } from "../../../../constants/common";
import NotificationModal from "../../../common/NotificationModal";
import LoginForm from "../../UserAuthForm/LoginForm";
import welcomeBack from "../../assets/welcomeBack.svg";
import Logo_Icon from "../../../general/assets/Logo_Icon.png";
import mixpanel from "mixpanel-browser";
import { Viewer, ViewerVariables } from "graphql/queries/types/Viewer";
import { VIEWER_QUERY } from "graphql/queries/viewer";
import i18n from "i18n";
import { OAUTH_ERROR_MESSAGE, OAUTH_METHOD } from "../../constants";

interface LoginProps {
  history: History<LocationState>;
}

interface RoleProps {
  role: string;
  token: string;
}

interface LoginState {
  [key: string]: boolean | string;
  email: string;
  phone: string;
  password: string;
  errors: string;
  countryCode: string;
  isPhone: boolean;
  showModal: boolean;
  notificationStatus: boolean;
}

type Props = LoginProps & LinkDispatchProps & WithTranslation & RoleProps;

class Login extends React.Component<Props, LoginState> {
  constructor(props: Props) {
    super(props);
    this.state = {} as LoginState;
  }

  componentDidMount = async () => {
    try {
      const { t, role, token } = this.props;
      document.title = GET_TITLE_BAR(t, "login", true);

      const viewerRes = await client.query<Viewer, ViewerVariables>({
        query: VIEWER_QUERY,
        variables: {
          eval: true,
        } as ViewerVariables,
      });

      const { viewer } = viewerRes.data;
      if (viewer != null) {
        const { token, metaType } = viewer;
        JS_COOKIE.set("token", token);
        this.props.onUpdateUserToken(token);
        this.props.onLoginSuccess(metaType);
        return;
      }

      if (role === ROLE.user) {
        this.props.history.push("/evaluation");
      } else if (role === ROLE.therapist) {
        this.props.history.push("/therapist");
      }
      if (token != null) {
        const res = await client.mutate({
          mutation: TOKEN_LOGIN_MUTATION,
          variables: {
            token: token,
          } as TokenLoginVariables,
        });
        const loginReturn = res.data.evalTokenLogin;
        const { message, status } = loginReturn.returnMessage;
        if (status === GRAPHQL_RETURN_STATUS.error) {
          throw new Error(message);
        }
        const role = loginReturn.user.metaType;
        if (!status) {
          throw new Error(message);
        }
        JS_COOKIE.set("token", token);
        this.props.onUpdateUserToken(token);
        this.props.onLoginSuccess(role);
        this.props.history.push("/evaluation");
      }
      window.scrollTo(0, 0);
    } catch (error: any) {
      this.displayErrorMessage(error.message);
    }
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    const isEmail = id === "email";
    this.setState({ [id]: isEmail ? value.replace(/\s+/g, "") : value });
  };

  handleCountryCodeChange = (value: string) => {
    if (value != null)
      this.setState({
        countryCode: value,
      });
  };

  setIsPhone = () => {
    this.setState((states) => ({
      isPhone: !states.isPhone,
    }));
  };

  setIsTherapist = () => {
    this.setState((states) => ({
      isTherapist: !states.isTherapist,
    }));
  };

  displayErrorMessage = (message: string) => {
    this.setState({
      errors: message,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  handleGoogleAuth = async (oAuthResponse: any) => {
    if (oAuthResponse != null) {
      const newUser = {
        firstName: oAuthResponse.given_name ?? "",
        lastName: oAuthResponse.family_name ?? "",
        email: oAuthResponse.email,
        phone: "1234567890",
        countryCode: "+1",
        requireOrganizationCode: false,
        organizationCode: "",
        password: oAuthResponse.sub,
        isAgreed: true,
        isOauth: true,
      };
      let user: { token: string; role: string };
      try {
        user = await LOGIN_SUBMIT(
          {
            email: oAuthResponse.email,
            password: oAuthResponse.sub,
          },
          OAUTH_METHOD.GOOGLE
        );
        const { token, role } = user;

        this.props.onUpdateUserToken(token);
        if (role === ROLE.user) {
          this.props.onLoginSuccess(ROLE.user);
          this.props.history.push("/dashboard");
        } else if (role === ROLE.therapist) {
          this.props.onLoginSuccess(ROLE.therapist);
          this.props.history.push("/therapist");
        }
      } catch (error: any) {
        if (error.message === OAUTH_ERROR_MESSAGE) {
          this.displayErrorMessage(i18n.t("message.account.methodError"));
        } else {
          this.props.history.push({
            pathname: "/oauth-register",
            state: { newUser: newUser, redirectLink: "/dashboard" },
          });
        }
      }
    }
  };

  onLoginSubmit = async () => {
    try {
      const { phone, email, countryCode, password, isPhone } = this.state;
      let user: { token: string; role: string };
      if (
        email === "developer@ps.com" &&
        process.env.REACT_APP_ENVIRONMENT === "development"
      ) {
        user = await DEVELOPER_LOGIN_SUBMIT({ email });
      } else {
        const userData = {
          countryCode,
          phone,
          email,
          password,
          isPhone,
        };
        LOGIN_VALIDATION(userData);
        user = await LOGIN_SUBMIT(userData);
      }
      const { token, role } = user;
      this.props.onUpdateUserToken(token);
      if (role === ROLE.user) {
        this.props.onLoginSuccess(ROLE.user);
        this.props.history.push("/evaluation");
      } else if (role === ROLE.therapist) {
        this.props.onLoginSuccess(ROLE.therapist);
        this.props.history.push("/therapist");
      }
    } catch (error: any) {
      mixpanel.track("Login", {
        action: "login failed",
        errorMessage: error.message,
      });
      this.displayErrorMessage(error.message);
    }
  };

  handleRegister = () => {
    this.props.history.push("/register");
  };
  render() {
    const { t } = this.props;
    const { showModal, errors, notificationStatus } = this.state;
    return (
      <Container>
        <NotificationModal
          show={showModal}
          message={errors}
          status={notificationStatus}
        />
        <LoginContainer>
          <LoginIntroContainer>
            <TitleContainer>
              <CompanyNameContainer>
                <Logo src={Logo_Icon} />
                <CompanyName>{t("general.companyName")}</CompanyName>
              </CompanyNameContainer>
              <Des>{t("auth.login.des1")}</Des>
              <Des>{t("auth.login.des2")}</Des>
            </TitleContainer>
            <LoginDesImg src={welcomeBack} />
          </LoginIntroContainer>
          <LoginBackground>
            <LoginForm {...this} {...this.state} {...this.props} />
          </LoginBackground>
        </LoginContainer>
      </Container>
    );
  }
}

//satisfies return values of mapDispatchToProps
interface LinkDispatchProps {
  onLoginSuccess: (data: string) => void;
  onUpdateUserToken: (data: string) => void;
}

const mapStateToProps = (state) => ({
  role: state.role,
  token: state.token,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: LoginProps
): LinkDispatchProps => ({
  onLoginSuccess: bindActionCreators(startLoginSuccess, dispatch),
  onUpdateUserToken: bindActionCreators(startUpdateUserToken, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Login))
);
