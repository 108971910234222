import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  z-index: 999;
  top: 11%;
  left: 50%;
`;

export const Content = styled.div`
  padding: 4px 20px 5px 15px;
  position: fixed;
  background: white;
  height: 30px;
  background-color: ${(props: { status: boolean }) =>
    props.status ? "#37c5ab" : "#e8384f"};
  color: #ffffff;
  border-radius: 15px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: fit-content;
  box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px,
    rgb(0 0 0 / 7%) 0px 4px 8px, rgb(0 0 0 / 7%) 0px 8px 16px,
    rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px;
`;

export const Message = styled.div`
  display: inline-block;
  padding-left: 10px;
`;

export const Image = styled.img`
  display: inline-block;
  vertical-align: middle;
`;
