export const LOGIN_SUCCESS = "userStatus/LOGIN_SUCCESS";
export const LOGIN_FAIL = "userStatus/LOGIN_FAIL";
export const UPDATE_USER_TOKEN = "userStatus/UPDATE_USER_TOKEN";
export const UPDATE_LANGUAGE = "userStatus/UPDATE_LANGUAGE";
export const FINISHED_EVALUATION = "userStatus/FINISHED_EVALUATION";
export const ONGOING_EVALUATION = "userStatus/ONGOING_EVALUATION";
export const OAUTH_ERROR_MESSAGE = "OAuthProblem";
export const OAUTH_METHOD = {
  GOOGLE: "google",
};
