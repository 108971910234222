import styled from "styled-components";

export const MilestonesContainer = styled.div`
  margin-bottom: 10px;
  white-space: pre-line;
`;

export const QuestionContainer = styled.div`
  margin-bottom: 55px;
`;
