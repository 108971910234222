import {
  UPDATE_QUESTIONNAIRE_STEP,
  UPDATE_QUESTIONNAIRE_STEP_DATA,
  UPDATE_QUESTIONNAIRE_META,
  RESET,
  FETCH_QUESTIONNAIRE,
  FETCH_QUESTIONNAIRE_SUCCESS,
  FETCH_QUESTIONNAIRE_FAIL,
  ENABLE_LOADING,
  DISABLE_LOADING,
  SAVE_FAIL,
  SAVE_SUCCESS,
  SAVE,
  FETCH_RESULT,
  FETCH_RESULT_SUCCESS,
  FETCH_RESULT_FAIL,
  REDIRECT,
  REDIRECT_FAIL,
  FETCH_MOST_RECENT_USER_RESULT,
  FETCH_MOST_RECENT_USER_RESULT_SUCCESS,
  FETCH_MOST_RECENT_USER_RESULT_FAIL,
  UPDATE_DATA,
  ENABLE_QUESTIONNAIRE_LOADING,
  DISABLE_QUESTIONNAIRE_LOADING,
} from "./constants";
import { AppActions } from "types/actions";
import { Dispatch } from "redux";
import { AppState } from "storeConfig";

export const save = (data: object, token: string): AppActions => ({
  type: SAVE,
  data,
  token,
});

export const startSave = (data: object, token: string) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(save(data, token));
  };
};

export const saveSuccess = (data: object): AppActions => ({
  type: SAVE_SUCCESS,
  data,
});

export const startSaveSuccess = (data: object) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(saveSuccess(data));
  };
};

export const saveFail = (data: object): AppActions => ({
  type: SAVE_FAIL,
  data,
});

export const startSaveFail = (data: object) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(saveFail(data));
  };
};

export const disableLoading = (): AppActions => ({
  type: DISABLE_LOADING,
});

export const startDisableLoading = () => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(disableLoading());
  };
};

export const enableLoading = (): AppActions => ({
  type: ENABLE_LOADING,
});

export const startEnableLoading = () => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(enableLoading());
  };
};

export const disableQuestionnaireLoading = (): AppActions => ({
  type: DISABLE_QUESTIONNAIRE_LOADING,
});

export const startDisableQuestionnaireLoading = () => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(disableQuestionnaireLoading());
  };
};

export const enableQuestionnaireLoading = (): AppActions => ({
  type: ENABLE_QUESTIONNAIRE_LOADING,
});

export const startEnableQuestionnaireLoading = () => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(enableQuestionnaireLoading());
  };
};

export const updateQuestionnaireMeta = (data: object): AppActions => ({
  type: UPDATE_QUESTIONNAIRE_META,
  data,
});

export const startUpdateQuestionnaireMeta = (data: object) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(updateQuestionnaireMeta(data));
  };
};

export const updateQuestionnaireStepData = (data: object): AppActions => ({
  type: UPDATE_QUESTIONNAIRE_STEP_DATA,
  data,
});

export const startUpdateQuestionnaireStepData = (data: object) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(updateQuestionnaireStepData(data));
  };
};

export const updateQuestionnaireStep = (data: string): AppActions => ({
  type: UPDATE_QUESTIONNAIRE_STEP,
  data,
});

export const startUpdateQuestionnaireStep = (data: string) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(updateQuestionnaireStep(data));
  };
};

export const reset = (): AppActions => ({
  type: RESET,
});

export const startReset = () => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(reset());
  };
};

export const fetchQuestionnaire = (token: string): AppActions => ({
  type: FETCH_QUESTIONNAIRE,
  token,
});

export const startFetchQuestionnaire = (token: string) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(fetchQuestionnaire(token));
  };
};

export const fetchQuestionnaireSuccess = (data: object): AppActions => ({
  type: FETCH_QUESTIONNAIRE_SUCCESS,
  data,
});

export const startFetchQuestionnaireSuccess = (data: object) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(fetchQuestionnaireSuccess(data));
  };
};

export const fetchQuestionnaireFail = (data: object): AppActions => ({
  type: FETCH_QUESTIONNAIRE_FAIL,
  data,
});

export const startFetchQuestionnaireFail = (data: object) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(fetchQuestionnaireFail(data));
  };
};

export const fetchResult = (id: string): AppActions => ({
  type: FETCH_RESULT,
  id,
});

export const startFetchResult = (id: string) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(fetchResult(id));
  };
};

export const fetchResultSuccess = (data: object): AppActions => ({
  type: FETCH_RESULT_SUCCESS,
  data,
});

export const startFetchResultSuccess = (data: object) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(fetchResultSuccess(data));
  };
};

export const fetchResultFail = (data: object): AppActions => ({
  type: FETCH_RESULT_FAIL,
  data,
});

export const startFetchResultFail = (data: object) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(fetchResultFail(data));
  };
};

export const redirect = (path: any): AppActions => ({
  type: REDIRECT,
  path,
});

export const startRedirect = (path: any) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(redirect(path));
  };
};

export const redirectFail = (data: object): AppActions => ({
  type: REDIRECT_FAIL,
  data,
});

export const startRedirectFail = (data: object) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(redirectFail(data));
  };
};

export const fetchMostRecentUserResult = (id: string): AppActions => ({
  type: FETCH_MOST_RECENT_USER_RESULT,
  id,
});

export const startFetchMostRecentUserResult = (id: string) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(fetchMostRecentUserResult(id));
  };
};

export const fetchMostRecentUserResultSuccess = (data: object): AppActions => ({
  type: FETCH_MOST_RECENT_USER_RESULT_SUCCESS,
  data,
});

export const startFetchMostRecentUserResultSuccess = (data: object) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(fetchMostRecentUserResultSuccess(data));
  };
};

export const fetchMostRecentUserResultFail = (data: object): AppActions => ({
  type: FETCH_MOST_RECENT_USER_RESULT_FAIL,
  data,
});

export const startFetchMostRecentUserResultFail = (data: object) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(fetchMostRecentUserResultFail(data));
  };
};

export const updateData = (data: object): AppActions => ({
  type: UPDATE_DATA,
  data,
});

export const startUpdateData = (data: object) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(updateData(data));
  };
};
