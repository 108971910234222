export interface basicInformationType {
  reportID: string;
  submissionTime: string;
  respondent: string;
  respondentOtherText: string;
  reportDate: string;
  childName: string;
  childBirthday: string;
  childBirthdayYear: string;
  childBirthdayMonth: string;
  childBirthdayDay: string;
  postpartumStatus: string;
  postpartumStatusOther: string;
  familyLanguage: string;
  familyLanguageOther: string;
  birthStatus: string;
  sex: string;
  hearingStatus: string;
  eyeStatus: string;
  originalDisease: string;
  babyHealthStatus: string;
  language: string;
}

export const initialBasicInformation = {
  reportID: "",
  submissionTime: "",
  respondent: "",
  respondentOtherText: "",
  reportDate: "",
  childName: "",
  childBirthday: "",
  childBirthdayYear: "",
  childBirthdayMonth: "",
  childBirthdayDay: "",
  postpartumStatus: "",
  postpartumStatusOther: "",
  familyLanguage: "",
  familyLanguageOther: "",
  birthStatus: "",
  sex: "",
  hearingStatus: "",
  eyeStatus: "",
  originalDisease: "",
  babyHealthStatus: "",
  language: "",
};

export interface reviewAndSuggestionType {
  sex: string;
  currentLanguageDevelopment: string;
  canNotUnderstand: string;
  canNotExpressOrSpeak: string;
  canUnderstand: string;
  canExpressOrSpeak: string;
  cognitiveConceptsObserved: string;
  comprehensionReview: string;
  homeActivity: string;
  currentLanguageDevelopmentStatus: number;
  selectedSuggestions: Array<number>;
  otherSuggestion: string;
}

export const initialReviewAndSuggestion = {
  sex: "",
  canNotUnderstand: "",
  canNotExpressOrSpeak: "",
  canUnderstand: "",
  canExpressOrSpeak: "",
  cognitiveConceptsObserved: "",
  comprehensionReview: "",
  homeActivity: "",
  currentLanguageDevelopment: "",
  currentLanguageDevelopmentStatus: 0,
  selectedSuggestions: [],
  otherSuggestion: "",
};

export interface scoreType {
  questionCount: number;
  score: number;
}

export interface generalAnalysisType {
  oralScore: scoreType;
  oralVideo: number;
  oralVideoOther: string;
  oralOverall: number;
  oralOverallOther: string;
  oralAbility: number;
  oralAbilityOther: string;
  speechClarityVideo: number;
  speechClarityVideoOther: string;
  speechClarityOverall: number;
  speechClarityOverallOther: string;
  speechClarityAbility: number;
  speechClarityAbilityOther: string;
  comprehensionScore: scoreType;
  comprehensionVideo: number;
  comprehensionVideoOther: string;
  comprehensionOverall: number;
  comprehensionOverallOther: string;
  comprehensionAbility: number;
  comprehensionAbilityOther: string;
  expressionScore: scoreType;
  expressionVideo: number;
  expressionVideoOther: string;
  expressionOverall: number;
  expressionOverallOther: string;
  expressionAbility: number;
  expressionAbilityOther: string;
  socialInteractionScore: scoreType;
  socialInteractionVideo: number;
  socialInteractionVideoOther: string;
  socialInteractionOverall: number;
  socialInteractionOverallOther: string;
  socialInteractionAbility: number;
  socialInteractionAbilityOther: string;
  earlyReadingSkillsScore: scoreType;
  earlyReadingSkillsVideo: number;
  earlyReadingSkillsVideoOther: string;
  earlyReadingSkillsOverall: number;
  earlyReadingSkillsOverallOther: string;
  earlyReadingSkillsAbility: number;
  earlyReadingSkillsAbilityOther: string;
  childStrength: Array<number>;
  childWeakness: Array<number>;
}

const initialScore = {
  questionCount: 0,
  score: 0,
};

export const initialGeneralAnalysis = {
  oralScore: initialScore,
  oralVideo: -1,
  oralVideoOther: "",
  oralOverall: -1,
  oralOverallOther: "",
  oralAbility: -1,
  oralAbilityOther: "",
  speechClarityVideo: -1,
  speechClarityVideoOther: "",
  speechClarityOverall: -1,
  speechClarityOverallOther: "",
  speechClarityAbility: -1,
  speechClarityAbilityOther: "",
  comprehensionScore: initialScore,
  comprehensionVideo: -1,
  comprehensionVideoOther: "",
  comprehensionOverall: -1,
  comprehensionOverallOther: "",
  comprehensionAbility: -1,
  comprehensionAbilityOther: "",
  expressionScore: initialScore,
  expressionVideo: -1,
  expressionVideoOther: "",
  expressionOverall: -1,
  expressionOverallOther: "",
  expressionAbility: -1,
  expressionAbilityOther: "",
  socialInteractionScore: initialScore,
  socialInteractionVideo: -1,
  socialInteractionVideoOther: "",
  socialInteractionOverall: -1,
  socialInteractionOverallOther: "",
  socialInteractionAbility: -1,
  socialInteractionAbilityOther: "",
  earlyReadingSkillsScore: initialScore,
  earlyReadingSkillsVideo: -1,
  earlyReadingSkillsVideoOther: "",
  earlyReadingSkillsOverall: -1,
  earlyReadingSkillsOverallOther: "",
  earlyReadingSkillsAbility: -1,
  earlyReadingSkillsAbilityOther: "",
  childStrength: [],
  childWeakness: [],
};

export interface videoAnalysisType {
  pictureQuality: number;
  audioQuality: number;
  naturalness: number;
  activityLevel: number;
  activityLevelOther: string;
  activityLevelComment: Array<number>;
  activityLevelCommentOther: string;
  sensitivity: number;
  sensitivityOther: string;
  sensitivityComment: Array<number>;
  sensitivityCommentOther: string;
  distraction: number;
  distractionOther: string;
  distractionComment: Array<number>;
  distractionCommentOther: string;
  speechClarity: number;
  speechClarityOther: string;
  speechClarityComment: Array<number>;
  speechClarityCommentOther: string;
  sentenceLength: number;
  sentenceLengthOther: string;
  sentenceLengthComment: Array<number>;
  sentenceLengthCommentOther: string;
  communication: number;
  communicationOther: string;
  communicationComment: Array<number>;
  communicationCommentOther: string;
  qualityOfInteraction: number;
  qualityOfInteractionOther: string;
  qualityOfInteractionComment: number;
  qualityOfInteractionCommentOther: string;
  qualityOfInteractionImprovement: Array<number>;
  qualityOfInteractionImprovementOther: string;
  encouragement: number;
  encouragementOther: string;
  encouragementComment: number;
  encouragementCommentOther: string;
  encouragementImprovement: Array<number>;
  encouragementImprovementOther: string;
  qualityOfGuide: number;
  qualityOfGuideOther: string;
  qualityOfGuideComment: number;
  qualityOfGuideCommentOther: string;
  qualityOfGuideImprovement: Array<number>;
  qualityOfGuideImprovementOther: string;
  emotion: number;
  emotionOther: string;
  emotionDescription: string;
  emotionImprovement: Array<number>;
  emotionImprovementOther: string;
}

export const initialVideoAnalysis = {
  pictureQuality: -1,
  audioQuality: -1,
  naturalness: -1,
  activityLevel: -1,
  activityLevelOther: "",
  activityLevelComment: [],
  activityLevelCommentOther: "",
  sensitivity: -1,
  sensitivityOther: "",
  sensitivityComment: [],
  sensitivityCommentOther: "",
  distraction: -1,
  distractionOther: "",
  distractionComment: [],
  distractionCommentOther: "",
  speechClarity: -1,
  speechClarityOther: "",
  speechClarityComment: [],
  speechClarityCommentOther: "",
  sentenceLength: -1,
  sentenceLengthOther: "",
  sentenceLengthComment: [],
  sentenceLengthCommentOther: "",
  communication: -1,
  communicationOther: "",
  communicationComment: [],
  communicationCommentOther: "",
  qualityOfInteraction: -1,
  qualityOfInteractionOther: "",
  qualityOfInteractionComment: -1,
  qualityOfInteractionCommentOther: "",
  qualityOfInteractionImprovement: [],
  qualityOfInteractionImprovementOther: "",
  encouragement: -1,
  encouragementOther: "",
  encouragementComment: -1,
  encouragementCommentOther: "",
  encouragementImprovement: [],
  encouragementImprovementOther: "",
  qualityOfGuide: -1,
  qualityOfGuideOther: "",
  qualityOfGuideComment: -1,
  qualityOfGuideCommentOther: "",
  qualityOfGuideImprovement: [],
  qualityOfGuideImprovementOther: "",
  emotion: -1,
  emotionOther: "",
  emotionDescription: "",
  emotionImprovement: [],
  emotionImprovementOther: "",
};

export interface activitiesType {
  activityType0: number;
  activity0: number;
  activityType1: number;
  activity1: number;
  activityType2: number;
  activity2: number;
}

export const initialActivities = {
  activityType0: 0,
  activity0: 0,
  activityType1: 0,
  activity1: 0,
  activityType2: 0,
  activity2: 0,
};

export interface skillsType {
  description: string;
  children: Array<string>;
  parent: Array<string>;
}

const initialSkills = {
  description: "",
  children: [],
  parent: [],
};

export interface activityType {
  name: string;
  age: string;
  skills: skillsType;
  scenarioOrSetup: string;
  frequency: string;
  steps: Array<string>;
  parentReactions: Array<string>;
  notes: Array<string>;
}

export const initialActivity = {
  name: "",
  age: "",
  skills: initialSkills,
  scenarioOrSetup: "",
  frequency: "",
  steps: [],
  parentReactions: [],
  notes: [],
};
