import { useState } from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import axios from "axios";
import api_address from "../../../../../constants/config";
import Cookies from "js-cookie";
import CardSection from "./CardSection";
import { useTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import { PayButton, PayLoader } from "styles";
import { CURRENCY, PRODUCTS } from "constants/common";
type Props = {
  stripe: any;
  elements: any;
} & CheckoutProps;

interface CheckOutButtonType {
  isLoading: boolean;
  product: string;
  currency: string;
  discountedPrice: string;
  isEligibleToPurchaseCPCTrial: boolean;
  handleSubmit: () => void;
}
const CheckOutButton = ({
  isLoading,
  currency,
  discountedPrice,
  isEligibleToPurchaseCPCTrial,
  product,
  handleSubmit,
}: CheckOutButtonType) => {
  const { t } = useTranslation();
  const currencyPresent = currency === CURRENCY.cny ? "¥" : "$";
  const isDisabled =
    !isEligibleToPurchaseCPCTrial && product == PRODUCTS.CPCTrial;
  if (isLoading) {
    return (
      <PayButton>
        {t("user.purchase.payments.checkoutPayment.processing")}
        <PayLoader />
      </PayButton>
    );
  }
  return (
    <PayButton onClick={handleSubmit} disabled={isDisabled}>
      {t("user.purchase.payments.checkoutPayment.pay")}
      {/* {"   " + currencyPresent + discountedPrice} */}
    </PayButton>
  );
};

function CheckoutForm(props: Props) {
  const [isProcessing, setIsProcessing] = useState(false);
  const {
    stripe,
    elements,
    product,
    productId,
    isBuyUp,
    currency,
    coupon,
    discountedPrice,
    isEligibleToPurchaseCPCTrial,
  } = props;

  const handleSubmit = async () => {
    try {
      if (stripe == null || elements == null) return;
      if (product == PRODUCTS.CPCTrial && !isEligibleToPurchaseCPCTrial) return;
      setIsProcessing(true);
      const data = {
        productID: productId,
        currency,
        price: discountedPrice,
        couponCode: coupon,
        isBuyUp,
        paymentMethod: "Credit card",
      };
      const token = Cookies.get("token");
      const checkoutResponse = await axios.post(
        api_address + "api/payment/stripeCheckout",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const {
        message,
        clientSecret,
        paymentIntentID,
        transactionId,
        paymentId,
      } = checkoutResponse.data;
      if (!checkoutResponse.data.status) throw new Error(message);

      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });
      if (error) throw error;
      const notifyResponse = await axios.post(
        api_address + "api/payment/stripeNotify",
        {
          product: data.productID,
          clientSecret,
          paymentIntentID,
          transactionId,
          couponCode: data.couponCode,
          paymentId,
        }
      );
      const { status } = notifyResponse.data;
      if (status === "succeeded") {
        const location = window.location.href.split("/purchases-payment")[0];
        mixpanel.track("Payment", {
          action: "stripe checkout",
        });
        window.location.replace(location + "/evaluation");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form>
      <CardSection />
      <CheckOutButton
        isLoading={isProcessing}
        handleSubmit={handleSubmit}
        {...props}
      />
    </form>
  );
}

type CheckoutProps = {
  product: string;
  productId: string;
  isBuyUp: boolean;
  currency: string;
  coupon: string;
  discountedPrice: string;
  isEligibleToPurchaseCPCTrial: boolean;
};

export default function InjectedCheckoutForm(props: CheckoutProps) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm {...props} stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}
