import React, { Component, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import axios from "axios";
import { withTranslation, WithTranslation } from "react-i18next";
import api_address from "../../../../../constants/config";
import { Range } from "immutable";
import { GET_TITLE_BAR } from "util/common";
import EvalProgressBar from "../../ProgressBar/EvalProgressBar";
import DropDown from "components/common/DropDown";
import NotificationModal from "components/common/NotificationModal";
import {
  RadioLabel,
  RadioInput,
  ChildBirthDayContainer,
  TextArea,
  TextInput,
  ChildRadioContainer,
  OptionText,
  Background,
  Container,
  QuestionText,
  SectionIndicate,
  Segment,
  Title,
  TitleIndicate,
  ButtonContainer,
} from "../../styles";
import { ShowErrNextButton } from "styles";
import Cookies from "js-cookie";
import { Select } from "antd";
import {
  EVAL_STEPS,
  EXPERT_PRODUCTS,
  PRODUCTS_WITH_CHILD_INFO_ONLY,
} from "constants/common";

interface ChildInfoProps {
  handleUpdateMeta: (data: any) => void;
  steps: any;
  currentstep: string;
  currentTransaction: any;
  handleUpdateStepData: (data: object) => void;
  handleFetchResultSuccess: (data: any) => void;
  onReset: () => void;
  handleDisableLoading: () => void;
  onUpdateUserToken: (data: string) => void;
  transactionId: string;
  prevTransactions: any;
  handleUpdateCurrentStep: (data: string) => void;
  product: string;
}

interface ChildInfoState {
  transactionId: string;
  language: string;
  id: string;
  respondentRef: boolean;
  respondentRefOther: boolean;
  sexRef: boolean;
  childNameRef: boolean;
  birthdayRef: boolean;
  worryStatusRef: boolean;
  birthStatusRef: boolean;
  birthStatusRefPreterm: boolean;
  birthStatusRefOther: boolean;
  birthStatusTextRef: boolean;
  birthStatusTextOtherRef: boolean;
  babyHealthStatusRef: boolean;
  babyHealthStatusOtherRef: boolean;
  hearingStatusRef: boolean;
  eyeStatusRef: boolean;
  familyLanguageRef: boolean;
  familyLanguageOtherRef: boolean;
  showErrorMessage: boolean;
  errorMessage: string;
  chosenChildTransactionId: string;
  displayChildInfoForm: boolean;
}

type Props = ChildInfoProps & WithTranslation & RouteComponentProps;

class ChildInfo extends Component<Props, ChildInfoState> {
  private respondentRef: React.RefObject<HTMLInputElement>;
  private sex: React.RefObject<HTMLInputElement>;
  private childNameRef: React.RefObject<HTMLInputElement>;
  private birthdayRef: React.RefObject<HTMLInputElement>;
  private worryStatusRef: React.RefObject<HTMLInputElement>;
  private birthStatusRef: React.RefObject<HTMLInputElement>;
  private birthStatusTextRef: React.RefObject<HTMLInputElement>;
  private babyHealthStatusRef: React.RefObject<HTMLInputElement>;
  private babyHealthStatusOtherRef: React.RefObject<HTMLInputElement>;
  private hearingStatusRef: React.RefObject<HTMLInputElement>;
  private eyeStatusRef: React.RefObject<HTMLInputElement>;
  private familyLanguageRef: React.RefObject<HTMLInputElement>;
  constructor(props: Props) {
    super(props);
    this.state = {
      transactionId: "",
      language: "",
      id: "",
      respondentRef: false,
      respondentRefOther: false,
      sexRef: false,
      childNameRef: false,
      birthdayRef: false,
      worryStatusRef: false,
      birthStatusRef: false,
      birthStatusRefPreterm: false,
      birthStatusRefOther: false,
      birthStatusTextRef: false,
      birthStatusTextOtherRef: false,
      babyHealthStatusRef: false,
      babyHealthStatusOtherRef: false,
      hearingStatusRef: false,
      eyeStatusRef: false,
      familyLanguageRef: false,
      familyLanguageOtherRef: false,
      showErrorMessage: false,
      errorMessage: "",
      chosenChildTransactionId: "",
      displayChildInfoForm: false,
    };
    this.respondentRef = React.createRef();
    this.sex = React.createRef();
    this.childNameRef = React.createRef();
    this.birthdayRef = React.createRef();
    this.worryStatusRef = React.createRef();
    this.birthStatusRef = React.createRef();
    this.birthStatusTextRef = React.createRef();
    this.babyHealthStatusRef = React.createRef();
    this.babyHealthStatusOtherRef = React.createRef();
    this.hearingStatusRef = React.createRef();
    this.eyeStatusRef = React.createRef();
    this.familyLanguageRef = React.createRef();
  }

  componentDidMount = () => {
    const { t, transactionId } = this.props;
    document.title = GET_TITLE_BAR(t, "childInfo", false);
    this.props.handleUpdateMeta({ transactionId: transactionId });
  };

  handleMultipleCheck = (event) => {
    const { steps, currentstep } = this.props;
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let finalSteps = steps;

    const originalVal = steps.getIn([currentstep, "values", name], false);

    if (
      !originalVal ||
      !steps.getIn([currentstep, "values", name, value], false)
    ) {
      finalSteps = steps.setIn([currentstep, "values", name, value], true);
    } else {
      finalSteps = steps.setIn([currentstep, "values", name, value], false);
    }
    this.props.handleUpdateStepData(finalSteps);
  };

  handleChildBirthdayYearChange = (value: string) => {
    if (value != null) {
      const { steps, currentstep } = this.props;
      const name = "childBirthdayYear";
      let finalSteps = steps;
      finalSteps = steps.setIn([currentstep, "values", name], value);
      this.props.handleUpdateStepData(finalSteps);
    }
  };

  handleChildBirthdayMonthChange = (value: string) => {
    if (value != null) {
      const { steps, currentstep } = this.props;
      const name = "childBirthdayMonth";
      let finalSteps = steps;
      finalSteps = steps.setIn([currentstep, "values", name], value);
      this.props.handleUpdateStepData(finalSteps);
    }
  };

  handleChildBirthdayDayChange = (value: string) => {
    if (value != null) {
      const { steps, currentstep } = this.props;
      const name = "childBirthdayDay";
      let finalSteps = steps;
      finalSteps = steps.setIn([currentstep, "values", name], value);
      this.props.handleUpdateStepData(finalSteps);
    }
  };

  handleCheck = (event) => {
    const { steps, currentstep } = this.props;
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let finalSteps = steps;
    const originalVal = steps.getIn([currentstep, "values", name], false);
    if (!originalVal || originalVal !== value) {
      finalSteps = steps.setIn([currentstep, "values", name], value);
    } else {
      finalSteps = steps.removeIn([currentstep, "values", name], false);
    }

    if (name === "respondent" && value !== 3) {
      finalSteps = finalSteps.setIn(
        [currentstep, "values", "respondentOtherText"],
        ""
      );
    }
    if (name === "birthStatus" && value !== 2 && value !== 3) {
      finalSteps = finalSteps.setIn(
        [currentstep, "values", "birthStatusOtherText"],
        ""
      );
    }
    this.props.handleUpdateStepData(finalSteps);
  };

  getBirthdayDropdown = () => {
    const { currentstep, steps, t } = this.props;

    const values = steps.getIn([currentstep, "values"]);

    const today = new Date();
    const childBirthYear = values.get("childBirthdayYear");
    const childBirthMonth = values.get("childBirthdayMonth");
    const childBirthDay = values.get("childBirthdayDay");

    type optionType = { text: string; value: string };

    const years: optionType[] = Range(1990, today.getFullYear() + 1)
      .reverse()
      .map((x: number) => ({ text: x.toString(), value: x.toString() }))
      .toArray();

    const monthList =
      childBirthYear === today.getFullYear().toString()
        ? Range(1, today.getMonth() + 2)
        : Range(1, 13);
    const months: optionType[] = monthList
      .map((x: number) => ({ text: x.toString(), value: x.toString() }))
      .toArray();

    const daysInMonth =
      childBirthYear != null && childBirthMonth != null
        ? new Date(childBirthYear, childBirthMonth, 0).getDate()
        : 31;
    const dayList =
      childBirthYear === today.getFullYear().toString() &&
      childBirthMonth === (today.getMonth() + 1).toString()
        ? Range(1, today.getDate())
        : Range(1, daysInMonth + 1);
    const days: optionType[] = dayList
      .map((x: number) => ({ text: x.toString(), value: x.toString() }))
      .toArray();

    return (
      <>
        <QuestionText
          ref={this.birthdayRef}
          style={this.state.birthdayRef ? { color: "red" } : { color: "black" }}
        >
          {t("user.survey.core.questionnaire.child_info.birthday.title")}
        </QuestionText>
        <ChildBirthDayContainer id="Container">
          <DropDown
            componentName="Child info"
            type="Year"
            size="small"
            defaultSelection={childBirthYear}
            selectedText={childBirthYear}
            options={years}
            onOptionChange={this.handleChildBirthdayYearChange}
          />

          <DropDown
            componentName="Child info"
            type="Month"
            size="small"
            defaultSelection={childBirthMonth}
            selectedText={childBirthMonth}
            options={months}
            onOptionChange={this.handleChildBirthdayMonthChange}
          />

          <DropDown
            componentName="Child info"
            type="Day"
            size="small"
            defaultSelection={childBirthDay}
            selectedText={childBirthDay}
            options={days}
            onOptionChange={this.handleChildBirthdayDayChange}
          />
        </ChildBirthDayContainer>
      </>
    );
  };

  childNameValid = () => {
    const { steps, currentstep } = this.props;
    const childName = steps.getIn([currentstep, "values", "childName"], false);
    if (!childName) {
      return false;
    }
    return true;
  };

  birthdayValid = (): boolean => {
    try {
      const { steps, currentstep } = this.props;
      const childBirthdayDay = steps.getIn([
        currentstep,
        "values",
        "childBirthdayDay",
      ]);
      if (childBirthdayDay == null) {
        throw new Error("child birthday day is empty");
      }
      const childBirthdayMonth = steps.getIn([
        currentstep,
        "values",
        "childBirthdayMonth",
      ]);
      if (childBirthdayMonth == null) {
        throw new Error("child birthday month is empty");
      }
      const childBirthdayYear = steps.getIn([
        currentstep,
        "values",
        "childBirthdayYear",
      ]);
      if (childBirthdayYear == null) {
        throw new Error("child birthday year is empty");
      }
      const birthday = new Date(
        childBirthdayYear,
        +childBirthdayMonth - 1,
        childBirthdayDay
      );
      const today = new Date();
      if (birthday > today) {
        throw new Error("child birthday invalid");
      }
      return true;
    } catch (err) {
      return false;
    }
  };

  worryStatusValid = () => {
    const { steps, currentstep } = this.props;
    const worryStatus = steps.getIn(
      [currentstep, "values", "worryStatus"],
      false
    );
    if (!worryStatus) {
      return false;
    }
    return true;
  };

  respondentValid = () => {
    const { steps, currentstep } = this.props;
    const respondent = steps.getIn(
      [currentstep, "values", "respondent"],
      false
    );
    if (!respondent) {
      return false;
    }
    return true;
  };

  respondentOtherValid = () => {
    const { steps, currentstep } = this.props;
    const respondent = steps.getIn(
      [currentstep, "values", "respondent"],
      false
    );
    const respondentOtherText = steps.getIn(
      [currentstep, "values", "respondentOtherText"],
      false
    );
    if (respondent && respondent === "3" && !respondentOtherText) {
      return false;
    }
    return true;
  };

  questionSexValid = () => {
    const { steps, currentstep } = this.props;
    const sex = steps.getIn([currentstep, "values", "sex"], false);
    if (!sex) {
      return false;
    }
    return true;
  };

  birthStatusValid = () => {
    const { steps, currentstep } = this.props;
    const birthStatus = steps.getIn(
      [currentstep, "values", "birthStatus"],
      false
    );
    if (!birthStatus) {
      return false;
    }
    return true;
  };

  birthStatusPretermValid = () => {
    const { steps, currentstep } = this.props;
    const birthStatus = steps.getIn([currentstep, "values", "birthStatus"]);
    const birthStatusOtherText = steps.getIn([
      currentstep,
      "values",
      "birthStatusOtherText",
    ]);
    if (
      birthStatus == null ||
      ((birthStatus === "2" || birthStatus === "3") &&
        (birthStatusOtherText == null || birthStatusOtherText === ""))
    ) {
      return false;
    }
    return true;
  };

  birthStatusOtherValid = () => {
    const { steps, currentstep } = this.props;
    const birthStatus = steps.getIn([currentstep, "values", "birthStatus"]);
    const birthStatusOtherText = steps.getIn([
      currentstep,
      "values",
      "birthStatusOtherText",
    ]);
    if (
      birthStatus == null &&
      (birthStatus === "2" || birthStatus === "3") &&
      (birthStatusOtherText == null || birthStatusOtherText === "")
    ) {
      return false;
    }
    return true;
  };

  postpartumStatusValid = () => {
    const { steps, currentstep } = this.props;
    const postpartumStatus = steps.getIn([
      currentstep,
      "values",
      "postpartumStatus",
    ])
      ? steps.getIn([currentstep, "values", "postpartumStatus"]).toJS()
      : {};
    if (Object.values(postpartumStatus).filter((r) => r).length === 0) {
      return false;
    }
    return true;
  };

  postpartumStatusOtherValid = () => {
    const { steps, currentstep } = this.props;
    const postpartumStatus = steps.getIn([
      currentstep,
      "values",
      "postpartumStatus",
    ])
      ? steps.getIn([currentstep, "values", "postpartumStatus"]).toJS()
      : {};
    const postpartumStatusOther = steps.getIn(
      [currentstep, "values", "postpartumStatusOther"],
      false
    );
    if (
      ((postpartumStatus[2] ?? false) ||
        (postpartumStatus[3] ?? false) ||
        (postpartumStatus[4] ?? false) ||
        (postpartumStatus[5] ?? false)) &&
      !postpartumStatusOther
    ) {
      return false;
    }
    return true;
  };

  babyHealthStatusValid = () => {
    const { steps, currentstep } = this.props;
    const babyHealthStatus = steps.getIn([
      currentstep,
      "values",
      "babyHealthStatus",
    ])
      ? steps.getIn([currentstep, "values", "babyHealthStatus"]).toJS()
      : {};
    if (Object.values(babyHealthStatus).filter((r) => r).length === 0) {
      return false;
    }
    return true;
  };

  babyHealthStatusOtherValid = () => {
    const { steps, currentstep } = this.props;
    const babyHealthStatus = steps.getIn([
      currentstep,
      "values",
      "babyHealthStatus",
    ])
      ? steps.getIn([currentstep, "values", "babyHealthStatus"]).toJS()
      : {};
    const babyHealthStatusOther = steps.getIn(
      [currentstep, "values", "babyHealthStatusOther"],
      false
    );
    if (
      ((babyHealthStatus[1] ?? false) ||
        (babyHealthStatus[2] ?? false) ||
        (babyHealthStatus[3] ?? false) ||
        (babyHealthStatus[4] ?? false)) &&
      !babyHealthStatusOther
    ) {
      return false;
    }
    return true;
  };

  hearingStatusValid = () => {
    const { steps, currentstep } = this.props;
    const hearingStatus = steps.getIn(
      [currentstep, "values", "hearingStatus"],
      false
    );
    if (!hearingStatus) {
      return false;
    }
    return true;
  };

  eyeStatusValid = () => {
    const { steps, currentstep } = this.props;
    const eyeStatus = steps.getIn([currentstep, "values", "eyeStatus"], false);
    if (!eyeStatus) {
      return false;
    }
    return true;
  };

  familyLanguageValid = () => {
    const { steps, currentstep } = this.props;
    const familyLanguage = steps.getIn([
      currentstep,
      "values",
      "familyLanguage",
    ])
      ? steps.getIn([currentstep, "values", "familyLanguage"]).toJS()
      : {};
    if (Object.values(familyLanguage).filter((r) => r).length === 0) {
      return false;
    }
    return true;
  };

  familyLanguageOtherValid = () => {
    const { steps, currentstep } = this.props;
    const familyLanguage = steps.getIn([
      currentstep,
      "values",
      "familyLanguage",
    ])
      ? steps.getIn([currentstep, "values", "familyLanguage"]).toJS()
      : {};
    const familyLanguageOther = steps.getIn(
      [currentstep, "values", "familyLanguageOther"],
      false
    );
    if ((familyLanguage[3] ?? false) && !familyLanguageOther) {
      return false;
    }
    return true;
  };

  isValid = () => {
    const respondentValid = this.respondentValid();
    const respondentOtherValid = this.respondentOtherValid();
    const childNameValid = this.childNameValid();
    const birthdayValid = this.birthdayValid();
    const questionSexValid = this.questionSexValid();
    const familyLanguageValid = this.familyLanguageValid();
    const familyLanguageOtherValid = this.familyLanguageOtherValid();
    const worryStatusValid = this.worryStatusValid();
    const birthStatusValid = this.birthStatusValid();
    const babyHealthStatusValid = this.babyHealthStatusValid();
    const babyHealthStatusOtherValid = this.babyHealthStatusOtherValid();
    const postpartumStatusValid = this.postpartumStatusValid();
    const postpartumStatusOtherValid = this.postpartumStatusOtherValid();
    const birthStatusPretermValid = this.birthStatusPretermValid();
    const birthStatusOtherValid = this.birthStatusOtherValid();
    const hearingStatusValid = this.hearingStatusValid();
    const eyeStatusValid = this.eyeStatusValid();
    return (
      childNameValid &&
      birthdayValid &&
      worryStatusValid &&
      respondentValid &&
      respondentOtherValid &&
      questionSexValid &&
      birthStatusValid &&
      birthStatusPretermValid &&
      birthStatusOtherValid &&
      postpartumStatusValid &&
      postpartumStatusOtherValid &&
      babyHealthStatusValid &&
      babyHealthStatusOtherValid &&
      hearingStatusValid &&
      eyeStatusValid &&
      familyLanguageValid &&
      familyLanguageOtherValid
    );
  };

  //used by submit button disable(on/off) to check if all required inputs have been inputted
  isValidAndGetCoordinate = () => {
    let firstInvalidCoordinate = 0;
    let valid = true;
    if (!this.childNameValid()) {
      valid = false;
      this.setState({ childNameRef: true });
      if (firstInvalidCoordinate === 0 && this.childNameRef.current != null) {
        firstInvalidCoordinate = this.childNameRef.current.offsetTop;
      }
    } else {
      this.setState({ childNameRef: false });
    }
    if (!this.birthdayValid()) {
      valid = false;
      this.setState({ birthdayRef: true });
      if (firstInvalidCoordinate === 0 && this.birthdayRef.current != null) {
        firstInvalidCoordinate = this.birthdayRef.current.offsetTop;
      }
    } else {
      this.setState({ birthdayRef: false });
    }
    if (!this.worryStatusValid()) {
      valid = false;
      this.setState({ worryStatusRef: true });
      if (firstInvalidCoordinate === 0 && this.worryStatusRef.current != null) {
        firstInvalidCoordinate = this.worryStatusRef.current.offsetTop;
      }
    } else {
      this.setState({ worryStatusRef: false });
    }
    if (!this.respondentValid()) {
      valid = false;
      this.setState({ respondentRef: true });
      if (firstInvalidCoordinate === 0 && this.respondentRef.current != null) {
        firstInvalidCoordinate = this.respondentRef.current.offsetTop;
      }
    } else {
      this.setState({ respondentRef: false });
    }
    if (!this.respondentOtherValid()) {
      valid = false;
      this.setState({ respondentRefOther: true });
      if (firstInvalidCoordinate === 0 && this.respondentRef.current != null) {
        firstInvalidCoordinate = this.respondentRef.current.offsetTop;
      }
    } else {
      this.setState({ respondentRefOther: false });
    }
    if (!this.questionSexValid()) {
      valid = false;
      this.setState({ sexRef: true });
      if (firstInvalidCoordinate === 0 && this.sex.current != null) {
        firstInvalidCoordinate = this.sex.current.offsetTop;
      }
    } else {
      this.setState({ sexRef: false });
    }
    if (!this.birthStatusValid()) {
      valid = false;
      this.setState({ birthStatusRef: true });
      if (firstInvalidCoordinate === 0 && this.birthStatusRef.current != null) {
        firstInvalidCoordinate = this.birthStatusRef.current.offsetTop;
      }
    } else {
      this.setState({ birthStatusRef: false });
    }
    if (!this.birthStatusPretermValid()) {
      valid = false;
      this.setState({ birthStatusRefPreterm: true });
      if (firstInvalidCoordinate === 0 && this.birthStatusRef.current != null) {
        firstInvalidCoordinate = this.birthStatusRef.current.offsetTop;
      }
    } else {
      this.setState({ birthStatusRefPreterm: false });
    }
    if (!this.birthStatusOtherValid()) {
      valid = false;
      this.setState({ birthStatusRefOther: true });
      if (firstInvalidCoordinate === 0 && this.birthStatusRef.current != null) {
        firstInvalidCoordinate = this.birthStatusRef.current.offsetTop;
      }
    } else {
      this.setState({ birthStatusRefOther: false });
    }
    if (!this.postpartumStatusValid()) {
      valid = false;
      this.setState({ birthStatusTextRef: true });
      if (
        firstInvalidCoordinate === 0 &&
        this.birthStatusTextRef.current != null
      ) {
        firstInvalidCoordinate = this.birthStatusTextRef.current.offsetTop;
      }
    } else {
      this.setState({ birthStatusTextRef: false });
    }
    if (!this.postpartumStatusOtherValid()) {
      valid = false;
      this.setState({ birthStatusTextOtherRef: true });
      if (
        firstInvalidCoordinate === 0 &&
        this.birthStatusTextRef.current != null
      ) {
        firstInvalidCoordinate = this.birthStatusTextRef.current.offsetTop;
      }
    } else {
      this.setState({ birthStatusTextOtherRef: false });
    }
    if (!this.babyHealthStatusValid()) {
      valid = false;
      this.setState({ babyHealthStatusRef: true });
      if (
        firstInvalidCoordinate === 0 &&
        this.babyHealthStatusRef.current != null
      ) {
        firstInvalidCoordinate = this.babyHealthStatusRef.current.offsetTop;
      }
    } else {
      this.setState({ babyHealthStatusRef: false });
    }
    if (!this.babyHealthStatusOtherValid()) {
      valid = false;
      this.setState({ babyHealthStatusOtherRef: true });
      if (
        firstInvalidCoordinate === 0 &&
        this.babyHealthStatusOtherRef.current != null
      ) {
        firstInvalidCoordinate =
          this.babyHealthStatusOtherRef.current.offsetTop;
      }
    } else {
      this.setState({ babyHealthStatusOtherRef: false });
    }
    if (!this.hearingStatusValid()) {
      valid = false;
      this.setState({ hearingStatusRef: true });
      if (
        firstInvalidCoordinate === 0 &&
        this.hearingStatusRef.current != null
      ) {
        firstInvalidCoordinate = this.hearingStatusRef.current.offsetTop;
      }
    } else {
      this.setState({ hearingStatusRef: false });
    }
    if (!this.eyeStatusValid()) {
      valid = false;
      this.setState({ eyeStatusRef: true });
      if (firstInvalidCoordinate === 0 && this.eyeStatusRef.current != null) {
        firstInvalidCoordinate = this.eyeStatusRef.current.offsetTop;
      }
    } else {
      this.setState({ eyeStatusRef: false });
    }
    if (!this.familyLanguageValid()) {
      valid = false;
      this.setState({ familyLanguageRef: true });
      if (
        firstInvalidCoordinate === 0 &&
        this.familyLanguageRef.current != null
      ) {
        firstInvalidCoordinate = this.familyLanguageRef.current.offsetTop;
      }
    } else {
      this.setState({ familyLanguageRef: false });
    }
    if (!this.familyLanguageOtherValid()) {
      valid = false;
      this.setState({ familyLanguageOtherRef: true });
      if (
        firstInvalidCoordinate === 0 &&
        this.familyLanguageRef.current != null
      ) {
        firstInvalidCoordinate = this.familyLanguageRef.current.offsetTop;
      }
    } else {
      this.setState({ familyLanguageOtherRef: false });
    }
    return { firstInvalidCoordinate, valid };
  };

  isChosenChildValid = () => {
    const { currentTransaction, prevTransactions } = this.props;
    const token = Cookies.get("token") as string;
    this.props.onReset();
    if (!token) {
      this.props.history.push("/login");
    }
    const chosenTransaction = prevTransactions.filter(
      (transaction) =>
        transaction.transactionid == this.state.chosenChildTransactionId
    );
    currentTransaction.steps.child_info = chosenTransaction[0].steps.child_info;
    this.props.onUpdateUserToken(token);
    this.props.handleFetchResultSuccess(currentTransaction);
    this.props.handleDisableLoading();
    this.handleNext();
  };

  createMockVideoRecord = async () => {
    const { transactionId } = this.props;
    const videoData = {
      transactionid: transactionId,
      video1: "",
      video2: "",
      video1comment: "cpcTeleHealth",
      video2comment: "cpcTeleHealth",
      video1aws: false,
      video2aws: false,
    };

    const videoResponse = await axios.post(
      api_address + "api/results/video",
      videoData
    );

    const { result } = videoResponse.data;

    if (!result) {
      throw new Error("Video submission failed");
    }

    const videoSectionData = { transactionid: transactionId };

    await axios.post(
      api_address + "api/results/videoSectionSubmit",
      videoSectionData
    );
  };

  handleChooseChild = async () => {
    const { currentTransaction, prevTransactions } = this.props;
    const { transactionId, steps, currentstep } = this.props;
    if (
      this.state.chosenChildTransactionId == currentTransaction.transactionid
    ) {
      this.setState({ displayChildInfoForm: true });
    } else {
      this.setState({ displayChildInfoForm: false });
      const token = Cookies.get("token") as string;
      this.props.onReset();
      if (!token) {
        this.props.history.push("/login");
      }
      setTimeout(async () => {
        const chosenTransaction = prevTransactions.filter(
          (transaction) =>
            transaction.transactionid == this.state.chosenChildTransactionId
        );
        currentTransaction.steps.child_info =
          chosenTransaction[0].steps.child_info;

        const childInfo = currentTransaction.steps.child_info.values;
        const chosenChildData = {
          babyHealthStatus: childInfo.babyHealthStatus,
          birthStatus: childInfo.birthStatus,
          birthStatusOtherText: childInfo.birthStatusOtherText,
          childBirthdayDay: childInfo.childBirthdayDay,
          childBirthdayMonth: childInfo.childBirthdayMonth,
          childBirthdayYear: childInfo.childBirthdayYear,
          childName: childInfo.childName,
          eyeStatus: childInfo.eyeStatus,
          familyLanguage: childInfo.familyLanguage,
          hearingStatus: childInfo.hearingStatus,
          postpartumStatus: childInfo.postpartumStatus,
          respondent: childInfo.respondent,
          respondentOtherText: childInfo.respondentOtherText,
          sex: childInfo.sex,
          worryStatus: childInfo.worryStatus,
          transactionId: currentTransaction.transactionid,
        };

        this.props.onUpdateUserToken(token);
        this.props.handleFetchResultSuccess(currentTransaction);
        this.props.handleDisableLoading();

        let finalSteps = steps;
        let childSteps = steps.getIn([currentstep, "values"]);
        childSteps = childSteps.setIn(["transactionId"], transactionId);

        const res = await axios.post(
          api_address + "api/results/child_info",
          chosenChildData
        );
        const { status, message } = res.data;
        if (!status) {
          throw new Error(message);
        }
        const { next, nextComponent } = res.data;
        if (next === "questionnaire") {
          finalSteps = finalSteps.setIn([currentstep, "values"], childSteps);
          finalSteps = finalSteps.setIn(
            [next, "values", "current"],
            nextComponent
          );
          this.props.handleUpdateStepData(finalSteps);
        }
        if (PRODUCTS_WITH_CHILD_INFO_ONLY.includes(this.props.product)) {
          await this.createMockVideoRecord();
          this.props.history.push("/waiting-section/" + transactionId); // MAKE IT GO TO NEXT STEP TO /
        }
        this.props.handleUpdateCurrentStep(next);
      }, 2000);
    }
  };

  handleNext = async () => {
    try {
      const { transactionId, steps, currentstep } = this.props;
      const { valid } = this.isValidAndGetCoordinate();
      if (!valid) {
        throw new Error("有部分必填题目尚未回答");
      }
      let finalSteps = steps;
      let childSteps = steps.getIn([currentstep, "values"]);
      childSteps = childSteps.setIn(["transactionId"], transactionId);
      const res = await axios.post(
        api_address + "api/results/child_info",
        childSteps
      );
      const { status, message } = res.data;
      if (!status) {
        throw new Error(message);
      }
      const { next, nextComponent } = res.data;
      if (next === "questionnaire") {
        finalSteps = finalSteps.setIn([currentstep, "values"], childSteps);
        finalSteps = finalSteps.setIn(
          [next, "values", "current"],
          nextComponent
        );
        this.props.handleUpdateStepData(finalSteps);
      }
      if (PRODUCTS_WITH_CHILD_INFO_ONLY.includes(this.props.product)) {
        await this.createMockVideoRecord();
        this.props.history.push("/waiting-section/" + transactionId); // MAKE IT GO TO NEXT STEP TO /
      }
      this.props.handleUpdateCurrentStep(next);
    } catch (err: any) {
      this.displayErrorMessage(err.message);
      window.scrollTo(0, 0);
    }
  };

  displayErrorMessage = (message: string) => {
    this.setState({ errorMessage: message, showErrorMessage: true });
    setTimeout(() => {
      this.setState({ showErrorMessage: false });
    }, 3000);
  };

  handleChange = (value: string) => {
    this.setState({ chosenChildTransactionId: value });
  };

  render() {
    const { currentstep, steps, t } = this.props;
    const {
      respondentRef,
      respondentRefOther,
      sexRef,
      childNameRef,
      worryStatusRef,
      birthStatusRef,
      birthStatusRefPreterm,
      birthStatusRefOther,
      birthStatusTextRef,
      birthStatusTextOtherRef,
      babyHealthStatusRef,
      babyHealthStatusOtherRef,
      hearingStatusRef,
      eyeStatusRef,
      familyLanguageRef,
      familyLanguageOtherRef,
      showErrorMessage,
      errorMessage,
    } = this.state;
    const values = steps.getIn([currentstep, "values"]);
    const worryStatus = values.get("worryStatus");
    const childName = values.get("childName");
    const respondent = steps.getIn([currentstep, "values", "respondent"]);
    const birthStatus = steps.getIn([currentstep, "values", "birthStatus"]);
    const sex = steps.getIn([currentstep, "values", "sex"]);
    const postpartumStatus = steps.getIn([
      currentstep,
      "values",
      "postpartumStatus",
    ])
      ? steps.getIn([currentstep, "values", "postpartumStatus"]).toJS()
      : {};
    const babyHealthStatus = steps.getIn([
      currentstep,
      "values",
      "babyHealthStatus",
    ])
      ? steps.getIn([currentstep, "values", "babyHealthStatus"]).toJS()
      : {};
    const hearingStatus = steps.getIn([currentstep, "values", "hearingStatus"]);
    const eyeStatus = steps.getIn([currentstep, "values", "eyeStatus"]);
    const familyLanguage = steps.getIn([
      currentstep,
      "values",
      "familyLanguage",
    ])
      ? steps.getIn([currentstep, "values", "familyLanguage"]).toJS()
      : {};

    type optionType = { label: string; value: string };
    let uniqueChild = new Array();

    this.props.prevTransactions.map((transaction) => {
      const child_info = transaction.steps.child_info.values;
      const dob =
        child_info.childBirthdayDay +
        "-" +
        child_info.childBirthdayMonth +
        "-" +
        child_info.childBirthdayYear;
      uniqueChild.length > 0
        ? uniqueChild.map((child) => {
            if (!(child.name == child_info.childName && child.dob == dob)) {
              uniqueChild.push({
                name: child_info.childName,
                dob: dob,
                transactionId: transaction.transactionid,
              });
            }
          })
        : uniqueChild.push({
            name: child_info.childName,
            dob: dob,
            transactionId: transaction.transactionid,
          });
    });
    const childOptions: optionType[] =
      uniqueChild.length > 0
        ? uniqueChild.map((child) => ({
            label: child.name + " (" + child.dob + ")",
            value: child.transactionId,
          }))
        : [];
    childOptions.push({
      label: "+ Add new child",
      value: this.props.transactionId,
    });
    return (
      <Background>
        <EvalProgressBar step="child_info" product={this.props.product} />
        <NotificationModal
          show={showErrorMessage}
          message={errorMessage}
          status={false}
        />
        <Container>
          {/* TODO can be removed after all components are using styled */}
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.min.css"
          />
          <Segment>
            <TitleIndicate />
            <Title>
              {t("user.survey.core.questionnaire.child_info.title")}
            </Title>
          </Segment>
          {this.props.prevTransactions.length > 0 &&
          this.state.displayChildInfoForm == false ? (
            <>
              <Segment>
                <SectionIndicate>
                  <QuestionText style={{ color: "white" }}>
                    {t(
                      "user.survey.core.questionnaire.child_info.childDropdown"
                    )}
                  </QuestionText>
                </SectionIndicate>
                <Select
                  style={{ marginTop: "45px", width: "100%" }}
                  onChange={this.handleChange}
                  placeholder={t(
                    "user.survey.core.questionnaire.child_info.childDropdown"
                  )}
                  options={childOptions}
                />
              </Segment>
              <ButtonContainer>
                <ShowErrNextButton
                  status={this.isValid()}
                  onClick={this.handleChooseChild}
                >
                  {t("user.survey.core.questionnaire.child_info.submit")}
                </ShowErrNextButton>
              </ButtonContainer>
            </>
          ) : (
            <>
              <Segment style={{ padding: "72px 24px 24px 24px" }}>
                <SectionIndicate>
                  <QuestionText style={{ color: "white" }}>
                    {t(
                      "user.survey.core.questionnaire.child_info.basicInformation"
                    )}
                  </QuestionText>
                </SectionIndicate>
                <QuestionText
                  ref={this.respondentRef}
                  style={
                    respondentRef || respondentRefOther
                      ? { color: "red" }
                      : { color: "black" }
                  }
                >
                  {t(
                    "user.survey.core.questionnaire.child_info.respondent.title"
                  )}
                </QuestionText>
                <ChildRadioContainer>
                  {[1, 2, 3].map((i: number) => {
                    return (
                      <RadioLabel checked={respondent === i.toString()}>
                        <RadioInput
                          name="respondent"
                          type="radio"
                          value={`${i}`}
                          onChange={this.handleCheck}
                        />
                        {i !== 3 ? (
                          <OptionText>
                            {t(
                              `user.survey.core.questionnaire.child_info.respondent.${i}`
                            )}
                          </OptionText>
                        ) : (
                          <OptionText>
                            {t(
                              "user.survey.core.questionnaire.child_info.respondent.3"
                            )}
                          </OptionText>
                        )}
                      </RadioLabel>
                    );
                  })}
                  {+respondent === 3 && (
                    <TextInput
                      type="text"
                      name="respondentOtherText"
                      onChange={this.handleCheck}
                      isEmpty={respondentRefOther}
                      placeholder={t(
                        "user.survey.core.questionnaire.child_info.respondent.3_text"
                      )}
                    />
                  )}
                </ChildRadioContainer>
              </Segment>
              <Segment>
                <QuestionText
                  ref={this.childNameRef}
                  style={childNameRef ? { color: "red" } : { color: "black" }}
                >
                  {t("user.survey.core.questionnaire.child_info.childName")}
                </QuestionText>
                <TextInput
                  type="text"
                  name="childName"
                  onChange={this.handleCheck}
                  defaultValue={childName}
                  isEmpty={childNameRef}
                  placeholder={t(
                    "user.survey.core.questionnaire.child_info.childName"
                  )}
                />
              </Segment>
              <Segment>{this.getBirthdayDropdown()}</Segment>
              <Segment>
                <QuestionText
                  ref={this.sex}
                  style={sexRef ? { color: "red" } : { color: "black" }}
                >
                  {t("user.survey.core.questionnaire.child_info.sex.title")}
                </QuestionText>
                <ChildRadioContainer>
                  {[1, 2].map((i: number) => {
                    return (
                      <RadioLabel checked={sex === i.toString()}>
                        <RadioInput
                          name="sex"
                          type="radio"
                          value={`${i}`}
                          onChange={this.handleCheck}
                        />
                        <OptionText>
                          {t(
                            `user.survey.core.questionnaire.child_info.sex.${i}`
                          )}
                        </OptionText>
                      </RadioLabel>
                    );
                  })}
                </ChildRadioContainer>
              </Segment>
              <Segment>
                <QuestionText
                  ref={this.familyLanguageRef}
                  style={
                    familyLanguageRef || familyLanguageOtherRef
                      ? { color: "red" }
                      : { color: "black" }
                  }
                >
                  {t(
                    "user.survey.core.questionnaire.child_info.familyLanguage.title"
                  )}
                </QuestionText>
                <ChildRadioContainer>
                  {[1, 2, 3].map((i: number) => {
                    return (
                      <label>
                        <input
                          name="familyLanguage"
                          type="checkbox"
                          value={`${i}`}
                          checked={familyLanguage[i] ?? false}
                          onChange={this.handleMultipleCheck}
                        />
                        {i < 3 ? (
                          <span>
                            {t(
                              `user.survey.core.questionnaire.child_info.familyLanguage.${i}`
                            )}
                          </span>
                        ) : (
                          <span>
                            {t(
                              "user.survey.core.questionnaire.child_info.familyLanguage.3"
                            )}
                          </span>
                        )}
                      </label>
                    );
                  })}
                  {(familyLanguage[3] ?? false) && (
                    <label>
                      <TextInput
                        type="text"
                        name="familyLanguageOther"
                        onChange={this.handleCheck}
                        isEmpty={familyLanguageOtherRef}
                        placeholder={t(
                          "user.survey.core.questionnaire.child_info.familyLanguage.3_text"
                        )}
                      />
                    </label>
                  )}
                </ChildRadioContainer>
              </Segment>
              <Segment>
                <QuestionText
                  ref={this.worryStatusRef}
                  style={worryStatusRef ? { color: "red" } : { color: "black" }}
                >
                  {t(
                    "user.survey.core.questionnaire.child_info.worryLevel.title"
                  )}
                </QuestionText>
                <ChildRadioContainer>
                  {[1, 2, 3].map((i: number) => {
                    return (
                      <RadioLabel checked={worryStatus === i.toString()}>
                        <RadioInput
                          name="worryStatus"
                          type="radio"
                          value={`${i}`}
                          onChange={this.handleCheck}
                        />
                        <OptionText>
                          {t(
                            `user.survey.core.questionnaire.child_info.worryLevel.${i}`
                          )}
                        </OptionText>
                      </RadioLabel>
                    );
                  })}
                </ChildRadioContainer>
              </Segment>
              <Segment style={{ padding: "72px 24px 24px 24px" }}>
                <SectionIndicate>
                  <QuestionText style={{ color: "white" }}>
                    {t(
                      "user.survey.core.questionnaire.child_info.healthStatus"
                    )}
                  </QuestionText>
                </SectionIndicate>
                <QuestionText
                  ref={this.birthStatusRef}
                  style={
                    birthStatusRef ||
                    birthStatusRefPreterm ||
                    birthStatusRefOther
                      ? { color: "red" }
                      : { color: "black" }
                  }
                >
                  {t(
                    "user.survey.core.questionnaire.child_info.birthStatus.title"
                  )}
                </QuestionText>
                <ChildRadioContainer>
                  {[1, 2, 3].map((i: number) => {
                    return (
                      <RadioLabel checked={+birthStatus === i}>
                        <RadioInput
                          name="birthStatus"
                          type="radio"
                          value={`${i}`}
                          onChange={this.handleCheck}
                        />
                        <OptionText>
                          {t(
                            `user.survey.core.questionnaire.child_info.birthStatus.${i}`
                          )}
                        </OptionText>
                      </RadioLabel>
                    );
                  })}
                  {[2, 3].includes(+birthStatus) && (
                    <TextInput
                      type="text"
                      name="birthStatusOtherText"
                      onChange={this.handleCheck}
                      isEmpty={birthStatusRefPreterm}
                      placeholder={t(
                        `user.survey.core.questionnaire.child_info.birthStatus.${birthStatus}_text`
                      )}
                    />
                  )}
                </ChildRadioContainer>
              </Segment>
              <Segment>
                <QuestionText
                  ref={this.birthStatusTextRef}
                  style={
                    birthStatusTextRef || birthStatusTextOtherRef
                      ? { color: "red" }
                      : { color: "black" }
                  }
                >
                  {t(
                    "user.survey.core.questionnaire.child_info.postPartumStatus.title"
                  )}
                </QuestionText>
                <ChildRadioContainer>
                  {[1, 2, 3, 4, 5].map((i: number) => {
                    return (
                      <label>
                        <input
                          name="postpartumStatus"
                          type="checkbox"
                          value={`${i}`}
                          checked={postpartumStatus[i] ?? false}
                          onChange={this.handleMultipleCheck}
                        />
                        {i < 5 ? (
                          <span>
                            {t(
                              `user.survey.core.questionnaire.child_info.postPartumStatus.${i}`
                            )}
                          </span>
                        ) : (
                          <span>
                            {t(
                              "user.survey.core.questionnaire.child_info.postPartumStatus.5"
                            )}
                          </span>
                        )}
                      </label>
                    );
                  })}
                  {((postpartumStatus[2] ?? false) ||
                    (postpartumStatus[3] ?? false) ||
                    (postpartumStatus[4] ?? false) ||
                    (postpartumStatus[5] ?? false)) && (
                    <div>
                      <TextArea
                        // type="text"
                        name="postpartumStatusOther"
                        onChange={this.handleCheck}
                        isEmpty={birthStatusTextOtherRef}
                        placeholder={t(
                          "user.survey.core.questionnaire.child_info.postPartumStatus.text"
                        )}
                      />
                    </div>
                  )}
                </ChildRadioContainer>
              </Segment>
              <Segment>
                <QuestionText
                  ref={this.babyHealthStatusRef}
                  style={
                    babyHealthStatusRef || babyHealthStatusOtherRef
                      ? { color: "red" }
                      : { color: "black" }
                  }
                >
                  {t(
                    "user.survey.core.questionnaire.child_info.babyHealthStatus.title"
                  )}
                </QuestionText>
                <ChildRadioContainer>
                  {[1, 2, 3, 4, 5].map((i: number) => {
                    return (
                      <label>
                        <input
                          name="babyHealthStatus"
                          type="checkbox"
                          value={`${i}`}
                          checked={babyHealthStatus[i] ?? false}
                          onChange={this.handleMultipleCheck}
                        />
                        <span>
                          {t(
                            `user.survey.core.questionnaire.child_info.babyHealthStatus.${i}`
                          )}
                        </span>
                      </label>
                    );
                  })}
                  {((babyHealthStatus[1] ?? false) ||
                    (babyHealthStatus[2] ?? false) ||
                    (babyHealthStatus[3] ?? false) ||
                    (babyHealthStatus[4] ?? false)) && (
                    <div>
                      <TextArea
                        // type="text"
                        name="babyHealthStatusOther"
                        onChange={this.handleCheck}
                        isEmpty={babyHealthStatusOtherRef}
                        placeholder={t(
                          "user.survey.core.questionnaire.child_info.babyHealthStatus.text"
                        )}
                      />
                    </div>
                  )}
                </ChildRadioContainer>
              </Segment>
              <Segment>
                <QuestionText
                  ref={this.hearingStatusRef}
                  style={
                    hearingStatusRef ? { color: "red" } : { color: "black" }
                  }
                >
                  {t("user.survey.core.questionnaire.child_info.hearing.title")}
                </QuestionText>
                <ChildRadioContainer>
                  {[1, 2, 3, 4].map((i: number) => {
                    return (
                      <RadioLabel checked={hearingStatus === i.toString()}>
                        <RadioInput
                          name="hearingStatus"
                          type="radio"
                          value={`${i}`}
                          onChange={this.handleCheck}
                        />
                        <OptionText>
                          {t(
                            `user.survey.core.questionnaire.child_info.hearing.${i}`
                          )}
                        </OptionText>
                      </RadioLabel>
                    );
                  })}
                </ChildRadioContainer>
              </Segment>
              <Segment>
                <QuestionText
                  ref={this.eyeStatusRef}
                  style={eyeStatusRef ? { color: "red" } : { color: "black" }}
                >
                  {t(
                    "user.survey.core.questionnaire.child_info.eyesight.title"
                  )}
                </QuestionText>
                <ChildRadioContainer>
                  {[1, 2, 3, 4].map((i: number) => {
                    return (
                      <RadioLabel checked={eyeStatus === i.toString()}>
                        <RadioInput
                          name="eyeStatus"
                          type="radio"
                          value={`${i}`}
                          onChange={this.handleCheck}
                        />
                        <OptionText>
                          {t(
                            `user.survey.core.questionnaire.child_info.eyesight.${i}`
                          )}
                        </OptionText>
                      </RadioLabel>
                    );
                  })}
                </ChildRadioContainer>
              </Segment>
              <ButtonContainer>
                <ShowErrNextButton
                  status={this.isValid()}
                  onClick={this.handleNext}
                >
                  {t("user.survey.core.questionnaire.child_info.submit")}
                </ShowErrNextButton>
              </ButtonContainer>
            </>
          )}
        </Container>
      </Background>
    );
  }
}

export default withRouter(withTranslation()(ChildInfo));
