import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Segment, Header, Image } from "./styles";
import { Container } from "../../../User/Evaluation/styles";
import NotAvailableImage from "../../../general/assets/NotAvailable.svg";
import { GET_TITLE_BAR } from "util/common";

export default function NotAvailable() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = GET_TITLE_BAR(t, "evaluationNotAvailable", false);
  }, [t]);

  return (
    <Container>
      <Segment>
        <Image src={NotAvailableImage} />
        <Header>此问券目前无法填写</Header>
      </Segment>
    </Container>
  );
}
