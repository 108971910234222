import { Image } from "./style";
import { useTranslation } from "react-i18next";
import SurveyImg from "../../general/assets/PendingApproval.svg";
import {
  ModalContainer,
  ModalHeader,
  ModalMask,
  ModalContent,
  NextButton,
  HeaderTitle,
  ActionTitle,
} from "styles";
interface Props {
  show: boolean;
  handleClose: () => void;
}

export default function GoToSurveyModal(props: Props) {
  const { show, handleClose } = props;
  const { t } = useTranslation();
  return (
    <ModalMask id="ModalContainer" open={show}>
      <ModalContainer id="ModalContent">
        <ModalHeader style={{ textAlign: "center" }}>
          <HeaderTitle>
            {t("user.purchase.payments.checkoutPayment.unfinishedSurvey")}
          </HeaderTitle>
        </ModalHeader>
        <ModalContent>
          <Image src={SurveyImg} />
        </ModalContent>
        <NextButton onClick={handleClose} status={true}>
          <ActionTitle>
            {t("user.purchase.payments.checkoutPayment.goToSurvey")}
          </ActionTitle>
        </NextButton>
      </ModalContainer>
    </ModalMask>
  );
}
