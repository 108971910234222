import React, { Component } from "react";
import i18n from "i18next";
import { History, LocationState } from "history";
import Cookies from "js-cookie";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AppActions } from "types/actions";
import { ThunkDispatch } from "redux-thunk";
import { withTranslation, WithTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import * as qs from "query-string";
import { REGISTER_VALIDATION, REGISTER_SUBMIT, LOGIN_SUBMIT } from "util/auth";
import { GET_TITLE_BAR } from "util/common";
import { ROLE } from "../../../../constants/common";
import NotificationModal from "../../../common/NotificationModal";
import RegisterForm from "../../UserAuthForm/RegisterForm";
import {
  startLoginSuccess,
  startLoginFail,
  startUpdateUserToken,
} from "../../actions";
import {
  CompanyName,
  CompanyNameContainer,
  Container,
  Des,
  LoginBackground,
  LoginContainer,
  LoginDesImg,
  LoginIntroContainer,
  Logo,
  RegisterScroll,
  TitleContainer,
} from "../styles";
import getStarted from "../../assets/getStarted.svg";
import Logo_Icon from "../../../general/assets/Logo_Icon.png";
import { getCountryInitial } from "constants/countryCode";
import countryList from "constants/countryList";
import { getUserBrowserTz } from "constants/timeZone";
import { OAUTH_ERROR_MESSAGE, OAUTH_METHOD } from "../../../auth/constants";
interface RegisterProps {
  history: History<LocationState>;
}

interface RegisterState {
  [key: string]: boolean | string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  countryCode: string;
  country: string;
  password: string;
  requireOrganizationCode: boolean;
  organizationCode: string;
  errors: string;
  showModal: boolean;
  isAgreed: boolean;
  notificationStatus: boolean;
  timeZone: string;
}

type Props = RegisterProps & LinkDispatchProps & WithTranslation;

class Register extends Component<Props, RegisterState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      countryCode: "",
      password: "",
      requireOrganizationCode: false,
      organizationCode: "",
      errors: "",
      showModal: false,
      isAgreed: false,
      notificationStatus: false,
      timeZone: getUserBrowserTz(),
    } as RegisterState;
  }

  componentDidMount = () => {
    const { t } = this.props;
    document.title = GET_TITLE_BAR(t, "register", true);
    const token = Cookies.get("token");
    const loggedIn = token != null;
    if (loggedIn) {
      this.props.history.push("/dashboard");
    } else {
      const { org: organizationCode }: { org?: string } = qs.parse(
        window.location.search
      );
      if (organizationCode) {
        this.setState({
          organizationCode: organizationCode,
          requireOrganizationCode: true,
        });
      }
    }
    window.scrollTo(0, 0);
  };

  handleGoogleAuth = async (oAuthResponse: any) => {
    if (oAuthResponse != null) {
      const newUser = {
        firstName: oAuthResponse.given_name ?? "",
        lastName: oAuthResponse.family_name ?? "",
        email: oAuthResponse.email,
        phone: "1234567890",
        countryCode: "+1",
        requireOrganizationCode: false,
        organizationCode: "",
        password: oAuthResponse.sub,
        isAgreed: true,
        isOauth: true,
      };
      let user: { token: string; role: string };
      try {
        user = await LOGIN_SUBMIT(
          {
            email: oAuthResponse.email,
            password: oAuthResponse.sub,
          },
          OAUTH_METHOD.GOOGLE
        );
        const { token, role } = user;
        this.props.onUpdateUserToken(token);
        if (role === ROLE.user) {
          this.props.onLoginSuccess(ROLE.user);
          this.props.history.push("/dashboard");
        } else if (role === ROLE.therapist) {
          this.props.onLoginSuccess(ROLE.therapist);
          this.props.history.push("/therapist");
        }
      } catch (error: any) {
        if (error.message === OAUTH_ERROR_MESSAGE) {
          this.displayErrorMessage(i18n.t("message.account.methodError"));
        } else {
          this.props.history.push({
            pathname: "/oauth-register",
            state: { newUser: newUser, redirectLink: "/dashboard" },
          });
        }
      }
    }
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    const isEmail = id === "email";
    this.setState({ [id]: isEmail ? value.replace(/\s+/g, "") : value });
  };

  handleCountryCodeChange = (value: string) => {
    if (value != null)
      this.setState({
        countryCode: value,
      });
    const countryCode = getCountryInitial(value);
    for (let i = 0; i < countryList.length; i++) {
      if (countryList[i].key === countryCode?.toLowerCase()) {
        this.setState({ country: countryList[i].key });
        return;
      }
    }
  };

  handleCountryChange = (event, { value }) => {
    if (value != null)
      this.setState({
        country: value,
      });
  };

  handleTimeZoneChange = (event, { value }) => {
    if (value != null)
      this.setState({
        timeZone: value,
      });
  };

  handleTimeZoneClick = (event, { value }) => {
    this.setState({
      timeZone: getUserBrowserTz(),
    });
  };

  setIsAgreed = () => {
    this.setState((states) => ({
      isAgreed: !states.isAgreed,
    }));
  };

  onRegisterSubmit = async () => {
    try {
      const {
        countryCode,
        country,
        firstName,
        lastName,
        email,
        phone,
        password,
        requireOrganizationCode,
        organizationCode,
        isAgreed,
        timeZone,
      } = this.state;
      const newUser = {
        firstName,
        lastName,
        email,
        phone,
        countryCode,
        country,
        requireOrganizationCode,
        organizationCode,
        password,
        isAgreed,
        timeZone,
      };
      REGISTER_VALIDATION(newUser);
      const token = await REGISTER_SUBMIT(newUser);
      this.props.onUpdateUserToken(token);
      this.props.onLoginSuccess(ROLE.user);
      this.props.history.push("/dashboard");
    } catch (error: any) {
      mixpanel.track("Register", {
        action: "register failed",
        errorMessage: error.message,
      });
      this.displayErrorMessage(error.message);
    }
  };

  displayErrorMessage = (message: string) => {
    this.setState({
      errors: message,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  handleLogin = () => {
    this.props.history.push("/login");
  };
  render() {
    const { t } = this.props;
    const { showModal, errors, notificationStatus } = this.state;
    return (
      <Container>
        <NotificationModal
          show={showModal}
          message={errors}
          status={notificationStatus}
        />
        <LoginContainer>
          <LoginIntroContainer>
            <TitleContainer>
              <CompanyNameContainer>
                <Logo src={Logo_Icon} />
                <CompanyName>{t("general.companyName")}</CompanyName>
              </CompanyNameContainer>
              <Des>{t("auth.login.des1")}</Des>
              <Des>{t("auth.login.des2")}</Des>
            </TitleContainer>
            <LoginDesImg src={getStarted} />
          </LoginIntroContainer>
          <LoginBackground>
            <RegisterScroll>
              <RegisterForm
                {...this}
                {...this.state}
                {...this.props}
                showOrganizationCodeInput={this.state.requireOrganizationCode}
              />
            </RegisterScroll>
          </LoginBackground>
        </LoginContainer>
      </Container>
    );
  }
}

//satisfies return values of mapDispatchToProps
interface LinkDispatchProps {
  onLoginSuccess: (data: string) => void;
  onLoginFail: () => void;
  onUpdateUserToken: (data: string) => void;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: RegisterProps
): LinkDispatchProps => ({
  onLoginSuccess: bindActionCreators(startLoginSuccess, dispatch),
  onLoginFail: bindActionCreators(startLoginFail, dispatch),
  onUpdateUserToken: bindActionCreators(startUpdateUserToken, dispatch),
});
export default withRouter(
  connect(null, mapDispatchToProps)(withTranslation()(Register))
);
