const awsQuickScreenerAssetsS3FolderLink =
  process.env.REACT_APP_AWS_QUICK_SCREENER_ASSETS_LINK;

//Quick Screener Index Page
export const WelcomeImg =
  awsQuickScreenerAssetsS3FolderLink + "imgHomePageGraphic.png";

//Quick Screener Questionnaire Page
export const id2_7 = awsQuickScreenerAssetsS3FolderLink + "id2-7.png";
export const id7_4 = awsQuickScreenerAssetsS3FolderLink + "id7-4.png";
export const id8_8 = awsQuickScreenerAssetsS3FolderLink + "id8-8.png";
export const id9_5 = awsQuickScreenerAssetsS3FolderLink + "id9-5.png";
export const id9_8_9_10 = awsQuickScreenerAssetsS3FolderLink + "id9-8-9-10.png";
export const id10_5 = awsQuickScreenerAssetsS3FolderLink + "id10-5.png";
export const id10_9 = awsQuickScreenerAssetsS3FolderLink + "id10-9.png";
export const id10_8_10 = awsQuickScreenerAssetsS3FolderLink + "id10-8-10.png";
export const id11_6 = awsQuickScreenerAssetsS3FolderLink + "id11-6.png";
export const id11_7 = awsQuickScreenerAssetsS3FolderLink + "id11-7.png";
export const id11_9 = awsQuickScreenerAssetsS3FolderLink + "id11-9.png";
export const id11_10 = awsQuickScreenerAssetsS3FolderLink + "id11-10.png";
export const id12_5 = awsQuickScreenerAssetsS3FolderLink + "id12-5.png";
export const id12_7 = awsQuickScreenerAssetsS3FolderLink + "id12-7.png";
export const id12_9 = awsQuickScreenerAssetsS3FolderLink + "id12-9.png";
export const id13_3 = awsQuickScreenerAssetsS3FolderLink + "id13-3.png";
export const id13_4 = awsQuickScreenerAssetsS3FolderLink + "id13-4.png";
export const id13_6 = awsQuickScreenerAssetsS3FolderLink + "id13-6.png";
export const id13_7 = awsQuickScreenerAssetsS3FolderLink + "id13-7.png";

//Quick Screener Result Page
export const qrcode = awsQuickScreenerAssetsS3FolderLink + "qrcode.png";
export const ServiceImage =
  awsQuickScreenerAssetsS3FolderLink + "icService.png";
export const InteractionImage =
  awsQuickScreenerAssetsS3FolderLink + "icInteraction.png";
export const Action2Image =
  awsQuickScreenerAssetsS3FolderLink + "icAction02.png";
export const Action1Image =
  awsQuickScreenerAssetsS3FolderLink + "icAction01.png";
export const SpeakImage = awsQuickScreenerAssetsS3FolderLink + "icSpeak.png";
export const AnalysisImage =
  awsQuickScreenerAssetsS3FolderLink + "icAnalysis.png";

// Quick Screener Footer Page
export const FacebookLogo =
  awsQuickScreenerAssetsS3FolderLink + "facebookLogo.png";
export const WeChat = awsQuickScreenerAssetsS3FolderLink + "WeChat.png";
export const Line = awsQuickScreenerAssetsS3FolderLink + "Line.png";
