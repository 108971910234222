import gql from "graphql-tag";

export const EDIT_USER_INFORMATION = gql`
  mutation EditUserInformationByUuid(
    $userUuid: ID!
    $attribute: String!
    $value: String!
  ) {
    editUserInformationByUuid(
      userUuid: $userUuid
      attribute: $attribute
      value: $value
    ) {
      user {
        id
        name
        firstName
        lastName
        phoneNumber
        activeChildId
        locale
        smsNotification
        country
      }
      returnMessage {
        status
        message
      }
    }
  }
`;
