import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import { string } from "prop-types";

// Record Item
export const RecordItemContainer = styled.div`
  color: red;
`;

// Brief
export const RecordItemBriefContainer = styled.div``;
export const RecordItemBriefImg = styled.img``;
export const RecordItemBriefTextContainer = styled.div``;
export const RecordItemBriefTitle = styled.div``;
export const RecordItemBriefSubtitle = styled.div``;

// Info
export const RecordItemInfoContainer = styled.div``;
export const RecordItemInfoTabContainer = styled.div``;
export const RecordItemInfoTab = styled.div``;

// Record directly

// Upload directly
export const DriveContainer = styled.div`
  text-align: center;
  margin: 30px 20px 7px 20px;
`;

export const DriveInput = styled.input`=
  font-family: Noto Sans CJK TC Regular, sans-serif;
  line-height: 18px;
  width: 100%;
  font-size: 16px;
  padding-left: 1%;
  height: 40px;
  margin-bottom: 2%;

  border: 1pt #BBBBBB solid;
  border-style: none none solid none;
  
  :focus{
    border: 1pt black solid;
    border-style: none none solid none;
    outline: none;
  }
  :active{
    border-color: #333333;
  }

  @media screen and (max-width: 600px){
    font-size: 14px;
  }
  
`;

// Upload with Url

export const Container = styled.div`
  min-height: 80vh;
  cursor: default;
  @media only screen and (max-width: 600px) {
    padding: 0 0 50px 0;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  text-align: center;
  color: rgb(51, 51, 51);
  line-height: 24px;
  font-family: Noto Sans CJK TC Medium, sans-serif;
  @media only screen and (max-width: 600px) {
    font-size: 16px;
    margin: 0px 20px;
  }
`;

export const NextLine = styled.div`
  display: inline;
  @media only screen and (max-width: 600px) {
    display: block;
  }
`;

export const Subtitle = styled(Title)`
  font-weight: Noto Sans CJK TC bold, sans-serif;
  color: #2ab2ac;
  margin-top: 10px;
  @media only screen and (max-width: 600px) {
    font-size: 12px;
    margin: 10px 0px 0px 0px;
  }
`;

export const HelpCardText = styled.div`
  color: rgb(255, 255, 255);
  margin-left: 50px;
  font-family: Noto Sans CJK TC Medium, sans-serif;
  font-size: 18px;
  line-height: 24px;

  @media only screen and (max-width: 600px) {
    margin: 0;
    font-size: 14px;
  }
`;

export const RadioCircle = styled.div`
  margin-top: -50px;
  height: 60px;
`;

export const RecordDiv = styled.div`
  position: relative;
  background-color: rgb(255, 255, 255);
  margin: 10px 0;
  padding: 10px 10px;
  border: 1px solid rgb(216, 216, 216);
  border-radius: 16px;
  @media only screen and (max-width: 600px) {
    margin: 10px 10px;
    padding: 20px 10px;
  }
`;

export const RecordCard = styled.div`
  display: grid;
  cursor: pointer;
  grid-template-columns: 10% 85% 5%;
  @media only screen and (max-width: 600px) {
    grid-template-columns: 17% 78% 5%;
  }
`;

export const Circle = styled.div`
  height: 50px;
  width: 50px;
  background-color: white;
  border-radius: 50%;
  border: #d9d9d9 1px solid;
  @media only screen and (max-width: 600px) {
    margin-top: 0px;
  }

  @media only screen and (max-width: 400px) {
    width: 40px;
    height: 40px;
  }
`;

export const CircleDiv = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const CheckCircle = styled.img`
    height: 50px;
    width: 50px;
    margin-top: 10px;
    margin: auto;
    animation: haha 0.8s;
    animation-timing-function: ease-in;
   

  @keyframes  haha {
    0%{ transform: scale(1.5); -webkit-filter:blur(0px)}
    10%{ transform: scale(1.5); -webkit-filter:blur(3px)}
    20%{ transform: scale(1.5); -webkit-filter:blur(0px)}
    25%{ transform: scale(1.5); -webkit-filter:blur(2px)}
    30%{ transform: scale(1); -webkit-filter:blur(0px)}
    40%{ transform: scale(1); opacity: 1}
    50%{ transform: scale(1); opacity: 1}
    60%{ transform: scale(1); opacity: 1}
    70%{ transform: scale(1); opacity: 1}
    80%{ transform: scale(1); opacity: 1}
    90%{ transform: scale(1); opacity: 1}
    100%{ transform: scale(1); opacity: 1}
    }
  }
  
  @media only screen and (max-width: 600px) {
    margin-top: 10px;
  }

  @media only screen and (max-width: 400px){
    width: 40px;
    height: 40px;
  }
`;

export const CheckCircleProgress = styled.img`
  height: 80px;
  width: 80px;
  animation: shrink 0.8s;
  animation-timing-function: ease-in;

  @keyframes  shrink {
    0%{ transform: scale(1); }
    10%{ transform: scale(0.8); }
    20%{ transform: scale(0.6); }
    25%{ transform: scale(0.4); }
    30%{ transform: scale(0.8); }
    40%{ transform: scale(1); }
    50%{ transform: scale(1); }
    60%{ transform: scale(1); }
    70%{ transform: scale(1); }
    80%{ transform: scale(1); }
    90%{ transform: scale(1); }
    100%{ transform: scale(1); }
    }
  }
`;

export const RecordPrompt = styled.div``;

export const RecordTopic = styled.span`
  margin-left: 10px;
  padding: 3px 10px;
  border-radius: 10px;
  background-color: rgb(235, 235, 235);
  font-size: 10px;
  text-align: center;
  color: rgb(51, 51, 51);
  line-height: 14px;
  font-family: Noto Sans CJK TC Medium, sans-serif;
`;

export const RecordText = styled.div`
  margin: 15px 28% 20px 15px;
  font-size: 20px;
  color: rgb(51, 51, 51);
  white-space: pre-line;
  @media only screen and (max-width: 1000px) {
    font-size: 18px;
    font-weight: 500;
  }
  @media only screen and (max-width: 600px) {
    font-size: 16px;
    font-weight: 500;
    margin: 15px 0px 20px 10px;
  }
`;

export const UploadMenu = styled.div`
  font-size: 14px;
  font-family: Noto Sans CJK TC Medium, sans-serif;
  text-align: center;
  margin: 0px 15px;
  border-bottom: 1px solid rgb(216, 216, 216);
  @media only screen and (max-width: 600px) {
    font-size: 12px;
    margin: 0px 5px;
  }
`;

export const UploadMenuOption = styled.div<{
  isActive: boolean;
  padding: string;
}>`
  display: inline-block;
  width: 33.3%;
  cursor: pointer;
  padding: 10px 0px 6px 0px;
  border-bottom: ${(props) =>
    props.isActive ? "4px solid #2AB2AC" : "4px solid rgba(82, 143, 227, 0)"};
  color: ${(props) =>
    props.isActive ? "rgb(51, 51, 51)" : "rgb(153, 153, 153)"};
  @media only screen and (max-width: 600px) {
    padding: ${(props) => props.padding};
    line-height: 16px;
  }
`;

export const AudioDiv = styled.audio<{
  display: string;
}>`
  width: 100%;
  display: ${(props) => props.display}
  src: ${(props) => props.src}
`;

export const RecordIconDiv = styled.div`
  margin-top: 10px;
  margin-bottom: 0px;
`;

export const CentralisingDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
`;

export const RecordIcon = styled.div<{
  paddingTop?: string;
  backgroundColor: string;
  borderRadius: string;
  display: string;
  height: string;
  width: string;
  margin: string;
  fontSize?: string;
  fontFamily?: string;
  textAlign?: string;
  lineHeight: string;
  border: string;
  cursor?: string;
  pointerHover: string;
  activeColor: string;
  pointerActive: string;
  hoverColor: string;
}>`
  padding-top: ${(props) => props.paddingTop};
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.borderRadius};
  display: ${(props) => props.display};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  font-size: ${(props) => props.fontSize};
  font-family: ${(props) => props.fontFamily};
  text-align: ${(props) => props.textAlign};
  line-height: ${(props) => props.lineHeight};
  border: ${(props) => props.border};
  cursor: ${(props) => props.cursor};
  transition: 0.2s;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: translate(0px, -4px);
    background-color: ${(props) => props.hoverColor};
    pointer-events: ${(props) => props.pointerHover};
  }
  &:active {
    transform: translate(0px, 0px);
    background-color: ${(props) => props.activeColor};
    pointer-events: ${(props) => props.pointerActive};
  }
`;
export const SaveAndRecordAgainContainer = styled.div`
  display: block;
`;

export const StopBtn = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 2px;
  background: white;
`;

export const RecordAgainBtnTextDiv = styled.div<{
  margin: string;
}>`
  margin: ${(props) => props.margin};

  :active {
    background: transparent;
  }
  :hover {
    background: transparent;
  }
`;

export const UploadIcon = styled.div<{
  backgroundColor: string;
  border: string;
  pointerHover: string;
  activeBackgroundColor: string;
  activeColor: string;
  pointerActive: string;
  src: string;
  width: string;
  padding: string;
  color: string;
  display: string;
  cursor: string;
  boxShadow: string;
}>`
  height: 50px;
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  padding-top: 15px;
  margin: 1% 5% 1% 5%;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) => props.border};
  display: ${(props) => props.display};
  font-family: Noto Sans CJK TC Medium, sans-serif;
  font-weight: 600;
  text-align: center;
  cursor: ${(props) => props.cursor};
  border-radius: 50px;
  white-space: nowrap;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;
  &:hover {
    pointer-events: ${(props) => props.pointerHover};
    box-shadow: ${(props) => props.boxShadow};
  }
  &:active {
    transform: translate(0px, 0px);
    transition: 0.2s;
    background-color: ${(props) => props.activeBackgroundColor};
    color: ${(props) => props.activeColor};
    pointer-events: ${(props) => props.pointerActive};
  }
  @media only screen and (max-width: 600px) {
    height: 50px;
    width: 50px;
    padding: 12.5px;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    margin-left: 20px;
    background-color: ${(props) => props.backgroundColor};
    background-image: url(${(props) => props.src});
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center;
  }
`;

export const UploadIconText = styled.div`
  display: inline;
  word-wrap: break-word;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const UploadIconTextMobile = styled.div<{
  display: string;
}>`
  display: ${(props) => props.display};
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  @media only screen and (min-width: 600px) {
    display: none;
  }
`;

export const MicrophoneText = styled.div<{
  display: string;
}>`
  text-align: center;
  font-size: 16px;
  margin: 0 0 10px 0;
  display: ${(props) => props.display};

  @media screen and (max-width: 600px) {
    font-size: 14px;
    margin: 10px 0 0 0;
  }
`;

export const RecordingText = styled.div<{
  display: string;
}>`
  font-size: 16px;
  margin-bottom: 1%;
  display: ${(props) => props.display};
  animation: blinking 1s linear 0s infinite alternate;

  @keyframes blinking {
    0% {
      color: black;
    }
    100% {
      color: white;
    }
  }
`;

export const TimerText = styled.div<{
  display: string;
}>`
  display: ${(props) => props.display};
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 2%;

  @media (max-width: 550px) {
    font-size: 16px;
    margin-bottom: 3%;
  }
`;

export const BlockDisplayContainer = styled.div<{
  display: string;
}>`
  display: ${(props) => props.display}
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  text-align: center;
  width: 100%;
  
`;

export const Vr = styled.div`
  border-left: 1px solid rgb(216, 216, 216);
  height: 40px;
  display: inline-block;
  margin: auto 19px;
  display: none;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const UploadBox = styled.div`
  margin: 19px 40px 0px 40px;
  border: 1px dashed rgb(204, 204, 204);
  border-radius: 10px;
  text-align: center;
  @media only screen and (max-width: 600px) {
    margin: 19px 10px 0px 10px;
  }
`;

export const UploadInput = styled.input`
  display: none;
`;

export const UploadButton = styled.label<{ src: string }>`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: 28px 28px;
  display: inline-block;
  margin: 21px 0px;
  cursor: pointer;
  height: 28px;
`;

export const UploadButtonText = styled.div`
  margin-left: 39px;
  font-size: 14px;
  font-family: Noto Sans CJK TC Medium, sans-serif;
  text-align: start;
  line-height: 28px;
`;

export const SaveButtonContainer = styled.div`
  text-align: center;
`;

export const SaveButton = styled.button<{
  backgroundColor: string;
  border: string;
  activeColor: string;
  pointerActive: string;
  cursor: string;
  boxShadow: string;
}>`
  border: 1px solid #2ab2ac;
  border-radius: 25px;
  color: rgb(255, 255, 255);
  background-color: #2ab2ac;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding: 0 40px 0 40px;
  cursor: pointer;
  margin: 20px 0px;
  height: 50px;
  width: max-content;
  outline: 0;
  cursor: ${(props) => props.cursor};
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) => props.border};
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;
  &:active {
    transform: translate(0px, 0px);
    transition: 0.2s;
    background-color: ${(props) => props.activeColor};
    pointer-events: ${(props) => props.pointerActive};
  }
  :hover {
    box-shadow: ${(props) => props.boxShadow};
  }

  @media screen and (max-width: 600px) {
    width: 100px;
    padding: 0;
    font-size: 14px;
  }
`;

export const Loader = styled.svg<{ record: boolean }>`
  fill: none;
  stroke: #2ab2ac;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-dasharray: ${(props) => (props.record ? "0" : "280")};
  transform-origin: center;
  animation: ${(props) =>
    props.record
      ? "null"
      : "line 5s linear infinite, spin 10s linear infinite"};

  @keyframes line {
    0% {
      stroke-dashoffset: 1120;
    }
    100% {
      stroke-dashoffset: 560;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(-90deg) translate3d(0, 0, 0);
    }
    100% {
      transform: rotate(270deg) translate3d(0, 0, 0);
    }
  }
`;

export const Microphone = styled.svg`
  padding: 12px 12px;
  position: absolute;
  fill: white;

  @keyframes microphone {
    0% {
      transform: scale(1);
    }
    10% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(0);
    }
  }
`;

export const MicrophoneSVG = styled.svg`
  padding: 12px 12px;
  position: relative;
  fill: white;
  cursor: pointer;
  text-align: center;
  justify-content: center;
`;

export const DotWrapper = styled.div`
  opacity: 1;
  position: absolute;
  width: 50px;
  height: 10px;
  @keyframes dot {
    0%,
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const Dots = styled.div<{ right: string; delay: string }>`
  position: absolute;
  border-radius: 50%;
  background-color: white;
  height: 10px;
  width: 10px;
  right: ${(props) => props.right};
  animation-delay: ${(props) => props.delay};

  @keyframes dots {
    0%,
    100% {
      top: 5px;
    }
    50% {
      top: -5px;
    }
  }
`;

export const Arrow = styled(Icon)<{ isOpen: boolean }>`
  ${(props) =>
    props.isOpen
      ? "transform: rotate(180deg); transition: transform .2s ease-in-out"
      : "transform: rotate(360deg); transition: transform .2s ease-in-out"};
`;
