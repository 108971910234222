import { Component } from "react";
import { match } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import { Checkbox, Table, notification } from "antd";
import warning from "../../../common/NotificationModal/assets/Exclamation.svg";
import { Image } from "../../../common/NotificationModal/styles";
import { SNOWFLAKE } from "../../../../util/common";
import api_address from "../../../../constants/config";
import { History, LocationState } from "history";
import NotificationModal from "../../../common/NotificationModal";
import DropDown from "../../../common/DropDown";
import { Title } from "components/Admin/User/Edit/styles";
import { getCountryInitial } from "constants/countryCode";
import countryList from "constants/countryList";
import TimeZoneDropdown from "components/common/TimeZoneDropdown";
import ImageUploader from "components/common/ImageUploader";
import TherapistBioTextbox from "components/common/TherapistBioTextbox";
import { getCountryCodeList } from "../../../../constants/countryCode";
import { IS_NUMBER, IS_VALID_EMAIL } from "../../../../util/common";
import {
  Container,
  ContentContainer,
  ContentSubtitle,
  Input,
  SaveButton,
  CancelButton,
  ContainerWithGrid,
  GridColumn,
} from "../../styles";
import { InputBorderDefault, InputBorderInvalid } from "../constant";
import { RedAsterisk } from "../styles";
import { ResetPasswordButton } from "./style";
import {
  DELETE_THERAPIST_BIO_SUBMIT,
  EDIT_THERAPIST_SUBMIT,
  JS_COOKIE,
} from "util/auth";
import UploaderOnGuide from "util/uploaderOnGuide";
import { THERAPIST_PROFILE_PICTURE_FOLDER } from "constants/common";
import { ADMIN_COOKIE_NAME } from "constants/admin";
import { UploadContainer } from "components/common/VideoModal/style";
import { isImageFileSizeBelow5MB, getBase64 } from "util/therapist";
import ResetPasswordModal from "./components/ResetPasswordModal";

export interface EditTherapistBioInput {
  bio: string;
  language: string;
  uuid: string;
  uniqueBioId: string;
}

interface Match {
  id: string;
}

interface TherapistEditProps {
  match: match<Match>;
  history: History<LocationState>;
}

interface TherapistEditStates {
  [key: string]: number | string | boolean | Array<Object> | Array<string>;
  UUID: string;
  firstName: string;
  lastName: string;
  countryCode: string;
  phone: string;
  email: string;
  timeZone: string;
  country: string;
  createdAt: string;
  lastLoginAt: string;
  message: string;
  consultationUrl: string;
  cnConsultationUrl: string;
  originFileObj: any;
  avatarPreview: string;
  encodedFilename: string;
  isUploaded: boolean;
  title: string;
  bio: Array<{
    id: number;
    unique_bio_id: string;
    bio: string;
    language: string;
  }>;
  tempDeletedBio: Array<string>;
  isLoading: boolean;
  emptyBioFromDB: boolean;
  disabled: boolean;
  showModal: boolean;
  notificationStatus: boolean;
  isResetPasswordModalVisible: boolean;
}

type Props = TherapistEditProps & WithTranslation;
class TherapistEdit extends Component<Props, TherapistEditStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      UUID: "",
      firstName: "",
      lastName: "",
      countryCode: "",
      phone: "",
      email: "",
      createdAt: "",
      lastLoginAt: "",
      timeZone: "",
      country: "",
      message: "",
      consultationUrl: "",
      cnConsultationUrl: "",
      avatarPreview: "",
      encodedFilename: "",
      originFileObj: "",
      isUploaded: false,
      title: "",
      bio: [],
      tempDeletedBio: [],
      isLoading: true,
      emptyBioFromDB: true,
      disabled: false,
      showModal: false,
      notificationStatus: false,
      isResetPasswordModalVisible: false,
    };
  }

  showResetPasswordModal = () => {
    this.setState({ isResetPasswordModalVisible: true });
  };

  closeResetPasswordModal = () => {
    this.setState({ isResetPasswordModalVisible: false });
  };

  getTherapistInfo = async () => {
    const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
    const { id } = this.props.match.params;

    const result = await axios
      .get(api_address + "api/admin/therapist/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((error) => {
        this.showNotification(error.message, false);
      });
    const {
      UUID,
      firstName,
      lastName,
      countryCode,
      phone,
      email,
      createdat,
      lastloginat,
      time_zone,
      country,
      consultation_url,
      cn_consultation_url,
      title,
      avatar_filename,
      disabled,
    } = result!.data.therapist.therapistData;
    const { therapistBio } = result!.data.therapist;
    if (therapistBio.length > 0) this.setState({ emptyBioFromDB: false });
    else this.setState({ emptyBioFromDB: true });
    this.setState({
      UUID,
      firstName,
      lastName,
      countryCode,
      phone,
      email,
      createdAt: createdat,
      lastLoginAt: lastloginat,
      timeZone: time_zone,
      consultationUrl: consultation_url,
      cnConsultationUrl: cn_consultation_url,
      encodedFilename: avatar_filename,
      avatarPreview: avatar_filename,
      disabled: disabled,
      title: title,
      bio: therapistBio,
    });
    if (country === "") {
      const { countryCode } = this.state;
      const countryCodeFromGetCountryInitial = getCountryInitial(countryCode);
      for (let i = 0; i < countryList.length; i++) {
        if (
          countryList[i].key === countryCodeFromGetCountryInitial?.toLowerCase()
        ) {
          this.setState({ country: countryList[i].key });
          return;
        }
      }
    } else this.setState({ country: country });
  };
  async componentDidUpdate(prevState): Promise<void> {
    if (this.state.isLoading === false) {
      await this.getTherapistInfo();
      this.setState({ isLoading: true });
    }
  }

  componentDidMount = async () => {
    await this.getTherapistInfo();
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    const input = document.getElementById(id);
    if (input) {
      input.style.border = InputBorderDefault;
    }
    this.setState({ [id]: value });
  };

  handleTimeZoneChange = (value: string) => {
    if (value) {
      this.setState({ timeZone: value });
    }
  };

  handleCountryCodeChange = (value: string) => {
    if (value != null)
      this.setState({
        countryCode: value,
      });
    const countryCode = getCountryInitial(value);
    for (let i = 0; i < countryList.length; i++) {
      if (countryList[i].key === countryCode?.toLowerCase()) {
        this.setState({ country: countryList[i].key });
        return;
      }
    }
  };

  beforeUpload = (file: any) => {
    try {
      if (!file) throw new Error("File is unavailable!");
      return isImageFileSizeBelow5MB(file.size);
    } catch (err: any) {
      this.showNotification(err.message, false);
      return false;
    }
  };

  handleUploadImageForPreview = async (e: any) => {
    try {
      const { file } = e;
      if (!file) throw new Error("File is unavailable!");
      if (file.status === "removed") {
        this.setState({ avatarPreview: "", isUploaded: false });
        return;
      }
      const originFileObj = file.originFileObj;

      const filePreview = await getBase64(originFileObj);
      this.setState({
        originFileObj: originFileObj,
        encodedFilename: "",
        avatarPreview: filePreview as string,
      });
    } catch (err: any) {
      this.showNotification(err.message, false);
    }
  };

  handleUploadImageToS3 = (therapistUUID: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const { originFileObj } = this.state;
      const extension = originFileObj.type === "image/png" ? ".png" : ".jpg";
      const encodedFileName = therapistUUID + extension;
      UploaderOnGuide(
        originFileObj,
        THERAPIST_PROFILE_PICTURE_FOLDER,
        encodedFileName
      ).send((err, data) => {
        if (err) {
          reject(err);
          return;
        }
        this.setState(
          {
            isUploaded: true,
            encodedFilename: encodedFileName,
          },
          () => resolve()
        );
      });
    });
  };

  handleBio = (bioList: any) => {
    if (bioList) this.setState({ bio: bioList });
  };

  filterBioData = (language: string) => {
    const { bio } = this.state;

    if (!bio || bio.length === 0) return [];

    return bio.filter((item) => {
      if (!item) return false;
      else if (!item.language) {
        if (language === "EN") return true;
        return false;
      }
      return item.language === language;
    });
  };

  handleDeleteBio = async (unique_bio_id: string) => {
    try {
      let tempDeletedBioArr = this.state.tempDeletedBio;
      tempDeletedBioArr.push(unique_bio_id);
      this.setState({ tempDeletedBio: tempDeletedBioArr });
    } catch (error: any) {
      this.showNotification(error.message, false);
    }
  };

  onDisabledCheckboxClick = (e) => {
    this.setState({ disabled: e.target.checked });
  };
  showNotification = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  openNotification = (description: string) => {
    notification.info({
      message: <p style={{ color: "White" }}>Error</p>,
      icon: <Image src={warning} style={{ marginBottom: "2px" }} alt="" />,
      description: description,
      placement: "top",
      style: {
        color: "white",
        backgroundColor: "#e8384f",
        height: "90px",
        width: "100%",
      },
    });
  };

  changeInputColor = (id) => {
    const input = document.getElementById(id);
    if (input) {
      input.style.border = InputBorderInvalid;
    }
  };

  validation = () => {
    const {
      email,
      phone,
      countryCode,
      firstName,
      lastName,
      password,
      timeZone,
      bio,
      title,
      avatarPreview,
    } = this.state;
    let count = 0;
    if (firstName === "") {
      count += 1;
      this.changeInputColor("firstName");
      this.openNotification("First name is missing");
    }
    if (lastName === "") {
      count += 1;
      this.changeInputColor("lastName");
      this.openNotification("Last name is missing");
    }
    if (countryCode === "") {
      count += 1;
      this.changeInputColor("countryCode");
      this.openNotification("Country code is missing");
    }
    if (phone === "") {
      count += 1;
      this.changeInputColor("phone");
      this.openNotification("Phone number is missing");
    }
    if (!IS_NUMBER(phone)) {
      count += 1;
      this.setState({ phone: "" });
      this.changeInputColor("phone");
      this.openNotification("Phone number is invalid");
    }
    if (email === "") {
      count += 1;
      this.changeInputColor("email");
      this.openNotification("Email is missing");
    }
    if (!IS_VALID_EMAIL(email)) {
      count += 1;
      this.setState({ email: "" });
      this.changeInputColor("email");
      this.openNotification("Email is invalid");
    }
    if (password === "") {
      count += 1;
      this.changeInputColor("password");
      this.openNotification("Password can not be empty");
    }
    if (timeZone === "") {
      count += 1;
      this.changeInputColor("timeZone");
      this.openNotification("Time zone is missing");
    }
    if (!title) {
      count += 1;
      this.changeInputColor("title");
      this.openNotification("Title is missing");
    }
    if (!avatarPreview) {
      count += 1;
      this.openNotification("Profile picture is missing");
    }
    if (bio.length === 0) {
      count += 1;
      this.openNotification("Bio is missing");
    } else {
      for (let i = 0; i < bio.length; i++) {
        if (bio[i] === undefined) {
          this.openNotification("Bio is missing");
          count += 1;
        } else if (!bio[i].bio) {
          this.openNotification("Bio content is missing");
          count += 1;
        } else if (!bio[i].language) {
          this.openNotification("Bio language is missing");
          count += 1;
        }
      }
    }
    return count === 0;
  };

  onCancelClick = () => {
    this.props.history.push("/admin/therapist");
  };

  onSaveClick = async () => {
    try {
      if (this.validation() === false) throw new Error("Err by validation");
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { id } = this.props.match.params;
      const { UUID, tempDeletedBio, originFileObj } = this.state;
      let deleteBioResult;
      if (tempDeletedBio.length > 0) {
        deleteBioResult = await DELETE_THERAPIST_BIO_SUBMIT(
          UUID,
          tempDeletedBio
        );
      }
      if (originFileObj !== "") {
        await this.handleUploadImageToS3(UUID);
      } else if (this.state.encodedFilename) {
        this.setState({ isUploaded: true });
      }

      const {
        firstName,
        lastName,
        country,
        timeZone,
        countryCode,
        phone,
        email,
        consultationUrl,
        cnConsultationUrl,
        encodedFilename,
        title,
        bio,
        disabled,
      } = this.state;
      var snowFlakeUUID: Array<String> = [];
      for (let i = 0; i < bio.length; i++) {
        if (!bio[i].id) snowFlakeUUID.push(SNOWFLAKE.gen());
      }
      const editInfo = {
        userUuid: UUID,
        firstName,
        lastName,
        email,
        country,
        timeZone,
        countryCode,
        phone,
        avatarFilename: encodedFilename,
        consultationUrl,
        cnConsultationUrl,
        title,
        disabled,
      };

      const result = await EDIT_THERAPIST_SUBMIT(
        editInfo,
        bio,
        snowFlakeUUID,
        token
      );
      if (result.status === "ERROR") {
        this.showNotification("Update failed", false);
        return;
      }

      if (this.state.isUploaded === false) {
        this.setState({ isLoading: false });
        throw new Error("Upload profile picture failed");
      }
      await this.getTherapistInfo();
      this.showNotification("Edited", true);
      this.props.history.go(0);
    } catch (error: any) {
      if (error.message !== "Err by validation")
        this.showNotification(error.message, false);
    }
  };

  render() {
    const { t } = this.props;
    const { id } = this.props.match.params;
    const {
      showModal,
      message,
      notificationStatus,
      UUID,
      firstName,
      lastName,
      countryCode,
      phone,
      email,
      createdAt,
      lastLoginAt,
      timeZone,
      country,
      isUploaded,
      encodedFilename,
      avatarPreview,
      consultationUrl,
      cnConsultationUrl,
      title,
      bio,
      tempDeletedBio,
      emptyBioFromDB,
      disabled,
      isResetPasswordModalVisible,
    } = this.state;
    const dataSourceEN = this.filterBioData("EN");
    const dataSourceZH_CN = this.filterBioData("ZH-CN");
    const dataSourceZH_TW = this.filterBioData("ZH-TW");
    const columnEN = [
      {
        title: "English",
        dataIndex: "bio",
        key: "bio",
        width: "1500px",
      },
    ];

    const columnZH_CN = [
      {
        title: "Simplified Chinese",
        dataIndex: "bio",
        key: "bio",
        width: "1500px",
      },
    ];

    const columnZH_TW = [
      {
        title: "Traditional Chinese",
        dataIndex: "bio",
        key: "bio",
        width: "1500px",
      },
    ];

    const image = {
      isUploaded,
      avatarFilename: encodedFilename,
      avatarPreview,
    };
    return (
      <div>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />
        <ContainerWithGrid>
          <GridColumn>
            <ContentContainer>
              <ContentSubtitle>{t("admin.therapist.UUID")}</ContentSubtitle>
              <Input disabled={true} value={UUID} />
            </ContentContainer>

            <ContentContainer>
              <ContentSubtitle>
                {t("admin.therapist.firstName")}
                <RedAsterisk>*</RedAsterisk>
              </ContentSubtitle>
              <Input
                onChange={this.handleInputChange}
                id="firstName"
                type="text"
                value={firstName}
              />
            </ContentContainer>

            <ContentContainer>
              <ContentSubtitle>
                {t("admin.therapist.lastName")}
                <RedAsterisk>*</RedAsterisk>
              </ContentSubtitle>
              <Input
                onChange={this.handleInputChange}
                id="lastName"
                type="text"
                value={lastName}
              />
            </ContentContainer>
            <ContentContainer>
              <ContentSubtitle>
                {t("admin.therapist.therapistTitle")}
                <RedAsterisk>*</RedAsterisk>
              </ContentSubtitle>
              <Input
                onChange={this.handleInputChange}
                id="title"
                type="text"
                value={title}
              />
            </ContentContainer>
            <ContentContainer>
              <ContentSubtitle>
                {t("admin.therapist.timeZone")}
                <RedAsterisk>*</RedAsterisk>
              </ContentSubtitle>
              <TimeZoneDropdown
                userId={id}
                timeZone={timeZone}
                userUuid={UUID}
                showNotification={this.showNotification}
                handleTimeZoneChange={this.handleTimeZoneChange}
              />
            </ContentContainer>
            <ContentContainer>
              <ContentSubtitle>
                {t("admin.therapist.countryCode")}
                <RedAsterisk>*</RedAsterisk>
              </ContentSubtitle>
              <DropDown
                componentName="admin - therapist"
                type="Country code"
                size="medium-adminCreateTherapistPanel"
                defaultSelection={countryCode}
                selectedText={countryCode}
                onOptionChange={this.handleCountryCodeChange}
                options={getCountryCodeList(t)}
              />
            </ContentContainer>

            <ContentContainer>
              <ContentSubtitle>
                {t("admin.therapist.phone")}
                <RedAsterisk>*</RedAsterisk>
              </ContentSubtitle>
              <Input
                onChange={this.handleInputChange}
                id="phone"
                type="text"
                value={phone}
              />
            </ContentContainer>

            <ContentContainer>
              <ContentSubtitle>{t("admin.therapist.email")}</ContentSubtitle>
              <Input disabled={true} value={email} />
            </ContentContainer>

            <ContentContainer>
              <ContentSubtitle>
                {t("admin.therapist.createdAt")}
              </ContentSubtitle>
              <Input disabled={true} value={createdAt} />
            </ContentContainer>

            <ContentContainer>
              <ContentSubtitle>
                {t("admin.therapist.lastLoginAt")}
              </ContentSubtitle>
              <Input disabled={true} value={lastLoginAt} />
            </ContentContainer>
            <ContentContainer>
              <ContentSubtitle>{t("admin.therapist.disabled")}</ContentSubtitle>
              <Checkbox
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                checked={disabled}
                onChange={this.onDisabledCheckboxClick}
              ></Checkbox>
            </ContentContainer>
            <ContentContainer>
              <ResetPasswordButton>
                <span onClick={this.showResetPasswordModal}>
                  {t("admin.therapist.resetPassword")}
                </span>
              </ResetPasswordButton>
            </ContentContainer>
            <ResetPasswordModal
              visible={isResetPasswordModalVisible}
              onClose={this.closeResetPasswordModal}
              uuid={UUID}
            />
          </GridColumn>
          <GridColumn>
            <ContentContainer>
              <ContentSubtitle>
                {t("admin.therapist.consultationUrl")}
              </ContentSubtitle>
              <Input
                onChange={this.handleInputChange}
                id="consultationUrl"
                type="text"
                value={consultationUrl}
              />
            </ContentContainer>
            <ContentContainer>
              <ContentSubtitle>
                {t("admin.therapist.backupConsultationUrl")}
              </ContentSubtitle>
              <Input
                onChange={this.handleInputChange}
                id="cnConsultationUrl"
                type="text"
                value={cnConsultationUrl}
              />
            </ContentContainer>
            <ContentContainer>
              <ContentSubtitle>
                {t("admin.therapist.profilePicture")}
                <RedAsterisk>*</RedAsterisk>
              </ContentSubtitle>
              <ImageUploader
                image={image}
                beforeUpload={this.beforeUpload}
                handleUploadImageForPreview={this.handleUploadImageForPreview}
              />
            </ContentContainer>
          </GridColumn>
        </ContainerWithGrid>
        <div style={{ width: "90%", marginLeft: "5%" }}>
          <Title>
            {t("admin.therapist.bio")}
            <RedAsterisk>*</RedAsterisk>
          </Title>
          <UploadContainer>
            <ContentSubtitle style={{ fontSize: "16px", fontStyle: "italic" }}>
              {t("admin.therapist.bioDescriptionMessage")}
            </ContentSubtitle>
            {bio.length > 0 ? (
              <TherapistBioTextbox
                currParentComponent="edit"
                bio={bio}
                handleBio={this.handleBio}
                handleDeleteBio={this.handleDeleteBio}
              />
            ) : bio.length === 0 && tempDeletedBio.length > 0 ? (
              <TherapistBioTextbox
                currParentComponent="edit"
                bio={bio}
                handleBio={this.handleBio}
                handleDeleteBio={this.handleDeleteBio}
              />
            ) : (
              emptyBioFromDB && (
                <TherapistBioTextbox
                  currParentComponent="edit"
                  bio={bio}
                  handleBio={this.handleBio}
                  handleDeleteBio={this.handleDeleteBio}
                />
              )
            )}
            <div style={{ display: "flex" }}>
              {dataSourceEN.length > 0 && (
                <Table
                  pagination={false}
                  columns={columnEN}
                  dataSource={dataSourceEN}
                ></Table>
              )}
              {dataSourceZH_CN.length > 0 && (
                <Table
                  pagination={false}
                  columns={columnZH_CN}
                  dataSource={dataSourceZH_CN}
                ></Table>
              )}
              {dataSourceZH_TW.length > 0 && (
                <Table
                  pagination={false}
                  columns={columnZH_TW}
                  dataSource={dataSourceZH_TW}
                ></Table>
              )}
            </div>
          </UploadContainer>
        </div>
        <Container>
          <ContentContainer>
            <CancelButton onClick={this.onCancelClick}>
              {t("admin.common.cancel")}
            </CancelButton>

            <SaveButton onClick={this.onSaveClick}>
              {t("admin.common.save")}
            </SaveButton>
          </ContentContainer>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(TherapistEdit);
