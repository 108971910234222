import styled from "styled-components";
import { QuestionText } from "../../../styles";

// export const StateContainer = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: space-around;
//   align-items: center;
// `;
// export const StateItemContainer = styled.div`
//   width: 20%;
//   display: grid;
//   place-items: center;
//   ${(props: { isSelected: boolean }) =>
//     props.isSelected
//       ? "border-bottom: .1875rem solid #6898E3; color: #6898E3;"
//       : ""}
//     cursor: pointer;
// `;
// export const StateImage = styled.img`
//   margin-bottom: 10px;
//   @media only screen and (max-width: 575px) {
//     height: 35px;
//     width: 35px;
//   }
//   @media only screen and (min-width: 576px) {
//     height: 35px;
//     width: 35px;
//   }
//   @media only screen and (min-width: 768px) {
//     height: 40px;
//     width: 40px;
//   }
//   @media only screen and (min-width: 992px) {
//     height: 50px;
//     width: 50px;
//   }
//   @media only screen and (min-width: 1200px) {
//     height: 50px;
//     width: 50px;
//   }
//   :hover {
//     cursor: pointer;
//   }
// `;

// export const TitleContainer = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
// `

// export const CategoriesTitle = styled.div`
//   font-size: 18px;
//   font-weight: bold;
//   text-align: left;
//   padding: 20px 0px 20px 0;
//   color: #6898E3;
// `;

export const AnalysisResultContainer = styled.div`
  border: solid 1px #80808036;
  border-radius: 4px;
`;
// export const AnalysisResultSectionContainer = styled.div`
//   padding: 10px 10px;
// `;
export const AnalysisResultTitle = styled.div`
  font-weight: bold;
  font-size: 15px;
`;
// export const AnalysisResultText = styled.div`
//   margin-top: 5px;
//   font-size: 12px;
// `;
export const LockedAnalysisResultSectionContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.2);
  padding: 10px 15px;
`;

// export const Line = styled.div`
//   background-color: #80808036;
//   height: 1px;
//   width: 100%;
// `;

export const LockModal = styled.div`
  background: #fff4da;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 5px;
`;

export const LockedModalContent = styled.div`
  width: 100%;
  font-size: 16px;
`;

export const LockedImage = styled.img`
  height: 30px;
  width: 30px;
  margin: 5px 10px;
`;

export const Info = styled.div`
  display: grid;
  place-items: center;
  margin-left: 5px;
  font-weight: bold;
  font-size: 12px;
  height: 15px;
  width: 15px;
  color: white;
  background: #6898e3;
  border-radius: 50%;
  cursor: pointer;
`;
export const CategoryContent = styled(QuestionText)`
  margin-top: 15px;
  font-weight: bold;
`;
