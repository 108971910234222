import styled from "styled-components";

export const Container = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? "flex" : "none")};
  position: absolute;
  z-index: 999;
  top: 11%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 800px;
  height: 600px;
  background-color: #ffffff;
  color: #ffffff;
  left: 50%;
  display: block;
`;

export const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background-color: gray;
  text-align: center;
  line-height: 30px;
  float: right;
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
`;
