import styled from "styled-components";

export const CentralisingDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
`;
export const Container = styled.div`
  background-color: #fff;
  border-radius: 3px;
  padding: 25px 25px 25px 30px;
  margin-bottom: 15px;
  text-align: left;
`;

export const WCArrowImgHolder = styled.img`
  margin-left: 85vw;
  animation: 1s containerMoveDown ease-in-out 0s infinite alternate;

  @keyframes containerMoveDown {
    from {
      transform: translateY(-47vh);
    }
    to {
      transform: translateY(-52vh);
    }
  }
`;

export const LineArrowImgHolder = styled.img`
  margin-left: 85vw;
  animation: 1s containerMoveDown ease-in-out 0s infinite alternate;
  @keyframes containerMoveDown {
    from {
      transform: translateY(10vh);
    }
    to {
      transform: translateY(20vh);
    }
  }
`;

export const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
  @media only screen and (max-width: 800px) {
    font-size: 16px;
    margin-bottom: 20px;
  }
`;
export const AlertText = styled.div`
  font-size: 18px;
  color: #2ab2ac;
  margin-bottom: 15px;
  @media only screen and (max-width: 800px) {
    font-size: 14px;
  }
`;
export const InstructionText = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
  @media only screen and (max-width: 800px) {
    font-size: 14px;
  }
`;
export const ImgContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 800px) {
    display: block;
  }
`;
export const InstructionContentImg = styled.img`
  src: ${(props) => props.src};
  width: 240px;
  min-width: 200px;
  margin: 0px 10px;
  @media only screen and (max-width: 800px) {
    margin: 0 0 20px 0;
  }
`;

// notification
export const Notification = styled.div`
  display: flex;
  left: 16px;
  bottom: 10px;
  height: 68px;
  position: fixed;
  padding: 16px 10px;
  border-radius: 8px;
  min-width: 288px;
  background-color: #242526;
  color: white;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 998;
  transform: matrix(1, 0, 0, 1, 0, 0);
  transition-property: transform, opacity;
  transition-duration: 280ms;
  transition-timing-function: cubic-bezier(0.14, 1, 0.34, 1);
  opacity: 1;
`;
export const NotificationContent = styled.div<{ status: boolean }>`
  color: ${({ status }) => (status ? "#52c41a" : "#ff4d4f")};
  display: flex;
  align-items: center;
`;
export const IconContainer = styled.div`
  padding: 0 6px;
`;

export const ModalMask = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? "flex" : "none")};
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: -webkit-center;
  background-color: #00000099;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999999;
`;
