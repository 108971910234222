import React from "react";
import { Segment, Title } from "../../../styles";
import { withTranslation, useTranslation } from "react-i18next";

const MAX_WEAKER_SKILL_COUNT = 5;

function displayWeakerSkills(weakerSkills, category, t) {
  const divList: React.ReactElement[] = [];

  const skillList = weakerSkills[category]["weakerSkills"];

  const uniqueSkillIds = new Set();

  const uniqueSkillList = skillList.filter((item) => {
    if (!uniqueSkillIds.has(item.skillId)) {
      uniqueSkillIds.add(item.skillId);
      return true;
    }
    return false;
  });

  const getSkillName = (
    obj: { skillName?: { en?: string } } | undefined
  ): string => {
    return obj?.skillName?.en?.toUpperCase() || "";
  };

  uniqueSkillList.sort((a, b) => {
    const nameA = getSkillName(a);
    const nameB = getSkillName(b);

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  for (
    let i = 0;
    i < Math.min(uniqueSkillList.length, MAX_WEAKER_SKILL_COUNT);
    i++
  ) {
    const a = uniqueSkillList[i];

    divList.push(
      <div
        style={{
          fontSize: "14px",
          marginTop: "4px",
        }}
      >
        {t(`skill.${a["skillId"]}`)}
      </div>
    );
  }
  return <div>{divList}</div>;
}

const WeakerSkills = ({ weakerSkills }) => {
  const { t } = useTranslation();

  return (
    <>
      <Title
        style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}
      >
        {t("user.survey.core.questionnaire.weakerSkills")}
      </Title>

      <Segment>
        <div
          className="container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {Object.keys(weakerSkills).map(
            (category) =>
              weakerSkills[category]["weakerSkills"].length !== 0 && (
                <div
                  className="skill-category"
                  style={{ margin: "0px 10px", flexBasis: "calc(50% - 20px)" }}
                >
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "650",
                      marginBottom: "8px",
                      marginTop: "24px",
                    }}
                  >
                    {t(`utils.fiveCategories.${category}.shortTerm`)}
                  </div>
                  <div className="skill-list">
                    {displayWeakerSkills(weakerSkills, category, t)}
                  </div>
                </div>
              )
          )}
        </div>
      </Segment>
    </>
  );
};

export default withTranslation()(WeakerSkills);
