import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  A4Container,
  SectionTitle,
  SectionTitleBasicInfo,
  SectionContainer,
  SectionContainerBasicInfo,
  ListTitleContainer,
  TitleListContainer,
  TitleListContainerBasicInfo,
  ListContentContainer,
  SmallSectionTitle,
  PageNumber,
  WaterMark,
} from "../styles";
import { basicInformationType } from "../../data";
import { getBasicInformation } from "./BasicInformation";
import { getWatermark } from "../ImageLoader";
import { Therapist } from "../../CoreProReport/index"; //Therapist Interface
import TherapistInformation from "../../../../../general/ExpertTeam/TherapistInformation";

interface BasicInformationProps {
  therapistUUID: string;
  therapist: Therapist;
  therapistBio: [];
  resultId: string;
  language: string;
  therapistBioLanguage: string;
  createdAt: string;
  basicInformation: basicInformationType;
}

interface BasicInformationState {}

type Props = BasicInformationProps & WithTranslation;

class BasicInformation extends Component<Props, BasicInformationState> {
  render() {
    const {
      t,
      basicInformation,
      therapistUUID,
      therapist,
      therapistBio,
      createdAt,
      resultId,
    } = this.props;
    const propLanguage = this.props.therapistBioLanguage;
    const data = {
      child_info: basicInformation,
      createdAt,
      resultId,
    };

    const {
      reportID,
      fillingPerson,
      reportDate,
      childName,
      childBirthday,
      childMonths,
      birthStatus,
      sex,
      hearingStatus,
      eyeStatus,
      originalDisease,
      babyHealthStatus,
      language,
    } = getBasicInformation(t, data);
    return (
      <div>
        <A4Container>
          <SectionTitleBasicInfo>
            {t("user.survey.corePro.result.basicInformation.expertInfo.title")}
          </SectionTitleBasicInfo>
          <SectionContainerBasicInfo style={{ display: "flex" }}>
            <ListTitleContainer>
              <div>
                {t(
                  "user.survey.corePro.result.basicInformation.expertInfo.reportId"
                )}
              </div>
              <div>
                {t(
                  "user.survey.corePro.result.basicInformation.expertInfo.reportDate"
                )}
              </div>
            </ListTitleContainer>
            <ListContentContainer>
              <div>{reportID}</div>
              <div>{reportDate}</div>
            </ListContentContainer>
          </SectionContainerBasicInfo>
          <SmallSectionTitle>
            {t(
              "user.survey.corePro.result.basicInformation.expertInfo.therapist"
            )}
          </SmallSectionTitle>
          <SectionContainerBasicInfo>
            <TherapistInformation
              UUID={therapistUUID}
              therapist={therapist}
              therapistBio={therapistBio}
              language={propLanguage}
            />
          </SectionContainerBasicInfo>
          <SmallSectionTitle>
            {t("user.survey.corePro.result.basicInformation.selfInfo.title")}
          </SmallSectionTitle>
          <SectionContainerBasicInfo>
            <div style={{ display: "flex", marginBottom: "20px" }}>
              <TitleListContainerBasicInfo>
                {t(
                  "user.survey.corePro.result.basicInformation.selfInfo.respondent.title"
                )}
              </TitleListContainerBasicInfo>
              <ListContentContainer>
                <div>
                  {": "}
                  {fillingPerson}
                </div>
              </ListContentContainer>
            </div>
            <SmallSectionTitle>
              {t(
                "user.survey.corePro.result.basicInformation.child_info.title"
              )}
            </SmallSectionTitle>
            <div style={{ display: "flex", marginBottom: "20px" }}>
              <TitleListContainerBasicInfo>
                <div>
                  {t(
                    "user.survey.corePro.result.basicInformation.child_info.childName"
                  )}
                </div>
                <div>
                  {t(
                    "user.survey.corePro.result.basicInformation.child_info.birthday.title"
                  )}
                </div>
                <div>
                  {t(
                    "user.survey.corePro.result.basicInformation.child_info.sex.title"
                  )}
                </div>
                <div>
                  {t(
                    "user.survey.corePro.result.basicInformation.child_info.language.title"
                  )}
                </div>
              </TitleListContainerBasicInfo>
              <ListContentContainer>
                <div>
                  {": "}
                  {childName}
                </div>
                <div>
                  {": "}
                  {childBirthday} ({childMonths})
                </div>
                <div>
                  {": "}
                  {sex}
                </div>
                <div>
                  {": "}
                  {language}
                </div>
              </ListContentContainer>
            </div>
            <SmallSectionTitle>
              {t(
                "user.survey.corePro.result.basicInformation.child_info.healthTitle"
              )}
            </SmallSectionTitle>
            <div style={{ display: "flex", marginBottom: "20px" }}>
              <TitleListContainerBasicInfo>
                <div>
                  {t(
                    "user.survey.corePro.result.basicInformation.child_info.birthStatus.title"
                  )}
                </div>
                <div>
                  {t(
                    "user.survey.corePro.result.basicInformation.child_info.postPartumStatus.title"
                  )}
                </div>
                <div>
                  {t(
                    "user.survey.corePro.result.basicInformation.child_info.babyHealthStatus.title"
                  )}
                </div>
                <div>
                  {t(
                    "user.survey.corePro.result.basicInformation.child_info.hearing.title"
                  )}
                </div>
                <div>
                  {t(
                    "user.survey.corePro.result.basicInformation.child_info.eyesight.title"
                  )}
                </div>
              </TitleListContainerBasicInfo>
              <ListContentContainer>
                <div>
                  {": "}
                  {birthStatus}
                </div>
                <div>
                  {": "}
                  {originalDisease}
                </div>
                <div>
                  {": "}
                  {babyHealthStatus}{" "}
                </div>
                <div>
                  {": "}
                  {hearingStatus}
                </div>
                <div>
                  {": "}
                  {eyeStatus}
                </div>
              </ListContentContainer>
            </div>
          </SectionContainerBasicInfo>
          <PageNumber>3</PageNumber>
          <WaterMark src={getWatermark(this.props.language)} />
        </A4Container>
      </div>
    );
  }
}
export default withTranslation()(BasicInformation);
