import styled from "styled-components";

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.p`
  margin-left: 8px;
  white-space: normal;
  width: max-content;
  @media (max-width: 768px) {
    width: 345px;
  }
`;
