import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Drive from "./Drive";
import Record from "./Record";
import Upload from "./Upload";
import ProgressModal from "./ProgressModal";
import { recordingProps } from "./interface";
import imgFinishStamp from "../../assets/imgFinishStamp.png";
import {
  RecordCard,
  RecordDiv,
  Circle,
  RecordTopic,
  RecordText,
  RecordPrompt,
  UploadMenu,
  UploadMenuOption,
  CheckCircle,
  CircleDiv,
  Arrow,
} from "./styles";

interface SoundCollectionState {
  isToggled: boolean;
  activeMenu: string;
  showModal: boolean;
  showProgress: boolean;
  completed: number;
}

type Props = recordingProps & WithTranslation;

class RecordItem extends Component<Props, SoundCollectionState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isToggled: false,
      activeMenu: "microphone",
      showModal: false,
      showProgress: false,
      completed: 0,
    };
  }

  updateProgressModalProgress = (completed: number) => {
    this.setState({ completed: completed });
  };

  updateProgressModalShowChecked = () => {
    this.setState({ showProgress: false });
  };

  setProgressModalOpen = () => {
    this.setState({ showModal: true, completed: 0, showProgress: true });
  };

  setProgressModalClose = () => {
    setTimeout(() => {
      this.setState({ showModal: false, completed: 0 });
    }, 1000);
  };

  render() {
    const { t, prompt, markComplete, transactionId } = this.props;
    const { isToggled, activeMenu, completed, showModal, showProgress } =
      this.state;
    return (
      <RecordDiv>
        <ProgressModal
          completed={completed}
          showModal={showModal}
          showProgress={showProgress}
        />
        <RecordCard
          onClick={() => this.setState({ isToggled: !isToggled })}
          style={{ pointerEvents: `${prompt.complete ? "none" : "initial"}` }}
        >
          <CircleDiv>
            {prompt.complete ? (
              <CheckCircle src={imgFinishStamp} />
            ) : (
              <Circle />
            )}
          </CircleDiv>
          <RecordPrompt>
            <RecordTopic
              style={{
                //Remove "none" if topic is needed again but its just a small small with "topic" in it
                display: "none",
              }}
            >
              {t(
                "user.survey.core.questionnaire.sound_collection.recording.topic"
              )}
            </RecordTopic>
            <RecordText>{t(prompt.text)}</RecordText>
          </RecordPrompt>
          <div style={{ display: "grid", placeItems: "center" }}>
            <Arrow
              className="angle down"
              isOpen={isToggled}
              style={{ display: `${prompt.complete ? "none" : ""}` }}
            />
          </div>
        </RecordCard>

        {!prompt.complete && isToggled && (
          <div>
            <UploadMenu>
              <UploadMenuOption
                isActive={activeMenu === "microphone"}
                onClick={() => this.setState({ activeMenu: "microphone" })}
                padding={"12pt 0pt 6pt 0pt"}
              >
                {t("user.survey.core.questionnaire.sound_collection.record")}
              </UploadMenuOption>
              <UploadMenuOption
                isActive={activeMenu === "upload"}
                onClick={() => this.setState({ activeMenu: "upload" })}
                padding={"12pt 0pt 6pt 0pt"}
              >
                {t("user.survey.core.questionnaire.sound_collection.upload")}
              </UploadMenuOption>
              <UploadMenuOption
                isActive={activeMenu === "google drive"}
                onClick={() => this.setState({ activeMenu: "google drive" })}
                padding={"12pt 0pt 6pt 0pt"}
              >
                {t("user.survey.core.questionnaire.sound_collection.sharing")}
              </UploadMenuOption>
            </UploadMenu>
            <div>
              {activeMenu === "microphone" && (
                <Record
                  updateProgressModalProgress={this.updateProgressModalProgress}
                  setProgressModalClose={this.setProgressModalClose}
                  setProgressModalOpen={this.setProgressModalOpen}
                  updateProgressModalShowChecked={
                    this.updateProgressModalShowChecked
                  }
                  markComplete={markComplete}
                  transactionId={this.props.transactionId}
                  prompt={this.props.prompt}
                />
              )}
              {activeMenu === "upload" && (
                <Upload
                  updateProgressModalProgress={this.updateProgressModalProgress}
                  setProgressModalClose={this.setProgressModalClose}
                  setProgressModalOpen={this.setProgressModalOpen}
                  updateProgressModalShowChecked={
                    this.updateProgressModalShowChecked
                  }
                  markComplete={markComplete}
                  transactionId={transactionId}
                  prompt={prompt}
                />
              )}
              {activeMenu === "google drive" && (
                <Drive
                  markComplete={markComplete}
                  transactionId={transactionId}
                  prompt={prompt}
                />
              )}
            </div>
          </div>
        )}
      </RecordDiv>
    );
  }
}

export default withTranslation()(RecordItem);
