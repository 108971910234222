import { Redirect, Route, Switch } from "react-router";
import { useSelector } from "react-redux";
import { makeSelectRole } from "components/auth/selectors";
import { ROLE } from "constants/common";
import AdminLogin from "components/auth/AdminLogin";
import Login from "components/auth/UserAuthRoute/LoginRoute";
import Register from "components/auth/UserAuthRoute/RegisterRoute";
import ForgotPassword from "components/auth/ForgotPassword";
import ResetPassword from "components/auth/ResetPassword";
import PrivacyPolicy from "components/general/PrivacyPolicy";
import TermsConditions from "components/general/TermsConditions";
import CheckoutPayment from "components/User/Purchase/Payment";
import Order from "components/User/Purchase";
import OrganizationEntry from "components/Organization/Entry";
import ApplyCoupon from "components/User/Purchase/ApplyCoupon";
import OauthRegister from "components/User/ProfileRoute/OauthRegister";

const PublicRoute = () => {
  const role = useSelector(makeSelectRole());
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <Route exact path="/login">
        {!role && <Login />}
        {role === ROLE.user && <Redirect to="/evaluation" />}
        {role === ROLE.therapist && <Redirect to="/therapist" />}
      </Route>
      <Route exact path="/oauth-register" component={OauthRegister} />
      <Route exact path="/adminLogin" component={AdminLogin} />
      <Route exact path="/admin/" component={AdminLogin} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/forgot" component={ForgotPassword} />
      <Route exact path="/reset/:token" component={ResetPassword} />
      <Route exact path="/terms-conditions" component={TermsConditions} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/purchases-payment" component={CheckoutPayment} />
      <Route exact path="/purchases/:product" component={Order} />
      <Route
        exact
        path="/OrganizationEntry/:coupon"
        component={OrganizationEntry}
      />
      <Route exact path="/apply-coupon/:couponcode" component={ApplyCoupon} />
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default PublicRoute;
