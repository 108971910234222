export const REPORT_GENERATION_STEPS = [
  "generalAnalysis",
  "videoAnalysis",
  "activity",
  "reviewAndSuggestion",
];

export const REVIEW_AND_SUGGESTION_LISTS = {
  languageDevelopmentStatusList: [
    "normal",
    "border",
    "mildToModerate",
    "severe",
  ],
  languageDevelopmentList: [
    "canNotUnderstand",
    "canNotExpressOrSpeak",
    "canUnderstand",
    "canExpressOrSpeak",
    "cognitiveConceptsObserved",
  ],
};

export const GENERAL_ANALYSIS_LIST = [
  "oral",
  "speechClarity",
  "comprehension",
  "expression",
  "socialInteraction",
  "earlyReadingSkills",
];

export const OVERALL_QUALITY_LIST = [
  "pictureQuality",
  "audioQuality",
  "naturalness",
];

export const TEMPERAMENT_AND_LEARNING_CHARACTERISTIC_LIST = [
  "activityLevel",
  "sensitivity",
  "distraction",
];

export const SPEECH_LANGUAGE_AND_COMMUNICATION_LIST = [
  "speechClarity",
  "sentenceLength",
  "communication",
];

export const INTERACTION_AND_GUIDANCE_LIST = [
  "qualityOfInteraction",
  "encouragement",
  "qualityOfGuide",
  "emotion",
];

export const REVIEW_AND_SUGGESTION_INDEX = {
  development: 4,
  normalSuggestion: 8,
  borderSuggestion: 9,
  mildToModerateSuggestion: 10,
  severeSuggestion: 10,
};

export const GENERAL_ANALYSIS_INDEX = {
  oral: {
    video: 4,
    overall: 4,
    ability: 6,
  },
  speechClarity: {
    video: 4,
    overall: 4,
    ability: 6,
  },
  comprehension: {
    video: 4,
    overall: 4,
    ability: 6,
  },
  expression: {
    video: 4,
    overall: 4,
    ability: 6,
  },
  socialInteraction: {
    video: 4,
    overall: 4,
    ability: 6,
  },
  earlyReadingSkills: {
    video: 4,
    overall: 4,
    ability: 6,
  },
};

export const VIDEO_ANALYSIS_INDEX = {
  overallQuality: {
    pictureQuality: 5,
    audioQuality: 5,
    naturalness: 5,
  },
  temperamentAndLearningCharacteristic: {
    activityLevel: {
      degree: 4,
      comment: 6,
    },
    sensitivity: {
      degree: 4,
      comment: 6,
    },
    distraction: {
      degree: 4,
      comment: 6,
    },
  },
  speechLanguageAndCommunication: {
    speechClarity: {
      degree: 3,
      comment: 5,
    },
    sentenceLength: {
      degree: 3,
      comment: 7,
    },
    communication: {
      degree: 3,
      comment: 13,
    },
  },
  interactionAndGuidance: {
    qualityOfInteraction: {
      degree: 3,
      comment: 3,
      improvement: 6,
    },
    encouragement: {
      degree: 4,
      comment: 4,
      improvement: 8,
    },
    qualityOfGuide: {
      degree: 4,
      comment: 4,
      improvement: 8,
    },
    emotion: {
      degree: 4,
      improvement: 8,
    },
  },
};
