import styled from "styled-components";
import phoneBlue from "../assets/phoneBlue.svg";
import phoneGrey from "../assets/phoneGrey.svg";
import emailBlue from "../assets/emailBlue.svg";
import emailGrey from "../assets/emailGrey.svg";
import { ButtonStyle, Description, FooterText, H1Title, Link } from "styles";
import { Input, Button } from "semantic-ui-react";

//Container
export const ContainerWidth = styled.div`
  width: 300px;
  @media only screen and (min-width: 768px) {
    width: 330px;
  }
  @media only screen and (min-width: 992px) {
    width: 360px;
  }
  @media only screen and (min-width: 1200px) {
    width: 400px;
  }
`;
export const AuthContainer = styled(ContainerWidth)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-flow: wrap;
  height: 100%;
`;
export const ContentContainer = styled(ContainerWidth)``;
export const FooterContainer = styled(ContainerWidth)`
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  margin: 0 0 10px 0;
`;
export const AuthActionLinkContainer = styled.div`
  display: flex;
  margin-top: 25px;
  align-items: center;
`;
export const FormContainer = styled.form``;
export const PhoneInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const DivideContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.1);
  font-size: 14px;
`;
export const AgreementContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 10px 0 -20px 0;
  align-items: center;
`;

// text
export const AuthTitle = styled(H1Title)`
  color: #6898e3;
  margin-bottom: 40px;
`;
export const AuthInputTitle = styled(Description)`
  color: #6898e3;
  margin: 0 0 4px 2px;
  text-align: left;
`;
export const OrgName = styled(Description)`
  margin: 0 0 16px 6px;
  text-align: left;
`;

export const OrText = styled(Description)`
  margin: 0 10px;
  letter-spacing: 0.2px;
  min-width: max-content;
`;
export const TermsText = styled(Description)`
  cursor: pointer;
`;
export const AuthActionText = styled(Description)``;
export const AuthFooterText = styled(FooterText)`
  margin-bottom: 5px;
`;

// link
export const ForgetLink = styled(Link)`
  text-align: right;
  margin: -12px 3px 0 0;
  color: #6898e3;
  cursor: pointer;
`;
export const AuthActionLink = styled(Link)`
  color: #6898e3;
  cursor: pointer;
  margin-left: 5px;
`;
export const TermsLink = styled(Link)`
  display: inline;
  margin-left: 5px;
  color: #6898e3;
  cursor: pointer;
`;

// input
export const InputArea = styled.input`
  letter-spacing: 0.3px;
  width: 100%;
  height: 50px;
  margin: 0 0 20px 0;
  padding: 0 10px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  line-height: 20px;
  :focus {
    border-bottom: none;
    outline: none;
    border: solid 1px #6898e3;
  }
  :hover {
    border: solid 1px #6898e3;
  }
  :: placeholder {
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-weight: 400;
    color: #909090;
  }
`;
export const PhoneInput = styled(InputArea)`
  width: 100%;
  margin-left: 2%;
`;

export const AgreeInput = styled.input`
  margin-right: 12px;
  cursor: pointer;
`;

// line
export const DivideLine = styled.div`
  width: 100%;
  height: 0.5px;
  background-color: rgba(0, 0, 0, 0.1);
`;

// button
export const AuthButton = styled(ButtonStyle)`
  width: 100%;
  height: 42px;
  margin: 35px 0 20px 0;
  color: white;
  background: #6898e3;
`;
export const LoginStatusButton = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  margin-top: 20px;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: white;
  :hover {
    cursor: pointer;
    border: solid 1px #6898e3;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  }
`;
export const LoginStatusImg = styled.div`
  flex: 0 1 0%;
  width: 40px;
  height: 40px;
  padding: 6px;
`;

export const PhoneImg = styled(LoginStatusImg)`
  content: url(${phoneGrey});
  @media (hover: hover) and (pointer: fine) {
    ${LoginStatusButton}: hover & {
      content: url(${phoneBlue});
    }
  }
`;
export const EmailImg = styled(LoginStatusImg)`
  content: url(${emailGrey});
  @media (hover: hover) and (pointer: fine) {
    ${LoginStatusButton}: hover & {
      content: url(${emailBlue});
    }
  }
`;

export const LoginStatusTitle = styled(Description)`
  flex: 1 1 0%;
  text-align: center;
  @media (hover: hover) and (pointer: fine) {
    ${LoginStatusButton}: hover & {
      color: #6898e3;
    }
  }
`;

export const AccountSettingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const NameFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const NameInputsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const NameInputWrapper = styled.div`
  flex: 1;
`;

export const InputLabel = styled.label`
  color: grey;
  margin-bottom: 5px;
  display: block;
`;

export const StyledInput = styled(Input)`
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
`;

export const StyledButton = styled(Button)`
  &.ui.button {
    margin-right: 0;
  }
`;

export const CountryCodeDropdown = styled.div`
  flex-shrink: 0;
  margin-right: 10px;
`;

export const PhoneInputWrapper = styled.div`
  flex: 1;
`;

export const InfoTextStyled = styled.div`
  flex-grow: 1;
  margin-right: 10px;
`;
