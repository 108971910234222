import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import {
  A4Container,
  SectionTitleReviewAndSuggestions,
  PageNumber,
  WaterMark,
  ContentTextReviewAndSuggestions,
  ListTextReviewAndSuggestions,
  SectionContainerReviewAndSuggestions,
  SmallSectionTitleReviewAndSuggestions,
} from "../styles";
import { reviewAndSuggestionType } from "../../data";
import {
  REVIEW_AND_SUGGESTION_LISTS,
  REVIEW_AND_SUGGESTION_INDEX,
} from "../../../../../../constants/report";
import { getWatermark } from "../ImageLoader";

interface ReviewAndSuggestionProps {
  language: string;
  reviewAndSuggestion: reviewAndSuggestionType;
}

interface ReviewAndSuggestionState {}

type Props = ReviewAndSuggestionProps & WithTranslation;

class ReviewAndSuggestion extends Component<Props, ReviewAndSuggestionState> {
  getSuggestion = () => {
    const { t, reviewAndSuggestion } = this.props;
    const { languageDevelopmentStatusList } = REVIEW_AND_SUGGESTION_LISTS;
    const {
      currentLanguageDevelopmentStatus,
      selectedSuggestions,
      otherSuggestion,
    } = reviewAndSuggestion;
    const status =
      languageDevelopmentStatusList[currentLanguageDevelopmentStatus - 1];
    const otherIndex = REVIEW_AND_SUGGESTION_INDEX[status + "Suggestion"];
    return selectedSuggestions.map((suggestion: number) => {
      return suggestion === otherIndex - 1 ? (
        <ListTextReviewAndSuggestions>
          {otherSuggestion}
        </ListTextReviewAndSuggestions>
      ) : (
        <ListTextReviewAndSuggestions>
          {ReactHtmlParser(
            t(
              `user.survey.corePro.result.reviewAndSuggestion.suggestion.${status}Suggestion.${
                suggestion + 1
              }`
            )
          )}
        </ListTextReviewAndSuggestions>
      );
    });
  };

  render() {
    const { t, reviewAndSuggestion } = this.props;
    const {
      sex,
      canNotUnderstand,
      canNotExpressOrSpeak,
      canUnderstand,
      canExpressOrSpeak,
      cognitiveConceptsObserved,
      comprehensionReview,
      homeActivity,
      currentLanguageDevelopment,
    } = reviewAndSuggestion;
    const heOrShe =
      sex === "1"
        ? t("user.survey.corePro.result.reviewAndSuggestion.overall.he")
        : t("user.survey.corePro.result.reviewAndSuggestion.overall.she");

    return (
      <A4Container>
        <SectionTitleReviewAndSuggestions>
          {t("user.survey.corePro.result.reviewAndSuggestion.title")}
        </SectionTitleReviewAndSuggestions>
        <SmallSectionTitleReviewAndSuggestions>
          {t("user.survey.corePro.result.reviewAndSuggestion.overall.title")}
        </SmallSectionTitleReviewAndSuggestions>
        <SectionContainerReviewAndSuggestions>
          <ContentTextReviewAndSuggestions>
            {t(
              "user.survey.corePro.result.reviewAndSuggestion.overall.language"
            )}{" "}
            {currentLanguageDevelopment}
          </ContentTextReviewAndSuggestions>
          <ListTextReviewAndSuggestions>
            {heOrShe +
              " " +
              t(
                "user.survey.corePro.result.reviewAndSuggestion.overall.canNotUnderstand"
              ) +
              " " +
              canNotUnderstand}
          </ListTextReviewAndSuggestions>
          <ListTextReviewAndSuggestions>
            {heOrShe +
              " " +
              t(
                "user.survey.corePro.result.reviewAndSuggestion.overall.canNotExpressOrSpeak"
              ) +
              " " +
              canNotExpressOrSpeak}
          </ListTextReviewAndSuggestions>
          <ListTextReviewAndSuggestions>
            {heOrShe +
              " " +
              t(
                "user.survey.corePro.result.reviewAndSuggestion.overall.canUnderstand"
              ) +
              " " +
              canUnderstand}
          </ListTextReviewAndSuggestions>
          <ListTextReviewAndSuggestions>
            {heOrShe +
              " " +
              t(
                "user.survey.corePro.result.reviewAndSuggestion.overall.canExpressOrSpeak"
              ) +
              " " +
              canExpressOrSpeak}
          </ListTextReviewAndSuggestions>
          <ListTextReviewAndSuggestions>
            {t(
              "user.survey.corePro.result.reviewAndSuggestion.overall.cognitiveConceptsObserved"
            ) +
              " " +
              cognitiveConceptsObserved}
          </ListTextReviewAndSuggestions>
          <ContentTextReviewAndSuggestions></ContentTextReviewAndSuggestions>
        </SectionContainerReviewAndSuggestions>
        <SmallSectionTitleReviewAndSuggestions>
          {t(
            "user.survey.corePro.result.reviewAndSuggestion.comprehensiveReview.title"
          )}
        </SmallSectionTitleReviewAndSuggestions>
        <SectionContainerReviewAndSuggestions>
          <ContentTextReviewAndSuggestions>
            {comprehensionReview}
          </ContentTextReviewAndSuggestions>
        </SectionContainerReviewAndSuggestions>
        <SmallSectionTitleReviewAndSuggestions>
          {t(
            "user.survey.corePro.result.reviewAndSuggestion.homeActivity.title"
          )}
        </SmallSectionTitleReviewAndSuggestions>
        <SectionContainerReviewAndSuggestions>
          <ContentTextReviewAndSuggestions>
            {homeActivity}
          </ContentTextReviewAndSuggestions>
        </SectionContainerReviewAndSuggestions>
        <SmallSectionTitleReviewAndSuggestions>
          {t("user.survey.corePro.result.reviewAndSuggestion.suggestion.title")}
        </SmallSectionTitleReviewAndSuggestions>
        <SectionContainerReviewAndSuggestions>
          <ContentTextReviewAndSuggestions>
            {this.getSuggestion()}
          </ContentTextReviewAndSuggestions>
        </SectionContainerReviewAndSuggestions>
        <PageNumber>4</PageNumber>
        <WaterMark src={getWatermark(this.props.language)} />
      </A4Container>
    );
  }
}
export default withTranslation()(ReviewAndSuggestion);
