import storyLineCn1png from "./images/Cn/1.png";
import storyLineCn2png from "./images/Cn/2.png";
import storyLineCn3png from "./images/Cn/3.png";
import storyLineCn4png from "./images/Cn/4.png";
import storyLineTw1png from "./images/Tw/1.png";
import storyLineTw2png from "./images/Tw/2.png";
import storyLineTw3png from "./images/Tw/3.png";
import storyLineTw4png from "./images/Tw/4.png";
import storyLineEn1png from "./images/En/1.png";
import storyLineEn2png from "./images/En/2.png";
import storyLineEn3png from "./images/En/3.png";
import storyLineEn4png from "./images/En/4.png";
import storyLineCn1webp from "./images/Cn/1.webp";
import storyLineCn2webp from "./images/Cn/2.webp";
import storyLineCn3webp from "./images/Cn/3.webp";
import storyLineCn4webp from "./images/Cn/4.webp";
import storyLineTw1webp from "./images/Tw/1.webp";
import storyLineTw2webp from "./images/Tw/2.webp";
import storyLineTw3webp from "./images/Tw/3.webp";
import storyLineTw4webp from "./images/Tw/4.webp";
import storyLineEn1webp from "./images/En/1.webp";
import storyLineEn2webp from "./images/En/2.webp";
import storyLineEn3webp from "./images/En/3.webp";
import storyLineEn4webp from "./images/En/4.webp";

export const GET_STORY_LINE = (language: string) => {
  switch (language) {
    case "en":
      return {
        storyLine1png: storyLineEn1png,
        storyLine2png: storyLineEn2png,
        storyLine3png: storyLineEn3png,
        storyLine4png: storyLineEn4png,
        storyLine1webp: storyLineEn1webp,
        storyLine2webp: storyLineEn2webp,
        storyLine3webp: storyLineEn3webp,
        storyLine4webp: storyLineEn4webp,
      };
    case "zh-CN":
      return {
        storyLine1png: storyLineCn1png,
        storyLine2png: storyLineCn2png,
        storyLine3png: storyLineCn3png,
        storyLine4png: storyLineCn4png,
        storyLine1webp: storyLineCn1webp,
        storyLine2webp: storyLineCn2webp,
        storyLine3webp: storyLineCn3webp,
        storyLine4webp: storyLineCn4webp,
      };
    case "zh-TW":
      return {
        storyLine1png: storyLineTw1png,
        storyLine2png: storyLineTw2png,
        storyLine3png: storyLineTw3png,
        storyLine4png: storyLineTw4png,
        storyLine1webp: storyLineTw1webp,
        storyLine2webp: storyLineTw2webp,
        storyLine3webp: storyLineTw3webp,
        storyLine4webp: storyLineTw4webp,
      };
    default:
      return {};
  }
};
