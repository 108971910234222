import { Component } from "react";
import axios from "axios";
import api_address from "../../../constants/config";
import { withTranslation, WithTranslation } from "react-i18next";
import { withRouter, match, RouteComponentProps } from "react-router-dom";
import Cookies from "js-cookie";
import {
  reviewAndSuggestionType,
  initialReviewAndSuggestion,
  generalAnalysisType,
  initialGeneralAnalysis,
  videoAnalysisType,
  initialVideoAnalysis,
  activitiesType,
  initialActivities,
} from "../../User/Evaluation/CorePro/data";
import {
  AutoSaveButton,
  ButtonContainer,
  FloatButton,
  NextButton,
  SaveIcon,
} from "./styles";
import { REPORT_GENERATION_STEPS } from "../../../constants/report";
import ReviewAndSuggestionForm from "./ReviewAndSuggestion";
import GeneralAnalysisForm from "./GeneralAnalysis";
import VideoAnalysisForm from "./VideoAnalysis";
import ActivityForm from "./Activity";
import ValidationModal from "./component/ValidationModal";
import { isReviewAndSuggestionNextDisabled } from "./ReviewAndSuggestion";
import { isGeneralAnalysisNextDisabled } from "./GeneralAnalysis";
import { isVideoAnalysisNextDisabled } from "./VideoAnalysis";
import { isActivityNextDisabled } from "./Activity";
import ProgressBarMenu from "components/common/ProgressBarMenu";
import { ActionTitle } from "styles";
import { GET_COOKIE_NAME, JS_COOKIE } from "util/auth";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { makeSelectRole } from "components/auth/selectors";
import { AppState } from "storeConfig";
import { ROLE } from "constants/common";

interface Match {
  resultId: string;
}

interface ReportGenerationProps {
  match?: match<Match>;
  location: History;
}

interface ReportGenerationState {
  currentStep: string;
  selection: number;
  savedReviewAndSuggestion: reviewAndSuggestionType;
  savedGeneralAnalysis: generalAnalysisType;
  savedVideoAnalysis: videoAnalysisType;
  savedActivities: activitiesType;
  currentReviewAndSuggestion: reviewAndSuggestionType;
  currentGeneralAnalysis: generalAnalysisType;
  currentVideoAnalysis: videoAnalysisType;
  currentActivities: activitiesType;
  showWarning: boolean;
  reviewAndSuggestionCompleted: boolean;
  generalAnalysisCompleted: boolean;
  videoAnalysisCompleted: boolean;
  activityCompleted: boolean;
  showModal: boolean;
  autoSaveVisible: boolean;
}

type States = ReportGenerationState;

type Props = ReportGenerationProps &
  WithTranslation &
  RouteComponentProps &
  LinkStateProp;

class ReportGeneration extends Component<Props, States> {
  interval: NodeJS.Timeout;
  timeout: NodeJS.Timeout;
  constructor(props: Props) {
    super(props);
    this.state = {
      savedReviewAndSuggestion: initialReviewAndSuggestion,
      savedGeneralAnalysis: initialGeneralAnalysis,
      savedVideoAnalysis: initialVideoAnalysis,
      savedActivities: initialActivities,
      currentReviewAndSuggestion: initialReviewAndSuggestion,
      currentGeneralAnalysis: initialGeneralAnalysis,
      currentVideoAnalysis: initialVideoAnalysis,
      currentActivities: initialActivities,
      reviewAndSuggestionCompleted: false,
      generalAnalysisCompleted: false,
      videoAnalysisCompleted: false,
      activityCompleted: false,
      showWarning: true,
      currentStep: "",
      selection: 0,
      showModal: false,
      autoSaveVisible: false,
    };
  }

  componentDidMount = () => {
    const { role } = this.props;
    const token = JS_COOKIE.get(GET_COOKIE_NAME(role));
    const { resultId } = this.props.match.params;
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.interval = setInterval(() => {
      if (this.unsavedData()) {
        this.handleSave(true, this.state.selection);
        this.setState({
          autoSaveVisible: true,
        });
        this.timeout = setTimeout(() => {
          this.setState({
            autoSaveVisible: false,
          });
        }, 3000);
      }
    }, 45000);

    axios
      .get(api_address + "api/therapistForm/reportGeneration/" + resultId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const { status } = res.data;
        if (!status) {
          const { message } = res.data;
          throw new Error(message);
        }
        const {
          currentStep,
          reviewAndSuggestion,
          generalAnalysis,
          videoAnalysis,
          activities,
        } = res.data;
        const currentIndex = REPORT_GENERATION_STEPS.indexOf(currentStep);
        const reviewAndSuggestionCompleted =
          !isReviewAndSuggestionNextDisabled(reviewAndSuggestion);
        const generalAnalysisCompleted =
          !isGeneralAnalysisNextDisabled(generalAnalysis);
        const videoAnalysisCompleted =
          !isVideoAnalysisNextDisabled(videoAnalysis);
        const activityCompleted = !isActivityNextDisabled(activities);
        this.setState({
          currentReviewAndSuggestion: reviewAndSuggestion,
          currentGeneralAnalysis: generalAnalysis,
          currentVideoAnalysis: videoAnalysis,
          currentActivities: activities,
          savedReviewAndSuggestion: reviewAndSuggestion,
          savedGeneralAnalysis: generalAnalysis,
          savedVideoAnalysis: videoAnalysis,
          savedActivities: activities,
          reviewAndSuggestionCompleted,
          generalAnalysisCompleted,
          videoAnalysisCompleted,
          activityCompleted,
          currentStep:
            currentIndex !== -1 ? currentStep : REPORT_GENERATION_STEPS[0],
          selection: currentIndex !== -1 ? currentIndex : 0,
        });
      })
      .catch((err) => {
        console.error(err.message);
        this.props.history.push("/therapist");
      });
  };

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.timeout);
  }

  componentDidUpdate = () => {
    const { showWarning } = this.state;
    if (showWarning) {
      window.onbeforeunload = () => true;
    }
    this.updateReviewAndSuggestionCompletedStatus();
    this.updateGeneralAnalysisCompletedStatus();
    this.updateVideoAnalysisCompletedStatus();
    this.updateActivityCompletedStatus();
  };

  handleSave = (isTempSave: boolean, selection: number) => {
    const { resultId } = this.props.match.params;
    const {
      currentReviewAndSuggestion,
      currentGeneralAnalysis,
      currentVideoAnalysis,
      currentActivities,
    } = this.state;
    const step = REPORT_GENERATION_STEPS[selection];
    const data = {
      isTempSave,
      currentStep: isTempSave ? step : "report",
      resultId,
      reviewAndSuggestion: currentReviewAndSuggestion,
      generalAnalysis: currentGeneralAnalysis,
      videoAnalysis: currentVideoAnalysis,
      activities: currentActivities,
    };
    const { role } = this.props;
    const token = JS_COOKIE.get(GET_COOKIE_NAME(role));
    axios
      .post(api_address + "api/therapistForm", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (!isTempSave) {
            if (role === ROLE.admin) {
              this.props.history.push(`/admin/core-pro-report/${resultId}`);
            } else {
              this.props.history.push(`/core-pro-report/${resultId}`);
            }
          }
          this.setState({
            savedReviewAndSuggestion: currentReviewAndSuggestion,
            savedGeneralAnalysis: currentGeneralAnalysis,
            savedVideoAnalysis: currentVideoAnalysis,
            savedActivities: currentActivities,
          });
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  // handle step change and save on next,prev,tab click
  handleTabClick = (index: number) => {
    this.setState({
      currentStep: REPORT_GENERATION_STEPS[index],
      selection: index,
    });
    this.handleSave(true, index);
  };
  handlePrevious = (selection: number) => {
    const prevSelection = selection - 1;
    const prevStep = REPORT_GENERATION_STEPS[prevSelection];
    this.setState({
      currentStep: prevStep,
      selection: prevSelection,
    });
    this.handleSave(true, prevSelection);
  };
  handleNext = (selection: number) => {
    const nextSelection = selection + 1;
    const nextStep = REPORT_GENERATION_STEPS[nextSelection];
    this.setState({
      currentStep: nextStep,
      selection: nextSelection,
    });
    this.handleSave(true, nextSelection);
  };
  handleSubmit = (selection: number) => {
    const {
      reviewAndSuggestionCompleted,
      generalAnalysisCompleted,
      videoAnalysisCompleted,
      activityCompleted,
    } = this.state;
    if (
      reviewAndSuggestionCompleted === false ||
      generalAnalysisCompleted === false ||
      videoAnalysisCompleted === false ||
      activityCompleted === false
    ) {
      this.setState({ showModal: true });
    } else {
      this.handleSave(false, selection);
    }
    this.handleSave(true, selection);
  };
  handleNextButton = (selection: number) => {
    // if selection === step of activity
    if (selection === 3) {
      this.handleSubmit(selection);
    } else {
      this.handleNext(selection);
    }
  };

  // modal function
  handleGotoUnfinished = () => {
    const {
      reviewAndSuggestionCompleted,
      generalAnalysisCompleted,
      videoAnalysisCompleted,
      activityCompleted,
    } = this.state;
    let stepIndex = 0;
    if (activityCompleted === false) {
      stepIndex = 2;
    }
    if (videoAnalysisCompleted === false) {
      stepIndex = 1;
    }
    if (generalAnalysisCompleted === false) {
      stepIndex = 0;
    }
    if (reviewAndSuggestionCompleted === false) {
      stepIndex = 3;
    }
    this.setState({
      currentStep: REPORT_GENERATION_STEPS[stepIndex],
      selection: stepIndex,
      showModal: false,
    });
  };
  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
  };

  // update current new data in state
  updatedStateHelper = (states: object, keyName: string) => {
    let value = this.state[keyName];
    let deepCopy = JSON.parse(JSON.stringify(value));
    for (let key in states) {
      // update deep copy data from the current form changes data
      deepCopy[key] = states[key];
    }
    let newState = {};
    newState[keyName] = deepCopy;
    this.setState(newState);
  };
  updateReviewAndSuggestionStates = (states: object) => {
    this.updatedStateHelper(states, "currentReviewAndSuggestion");
  };
  updateGeneralAnalysisStates = (states: object) => {
    this.updatedStateHelper(states, "currentGeneralAnalysis");
  };
  updateVideoAnalysisStates = (states: object) => {
    this.updatedStateHelper(states, "currentVideoAnalysis");
  };
  updateActivityStates = (states: object) => {
    this.updatedStateHelper(states, "currentActivities");
  };

  // identify there is new current data compare with saved data
  unsavedData = () => {
    const {
      currentReviewAndSuggestion,
      currentGeneralAnalysis,
      currentVideoAnalysis,
      currentActivities,
      savedReviewAndSuggestion,
      savedGeneralAnalysis,
      savedVideoAnalysis,
      savedActivities,
    } = this.state;
    const currentData = [
      currentReviewAndSuggestion,
      currentGeneralAnalysis,
      currentVideoAnalysis,
      currentActivities,
    ];
    const savedData = [
      savedReviewAndSuggestion,
      savedGeneralAnalysis,
      savedVideoAnalysis,
      savedActivities,
    ];
    const newContentChanged =
      JSON.stringify(currentData) !== JSON.stringify(savedData);
    return newContentChanged;
  };

  // update form section completion status
  updateReviewAndSuggestionCompletedStatus = () => {
    const { currentReviewAndSuggestion } = this.state;
    const isCompleted = !isReviewAndSuggestionNextDisabled(
      currentReviewAndSuggestion
    );
    if (isCompleted !== this.state.reviewAndSuggestionCompleted) {
      this.setState({ reviewAndSuggestionCompleted: isCompleted });
    }
  };
  updateGeneralAnalysisCompletedStatus = () => {
    const { currentGeneralAnalysis } = this.state;
    const isCompleted = !isGeneralAnalysisNextDisabled(currentGeneralAnalysis);
    if (isCompleted !== this.state.generalAnalysisCompleted) {
      this.setState({ generalAnalysisCompleted: isCompleted });
    }
  };
  updateVideoAnalysisCompletedStatus = () => {
    const { currentVideoAnalysis } = this.state;
    const isCompleted = !isVideoAnalysisNextDisabled(currentVideoAnalysis);
    if (isCompleted !== this.state.videoAnalysisCompleted) {
      this.setState({ videoAnalysisCompleted: isCompleted });
    }
  };
  updateActivityCompletedStatus = () => {
    const { currentActivities } = this.state;
    const isCompleted = !isActivityNextDisabled(currentActivities);
    if (isCompleted !== this.state.activityCompleted) {
      this.setState({ activityCompleted: isCompleted });
    }
  };

  handleNextButtonText = () => {
    const { t } = this.props;
    const { currentStep } = this.state;
    console.log("currentStep", currentStep);
    if (currentStep === "reviewAndSuggestion") {
      return t("therapist.reportGeneration.submitButton");
    }
    return t("therapist.reportGeneration.nextButton");
  };

  render = () => {
    const {
      selection,
      currentReviewAndSuggestion,
      currentGeneralAnalysis,
      currentVideoAnalysis,
      currentActivities,
      reviewAndSuggestionCompleted,
      generalAnalysisCompleted,
      videoAnalysisCompleted,
      activityCompleted,
      showModal,
      autoSaveVisible,
    } = this.state;
    const { t } = this.props;
    const tabTitles = REPORT_GENERATION_STEPS.map((step: string) => {
      return t(`therapist.reportGeneration.${step}.progressbarTitle`);
    });
    const tabData = [
      <GeneralAnalysisForm
        setState={this.updateGeneralAnalysisStates}
        generalAnalysis={currentGeneralAnalysis}
      />,
      <VideoAnalysisForm
        setState={this.updateVideoAnalysisStates}
        videoAnalysis={currentVideoAnalysis}
      />,
      <ActivityForm
        setState={this.updateActivityStates}
        activities={currentActivities}
      />,
      <ReviewAndSuggestionForm
        reviewAndSuggestion={currentReviewAndSuggestion}
        setState={this.updateReviewAndSuggestionStates}
      />,
    ];
    const completionStatus = [
      generalAnalysisCompleted,
      videoAnalysisCompleted,
      activityCompleted,
      reviewAndSuggestionCompleted,
    ];
    return (
      <>
        <ValidationModal
          show={showModal}
          handleGotoUnfinished={this.handleGotoUnfinished}
          handleCloseModal={this.handleCloseModal}
        />
        <FloatButton
          onClick={() => this.handleSave(true, selection)}
          status={this.unsavedData()}
          display={autoSaveVisible}
        >
          <SaveIcon className={this.unsavedData() ? "save" : "check circle"} />
          <ActionTitle>
            {this.unsavedData()
              ? t("therapist.reportGeneration.tempSaveButton")
              : t("therapist.reportGeneration.saved")}
          </ActionTitle>
        </FloatButton>
        <AutoSaveButton display={autoSaveVisible}>
          <SaveIcon className={"check circle"} />
          <ActionTitle>{t("therapist.reportGeneration.autoSaved")}</ActionTitle>
        </AutoSaveButton>

        <ProgressBarMenu
          tabTitles={tabTitles}
          tabContents={tabData}
          selection={selection}
          completionStatus={completionStatus}
          handleTabClick={this.handleTabClick}
          unsavedStatus={this.unsavedData()}
          handleSave={this.handleSave}
        />
        <ButtonContainer>
          {selection !== 0 && (
            <NextButton onClick={() => this.handlePrevious(selection)}>
              {t("therapist.reportGeneration.previousButton")}
            </NextButton>
          )}
          <NextButton onClick={() => this.handleNextButton(selection)}>
            {this.handleNextButtonText()}
          </NextButton>
        </ButtonContainer>
      </>
    );
  };
}

interface LinkStateProp {
  role: string;
}

const mapStateToProps = createStructuredSelector<AppState, LinkStateProp>({
  role: makeSelectRole(),
});

export default withRouter(
  connect(mapStateToProps)(withTranslation()(ReportGeneration))
);
