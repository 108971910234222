import { Component, useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import {
  NextButton,
  CloseButtonContainer,
  ModalContainer,
  ModalHeader,
  ModalMask,
  ModalContent,
  ShowErrNextButton,
  HeaderTitle,
  ContentText,
  ModalContainerWithClose,
  ModalButtonContainer,
  ActionTitle,
} from "styles";

import { UploadVideoLinkInstructionButton } from "../../../Evaluation/styles";

const UploadModal = (props) => {
  //Disable scrolling functions when modal opens
  const disableScroll = () => {
    document.addEventListener("wheel", preventDefault, { passive: false });
  };

  const enableScroll = () => {
    document.removeEventListener("wheel", preventDefault, false);
  };

  const preventDefault = (e: any) => {
    e = e || window.event;
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.returnValue = false;
  };

  //Disables scrolling when the modal is first loaded
  useEffect(() => {
    disableScroll();
  }, []);

  return (
    <div>
      <ModalMask open={true}>
        <ModalContainerWithClose>
          <ModalContainer>
            <ModalHeader>
              <HeaderTitle
                style={{
                  fontSize: "24px",
                  color: "black",
                  textAlign: "center",
                }}
              >
                {props.modalHeader}
              </HeaderTitle>
            </ModalHeader>
            <ModalContent>
              <ContentText style={{ textAlign: "center" }}>
                {props.modalText}
              </ContentText>
            </ModalContent>

            <ModalContent>
              <ContentText style={{ textAlign: "center", marginTop: "12px" }}>
                {props.modalText2}
                <UploadVideoLinkInstructionButton
                  href="/upload-instructions"
                  target="_blank"
                >
                  {props.modalText3}
                </UploadVideoLinkInstructionButton>
              </ContentText>
            </ModalContent>

            <ModalButtonContainer>
              <ShowErrNextButton
                onClick={function (event) {
                  enableScroll();
                  props.onCancel();
                }}
                status={false}
                style={{
                  marginRight: "20px",
                  borderRadius: "50px",
                  color: "#6898e3",
                  borderColor: "#6898e3",
                }}
              >
                <ActionTitle>{props.cancelText}</ActionTitle>
              </ShowErrNextButton>
              <NextButton
                onClick={function (event) {
                  enableScroll();
                  props.onAction();
                }}
                status={true}
                style={{
                  marginRight: "0px",
                  borderRadius: "50px",
                  background: "#6898e3",
                }}
              >
                <ActionTitle>{props.actionText}</ActionTitle>
              </NextButton>
            </ModalButtonContainer>
          </ModalContainer>
        </ModalContainerWithClose>
      </ModalMask>
    </div>
  );
};

export default withTranslation()(UploadModal);
