import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  PageContainer,
  OptionContainer,
  LabelContainer,
  OptionText,
  OptionRadio,
  SmallSectionTitleContainer,
  FormSectionDescriptionText,
  FormTitle,
} from "../styles";

import {
  VIDEO_ANALYSIS_INDEX,
  TEMPERAMENT_AND_LEARNING_CHARACTERISTIC_LIST,
  OVERALL_QUALITY_LIST,
  SPEECH_LANGUAGE_AND_COMMUNICATION_LIST,
  INTERACTION_AND_GUIDANCE_LIST,
} from "../../../../constants/report";
import { videoAnalysisType } from "../../../User/Evaluation/CorePro/data";
import FormSectionTitle from "../component/FormSectionTitle";
import { QuestionText } from "../component/style";
import QuestionTitle from "../component/QuestionTitle";
import TextArea from "../component/TextArea";
import FormSection from "../component/FormSection";
import { Color } from "../ColorUtils";

interface VideoAnalysisFormProps {
  videoAnalysis: videoAnalysisType;
  setState: (states: object) => void;
}

interface VideoAnalysisStates {}

type States = VideoAnalysisStates;

type Props = VideoAnalysisFormProps & WithTranslation & RouteComponentProps;

class VideoAnalysisForm extends Component<Props, States> {
  private activityLevel;
  private temperamentAndLearningCharacteristicactivityLevel;
  private sensitivity;
  private temperamentAndLearningCharacteristicsensitivity;
  private distraction;
  private temperamentAndLearningCharacteristicdistraction;
  private speechClarity;
  private speechLanguageAndCommunicationspeechClarity;
  private sentenceLength;
  private speechLanguageAndCommunicationsentenceLength;
  private communication;
  private speechLanguageAndCommunicationcommunication;
  private qualityOfInteraction;
  private interactionAndGuidancequalityOfInteraction;
  private encouragement;
  private interactionAndGuidanceencouragement;
  private qualityOfGuide;
  private interactionAndGuidancequalityOfGuide;
  private emotion;
  private interactionAndGuidanceemotion;
  private improvementOtherRef;
  constructor(props: Props) {
    super(props);
    this.activityLevel = React.createRef();
    this.temperamentAndLearningCharacteristicactivityLevel = React.createRef();
    this.sensitivity = React.createRef();
    this.temperamentAndLearningCharacteristicsensitivity = React.createRef();
    this.distraction = React.createRef();
    this.temperamentAndLearningCharacteristicdistraction = React.createRef();
    this.speechClarity = React.createRef();
    this.speechLanguageAndCommunicationspeechClarity = React.createRef();
    this.sentenceLength = React.createRef();
    this.speechLanguageAndCommunicationsentenceLength = React.createRef();
    this.communication = React.createRef();
    this.speechLanguageAndCommunicationcommunication = React.createRef();
    this.qualityOfInteraction = React.createRef();
    this.interactionAndGuidancequalityOfInteraction = React.createRef();
    this.encouragement = React.createRef();
    this.interactionAndGuidanceencouragement = React.createRef();
    this.qualityOfGuide = React.createRef();
    this.interactionAndGuidancequalityOfGuide = React.createRef();
    this.emotion = React.createRef();
    this.interactionAndGuidanceemotion = React.createRef();
    this.improvementOtherRef = React.createRef();
  }

  componentDidMount = () => {
    window.scrollTo({ top: 0 });
  };

  handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;
    const { setState } = this.props;
    setState({ [name]: value });
  };

  handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    const { setState } = this.props;
    setState({ [name]: parseInt(value) });
  };

  handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: { section: string; question: string }
  ) => {
    const { name } = event.currentTarget;
    const value = +event.currentTarget.value;
    const { section, question } = data;
    const sectionIndex = VIDEO_ANALYSIS_INDEX[section];
    const { setState } = this.props;
    let oldList = this.props.videoAnalysis[name] as Array<number>;
    let oldOther = this.props.videoAnalysis[name + "Other"];
    const index = oldList.indexOf(value);
    if (index > -1) {
      oldList = oldList.filter((item: number) => item !== value);
      if (index === sectionIndex[question] - 1) {
        oldOther = "";
      }
    } else {
      oldList = oldList.concat([value]);
    }
    setState({
      [name]: oldList,
      [name + "Other"]: oldOther,
    });
  };

  getOverallQualityQuestion = (question: string): JSX.Element => {
    const { t, videoAnalysis } = this.props;
    const value = videoAnalysis[question];
    const options: Array<number> = [];
    const optionCount = VIDEO_ANALYSIS_INDEX.overallQuality[question];
    for (let i = 0; i < optionCount; i++) {
      options.push(i + 1);
    }
    return (
      <FormSection
        key={question}
        topLeftBorderRadius={true}
        indicator={false}
        index={1}
      >
        <QuestionTitle>
          {t(`therapist.reportGeneration.overallQuality.${question}.title`) +
            t(
              `therapist.reportGeneration.overallQuality.${question}.description`
            )}
        </QuestionTitle>
        {options.map((option, index) => {
          return (
            <OptionContainer>
              <LabelContainer key={question + " " + index}>
                <OptionRadio
                  type="radio"
                  value={option}
                  name={question}
                  onChange={this.handleRadioChange}
                  checked={value === option}
                />
                <OptionText>{option}</OptionText>
              </LabelContainer>
            </OptionContainer>
          );
        })}
      </FormSection>
    );
  };

  getOverallQuality = (): JSX.Element => {
    const { t } = this.props;
    const questions = this.getOverallQualityQuestion;
    return (
      <>
        <SmallSectionTitleContainer backgroundColor={"#6898e3"}>
          {t("therapist.reportGeneration.overallQuality.title")}
        </SmallSectionTitleContainer>
        {OVERALL_QUALITY_LIST.map((question: string) => {
          return questions(question);
        })}
      </>
    );
  };

  getDegree = (section: string, question: string): JSX.Element => {
    const { t, videoAnalysis } = this.props;
    const value = videoAnalysis[question];
    const options: Array<string> = [];
    const optionCount = VIDEO_ANALYSIS_INDEX[section][question].degree;
    for (let i = 0; i < optionCount; i++) {
      options.push(
        t(`therapist.reportGeneration.${section}.${question}.degree.${i}`)
      );
    }
    return (
      <>
        <QuestionTitle>
          {t(`therapist.reportGeneration.${section}.${question}.degree.title`)}
        </QuestionTitle>
        {options.map((option, index) => {
          return (
            <OptionContainer>
              <LabelContainer key={question + " " + index}>
                <OptionRadio
                  type="radio"
                  value={index}
                  name={question}
                  onChange={this.handleRadioChange}
                  checked={value === index}
                />
                <OptionText>{option}</OptionText>
              </LabelContainer>
            </OptionContainer>
          );
        })}
        {value === optionCount - 1 ? (
          <TextArea
            placeholder=""
            handleInputChange={this.handleInputChange}
            _ref={this[question]}
            isEmpty={false}
            id={question + "Other"}
            name={question + "Other"}
            value={videoAnalysis[question + "Other"]}
          />
        ) : undefined}
      </>
    );
  };

  getComments = (section: string, question: string): JSX.Element => {
    const { t, videoAnalysis } = this.props;
    const value = videoAnalysis[question + "Comment"] as Array<number>;
    const options: Array<string> = [];
    const data = {
      section,
      question,
    };
    const optionCount = VIDEO_ANALYSIS_INDEX[section][question].comment;
    for (let i = 0; i < optionCount; i++) {
      options.push(
        t(`therapist.reportGeneration.${section}.${question}.comment.${i}`)
      );
    }
    return (
      <>
        <QuestionTitle>
          {t(`therapist.reportGeneration.${section}.${question}.comment.title`)}
        </QuestionTitle>
        {options.map((option: string, index: number) => {
          return (
            <OptionContainer>
              <LabelContainer key={question + " " + index}>
                <OptionRadio
                  type="checkbox"
                  value={index}
                  name={question + "Comment"}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    this.handleCheckboxChange(event, data)
                  }
                  checked={value.includes(index)}
                />
                <OptionText>{option}</OptionText>
              </LabelContainer>
            </OptionContainer>
          );
        })}
        {value.includes(optionCount - 1) ? (
          <TextArea
            placeholder=""
            handleInputChange={this.handleInputChange}
            _ref={this[section + question]}
            isEmpty={false}
            id={question + "CommentOther"}
            name={question + "CommentOther"}
            value={videoAnalysis[question + "CommentOther"]}
          />
        ) : undefined}
      </>
    );
  };

  getTemperamentAndLearningCharacteristicQuestion = (
    question: string
  ): JSX.Element => {
    const { t } = this.props;
    const section = "temperamentAndLearningCharacteristic";
    const degree = this.getDegree(section, question);
    const comment = this.getComments(section, question);
    return (
      <>
        <SmallSectionTitleContainer backgroundColor={Color(1)}>
          {t(`therapist.reportGeneration.${section}.${question}.title`)}
        </SmallSectionTitleContainer>
        <FormSection topLeftBorderRadius={false} indicator={true} index={1}>
          <QuestionText>
            {t(`therapist.reportGeneration.${section}.${question}.instruction`)}
          </QuestionText>
        </FormSection>
        <div key={section + " " + question}>
          <FormSection topLeftBorderRadius={true} indicator={true} index={1}>
            {degree}
          </FormSection>
          <FormSection topLeftBorderRadius={true} indicator={true} index={1}>
            {comment}
          </FormSection>
        </div>
      </>
    );
  };

  getTemperamentAndLearningCharacteristic = (): JSX.Element => {
    const { t } = this.props;
    const questions = this.getTemperamentAndLearningCharacteristicQuestion;
    return (
      <>
        {/* <Instruction>
            {t(
              "therapist.reportGeneration.temperamentAndLearningCharacteristic.instruction"
            )}
          </Instruction> */}
        <FormSection topLeftBorderRadius={true} indicator={true} index={1}>
          <QuestionText>
            {t(
              "therapist.reportGeneration.temperamentAndLearningCharacteristic.title"
            )}
          </QuestionText>
        </FormSection>
        {TEMPERAMENT_AND_LEARNING_CHARACTERISTIC_LIST.map(
          (question: string) => {
            return questions(question);
          }
        )}
      </>
    );
  };

  getSpeechLanguageAndCommunicationQuestion = (
    question: string
  ): JSX.Element => {
    const { t } = this.props;
    const section = "speechLanguageAndCommunication";
    const degree = this.getDegree(section, question);
    const comment = this.getComments(section, question);
    return (
      <>
        <SmallSectionTitleContainer backgroundColor={Color(2)}>
          {t(`therapist.reportGeneration.${section}.${question}.title`)}
        </SmallSectionTitleContainer>
        <div key={section + " " + question}>
          <FormSection topLeftBorderRadius={false} indicator={true} index={2}>
            {degree}
          </FormSection>
          <FormSection topLeftBorderRadius={true} indicator={true} index={2}>
            {comment}
          </FormSection>
        </div>
      </>
    );
  };

  getSpeechLanguageAndCommunication = (): JSX.Element => {
    const { t } = this.props;
    const questions = this.getSpeechLanguageAndCommunicationQuestion;
    return (
      <>
        <FormSection topLeftBorderRadius={true} indicator={true} index={2}>
          <QuestionText>
            {t(
              "therapist.reportGeneration.speechLanguageAndCommunication.title"
            )}
          </QuestionText>
          <FormSectionDescriptionText>
            {t(
              "therapist.reportGeneration.speechLanguageAndCommunication.instruction"
            )}
          </FormSectionDescriptionText>
        </FormSection>
        {SPEECH_LANGUAGE_AND_COMMUNICATION_LIST.map((question: string) => {
          return questions(question);
        })}
      </>
    );
  };

  getInteractionAndGuidanceComment = (
    section: string,
    question: string
  ): JSX.Element => {
    const { t, videoAnalysis } = this.props;
    const { emotionDescription } = this.props.videoAnalysis;
    const emotionStatus = videoAnalysis[question] as number;
    const value = videoAnalysis[question + "Comment"];
    const options: Array<string> = [];
    const optionCount =
      VIDEO_ANALYSIS_INDEX.interactionAndGuidance[question].comment;
    if (question === "emotion") {
      return emotionStatus > -1 ? (
        <>
          <QuestionTitle>
            {t(
              `therapist.reportGeneration.${section}.${question}.comment.${emotionStatus}`
            )}
          </QuestionTitle>
          <TextArea
            placeholder=""
            handleInputChange={this.handleInputChange}
            _ref={this[section + question]}
            isEmpty={false}
            id="emotionDescription"
            name="emotionDescription"
            value={emotionDescription}
          />
        </>
      ) : (
        <div />
      );
    } else {
      for (let i = 0; i < optionCount; i++) {
        options.push(
          t(`therapist.reportGeneration.${section}.${question}.comment.${i}`)
        );
      }
      return (
        <div key={question}>
          <QuestionTitle>
            {t(
              `therapist.reportGeneration.${section}.${question}.comment.title`
            )}
          </QuestionTitle>
          {options.map((option, index) => {
            return (
              <OptionContainer>
                <LabelContainer key={question + " " + index}>
                  <OptionRadio
                    type="radio"
                    value={index}
                    name={question + "Comment"}
                    onChange={this.handleRadioChange}
                    checked={value === index}
                  />
                  <OptionText>{option}</OptionText>
                </LabelContainer>
              </OptionContainer>
            );
          })}
          {value === optionCount - 1 ? (
            <TextArea
              placeholder=""
              handleInputChange={this.handleInputChange}
              _ref={this[section + question]}
              isEmpty={false}
              id={question + "CommentOther"}
              name={question + "CommentOther"}
              value={videoAnalysis[question + "CommentOther"]}
            />
          ) : undefined}
        </div>
      );
    }
  };

  getImprovement = (
    section: string,
    question: string
  ): JSX.Element | undefined => {
    const { t, videoAnalysis } = this.props;
    const value = videoAnalysis[question + "Improvement"] as Array<number>;
    const options: Array<string> = [];
    const optionCount =
      VIDEO_ANALYSIS_INDEX.interactionAndGuidance[question].improvement;
    const data = {
      section,
      question,
    };
    const degree = videoAnalysis[question];
    const display =
      degree !== -1 &&
      degree !== 0 &&
      degree !==
        VIDEO_ANALYSIS_INDEX.interactionAndGuidance[question].degree - 1;
    for (let i = 0; i < optionCount; i++) {
      options.push(
        t(`therapist.reportGeneration.${section}.${question}.improvement.${i}`)
      );
    }
    return display ? (
      <FormSection topLeftBorderRadius={true} indicator={true} index={3}>
        <QuestionTitle>
          {t(
            `therapist.reportGeneration.${section}.${question}.improvement.title`
          )}
        </QuestionTitle>
        {options.map((option: string, index: number) => {
          return (
            <OptionContainer>
              <LabelContainer key={question + " " + index}>
                <OptionRadio
                  type="checkbox"
                  value={index}
                  name={question + "Improvement"}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    this.handleCheckboxChange(event, data)
                  }
                  checked={value.includes(index)}
                />
                <OptionText>{option}</OptionText>
              </LabelContainer>
            </OptionContainer>
          );
        })}
        {value.includes(optionCount - 1) ? (
          <TextArea
            placeholder=""
            handleInputChange={this.handleInputChange}
            _ref={this.improvementOtherRef}
            isEmpty={false}
            id={question + "ImprovementOther"}
            name={question + "ImprovementOther"}
            value={videoAnalysis[question + "ImprovementOther"]}
          />
        ) : undefined}
      </FormSection>
    ) : undefined;
  };

  getInteractionAndGuidanceQuestion = (question: string): JSX.Element => {
    const { t } = this.props;
    const section = "interactionAndGuidance";
    const degree = this.getDegree(section, question);
    const comment = this.getInteractionAndGuidanceComment(section, question);
    const improvement = this.getImprovement(section, question);
    return (
      <>
        <SmallSectionTitleContainer backgroundColor={Color(3)}>
          {t(`therapist.reportGeneration.${section}.${question}.title`)}
        </SmallSectionTitleContainer>
        <div key={section + " " + question}>
          <FormSection topLeftBorderRadius={false} indicator={true} index={3}>
            <QuestionText>
              {t(
                `therapist.reportGeneration.${section}.${question}.instruction`
              )}
            </QuestionText>
          </FormSection>
          <FormSection topLeftBorderRadius={true} indicator={true} index={3}>
            {degree}
          </FormSection>
          <FormSection topLeftBorderRadius={true} indicator={true} index={3}>
            {comment}
          </FormSection>
          {improvement}
        </div>
      </>
    );
  };

  getInteractionAndGuidance = (): JSX.Element => {
    const { t } = this.props;
    const questions = this.getInteractionAndGuidanceQuestion;
    return (
      <>
        <FormSection topLeftBorderRadius={true} indicator={true} index={3}>
          <QuestionText>
            {t("therapist.reportGeneration.interactionAndGuidance.title")}
          </QuestionText>
          <FormSectionDescriptionText>
            {t("therapist.reportGeneration.interactionAndGuidance.instruction")}
          </FormSectionDescriptionText>
        </FormSection>
        {INTERACTION_AND_GUIDANCE_LIST.map((question: string) => {
          return questions(question);
        })}
      </>
    );
  };

  render = () => {
    const { t } = this.props;
    const overallQuality = this.getOverallQuality();
    const temperamentAndLearningCharacteristic =
      this.getTemperamentAndLearningCharacteristic();
    const speechLanguageAndCommunication =
      this.getSpeechLanguageAndCommunication();
    const interactionAndGuidance = this.getInteractionAndGuidance();
    return (
      <PageContainer>
        <FormSectionTitle>
          <FormTitle>
            {t("therapist.reportGeneration.videoAnalysis.title")}
          </FormTitle>
          <FormSectionDescriptionText>
            {t("therapist.reportGeneration.videoAnalysis.instruction")}
          </FormSectionDescriptionText>
        </FormSectionTitle>
        {overallQuality}
        <div style={{ height: "60px" }} />
        {temperamentAndLearningCharacteristic}
        <div style={{ height: "60px" }} />
        {speechLanguageAndCommunication}
        <div style={{ height: "60px" }} />
        {interactionAndGuidance}
      </PageContainer>
    );
  };
}

const isOverallQualityInvalid = (videoAnalysis: videoAnalysisType): boolean => {
  let invalid: boolean = false;
  for (let i = 0; i < OVERALL_QUALITY_LIST.length; i++) {
    invalid = invalid || videoAnalysis[OVERALL_QUALITY_LIST[i]] === 0;
  }
  return invalid;
};

const isTemperamentAndLearningCharacteristicInvalid = (
  videoAnalysis: videoAnalysisType
): boolean => {
  let invalid: boolean = false;
  for (
    let i = 0;
    i < TEMPERAMENT_AND_LEARNING_CHARACTERISTIC_LIST.length;
    i++
  ) {
    const section = TEMPERAMENT_AND_LEARNING_CHARACTERISTIC_LIST[i];
    const { degree } =
      VIDEO_ANALYSIS_INDEX.temperamentAndLearningCharacteristic[section];
    const degreeValue = videoAnalysis[section];
    const degreeOther = videoAnalysis[section + "Other"];
    const isDegreeOther = degreeValue === degree - 1;
    const commentValue = videoAnalysis[section + "Comment"];
    const commentOther = videoAnalysis[section + "CommentOther"];
    const isCommentOther = commentValue.includes(
      VIDEO_ANALYSIS_INDEX.temperamentAndLearningCharacteristic[section]
        .comment - 1
    );
    invalid =
      invalid || degreeValue === -1 || (isDegreeOther && degreeOther === "");
    invalid =
      invalid ||
      commentValue.length === 0 ||
      (isCommentOther && commentOther === "");
  }
  return invalid;
};

const isSpeechLanguageAndCommunicationInvalid = (
  videoAnalysis: videoAnalysisType
): boolean => {
  let invalid: boolean = false;
  for (let i = 0; i < SPEECH_LANGUAGE_AND_COMMUNICATION_LIST.length; i++) {
    const section = SPEECH_LANGUAGE_AND_COMMUNICATION_LIST[i];
    const { degree } =
      VIDEO_ANALYSIS_INDEX.speechLanguageAndCommunication[section];
    const degreeValue = videoAnalysis[section];
    const degreeOther = videoAnalysis[section + "Other"];
    const isDegreeOther = degreeValue === degree - 1;
    const commentValue = videoAnalysis[section + "Comment"];
    const commentOther = videoAnalysis[section + "CommentOther"];
    const isCommentOther = commentValue.includes(
      VIDEO_ANALYSIS_INDEX.speechLanguageAndCommunication[section].comment - 1
    );
    const degreeInvalid =
      degreeValue === -1 || (isDegreeOther && degreeOther === "");
    const commentInvalid =
      commentValue.length === 0 || (isCommentOther && commentOther === "");
    invalid = invalid || degreeInvalid || commentInvalid;
  }
  return invalid;
};

const isInteractionAndGuidanceInvalid = (
  videoAnalysis: videoAnalysisType
): boolean => {
  let invalid: boolean = false;
  for (let i = 0; i < INTERACTION_AND_GUIDANCE_LIST.length - 1; i++) {
    const section = INTERACTION_AND_GUIDANCE_LIST[i];
    const { degree, comment, improvement } =
      VIDEO_ANALYSIS_INDEX.interactionAndGuidance[section];
    const degreeValue = videoAnalysis[section];
    const degreeOther = videoAnalysis[section + "Other"];
    const isDegreeOther = degreeValue === degree - 1;
    const commentValue = videoAnalysis[section + "Comment"];
    const commentOther = videoAnalysis[section + "CommentOther"];
    const isCommentOther = commentValue === comment - 1;
    const improvementValue = videoAnalysis[
      section + "Improvement"
    ] as Array<number>;

    const isDegreeInvalid =
      degreeValue === -1 || (isDegreeOther && degreeOther === "");
    const isCommentInvalid =
      commentValue === -1 || (isCommentOther && commentOther === "");

    if (degreeValue !== -1 && degreeValue !== 0 && degreeValue !== degree - 1) {
      const improvementOther = videoAnalysis[section + "ImprovementOther"];
      const isImprovementOther = improvementValue.includes(improvement - 1);
      const isImprovementInvalid =
        improvementValue.length === 0 ||
        (isImprovementOther && improvementOther === "");
      invalid = invalid || isImprovementInvalid;
    }
    invalid = invalid || isDegreeInvalid || isCommentInvalid;
  }
  const {
    emotion,
    emotionOther,
    emotionDescription,
    emotionImprovement,
    emotionImprovementOther,
  } = videoAnalysis;
  // when select "other" in emotion D1
  const isEmotionOther =
    emotion === VIDEO_ANALYSIS_INDEX.interactionAndGuidance.emotion.degree - 1;

  const isEmotionImprovementOther = emotionImprovement.includes(
    VIDEO_ANALYSIS_INDEX.interactionAndGuidance.emotion.improvement - 1
  );
  const isDegreeInvalid =
    emotion === -1 || (isEmotionOther && emotionOther === "");
  invalid = invalid || isDegreeInvalid;

  // this is emotion D2 question textarea
  if (emotion > -1) {
    const isDescriptionInvalid = emotionDescription === "";
    invalid = invalid || isDescriptionInvalid;
  }

  // this is emotion D3, the additional section when select emotion D1 degree === 1 || degree === 2
  if (
    emotion !== -1 &&
    emotion !== 0 &&
    emotion !== VIDEO_ANALYSIS_INDEX.interactionAndGuidance.emotion.degree - 1
  ) {
    const isImprovementInvalid =
      emotionImprovement.length === 0 ||
      (isEmotionImprovementOther && emotionImprovementOther === "");
    invalid = invalid || isImprovementInvalid;
  }
  return invalid;
};

export const isVideoAnalysisNextDisabled = (
  videoAnalysis: videoAnalysisType
): boolean => {
  const overallQualityInvalid = isOverallQualityInvalid(videoAnalysis);
  const temperamentAndLearningCharacteristicInvalid =
    isTemperamentAndLearningCharacteristicInvalid(videoAnalysis);
  const speechLanguageAndCommunicationInvalid =
    isSpeechLanguageAndCommunicationInvalid(videoAnalysis);
  const interactionAndGuidanceInvalid =
    isInteractionAndGuidanceInvalid(videoAnalysis);
  return (
    overallQualityInvalid ||
    temperamentAndLearningCharacteristicInvalid ||
    speechLanguageAndCommunicationInvalid ||
    interactionAndGuidanceInvalid
  );
};

export default withRouter(withTranslation()(VideoAnalysisForm));
