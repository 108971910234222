import styled from "styled-components";

export const CloseButton = styled.div`
  color: white;
  background: #6898e3;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 40px;
  min-width: 120px;
  width: 44%;
  height: 40px;
  border-radius: 4px;
  padding: 10px;
  box-shadow: -6px -6px 14px rgba(255, 255, 255, 0.7),
    -6px -6px 10px rgba(255, 255, 255, 0.5),
    6px 6px 8px rgba(255, 255, 255, 0.075), 6px 6px 10px rgba(0, 0, 0, 0.15);
  :hover {
    box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.6),
      -2px -2px 4px rgba(255, 255, 255, 0.4),
      2px 2px 2px rgba(255, 255, 255, 0.05), 2px 2px 4px rgba(0, 0, 0, 0.1);
  }
  :active {
    box-shadow: inset -2px -2px 6px rgba(29, 178, 221, 0.7),
      inset -2px -2px 4px rgba(29, 178, 221, 0.5),
      inset 2px 2px 2px rgba(29, 178, 221, 0.075),
      inset 2px 2px 4px rgba(0, 0, 0, 0.15);
  }
`;

export const NotifyImg = styled.img`
  height: 200px;
  border-radius: 8px;
  width: 100%;
  object-fit: fill;
  margin-top: 30px;
`;
