import Evaluation from "components/User/Evaluation";
import Questionnaire from "components/User/Evaluation/Core";
import NavMenu from "components/general/NavMenu";
import OrganizationEnd from "components/Organization/End";

import { Route, Switch } from "react-router";
import PublicRoute from "./PublicRoute";

const TmpUserRoute = () => {
  return (
    <>
      <NavMenu />
      <Switch>
        <Route exact path="/evaluation" component={Evaluation} />
        <Route path="/questionnaire/" component={Questionnaire} />
        <Route
          exact
          path="/OrganizationEnd/:organization"
          component={OrganizationEnd}
        />
        <PublicRoute />
      </Switch>
    </>
  );
};

export default TmpUserRoute;
