import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  PageContainer,
  LabelContainer,
  OptionText,
  OptionRadio,
  OptionContainer,
  SkillContainer,
  AddSkillButton,
  Skill,
  SmallSectionTitleContainer,
  FormTitle,
} from "../styles";
import {
  GENERAL_ANALYSIS_INDEX,
  GENERAL_ANALYSIS_LIST,
} from "../../../../constants/report";
import {
  generalAnalysisType,
  scoreType,
} from "../../../User/Evaluation/CorePro/data";
import SkillModal from "../../../common/SkillModal";
import FormSectionTitle from "../component/FormSectionTitle";
import { QuestionText } from "components/Organization/styles";
import QuestionTitle from "../component/QuestionTitle";
import TextArea from "../component/TextArea";
import FormSection from "../component/FormSection";
import { Color } from "../ColorUtils";
interface GeneralAnalysisFormProps {
  generalAnalysis: generalAnalysisType;
  setState: (states: object) => void;
}

interface GeneralAnalysisState {
  showStrengthSkillModal: boolean;
  showWeaknessSkillModal: boolean;
}

type State = GeneralAnalysisState;

type Props = GeneralAnalysisFormProps & WithTranslation & RouteComponentProps;

class GeneralAnalysisForm extends Component<Props, State> {
  private videooral;
  private overalloral;
  private abilityoral;
  private videospeechClarity;
  private overallspeechClarity;
  private abilityspeechClarity;
  private videocomprehension;
  private overallcomprehension;
  private abilitycomprehension;
  private videoexpression;
  private overallexpression;
  private abilityexpression;
  private videosocialInteraction;
  private overallsocialInteraction;
  private abilitysocialInteraction;
  private videoearlyReadingSkills;
  private overallearlyReadingSkills;
  private abilityearlyReadingSkills;
  constructor(props: Props) {
    super(props);
    this.videooral = React.createRef();
    this.overalloral = React.createRef();
    this.abilityoral = React.createRef();
    this.videospeechClarity = React.createRef();
    this.overallspeechClarity = React.createRef();
    this.abilityspeechClarity = React.createRef();
    this.videocomprehension = React.createRef();
    this.overallcomprehension = React.createRef();
    this.abilitycomprehension = React.createRef();
    this.videoexpression = React.createRef();
    this.overallexpression = React.createRef();
    this.abilityexpression = React.createRef();
    this.videosocialInteraction = React.createRef();
    this.overallsocialInteraction = React.createRef();
    this.abilitysocialInteraction = React.createRef();
    this.videoearlyReadingSkills = React.createRef();
    this.overallearlyReadingSkills = React.createRef();
    this.abilityearlyReadingSkills = React.createRef();
    this.state = {
      showStrengthSkillModal: false,
      showWeaknessSkillModal: false,
    };
  }

  componentDidMount = () => {
    window.scrollTo({ top: 0 });
  };

  handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;
    const { setState } = this.props;
    setState({ [name]: value });
  };

  handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    const { setState } = this.props;
    setState({ [name]: parseInt(value) });
  };

  handleOpenStrengthSkillModal = () => {
    this.setState({ showStrengthSkillModal: true });
  };

  handleCloseStrengthSkillModal = () => {
    this.setState({ showStrengthSkillModal: false });
  };

  handleOpenWeaknessSkillModal = () => {
    this.setState({ showWeaknessSkillModal: true });
  };

  handleCloseWeaknessSkillModal = () => {
    this.setState({ showWeaknessSkillModal: false });
  };

  handelAddStrengthSkills = (skills: Array<number>) => {
    const { childStrength } = this.props.generalAnalysis;
    const newChildStrength = childStrength.concat(skills);
    this.setState({ showStrengthSkillModal: false });
    this.props.setState({
      childStrength: newChildStrength,
    });
  };

  handelRemoveStrengthSkills = (skill: number) => {
    const { setState, generalAnalysis } = this.props;
    let { childStrength } = generalAnalysis;
    childStrength = childStrength.filter((item: number) => item !== skill);
    setState({
      childStrength,
    });
  };

  handelAddWeaknessSkills = (skills: Array<number>) => {
    const { childWeakness } = this.props.generalAnalysis;
    const newChildWeakness = childWeakness.concat(skills);
    this.setState({ showWeaknessSkillModal: false });
    this.props.setState({
      childWeakness: newChildWeakness,
    });
  };

  handelRemoveWeaknessSkills = (skill: number) => {
    const { setState, generalAnalysis } = this.props;
    let { childWeakness } = generalAnalysis;
    childWeakness = childWeakness.filter((item: number) => item !== skill);
    setState({
      childWeakness,
    });
  };

  getVideo = (section: string, question: string): JSX.Element => {
    const { t, generalAnalysis } = this.props;
    const value = generalAnalysis[`${question}Video`];
    const options: Array<string> = [];
    const optionCount = GENERAL_ANALYSIS_INDEX[question]["video"];
    for (let i = 0; i < optionCount; i++) {
      options.push(
        t(
          `therapist.reportGeneration.generalAnalysis.${question}.${section}.${i}`
        )
      );
    }
    return (
      <>
        <QuestionTitle>
          {t(
            `therapist.reportGeneration.generalAnalysis.${question}.${section}.title`
          )}
        </QuestionTitle>
        {options.map((option, index) => {
          return (
            <OptionContainer>
              <LabelContainer key={question + " " + index}>
                <OptionRadio
                  type="radio"
                  value={index}
                  name={`${question}Video`}
                  onChange={this.handleRadioChange}
                  checked={value === index}
                />
                <OptionText>{option}</OptionText>
              </LabelContainer>
            </OptionContainer>
          );
        })}
        {value === optionCount - 1 ? (
          <TextArea
            id={`${question}VideoOther`}
            name={`${question}VideoOther`}
            placeholder=""
            value={generalAnalysis[`${question}VideoOther`]}
            handleInputChange={this.handleInputChange}
            _ref={this[section + question]}
            isEmpty={false}
          />
        ) : undefined}
      </>
    );
  };

  getOverall = (section: string, question: string): JSX.Element => {
    const { t, generalAnalysis } = this.props;
    const value = generalAnalysis[`${question}Overall`];
    const options: Array<string> = [];
    const optionCount = GENERAL_ANALYSIS_INDEX[question][section];
    for (let i = 0; i < optionCount; i++) {
      options.push(
        t(
          `therapist.reportGeneration.generalAnalysis.${question}.${section}.${i}`
        )
      );
    }
    return (
      <>
        <QuestionTitle>
          {t(
            `therapist.reportGeneration.generalAnalysis.${question}.${section}.title`
          )}
        </QuestionTitle>
        {options.map((option, index) => {
          return (
            <OptionContainer>
              <LabelContainer key={question + " " + index}>
                <OptionRadio
                  type="radio"
                  value={index}
                  name={`${question}Overall`}
                  onChange={this.handleRadioChange}
                  checked={value === index}
                />
                <OptionText>{option}</OptionText>
              </LabelContainer>
            </OptionContainer>
          );
        })}
        {value === optionCount - 1 ? (
          <TextArea
            id={`${question}OverallOther`}
            name={`${question}OverallOther`}
            placeholder=""
            value={generalAnalysis[`${question}OverallOther`]}
            handleInputChange={this.handleInputChange}
            _ref={this[section + question]}
            isEmpty={false}
          />
        ) : undefined}
      </>
    );
  };

  getAbility = (section: string, question: string): JSX.Element => {
    const { t, generalAnalysis } = this.props;
    const value = generalAnalysis[`${question}Ability`];
    const options: Array<string> = [];
    const optionCount = GENERAL_ANALYSIS_INDEX[question][section];
    for (let i = 0; i < optionCount; i++) {
      options.push(
        t(
          `therapist.reportGeneration.generalAnalysis.${question}.${section}.${i}`
        )
      );
    }
    return (
      <>
        <QuestionTitle>
          {t(
            `therapist.reportGeneration.generalAnalysis.${question}.${section}.title`
          )}
        </QuestionTitle>
        {options.map((option, index) => {
          return (
            <OptionContainer>
              <LabelContainer key={question + " " + index}>
                <OptionRadio
                  type="radio"
                  value={index}
                  name={`${question}Ability`}
                  onChange={this.handleRadioChange}
                  checked={value === index}
                />
                <OptionText>{option}</OptionText>
              </LabelContainer>
            </OptionContainer>
          );
        })}
        {value === optionCount - 1 ? (
          <TextArea
            id={`${question}AbilityOther`}
            name={`${question}AbilityOther`}
            placeholder=""
            value={generalAnalysis[`${question}AbilityOther`]}
            handleInputChange={this.handleInputChange}
            _ref={this[section + question]}
            isEmpty={false}
          />
        ) : undefined}
      </>
    );
  };

  getQuestion = (question: string, index: number): JSX.Element => {
    const { t, generalAnalysis } = this.props;
    const videoSection = "video";
    const overallSection = "overall";
    const abilitySection = "ability";
    const video = this.getVideo(videoSection, question);
    const overall = this.getOverall(overallSection, question);
    const ability = this.getAbility(abilitySection, question);
    const scores = generalAnalysis[question + "Score"] as scoreType;
    return (
      <>
        <SmallSectionTitleContainer backgroundColor={Color(index)}>
          {t(`therapist.reportGeneration.generalAnalysis.${question}.title`)}
        </SmallSectionTitleContainer>
        <div key={question}>
          <FormSection
            topLeftBorderRadius={false}
            indicator={true}
            index={index}
          >
            <QuestionText>
              {t(
                `therapist.reportGeneration.generalAnalysis.${question}.score`
              )}
              {question !== "speechClarity" && (
                <span>{`: ${scores.score} / ${scores.questionCount}`}</span>
              )}
            </QuestionText>
          </FormSection>
          <FormSection
            topLeftBorderRadius={true}
            indicator={true}
            index={index}
          >
            {video}
          </FormSection>
          <FormSection
            topLeftBorderRadius={true}
            indicator={true}
            index={index}
          >
            {overall}
          </FormSection>
          <FormSection
            topLeftBorderRadius={true}
            indicator={true}
            index={index}
          >
            {ability}
          </FormSection>
        </div>
      </>
    );
  };

  getGeneralAnalysis = (): JSX.Element => {
    return (
      <>
        {GENERAL_ANALYSIS_LIST.map((question: string, index: number) =>
          this.getQuestion(question, index)
        )}
      </>
    );
  };

  getStrengthAndWeakness = (): JSX.Element => {
    const { t } = this.props;
    return (
      <>
        {/* <Instruction>
          {t("therapist.reportGeneration.strengthAndWeakness.instruction")}
        </Instruction> */}
        <SmallSectionTitleContainer backgroundColor={"#6898e3"}>
          {t("therapist.reportGeneration.strengthAndWeakness.title")}
        </SmallSectionTitleContainer>
        {this.getStrength()}
        {this.getWeakness()}
      </>
    );
  };

  getStrength = (): JSX.Element => {
    const { t, generalAnalysis } = this.props;
    const { showStrengthSkillModal } = this.state;
    const { childStrength } = generalAnalysis;

    return (
      <>
        <SkillModal
          show={showStrengthSkillModal}
          selectedSkills={childStrength}
          handleAddSkill={this.handelAddStrengthSkills}
          handleClose={this.handleCloseStrengthSkillModal}
        />
        <FormSection topLeftBorderRadius={false} indicator={false} index={0}>
          <QuestionTitle>
            {t("therapist.reportGeneration.strengthAndWeakness.strengthTitle")}
          </QuestionTitle>
          <AddSkillButton onClick={this.handleOpenStrengthSkillModal}>
            {t("therapist.reportGeneration.strengthAndWeakness.addStrength")}
          </AddSkillButton>
          {childStrength.length > 0 && (
            <SkillContainer>
              {childStrength.map((strength: number) => (
                <Skill
                  onClick={() => this.handelRemoveStrengthSkills(strength)}
                >
                  {t("skill." + strength) + " ✕"}
                </Skill>
              ))}
            </SkillContainer>
          )}
        </FormSection>
      </>
    );
  };

  getWeakness = (): JSX.Element => {
    const { t, generalAnalysis } = this.props;
    const { showWeaknessSkillModal } = this.state;
    const { childWeakness } = generalAnalysis;

    return (
      <>
        <SkillModal
          show={showWeaknessSkillModal}
          selectedSkills={childWeakness}
          handleAddSkill={this.handelAddWeaknessSkills}
          handleClose={this.handleCloseWeaknessSkillModal}
        />
        <FormSection topLeftBorderRadius={true} indicator={false} index={0}>
          <QuestionTitle>
            {t("therapist.reportGeneration.strengthAndWeakness.weaknessTitle")}
          </QuestionTitle>
          <AddSkillButton onClick={this.handleOpenWeaknessSkillModal}>
            {t("therapist.reportGeneration.strengthAndWeakness.addWeakness")}
          </AddSkillButton>
          {childWeakness.length > 0 && (
            <SkillContainer>
              {childWeakness.map((weakness: number) => (
                <Skill
                  onClick={() => this.handelRemoveWeaknessSkills(weakness)}
                >
                  {t("skill." + weakness) + " ✕"}
                </Skill>
              ))}
            </SkillContainer>
          )}
        </FormSection>
      </>
    );
  };

  render = () => {
    const { t } = this.props;
    return (
      <PageContainer>
        <FormSectionTitle>
          <FormTitle>
            {t("therapist.reportGeneration.generalAnalysis.title")}
          </FormTitle>
        </FormSectionTitle>
        {/* <Instruction>
            {t("therapist.reportGeneration.generalAnalysis.instruction")}
          </Instruction> */}
        {this.getGeneralAnalysis()}
        {this.getStrengthAndWeakness()}
      </PageContainer>
    );
  };
}

const isGeneralAnalysisInvalid = (
  generalAnalysis: generalAnalysisType
): boolean => {
  let invalid = false;
  for (let i = 0; i < GENERAL_ANALYSIS_LIST.length; i++) {
    const component = GENERAL_ANALYSIS_LIST[i];
    const { video, overall, ability } = GENERAL_ANALYSIS_INDEX[component];
    const videoStatus = generalAnalysis[component + "Video"];
    const videoOther = generalAnalysis[component + "VideoOther"];
    const overallStatus = generalAnalysis[component + "Overall"];
    const overallOther = generalAnalysis[component + "OverallOther"];
    const abilityStatus = generalAnalysis[component + "Ability"];
    const abilityOther = generalAnalysis[component + "AbilityOther"];
    const invalidVideo =
      videoStatus === -1 || (videoStatus === video - 1 && videoOther === "");
    const invalidOverall =
      overallStatus === -1 ||
      (overallStatus === overall - 1 && overallOther === "");
    const invalidAbility =
      abilityStatus === -1 ||
      (abilityStatus === ability - 1 && abilityOther === "");
    invalid = invalid || invalidVideo || invalidOverall || invalidAbility;
  }
  return invalid;
};

const isStrengthAndWeaknessInvalid = (
  generalAnalysis: generalAnalysisType
): boolean => {
  const { childStrength, childWeakness } = generalAnalysis;
  const isStrengthInvalid = childStrength.length === 0;
  const isWeaknessInvalid = childWeakness.length === 0;
  return isStrengthInvalid || isWeaknessInvalid;
};

export const isGeneralAnalysisNextDisabled = (
  generalAnalysis: generalAnalysisType
): boolean => {
  const generalAnalysisInvalid = isGeneralAnalysisInvalid(generalAnalysis);
  const strengthAndWeaknessInvalid =
    isStrengthAndWeaknessInvalid(generalAnalysis);
  return generalAnalysisInvalid || strengthAndWeaknessInvalid;
};

export default withRouter(withTranslation()(GeneralAnalysisForm));
