import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  MODULARIZED_PRODUCTS,
  SELF_PRODUCTS,
  EXPERT_PRODUCTS,
  PRODUCTS,
  EXPERT_PRODUCTS_WITHOUT_REPORT,
  PRODUCTS_WITH_CHILD_INFO_ONLY,
} from "../../../../../constants/common";
import { ResultListSegment } from "./styles";
import {
  FlexBetweenContainer,
  GreyInfoText,
  InfoContainer,
  InfoRow,
  LabelText,
  ProductName,
  RowContainer,
  ValueText,
} from "../../../ProfileRoute/PurchasesHistory/styles";

type steps = {
  child_info: {
    values: {
      childName: string;
    };
  };
};

type Props = {
  id: string;
  transactionid: string;
  completed: boolean;
  product: string;
  createdat: string;
  currentstep: string;
  steps: steps;
};

export default function Item(props: Props) {
  const { t } = useTranslation();
  const history = useHistory();

  const handleViewResult = (
    product: string,
    resultId: string,
    transactionId: string,
    completed: boolean
  ) => {
    if (PRODUCTS_WITH_CHILD_INFO_ONLY.includes(product)) {
      return;
    }
    if (EXPERT_PRODUCTS.includes(product) && completed) {
      window.open("/core-pro-report/" + resultId);
    } else {
      history.push("/core-report/" + transactionId);
    }
  };

  const getCurrentSteps = (product: string, currentStep: string) => {
    if (currentStep === "report" || currentStep === "reviewing") {
      if (completed && EXPERT_PRODUCTS.includes(product)) {
        return t("user.survey.history.coreProReport");
      } else {
        return t("user.survey.history.coreReport");
      }
    } else {
      return t(`user.survey.history.${currentStep}`);
    }
  };

  const {
    id,
    transactionid,
    product,
    createdat,
    currentstep,
    steps,
    completed,
  } = props;
  let productName = product;
  if (MODULARIZED_PRODUCTS.includes(product)) {
    productName = PRODUCTS.ModularizedEval;
  } else if (SELF_PRODUCTS.includes(product)) {
    productName = PRODUCTS.Core;
  }
  const date = createdat.substring(0, 10);
  const name =
    steps.child_info.values.childName == null
      ? t("user.survey.history.noChildName")
      : steps.child_info.values.childName;
  return (
    <ResultListSegment
      onClick={() => handleViewResult(product, id, transactionid, completed)}
    >
      <FlexBetweenContainer>
        <ProductName>{productName}</ProductName>
        <GreyInfoText>{date}</GreyInfoText>
      </FlexBetweenContainer>
      <FlexBetweenContainer>
        <InfoContainer>
          <RowContainer>
            <InfoRow>
              <LabelText>{t("user.survey.history.childName")}</LabelText>
              <ValueText>{name}</ValueText>
            </InfoRow>
            {!PRODUCTS_WITH_CHILD_INFO_ONLY.includes(product) && (
              <InfoRow>
                <LabelText>{t("user.survey.history.currentSteps")}</LabelText>
                <ValueText>{getCurrentSteps(product, currentstep)}</ValueText>
              </InfoRow>
            )}
          </RowContainer>
        </InfoContainer>
      </FlexBetweenContainer>
    </ResultListSegment>
  );
}
