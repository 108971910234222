import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  A4Container,
  SmallSectionTitle,
  Table,
  TR,
  TD,
  GeneralTD,
  SectionTitle,
  TH,
  HeadTR,
  FirstTD,
  PageNumber,
  WaterMark,
  ListText,
} from "../styles";
import { generalAnalysisType } from "../../data";
import { GENERAL_ANALYSIS_INDEX } from "../../../../../../constants/report";
import { getWatermark } from "../ImageLoader";
interface GeneralAnalysisProps {
  language: string;
  generalAnalysis: generalAnalysisType;
}

interface GeneralAnalysisState {}

type Props = GeneralAnalysisProps & WithTranslation;

class GeneralAnalysis extends Component<Props, GeneralAnalysisState> {
  oralSkills = (): JSX.Element => {
    const { t, generalAnalysis } = this.props;
    const {
      oralScore,
      oralVideo,
      oralVideoOther,
      oralOverall,
      oralOverallOther,
      oralAbility,
      oralAbilityOther,
    } = generalAnalysis;
    const { score, questionCount } = oralScore;
    const { video, overall, ability } = GENERAL_ANALYSIS_INDEX.oral;
    return (
      <TR>
        <FirstTD>
          {t("user.survey.corePro.result.generalAnalysis.oral.title")}
        </FirstTD>
        <GeneralTD>{`${score} / ${questionCount}`}</GeneralTD>
        {oralVideo !== video - 1 ? (
          <GeneralTD>
            {t(
              `user.survey.corePro.result.generalAnalysis.oral.video.${oralVideo}`
            )}
          </GeneralTD>
        ) : (
          <GeneralTD>{oralVideoOther}</GeneralTD>
        )}
        {oralOverall !== overall - 1 ? (
          <GeneralTD>
            {t(
              `user.survey.corePro.result.generalAnalysis.oral.overall.${oralOverall}`
            )}
          </GeneralTD>
        ) : (
          <GeneralTD>{oralOverallOther}</GeneralTD>
        )}
        {oralAbility !== ability - 1 ? (
          <GeneralTD>
            {t(
              `user.survey.corePro.result.generalAnalysis.oral.ability.${oralAbility}`
            )}
          </GeneralTD>
        ) : (
          <GeneralTD>{oralAbilityOther}</GeneralTD>
        )}
      </TR>
    );
  };

  speechClarity(): JSX.Element {
    const { t, generalAnalysis } = this.props;
    const {
      speechClarityVideo,
      speechClarityVideoOther,
      speechClarityOverall,
      speechClarityOverallOther,
      speechClarityAbility,
      speechClarityAbilityOther,
    } = generalAnalysis;
    const { video, overall, ability } = GENERAL_ANALYSIS_INDEX.speechClarity;
    return (
      <TR>
        <FirstTD>
          {t("user.survey.corePro.result.generalAnalysis.speechClarity.title")}
        </FirstTD>
        <GeneralTD>
          {t("user.survey.corePro.result.generalAnalysis.speechClarity.NA")}
        </GeneralTD>
        {speechClarityVideo !== video - 1 ? (
          <GeneralTD>
            {t(
              `user.survey.corePro.result.generalAnalysis.speechClarity.video.${speechClarityVideo}`
            )}
          </GeneralTD>
        ) : (
          <GeneralTD>{speechClarityVideoOther}</GeneralTD>
        )}
        {speechClarityOverall !== overall - 1 ? (
          <GeneralTD>
            {t(
              `user.survey.corePro.result.generalAnalysis.speechClarity.overall.${speechClarityOverall}`
            )}
          </GeneralTD>
        ) : (
          <GeneralTD>{speechClarityOverallOther}</GeneralTD>
        )}
        {speechClarityAbility !== ability - 1 ? (
          <GeneralTD>
            {t(
              `user.survey.corePro.result.generalAnalysis.speechClarity.ability.${speechClarityAbility}`
            )}
          </GeneralTD>
        ) : (
          <GeneralTD>{speechClarityAbilityOther}</GeneralTD>
        )}
      </TR>
    );
  }

  comprehension(): JSX.Element {
    const { t, generalAnalysis } = this.props;
    const {
      comprehensionScore,
      comprehensionVideo,
      comprehensionVideoOther,
      comprehensionOverall,
      comprehensionOverallOther,
      comprehensionAbility,
      comprehensionAbilityOther,
    } = generalAnalysis;
    const { score, questionCount } = comprehensionScore;
    const { video, overall, ability } = GENERAL_ANALYSIS_INDEX.comprehension;
    return (
      <TR>
        <FirstTD>
          {t("user.survey.corePro.result.generalAnalysis.comprehension.title")}
        </FirstTD>
        <GeneralTD>{`${score} / ${questionCount}`}</GeneralTD>
        {comprehensionVideo !== video - 1 ? (
          <GeneralTD>
            {t(
              `user.survey.corePro.result.generalAnalysis.comprehension.video.${comprehensionVideo}`
            )}
          </GeneralTD>
        ) : (
          <GeneralTD>{comprehensionVideoOther}</GeneralTD>
        )}
        {comprehensionOverall !== overall - 1 ? (
          <GeneralTD>
            {t(
              `user.survey.corePro.result.generalAnalysis.comprehension.overall.${comprehensionOverall}`
            )}
          </GeneralTD>
        ) : (
          <GeneralTD>{comprehensionOverallOther}</GeneralTD>
        )}
        {comprehensionAbility !== ability - 1 ? (
          <GeneralTD>
            {t(
              `user.survey.corePro.result.generalAnalysis.comprehension.ability.${comprehensionAbility}`
            )}
          </GeneralTD>
        ) : (
          <GeneralTD>{comprehensionAbilityOther}</GeneralTD>
        )}
      </TR>
    );
  }

  expression(): JSX.Element {
    const { t, generalAnalysis } = this.props;
    const {
      expressionScore,
      expressionVideo,
      expressionVideoOther,
      expressionOverall,
      expressionOverallOther,
      expressionAbility,
      expressionAbilityOther,
    } = generalAnalysis;
    const { score, questionCount } = expressionScore;
    const { video, overall, ability } = GENERAL_ANALYSIS_INDEX.expression;
    return (
      <TR>
        <FirstTD>
          {t("user.survey.corePro.result.generalAnalysis.expression.title")}
        </FirstTD>
        <GeneralTD>{`${score} / ${questionCount}`}</GeneralTD>
        {expressionVideo !== video - 1 ? (
          <GeneralTD>
            {t(
              `user.survey.corePro.result.generalAnalysis.expression.video.${expressionVideo}`
            )}
          </GeneralTD>
        ) : (
          <GeneralTD>{expressionVideoOther}</GeneralTD>
        )}
        {expressionOverall !== overall - 1 ? (
          <GeneralTD>
            {t(
              `user.survey.corePro.result.generalAnalysis.expression.overall.${expressionOverall}`
            )}
          </GeneralTD>
        ) : (
          <GeneralTD>{expressionOverallOther}</GeneralTD>
        )}
        {expressionAbility !== ability - 1 ? (
          <GeneralTD>
            {t(
              `user.survey.corePro.result.generalAnalysis.expression.ability.${expressionAbility}`
            )}
          </GeneralTD>
        ) : (
          <GeneralTD>{expressionAbilityOther}</GeneralTD>
        )}
      </TR>
    );
  }

  socialInteraction(): JSX.Element {
    const { t, generalAnalysis } = this.props;
    const {
      socialInteractionScore,
      socialInteractionVideo,
      socialInteractionVideoOther,
      socialInteractionOverall,
      socialInteractionOverallOther,
      socialInteractionAbility,
      socialInteractionAbilityOther,
    } = generalAnalysis;
    const { score, questionCount } = socialInteractionScore;
    const { video, overall, ability } =
      GENERAL_ANALYSIS_INDEX.socialInteraction;
    return (
      <TR>
        <FirstTD>
          {t(
            "user.survey.corePro.result.generalAnalysis.socialInteraction.title"
          )}
        </FirstTD>
        <GeneralTD>{`${score} / ${questionCount}`}</GeneralTD>
        {socialInteractionVideo !== video - 1 ? (
          <GeneralTD>
            {t(
              `user.survey.corePro.result.generalAnalysis.socialInteraction.video.${socialInteractionVideo}`
            )}
          </GeneralTD>
        ) : (
          <GeneralTD>{socialInteractionVideoOther}</GeneralTD>
        )}
        {socialInteractionOverall !== overall - 1 ? (
          <GeneralTD>
            {t(
              `user.survey.corePro.result.generalAnalysis.socialInteraction.overall.${socialInteractionOverall}`
            )}
          </GeneralTD>
        ) : (
          <GeneralTD>{socialInteractionOverallOther}</GeneralTD>
        )}
        {socialInteractionAbility !== ability - 1 ? (
          <GeneralTD>
            {t(
              `user.survey.corePro.result.generalAnalysis.socialInteraction.ability.${socialInteractionAbility}`
            )}
          </GeneralTD>
        ) : (
          <GeneralTD>{socialInteractionAbilityOther}</GeneralTD>
        )}
      </TR>
    );
  }

  earlyReadingSkills(): JSX.Element {
    const { t, generalAnalysis } = this.props;
    const {
      earlyReadingSkillsScore,
      earlyReadingSkillsVideo,
      earlyReadingSkillsVideoOther,
      earlyReadingSkillsOverall,
      earlyReadingSkillsOverallOther,
      earlyReadingSkillsAbility,
      earlyReadingSkillsAbilityOther,
    } = generalAnalysis;
    const { score, questionCount } = earlyReadingSkillsScore;
    const { video, overall, ability } =
      GENERAL_ANALYSIS_INDEX.earlyReadingSkills;
    return (
      <TR>
        <FirstTD>
          {t(
            "user.survey.corePro.result.generalAnalysis.earlyReadingSkills.title"
          )}
        </FirstTD>
        <GeneralTD>{`${score} / ${questionCount}`}</GeneralTD>
        {earlyReadingSkillsVideo !== video - 1 ? (
          <GeneralTD>
            {t(
              `user.survey.corePro.result.generalAnalysis.earlyReadingSkills.video.${earlyReadingSkillsVideo}`
            )}
          </GeneralTD>
        ) : (
          <GeneralTD>{earlyReadingSkillsVideoOther}</GeneralTD>
        )}
        {earlyReadingSkillsOverall !== overall - 1 ? (
          <GeneralTD>
            {t(
              `user.survey.corePro.result.generalAnalysis.earlyReadingSkills.overall.${earlyReadingSkillsOverall}`
            )}
          </GeneralTD>
        ) : (
          <GeneralTD>{earlyReadingSkillsOverallOther}</GeneralTD>
        )}
        {earlyReadingSkillsAbility !== ability - 1 ? (
          <GeneralTD>
            {t(
              `user.survey.corePro.result.generalAnalysis.earlyReadingSkills.ability.${earlyReadingSkillsAbility}`
            )}
          </GeneralTD>
        ) : (
          <GeneralTD>{earlyReadingSkillsAbilityOther}</GeneralTD>
        )}
      </TR>
    );
  }

  Table = (): JSX.Element => {
    const { t } = this.props;
    return (
      <Table>
        <thead>
          <HeadTR>
            <TH>
              {t("user.survey.corePro.result.generalAnalysis.table.category")}
            </TH>
            <TH>
              {t("user.survey.corePro.result.generalAnalysis.table.score")}
            </TH>
            <TH>
              {t(
                "user.survey.corePro.result.generalAnalysis.table.videoAnalysis"
              )}
            </TH>
            <TH>
              {t("user.survey.corePro.result.generalAnalysis.table.overall")}
            </TH>
            <TH>
              {t("user.survey.corePro.result.generalAnalysis.table.ability")}
            </TH>
          </HeadTR>
        </thead>
        <tbody>
          {this.oralSkills()}
          {this.speechClarity()}
          {this.comprehension()}
          {this.expression()}
          {this.socialInteraction()}
          {this.earlyReadingSkills()}
        </tbody>
      </Table>
    );
  };

  getStrength = (): JSX.Element[] => {
    const { t, generalAnalysis } = this.props;
    const { childStrength } = generalAnalysis;
    return childStrength.map((strength: number) => {
      return <ListText>{t(`skill.${strength}`)}</ListText>;
    });
  };

  getWeakness = (): JSX.Element[] => {
    const { t, generalAnalysis } = this.props;
    const { childWeakness } = generalAnalysis;
    return childWeakness.map((weakness: number) => {
      return <ListText>{t(`skill.${weakness}`)}</ListText>;
    });
  };

  strengthsWeaknessesTable = (): JSX.Element => {
    const { t } = this.props;

    return (
      <Table>
        <thead>
          <HeadTR>
            <TH>
              {t(
                "user.survey.corePro.result.generalAnalysis.strengthAndWeakness.strength.title"
              )}
            </TH>
            <TH>
              {t(
                "user.survey.corePro.result.generalAnalysis.strengthAndWeakness.weakness.title"
              )}
            </TH>
          </HeadTR>
        </thead>
        <TR>
          <TD>{this.getStrength()}</TD>
          <TD>{this.getWeakness()}</TD>
        </TR>
      </Table>
    );
  };

  render = () => {
    const { t } = this.props;
    return (
      <A4Container>
        <SectionTitle>
          {t("user.survey.corePro.result.generalAnalysis.title")}
        </SectionTitle>
        {this.Table()}
        <div style={{ marginTop: "30px" }} />
        <SmallSectionTitle>
          {t(
            "user.survey.corePro.result.generalAnalysis.strengthAndWeakness.title"
          )}
        </SmallSectionTitle>
        {this.strengthsWeaknessesTable()}
        <PageNumber>5</PageNumber>
        <WaterMark src={getWatermark(this.props.language)} />
      </A4Container>
    );
  };
}
export default withTranslation()(GeneralAnalysis);
