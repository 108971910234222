import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  A4Container,
  A4ContentContainer,
  SectionTitle,
  SmallSectionTitle,
  WaterMark,
  ContentText,
  PageNumber,
  SectionContainer,
} from "../styles";
import { getWatermark } from "../ImageLoader";

interface AppendixProps {
  language: string;
}
interface AppendixState {}

type Props = AppendixProps & WithTranslation;
class Appendix2 extends Component<Props, AppendixState> {
  render = () => {
    const { t } = this.props;
    return (
      <>
        <A4Container>
          <SectionTitle>
            {t("user.survey.corePro.result.appendix2.title")}
          </SectionTitle>
          <SmallSectionTitle>
            {t("utils.fiveCategories.expression.title")}
          </SmallSectionTitle>
          <SectionContainer>
            <ContentText>
              {t("utils.fiveCategories.expression.content")}
            </ContentText>
          </SectionContainer>
          <SmallSectionTitle>
            {t("utils.fiveCategories.comprehension.title")}
          </SmallSectionTitle>
          <SectionContainer>
            <ContentText>
              {t("utils.fiveCategories.comprehension.content")}
            </ContentText>
          </SectionContainer>
          <SmallSectionTitle>
            {t("utils.fiveCategories.oral.title")}
          </SmallSectionTitle>
          <SectionContainer>
            <ContentText>{t("utils.fiveCategories.oral.content")}</ContentText>
          </SectionContainer>
          <PageNumber>15</PageNumber>
          <WaterMark src={getWatermark(this.props.language)} />
        </A4Container>
        <A4ContentContainer>
          <SmallSectionTitle>
            {t("utils.fiveCategories.social.title")}
          </SmallSectionTitle>
          <SectionContainer>
            <ContentText>
              {t("utils.fiveCategories.social.content")}
            </ContentText>
          </SectionContainer>
          <SmallSectionTitle>
            {t("utils.fiveCategories.preliteracy.title")}
          </SmallSectionTitle>
          <SectionContainer>
            <ContentText>
              {t("utils.fiveCategories.preliteracy.content")}
            </ContentText>
          </SectionContainer>
          <PageNumber>16</PageNumber>
          <WaterMark src={getWatermark(this.props.language)} />
        </A4ContentContainer>
      </>
    );
  };
}
export default withTranslation()(Appendix2);
