import styled from "styled-components";
import { ButtonStyle, UserComment } from "styles";

// change status bar
export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
  padding: 20px 6%;
  @media only screen and (min-width: 992px) {
    padding: 20px 8%;
  }
`;
export const StatusButtonContainer = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  width: 150px;
  margin-right: 30px;
  cursor: pointer;
  color: ${(props) => (props.selected ? "#6898E3" : "#E0E0E0")};
`;
export const StatusNumber = styled.div<{ selected: boolean }>`
  height: 30px;
  margin-left: 10px;
  background-color: ${(props) => (props.selected ? "#6898E3" : "#E0E0E0")};
  border-radius: 8px;
  color: white;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableContainer = styled.div`
  padding: 20px 4%;
  @media only screen and (min-width: 992px) {
    padding: 20px 6%;
  }
`;
export const ScrollContainer = styled.div`
  display: flex;
  padding: 10px 10px 25px 10px;
  overflow-x: scroll;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    width: 0px;
    background: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0) !important;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0) !important;
  }
`;

// table
export const Table = styled.div`
  border-radius: 15px;
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
  min-width: 1000px;
  @media only screen and (min-width: 992px) {
    width: 100%;
  }
`;

// table header
export const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid rgb(241, 241, 241);
`;
export const HeaderTitleContainer = styled.div`
  text-align: center;
`;
export const HeaderText = styled(UserComment)`
  font-weight: 500;
`;

// table body
export const BodyRow = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  height: auto;
  padding: 10px 0px;
  border-bottom: 1px solid rgb(241, 241, 241);
`;
export const BodyContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ActionButton = styled(ButtonStyle)`
  background: #6898e3;
  color: #ffffff;
  height: 44px;
  margin: 0;
`;

export const ReportNotNeededLabel = styled.div`
  color: #d5d5d5d;
`;

export const TooltipContainer = styled.div<{
  display: boolean;
}>`
  padding: 1rem 3rem;
  transition: 0.5s;
  position: absolute;
  margin-left: 25rem;
  margin-top: 5rem;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #ffffff;
  opacity: ${(props) => (props.display ? "1" : "0")};
  pointer-events: ${(props) => (props.display ? "auto" : "none")};
  z-index: 9999;
  max-width: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #333333;
`;
export const TooltipText = styled.div`
  margin: 2rem 1rem;
  font-size: 1.4rem;
`;
export const TooltipVideoContainer = styled.div`
  width: 40rem;
  border: 1px solid #e0e0e0;
  margin-bottom: 1rem;
  max-height: 30rem;
`;
export const RecommendedViewContainer = styled.div`
  padding: 25px 7% 0 7%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #a3a3a3;
  text-decoration: italic;
  pointer: default;
`;
