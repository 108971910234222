import { makeSelectRole } from "components/auth/selectors";
import { ROLE } from "constants/common";
import { useSelector } from "react-redux";
import AdminRoute from "./AdminRoute";
import TherapistRoute from "./TherapistRoute";
import TmpUserRoute from "./TmpUserRoute";
import UserRoute from "./UserRoute";
import { JS_COOKIE } from "../../util/auth";
import { ADMIN_COOKIE_ROLE } from "../../constants/admin";
import { Redirect } from "react-router";

const PrivateRoute = () => {
  const role = useSelector(makeSelectRole());
  const adminRole = JS_COOKIE.get(ADMIN_COOKIE_ROLE);
  const isAdmin = adminRole === ROLE.admin || adminRole === ROLE.translator;

  const renderPrivateRoute = () => {
    if (role === ROLE.user) return <UserRoute />;
    else if (role === ROLE.therapist) return <TherapistRoute />;
    else if (role === ROLE.tmpUser) return <TmpUserRoute />;
    else if (isAdmin) {
      return <AdminRoute />;
    }
    return <Redirect to="/login" />;
  };

  return <>{renderPrivateRoute()}</>;
};

export default PrivateRoute;
