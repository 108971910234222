import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  TermsIntroTitle,
  TermsSection,
  TermsContentListContainer,
  PageContainer,
  TermsContentList,
  TermsContent,
  TermsIndex,
  TermsContentContainer,
  LanguageContainer,
  TitleContainer,
} from "./styles";
import { GET_TITLE_BAR } from "util/common";
import LanguageDropdown from "../LanguageDropdown";
import { H1Title } from "styles";

interface TermsConditionsProps {}

interface TermsConditionsState {}

type Props = TermsConditionsProps & WithTranslation;

class TermsConditions extends Component<Props, TermsConditionsState> {
  componentDidMount = () => {
    const { t } = this.props;
    document.title = GET_TITLE_BAR(t, "termsAndCondition", true);
  };

  render() {
    window.scrollTo(0, 0);
    const { t } = this.props;
    return (
      <PageContainer>
        <LanguageContainer>
          <LanguageDropdown path="TermsConditions" color={"black"} />
        </LanguageContainer>
        <TitleContainer>
          <H1Title>{t("utils.terms.title")}</H1Title>
        </TitleContainer>
        <TermsSection>
          <TermsContent>{t("utils.terms.intro")}</TermsContent>
        </TermsSection>
        <TermsSection>
          <TermsIntroTitle>{t("utils.terms.section1.title")}</TermsIntroTitle>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section1.1")}</TermsIndex>
            <TermsContent>{t("utils.terms.section1.p1")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section1.2")}</TermsIndex>
            <TermsContent>{t("utils.terms.section1.p2")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section1.3")}</TermsIndex>
            <TermsContent>{t("utils.terms.section1.p3")}</TermsContent>
          </TermsContentContainer>
        </TermsSection>
        <TermsSection>
          <TermsIntroTitle>{t("utils.terms.section2.title")}</TermsIntroTitle>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section2.1")}</TermsIndex>
            <TermsContent>{t("utils.terms.section2.p1")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section2.2")}</TermsIndex>
            <TermsContent>{t("utils.terms.section2.p2")}</TermsContent>
          </TermsContentContainer>
        </TermsSection>
        <TermsSection>
          <TermsIntroTitle>{t("utils.terms.section3.title")}</TermsIntroTitle>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section3.1")}</TermsIndex>
            <TermsContent>{t("utils.terms.section3.p1")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section3.2")}</TermsIndex>
            <TermsContent>{t("utils.terms.section3.p2")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section3.3")}</TermsIndex>
            <TermsContent>{t("utils.terms.section3.p3")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section3.4")}</TermsIndex>
            <TermsContent>{t("utils.terms.section3.p4")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section3.5")}</TermsIndex>
            <TermsContent>{t("utils.terms.section3.p5")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section3.6")}</TermsIndex>
            <TermsContent>{t("utils.terms.section3.p6")}</TermsContent>
          </TermsContentContainer>
        </TermsSection>
        <TermsSection>
          <TermsIntroTitle>{t("utils.terms.section4.title")}</TermsIntroTitle>
          <TermsContentContainer>
            <TermsContent>{t("utils.terms.section4.p1")}</TermsContent>
          </TermsContentContainer>
        </TermsSection>
        <TermsSection>
          <TermsIntroTitle>{t("utils.terms.section5.title")}</TermsIntroTitle>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section5.1")}</TermsIndex>
            <TermsContent>{t("utils.terms.section5.p1")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section5.2")}</TermsIndex>
            <div>
              <TermsContentList>
                {t("utils.terms.section5.p2")}
              </TermsContentList>
              <div>
                <TermsContentListContainer>
                  <TermsIndex>{t("utils.terms.index.1")}</TermsIndex>
                  <TermsContentList>
                    {t("utils.terms.section5.c1")}
                  </TermsContentList>
                </TermsContentListContainer>
                <TermsContentListContainer>
                  <TermsIndex>{t("utils.terms.index.2")}</TermsIndex>
                  <TermsContentList>
                    {t("utils.terms.section5.c2")}
                  </TermsContentList>
                </TermsContentListContainer>
                <TermsContentListContainer>
                  <TermsIndex>{t("utils.terms.index.3")}</TermsIndex>
                  <TermsContentList>
                    {t("utils.terms.section5.c3")}
                  </TermsContentList>
                </TermsContentListContainer>
                <TermsContentListContainer>
                  <TermsIndex>{t("utils.terms.index.4")}</TermsIndex>
                  <TermsContentList>
                    {t("utils.terms.section5.c4")}
                  </TermsContentList>
                </TermsContentListContainer>
                <TermsContentListContainer>
                  <TermsIndex>{t("utils.terms.index.5")}</TermsIndex>
                  <TermsContentList>
                    {t("utils.terms.section5.c5")}
                  </TermsContentList>
                </TermsContentListContainer>
              </div>
            </div>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section5.3")}</TermsIndex>
            <TermsContent>{t("utils.terms.section5.p3")}</TermsContent>
          </TermsContentContainer>
        </TermsSection>
        <TermsSection>
          <TermsIntroTitle>{t("utils.terms.section6.title")}</TermsIntroTitle>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section6.1")}</TermsIndex>
            <TermsContent>{t("utils.terms.section6.p1")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section6.2")}</TermsIndex>
            <div>
              <TermsContentList>
                {t("utils.terms.section6.p2")}
              </TermsContentList>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.1")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section6.c1")}
                </TermsContentList>
              </TermsContentListContainer>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.2")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section6.c2")}
                </TermsContentList>
              </TermsContentListContainer>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.3")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section6.c3")}
                </TermsContentList>
              </TermsContentListContainer>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.4")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section6.c4")}
                </TermsContentList>
              </TermsContentListContainer>
            </div>
          </TermsContentContainer>
        </TermsSection>
        <TermsSection>
          <TermsIntroTitle>{t("utils.terms.section7.title")}</TermsIntroTitle>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section7.1")}</TermsIndex>
            <TermsContent>{t("utils.terms.section7.p1")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section7.2")}</TermsIndex>
            <TermsContent>{t("utils.terms.section7.p2")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section7.3")}</TermsIndex>
            <TermsContent>{t("utils.terms.section7.p3")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section7.4")}</TermsIndex>
            <TermsContent>{t("utils.terms.section7.p4")}</TermsContent>
          </TermsContentContainer>
        </TermsSection>
        <TermsSection>
          <TermsIntroTitle>{t("utils.terms.section8.title")}</TermsIntroTitle>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section8.1")}</TermsIndex>
            <TermsContent>{t("utils.terms.section8.p1")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section8.2")}</TermsIndex>
            <TermsContent>{t("utils.terms.section8.p2")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section8.3")}</TermsIndex>
            <TermsContent>{t("utils.terms.section8.p3")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section8.4")}</TermsIndex>
            <div>
              <TermsContentList>
                {t("utils.terms.section8.p4")}
              </TermsContentList>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.1")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section8.c1")}
                </TermsContentList>
              </TermsContentListContainer>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.2")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section8.c2")}
                </TermsContentList>
              </TermsContentListContainer>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.3")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section8.c3")}
                </TermsContentList>
              </TermsContentListContainer>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.4")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section8.c4")}
                </TermsContentList>
              </TermsContentListContainer>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.5")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section8.c5")}
                </TermsContentList>
              </TermsContentListContainer>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.6")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section8.c6")}
                </TermsContentList>
              </TermsContentListContainer>
            </div>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section8.5")}</TermsIndex>
            <TermsContent>{t("utils.terms.section8.p5")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section8.6")}</TermsIndex>
            <TermsContent>{t("utils.terms.section8.p6")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section8.7")}</TermsIndex>
            <TermsContent>{t("utils.terms.section8.p7")}</TermsContent>
          </TermsContentContainer>
        </TermsSection>
        <TermsSection>
          <TermsIntroTitle>{t("utils.terms.section9.title")}</TermsIntroTitle>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section9.1")}</TermsIndex>
            <TermsContent>{t("utils.terms.section9.p1")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section9.2")}</TermsIndex>
            <TermsContent>{t("utils.terms.section9.p2")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section9.3")}</TermsIndex>
            <TermsContent>{t("utils.terms.section9.p3")}</TermsContent>
          </TermsContentContainer>
        </TermsSection>
        <TermsSection>
          <TermsIntroTitle>{t("utils.terms.section10.title")}</TermsIntroTitle>
          <TermsContentContainer>
            <TermsContent>{t("utils.terms.section10.p1")}</TermsContent>
          </TermsContentContainer>
        </TermsSection>
        <TermsSection>
          <TermsIntroTitle>{t("utils.terms.section11.title")}</TermsIntroTitle>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section11.1")}</TermsIndex>
            <div>
              <TermsContentList>
                {t("utils.terms.section11.p1.title")}
              </TermsContentList>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.1")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section11.p1.c1")}
                </TermsContentList>
              </TermsContentListContainer>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.2")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section11.p1.c2")}
                </TermsContentList>
              </TermsContentListContainer>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.3")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section11.p1.c3")}
                </TermsContentList>
              </TermsContentListContainer>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.4")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section11.p1.c4")}
                </TermsContentList>
              </TermsContentListContainer>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.5")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section11.p1.c5")}
                </TermsContentList>
              </TermsContentListContainer>
            </div>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section11.2")}</TermsIndex>
            <div>
              <TermsContentList>
                {t("utils.terms.section11.p2.title")}
              </TermsContentList>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.1")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section11.p2.c1")}
                </TermsContentList>
              </TermsContentListContainer>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.2")}</TermsIndex>
                <div>
                  <TermsContentList>
                    {t("utils.terms.section11.p2.c2")}
                  </TermsContentList>
                  <TermsContentListContainer>
                    <TermsIndex>{t("utils.terms.index.a")}</TermsIndex>
                    <TermsContentList>
                      {t("utils.terms.section11.p2.a")}
                    </TermsContentList>
                  </TermsContentListContainer>
                  <TermsContentListContainer>
                    <TermsIndex>{t("utils.terms.index.b")}</TermsIndex>
                    <TermsContentList>
                      {t("utils.terms.section11.p2.b")}
                    </TermsContentList>
                  </TermsContentListContainer>
                  <TermsContentListContainer>
                    <TermsIndex>{t("utils.terms.index.c")}</TermsIndex>
                    <TermsContentList>
                      {t("utils.terms.section11.p2.c")}
                    </TermsContentList>
                  </TermsContentListContainer>
                  <TermsContentListContainer>
                    <TermsIndex>{t("utils.terms.index.d")}</TermsIndex>
                    <TermsContentList>
                      {t("utils.terms.section11.p2.d")}
                    </TermsContentList>
                  </TermsContentListContainer>
                  <TermsContentListContainer>
                    <TermsIndex>{t("utils.terms.index.e")}</TermsIndex>
                    <TermsContentList>
                      {t("utils.terms.section11.p2.e")}
                    </TermsContentList>
                  </TermsContentListContainer>
                  <TermsContentListContainer>
                    <TermsIndex>{t("utils.terms.index.f")}</TermsIndex>
                    <TermsContentList>
                      {t("utils.terms.section11.p2.f")}
                    </TermsContentList>
                  </TermsContentListContainer>
                </div>
              </TermsContentListContainer>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.3")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section11.p2.c3")}
                </TermsContentList>
              </TermsContentListContainer>
            </div>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section11.3")}</TermsIndex>
            <TermsContent>{t("utils.terms.section11.p3")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section11.4")}</TermsIndex>
            <TermsContent>{t("utils.terms.section11.p4")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section11.5")}</TermsIndex>
            <TermsContent>{t("utils.terms.section11.p5")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section11.6")}</TermsIndex>
            <div>
              <TermsContentList>
                {t("utils.terms.section11.p6.title")}
              </TermsContentList>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.1")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section11.p6.c1")}
                </TermsContentList>
              </TermsContentListContainer>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.2")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section11.p6.c2")}
                </TermsContentList>
              </TermsContentListContainer>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.3")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section11.p6.c3")}
                </TermsContentList>
              </TermsContentListContainer>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.4")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section11.p6.c4")}
                </TermsContentList>
              </TermsContentListContainer>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.5")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section11.p6.c5")}
                </TermsContentList>
              </TermsContentListContainer>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.6")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section11.p6.c6")}
                </TermsContentList>
              </TermsContentListContainer>
              <TermsContentListContainer>
                <TermsIndex>{t("utils.terms.index.7")}</TermsIndex>
                <TermsContentList>
                  {t("utils.terms.section11.p6.c7")}
                </TermsContentList>
              </TermsContentListContainer>
            </div>
          </TermsContentContainer>
        </TermsSection>
        <TermsSection>
          <TermsIntroTitle>{t("utils.terms.section12.title")}</TermsIntroTitle>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section12.1")}</TermsIndex>
            <TermsContent>{t("utils.terms.section12.p1")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section12.2")}</TermsIndex>
            <TermsContent>{t("utils.terms.section12.p2")}</TermsContent>
          </TermsContentContainer>
        </TermsSection>
        <TermsSection>
          <TermsIntroTitle>{t("utils.terms.section13.title")}</TermsIntroTitle>
          <TermsContentContainer>
            <TermsContent>{t("utils.terms.section13.p1")}</TermsContent>
          </TermsContentContainer>
        </TermsSection>
        <TermsSection>
          <TermsIntroTitle>{t("utils.terms.section14.title")}</TermsIntroTitle>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section14.1")}</TermsIndex>
            <TermsContent>{t("utils.terms.section14.p1")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section14.2")}</TermsIndex>
            <TermsContent>{t("utils.terms.section14.p2")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section14.3")}</TermsIndex>
            <TermsContent>{t("utils.terms.section14.p3")}</TermsContent>
          </TermsContentContainer>
          <TermsContentContainer>
            <TermsIndex>{t("utils.terms.section14.4")}</TermsIndex>
            <TermsContent>{t("utils.terms.section14.p4")}</TermsContent>
          </TermsContentContainer>
        </TermsSection>
        <TermsIntroTitle>{t("utils.terms.createBy.company")}</TermsIntroTitle>
        <TermsIntroTitle>{t("utils.terms.createBy.date")}</TermsIntroTitle>
      </PageContainer>
    );
  }
}
export default withTranslation()(TermsConditions);
