import React from "react";
import styled from "styled-components";
import warning from "./assets/Exclamation.svg";
import tick from "./assets/success.svg";

import { Container, Content, Message, Image } from "./styles";

interface Props {
  show: boolean;
  message: any;
  status: boolean;
}

interface States {}

class NotificationModal extends React.Component<Props, States> {
  render() {
    const { show, message, status } = this.props;

    return show ? (
      <Container id="ModalContainer">
        <Content
          id="ModalContent"
          status={status}
          style={{ display: "flex", height: "auto" }}
        >
          <Image
            src={status ? tick : warning}
            style={{ marginBottom: "2px" }}
            alt=""
          />
          <NotiBlock>
            {typeof message === "string" ? (
              <Message>{message}</Message>
            ) : (
              message &&
              message.map((item, index) => (
                <Message key={index}>{item}</Message>
              ))
            )}
          </NotiBlock>
        </Content>
      </Container>
    ) : null;
  }
}

export default NotificationModal;

const NotiBlock = styled.div`
  display: flex;
  flex-direction: column;
`;
