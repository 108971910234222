import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ProTable } from "@ant-design/pro-components";
import { Button, Input, ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US";
import api_address from "../../../constants/config";
import { ADMIN_COOKIE_NAME, LIMIT } from "../../../constants/admin";
import { GET_DATE_TIME_STRING_FROM_TIMESTAMP } from "../../../util/common";
import { JS_COOKIE } from "util/auth";
import { Container, AdminTableContainer } from "../styles";

interface TherapistType {
  id: string;
  UUID: string;
  firstName: string;
  lastName: string;
  createdat: string;
  lastloginat: string;
}

interface SorterState {
  field: string;
  order: "ascend" | "descend" | undefined;
}

const Therapist: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [therapistData, setTherapistData] = useState<TherapistType[]>([]);
  const [filteredData, setFilteredData] = useState<TherapistType[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [sorter, setSorter] = useState<SorterState | null>(null);
  const [filters, setFilters] = useState<{}>({});
  const [searchText, setSearchText] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(25);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const getTherapistData = async (page: number = 1, pageSize: number = 25) => {
    setLoading(true);
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const offset = (page - 1) * pageSize;
      const { data } = await axios.get(api_address + "api/admin/therapist", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          offset: offset,
          limit: pageSize,
        },
      });
      const sortedData = sortData(data.therapist, sorter);
      setTherapistData(sortedData);
      setFilteredData(sortedData);
      setTotal(data.rowCount);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTherapistData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  useEffect(() => {
    applyFiltersAndSort();
  }, [filters, sorter, therapistData, searchText]);

  const applyFiltersAndSort = () => {
    let result = [...therapistData];

    if (searchText) {
      result = result.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }

    result = sortData(result, sorter);

    setFilteredData(result);
    setTotal(result.length);
  };

  const sortData = (
    data: TherapistType[],
    currentSorter: SorterState | null
  ) => {
    if (!currentSorter) return data;

    return [...data].sort((a, b) => {
      if (currentSorter.order === "ascend") {
        return a[currentSorter.field] > b[currentSorter.field] ? 1 : -1;
      } else if (currentSorter.order === "descend") {
        return a[currentSorter.field] < b[currentSorter.field] ? 1 : -1;
      }
      return 0;
    });
  };

  const renderDate = (text: string) => {
    if (!text || text === "-" || text.includes("NaN")) {
      return "-";
    }
    return GET_DATE_TIME_STRING_FROM_TIMESTAMP(text);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const newSorter: SorterState = {
      field: sorter.field,
      order: sorter.order,
    };
    setSorter(newSorter);
    setFilters(filters);
    setPageSize(pagination.pageSize);
    setCurrentPage(pagination.current);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const columns = [
    {
      title: t("admin.therapist.id"),
      dataIndex: "id",
      sorter: true,
    },
    {
      title: t("admin.therapist.UUID"),
      dataIndex: "UUID",
      sorter: true,
    },
    {
      title: t("admin.therapist.firstName"),
      dataIndex: "firstName",
      sorter: true,
    },
    {
      title: t("admin.therapist.lastName"),
      dataIndex: "lastName",
      sorter: true,
    },
    {
      title: t("admin.therapist.lastLoginAt"),
      dataIndex: "lastloginat",
      sorter: true,
      render: renderDate,
    },
    {
      title: t("admin.therapist.createdAt"),
      dataIndex: "createdat",
      sorter: true,
      render: renderDate,
    },
  ];

  return (
    <ConfigProvider locale={enUS}>
      <AdminTableContainer>
        <Input.Search
          placeholder={"UUID, Name"}
          onChange={handleSearch}
          style={{ marginBottom: 16 }}
        />
        <ProTable<TherapistType>
          columns={columns}
          dataSource={filteredData}
          loading={loading}
          rowKey="id"
          pagination={{
            showQuickJumper: true,
            current: currentPage,
            total: total,
            pageSize: pageSize,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "25", "50", "100"],
          }}
          search={false}
          dateFormatter="string"
          headerTitle={t("admin.therapist.title")}
          rowSelection={{
            onChange: (selectedRowKeys) => {
              setSelectedRowKeys(selectedRowKeys);
            },
          }}
          toolBarRender={() => [
            <Button
              key="create"
              onClick={() => history.push("/admin/therapist/create")}
            >
              {t("admin.therapist.create")}
            </Button>,
            selectedRowKeys.length === 1 && (
              <Button
                key="edit"
                onClick={() =>
                  history.push(`/admin/therapist/edit/${selectedRowKeys[0]}`)
                }
              >
                {t("admin.therapist.edit")}
              </Button>
            ),
          ]}
          onChange={handleTableChange}
        />
      </AdminTableContainer>
    </ConfigProvider>
  );
};

export default Therapist;
