import i18next from "i18next";
import mixpanel from "mixpanel-browser";
import Cookies from "js-cookie";
import axios from "axios";
import api_address from "../constants/config";
import { IS_NUMBER, IS_VALID_EMAIL, GET_LANGUAGE } from "./common";
import { client } from "graphql/ApolloClient";
import {
  LOGIN_MUTATION,
  LoginData,
  LoginVariables,
} from "graphql/mutations/login";
import {
  ADMIN_LOGIN_MUTATION,
  AdminLoginData,
  AdminLoginVariables,
} from "graphql/mutations/adminLogin";
import {
  REGISTER_MUTATION,
  RegisterVariables,
  RegisterData,
} from "graphql/mutations/register";
import {
  REGISTER_THERAPIST_MUTATION,
  RegisterTherapistVariables,
  RegisterTherapistData,
} from "graphql/mutations/registerTherapist";
import {
  EDIT_THERAPIST_MUTATION,
  EditTherapistVariables,
  EditTherapistData,
} from "graphql/mutations/editTherapist";
import {
  DELETE_THERAPIST_BIO_MUTATION,
  DeleteTherapistBioVariables,
  DeleteTherapistBioData,
} from "graphql/mutations/deleteTherapistBio";
import { ROLE, GRAPHQL_RETURN_STATUS } from "../constants/common";
import {
  SendResetPasswordInstructionsData,
  SendResetPasswordInstructionsVariables,
  SEND_RESET_PASSWORD_INSTRUCTIONS_MUTATION,
} from "graphql/mutations/sendResetPasswordInstructions";
import {
  UserWithResetPasswordTokenData,
  UserWithResetPasswordTokenVariables,
  USER_WITH_RESET_PASSWORD_TOKEN_QUERY,
} from "graphql/queries/userWithResetPasswordToken";
import {
  ResetPasswordData,
  ResetPasswordVariables,
  RESET_PASSWORD_MUTATION,
} from "graphql/mutations/resetPassword";
import {
  OAUTH_ERROR_MESSAGE,
  OAUTH_METHOD,
} from "../components/auth/constants";
import {
  RESET_PASSWORD_BY_UUID_MUTATION,
  ResetPasswordByUuidData,
  ResetPasswordByUuidVariables,
} from "graphql/mutations/resetPasswordByUuid";
// Set cookie domain to parent domain, so that it can be accessed by all subdomains
// Duplicated in guide, any changes have to be made there too
export const JS_COOKIE = Cookies.withAttributes({
  domain: window.location.hostname.split(".").slice(1).join("."),
  expires: 1,
});

export const GET_COOKIE_NAME = (role: string) => {
  if (role === ROLE.admin) {
    return "admin-token";
  } else {
    return "token";
  }
};

export const LOGIN_VALIDATION = ({
  countryCode,
  phone,
  email,
  password,
  isPhone,
}) => {
  if (isPhone) {
    if (countryCode === "") {
      throw new Error(i18next.t("message.account.countryCodeMissing"));
    }
    if (phone === "") {
      throw new Error(i18next.t("message.account.phoneMissing"));
    }
    if (!IS_NUMBER(phone)) {
      throw new Error(i18next.t("message.account.invalidPhone"));
    }
  } else {
    if (!email) {
      throw new Error(i18next.t("message.account.emailMissing"));
    }
    if (!IS_VALID_EMAIL(email)) {
      throw new Error(i18next.t("message.account.invalidEmail"));
    }
  }
  if (!password) {
    throw new Error(i18next.t("message.account.passwordMissing"));
  }
};

export const REGISTER_VALIDATION = ({
  firstName,
  lastName,
  email,
  phone,
  countryCode,
  country,
  password,
  isAgreed,
  requireOrganizationCode,
  organizationCode,
  timeZone,
}: {
  firstName: string;
  lastName: string;
  email: string;
  phone: any;
  countryCode: any;
  country: string;
  password: string;
  isAgreed: boolean;
  requireOrganizationCode?: boolean;
  organizationCode?: string;
  timeZone: string;
}) => {
  if (firstName === "" && lastName === "") {
    throw new Error(i18next.t("message.account.nameMissing"));
  }
  if (email === "") {
    throw new Error(i18next.t("message.account.emailMissing"));
  }
  if (!IS_VALID_EMAIL(email)) {
    throw new Error(i18next.t("message.account.invalidEmail"));
  }
  if (countryCode === "") {
    throw new Error(i18next.t("message.account.countryCodeMissing"));
  }
  if (country === "") {
    throw new Error(i18next.t("message.account.countryMissing"));
  }
  if (phone === "") {
    throw new Error(i18next.t("message.account.phoneMissing"));
  }
  if (!IS_NUMBER(phone)) {
    throw new Error(i18next.t("message.account.invalidPhone"));
  }
  if (password === "") {
    throw new Error(i18next.t("message.account.passwordMissing"));
  }
  if (requireOrganizationCode && organizationCode === "") {
    throw new Error(i18next.t("message.account.organizationCodeMissing"));
  }
  if (!isAgreed) {
    throw new Error(i18next.t("message.account.agreementMissing"));
  }
  if (timeZone === "") {
    throw new Error(i18next.t("message.account.timeZoneMissing"));
  }
};

export const LOGIN = async (data: LoginVariables): Promise<LoginData> => {
  const res = await client.mutate({
    mutation: LOGIN_MUTATION,
    variables: data,
  });
  const loginReturn = res.data as LoginData;
  return loginReturn;
};

export const ADMIN_LOGIN = async (
  data: AdminLoginVariables
): Promise<AdminLoginData> => {
  const res = await client.mutate({
    mutation: ADMIN_LOGIN_MUTATION,
    variables: data,
  });
  const loginReturn = res.data as AdminLoginData;
  return loginReturn;
};

export const DEVELOPER_LOGIN_SUBMIT = async (userInfo: { email: string }) => {
  const res = await axios.post(
    api_address + "api/user/developerLogin",
    userInfo
  );
  const { message, status } = res.data;
  if (!status) {
    throw new Error(message);
  }
  const { token, user } = res.data;
  mixpanel.identify(user?.uuid);
  JS_COOKIE.set("token", token);
  return {
    token,
    role: ROLE.user,
  };
};

export const ADMIN_LOGIN_SUBMIT = async (
  userInfo: {
    email: string;
    password: string;
  },
  from: string = ""
) => {
  const { email, password } = userInfo;
  const locale = GET_LANGUAGE();
  const loginReturn = await ADMIN_LOGIN({
    email,
    password,
    eval: true,
    locale: locale,
  });
  const { message, status } = loginReturn.adminLogin.returnMessage;
  if (
    from === OAUTH_METHOD.GOOGLE &&
    status === GRAPHQL_RETURN_STATUS.error &&
    loginReturn.adminLogin.user &&
    loginReturn.adminLogin.user?.isOauth === false
  ) {
    throw new Error(OAUTH_ERROR_MESSAGE);
  }

  if (
    status === GRAPHQL_RETURN_STATUS.error &&
    loginReturn.adminLogin.user &&
    loginReturn.adminLogin.user?.isOauth === true
  ) {
    throw new Error(i18next.t("message.account.methodError"));
  }

  if (status === GRAPHQL_RETURN_STATUS.error) {
    throw new Error(message);
  }
  const { token, user } = loginReturn.adminLogin;
  mixpanel.identify(user?.uuid);
  const role = user?.metaType ?? ROLE.user;
  JS_COOKIE.set("token", token);
  return { token, role };
};

export const LOGIN_SUBMIT = async (
  userInfo: {
    email: string;
    password: string;
  },
  from: string = ""
) => {
  const { email, password } = userInfo;
  const locale = GET_LANGUAGE();
  const loginReturn = await LOGIN({
    email,
    password,
    eval: true,
    locale: locale,
  });
  const { message, status } = loginReturn.login.returnMessage;
  if (
    from === OAUTH_METHOD.GOOGLE &&
    status === GRAPHQL_RETURN_STATUS.error &&
    loginReturn.login.user &&
    loginReturn.login.user?.isOauth === false
  ) {
    throw new Error(OAUTH_ERROR_MESSAGE);
  }

  if (
    status === GRAPHQL_RETURN_STATUS.error &&
    loginReturn.login.user &&
    loginReturn.login.user?.isOauth === true
  ) {
    throw new Error(i18next.t("message.account.methodError"));
  }

  if (status === GRAPHQL_RETURN_STATUS.error) {
    throw new Error(message);
  }
  const { token, user } = loginReturn.login;
  mixpanel.identify(user?.uuid);
  const role = user?.metaType ?? ROLE.user;
  JS_COOKIE.set("token", token);
  return { token, role };
};

export const REGISTER = async (data: RegisterVariables) => {
  const res = await client.mutate({
    mutation: REGISTER_MUTATION,
    variables: data,
  });
  const registerData = res.data as RegisterData;
  return registerData;
};

export const REGISTER_SUBMIT = async (newUser: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  countryCode: string;
  phone: string;
  modularized?: boolean;
  originalToken?: string;
  requireOrganizationCode?: boolean;
  organizationCode?: string;
  isOauth?: boolean;
  timeZone: string;
}): Promise<string> => {
  const locale = GET_LANGUAGE();
  const registerData = await REGISTER({
    ...newUser,
    locale: locale,
    role: ROLE.user,
  });
  const { token, returnMessage, user } = registerData.register;
  if (returnMessage.status === GRAPHQL_RETURN_STATUS.error) {
    throw new Error(returnMessage.message);
  }
  mixpanel.register({
    name:
      newUser.firstName +
      (newUser.lastName !== "" ? " " + newUser.lastName : ""),
    Email: newUser.email,
    countryCode: newUser.countryCode,
    phone: newUser.phone,
    role: ROLE.user,
  });
  mixpanel.identify(user?.uuid);
  JS_COOKIE.set("token", token);
  return token;
};

export const REGISTER_THERAPIST = async (data: RegisterTherapistVariables) => {
  const res = await client.mutate({
    mutation: REGISTER_THERAPIST_MUTATION,
    variables: data,
  });
  const registerData = res.data as RegisterTherapistData;
  return registerData;
};

export const REGISTER_THERAPIST_SUBMIT = async (
  newUser: {
    uuid: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    country: string;
    countryCode: string;
    phone: string;
    timeZone: string;
    title: string;
    consultationUrl?: string;
    cnConsultationUrl?: string;
    avatarFilename: string;
  },
  bio: Array<Object>
): Promise<any> => {
  const locale = GET_LANGUAGE();
  const registerData = await REGISTER_THERAPIST({
    ...newUser,
    bio: bio,
    locale: locale,
    role: "Therapist",
  });

  const { returnMessage } = registerData.registerTherapist;
  return returnMessage;
};

export const EDIT_THERAPIST = async (data: EditTherapistVariables) => {
  const res = await client.mutate({
    mutation: EDIT_THERAPIST_MUTATION,
    variables: data,
  });

  const editTherapistData = res.data as EditTherapistData;
  return editTherapistData;
};

export const EDIT_THERAPIST_SUBMIT = async (
  editInfo: {
    userUuid: string;
    firstName: string;
    lastName: string;
    email: string;
    country: string;
    timeZone: string;
    countryCode: string;
    phone: string;
    consultationUrl?: string;
    cnConsultationUrl?: string;
    avatarFilename: string;
    title: string;
    disabled: boolean;
  },
  bio: Array<Object>,
  snowFlakeUuid: Array<String>,
  token: string | undefined
) => {
  const editData = await EDIT_THERAPIST({
    ...editInfo,
    originalToken: token,
    snowFlakeUuid: snowFlakeUuid,
    bio: bio,
  });

  const { returnMessage } = editData.editTherapist;
  return returnMessage;
};

export const DELETE_THERAPIST_BIO = async (
  data: DeleteTherapistBioVariables
) => {
  const res = await client.mutate({
    mutation: DELETE_THERAPIST_BIO_MUTATION,
    variables: data,
  });
  const deleteTherapistBioData = res.data as DeleteTherapistBioData;
  return deleteTherapistBioData;
};

export const DELETE_THERAPIST_BIO_SUBMIT = async (
  userUuid: string,
  uniqueBioIds: Array<String>
) => {
  const deleteData = await DELETE_THERAPIST_BIO({
    userUuid,
    uniqueBioIds,
  });

  const { returnMessage } = deleteData.deleteTherapistBio;
  return returnMessage;
};

export const SEND_RESET_PASSWORD_INSTRUCTIONS = async (
  data: SendResetPasswordInstructionsVariables
) => {
  const res = await client.mutate({
    mutation: SEND_RESET_PASSWORD_INSTRUCTIONS_MUTATION,
    variables: data,
  });
  const sendResetPasswordInstructionsData =
    res.data as SendResetPasswordInstructionsData;
  return sendResetPasswordInstructionsData;
};

export const USER_WITH_RESET_PASSWORD_TOKEN = async (
  data: UserWithResetPasswordTokenVariables
) => {
  const res = await client.query({
    query: USER_WITH_RESET_PASSWORD_TOKEN_QUERY,
    variables: data,
  });
  const userWithResetPasswordTokenData =
    res.data as UserWithResetPasswordTokenData;
  return userWithResetPasswordTokenData;
};

export const RESET_PASSWORD = async (data: ResetPasswordVariables) => {
  const res = await client.mutate({
    mutation: RESET_PASSWORD_MUTATION,
    variables: data,
  });
  const resetPasswordData = res.data as ResetPasswordData;
  return resetPasswordData;
};

export const RESET_PASSWORD_BY_UUID = async (
  data: ResetPasswordByUuidVariables
) => {
  const res = await client.mutate({
    mutation: RESET_PASSWORD_BY_UUID_MUTATION,
    variables: data,
  });
  const resetPasswordByUuidData = res.data as ResetPasswordByUuidData;
  return resetPasswordByUuidData;
};
