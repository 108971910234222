import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Upload, UploadFile, Image, Modal } from "antd";
import { THERAPIST_PROFILE_PICTURE_FOLDER_ON_S3_ROUTE } from "constants/common";
interface imageType {
  avatarPreview: string;
  avatarFilename: string;
  isUploaded: boolean;
}
interface imageUploadProps {
  image: imageType;
  beforeUpload: (e: any) => void;
  handleUploadImageForPreview: (e: any) => Promise<void>;
}

interface States {
  previewImage: string;
  previewOpen: boolean;
}

type Props = imageUploadProps & WithTranslation;

class ImageUploader extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      previewImage: "",
      previewOpen: false,
    };
  }

  handlePreview = (file: UploadFile) => {
    const { image } = this.props;
    this.setState({
      previewImage:
        image.avatarFilename !== ""
          ? THERAPIST_PROFILE_PICTURE_FOLDER_ON_S3_ROUTE + image.avatarFilename
          : image.avatarPreview,
      previewOpen: true,
    });
  };

  render() {
    const { t, beforeUpload, handleUploadImageForPreview, image } = this.props;
    const { previewImage, previewOpen } = this.state;
    const imageFile: UploadFile[] =
      image.avatarPreview !== ""
        ? [
            {
              uid: "123",
              name: image.avatarPreview,
              status: image.isUploaded ? "done" : "removed",
              url:
                image.avatarFilename !== ""
                  ? THERAPIST_PROFILE_PICTURE_FOLDER_ON_S3_ROUTE +
                    image.avatarFilename
                  : image.avatarPreview,
            },
          ]
        : [];
    return (
      <div>
        <Upload
          accept="image/*"
          onChange={handleUploadImageForPreview}
          listType="picture-card"
          maxCount={1}
          fileList={imageFile}
          beforeUpload={beforeUpload}
          onPreview={this.handlePreview}
        >
          <button style={{ border: 0, background: "none" }} type="button">
            <div style={{ marginTop: 8 }}>Upload</div>
          </button>
        </Upload>
        <p style={{ fontStyle: "italic" }}>
          {t("admin.therapist.imageUploaderNote")}
        </p>

        {previewImage && (
          <Image
            wrapperStyle={{ display: "none" }}
            preview={{
              visible: previewOpen,
              onVisibleChange: (visible) =>
                this.setState({ previewOpen: visible }),
            }}
            src={previewImage}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(ImageUploader);
