import AWS from "aws-sdk";

AWS.config.region = process.env.REACT_APP_AWS_UPLOADER_S3_REGION;

AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID ?? "",
});

const getSignedUrl = (folder: string, name: string) => {
  const s3 = new AWS.S3({
    region: process.env.REACT_APP_AWS_UPLOADER_S3_REGION,
  });
  // const signedUrlExpireSeconds = 60 * 5 // your expiry time in seconds.

  const url = s3.getSignedUrl("getObject", {
    Bucket: process.env.REACT_APP_AWS_UPLOADER_S3_BUCKET as string,
    Key: `${folder}/${name}`,
    // Expires: signedUrlExpireSeconds
  });
  return url;
};

export default getSignedUrl;
