import React, { Component } from "react";
import { History, LocationState } from "history";
import { withTranslation, WithTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import {
  CloseButtonContainer,
  H1Title,
  ModalContainer,
  HeaderTitle,
  ModalContainerWithClose,
  ModalContent,
  ContentText,
  ModalHeader,
  ModalMask,
  ActionTitle,
  NextButton,
  RouteSectionContainer,
} from "styles";
import {
  EvaluationSegment,
  GoToButton,
  GoToGuideButton,
  GuideSegment,
  RecommendationSegment,
  ResultSegment,
  SectionDes,
  SegmentContainer,
  SegmentTitle,
} from "./styles";
import { makeUpdateEvaluationStatus } from "../../auth/selectors";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { AppState } from "storeConfig";
import { Icon } from "semantic-ui-react";
import {
  updateOngoingEvaluation,
  updateFinishedEvaluation,
} from "../../auth/actions";
import { AppActions } from "types/actions";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import Cookies from "js-cookie";
import axios from "axios";
import api_address from "../../../constants/config";
import { GET_TITLE_BAR } from "util/common";
import ReportReadyModal from "components/common/ReportReadyModal";

interface DashboardProps {
  history: History<LocationState>;
}

interface DashboardState {
  openModal: boolean;
  product: string;
}

type Props = DashboardProps &
  LinkStateProp &
  LinkDispatchProps &
  WithTranslation;

class Dashboard extends Component<Props, DashboardState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openModal: false,
      product: "",
    };
  }

  componentDidMount = async () => {
    try {
      const { t } = this.props;
      document.title = GET_TITLE_BAR(t, "dashboard", true);
      mixpanel.track("dashboard page", {
        action: "arrive",
      });
      window.scrollTo(0, 0);
      const token = Cookies.get("token");
      if (token) {
        const {
          data: { hasUnfinishedEvaluation, guideProduct },
        } = await axios.get(api_address + "api/user/dashboard", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const product = guideProduct;
        this.setState({
          product,
        });
        if (hasUnfinishedEvaluation) {
          this.props.onUpdateOngoingEvaluation();
        } else {
          this.props.onUpdateFinishedEvaluation();
        }
      } else {
        this.props.history.push("/login");
      }
    } catch (err) {
      console.error(err);
    }
  };

  onRecommendationClick = () => {
    mixpanel.track("Dashboard", {
      option: "Recommendation",
    });
    this.props.history.push("/purchases-buy-up/");
  };

  onEvaluationClick = (hasUnfinishedEvaluation: boolean) => {
    mixpanel.track("Dashboard", {
      option: "Evaluation",
    });
    this.props.history.push("/evaluation");
  };

  onResultClick = () => {
    mixpanel.track("Dashboard", {
      option: "Evaluation result",
    });
    this.props.history.push("/evaluation-history");
  };

  onEvaluationModalClose = () => {
    this.setState((states, props) => ({
      openModal: !states.openModal,
    }));
  };

  onGuideClick = () => {
    mixpanel.track("Dashboard", {
      option: "Go to guide",
    });
    window.open(process.env.REACT_APP_GUIDE_URL as string);
  };

  render() {
    const { t } = this.props;
    const { evaluation } = this.props;
    const { openModal, product } = this.state;

    return (
      <RouteSectionContainer>
        <ModalMask id="ModalContainer" open={openModal}>
          <ModalContainerWithClose id="ModalContent">
            <CloseButtonContainer>
              <Icon
                className="close"
                size="large"
                link
                style={{ marginBottom: "10px", marginRight: "-4px" }}
                onClick={this.onEvaluationModalClose}
              />
            </CloseButtonContainer>
            <ModalContainer>
              <ModalHeader>
                <HeaderTitle>{t("user.dashboard.modalHeader")}</HeaderTitle>
              </ModalHeader>
              <ModalContent>
                <ContentText>{t("user.dashboard.modalContent")}</ContentText>
              </ModalContent>
              <NextButton status={true} onClick={this.onRecommendationClick}>
                <ActionTitle>{t("user.dashboard.modalButton")}</ActionTitle>
              </NextButton>
            </ModalContainer>
          </ModalContainerWithClose>
        </ModalMask>

        <div style={{ padding: "0 8px" }}>
          <H1Title>{t("user.dashboard.welcome1")}</H1Title>
          <SegmentContainer>
            <GoToButton data-duration={t("user.dashboard.goToEvaluation")}>
              <EvaluationSegment
                onClick={() => this.onEvaluationClick(evaluation)}
              >
                <SegmentTitle>
                  {t("user.dashboard.evaluationTitle")}
                </SegmentTitle>
                <SectionDes>{t("user.dashboard.evaluationDes")}</SectionDes>
              </EvaluationSegment>
            </GoToButton>
            {product !== "" && (
              <GoToGuideButton
                data-duration={t("user.dashboard.goToGuideSystem")}
              >
                <GuideSegment onClick={this.onGuideClick}>
                  <SegmentTitle>
                    {t(`user.purchase.order.product.${product}.title`)}
                  </SegmentTitle>
                  <SectionDes>{t(`user.dashboard.${product}Des`)}</SectionDes>
                </GuideSegment>
              </GoToGuideButton>
            )}
            {/* This is commented out because no one ever used this part */}
            {/* {product !== "" && (
              <GoToButton
                data-duration={t("user.dashboard.goToRecommendation")}
              >
                <RecommendationSegment onClick={this.onRecommendationClick}>
                  <SegmentTitle>
                    {t("user.dashboard.recommendationTitle")}
                  </SegmentTitle>
                  <SectionDes>
                    {t("user.dashboard.recommendationDes")}
                  </SectionDes>
                </RecommendationSegment>
              </GoToButton>
            )} */}
            <GoToButton
              data-duration={t("user.dashboard.goToEvaluationResult")}
            >
              <ResultSegment onClick={this.onResultClick}>
                <SegmentTitle>
                  {t("user.dashboard.evaluationResultTitle")}
                </SegmentTitle>
                <SectionDes>
                  {t("user.dashboard.evaluationResultDes")}
                </SectionDes>
              </ResultSegment>
            </GoToButton>
          </SegmentContainer>
        </div>
      </RouteSectionContainer>
    );
  }
}

interface LinkStateProp {
  evaluation: boolean;
}

const mapStateToProps = createStructuredSelector<AppState, LinkStateProp>({
  evaluation: makeUpdateEvaluationStatus(),
});

interface LinkDispatchProps {
  onUpdateOngoingEvaluation: () => void;
  onUpdateFinishedEvaluation: () => void;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): LinkDispatchProps => ({
  onUpdateOngoingEvaluation: bindActionCreators(
    updateOngoingEvaluation,
    dispatch
  ),
  onUpdateFinishedEvaluation: bindActionCreators(
    updateFinishedEvaluation,
    dispatch
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Dashboard))
);
