import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ProTable } from "@ant-design/pro-components";
import { ConfigProvider } from "antd";
import api_address from "../../../constants/config";
import { ADMIN_COOKIE_NAME, LIMIT } from "../../../constants/admin";
import { GET_DATE_TIME_STRING_FROM_TIMESTAMP } from "../../../util/common";
import { JS_COOKIE } from "util/auth";
import { Container, AdminTableContainer } from "../styles";
import enUS from "antd/es/locale/en_US";
import NotificationModal from "../../common/NotificationModal";

interface FeedbackType {
  id: string;
  userid: string;
  score: string;
  resultId: string;
  product: string;
  maxscore: string;
  comment: string;
  createdAt: string;
}

interface SorterState {
  field: string;
  order: "ascend" | "descend" | undefined;
}

const Feedback: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [notificationStatus, setNotificationStatus] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(25);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const getFeedbackData = async (params: {
    current?: number;
    pageSize?: number;
  }) => {
    setLoading(true);
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { data } = await axios.get(api_address + "api/admin/feedback", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          offset: ((params.current || 1) - 1) * (params.pageSize || LIMIT),
          limit: params.pageSize || LIMIT,
        },
      });
      setLoading(false);
      return {
        data: data.feedback,
        success: true,
        total: data.rowCount,
      };
    } catch (err) {
      console.error(err);
      setLoading(false);
      return {
        data: [],
        success: false,
        total: 0,
      };
    }
  };

  const handleTableChange = (
    pagination: { current: number; pageSize: number },
    filters: any,
    sorter: any
  ) => {
    setPageSize(pagination.pageSize);
    setCurrentPage(pagination.current);
    getFeedbackData({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const columns = [
    {
      title: t("admin.feedback.id"),
      dataIndex: "id",
      sorter: true,
    },
    {
      title: t("admin.user.id"),
      dataIndex: "userid",
      sorter: true,
      render: (text: string) => (
        <a onClick={() => history.push(`/admin/user/edit/${text}`)}>{text}</a>
      ),
    },
    {
      title: t("admin.result.id"),
      dataIndex: "resultId",
      sorter: true,
    },
    {
      title: t("admin.feedback.product"),
      dataIndex: "product",
      sorter: true,
      render: (text: string) => t(`admin.product.${text}`),
    },
    {
      title: t("admin.feedback.score"),
      dataIndex: "score",
      sorter: true,
      render: (text: string, record: FeedbackType) =>
        `${text} / ${record.maxscore}`,
    },
    {
      title: t("admin.feedback.comment"),
      dataIndex: "comment",
      ellipsis: true,
    },
    {
      title: t("admin.user.createdAt"),
      dataIndex: "createdAt",
      sorter: true,
      render: (text: string) => GET_DATE_TIME_STRING_FROM_TIMESTAMP(text),
    },
  ];

  return (
    <ConfigProvider locale={enUS}>
      <Container>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />
        <AdminTableContainer>
          <ProTable<FeedbackType>
            columns={columns}
            request={getFeedbackData}
            rowKey="id"
            pagination={{
              showQuickJumper: true,
              pageSize: pageSize,
              current: currentPage,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "25", "50", "100"],
            }}
            search={false}
            dateFormatter="string"
            headerTitle={t("admin.feedback.title")}
            toolBarRender={false}
            onChange={handleTableChange}
          />
        </AdminTableContainer>
      </Container>
    </ConfigProvider>
  );
};

export default Feedback;
