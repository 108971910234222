import React from "react";
import {
  LockedContainer,
  UnlockedContainer,
  Header,
  ContentContainer,
  Content,
  Arrow,
  CollapsibleSegment,
} from "./styles";

interface Props {
  header: JSX.Element;
  content: JSX.Element;
  isLock?: boolean;
  defaultOpen?: boolean;
}

interface States {
  isOpen: boolean;
}

class CollapsibleContent extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: props.defaultOpen || false,
    };
  }

  toggling = (): void => {
    this.setState((states) => ({
      isOpen: !states.isOpen,
    }));
  };

  render() {
    const { header, content, isLock } = this.props;
    const { isOpen } = this.state;
    const Container = isLock ? LockedContainer : UnlockedContainer;
    return (
      <CollapsibleSegment onClick={this.toggling}>
        <Container>
          <Header isOpen={isOpen}>
            {header}
            <Arrow className="angle down" isOpen={isOpen} />
          </Header>
          <ContentContainer isOpen={isOpen}>
            <Content>{content}</Content>
          </ContentContainer>
        </Container>
      </CollapsibleSegment>
    );
  }
}

export default CollapsibleContent;
