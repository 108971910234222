import countryCodesList from "country-codes-list";

const sortOn = (arr: any[], prop: string) => {
  arr.sort(function (a, b) {
    if (a[prop] < b[prop]) {
      return -1;
    } else if (a[prop] > b[prop]) {
      return 1;
    } else {
      return 0;
    }
  });
};

interface countryCodeOptionType {
  key: string;
  text: string;
  value: string;
}

export const CountryCodeOptions = () => {
  const callingCodes = countryCodesList.customList(
    "countryCode",
    "+{countryCallingCode}"
  );
  const callingCodesText = countryCodesList.customList(
    "countryCode",
    "+{countryCallingCode} {countryNameEn}"
  );
  const countryNames = countryCodesList.customList(
    "countryCode",
    "{countryNameEn}"
  );

  let countryCallingCodesOptions = [] as Array<countryCodeOptionType>;

  //initiate countryCallingCodesOptions with all flags
  for (var key of Object.keys(callingCodes)) {
    if (callingCodes[key] === "+") continue;
    var listItem = {
      key: countryNames[key],
      text: callingCodesText[key],
      value: callingCodes[key],
    };
    countryCallingCodesOptions.push(listItem);
  }

  sortOn(countryCallingCodesOptions, "key");

  return countryCallingCodesOptions;
};
