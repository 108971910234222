import React, { Component } from "react";
import {
  FacebookLogo,
  qrcode,
  WeChat,
  Line,
} from "./assets/aws/QuickScreenerImageMapping";
import { withTranslation } from "react-i18next";
import i18n from "i18next";

class Footer extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <footer>
          {t("quickScreener.index.customerservice")}
          <br />
          {i18n.language === "zh-CN" ? (
            <a
              href="https://work.weixin.qq.com/u/vc38bf55d0c6882fff?v=4.0.1.81217"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                style={{
                  width: "60px",
                  height: "auto",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                src={WeChat}
                alt={"WeChatLogo"}
              ></img>
            </a>
          ) : (
            <div style={{ marginTop: "10px", paddingBottom: "10px" }}>
              <a
                href="https://lin.ee/5TuAhDA"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    width: "60px",
                    height: "auto",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  src={Line}
                  alt={"LineLogo"}
                ></img>
              </a>
            </div>
          )}
        </footer>
      </div>
    );
  }
}

export default withTranslation()(Footer);
