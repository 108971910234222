import { Suspense, useEffect, useState, useCallback } from "react";
import { BrowserRouter } from "react-router-dom";
import { Route, Switch } from "react-router";
import { RouteContainer } from "styles";
import { ApolloProvider } from "@apollo/client";
import { client } from "./graphql/ApolloClient";
import PublicRoute from "components/routes/PublicRoute";
import PrivateRoute from "components/routes/PrivateRoute";
import { JS_COOKIE } from "util/auth";
import { ADMIN_COOKIE_NAME } from "constants/admin";
import axios from "axios";
import api_address from "constants/config";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_SUCCESS, UPDATE_USER_TOKEN } from "components/auth/constants";
import BrowserCheckModal from "components/common/BrowserCheckModal";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { makeSelectRole } from "components/auth/selectors";

const App = () => {
  const dispatch = useDispatch();
  const role = useSelector(makeSelectRole());
  const [loading, setLoading] = useState(true);

  const updateUserData = useCallback(async () => {
    let token = JS_COOKIE.get("token");
    const path = window.location.pathname;
    if (path.includes("/admin")) {
      token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
    }
    if (token == null) {
      setLoading(false);
      return;
    }
    const res = await axios.get(api_address + "api/user/account", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { role } = res.data;
    dispatch({ type: LOGIN_SUCCESS, data: role });
    dispatch({ type: UPDATE_USER_TOKEN, data: token });
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    updateUserData();
  }, [updateUserData]);

  const loadingGraphic = (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
      className="preloader-wrapper active"
    >
      <div className="spinner-layer spinner-blue-only">
        <div className="circle-clipper left">
          <div className="circle"></div>
        </div>
        <div className="gap-patch">
          <div className="circle"></div>
        </div>
        <div className="circle-clipper right">
          <div className="circle"></div>
        </div>
      </div>
    </div>
  );

  if (loading) {
    return loadingGraphic;
  }

  return (
    <>
      <GoogleOAuthProvider clientId="439795220548-tj46dda251s0l8gs1chmdam49g6h34on.apps.googleusercontent.com">
        <BrowserCheckModal />
        <Suspense fallback={loadingGraphic}>
          <ApolloProvider client={client}>
            <BrowserRouter>
              <RouteContainer>
                <Switch>{role ? <PrivateRoute /> : <PublicRoute />}</Switch>
              </RouteContainer>
            </BrowserRouter>
          </ApolloProvider>
        </Suspense>
      </GoogleOAuthProvider>
    </>
  );
};

export default App;
