import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { withTranslation, WithTranslation } from "react-i18next";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  PageContainer,
  OptionRadio,
  ListContainer,
  LabelContainer,
  OptionText,
  ListOrdinal,
  DescriptionText,
  InstructionListContainer,
  OptionContainer,
  SmallSectionTitleContainer,
  MultiInputContainer,
  FormTitle,
} from "../styles";
import {
  REVIEW_AND_SUGGESTION_INDEX,
  REVIEW_AND_SUGGESTION_LISTS,
} from "../../../../constants/report";
import { reviewAndSuggestionType } from "../../../User/Evaluation/CorePro/data";
import TextArea from "../component/TextArea";
import FormSectionTitle from "../component/FormSectionTitle";
import QuestionTitle from "../component/QuestionTitle";
import { QuestionText } from "../component/style";
import FormSection from "../component/FormSection";
interface ReviewAndSuggestionFormProps {
  reviewAndSuggestion: reviewAndSuggestionType;
  setState: (states: object) => void;
}

interface ReviewAndSuggestionState {}

type State = ReviewAndSuggestionState;

type Props = ReviewAndSuggestionFormProps &
  WithTranslation &
  RouteComponentProps;

class ReviewAndSuggestionForm extends Component<Props, State> {
  private currentLanguageDevelopmentRef;
  private canNotUnderstand;
  private canNotExpressOrSpeak;
  private canUnderstand;
  private canExpressOrSpeak;
  private cognitiveConceptsObserved;
  private comprehensionReviewRef;
  private homeActivityRef;
  private nextSuggestionRef;
  constructor(props: Props) {
    super(props);
    this.currentLanguageDevelopmentRef = React.createRef();
    this.canNotUnderstand = React.createRef();
    this.canNotExpressOrSpeak = React.createRef();
    this.canUnderstand = React.createRef();
    this.canExpressOrSpeak = React.createRef();
    this.cognitiveConceptsObserved = React.createRef();
    this.comprehensionReviewRef = React.createRef();
    this.homeActivityRef = React.createRef();
    this.nextSuggestionRef = React.createRef();
  }

  componentDidMount = () => {
    window.scrollTo({ top: 0 });
  };

  handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;
    const { setState } = this.props;
    setState({ [name]: value });
  };

  handleSuggestionsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { setState, reviewAndSuggestion } = this.props;
    const value = +e.currentTarget.value;
    let { selectedSuggestions, otherSuggestion } = reviewAndSuggestion;
    const index = selectedSuggestions.indexOf(value);
    const isUncheck = index > -1;
    if (isUncheck) {
      selectedSuggestions = selectedSuggestions.filter(
        (item) => item !== value
      );
      const status = getSuggestionNameByStatus(reviewAndSuggestion);
      const isOther = index === REVIEW_AND_SUGGESTION_INDEX[status];
      if (isOther) {
        otherSuggestion = "";
      }
    } else {
      selectedSuggestions = selectedSuggestions.concat([value]);
    }
    setState({
      selectedSuggestions: selectedSuggestions,
      otherSuggestion: otherSuggestion,
    });
  };

  getReviewAndSuggestionInstruction = (): JSX.Element => {
    const { t, reviewAndSuggestion } = this.props;
    const { languageDevelopmentList } = REVIEW_AND_SUGGESTION_LISTS;
    return (
      <>
        <SmallSectionTitleContainer backgroundColor={"#6898e3"}>
          {t(
            "therapist.reportGeneration.reviewAndSuggestion.reviewAndSuggestionInstruction.title"
          )}
        </SmallSectionTitleContainer>
        <FormSection topLeftBorderRadius={false} indicator={false} index={0}>
          <QuestionTitle>
            {t(
              "therapist.reportGeneration.reviewAndSuggestion.reviewAndSuggestionInstruction.instruction"
            )}
          </QuestionTitle>
          <MultiInputContainer>
            {languageDevelopmentList.map((component: string) => {
              return (
                <div>
                  <QuestionText>
                    {t(
                      `therapist.reportGeneration.reviewAndSuggestion.reviewAndSuggestionInstruction.${component}`
                    )}
                  </QuestionText>
                  <TextArea
                    _ref={this[component]}
                    id={component}
                    placeholder={t(
                      `therapist.reportGeneration.reviewAndSuggestion.reviewAndSuggestionPlaceHolder.${component}`
                    )}
                    name={component}
                    value={reviewAndSuggestion[component]}
                    handleInputChange={this.handleTextAreaChange}
                    isEmpty={false}
                  />
                </div>
              );
            })}
          </MultiInputContainer>
        </FormSection>
      </>
    );
  };

  getComprehensionReviewInstruction = (): JSX.Element => {
    const { t } = this.props;
    const { comprehensionReview } = this.props.reviewAndSuggestion;
    return (
      <>
        <SmallSectionTitleContainer backgroundColor={"#6898e3"}>
          {t(
            "therapist.reportGeneration.reviewAndSuggestion.comprehensionReviewInstruction.title"
          )}
        </SmallSectionTitleContainer>
        <FormSection topLeftBorderRadius={false} indicator={false} index={0}>
          <QuestionTitle>
            {t(
              "therapist.reportGeneration.reviewAndSuggestion.comprehensionReviewInstruction.instruction1"
            )}
          </QuestionTitle>
          <InstructionListContainer>
            <ListContainer>
              <ListOrdinal>1. </ListOrdinal>
              <DescriptionText>
                {t(
                  "therapist.reportGeneration.reviewAndSuggestion.comprehensionReviewInstruction.instruction2"
                )}
              </DescriptionText>
            </ListContainer>
            <ListContainer>
              <ListOrdinal>2. </ListOrdinal>
              <DescriptionText>
                {t(
                  "therapist.reportGeneration.reviewAndSuggestion.comprehensionReviewInstruction.instruction3"
                )}
              </DescriptionText>
            </ListContainer>
            <ListContainer>
              <ListOrdinal>3. </ListOrdinal>
              <DescriptionText>
                {t(
                  "therapist.reportGeneration.reviewAndSuggestion.comprehensionReviewInstruction.instruction4"
                )}
              </DescriptionText>
            </ListContainer>
            <ListContainer>
              <ListOrdinal>4. </ListOrdinal>
              <DescriptionText>
                {t(
                  "therapist.reportGeneration.reviewAndSuggestion.comprehensionReviewInstruction.instruction5"
                )}
                <br />
                {t(
                  "therapist.reportGeneration.reviewAndSuggestion.comprehensionReviewInstruction.instruction5pt1"
                )}
                <br />
                {t(
                  "therapist.reportGeneration.reviewAndSuggestion.comprehensionReviewInstruction.instruction5pt2"
                )}
                <br />
                {t(
                  "therapist.reportGeneration.reviewAndSuggestion.comprehensionReviewInstruction.instruction5pt3"
                )}
                <br />
                {t(
                  "therapist.reportGeneration.reviewAndSuggestion.comprehensionReviewInstruction.instruction5pt4"
                )}
              </DescriptionText>
            </ListContainer>
          </InstructionListContainer>
          <InstructionListContainer>
            <DescriptionText>
              {t(
                "therapist.reportGeneration.reviewAndSuggestion.comprehensionReviewInstruction.question1"
              )}
            </DescriptionText>
            <DescriptionText style={{ fontStyle: "italic" }}>
              {t(
                "therapist.reportGeneration.reviewAndSuggestion.comprehensionReviewInstruction.questionExample1"
              )}
            </DescriptionText>
            <DescriptionText style={{ fontStyle: "italic" }}>
              {t(
                "therapist.reportGeneration.reviewAndSuggestion.comprehensionReviewInstruction.questionExample2"
              )}
            </DescriptionText>
          </InstructionListContainer>
          <InstructionListContainer>
            <DescriptionText>
              {t(
                "therapist.reportGeneration.reviewAndSuggestion.comprehensionReviewInstruction.question2"
              )}
            </DescriptionText>
          </InstructionListContainer>
          <TextArea
            id="comprehensionReview"
            name="comprehensionReview"
            placeholder={""}
            value={comprehensionReview}
            handleInputChange={this.handleTextAreaChange}
            _ref={this.comprehensionReviewRef}
            isEmpty={comprehensionReview === ""}
          />
        </FormSection>
      </>
    );
  };

  getHomeActivity = (): JSX.Element => {
    const { t } = this.props;
    const { homeActivity } = this.props.reviewAndSuggestion;
    return (
      <>
        <SmallSectionTitleContainer backgroundColor={"#6898e3"}>
          {t(
            "therapist.reportGeneration.reviewAndSuggestion.homeActivity.title"
          )}
        </SmallSectionTitleContainer>
        <FormSection topLeftBorderRadius={false} indicator={false} index={0}>
          {/* <QuestionTitle>
            {t(
              "therapist.reportGeneration.reviewAndSuggestion.homeActivity.instruction"
            )}
          </QuestionTitle> */}
          <QuestionText>
            {t(
              "therapist.reportGeneration.reviewAndSuggestion.homeActivity.question"
            )}
          </QuestionText>
          <TextArea
            id="homeActivity"
            name="homeActivity"
            placeholder=""
            value={homeActivity}
            handleInputChange={this.handleTextAreaChange}
            _ref={this.homeActivityRef}
            isEmpty={homeActivity === ""}
          />
        </FormSection>
      </>
    );
  };

  getCurrentLanguageDevelopment = (): JSX.Element => {
    const { currentLanguageDevelopment } = this.props.reviewAndSuggestion;
    const { t } = this.props;
    const options = [
      t(
        `therapist.reportGeneration.reviewAndSuggestion.currentLanguageDevelopmentStatus.1`
      ),
      t(
        `therapist.reportGeneration.reviewAndSuggestion.currentLanguageDevelopmentStatus.2`
      ),
      t(
        `therapist.reportGeneration.reviewAndSuggestion.currentLanguageDevelopmentStatus.3`
      ),
      t(
        `therapist.reportGeneration.reviewAndSuggestion.currentLanguageDevelopmentStatus.4`
      ),
    ];
    return (
      <FormSection topLeftBorderRadius={true} indicator={false} index={0}>
        <QuestionTitle>
          {t(
            "therapist.reportGeneration.reviewAndSuggestion.currentLanguageDevelopment.instruction"
          )}
        </QuestionTitle>
        {/* <TextArea
          id="currentLanguageDevelopment"
          name="currentLanguageDevelopment"
          placeholder=""
          value={currentLanguageDevelopment}
          handleInputChange={this.handleTextAreaChange}
          _ref={this.currentLanguageDevelopmentRef}
          isEmpty={currentLanguageDevelopment === ""}
        /> */}
        {options.map((option: string, index: number) => {
          return (
            <OptionContainer>
              <LabelContainer key={"status " + index}>
                <OptionRadio
                  type="radio"
                  value={index + 1}
                  name="overallLanguageDevelopmentStatus"
                  onChange={() => {
                    this.handleRadioButtonClick(option);
                  }}
                  checked={currentLanguageDevelopment === option}
                />
                <OptionText>{option}</OptionText>
              </LabelContainer>
            </OptionContainer>
          );
        })}
      </FormSection>
    );
  };

  handleCurrentLanguageDevelopmentStatusChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { setState, t } = this.props;
    const options = [
      t(
        `therapist.reportGeneration.reviewAndSuggestion.currentLanguageDevelopmentStatus.1`
      ),
      t(
        `therapist.reportGeneration.reviewAndSuggestion.currentLanguageDevelopmentStatus.2`
      ),
      t(
        `therapist.reportGeneration.reviewAndSuggestion.currentLanguageDevelopmentStatus.3`
      ),
      t(
        `therapist.reportGeneration.reviewAndSuggestion.currentLanguageDevelopmentStatus.4`
      ),
    ];
    const { value } = e.currentTarget;
    const option = options[parseInt(value) - 1];
    setState({
      currentLanguageDevelopmentStatus: parseInt(value),
      currentLanguageDevelopment: option,
      selectedSuggestions: [],
    });
  };

  handleRadioButtonClick = (option) => {
    const { setState } = this.props;
    setState({ currentLanguageDevelopment: option });
  };

  getCurrentLanguageDevelopmentStatus = (): JSX.Element[] => {
    const { currentLanguageDevelopmentStatus } = this.props.reviewAndSuggestion;
    const { t } = this.props;
    const statusCount = REVIEW_AND_SUGGESTION_INDEX.development;
    let options = [] as string[];
    for (let i = 1; i <= statusCount; i++) {
      options.push(
        t(
          `therapist.reportGeneration.reviewAndSuggestion.currentLanguageDevelopmentStatus.${i}`
        )
      );
    }
    return options.map((label: string, index: number) => {
      return (
        <OptionContainer>
          <LabelContainer key={"status " + index}>
            <OptionRadio
              type="radio"
              value={index + 1}
              name="currentLanguageDevelopmentStatus"
              onChange={this.handleCurrentLanguageDevelopmentStatusChange}
              checked={currentLanguageDevelopmentStatus === index + 1}
            />
            <OptionText>{label}</OptionText>
          </LabelContainer>
        </OptionContainer>
      );
    });
  };

  getSuggestion = (): JSX.Element => {
    const { t, reviewAndSuggestion } = this.props;
    const { selectedSuggestions } = reviewAndSuggestion;
    const status = getSuggestionNameByStatus(reviewAndSuggestion);
    const optionCount = REVIEW_AND_SUGGESTION_INDEX[status];
    let options = [] as string[];
    for (let i = 1; i <= optionCount; i++) {
      options.push(
        t(
          `therapist.reportGeneration.reviewAndSuggestion.suggestion.${status}.${i}`
        )
      );
    }
    return (
      <div style={{ marginTop: "32px" }}>
        <QuestionTitle>
          {t(
            `therapist.reportGeneration.reviewAndSuggestion.suggestion.${status}.title`
          )}
        </QuestionTitle>
        {options.map((suggestion: string, index: number) => {
          return (
            <OptionContainer>
              <LabelContainer key={"suggestion-" + index}>
                <OptionRadio
                  type="checkbox"
                  value={index}
                  name="suggestion"
                  onChange={this.handleSuggestionsChange}
                  checked={selectedSuggestions.includes(index)}
                />
                <OptionText>{ReactHtmlParser(suggestion)}</OptionText>
              </LabelContainer>
            </OptionContainer>
          );
        })}
      </div>
    );
  };

  getNextSuggestion = (): JSX.Element => {
    const { t, reviewAndSuggestion } = this.props;
    const { currentLanguageDevelopmentStatus, otherSuggestion } =
      reviewAndSuggestion;
    const suggestion = this.getSuggestion();
    const showOtherSuggestions = isOtherSuggestionEnable(reviewAndSuggestion);
    return (
      <>
        <SmallSectionTitleContainer backgroundColor={"#6898e3"}>
          {t("therapist.reportGeneration.reviewAndSuggestion.suggestion.title")}
        </SmallSectionTitleContainer>
        <FormSection topLeftBorderRadius={false} indicator={false} index={0}>
          <QuestionTitle>
            {t(
              "therapist.reportGeneration.reviewAndSuggestion.suggestion.instruction"
            )}
          </QuestionTitle>
          <QuestionTitle>
            {t(
              "therapist.reportGeneration.reviewAndSuggestion.suggestion.question"
            )}
          </QuestionTitle>
          {this.getCurrentLanguageDevelopmentStatus()}
          {currentLanguageDevelopmentStatus !== 0 && suggestion}
          {showOtherSuggestions ? (
            <TextArea
              id="otherSuggestion"
              name="otherSuggestion"
              placeholder=""
              value={otherSuggestion}
              handleInputChange={this.handleTextAreaChange}
              _ref={this.nextSuggestionRef}
              isEmpty={otherSuggestion === ""}
            />
          ) : undefined}
        </FormSection>
      </>
    );
  };

  render = () => {
    const { t } = this.props;
    return (
      <PageContainer>
        <FormSectionTitle>
          <FormTitle>
            {t("therapist.reportGeneration.reviewAndSuggestion.title")}
          </FormTitle>
        </FormSectionTitle>
        {this.getReviewAndSuggestionInstruction()}
        {this.getComprehensionReviewInstruction()}
        {this.getHomeActivity()}
        {this.getNextSuggestion()}
      </PageContainer>
    );
  };
}

function getSuggestionNameByStatus(
  reviewAndSuggestion: reviewAndSuggestionType
): string {
  const { currentLanguageDevelopmentStatus } = reviewAndSuggestion;
  if (currentLanguageDevelopmentStatus === 1) {
    return "normalSuggestion";
  } else if (currentLanguageDevelopmentStatus === 2) {
    return "borderSuggestion";
  } else if (currentLanguageDevelopmentStatus === 3) {
    return "mildToModerateSuggestion";
  } else if (currentLanguageDevelopmentStatus === 4) {
    return "severeSuggestion";
  } else {
    return "";
  }
}

function isOtherSuggestionEnable(
  reviewAndSuggestion: reviewAndSuggestionType
): boolean {
  const { selectedSuggestions } = reviewAndSuggestion;
  const status = getSuggestionNameByStatus(reviewAndSuggestion);
  const otherIndex = REVIEW_AND_SUGGESTION_INDEX[status] - 1;
  const containOther = selectedSuggestions.includes(otherIndex);
  return containOther;
}

export function isReviewAndSuggestionNextDisabled(
  reviewAndSuggestion: reviewAndSuggestionType
): boolean {
  const {
    canNotUnderstand,
    canNotExpressOrSpeak,
    canUnderstand,
    canExpressOrSpeak,
    cognitiveConceptsObserved,
    comprehensionReview,
    homeActivity,
    currentLanguageDevelopment,
    currentLanguageDevelopmentStatus,
    selectedSuggestions,
    otherSuggestion,
  } = reviewAndSuggestion;
  const enableOtherSuggestion = isOtherSuggestionEnable(reviewAndSuggestion);
  const currentLanguageDevelopmentInvalid = currentLanguageDevelopment === "";
  const reviewAndSuggestionInvalid =
    canNotUnderstand === "" ||
    canNotExpressOrSpeak === "" ||
    canUnderstand === "" ||
    canExpressOrSpeak === "" ||
    cognitiveConceptsObserved === "";
  const comprehensionReviewInvalid = comprehensionReview === "";
  const homeActivityInvalid = homeActivity === "";
  const nextSuggestionInvalid =
    currentLanguageDevelopmentStatus === 0 ||
    selectedSuggestions.length === 0 ||
    (enableOtherSuggestion && otherSuggestion === "");

  return (
    currentLanguageDevelopmentInvalid ||
    reviewAndSuggestionInvalid ||
    comprehensionReviewInvalid ||
    homeActivityInvalid ||
    nextSuggestionInvalid
  );
}

export default withRouter(withTranslation()(ReviewAndSuggestionForm));
