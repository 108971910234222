import styled from "styled-components";

export const ResetPasswordButton = styled.div`
  width: 300px;
  height: 50px;
  background-color: #f9fff6;
  border: solid 1px #a8bf96;
  color: #000000;
  font-size: 30px;
  line-height: 40px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
`;

export const RestLink = styled.div`
  display: grid;
  place-items: center;
  font-size: 16px;
  border: solid 1px black;
  border-radius: 4px;
  padding: 0 5px;
`;

export const Title = styled.h1``;

export const Table = styled.div`
  width: 100%;
  border: solid 1px black;
  border-radius: 4px;
`;

const R = styled.div`
  height: 50px;
  width: 100%;
  border-bottom: solid 1px black;
  display: grid;
`;

const textWrapTable = styled.div`
  height: fit-content;
  width: 100%;
  border-bottom: solid 1px black;
  display: grid;
`;
const Result = styled(textWrapTable)`
  grid-template-columns: 1.5fr 3fr 1fr 1fr 1fr 1fr;
`;

const Payment = styled(textWrapTable)`
  grid-template-columns: 1.5fr 3fr 0.75fr 1.2fr 0.75fr 0.9fr 0.9fr;
`;

const Assessment = styled(textWrapTable)`
  grid-template-columns: 0.4fr 3fr 3fr 2fr 1.5fr 1fr;
`;

export const ResultHeader = styled(Result)``;

export const ResultRow = styled(Result)`
  cursor: pointer;
  :last-child {
    border: none;
  }
`;

export const AssessmentHeader = styled(Assessment)``;
export const AssessmentRow = styled(Assessment)`
  cursor: pointer;
  :last-child {
    border: none;
  }
`;

export const PaymentHeader = styled(Payment)``;

export const PaymentRow = styled(Payment)`
  cursor: pointer;
  :last-child {
    border: none;
  }
`;

export const Item = styled.div`
  line-height: 2;
  word-break: break-all;
  padding-left: 20px;
`;

export const TimeItem = styled.div`
  padding-left: 20px;
  line-height: 25px;
  white-space: pre-line;
`;
