import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "../QuickScreener/styles/style.css";
import {
  TopBar,
  TopBarContent,
  LandingBody,
  QuestionnaireBodyBox,
  QuestionnaireBodyBoxTopContent,
  QuestionnaireBodyBoxBottomContent,
  PreviousButton,
  Centralise,
} from "../QuickScreener/styles/Theme";
import configStore from "../QuickScreener/store";
import {
  Grid,
  Progress,
  Dimmer,
  Header,
  Message,
  Button,
  Loader,
  Sidebar,
  SidebarPusher,
  Menu,
  Icon,
} from "semantic-ui-react";
import RadioGroup from "../QuickScreener/components/radio-web";
import { fetchQuestionnaires } from "../QuickScreener/actions/questionnaires";
import { isBrowser } from "react-device-detect";
import { WithTranslation, withTranslation } from "react-i18next";
import { DesktopOrLaptop, TabletOrMobile } from "../QuickScreener/MediaQuery";
import { Dropdown } from "semantic-ui-react";
import i18n from "i18next";
import Footer from "./Footer";
import Question from "components/User/Evaluation/Core/Questionnaire/Question";

interface RouteParams {
  questionnaireId: string;
  sexSel: string;
  dateSel: string;
  transactionId: string;
  agentId?: string;
}

interface QuestionnaireProps
  extends RouteComponentProps<RouteParams>,
    WithTranslation {
  // ... other props
}

interface QuestionnaireState {
  questionnaireId: number;
  currentIndex: number;
  sexSel: string;
  dateSel: string;
  scoreList: number[];
  selectedIds: number[];
  questions: any; // Define this type based on your question structure
  active: boolean;
  loadingMessage: boolean;
  visible: boolean;
  transactionId: string;
  agentId: string;
}

class Questionnaire extends Component<QuestionnaireProps, QuestionnaireState> {
  constructor(props: QuestionnaireProps) {
    super(props);
    this.state = {
      questionnaireId: 0,
      currentIndex: 0,
      sexSel: "",
      dateSel: "",
      scoreList: [],
      selectedIds: [],
      questions: [],
      active: true,
      loadingMessage: true,
      visible: false,
      transactionId: "",
      agentId: "",
    };
    // Other initializations...
  }

  componentDidMount() {
    document.body.style.backgroundColor = "#f5b5b5";
    const { transactionId, questionnaireId, sexSel, dateSel, agentId } =
      this.props.match.params;
    console.log(this.props, "debug questionnaire");
    this.getQuestions(parseInt(questionnaireId));

    // window.addEventListener("scroll", preventMotion, false);
    // window.addEventListener("touchmove", preventMotion, false);

    // function preventMotion(event) {
    //   window.scrollTo(0, 0);
    //   event.preventDefault();
    //   event.stopPropagation();
    // }

    this.setState({
      questionnaireId: parseInt(questionnaireId),
      sexSel: sexSel,
      dateSel: dateSel,
      transactionId: transactionId,
      agentId: agentId ?? "",
    });
  }

  componentWillUnmount() {
    // Reset the background color when the component unmounts
    document.body.style.backgroundColor = "initial";
  }

  handleMobileChange(lang) {
    if (lang === "简体中文 (Chinese - Simplified)") {
      i18n.changeLanguage("zh-CN");
      this.handleVisible(false);
    } else if (lang === "繁體中文 (Chinese - Traditional)") {
      i18n.changeLanguage("zh-TW");
      this.handleVisible(false);
    }
  }

  handleVisible(boolean) {
    this.setState({ visible: boolean });
  }

  codeToText(languageCode) {
    if (languageCode === "zh-CN") {
      return "简体中文 (Chinese - Simplified)";
    } else if (languageCode === "zh-TW") {
      return "繁體中文 (Chinese - Traditional)";
    }
    return;
  }

  handleChange(event) {
    const lang = event.currentTarget.textContent;

    if (lang === "简体中文 (Chinese - Simplified)") {
      i18n.changeLanguage("zh-CN");
    } else if (lang === "繁體中文 (Chinese - Traditional)") {
      i18n.changeLanguage("zh-TW");
    }
  }

  handleOpen = () => this.setState({ active: true });
  handleClose = () => this.setState({ active: false });

  getSum = (list) => {
    return list.reduce(function (acc, val) {
      return acc + val;
    }, 0);
  };

  getQuestions = (questionnaireId) => {
    const store = configStore();
    console.log(questionnaireId, "getQuestions");
    return store.dispatch(fetchQuestionnaires()).then(() => {
      const questionnaires = store.getState().questionnaires.list;
      const questionnaire =
        questionnaires.find((item) => item.id === questionnaireId) || {};

      if (questionnaire.length < 1) {
        this.setState({ active: false });
        this.setState({ loadingMessage: false });
      }
      this.setState({ questions: questionnaire.questions });
      this.setState({ active: false });
    });
  };

  handleRadioSelect = (options, selectedKey) => {
    const {
      currentIndex,
      questions,
      selectedIds,
      questionnaireId,
      scoreList,
      sexSel,
      dateSel,
      transactionId,
      agentId,
    } = this.state;
    const scoreGain = options[selectedKey - 1].score; // the score gained from the current question
    const question = questions[currentIndex] || {};
    console.log("asdasdasdsadsadas", agentId);
    if (question.single) {
      if (currentIndex === questions.length - 1) {
        var finalScore = this.getSum(scoreList);
        finalScore = finalScore + scoreGain;
        this.props.history.push(
          `/quick-screener/results/${questionnaireId}/${finalScore}/${sexSel}/${dateSel}/${transactionId}/${agentId}`
        );
      } else {
        scoreList.push(scoreGain);
        if (selectedIds.length <= currentIndex) {
          selectedIds.push(selectedKey);
        } else {
          selectedIds[currentIndex] = selectedKey;
        }

        setTimeout(
          () =>
            this.setState({
              currentIndex: currentIndex + 1,
              scoreList: scoreList,
              selectedIds: selectedIds,
            }),
          200
        );
      }
    }
  };

  handlePrev = () => {
    const { currentIndex, scoreList } = this.state;
    if (currentIndex === 0) {
      return;
    }
    scoreList.pop();
    this.setState({
      currentIndex: currentIndex - 1,
      scoreList: scoreList,
    });
  };

  render() {
    const { t } = this.props;
    const languageOptions = [
      {
        key: "繁體中文 (Chinese - Traditional)",
        text: "繁體中文 (Chinese - Traditional)",
        value: "繁體中文 (Chinese - Traditional)",
      },
      {
        key: "简体中文 (Chinese - Simplified)",
        text: "简体中文 (Chinese - Simplified)",
        value: "简体中文 (Chinese - Simplified)",
      },
    ];

    const active = this.state.active;
    const loadingMessage = this.state.loadingMessage;
    const { currentIndex, questions, selectedIds } = this.state;
    const question = questions[currentIndex] || {};
    var { title, options, image } = question;
    const radioOptions = (options || []).map((item) => ({
      ...item,
      key: item.id,
      index: item.key,
      score: item.score,
    }));

    // would be better to split question texts, especially for id 13 quesiton 4
    var newText;
    if (title) {
      newText = t(title)
        .split("\n")
        .map((item, i) => {
          return (
            <div key={i} className="title-break" style={{ lineHeight: "2" }}>
              {item}
            </div>
          );
        });
    }
    var display;

    if (image) {
      display = (
        <div className="questionnaire__image">
          <img style={{ width: "100%" }} src={image} alt="questionimage" />
        </div>
      );
    } else {
      display = null;
    }
    const percent = Math.round((currentIndex / questions.length) * 100);
    const currentNumber = currentIndex + 1;
    const totalNumber = questions.length;

    return (
      <html className="questionnaire">
        <div>
          <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            inverted
            onHide={() => this.handleVisible(false)}
            vertical
            visible={this.state.visible}
            width="thin"
            style={{ backgroundColor: "white" }}
          >
            <br />
            <a
              className="waves-effect waves-light btn-medium teal-text text-accent-4"
              onClick={() => this.handleVisible(false)}
            >
              <Icon name="angle double left" />
              <b>Hide</b>
            </a>
            <br />
            <br />
            <Menu.Item
              as="a"
              style={{ color: "black" }}
              onClick={() =>
                this.handleMobileChange("繁體中文 (Chinese - Traditional)")
              }
            >
              繁體中文 (Chinese - Traditional)
            </Menu.Item>

            <Menu.Item
              as="a"
              style={{ color: "black" }}
              onClick={() =>
                this.handleMobileChange("简体中文 (Chinese - Simplified)")
              }
            >
              简体中文 (Chinese - Simplified)
            </Menu.Item>
          </Sidebar>
          <Dimmer active={active} onClickOutside={this.handleClose} page>
            <Header as="h2" icon inverted>
              {!loadingMessage && (
                <Message warning size="small">
                  <Message.Header>
                    {t("quickScreener.questionnaire.warning")}
                  </Message.Header>
                  <p>{t("quickScreener.questionnaire.message")}</p>
                  <Button onClick={() => this.props.history.push("/")}>
                    {t("quickScreener.questionnaire.understand")}
                  </Button>
                </Message>
              )}
              {loadingMessage && <Loader />}
            </Header>
          </Dimmer>
          <SidebarPusher>
            <TopBar page="questionnaire">
              <DesktopOrLaptop>
                <Dropdown
                  button
                  className="icon left"
                  labeled
                  icon="world"
                  options={languageOptions}
                  text={this.codeToText(this.props.i18n.language)}
                  onChange={this.handleChange}
                  style={{
                    display: "float",
                    float: "left",
                    marginTop: "0.8rem",

                    marginLeft: "2rem",
                  }}
                />
              </DesktopOrLaptop>
              <TabletOrMobile>
                <div className="sideMenu"></div>
                <Icon
                  style={{
                    display: "float",
                    float: "left",
                    marginTop: "2rem",
                    paddingLeft: "2rem",
                    cursor: "pointer",
                    paddingBottom: "0.5rem",
                  }}
                  onClick={() => this.handleVisible(true)}
                  name="globe"
                  color="black"
                  size="big"
                />
              </TabletOrMobile>
              <TopBarContent>
                <p
                  style={{
                    // display: "inline-block",
                    // marginRight: isBrowser ? "22rem" : "1rem",
                    // marginTop: isBrowser ? undefined : "0.2rem",
                    display: "inline-block",
                    paddingTop: "1rem",
                    fontSize: "2.3rem",
                    ...(isBrowser
                      ? {
                          marginRight: "22rem",
                        }
                      : {
                          marginRight: "1rem",
                        }),
                  }}
                >
                  {t("quickScreener.questionnaire.topbar")}
                </p>
              </TopBarContent>
            </TopBar>
            <LandingBody page="pink">
              <Grid>
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column width={isBrowser ? 5 : 1}></Grid.Column>
                  <Grid.Column width={isBrowser ? 6 : 14}>
                    <p
                      style={{
                        marginTop: "15px",
                        color: "white",
                        fontSize: "14pt",
                      }}
                    >
                      第{currentNumber}
                      {t("quickScreener.questionnaire.question")}（共
                      {totalNumber}
                      {t("quickScreener.questionnaire.question")}）
                    </p>
                    <Progress percent={percent} />
                    <QuestionnaireBodyBox>
                      <QuestionnaireBodyBoxTopContent>
                        {newText}
                        {display}
                      </QuestionnaireBodyBoxTopContent>
                      <QuestionnaireBodyBoxBottomContent>
                        <RadioGroup
                          options={radioOptions}
                          selectedKey={selectedIds[currentIndex]}
                          onSelect={this.handleRadioSelect.bind(this, options)}
                        />
                        {currentIndex > 0 ? (
                          <Centralise>
                            <PreviousButton onClick={this.handlePrev}>
                              {t("quickScreener.questionnaire.previous")}
                            </PreviousButton>
                          </Centralise>
                        ) : (
                          <div style={{ height: "45px" }}></div>
                        )}
                      </QuestionnaireBodyBoxBottomContent>
                    </QuestionnaireBodyBox>
                  </Grid.Column>
                  <Grid.Column
                    width={isBrowser ? 5 : 1}
                    style={isBrowser ? null : { padding: 0 }}
                  ></Grid.Column>
                </Grid.Row>
              </Grid>
            </LandingBody>
          </SidebarPusher>
          <Footer />
        </div>
      </html>
    );
  }
}

export default withRouter(withTranslation()(Questionnaire));
