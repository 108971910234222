import { useTranslation } from "react-i18next";
import { ResultListSegment } from "./styles";
import {
  FlexBetweenContainer,
  GreyInfoText,
  InfoContainer,
  InfoRow,
  LabelText,
  ProductName,
  RowContainer,
  ValueText,
} from "../../../ProfileRoute/PurchasesHistory/styles";
import AWS from "aws-sdk";

type Props = {
  id: number;
  name: string;
  createdAt: string;
  evaluationName: string;
  evaluationDate: string;
  evaluationAgency: string;
  uuid: string;
};

export const AWS_USER_ASSESSMENTS_FOLDER_PATH = `user_assessments/`;

export const handleOpenPDFFromAWS = async ({
  folderName,
  fileName,
}: {
  folderName: string;
  fileName: string;
}) => {
  try {
    const s3 = new AWS.S3({
      region: process.env.REACT_APP_AWS_UPLOADER_S3_REGION,
    });

    const url = await s3.getSignedUrlPromise("getObject", {
      Bucket: process.env.REACT_APP_AWS_UPLOADER_S3_BUCKET as string,
      Key: `${folderName}/${fileName}`,
    });
    window.open(url, "_blank");
  } catch (error) {
    console.error("Error generating signed URL:", error);
  }
};

export default function UserAssessment(props: Props) {
  const { t } = useTranslation();
  const {
    id,
    name,
    evaluationName,
    evaluationDate,
    evaluationAgency,
    createdAt,
    uuid,
  } = props;

  const createdAtForDisplay = createdAt.substring(0, 10);
  const evaluationDateForDisplay = evaluationDate.substring(0, 10);
  return (
    <ResultListSegment
      onClick={() =>
        handleOpenPDFFromAWS({
          folderName: `${AWS_USER_ASSESSMENTS_FOLDER_PATH + uuid}`,
          fileName: name,
        })
      }
    >
      <FlexBetweenContainer>
        <ProductName>{evaluationName + " (ID: " + id + ")"}</ProductName>
        <GreyInfoText>{createdAtForDisplay}</GreyInfoText>
      </FlexBetweenContainer>
      <FlexBetweenContainer>
        <InfoContainer>
          <RowContainer>
            <InfoRow>
              <LabelText>
                {t(`user.survey.history.assessment.uploadedFileName`)}
              </LabelText>
              <ValueText>{name}</ValueText>
            </InfoRow>
            <InfoRow>
              <LabelText>
                {t(`user.survey.history.assessment.evaluationAgency`)}
              </LabelText>
              <ValueText>{evaluationAgency}</ValueText>
            </InfoRow>
            <InfoRow>
              <LabelText>
                {t(`user.survey.history.assessment.evaluationDate`)}
              </LabelText>
              <ValueText>{evaluationDateForDisplay}</ValueText>
            </InfoRow>
          </RowContainer>
        </InfoContainer>
      </FlexBetweenContainer>
    </ResultListSegment>
  );
}
// Styled components for row layout
