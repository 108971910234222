import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  Container,
  ModalContent,
  Header,
  SubmitButton,
  Content,
  Comment,
  ButtonContainer,
} from "./style";

interface CommentModalProps {
  title?: string;
  show: boolean;
  onCommentChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSubmit: () => void;
  handleClose: () => void;
}

interface States {}

type Props = CommentModalProps & WithTranslation;

class CommentModal extends React.Component<Props, States> {
  render() {
    const { show, title, handleSubmit, handleClose, onCommentChange } =
      this.props;
    return (
      <Container id="ModalContainer" show={show}>
        <ModalContent id="ModalContent">
          <Header>{title || "Comment"}</Header>
          <Content>
            <Comment onChange={onCommentChange}></Comment>
            <ButtonContainer>
              <SubmitButton onClick={handleClose}>Close</SubmitButton>
              <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
            </ButtonContainer>
          </Content>
        </ModalContent>
      </Container>
    );
  }
}

export default withTranslation()(CommentModal);
