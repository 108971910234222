import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import { CategoryIcon } from "./style";
import comprehension from "../../User/Evaluation/Core/CoreReport/assets/comprehension.png";
import expression from "../../User/Evaluation/Core/CoreReport/assets/expression.png";
import oral from "../../User/Evaluation/Core/CoreReport/assets/oral.png";
import social from "../../User/Evaluation/Core/CoreReport/assets/social.png";
import preliteracy from "../../User/Evaluation/Core/CoreReport/assets/preliteracy.png";
import {
  CloseButtonContainer,
  ModalContainer,
  ModalContainerWithClose,
  ModalContent,
  ModalHeader,
  ModalMask,
  HeaderTitle,
} from "styles";
import { List, UnOrderList } from "components/User/Evaluation/styles";
interface DefinitionModalProps {
  show: boolean;
  type: string;
  handleDefinitionClose: () => void;
}

interface States {}

type Props = DefinitionModalProps & WithTranslation;

class DefinitionModal extends React.Component<Props, States> {
  render() {
    const { show, t, type, handleDefinitionClose } = this.props;
    const imgSrc = (category: string) => {
      switch (category) {
        case "comprehension":
          return comprehension;
        case "expression":
          return expression;
        case "oral":
          return oral;
        case "social":
          return social;
        case "preliteracy":
          return preliteracy;
        default:
          return comprehension;
      }
    };
    return (
      <ModalMask id="ModalContainer" open={show}>
        <ModalContainerWithClose id="ModalContent">
          <CloseButtonContainer>
            <Icon
              className="close"
              size="large"
              link
              style={{ marginBottom: "10px", marginRight: "-4px" }}
              onClick={handleDefinitionClose}
            />
          </CloseButtonContainer>
          <ModalContainer>
            <ModalHeader style={{ display: "flex" }}>
              <CategoryIcon src={imgSrc(type)} />
              <HeaderTitle>
                {t(`utils.fiveCategories.${type}.title`)}
              </HeaderTitle>
            </ModalHeader>
            <ModalContent>
              {t(`utils.fiveCategories.${type}.content`)}
              <UnOrderList>
                <List>{t(`utils.fiveCategories.${type}.content-1`)}</List>
                <List>{t(`utils.fiveCategories.${type}.content-2`)}</List>
                <List>{t(`utils.fiveCategories.${type}.content-3`)}</List>
              </UnOrderList>
            </ModalContent>
          </ModalContainer>
        </ModalContainerWithClose>
      </ModalMask>
    );
  }
}

export default withTranslation()(DefinitionModal);
