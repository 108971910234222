import gql from "graphql-tag";
import { User } from "../types/User";

export const USER_WITH_RESET_PASSWORD_TOKEN_QUERY = gql`
  query UserWithResetPasswordToken($token: String!) {
    userWithResetPasswordToken(token: $token) {
      id
    }
  }
`;

export interface UserWithResetPasswordTokenData {
  userWithResetPasswordToken: User;
}

export interface UserWithResetPasswordTokenVariables {
  token: string;
}
