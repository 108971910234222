import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { History, LocationState } from "history";
import { match } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import api_address from "../../../constants/config";
import { FAQ } from "./FAQ";
import { Form } from "./Form";
import { CompleteMessage } from "./CompleteMessage";
import mixpanel from "mixpanel-browser";
import { GET_LANGUAGE } from "../../../util/common";
import NotificationModal from "../../common/NotificationModal";

interface ContactUsProps {
  history: History<LocationState>;
  match: match<Match>;
}

interface ContactUsState {
  [key: string]: boolean | string | Array<number>;
  phone: string;
  name: string;
  timeZone: string;
  countryCode: string;
  availableTimes: Array<number>;
  weChatId: string;
  showNotification: boolean;
  message: string;
  notificationStatus: boolean;
  formStatus: boolean;
}

interface Match {
  type: string;
}

type contactUsFrom = {
  type: string;
  phone: string;
  name: string;
  timeZone: string;
  countryCode: string;
  availableTimes: Array<number>;
  weChatId: string;
};

type Props = ContactUsProps & WithTranslation;

class ContactUs extends React.Component<Props, ContactUsState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      phone: "",
      name: "",
      timeZone: "",
      countryCode: "",
      availableTimes: [],
      weChatId: "",
      showNotification: false,
      message: "",
      notificationStatus: false,
      formStatus: false,
    } as ContactUsState;
  }

  validation = () => {
    const { phone, name, timeZone, countryCode, availableTimes, weChatId } =
      this.state;
    const { t } = this.props;
    const language = GET_LANGUAGE();
    let emptyInput = "";
    if (name === "") {
      emptyInput = "name";
    } else if (weChatId === "" && language === "zh-CN") {
      emptyInput = "weChatId";
    } else if (countryCode === "") {
      emptyInput = "countryCode";
    } else if (phone === "") {
      emptyInput = "phone";
    } else if (timeZone === "") {
      emptyInput = "timeZone";
    } else if (availableTimes.length === 0) {
      emptyInput = "availableTimes";
    }
    if (emptyInput !== "") {
      const element = document.getElementById(emptyInput) as HTMLElement;
      element.style.border = "solid red 1px";
      element.style.borderRadius = "4px";
      throw new Error(
        t("general.contactUs.error") + t(`general.contactUs.${emptyInput}`)
      );
    }
  };

  handleSubmit = async () => {
    try {
      this.validation();
      const token = Cookies.get("token");
      const { type } = this.props.match.params;
      const contactUsData: contactUsFrom = {
        type,
        ...this.state,
      };
      const res = await axios.post(
        api_address + "api/contactUs",
        contactUsData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { status, message } = res.data;
      if (!status) {
        throw new Error(message);
      } else {
        this.setState({
          formStatus: true,
        });
      }
    } catch (err: any) {
      console.error(err);
      this.showNotification(err.message, false);
    }
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    this.setState({ [name]: value });
  };

  handleCountryCodeChange = (value: string) => {
    if (value != null)
      this.setState({
        countryCode: value,
      });
  };

  handleTimezoneChange = (value: string) => {
    if (value != null)
      this.setState({
        timeZone: value,
      });
  };

  handleAvailableTimesChange = (id: number) => {
    const { availableTimes } = this.state;
    if (availableTimes.includes(id)) {
      const newAvailableTimes = availableTimes.filter(
        (time: number) => time !== id
      );
      this.setState({ availableTimes: [...newAvailableTimes] });
    } else {
      availableTimes.push(id);
      this.setState({ availableTimes: [...availableTimes] });
    }
  };

  onFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.currentTarget;
    mixpanel.track("Contact", {
      action: "focus on " + id,
    });
  };

  showNotification = (message: string, status: boolean) => {
    this.setState({
      showNotification: true,
      message: message,
      notificationStatus: status,
    });
    setTimeout(() => {
      this.setState({ showNotification: false });
    }, 3000);
  };

  render() {
    const { type } = this.props.match.params;
    const { showNotification, message, notificationStatus, formStatus } =
      this.state;
    return (
      <>
        <NotificationModal
          show={showNotification}
          message={message}
          status={notificationStatus}
        />
        {type === "Issues" && <FAQ />}
        {type === "Sales" && (
          <>
            {formStatus ? (
              <CompleteMessage />
            ) : (
              <Form type={type} {...this} {...this.state} />
            )}
          </>
        )}
      </>
    );
  }
}

export default withTranslation()(ContactUs);
