import questionnaireReducer from "./components/User/Evaluation/Core/utils/reducer";
import { userStatusReducer } from "./components/auth/reducer";
import { compose } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import {
  saveSaga,
  mostRecentResultSaga,
  updateSectionSaga,
  resultSaga,
} from "./components/User/Evaluation/Core/utils/sagas";
import { AppActions } from "types/actions";
import { combineReducers } from "redux-immutable";

export const rootReducer = combineReducers({
  auth: userStatusReducer,
  questionnaire: questionnaireReducer,
});

//AppState will be the single source of truth for all application state throughout our Redux Store
//ReturnType checks whatever is in the parameter and just grabs the return types of whatever is in the parameter
export type AppState = ReturnType<typeof rootReducer>;

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      sagaMiddleware,
      thunk as ThunkMiddleware<AppState, AppActions>
    )
  )
);

sagaMiddleware.run(saveSaga);
sagaMiddleware.run(mostRecentResultSaga);
sagaMiddleware.run(updateSectionSaga);
sagaMiddleware.run(resultSaga);
