import { Component, useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { TimerText } from "./styles";

const Timer = (props) => {
  const [time, setTime] = useState(0);
  const [timerOn, setTimerOn] = useState(false);

  // Takes in an arrow function and array, the arrow function will run as soon as the component is rendered. Putting timerOn means the function will run everytime timerOn changes state
  useEffect(() => {
    let interval;

    if (props.timerOn) {
      setTimerOn(true);
    } else {
      setTimerOn(false);
    }

    if (timerOn) {
      //setInterval is a built-in js function which takes in an arrow function and an int which is the number of milliseconds
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setTime(0);
    }

    //stops timer when user leaves the page
    return () => clearInterval(interval);
  }, [timerOn, props.timerOn]);

  return (
    <div>
      <TimerText display={props.timerOn ? "block" : "none"}>
        {("0" + Math.floor((time / 60) % 60).toString()).slice(-2)}:
        {("0" + Math.floor(time % 60).toString()).slice(-2)}
      </TimerText>
    </div>
  );
};

export default withTranslation()(Timer);
