import React, { Component } from "react";
import RadioItem from "./item";
import isEqual from "lodash/isEqual";
import "./index.css";
import { isBrowser } from "react-device-detect";

class RadioGroup extends Component {
  state = {
    data: [],
  };

  componentWillReceiveProps(nextProps) {
    const options = this.props.options || [];

    const nextOptions = nextProps.options || [];
    if (!isEqual(options, nextOptions)) {
      this.setState({
        data: nextOptions.map((op) => ({
          ...op,
          selected: false,
        })),
      });
    }
  }

  handleSelect = (item) => {
    const { data } = this.state;
    const newData = data.map((op) => {
      if (op.key === item.key) {
        return {
          ...op,
          selected: true,
        };
      }
      return {
        ...op,
        selected: false,
      };
    });
    this.setState({ data: newData });

    // call parent click callback
    const { onSelect } = this.props;
    const selectedKey =
      newData.filter((op) => op.selected).map((op) => op.key)[0] || {};
    onSelect(selectedKey);
  };

  render() {
    const { data } = this.state;
    const { selectedKey } = this.props;

    const radioItems = data.map((item) => (
      <RadioItem
        key={item.key}
        value={item.value}
        selected={selectedKey ? selectedKey === item.key : false} //item.selected
        onClick={this.handleSelect.bind(this, item)}
      />
    ));

    return (
      <div className={isBrowser ? "radiogroup" : "mobile"}>{radioItems}</div>
    );
  }
}

export default RadioGroup;
