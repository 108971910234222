import gql from "graphql-tag";
import { ReturnMessage } from "../types/ReturnMessage";
import { User } from "../types/User";

export const RESET_PASSWORD_BY_UUID_MUTATION = gql`
  mutation ResetPasswordByUuid($userUuid: String!, $newPassword: String!) {
    resetPasswordByUuid(userUuid: $userUuid, newPassword: $newPassword) {
      user {
        id
      }
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface ResetPasswordByUuidData {
  resetPasswordByUuid: {
    user?: User;
    returnMessage: ReturnMessage;
  };
}

export interface ResetPasswordByUuidVariables {
  userUuid: string;
  newPassword: string;
}
