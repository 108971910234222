import React, { useState, useEffect } from "react";
import { Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {
  Title,
  Container,
  InstructionText,
  ImgContainer,
  InstructionContentImg,
  AlertText,
  CentralisingDiv,
  ModalMask,
  LineArrowImgHolder,
  WCArrowImgHolder,
} from "./styles";

import lineArrow from "./LineArrow.png";
import WCArrow from "./WCArrow.png";

const BrowserCheckModal = () => {
  const { t } = useTranslation();
  const [openLineModal, setOpenLineModal] = useState<boolean>(false);
  const [openWCModal, setOpenWCModal] = useState<boolean>(false);
  //zh-TW, zh-CN, en-US, default is zh-TW
  const [modalTitle, setModalTitle] = useState(
    "為獲得最佳體驗，請切換至您的默認瀏覽器"
  );
  const [lineModalStep1, setlineModalStep1] = useState(
    "第 一 步：點擊螢幕右下角的 3 個點。"
  );
  const [WCModalStep1, setWCModalStep1] = useState(
    "第 一 步：點擊螢幕右上角的 3 個點。。"
  );
  const [modalStep2, setModalStep2] =
    useState("第二步：點擊「在默認瀏覽器中打開」。");
  const detectBrowser = () => {
    const ua = window.navigator.userAgent;
    if (ua) {
      if (/line/i.test(ua)) {
        setOpenLineModal(true);
      }
      if (/micromessenger/i.test(ua)) {
        setOpenWCModal(true);
      }
    }
  };

  useEffect(() => {
    detectBrowser();

    //Check browser language and change modal language based on that since we cannot access locale since they have no logged in yet
    var userLang = navigator.language;
    if (userLang == "zh-CN") {
      setModalTitle("为获得最佳体验，请切换至您的默认浏览器");
      setlineModalStep1("第 一 步：点击萤幕右下角的 3 个点。");
      setWCModalStep1("第 一 步：点击萤幕右上角的 3 个点。");
      setModalStep2("第二步：点击「在默认浏览器中打开」。");
    } else if (
      userLang == "en-US" ||
      userLang == "en-UK" ||
      userLang.includes("en")
    ) {
      setModalTitle(
        "For optimal experience, please switch to your default browser"
      );
      setlineModalStep1(
        "Step 1: Click the 3 dots on the bottom right of your screen."
      );
      setWCModalStep1(
        "Step 1: Click the 3 dots on the top right of your screen."
      );
      setModalStep2("Step 2: Click  “Open in your default browser”.");
    }
  }, []);

  return (
    <>
      {openLineModal && (
        <ModalMask open={true}>
          <Container
            style={{
              borderRadius: "15px",
              width: "80%",
              height: "max-content",
              textAlign: "center",
              overscrollBehavior: "contain",
            }}
          >
            <Title style={{ fontSize: "20px", color: "#6898E3" }}>
              {modalTitle}
            </Title>
            <InstructionText>{lineModalStep1}</InstructionText>
            <InstructionText>{modalStep2}</InstructionText>
          </Container>
          <LineArrowImgHolder src={lineArrow}></LineArrowImgHolder>
        </ModalMask>
      )}

      {openWCModal && (
        <ModalMask open={true}>
          <Container
            style={{
              borderRadius: "15px",
              width: "80%",
              height: "max-content",
              textAlign: "center",
              overscrollBehavior: "contain",
            }}
          >
            <Title style={{ fontSize: "20px", color: "#6898E3" }}>
              {modalTitle}
            </Title>
            <InstructionText>{WCModalStep1}</InstructionText>
            <InstructionText>{modalStep2}</InstructionText>
          </Container>
          <WCArrowImgHolder src={WCArrow}></WCArrowImgHolder>
        </ModalMask>
      )}
    </>
  );
};
export default BrowserCheckModal;
