import styled from "styled-components";
import { SmallSectionTitle } from "../styles";

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;

export const ContentText = styled(SmallSectionTitle)`
  min-width: max-content;
  margin-right: 15px;
`;
export const Dot = styled.div`
  border: dashed 1px #000000;
  width: 100%;
  margin-top: 10px;
`;
export const PageNumber = styled(SmallSectionTitle)`
  margin-left: 15px;
`;
