import React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

export const DropDownContainer = styled.div<{
  size?: "small" | "medium" | "large" | "medium-adminCreateTherapistPanel";
}>`
  margin-bottom: 20px;
  cursor: pointer;
  width: ${(props) => {
    const { size } = props;
    if (size === "small") {
      return "28%;";
    } else if (size === "medium-adminCreateTherapistPanel") {
      return "260px;";
    } else if (size === "medium") {
      return "60%;";
    } else if (size === "large") {
      return "100%;";
    } else {
      return "28%;";
    }
  }};
`;

export const DropDownHeader = styled.div`
  position: relative;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  :hover {
    border: solid 1px #6896e3;
  }
`;

export const HeaderText = styled.div<{
  margin?: string;
}>`
  ${(props) => props.margin}
`;

// prevent isOpen from eventually being passed directly as a dom attribute
type FilteredIconProps = { isOpen: boolean; className: string };
const FilteredIcon = ({ isOpen, className, ...props }: FilteredIconProps) => (
  <Icon className={className} {...props}></Icon>
);
export const Arrow = styled(FilteredIcon)`
  position: absolute;
  right: 10px;
  color: #909090;
  ${(props: { isOpen: boolean }) =>
    props.isOpen
      ? "transform: rotate(180deg); transition: transform .2s ease-in-out"
      : "transform: rotate(360deg); transition: transform .2s ease-in-out"};
`;

export const DropDownListContainer = styled.div`
  position: absolute;
  z-index: 100;
  margin-top: 10px;
`;

export const DropDownList = styled.ul`
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
  border-radius: 8px;
  margin: 0;
  height: 250px;
  min-width: 150px;
  background: white;
  overflow-y: scroll;
  box-sizing: border-box;
  list-style-type: none;
  text-align: left;
  padding: 10px 5px 10px 10px;
  ::-webkit-scrollbar {
    width: 5px;
    background: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    width: 5px;
    position: relative;
  }
  ::-webkit-scrollbar-thumb {
    // background: #6896e3;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0);
  }
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 16px;
  height: 40px;
  list-style-type: none;
  padding: 0 15px;
  &:hover {
    color: white;
    background: #6896e3;
  }
`;

export const Placeholder = styled.div`
  color: rgba(0, 0, 0, 0);
`;
