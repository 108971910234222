import React from "react";
import {
  withTranslation,
  WithTranslation,
  useTranslation,
} from "react-i18next";
import { History, LocationState } from "history";
import mixpanel from "mixpanel-browser";
import { getCountryCodeList } from "../../../../constants/countryCode";
import DropDown from "../../../common/DropDown";
import LanguageDropdown from "../../../general/LanguageDropdown";
import {
  AuthActionLink,
  AuthActionLinkContainer,
  AuthActionText,
  AuthContainer,
  FooterContainer,
  FormContainer,
  PhoneInputContainer,
  InputArea,
  PhoneInput,
  AuthButton,
  // LoginStatusButton,
  AuthTitle,
  AuthFooterText,
  AuthInputTitle,
  ContentContainer,
  ForgetLink,
  DivideContainer,
  DivideLine,
  OrText,
  // LoginStatusTitle,
  // PhoneImg,
  // EmailImg,
} from "../styles";
import { LanguageContainer } from "components/general/NavMenu/styles";
import { ActionTitle } from "../../../../styles";
import GoogleLoginComponent from "../GoogleLogin";

type emailLoginFormProps = {
  email: string;
  password: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

function EmailLoginForm(props: emailLoginFormProps) {
  const { t } = useTranslation();
  return (
    <div>
      <AuthInputTitle>{t("auth.register.email")}</AuthInputTitle>
      <InputArea
        autoFocus
        onFocus={props.onFocus}
        onChange={props.handleInputChange}
        id="email"
        type="text"
        value={props.email}
        placeholder={t("auth.register.emailPlaceholder")}
        onKeyDown={props.onKeyDown}
      />
      <AuthInputTitle>{t("auth.register.password")}</AuthInputTitle>
      <InputArea
        onFocus={props.onFocus}
        onChange={props.handleInputChange}
        id="password"
        type="password"
        value={props.password}
        placeholder={t("auth.login.passwordPlaceholder")}
        onKeyDown={props.onKeyDown}
      />
    </div>
  );
}

type phoneLoginFormProps = {
  phone: string;
  countryCode: string;
  password: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCountryCodeChange: (value: string) => void;
  onFocus: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

function PhoneLoginForm(props: phoneLoginFormProps) {
  const { t } = useTranslation();
  return (
    <div>
      <PhoneInputContainer>
        <DropDown
          componentName="Login"
          type="Country code"
          defaultSelection={props.countryCode}
          selectedText={props.countryCode}
          onOptionChange={props.handleCountryCodeChange}
          options={getCountryCodeList(t)}
        />
        <PhoneInput
          autoFocus
          onFocus={props.onFocus}
          onChange={props.handleInputChange}
          id="phone"
          type="text"
          value={props.phone}
          placeholder={t("auth.login.phone")}
          onKeyDown={props.onKeyDown}
        />
      </PhoneInputContainer>
      <InputArea
        onFocus={props.onFocus}
        onChange={props.handleInputChange}
        id="password"
        type="password"
        value={props.password}
        placeholder={t("auth.login.password")}
        onKeyDown={props.onKeyDown}
      />
    </div>
  );
}

// type loginStatusButtonProps = {
//   isPhone: boolean;
//   setIsPhone: () => void;
// };

// function LoginStatusButtonComponent(props: loginStatusButtonProps) {
//   const { t } = useTranslation();
//   const { isPhone, setIsPhone } = props;
//   const Image = isPhone ? <EmailImg /> : <PhoneImg />;
//   const title = isPhone ? t("auth.login.withEmail") : t("auth.login.withPhone");

//   const onSetIsPhoneClick = () => {
//     mixpanel.track("Login", {
//       action: "switch login method",
//       from: isPhone ? "phone" : "email",
//       to: !isPhone ? "email" : "phone",
//     });
//     setIsPhone();
//   };

//   return (
//     <LoginStatusButton onClick={onSetIsPhoneClick}>
//       {Image}
//       <LoginStatusTitle>{title}</LoginStatusTitle>
//     </LoginStatusButton>
//   );
// }

interface LoginProps {
  history: History<LocationState>;
  handleRegister: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCountryCodeChange: (value: string) => void;
  setIsPhone: () => void;
  onLoginSubmit: () => void;
  email: string;
  phone: string;
  password: string;
  countryCode: string;
  isPhone: boolean;
}

interface LoginState {}

type Props = LoginProps & WithTranslation;

class LoginForm extends React.Component<Props, LoginState> {
  onForgotPasswordClick = () => {
    mixpanel.track("Login", {
      action: "forget password",
    });
    this.props.history.push("/forgot");
  };

  onRegisterClick = () => {
    mixpanel.track("Login", {
      action: "switch to register",
    });
    this.props.handleRegister();
  };

  onFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.currentTarget;
    mixpanel.track("Login", {
      action: "focus on " + id,
    });
  };

  onLoginClick = () => {
    mixpanel.track("Login", {
      action: "click login button",
    });
    this.props.onLoginSubmit();
  };

  onLoginEnterClick = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      mixpanel.track("Login", {
        action: "click enter button to login",
      });
      this.props.onLoginSubmit();
    }
  };

  render() {
    const { t, isPhone } = this.props;
    return (
      <AuthContainer>
        <ContentContainer>
          <AuthTitle style={{}}>
            {t("auth.login.title")}
            <LanguageContainer
              style={{
                float: "right",
                marginTop: "15px",
              }}
            >
              <LanguageDropdown path="register" color={"#909090"} />
            </LanguageContainer>
          </AuthTitle>

          <FormContainer noValidate>
            {isPhone ? (
              <PhoneLoginForm
                {...this.props}
                onFocus={this.onFocus}
                onKeyDown={this.onLoginEnterClick}
              />
            ) : (
              <EmailLoginForm
                {...this.props}
                onFocus={this.onFocus}
                onKeyDown={this.onLoginEnterClick}
              />
            )}
          </FormContainer>
          <ForgetLink onClick={this.onForgotPasswordClick}>
            {t("auth.login.forgotPassword")}
          </ForgetLink>
          <AuthButton onClick={this.onLoginClick}>
            <ActionTitle>{t("auth.login.login")}</ActionTitle>
          </AuthButton>
          <DivideContainer>
            <DivideLine />
            <OrText>{t("auth.login.or")}</OrText>
            <DivideLine />
          </DivideContainer>
          <GoogleLoginComponent {...this} {...this.state} {...this.props} />
          {/* <LoginStatusButtonComponent {...this.props} /> */}
          <AuthActionLinkContainer>
            <AuthActionText>{t("auth.login.dontHaveAccount")}</AuthActionText>
            <AuthActionLink onClick={this.onRegisterClick}>
              {t("auth.login.signUp")}
            </AuthActionLink>
          </AuthActionLinkContainer>
        </ContentContainer>
        <FooterContainer>
          <AuthFooterText>
            {t("general.copyright", { year: new Date().getFullYear() })}
          </AuthFooterText>
        </FooterContainer>
      </AuthContainer>
    );
  }
}

export default withTranslation()(LoginForm);
