import React, { Component } from "react";
import { match } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import { PencilIcon, CheckIcon, ChevronLeft } from "lucide-react";
import { History, LocationState } from "history";
import NotificationModal from "../../../common/NotificationModal";
import DropDown from "../../../common/DropDown";
import { getCountryCodeList } from "../../../../constants/countryCode";
import {
  IS_NUMBER,
  IS_VALID_EMAIL,
  NORMALIZE_LANGUAGE_FOR_MAILGUN_EMAIL_TEMPLATE,
  GET_DATE_TIME_STRING_FROM_TIMESTAMP,
} from "../../../../util/common";
import { EDIT_USER_INFORMATION } from "../../../../graphql/mutations/editUserInformation";
import fileSuccess from "../../../User/Evaluation/CorePro/UploadVideoSection/images/FileSuccess-2x.png";
import { client } from "../../../../graphql/ApolloClient";
import api_address from "../../../../constants/config";
import addFile from "../../../User/Evaluation/CorePro/UploadVideoSection/images/AddFile-2x.png";
import { JS_COOKIE } from "util/auth";
import { ADMIN_COOKIE_NAME } from "constants/admin";
import Uploader from "util/uploader";
import { Button, Modal, Upload, message, Select } from "antd";
import { ExclamationCircleFilled, InboxOutlined } from "@ant-design/icons";
import {
  AWS_USER_ASSESSMENTS_FOLDER_PATH,
  handleOpenPDFFromAWS,
} from "components/User/Dashboard/SurveyHistory/UserAssessment";
import { handleSendNewReportEmail } from "./components/newUploadReportEmailSender";
import {
  Container,
  ContentContainer,
  ContentNewSubtitle,
  Input,
  ContentTitle,
  BackButton,
  PencilButton,
  UtcNotice,
} from "../../styles";
import {
  UploadInput,
  UploadButton,
  UploadButtonText,
  EvaluationInput,
  UploadContainer,
  SubmitButton,
  EvaluationLabel,
  Asterisk,
} from "components/common/VideoModal/style";
import {
  Title,
  Table,
  ResultHeader,
  ResultRow,
  PaymentHeader,
  PaymentRow,
  Item,
  TimeItem,
  AssessmentRow,
  AssessmentHeader,
} from "./styles";

const { confirm } = Modal;
const { Dragger } = Upload;

interface Match {
  id: string;
}

interface ResultType {
  id: string;
  transactionId: string;
  product: string;
  currentStep: string;
  completed: boolean;
  startAt: string;
  finishAt: string;
}

interface PaymentType {
  id: string;
  transactionId: string;
  name: string;
  currency: string;
  paymentMethod: string;
  price: string;
  status: string;
  createdAt: string;
}

interface UserEditProps {
  match: match<Match>;
  history: History<LocationState>;
}

interface UserEditStates {
  [key: string]:
    | string
    | boolean
    | ResultType[]
    | PaymentType[]
    | null
    | number;
  UUID: string;
  firstName: string;
  lastName: string;
  countryCode: string;
  language: string;
  phone: string;
  email: string;
  createdAt: string;
  lastLoginAt: string;
  resetLink: string;
  message: string;
  showModal: boolean;
  notificationStatus: boolean;
  resultList: ResultType[];
  paymentList: PaymentType[];
  userAssessmentList: any;
  shouldRefetchUserData: boolean;
  evaluationName: string;
  evaluationDate: string;
  evaluationAgency: string;
  file: any;
  uploadProgress: number;
  AWSFolderName: string;
  isEmailEditable: boolean;
  tempEmail: string;
  isFirstNameEditable: boolean;
  tempFirstName: string;
  isLastNameEditable: boolean;
  tempLastName: string;
  organizationId: string;
}

type Props = UserEditProps & WithTranslation;
class UserEdit extends Component<Props, UserEditStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      UUID: "",
      firstName: "",
      lastName: "",
      countryCode: "",
      language: "",
      phone: "",
      email: "",
      createdAt: "",
      lastLoginAt: "",
      resetLink: "",
      message: "",
      showModal: false,
      notificationStatus: false,
      resultList: [],
      paymentList: [],
      userAssessmentList: [],
      evaluationName: "",
      evaluationDate: "",
      evaluationAgency: "",
      shouldRefetchUserData: false,
      file: null,
      uploadProgress: 0,
      AWSFolderName: "",
      isEmailEditable: false,
      tempEmail: "",
      isFirstNameEditable: false,
      tempFirstName: "",
      isLastNameEditable: false,
      tempLastName: "",
      tempCountryCode: "",
      isPhoneEditable: false,
      tempPhone: "",
      organizationId: "",
    };
  }

  updateInfoOnGuide = async (userUuid, attribute, value) => {
    await client.mutate({
      mutation: EDIT_USER_INFORMATION,
      variables: {
        userUuid: userUuid,
        attribute: attribute,
        value: value.toString(),
      },
    });
  };

  fetchUserData = async (userId) => {
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { data } = await axios.get(
        `${api_address}api/admin/user/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { user, resultList, paymentList, userAssessmentList } = data;
      this.setState({
        UUID: user.UUID,
        firstName: user.firstName,
        lastName: user.lastName,
        countryCode: user.countrycode,
        language: user.language,
        phone: user.phone,
        email: user.email,
        createdAt: user.createdat,
        lastLoginAt: user.lastloginat,
        resultList: resultList.reverse(),
        paymentList: paymentList.reverse(),
        userAssessmentList: userAssessmentList.reverse(),
        shouldRefetchUserData: false,
        AWSFolderName: `${AWS_USER_ASSESSMENTS_FOLDER_PATH + user.UUID}`,
        organizationId: user.organizationId,
      });
    } catch (err: any) {
      console.error("Failed to fetch user data", err);
      this.showNotification("Failed to load user data: " + err.message, false);
    }
  };

  componentDidMount() {
    this.fetchUserData(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    const currentUserId = this.props.match.params.id;
    const previousUserId = prevProps.match.params.id;

    if (currentUserId !== previousUserId || this.state.shouldRefetchUserData) {
      this.fetchUserData(currentUserId);
    }
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    this.setState({ [id]: value });
  };

  handleCountryCodeChange = (value: string) => {
    if (value != null)
      this.setState({
        countryCode: value,
      });
  };

  handleDeleteAssessment = async (assessmentId: number) => {
    const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
    return axios
      .delete(`${api_address}api/admin/user/assessment/${assessmentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const { status, message } = res.data;
        this.setState({ shouldRefetchUserData: true });
        return status;
      });
  };

  handleFileChange = (info) => {
    const { AWSFolderName } = this.state;

    if (info.fileList.length === 0) return;
    const file = info.fileList[0].originFileObj;

    Uploader(file, AWSFolderName, file.name)
      .on("httpUploadProgress", (evt) => {
        const uploaded = Math.round((evt.loaded / evt.total) * 100);
        this.setState({ uploadProgress: uploaded });
      })
      .send((err, data) => {
        if (err) {
          console.error(err);
          return;
        }
      });
    this.setState({ file });
  };

  handleSubmit = async () => {
    const { t } = this.props;
    const {
      file,
      evaluationName,
      evaluationDate,
      evaluationAgency,
      email,
      language,
    } = this.state;
    const { id: userId } = this.props.match.params;
    if (!evaluationName) {
      this.showNotification(t("admin.user.errorMessage.evaluationName"), false);
      return;
    }

    if (!evaluationAgency) {
      this.showNotification(
        t("admin.user.errorMessage.organizationName"),
        false
      );
      return;
    }

    if (!evaluationDate) {
      this.showNotification(t("admin.user.errorMessage.evaluationDate"), false);
      return;
    }
    if (!file) {
      this.showNotification(t("admin.user.errorMessage.noFile"), false);
      console.log("No file uploaded");
      return;
    }

    const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
    const data = {
      fileName: file.name,
      userId: userId,
      evaluationName,
      evaluationDate,
      evaluationAgency,
    };

    try {
      const res = await axios.post(
        api_address + "api/admin/user/assessment/",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { status, message } = res.data;

      this.setState({
        shouldRefetchUserData: true,
        file: null,
        evaluationDate: "",
        evaluationName: "",
        evaluationAgency: "",
        uploadProgress: 0,
      });
      if (status) {
        handleSendNewReportEmail(
          NORMALIZE_LANGUAGE_FOR_MAILGUN_EMAIL_TEMPLATE(language),
          email
        );
      }
      return status;
    } catch (error) {
      console.error("Failed to submit assessment:", error);
      this.showNotification("Failed to submit assessment", false);
    }
  };

  showNotification = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  validation = () => {
    const { email, phone, countryCode, firstName } = this.state;
    if (firstName === "") {
      throw new Error("first name is missing");
    }
    if (countryCode === "") {
      throw new Error("country code is missing");
    }
    if (phone === "") {
      throw new Error("phone number is missing");
    }
    if (!IS_NUMBER(phone)) {
      throw new Error("phone number is invalid");
    }
    if (email === "") {
      throw new Error("email is missing");
    }
    if (!IS_VALID_EMAIL(email)) {
      throw new Error("email is invalid");
    }
  };

  onCancelClick = () => {
    this.props.history.push("/admin/user");
  };

  onSaveClick = async () => {
    try {
      this.validation();
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { id } = this.props.match.params;
      const { UUID, firstName, lastName, countryCode, phone, email } =
        this.state;
      const res = await axios.put(
        api_address + "api/admin/user/" + id,
        { UUID, firstName, lastName, countryCode, phone, email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await this.updateInfoOnGuide(UUID, "First Name", firstName);
      await this.updateInfoOnGuide(UUID, "Last Name", lastName);
      await this.updateInfoOnGuide(UUID, "Phone Number", countryCode + phone);
      await this.updateInfoOnGuide(UUID, "Email", email);

      const { status, message } = res.data;
      this.showNotification(message, status);
    } catch (error: any) {
      this.showNotification(error.message, false);
    }
  };

  showDeleteConfirm = (assessmentId) => {
    confirm({
      title: "Delete Task",
      icon: <ExclamationCircleFilled rev={undefined} />,
      content:
        "Are you certain you want to delete this task? This action is irreversible.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        return new Promise<void>((resolve, reject) => {
          this.handleDeleteAssessment(assessmentId)
            .then((status) => {
              if (status) {
                this.showNotification("Successfully deleted", status);
                resolve();
              } else {
                reject(new Error("Failed to delete the assessment"));
              }
            })
            .catch((error) => {
              console.error("Error deleting assessment:", error);
              reject(error);
            });
        }).catch(() => {
          console.log("Unknown errors!");
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  handleFieldEdit = (field) => {
    this.setState({
      [`is${field}Editable`]: true,
      [`temp${field}`]: this.state[field.toLowerCase()],
    });
  };

  handleFieldSave = async (field, apiField, guideField) => {
    try {
      const UUID = this.state.UUID;
      const tempValue = this.state[`temp${field}`];
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);

      const getUserRes = await axios.get(
        `${api_address}api/admin/user/${this.props.match.params.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const currentUserData = getUserRes.data.user;
      const updatedUserData = {
        ...currentUserData,
        [apiField]: tempValue,
      };

      const res = await axios.put(
        `${api_address}api/admin/user/${this.props.match.params.id}`,
        updatedUserData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await this.updateInfoOnGuide(UUID, guideField, tempValue);

      if (res.data.status) {
        this.setState({
          [apiField]: tempValue,
          [`is${field}Editable`]: false,
        });

        this.showNotification(`${field} updated successfully`, true);
      } else {
        throw new Error(res.data.message);
      }
    } catch (error) {
      this.showNotification(error.message, false);
    }
  };

  handlePhoneEdit = () => {
    this.setState({
      isPhoneEditable: true,
      tempCountryCode: this.state.countryCode,
      tempPhone: this.state.phone,
    });
  };

  handlePhoneSave = async () => {
    try {
      const { UUID, tempCountryCode, tempPhone } = this.state;
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);

      const getUserRes = await axios.get(
        `${api_address}api/admin/user/${this.props.match.params.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const currentUserData = getUserRes.data.user;
      const updatedUserData = {
        ...currentUserData,
        countryCode: tempCountryCode,
        phone: tempPhone,
      };

      const res = await axios.put(
        `${api_address}api/admin/user/${this.props.match.params.id}`,
        updatedUserData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await this.updateInfoOnGuide(
        UUID,
        "Phone Number",
        tempCountryCode + tempPhone
      );

      if (res.data.status) {
        this.setState({
          countryCode: tempCountryCode,
          phone: tempPhone,
          isPhoneEditable: false,
        });

        this.showNotification("Phone number updated successfully", true);
      } else {
        throw new Error(res.data.message);
      }
    } catch (error) {
      this.showNotification(error.message, false);
    }
  };

  handleEmailEdit = () => this.handleFieldEdit("Email");

  handleEmailSave = () => this.handleFieldSave("Email", "email", "Email");

  handleFirstNameEdit = () => this.handleFieldEdit("FirstName");

  handleFirstNameSave = () =>
    this.handleFieldSave("FirstName", "firstName", "First Name");

  handleLastNameEdit = () => this.handleFieldEdit("LastName");

  handleLastNameSave = () =>
    this.handleFieldSave("LastName", "lastName", "Last Name");

  handleCompletedChange = async (resultId, value) => {
    const completed = value === "Yes";
    const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);

    try {
      await axios.put(
        `${api_address}api/admin/result/${resultId}`,
        { completed },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      this.setState((prevState) => ({
        resultList: prevState.resultList.map((result) =>
          result.id === resultId ? { ...result, completed } : result
        ),
      }));

      this.showNotification("Completed status updated successfully", true);
    } catch (error) {
      console.error("Error updating completed status:", error);
      this.showNotification("Failed to update completed status", false);
    }
  };

  render = () => {
    const { t } = this.props;
    const { id } = this.props.match.params;
    const { Option } = Select;
    const {
      showModal,
      message,
      notificationStatus,
      UUID,
      firstName,
      lastName,
      countryCode,
      phone,
      email,
      createdAt,
      lastLoginAt,
      resultList,
      paymentList,
      userAssessmentList,
      uploadProgress,
      AWSFolderName,
      isEmailEditable,
      tempEmail,
      isFirstNameEditable,
      tempFirstName,
      isLastNameEditable,
      tempLastName,
      isCountryCodeEditable,
      tempCountryCode,
      isPhoneEditable,
      tempPhone,
      organizationId,
    } = this.state;

    return (
      <Container>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />
        <UtcNotice>{t("admin.result.utcNote")}</UtcNotice>

        <ContentContainer style={{ marginTop: "20px" }}>
          <BackButton onClick={this.onCancelClick}>
            <ChevronLeft />
          </BackButton>

          <ContentTitle>{"User Details"}</ContentTitle>
        </ContentContainer>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <ContentContainer>
              <ContentNewSubtitle>{t("admin.user.id")}</ContentNewSubtitle>
              <Input disabled={true} value={id} />
            </ContentContainer>

            <ContentContainer>
              <ContentNewSubtitle>
                {t("admin.user.firstName")}
              </ContentNewSubtitle>
              <div style={{ position: "relative" }}>
                <Input
                  disabled={!isFirstNameEditable}
                  onChange={(e) =>
                    this.setState({ tempFirstName: e.target.value })
                  }
                  value={isFirstNameEditable ? tempFirstName : firstName}
                  className="pr-12"
                  style={{ height: "-webkit-fill-available" }}
                />
                <PencilButton
                  onClick={
                    isFirstNameEditable
                      ? this.handleFirstNameSave
                      : this.handleFirstNameEdit
                  }
                  isEditing={isFirstNameEditable}
                >
                  {isFirstNameEditable ? (
                    <CheckIcon
                      className="h-5 w-5 text-white"
                      style={{ color: "white" }}
                    />
                  ) : (
                    <PencilIcon
                      className="h-5 w-5 text-white"
                      style={{ color: "white" }}
                    />
                  )}
                </PencilButton>
              </div>
            </ContentContainer>

            <ContentContainer>
              <ContentNewSubtitle>{t("admin.user.email")}</ContentNewSubtitle>
              <div style={{ position: "relative" }}>
                <Input
                  disabled={!isEmailEditable}
                  onChange={(e) => this.setState({ tempEmail: e.target.value })}
                  value={isEmailEditable ? tempEmail : email}
                  className="pr-12"
                  style={{ height: "-webkit-fill-available" }}
                />
                <PencilButton
                  onClick={
                    isEmailEditable
                      ? this.handleEmailSave
                      : this.handleEmailEdit
                  }
                  isEditing={isEmailEditable}
                >
                  {isEmailEditable ? (
                    <CheckIcon
                      className="h-5 w-5 text-green-500"
                      style={{ color: "white" }}
                    />
                  ) : (
                    <PencilIcon
                      className="h-5 w-5"
                      style={{ color: "white" }}
                    />
                  )}
                </PencilButton>
              </div>
            </ContentContainer>
            <ContentContainer>
              <ContentNewSubtitle>
                {t("admin.organization.id")}
              </ContentNewSubtitle>
              <Input disabled={true} value={organizationId} />
            </ContentContainer>
          </div>

          <div>
            <ContentContainer>
              <ContentNewSubtitle>{t("admin.user.UUID")}</ContentNewSubtitle>
              <Input
                disabled={true}
                onChange={this.handleInputChange}
                id="UUID"
                type="text"
                value={UUID}
              />
            </ContentContainer>

            <ContentContainer>
              <ContentNewSubtitle>
                {t("admin.user.lastName")}
              </ContentNewSubtitle>
              <div style={{ position: "relative" }}>
                <Input
                  disabled={!isLastNameEditable}
                  onChange={(e) =>
                    this.setState({ tempLastName: e.target.value })
                  }
                  value={isLastNameEditable ? tempLastName : lastName}
                  className="pr-12"
                  style={{ height: "-webkit-fill-available" }}
                />
                <PencilButton
                  onClick={
                    isLastNameEditable
                      ? this.handleLastNameSave
                      : this.handleLastNameEdit
                  }
                  isEditing={isLastNameEditable}
                >
                  {isLastNameEditable ? (
                    <CheckIcon
                      className="h-5 w-5 text-green-500"
                      style={{ color: "white" }}
                    />
                  ) : (
                    <PencilIcon
                      className="h-5 w-5"
                      style={{ color: "white" }}
                    />
                  )}
                </PencilButton>
              </div>
            </ContentContainer>

            <ContentContainer>
              <ContentNewSubtitle>{t("admin.user.phone")}</ContentNewSubtitle>
              <div style={{ position: "relative", marginTop: "10px" }}>
                {isPhoneEditable ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <DropDown
                      componentName="admin - user"
                      type="Country code"
                      defaultSelection={tempCountryCode}
                      selectedText={tempCountryCode}
                      onOptionChange={(value) =>
                        this.setState({ tempCountryCode: value })
                      }
                      options={getCountryCodeList(t)}
                      style={{ marginRight: "10px" }}
                    />
                    <Input
                      onChange={(e) =>
                        this.setState({ tempPhone: e.target.value })
                      }
                      value={tempPhone}
                      className="pr-12"
                      style={{
                        paddingRight: "50px",
                        height: "50px",
                        marginLeft: "10px",
                      }}
                    />
                  </div>
                ) : (
                  <Input
                    disabled={true}
                    value={`${countryCode} ${phone}`}
                    className="pr-10"
                    style={{ height: "50px", marginBottom: "18px" }}
                  />
                )}
                <PencilButton
                  onClick={
                    isPhoneEditable
                      ? this.handlePhoneSave
                      : this.handlePhoneEdit
                  }
                  isEditing={isPhoneEditable}
                  style={{
                    top: "39%",
                  }}
                >
                  {isPhoneEditable ? (
                    <CheckIcon
                      className="h-5 w-5 text-green-500"
                      style={{ color: "white" }}
                    />
                  ) : (
                    <PencilIcon
                      className="h-5 w-5"
                      style={{ color: "white" }}
                    />
                  )}
                </PencilButton>
              </div>
            </ContentContainer>
          </div>
        </div>
        <Title>{t("admin.result.result")}</Title>
        <Table>
          <ResultHeader>
            <Item>{t("admin.result.transactionId")}</Item>
            <Item>{t("admin.result.productName")}</Item>
            <Item>{t("admin.result.currentStep")}</Item>
            <Item>{t("admin.result.completed")}</Item>
            <Item>{t("admin.result.startAt")}</Item>
            <Item>{t("admin.result.finishAt")}</Item>
          </ResultHeader>
          {resultList.map((result: ResultType) => (
            <ResultRow
              key={result.id}
              onClick={() =>
                this.props.history.push("/admin/result/edit/" + result.id)
              }
            >
              <Item>{result.transactionId}</Item>
              <Item>{result.product}</Item>
              <Item>{result.currentStep}</Item>
              <Item>
                {/* Dropdown for Completed status */}
                <Select
                  value={
                    result.completed
                      ? t("admin.result.yes")
                      : t("admin.result.no")
                  }
                  style={{ width: "100px", marginTop: "10px" }}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(value) =>
                    this.handleCompletedChange(result.id, value)
                  }
                >
                  <Option value="Yes">{t("admin.result.yes")}</Option>
                  <Option value="No">{t("admin.result.no")}</Option>
                </Select>
              </Item>
              <TimeItem>
                {GET_DATE_TIME_STRING_FROM_TIMESTAMP(result.startAt)}
              </TimeItem>
              <TimeItem>
                {GET_DATE_TIME_STRING_FROM_TIMESTAMP(result.finishAt)}
              </TimeItem>
            </ResultRow>
          ))}
        </Table>

        <Title>{t("admin.payment.payments")}</Title>
        <Table>
          <PaymentHeader>
            <Item>{t("admin.payment.transactionId")}</Item>
            <Item>{t("admin.payment.product")}</Item>
            <Item>{t("admin.payment.currency")}</Item>
            <Item>{t("admin.payment.paymentMethod.title")}</Item>
            <Item>{t("admin.payment.price")}</Item>
            <Item>{t("admin.payment.status.title")}</Item>
            <Item>{t("admin.payment.createdAt")}</Item>
          </PaymentHeader>
          {paymentList.map((payment: PaymentType) => (
            <PaymentRow
              key={payment.id}
              onClick={() =>
                this.props.history.push("/admin/payment/edit/" + payment.id)
              }
            >
              <Item>{payment.transactionId}</Item>
              <Item>{payment.name}</Item>
              <Item>{payment.currency}</Item>
              <Item>
                {t(`admin.payment.paymentMethod.${payment.paymentMethod}`)}
              </Item>
              <Item>{payment.price}</Item>
              <Item>{t(`admin.payment.status.${payment.status}`)}</Item>
              <TimeItem>
                {GET_DATE_TIME_STRING_FROM_TIMESTAMP(payment.createdAt)}
              </TimeItem>
            </PaymentRow>
          ))}
        </Table>

        <Title>{t("admin.uploadAssessment.title")}</Title>
        <UploadContainer>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr", // 3 columns
              gap: "20px", // Space between columns
              marginBottom: "20px", // Space below the inputs
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <EvaluationLabel htmlFor="evaluationName">
                {t("admin.uploadAssessment.evaluationName")}
                <Asterisk>*</Asterisk>
              </EvaluationLabel>
              <EvaluationInput
                type="text"
                placeholder={t("admin.uploadAssessment.enterEvaluationName")}
                value={this.state.evaluationName}
                onChange={(e) =>
                  this.setState({ evaluationName: e.target.value })
                }
                required
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <EvaluationLabel htmlFor="evaluationAgency">
                {t("admin.uploadAssessment.evaluationAgency")}
                <Asterisk>*</Asterisk>
              </EvaluationLabel>
              <EvaluationInput
                type="text"
                placeholder={t("admin.uploadAssessment.enterEvaluationAgency")}
                value={this.state.evaluationAgency}
                onChange={(e) =>
                  this.setState({ evaluationAgency: e.target.value })
                }
                required
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <EvaluationLabel htmlFor="evaluationDate">
                {t("admin.uploadAssessment.evaluationDate")}
                <Asterisk>*</Asterisk>
              </EvaluationLabel>
              <EvaluationInput
                type="date"
                value={this.state.evaluationDate}
                onChange={(e) =>
                  this.setState({ evaluationDate: e.target.value })
                }
                required
              />
            </div>
          </div>
          <EvaluationLabel htmlFor="Upload_1">
            {t("admin.uploadAssessment.uploadFile")}
            <Asterisk>*</Asterisk>
          </EvaluationLabel>
          <Dragger
            name="file"
            accept=".pdf"
            beforeUpload={(file) => {
              this.setState({ file });
              return false;
            }}
            onChange={this.handleFileChange}
            multiple={false}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              {t("admin.uploadAssessment.dragDropTitle")}
            </p>
            <p className="ant-upload-hint">
              {t("admin.uploadAssessment.dragDropSubTitle")}
            </p>
          </Dragger>
          {this.state.file && this.state.file.name && (
            <p>{this.state.file.name}</p>
          )}
          <br />
          <SubmitButton onClick={this.handleSubmit}>
            {t("admin.uploadAssessment.submit")}
          </SubmitButton>
        </UploadContainer>
        {userAssessmentList && userAssessmentList.length > 0 ? (
          <Table>
            <AssessmentHeader>
              <Item>{t("admin.user.assessment.id")}</Item>
              <Item>{t("admin.user.assessment.name")}</Item>
              <Item>{t("admin.user.assessment.evaluationName")}</Item>
              <Item>{t("admin.user.assessment.evaluationAgency")}</Item>
              <Item>{t("admin.user.assessment.evaluationDate")}</Item>
              <Item></Item>
            </AssessmentHeader>
            {userAssessmentList.map((assessment: any) => (
              <AssessmentRow
                key={assessment.id}
                onClick={() =>
                  handleOpenPDFFromAWS({
                    folderName: AWSFolderName,
                    fileName: assessment.name,
                  })
                }
              >
                <Item>{assessment.id}</Item>
                <Item>{assessment.name}</Item>
                <Item>{assessment.evaluationName}</Item>
                <Item>{assessment.evaluationAgency}</Item>
                <Item>{assessment.evaluationDate.substring(0, 10)}</Item>
                <Item>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      this.showDeleteConfirm(assessment.id);
                    }}
                    type="dashed"
                  >
                    Delete
                  </Button>
                </Item>
              </AssessmentRow>
            ))}
          </Table>
        ) : null}
      </Container>
    );
  };
}

export default withTranslation()(UserEdit);
