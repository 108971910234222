import { useTranslation } from "react-i18next";
import {
  List,
  OptionText,
  OrderList,
  Segment,
  Title,
  TitleIndicate,
  QuestionText,
} from "../../styles";
import { PRODUCTS } from "constants/common";
function CoreProIntro(props) {
  const product = props.product;
  const { t } = useTranslation();
  return (
    <>
      <Segment>
        <TitleIndicate />
        <Title>
          {product === PRODUCTS.PEAWithReport
            ? t(
                "user.survey.core.questionnaire.intro.corePro.altWelcomePEAWithReport"
              )
            : product === PRODUCTS.CPCWithReport
            ? t(
                "user.survey.core.questionnaire.intro.corePro.altWelcomeCPCWithReport"
              )
            : product === PRODUCTS.CPCTrial
            ? t(
                "user.survey.core.questionnaire.intro.corePro.altWelcomeCPCTrial"
              )
            : product === PRODUCTS.CPCBH
            ? t("user.survey.core.questionnaire.intro.corePro.altWelcomeCPCBH")
            : product === PRODUCTS.CPC
            ? t("user.survey.core.questionnaire.intro.corePro.altWelcomeCPC")
            : product === PRODUCTS.PEA
            ? t("user.survey.core.questionnaire.intro.corePro.altWelcomePEA")
            : product === PRODUCTS.CPCFlexWithReport
            ? t(
                "user.survey.core.questionnaire.intro.corePro.altWelcomeCPCFlexWithReport"
              )
            : product === PRODUCTS.CPCFlex
            ? t(
                "user.survey.core.questionnaire.intro.corePro.altWelcomeCPCFlex"
              )
            : product === PRODUCTS.SpecializedAssessment
            ? t(
                "user.survey.core.questionnaire.intro.corePro.altWelcomeSpecializedAssessment"
              )
            : t("user.survey.core.questionnaire.intro.corePro.welcome")}
        </Title>
      </Segment>
      <Segment>
        <QuestionText>
          {t("user.survey.core.questionnaire.intro.corePro.read")}
        </QuestionText>
        <OrderList>
          <List>
            <OptionText>
              {t("user.survey.core.questionnaire.intro.corePro.1.text")}
              <b>{t("user.survey.core.questionnaire.intro.corePro.1.time")}</b>
            </OptionText>
          </List>
          <List>
            <OptionText>
              {t("user.survey.core.questionnaire.intro.corePro.2.system")}
              <b>{t("user.survey.core.questionnaire.intro.corePro.2.save")}</b>
              {t("user.survey.core.questionnaire.intro.corePro.2.continue")}
            </OptionText>
          </List>
          <List>
            <OptionText>
              {t("user.survey.core.questionnaire.intro.corePro.3.observations")}
              <b>
                {t("user.survey.core.questionnaire.intro.corePro.3.judgement")}
              </b>
              {t("user.survey.core.questionnaire.intro.corePro.3.choice")}
            </OptionText>
          </List>
          <List>
            <OptionText>
              {t(
                "user.survey.core.questionnaire.intro.corePro.4.questionnaire"
              )}
              <b>
                {t("user.survey.core.questionnaire.intro.corePro.4.required")}
              </b>
              {t("user.survey.core.questionnaire.intro.corePro.4.repetitive")}
            </OptionText>
          </List>
        </OrderList>
      </Segment>
    </>
  );
}
export default CoreProIntro;
