import { getYearAndMonth } from "../../../../../../util/result";

const getFillingPerson = (t, { respondent, respondentOtherText }): string => {
  const fillingPerson =
    respondent === "3"
      ? respondentOtherText
      : t(
          `user.survey.corePro.result.basicInformation.selfInfo.respondent.${respondent}`
        );
  return fillingPerson;
};

const getReportDate = (): string => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  const reportDate = `${year}/${month}/${day}`;
  return reportDate;
};

const getChildMonths = (
  t,
  { childBirthdayYear, childBirthdayMonth, childBirthdayDay }
): string => {
  const { year, month } = getYearAndMonth(
    childBirthdayYear,
    childBirthdayMonth,
    childBirthdayDay
  );
  const childMonth = `${year} ${t(
    "user.survey.corePro.result.basicInformation.child_info.birthday.year"
  )} ${month} ${t(
    "user.survey.corePro.result.basicInformation.child_info.birthday.month"
  )}`;
  return childMonth;
};

const getOriginalDisease = (
  t,
  { postpartumStatus, postpartumStatusOther }
): string => {
  let originalDisease = "";
  Object.keys(postpartumStatus).forEach((value) => {
    if (postpartumStatus[value]) {
      if (originalDisease !== "") {
        originalDisease = originalDisease + ", ";
      }
      originalDisease +=
        value !== "5"
          ? t(
              `user.survey.corePro.result.basicInformation.child_info.postPartumStatus.${value}`
            )
          : postpartumStatusOther;
    }
  });
  return originalDisease;
};

const getLanguage = (t, { familyLanguage, familyLanguageOther }): string => {
  let language = "";
  Object.keys(familyLanguage).forEach(function (value: string) {
    if (familyLanguage[value]) {
      if (language !== "") {
        language = language + ", ";
      }
      if (value === "3") {
        language = language + familyLanguageOther;
      } else {
        language += t(
          `user.survey.core.questionnaire.child_info.familyLanguage.${value}`
        );
      }
    }
  });
  return language;
};

const getBabyHealthStatus = (t, babyHealthStatus): string => {
  let healthStatus = "";
  Object.keys(babyHealthStatus).forEach((value: string) => {
    if (babyHealthStatus[value]) {
      if (healthStatus !== "") {
        healthStatus = healthStatus + ", ";
      }
      healthStatus += t(
        `user.survey.core.questionnaire.child_info.babyHealthStatus.${value}`
      );
    }
  });
  return healthStatus;
};

export const getBasicInformation = (t, data) => {
  const { child_info, createdat } = data;
  const {
    reportID,
    childName,
    childBirthdayDay,
    childBirthdayMonth,
    childBirthdayYear,
    birthStatus,
    birthStatusOtherText,
    sex,
    babyHealthStatus,
    hearingStatus,
    eyeStatus,
    respondent,
    respondentOtherText,
    postpartumStatus,
    postpartumStatusOther,
    familyLanguage,
    familyLanguageOther,
  } = child_info;
  const fillingPerson = getFillingPerson(t, {
    respondent,
    respondentOtherText,
  });
  const reportDate = getReportDate();
  const childBirthday = `${childBirthdayYear}/${childBirthdayMonth}/${childBirthdayDay}`;
  const childMonth = getChildMonths(t, {
    childBirthdayDay,
    childBirthdayMonth,
    childBirthdayYear,
  });
  const originalDisease = getOriginalDisease(t, {
    postpartumStatus,
    postpartumStatusOther,
  });
  const language = getLanguage(t, { familyLanguage, familyLanguageOther });
  const healthStatus = getBabyHealthStatus(t, babyHealthStatus);
  let birthStatusText = "";
  if (birthStatus === "1") {
    birthStatusText = t(
      "user.survey.core.questionnaire.child_info.birthStatus.1"
    );
  } else {
    if (birthStatus === "2") {
      birthStatusText =
        t("user.survey.core.questionnaire.child_info.birthStatus.2") + ": ";
    }
    birthStatusText += birthStatusOtherText;
  }
  const basicInformation = {
    reportID: reportID,
    submissionTime: createdat,
    fillingPerson: fillingPerson,
    reportDate: reportDate,
    childName: childName,
    childBirthday: childBirthday,
    childMonths: childMonth,
    babyHealthStatus: healthStatus,
    birthStatus: birthStatusText,
    sex: t(`user.survey.core.questionnaire.child_info.sex.${sex}`),
    hearingStatus: t(
      `user.survey.core.questionnaire.child_info.hearing.${hearingStatus}`
    ),
    eyeStatus: t(
      `user.survey.core.questionnaire.child_info.eyesight.${eyeStatus}`
    ),
    originalDisease: originalDisease,
    language: language,
  };
  return basicInformation;
};
