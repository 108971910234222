import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ThunkDispatch } from "redux-thunk";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { AppActions } from "types/actions";
import { startLoginFail } from "../../../auth/actions";
import { startReset } from "../../../User/Evaluation/Core/utils/actions";
import { NextButton } from "styles";
import { Header, Segment, Image } from "./styles";
import { Container } from "../../../User/Evaluation/styles";
import ThankYouImg from "../../../general/assets/ThankYou.svg";
import { GET_TITLE_BAR } from "util/common";
import { JS_COOKIE } from "util/auth";

type BJCHEndProps = {};

type Props = BJCHEndProps & LinkDispatchProps;

function BJCHEnd(props: Props) {
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = GET_TITLE_BAR(t, "BJCH", false);
  }, [t]);

  const handleClose = () => {
    props.onLoginFail();
    props.onReset();
    JS_COOKIE.remove("token");
    const coupon = process.env.REACT_APP_BJCH_COUPON;
    history.push("/OrganizationEntry/" + coupon);
  };
  return (
    <Container>
      <Segment>
        <Image src={ThankYouImg} />
        <Header>您已成功完成测评，感谢您的参与！</Header>
        <Header>再次填入病历号获取初步针对性推荐亲子活动</Header>
        <Header>(测评结果正进行科研中，不代表临床判断，仅供参考)</Header>
        <NextButton onClick={handleClose} status={true}>
          结束问卷
        </NextButton>
      </Segment>
    </Container>
  );
}

interface LinkDispatchProps {
  onLoginFail: () => void;
  onReset: () => void;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): LinkDispatchProps => ({
  onLoginFail: bindActionCreators(startLoginFail, dispatch),
  onReset: bindActionCreators(startReset, dispatch),
});

export default connect(null, mapDispatchToProps)(BJCHEnd);
