import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  UPDATE_USER_TOKEN,
  UPDATE_LANGUAGE,
  FINISHED_EVALUATION,
  ONGOING_EVALUATION,
} from "./constants";
import { AppActions } from "types/actions";
import { Dispatch } from "redux";
import { AppState } from "storeConfig";

export const loginSuccess = (data: string): AppActions => ({
  type: LOGIN_SUCCESS,
  data,
});

export const loginFail = (): AppActions => ({
  type: LOGIN_FAIL,
});

export const updateUserToken = (data: string): AppActions => ({
  type: UPDATE_USER_TOKEN,
  data,
});

export const updateLanguage = (data: string): AppActions => ({
  type: UPDATE_LANGUAGE,
  data,
});

export const ongoingEvaluation = (): AppActions => ({
  type: ONGOING_EVALUATION,
});

export const finishedEvaluation = (): AppActions => ({
  type: FINISHED_EVALUATION,
});

export const startLoginSuccess = (data: string) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(loginSuccess(data));
  };
};

export const startLoginFail = () => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(loginFail());
  };
};

export const startUpdateUserToken = (data: string) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(updateUserToken(data));
  };
};

export const startUpdateUserLanguage = (data: string) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(updateLanguage(data));
  };
};

export const updateOngoingEvaluation = () => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(ongoingEvaluation());
  };
};

export const updateFinishedEvaluation = () => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(finishedEvaluation());
  };
};
