import { Component } from "react";
import { withRouter, RouteComponentProps, match } from "react-router-dom";
import EvalProgressBar from "../../User/Evaluation/ProgressBar/EvalProgressBar";
import axios from "axios";
import api_address from "../../../constants/config";
import { withTranslation, WithTranslation } from "react-i18next";
import { History, LocationState } from "history";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AppActions } from "types/actions";
import { ThunkDispatch } from "redux-thunk";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {
  startLoginSuccess,
  startUpdateUserToken,
  startLoginFail,
} from "../../auth/actions";
import { startReset } from "../../User/Evaluation/Core/utils/actions";
import { ROLE } from "../../../constants/common";
import { EVAL_STEPS, PRODUCTS } from "../../../constants/common";
import { Background } from "../../User/Evaluation/styles";
import BJCHIntro from "./BJCH";
import NotAvailable from "./NotAvailable";
import NotificationModal from "../../common/NotificationModal";
import Modal from "../Modal";
import { JS_COOKIE } from "util/auth";
interface EntryProps {
  history: History<LocationState>;
  match: match<Match>;
}

interface IntroProps {}

interface Match {
  coupon: string;
}

interface IntroState {
  [key: string]: string | boolean;
  transactionId: string;
  product: string;
  showNotification: boolean;
  notificationMessage: string;
  notificationStatus: boolean;
  showModal: boolean;
  notAvailable: boolean;
}

type Props = IntroProps &
  LinkDispatchProps &
  EntryProps &
  WithTranslation &
  RouteComponentProps;

class Entry extends Component<Props, IntroState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      transactionId: "",
      product: "",
      showNotification: false,
      notificationMessage: "",
      notificationStatus: false,
      showModal: false,
      notAvailable: false,
    };
  }

  componentDidMount = async () => {
    try {
      const { coupon } = this.props.match.params;
      const res = await axios.get(api_address + "api/coupon/couponStatus", {
        params: {
          coupon,
        },
      });
      const { status, message, product } = res.data;
      if (!status) {
        throw new Error(message);
      }
      this.setState({
        product,
      });
    } catch (err) {
      this.setState({ notAvailable: true });
    }
    this.props.onLoginFail();
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    this.setState({ [name]: value });
  };

  handleNext = async () => {
    try {
      const { transactionId } = this.state;
      const { coupon } = this.props.match.params;
      const language = i18n.use(LanguageDetector).language;
      const res = await axios.post(
        api_address + "api/results/organizationIntro",
        {
          transactionId,
          coupon,
          language,
        }
      );
      const { status, message, token, isNewReport, currentStep } = res.data;
      if (!status) {
        throw new Error(message);
      }
      JS_COOKIE.set("token", token);
      this.props.onUpdateUserToken(token);
      this.props.onLoginSuccess(ROLE.tmpUser);
      if (!isNewReport && currentStep === EVAL_STEPS.report) {
        this.setState({ showModal: true });
      } else {
        this.props.history.push("/evaluation");
      }
    } catch (err: any) {
      console.error(err.message);
    }
  };

  displayNotificationMessage = (message: string, status: boolean) => {
    this.setState({
      notificationMessage: message,
      showNotification: true,
      notificationStatus: status,
    });
    setTimeout(() => {
      this.setState({ showNotification: false });
    }, 3000);
  };

  getIntroByProduct = () => {
    const { product } = this.state;
    switch (product) {
      case PRODUCTS.BJCH_Questionnaire:
        return <BJCHIntro {...this} {...this.state} />;
      default:
        return <div />;
    }
  };

  handleCloseModal = () => {
    this.props.onLoginFail();
    this.props.onReset();
    JS_COOKIE.remove("token");
    this.setState({ showModal: false, transactionId: "" });
  };

  handleGoToReport = () => {
    const { transactionId } = this.state;
    this.props.history.push("/core-report/" + transactionId);
  };

  render(): JSX.Element {
    const {
      product,
      showNotification,
      notificationMessage,
      notificationStatus,
      notAvailable,
    } = this.state;
    const Intro = this.getIntroByProduct();
    return (
      <Background>
        <Modal handleClose={this.handleCloseModal} {...this.state} {...this} />
        <NotificationModal
          show={showNotification}
          message={notificationMessage}
          status={notificationStatus}
        />
        {notAvailable ? (
          <NotAvailable />
        ) : (
          <>
            <EvalProgressBar step={EVAL_STEPS.basicIntro} product={product} />
            {Intro}
          </>
        )}
      </Background>
    );
  }
}

interface LinkDispatchProps {
  onLoginSuccess: (data: string) => void;
  onUpdateUserToken: (data: string) => void;
  onLoginFail: () => void;
  onReset: () => void;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): LinkDispatchProps => ({
  onLoginSuccess: bindActionCreators(startLoginSuccess, dispatch),
  onUpdateUserToken: bindActionCreators(startUpdateUserToken, dispatch),
  onLoginFail: bindActionCreators(startLoginFail, dispatch),
  onReset: bindActionCreators(startReset, dispatch),
});

export default withRouter(
  connect(null, mapDispatchToProps)(withTranslation()(Entry))
);
