import { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  VideoListItem,
  OptionText,
  OrderList,
  Segment,
  Title,
  TitleIndicate,
  ItaticText,
} from "components/User/Evaluation/styles";
import { videoUploadTabs } from "constants/eval_constants";

interface InstructionProps {
  submitButton: (videoId: number) => JSX.Element;
}

interface InstructionState {}

type Props = InstructionProps & RouteComponentProps & WithTranslation;

class Instruction extends Component<Props, InstructionState> {
  render() {
    const { t } = this.props;
    const { submitButton } = this.props;
    return (
      <>
        <Segment>
          <TitleIndicate />
          <Title>{t("user.survey.corePro.video.instruction.title")}</Title>
        </Segment>
        <Segment>
          <OrderList style={{ marginLeft: "20px", marginRight: "30px" }}>
            <VideoListItem>
              <OptionText>
                {t("user.survey.corePro.video.instruction.opening3")}
              </OptionText>
            </VideoListItem>
            <VideoListItem>
              <OptionText>
                {t("user.survey.corePro.video.instruction.opening2a")}
                {t("user.survey.corePro.video.instruction.opening2b")}
                {t("user.survey.corePro.video.instruction.opening2c")}
              </OptionText>
            </VideoListItem>
            <VideoListItem>
              <OptionText>
                {t("user.survey.corePro.video.instruction.opening1a")}
                <b>{t("user.survey.corePro.video.instruction.opening1b")}</b>
                {t("user.survey.corePro.video.instruction.opening1c")}
              </OptionText>
              <OrderList>
                <VideoListItem className="subListLowerAlpha">
                  <OptionText>
                    {t("user.survey.corePro.video.instruction.opening1d")}
                  </OptionText>
                </VideoListItem>
              </OrderList>
            </VideoListItem>
          </OrderList>

          <ItaticText>
            {t("user.survey.corePro.video.instruction.message")}
          </ItaticText>
        </Segment>
        {submitButton(videoUploadTabs.instruction)}
      </>
    );
  }
}

export default withRouter(withTranslation()(Instruction));
