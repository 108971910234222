import gql from "graphql-tag";
import { ReturnMessage } from "../types/ReturnMessage";

export const REGISTER_THERAPIST_MUTATION = gql`
  mutation RegiserTherapist(
    $uuid: String!
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $locale: String!
    $phone: String!
    $countryCode: String!
    $country: String!
    $title: String!
    $bio: [TherapistBioInput!]!
    $consultationUrl: String
    $cnConsultationUrl: String
    $avatarFilename: String!
    $timeZone: String!
  ) {
    registerTherapist(
      uuid: $uuid
      email: $email
      password: $password
      locale: $locale
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      countryCode: $countryCode
      country: $country
      title: $title
      bio: $bio
      timeZone: $timeZone
      consultationUrl: $consultationUrl
      cnConsultationUrl: $cnConsultationUrl
      avatarFilename: $avatarFilename
    ) {
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface RegisterTherapistData {
  registerTherapist: {
    returnMessage: ReturnMessage;
  };
}

export interface RegisterTherapistVariables {
  uuid: string;
  email: string;
  password: string;
  locale: string;
  role: string;
  firstName: string;
  lastName: string;
  phone: string;
  country?: string;
  countryCode: string;
  title: string;
  bio: Array<Object>;
  timeZone: string;
  consultationUrl?: string;
  cnConsultationUrl?: string;
  avatarFilename: string;
}
