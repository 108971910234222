import React, { Component, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  DriveContainer,
  DriveInput,
  SaveButtonContainer,
  SaveButton,
} from "./styles";

import { recordingProps } from "./interface";
import { IS_VALID_URL } from "../../../../../../util/common";
import btnUpload from "../../assets/btnUpload.png";
import RecordModal from "./RecordModal";

interface DriveState {
  url: string;
  uploadModalOpen: boolean;
}

type Props = recordingProps & WithTranslation;

class Drive extends Component<Props, DriveState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      url: "",
      uploadModalOpen: false,
    };
  }

  componentDidMount() {
    const { prompt } = this.props;
    this.setState({ url: prompt.path });
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ url: e.currentTarget.value });
  };

  handleSaveFile = () => {
    const { prompt, markComplete, t } = this.props;
    const { url } = this.state;
    if (!IS_VALID_URL(url)) {
      alert(
        t("user.survey.core.questionnaire.sound_collection.sharingURLInvalid")
      );
      return;
    }
    markComplete(prompt.key, url);
  };

  //Modal functions
  setUploadModalOpen = () => {
    this.setState({ uploadModalOpen: true });
  };

  setUploadModalClose = () => {
    this.setState({ uploadModalOpen: false });
  };

  uploadModalHandler = () => {
    this.setUploadModalClose();
    this.handleSaveFile();
  };

  render() {
    const { t } = this.props;
    const { uploadModalOpen } = this.state;

    return (
      <DriveContainer>
        {uploadModalOpen && (
          <RecordModal
            modalHeader={t(
              "user.survey.core.questionnaire.sound_collection.uploadURLModalHeader"
            )}
            modalText={t(
              "user.survey.core.questionnaire.sound_collection.uploadURLModalText"
            )}
            cancelText={t(
              "user.survey.core.questionnaire.sound_collection.skip.cancelButton"
            )}
            actionText={t(
              "user.survey.core.questionnaire.sound_collection.uploadButton"
            )}
            onCancel={this.setUploadModalClose}
            onAction={this.uploadModalHandler}
          ></RecordModal>
        )}

        <DriveInput
          placeholder={t(
            "user.survey.core.questionnaire.sound_collection.sharingPlaceholder"
          )}
          type="text"
          onChange={this.handleChange}
        />
        <SaveButtonContainer>
          <SaveButton
            backgroundColor="#2AB2AC"
            border="1pt solid #2AB2AC"
            activeColor="rgb(100, 155, 250)"
            pointerActive="inherit"
            onClick={this.setUploadModalOpen}
            cursor="pointer"
            boxShadow={
              "rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;"
            }
          >
            {t("user.survey.core.questionnaire.sound_collection.uploadButton")}
          </SaveButton>
        </SaveButtonContainer>
      </DriveContainer>
    );
  }
}

export default withTranslation()(Drive);
