import styled from "styled-components";

export const PopUpModalContainer = styled.div`
  background-color: #fff;
  border-radius: 3px;
  padding: 25px 25px 25px 30px;
  margin-bottom: 15px;
  text-align: center;
  border-radius: 15px;
  height: max-content;
  text-align: center;
  overscroll-behavior: contain;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  z-index: 99;

  @media only screen and (max-width: 430px) {
    width: 300px;
  } ;
`;

export const PopUpModalTitle = styled.div`
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 25px;
  margin-top: 10px;
`;

export const PopUpModalText = styled.div`
  font-size: 16px;
  width: 80%;
  margin-bottom: 25px;
`;

export const PopUpModalButton = styled.button<{
  marginTop: string;
  marginBottom: string;
  disabled: boolean;
}>`
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  font-size: 16px;
  border-radius: 5px;
  border: ${(props) => (props.disabled ? "#D9D9D9" : "#6898e3")} 1px solid;
  padding: 10px 24px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  background: ${(props) => (props.disabled ? "#D9D9D9" : "#6898e3")};
  color: white;

  @media only screen and (max-width: 572px) {
    text-align: center;
    margin-top: 30px;
  } ;
`;

export const ModalMask = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? "flex" : "none")};
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: -webkit-center;
  background-color: #00000099;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2;
`;
