import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import EN from "./locales/en/translation.json";
import ZH_CN from "./locales/zh-CN/translation.json";
import ZH_TW from "./locales/zh-TW/translation.json";

// the translations
const resources = {
  en: {
    translation: EN,
  },
  "zh-CN": {
    translation: ZH_CN,
  },
  "zh-TW": {
    translation: ZH_TW,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: {
      en: ["en", "en-US"],
      "zh-CN": ["zh-CN"],
      "zh-TW": ["zh-TW"],
      default: ["zh-CN"],
    },
    load: "languageOnly",
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
