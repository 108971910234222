import styled from "styled-components";
import {
  GeneralSegment,
  ActionTitle,
  SectionTitle,
  ButtonStyle,
} from "../../../styles";

export const OptionSegment = styled(GeneralSegment)`
  max-height: fit-content;
  margin: 15px 0 0 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export const OptionTitle = styled(ActionTitle)``;

export const LogoutButton = styled(ButtonStyle)`
  color: white;
  background-color: #6898e3;
  margin-top: 0px;
  height: 20px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -38px;
  padding-bottom: 20px;
`;

export const HeaderContainerSpaceBetween = styled(HeaderContainer)`
  justify-content: space-between;
  align-items: center;
`;

export const HeaderTitle = styled(SectionTitle)``;

export const ResultsContainer = styled.div``;
export const Result = styled.div`
  padding: 0 8px;

  &:hover {
    cursor: pointer;
  }
`;
export const NoResultText = styled.div`
  height: 78vh;
  font-size: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  text-align: center;

  @media only screen and (max-width: 850px) {
    font-size: 2.3rem;
    padding: 0 1.5rem;
  }
`;
