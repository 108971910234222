import { Color } from "../../ColorUtils";
import {
  GeneralFormSectionContainer,
  GeneralSectionIndicator,
  GeneralFormSectionContentContainer,
} from "../../styles";

interface Props {
  children: React.ReactNode;
  topLeftBorderRadius: boolean;
  indicator: boolean;
  index: number;
}
const FormSection = ({
  children,
  topLeftBorderRadius,
  indicator,
  index,
}: Props) => {
  return (
    <GeneralFormSectionContainer topLeftBorderRadius={topLeftBorderRadius}>
      <GeneralSectionIndicator
        indicator={indicator}
        indicatorColor={Color(index)}
        topLeftBorderRadius={topLeftBorderRadius}
      />
      <GeneralFormSectionContentContainer indicator={indicator}>
        {children}
      </GeneralFormSectionContentContainer>
    </GeneralFormSectionContainer>
  );
};

export default FormSection;
