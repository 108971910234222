import styled from "styled-components";

export const ModalContent = styled.div`
  border-radius: 4px;
  background-color: white;
  overflow: auto;
  height: max-content;
  align-item: center;
  display: flex;
  justify-content: center;
  padding: 40px 25px;
`;

export const SkipModalText = styled.div`
  margin-top: 60px;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  color: #000000;
  line-height: 15px;
`;

// Instruction
export const InstructionContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 200px;
  padding: 20px;
  border: solid 1px #9d9d9d;
  border-top: solid 2px #6898e3;
`;
export const InstructionTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #000000;
`;
export const InstructionLine = styled.div`
  margin: 15px 0px;
  width: 100%;
  border: solid 1px #e4e4e4;
`;
export const InstructionDescription = styled.div`
  margin-top: 15px;
  font-size: 14px;
  font-weight: normal;
  color: #272643;
`;

export const InstructionDescriptionSkipText = styled.span`
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  color: #6898e3;
  cursor: pointer;
`;

// Recording
export const RecordingContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  padding: 20px;
  border: solid 1px #9d9d9d;
  border-top: solid 2px #6898e3;
`;
export const RecordingTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #000000;
`;
export const RecordingLine = styled.div`
  margin: 15px 0px;
  width: 100%;
  border: solid 1px #e4e4e4;
`;

// submit
export const SubmitContainer = styled.div`
  padding: 30px 0px;
`;
export const SubmitButton = styled.div<{ isActive: boolean }>`
  margin: auto;
  width: 120px;
  height: 30px;
  border-radius: 1px;
  background-color: ${(props) =>
    props.isActive ? "transparent" : "rgb(204,204,204)"};
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
`;

export const UploadIcon = styled.div<{
  backgroundColor: string;
  border: string;
  pointerHover: string;
  activeColor: string;
  pointerActive: string;
  src: string;
}>`
  height: 25px;
  width: 75px;
  border-radius: 25px;
  padding: 12.5px;
  color: white;
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) => props.border};
  display: inline-block;
  font-size: 14px;
  font-family: Noto Sans CJK TC Medium, sans-serif;
  text-align: center;
  cursor: default;
  &:hover {
    transform: translate(0px, -4px);
    transition: 0.2s;
    background-color: rgb(125, 171, 250);
    pointer-events: ${(props) => props.pointerHover};
  }
  &:active {
    transform: translate(0px, 0px);
    transition: 0.2s;
    background-color: ${(props) => props.activeColor};
    pointer-events: ${(props) => props.pointerActive};
  }
  @media only screen and (max-width: 600px) {
    height: 25px;
    width: 25px;
    padding: 12.5px;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    margin-left: 20px;
    background-image: url(${(props) => props.src});
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center;
  }
`;

export const UploadIconText = styled.div`
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const Vr = styled.div`
  border-left: 1px solid rgb(216, 216, 216);
  height: 40px;
  display: inline-block;
  margin: auto 19px;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const UploadBox = styled.div`
  margin: 19px 40px 0px 40px;
  border: 1px dashed rgb(204, 204, 204);
  border-radius: 10px;
  text-align: center;
  @media only screen and (max-width: 600px) {
    margin: 19px 10px 0px 10px;
  }
`;

export const UploadInput = styled.input`
  display: none;
`;

export const UploadButton = styled.label<{ src: string }>`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: 28px 28px;
  display: inline-block;
  margin: 21px 0px;
  cursor: pointer;
  height: 28px;
`;

export const UploadButtonText = styled.div`
  margin-left: 39px;
  font-size: 14px;
  font-family: Noto Sans CJK TC Medium, sans-serif;
  text-align: start;
  line-height: 28px;
`;

export const DriveInput = styled.input`
  width: 80%;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px;
  border: 1px solid rgb(213, 213, 213);
  margin: 34px 40px 0px 40px;
  font-family: Noto Sans CJK TC Regular, sans-serif;
  line-height: 18px;
  outline: 0;
  @media only screen and (max-width: 600px) {
    margin: 34px 10px 15px 10px;
    width: 84%;
  }
`;

export const SaveButton = styled.button<{
  backgroundColor: string;
  border: string;
  activeColor: string;
  pointerActive: string;
}>`
  border: 1px solid rgb(127, 168, 239);
  border-radius: 25px;
  color: rgb(255, 255, 255);
  background-color: rgb(149, 186, 249);
  font-size: 14px;
  font-family: Noto Sans CJK TC Medium, sans-serif;
  text-align: center;
  cursor: pointer;
  margin: 20px 0px;
  height: 50px;
  width: 100px;
  outline: 0;
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) => props.border};
  &:active {
    transform: translate(0px, 0px);
    transition: 0.2s;
    background-color: ${(props) => props.activeColor};
    pointer-events: ${(props) => props.pointerActive};
  }
`;

export const NextButton = styled.button<{ isActive: boolean }>`
  margin: 50px auto 0px auto;
  display: block;
  background-color: ${(props) =>
    props.isActive ? "rgb(149, 186, 249)" : "rgb(204,204,204)"};
  color: #ffffff;
  font-size: 14px;
  padding: 10px 54px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  z-index: 400;
  outline: 0;

  @media only screen and (max-width: 600px) {
    position: fixed;
    width: 100%;
    height: 50px;
    bottom: 0;
    order: 3;
    margin: 0 auto;
  }
`;

export const Loader = styled.svg<{ record: boolean }>`
  fill: none;
  stroke: rgb(127, 168, 239);
  stroke-width: 4;
  stroke-linecap: round;
  stroke-dasharray: ${(props) => (props.record ? "0" : "280")};
  transform-origin: center;
  animation: ${(props) =>
    props.record
      ? "null"
      : "line 5s linear infinite, spin 10s linear infinite"};

  @keyframes line {
    0% {
      stroke-dashoffset: 1120;
    }
    100% {
      stroke-dashoffset: 560;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(-90deg) translate3d(0, 0, 0);
    }
    100% {
      transform: rotate(270deg) translate3d(0, 0, 0);
    }
  }
`;

export const Microphone = styled.svg<{ record: boolean }>`
  position: absolute;
  fill: white;

  animation: ${(props) =>
    props.record ? "null" : "microphone 0.5s ease-in-out both"};

  @keyframes microphone {
    0% {
      transform: scale(1);
    }
    10% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(0);
    }
  }
`;

export const MicrophoneSVG = styled.svg`
  position: absolute;
  fill: white;
`;

export const DotWrapper = styled.div<{ record: boolean }>`
  opacity: ${(props) => (props.record ? "0" : "1")};
  position: absolute;
  width: 50px;
  height: 10px;
  animation: ${(props) => (props.record ? "null" : "dot 1s ease-in-out both")};

  @keyframes dot {
    0%,
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const Dots = styled.div<{
  right: string;
  record: boolean;
  delay: string;
}>`
  position: absolute;
  border-radius: 50%;
  background-color: white;
  height: 10px;
  width: 10px;
  right: ${(props) => props.right};
  animation: ${(props) =>
    props.record ? "null" : "dots 1s ease-in-out infinite"};
  animation-delay: ${(props) => props.delay};

  @keyframes dots {
    0%,
    100% {
      top: 5px;
    }
    50% {
      top: -5px;
    }
  }
`;
