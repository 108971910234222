import { useTranslation } from "react-i18next";
import {
  ActivitySubtitle,
  ActivityDescription,
  WarningContainer,
  AnalysisResultGameName,
} from "./styles";
import { getSolutionIndexByType } from "../../../../../../constants/questionnaireResult";
import { EVAL_STEPS } from "../../../../../../constants/common";
import CollapsibleContent from "../../../../../common/CollapsibleContent";
import { Title } from "components/User/Evaluation/styles";
import { LockModal } from "../Utils";
import { objectToArray } from "util/common";

type Props = { age: number; lockSummaryAndActivity: boolean };

const Notes = ({ translation }): JSX.Element => {
  const { t } = useTranslation();
  const notes = Object.keys(translation).map((key: string) => translation[key]);
  return notes.length === 0 ? (
    <WarningContainer>{t("user.survey.core.result.none")}</WarningContainer>
  ) : (
    <WarningContainer>
      {notes.map((note: string) => (
        <p>{note}</p>
      ))}
    </WarningContainer>
  );
};

const Skills = ({ translation }) => {
  const { t } = useTranslation();
  const children = objectToArray(translation["children"]);
  const parent = objectToArray(translation["parent"]);
  return (
    <div>
      <div style={{ fontWeight: "bold" }}>
        {t("user.survey.corePro.result.activity.subtitle.skills")}
      </div>
      <p>{translation["description"]}</p>
      <div style={{ fontWeight: "bold" }}>
        {t("user.survey.corePro.result.activity.subtitle.children")}
      </div>
      {children.map((line: string) => (
        <p>{line}</p>
      ))}
      <div style={{ fontWeight: "bold" }}>
        {t("user.survey.corePro.result.activity.subtitle.parent")}
      </div>
      {parent.map((line: string) => (
        <p>{line}</p>
      ))}
    </div>
  );
};

const Steps = ({ translation }): JSX.Element => {
  const steps = Object.keys(translation).map((key: string) => translation[key]);
  return (
    <div>
      {steps.map((step: string, index: number) => (
        <p key={index}>{step}</p>
      ))}
    </div>
  );
};

const Name = ({ name }): JSX.Element => {
  return <AnalysisResultGameName>{name}</AnalysisResultGameName>;
};

const Activity = ({ translation }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div>
      <ActivitySubtitle>
        {t("user.survey.core.result.recommendGame.execSkill.title")}
      </ActivitySubtitle>
      <ActivityDescription>
        <Skills translation={translation["skills"]} />
      </ActivityDescription>
      <ActivitySubtitle>
        {t("user.survey.core.result.recommendGame.gameScene.title")}
      </ActivitySubtitle>
      <ActivityDescription>{translation["frequency"]}</ActivityDescription>
      <ActivitySubtitle>
        {t("user.survey.core.result.recommendGame.gameStep.title")}
      </ActivitySubtitle>
      <ActivityDescription>
        <Steps translation={translation["steps"]} />
      </ActivityDescription>
      <ActivitySubtitle>
        {t("user.survey.core.result.recommendGame.gameWarning.title")}
      </ActivitySubtitle>
      <ActivityDescription>
        <Notes translation={translation["notes"]} />
      </ActivityDescription>
    </div>
  );
};

const GameSegment = (props: { category: string; age: number }): JSX.Element => {
  const { category, age } = props;
  const caseNum = getSolutionIndexByType(category, age);
  const { t } = useTranslation();
  const translation = t(`recommendedActivities.${category}.${caseNum}`, {
    returnObjects: true,
  });
  return (
    <CollapsibleContent
      header={<Name name={translation["name"]} />}
      content={<Activity translation={translation} />}
      isLock={false}
    />
  );
};

const RecommendedActivities = (props: Props): JSX.Element => {
  const { age, lockSummaryAndActivity } = props;
  const { t } = useTranslation();
  return (
    <>
      <Title
        style={{ fontSize: "20px", fontWeight: "bold", marginTop: "30px" }}
      >
        {t("user.survey.core.result.recommendGame.title")}
      </Title>
      <GameSegment category={EVAL_STEPS.expression} age={age} />
      <div style={{ position: "relative" }}>
        <GameSegment category={EVAL_STEPS.comprehension} age={age} />
        {lockSummaryAndActivity && <LockModal />}
      </div>
      <div style={{ position: "relative" }}>
        <GameSegment category={EVAL_STEPS.social} age={age} />
        {lockSummaryAndActivity && <LockModal />}
      </div>
    </>
  );
};

export default RecommendedActivities;
