import { NextButton, CloseButton } from "styles";
import { Header, Image } from "./styles";
import ThankYouImg from "../../../general/assets/ThankYou.svg";

type BJCHHistoryProps = {
  handleClose: () => void;
  handleGoToReport: () => void;
};

type Props = BJCHHistoryProps;

function BJCHHistory(props: Props) {
  return (
    <>
      <CloseButton onClick={props.handleClose}>X</CloseButton>
      <div style={{ display: "grid", placeItems: "center" }}>
        <Image src={ThankYouImg} />
      </div>
      <Header>本病例号已填写过问卷</Header>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <NextButton onClick={props.handleGoToReport} status={true}>
          查看结果
        </NextButton>
      </div>
    </>
  );
}

export default BJCHHistory;
