import { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";

import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import {
  QUESTIONNAIRE_STEPS,
  EXPERT_PRODUCTS,
  EVAL_STEPS,
  VIDEO_STATUS,
  PRODUCTS,
} from "../../../constants/common";
import { AppState } from "storeConfig";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "types/actions";
import { History, LocationState } from "history";
import { withTranslation, WithTranslation } from "react-i18next";
import api_address from "../../../constants/config";
import {
  makeIsUserLoggedIn,
  makeSelectToken,
  makeSelectLanguage,
} from "../../auth/selectors";
import {
  makeIsNewQuestionnaire,
  makeSelectCurrentStep,
  makeSelectStep,
  makeSelectMeta,
  makeSelectLoading,
  makeSelectCompleted,
  makeSelectVideo,
  makeSelectReport,
} from "./Core/utils/selectors";

import {
  startUpdateQuestionnaireMeta,
  startReset,
  startFetchMostRecentUserResult,
  startFetchQuestionnaire,
  startFetchResultSuccess,
  startDisableLoading,
} from "./Core/utils/actions";

import { startUpdateUserToken } from "../../auth/actions";
import { bindActionCreators } from "redux";
import { t } from "i18next";
import { NoEvalButton, NoEvalContainer, NoEvalTitle } from "./styles";

interface EvaluationProps {
  history: History<LocationState>;
}

interface EvaluationState {
  userId: string;
  transactionId: string;
  product: string;
  video: number;
}

type Props = EvaluationProps &
  LinkDispatchProps &
  LinkStateProp &
  WithTranslation;

class Evaluation extends Component<Props, EvaluationState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userId: "",
      transactionId: "",
      product: "",
      video: 0,
    };
  }

  componentDidMount = async () => {
    try {
      const token = Cookies.get("token");
      this.props.onReset();
      if (!token) {
        this.props.history.push("/login");
      }
      const res = await axios.get(api_address + "api/user/surveys", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { id } = res.data.authData;
      const { transactions, completedResult } = res.data;
      console.log(res.data, "res.data");
      if (transactions.length > 0) {
        const transaction = transactions[0];
        const { product, transactionid, currentstep } = transaction;
        this.setState({
          userId: id,
          transactionId: transactionid,
          product: product,
        });
        this.handleFetchResultSuccess(transaction);
        this.handleDisableLoading();
        if (product === PRODUCTS.QuickScreener) {
          this.props.history.push({
            pathname: "/quick-screener",
            state: { transactionId: this.state.transactionId },
          });
        } else if (QUESTIONNAIRE_STEPS.includes(currentstep)) {
          this.props.history.push("/questionnaire");
        } else if (currentstep === EVAL_STEPS.video) {
          this.props.history.push("/upload-video-section/" + transactionid);
        } else if (
          currentstep === VIDEO_STATUS.waiting ||
          currentstep === VIDEO_STATUS.reviewing
        ) {
          this.props.history.push("/waiting-section/" + transactionid);
        } else if (currentstep === EVAL_STEPS.report) {
          if (EXPERT_PRODUCTS.includes(product)) {
            this.props.history.push("/waiting-section/" + transactionid);
          } else {
            this.props.history.push("/core-report/" + transactionid);
          }
        } else if (currentstep === EVAL_STEPS.partialReport) {
          this.props.history.push("/core-report/" + transactionid);
        }
      } else if (completedResult.length > 0) {
        const transacId = completedResult[0].transactionid;
        const lastCompletedProduct = completedResult[0].product;
        if (lastCompletedProduct == PRODUCTS.QuickScreener) {
          this.props.history.push(`/evaluation-history`);
        } else {
          this.props.history.push(`/waiting-section/${transacId}`);
        }
      }
    } catch (error: any) {
      console.log("error: ", error);
    }
  };

  handleFetchResultSuccess = (data: object) => {
    this.props.onFetchResultSuccess(data);
  };

  handleDisableLoading = () => {
    this.props.onDisableLoading();
  };

  render() {
    return (
      <NoEvalContainer>
        <NoEvalTitle>{t("user.dashboard.modalHeader")}</NoEvalTitle>
        <NoEvalButton
          onClick={() => {
            this.props.history.push("/dashboard");
          }}
        >
          {t("user.dashboard.backButton")}
        </NoEvalButton>
      </NoEvalContainer>
    );
  }
}

//satisfies return values of mapStateToProps
interface LinkStateProp {
  loggedIn: boolean;
  token: string;
  currentStep: string;
  steps: any;
  isNewQuestionnaire: boolean;
  meta: any;
  loading: boolean;
  completed: boolean;
  video: number;
  language: string;
  report: string;
}

//satisfies return values of mapDispatchToProps
interface LinkDispatchProps {
  onUpdateUserToken: (data: string) => void;
  onFetchMostRecentUserResult: (id: string) => void;
  onFetchQuestionnaire: (token: string) => void;
  onUpdateMeta: (data: object) => void;
  onFetchResultSuccess: (data: object) => void;
  onReset: () => void;
  onDisableLoading: () => void;
}

const mapStateToProps = createStructuredSelector<AppState, LinkStateProp>({
  loggedIn: makeIsUserLoggedIn(),
  token: makeSelectToken(),
  currentStep: makeSelectCurrentStep(),
  steps: makeSelectStep(),
  isNewQuestionnaire: makeIsNewQuestionnaire(),
  meta: makeSelectMeta(),
  loading: makeSelectLoading(),
  completed: makeSelectCompleted(),
  video: makeSelectVideo(),
  language: makeSelectLanguage(),
  report: makeSelectReport(),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: EvaluationProps
): LinkDispatchProps => ({
  onUpdateUserToken: bindActionCreators(startUpdateUserToken, dispatch),
  onFetchMostRecentUserResult: bindActionCreators(
    startFetchMostRecentUserResult,
    dispatch
  ),
  onFetchResultSuccess: bindActionCreators(startFetchResultSuccess, dispatch),
  onUpdateMeta: bindActionCreators(startUpdateQuestionnaireMeta, dispatch),
  onReset: bindActionCreators(startReset, dispatch),
  onFetchQuestionnaire: bindActionCreators(startFetchQuestionnaire, dispatch),
  onDisableLoading: bindActionCreators(startDisableLoading, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Evaluation))
);
