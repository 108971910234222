import BJCHHistory from "./BJCHHistory";
import { PRODUCTS } from "../../../constants/common";
import { ModalContainer, ModalContent, ModalMask } from "styles";

interface ModalProps {
  showModal: boolean;
  product: string;
  transactionId: string;
  handleClose: () => void;
  handleGoToReport: () => void;
}

type Props = ModalProps;

function Modal(props: Props) {
  const { showModal, product } = props;

  const getHistoryComponentByOrganization = () => {
    switch (product) {
      case PRODUCTS.BJCH_Questionnaire:
        return <BJCHHistory {...props} />;
      default:
        return <div />;
    }
  };

  return (
    <ModalMask open={showModal}>
      <ModalContainer id="ModalContainer">
        <ModalContent>{getHistoryComponentByOrganization()}</ModalContent>
      </ModalContainer>
    </ModalMask>
  );
}

export default Modal;
