import { useTranslation } from "react-i18next";
import {
  ComponentContainer,
  Age,
  Score,
  QuestionContainer,
  Question,
  QuestionTitle,
  QuestionAnswer,
  Skill,
} from "./styles";

interface questionType {
  en: Array<string>;
  "zh-TW": Array<string>;
  "zh-CN": Array<string>;
}

type Props = {
  language: string;
  scoreSums: Array<number>;
  ageRange: Array<number>;
  questions: Array<questionType>;
  answers: Array<Array<number>>;
  keyQuestion: Array<Array<boolean>>;
  skills: Array<Array<number>>;
};

function Questionnaire({
  language,
  questions,
  answers,
  ageRange,
  scoreSums,
  skills,
  keyQuestion,
}: Props) {
  const { t } = useTranslation();

  return (
    <ComponentContainer>
      {questions.map((question: questionType, i: number) => {
        return (
          <QuestionContainer>
            <Age>
              {t("therapist.fullResult.questionnaire.age") +
                ": " +
                ageRange[i][0] +
                " " +
                t("therapist.fullResult.questionnaire.month") +
                " - " +
                ageRange[i][1] +
                " " +
                t("therapist.fullResult.questionnaire.month")}
            </Age>
            <Score>
              {t("therapist.fullResult.questionnaire.score") +
                ": " +
                scoreSums[i] +
                " / " +
                answers[i].length}
            </Score>
            {question[language].map((q: Array<string>, j: number) => {
              const answer = answers[i][j];
              return (
                <Question>
                  <Skill>
                    {t("therapist.fullResult.questionnaire.skill") +
                      ": " +
                      t("skill." + skills[i][j])}
                  </Skill>
                  <QuestionTitle isKeyQuestion={keyQuestion[i][j]}>
                    {q}
                  </QuestionTitle>
                  <QuestionAnswer>
                    {answer === 0
                      ? t("therapist.fullResult.questionnaire.yes")
                      : t("therapist.fullResult.questionnaire.no")}
                  </QuestionAnswer>
                </Question>
              );
            })}
          </QuestionContainer>
        );
      })}
    </ComponentContainer>
  );
}

export default Questionnaire;
