import { Form, Select } from "antd";
import {
  PopUpModalContainer,
  PopUpModalText,
  PopUpModalTitle,
  PopUpModalButton,
  ModalMask,
} from "./styles";
import { EDIT_USER_INFORMATION } from "graphql/mutations/editUserInformation";
import { GET_USER_INFO, UPDATE_USER_COUNTRY } from "util/common";
import { client } from "graphql/ApolloClient";
import { useEffect, useState } from "react";
import countryList from "constants/countryList";
import { Dropdown } from "semantic-ui-react";

const ChangeCountryModal = (props) => {
  const { t } = props;
  const [country, setCountry] = useState("");

  const [userInfo, setUserInfo] = useState({
    userId: 0,
    userUuid: 0,
    country: "",
  });

  const getUserInfo = async () => {
    const userData = await GET_USER_INFO();
    setUserInfo({
      userId: userData.id,
      userUuid: userData.uuid,
      country: userData.country,
    });
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const handleCountryChange = (event, { value }) => {
    setCountry(value);
  };
  const [form] = Form.useForm();

  const updateUserOnGuide = async (userUuid, country) => {
    await client.mutate({
      mutation: EDIT_USER_INFORMATION,
      variables: {
        userUuid: userUuid,
        attribute: "Country",
        value: country,
      },
    });
  };

  const onFinish = async () => {
    try {
      await UPDATE_USER_COUNTRY(country);
      updateUserOnGuide(userInfo.userUuid, country);
      props.onClick();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {props.display && (
        <ModalMask open={true}>
          <PopUpModalContainer>
            <PopUpModalTitle>{props.title}</PopUpModalTitle>
            <PopUpModalText>{props.text}</PopUpModalText>

            <Form form={form} onFinish={onFinish}>
              <Dropdown
                placeholder={t("auth.login.chooseCountry")}
                fluid
                selection
                search
                onChange={handleCountryChange}
                options={countryList}
                style={{ width: "250px" }}
              />

              <PopUpModalButton
                disabled={false}
                htmlType="submit"
                marginTop={"30px"}
                marginBottom={"0px"}
              >
                {props.buttonText}
              </PopUpModalButton>
            </Form>
          </PopUpModalContainer>
        </ModalMask>
      )}
    </>
  );
};

export default ChangeCountryModal;
