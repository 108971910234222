import React from "react";
import { ActionTitle } from "styles";
import { SaveIcon } from "components/Therapist/ReportGeneration/styles";
import { videoUploadTabs } from "constants/eval_constants";
import {
  TabMenuContainer,
  HighlightBottomEdge,
  TabMenuItem,
  TabContent,
} from "./styles";

interface Props {
  tabTitles: Array<string>;
  tabContents: React.ReactNode[];
  tabSelection: number;
  handleTabClick: (index: number, title: string) => void;
}

interface States {}

class TabMenu extends React.Component<Props, States> {
  render() {
    const { tabSelection, tabTitles, tabContents, handleTabClick } = this.props;
    return (
      <>
        <TabMenuContainer>
          {tabTitles.map((title, index) => {
            return (
              <HighlightBottomEdge isSelected={index === tabSelection}>
                <TabMenuItem
                  key={index}
                  active={index === tabSelection}
                  onClick={() => handleTabClick(index, title)}
                >
                  <ActionTitle>{title}</ActionTitle>
                </TabMenuItem>
              </HighlightBottomEdge>
            );
          })}
        </TabMenuContainer>
        <TabContent>{tabContents[tabSelection]}</TabContent>
      </>
    );
  }
}
export default TabMenu;
